import styled from "styled-components";

interface IContainer {
    isAbsoluteContainer?: boolean
}

export const Container = styled.div<IContainer>`
${({ isAbsoluteContainer }) => isAbsoluteContainer ? `
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
`: 'flex:1;'}
.formio-read-only {
  .field-required.control-label--hidden {
    display: none;
  }
}

`