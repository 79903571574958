import * as React from 'react';
import _ from 'lodash';

import Table from 'src/Framework/Controls/Table';
import Checkbox from 'src/Framework/Controls/Checkbox';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';

import { LoadingContainer } from 'src/Activities/Subject/Patients/PatientInfo_Styles';
import DashletSettings, {
  IOwnProps as IDashletSettingsProps
} from 'src/Activities/Counseling/Dashboard/Settings/DashletSettings';

import * as container from './Common_Styles';

interface IProps {
  title: any;
  data: any;
  onSaveHandler: any;
  onCancelHandler: any;
  onShowChangedHandler: any;
  onOrderChangedHandler: any;
  showSettings?: boolean;
  settings?: IDashletSettingsProps;
}

export const getSortedPrefs = (prefValue: any) => {
  let prefArray: any[] = [];
  Object.keys(prefValue).forEach((key) => {
    prefArray.push({
      key: key,
      order: prefValue[key].order,
      show: prefValue[key].show
    });
  });
  let sortedPrefs = prefArray.sort((a: any, b: any) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });
  return sortedPrefs;
};

export const getColumnSettingsUi = (settings: any) => {
  let data: any = [];
  const list = Object.entries(settings);
  const items = list.map(([key, value]: any, index) => {
    return {
      label: value.label || value.name,
      order: value.order,
      show: value.show,
      key
    };
  });
  data = _.sortBy(items, 'order');
  data.forEach((v: any, index: number) => {
    if (!v.order) {
      const prev = data[index - 1];
      if (prev) {
        v.order = prev.order + 1;
      }
    }
  });
  return data;
};

export const getColumnSettings = (settingsUi: any, prefValue: any) => {
  if (Object.keys(prefValue)?.length > 0) {
    let sortedPrefs = getSortedPrefs(prefValue);
    let order = 0;
    sortedPrefs.forEach((item: any) => {
      let key = item.key;
      if (settingsUi[key]) {
        order += 1;
        settingsUi[key].order = order;
        settingsUi[key].show = item.show;
        settingsUi[key].label = settingsUi[key].label
          ? getPatientTerm(settingsUi[key].label)
          : settingsUi[key].label;
      }
    });
  }
  return settingsUi;
};

function ColumnSettings(props: IProps) {
  const { data, showSettings, settings } = props;

  const CheckboxList = ({ item }: any) => (
    <Checkbox
      checked={item.show}
      id={'show-item-' + item.key}
      onClick={(e: any) => props.onShowChangedHandler(!item.show, item)}
    />
  );

  const onChangeOrder = (prev: number, next: number) => {
    props.onOrderChangedHandler(next + 1, data[prev]);
  };

  return !data || Object.keys(data).length === 0 ? (
    <LoadingContainer>
      <div>
        <div className="loader" />
      </div>
    </LoadingContainer>
  ) : (
    <container.Wrapper>
      <container.Header>
        <div className="header__row">
          <div className="header__row__title">
            <h1>{props.title}</h1>
          </div>
          <div className="header__row__actions">
            <button
              type="button"
              onClick={props.onCancelHandler}
              className="cancel"
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              onClick={props.onSaveHandler}
              className="ant-btn"
            >
              <span>Save</span>
            </button>
          </div>
        </div>
        {showSettings && settings && <DashletSettings {...settings} />}
        <div className="header__table">
          <div className="column__one">
            <p>Category</p>
          </div>
          <div className="column__two">
            <p>Order</p>
          </div>
          <div className="column__three">
            <p>Show</p>
          </div>
        </div>
      </container.Header>
      <container.Content>
        <Table
          hideHeader={true}
          onChangeOrder={onChangeOrder}
          dragAndDropProps={{
            rootList: data,
            onChangeOrder
          }}
          data={data.map((item: any) => ({
            ...item,
            id: item.key,
            show: <CheckboxList item={item} />
          }))}
          columns={columns}
        />
      </container.Content>
    </container.Wrapper>
  );
}

export default ColumnSettings;

const columns: any = [
  {
    title: 'Item',
    flex: '350px',
    key: 'label'
  },
  {
    title: 'Order',
    flex: '75px',
    key: 'order'
  },
  {
    title: 'Show',
    flex: '80px',
    key: 'show'
  },
  {
    flex: 'auto',
    key: '',
    title: '',
    type: 'change-order'
  }
];
