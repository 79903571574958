
import * as actionType from "../../RuleActions/ActionTypes";
import _ from 'lodash';

interface IAction {
    type: string;
    ruleId: string;
    componentId: number;
    immunizationSeries: any;
    pagination: any;
    id: string;
}
export interface IImmunizationsSeriesStore {
    ruleId: string;
    componentId: number;
    pagination: any
    immunizationSeries: any[];
}



const ImmunizationSeriesOptionReducer = (state: IImmunizationsSeriesStore[] = [], action: IAction) => {
    switch (action.type) {
        case actionType.ADD_IMMUNIZATIONS_SERIES: {
            let newState = _.cloneDeep(state)
            let immunizationStore = newState.find((i: IImmunizationsSeriesStore) => i.ruleId === action.ruleId && i.componentId === action.componentId);
            if (immunizationStore) {
                if (action.pagination.current > 1) {
                    immunizationStore.immunizationSeries = [...immunizationStore.immunizationSeries, ...action.immunizationSeries];
                }
                else {
                    immunizationStore.immunizationSeries = action.immunizationSeries;
                }
                immunizationStore.pagination = action.pagination;
            } else {
                let data: IImmunizationsSeriesStore = {
                    ruleId: action.ruleId,
                    componentId: action.componentId,
                    immunizationSeries: action.immunizationSeries,
                    pagination: action.pagination,
                }
                newState.push(data);
            }
            return newState;
        }
        case actionType.CLEAR_RULE_EDITOR: {
            let newState = _.cloneDeep(state)
            let iArray = newState.filter((i: IImmunizationsSeriesStore) => i.ruleId === action.id);
            if (iArray.length === 0) return newState;
            for (let i = 0; i < iArray.length; i++) {
                newState.splice(newState.indexOf(iArray[i]), 0);
            }
            return newState;
        }

        default:
            return state;
    }
};

export default ImmunizationSeriesOptionReducer;
