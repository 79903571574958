import _ from 'lodash'
import { IStateReducer, IAction } from './types';

const prefix = 'TAGS_';

export const actionsTypes = {
    SET_TAGS_DEFINITIONS: `${prefix}SET_TAGS_DEFINITIONS`,
    SET_TAGS_VALUES: `${prefix}SET_TAGS_VALUES`,
    SET_TAGS_LINKS: `${prefix}SET_TAGS_LINKS`,
} as const;

const initialState: IStateReducer = {
    definitions: {},
    values: {},
    links: {}
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(initialState),
    action: IAction
): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET_TAGS_LINKS: {
            if (!action.tagValueId) return state
            return {
                ...state,
                links: {
                    ...state.links,
                    [action.tagValueId]: action.payload
                }
            }
        }
        case actionsTypes.SET_TAGS_VALUES: {
            if (!action.tagDefinitionId) return state
            return {
                ...state,
                values: {
                    ...state.values,
                    [action.tagDefinitionId]: {
                        ...(state.values[action.tagDefinitionId] || {}),
                        ...action.payload
                    }
                }
            }
        }
        case actionsTypes.SET_TAGS_DEFINITIONS: {
            return {
                ...state,
                definitions: action.payload
            }
        }
        default:
            return state;
    }
};

export default Reducer;
