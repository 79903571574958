import React, { FC } from 'react';
import { goToSecureMessage } from 'src/App/ActivityPanel/Store/ActivityActions';
import ButtonIcon, {
  ICONS_LIST,
  IIcon
} from 'src/Framework/Controls/ButtonIcon';

interface IProps {
  patientId: number | string;
  tooltip?: string;
  params: {
    noteId?: string | number;
    formId?: string | number;
    formType?: 'm1' | 'legacy';
  };
  buttonProps?: Partial<IIcon>;
}

const Component: FC<IProps> = ({
  patientId,
  params,
  tooltip,
  buttonProps
}: IProps) => {
  return (
    <ButtonIcon
      isButton={true}
      label={tooltip || 'Create Secure Message'}
      tooltip={tooltip || 'Create Secure Message'}
      iconScale={2}
      changeColorOnHover={true}
      name={ICONS_LIST.secureMessages}
      onClick={() => {
        goToSecureMessage(patientId, params);
      }}
      {...buttonProps}
    />
  );
};

export default React.memo(Component);
