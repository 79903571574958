import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { IUnderRowView } from '../types';

interface IProps extends IUnderRowView {
  rowRef: React.RefObject<HTMLDivElement>;
}
export interface IHandels {
  onClickRow: () => any;
}
const Component: React.ForwardRefRenderFunction<IHandels, IProps> = (
  { defaultOpened, component, rowRef, onOpenChanged },
  ref
) => {
  const [open, setOpen] = useState(defaultOpened || false);

  useEffect(() => {
    if (open) {
      rowRef.current?.classList.add('under-row-opened');
    } else {
      rowRef.current?.classList.remove('under-row-opened');
    }
  }, [open]);

  const handleRowClick = useCallback(() => {
    setOpen((v) => {
      const newV = !v;
      if (onOpenChanged) {
        onOpenChanged(newV);
      }
      return newV;
    });
  }, []);

  useImperativeHandle(ref, () => ({
    onClickRow: handleRowClick
  }));

  return open ? <>{component}</> : null;
};

export default React.forwardRef(Component);
