import React from 'react';
import { Select } from 'antd';

import Checkbox from 'src/Framework/Controls/Checkbox';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import { pickTextColorBasedOnBgColorSimple } from 'src/Framework/util/helps';

const { Option } = Select;

interface IProps {
  val: any;
  checkboxOptions?: boolean;
  rowSelectOptions?: boolean;
  value: any;
  isLast?: boolean;
  homeRef?: React.MutableRefObject<any>;
  isOpened?: boolean;
  disableEllipsis?: boolean;
  enableTextColorOption?: boolean;
  optionIcons?: boolean;
}

const Component = ({
  val,
  checkboxOptions,
  rowSelectOptions,
  value,
  isLast,
  homeRef,
  isOpened,
  disableEllipsis,
  enableTextColorOption,
  optionIcons
}: IProps) => {
  const onKeyDownAccessibility = (e: React.KeyboardEvent) => {
    if (!e.shiftKey && e.key === 'Tab' && isLast) {
      e.preventDefault();
      if (homeRef?.current) {
        homeRef?.current.focus();
      }
    }
    if (e.key === 'Enter') {
      if (e.target) {
        //@ts-ignore
        e.target.click();
      }
    }
  };

  const optionStyle: any = {};
  if (val.backgroundColor) {
    optionStyle.backgroundColor = val.backgroundColor;
    optionStyle.color = pickTextColorBasedOnBgColorSimple(val.backgroundColor);
  }
  const colorValue = enableTextColorOption
    ? val.color
      ? val.color
      : null
    : null;
  return (
    <Option
      key={val.value}
      value={val.value}
      disabled={val.disabled}
      style={optionStyle}
      {...{
        ['option-value']: val.value,
        ['aria-label']: optionIcons ? val.title : val.label
      }}
      label={optionIcons ? val.title : val.label || ''}
    >
      {checkboxOptions ? (
        <div
          option-value={val.value}
          aria-label={optionIcons ? val.title : val.label}
          onClick={(e) => e.preventDefault()}
        >
          <Checkbox
            disableFitContent={true}
            id={`checkbox-${val.value}`}
            checked={value && value.includes(val.value)}
            onKeyDown={(e) => onKeyDownAccessibility(e)}
            style={{
              color: colorValue
            }}
          >
            <EllipsisText disabled={disableEllipsis}>
              {optionIcons ? val.label : val.title || val.label}
            </EllipsisText>
          </Checkbox>
        </div>
      ) : rowSelectOptions ? (
        <div
          option-value={val.value}
          aria-label={optionIcons ? val.title : val.label}
          tabIndex={isOpened ? 0 : -1}
          onKeyDown={(e) => onKeyDownAccessibility(e)}
          onClick={(e) => e.preventDefault()}
          style={{
            color: colorValue
          }}
        >
          <EllipsisText disabled={disableEllipsis}>
            {optionIcons ? val.label : val.title || val.label}
          </EllipsisText>
        </div>
      ) : (
        <span
          option-value={val.value}
          aria-label={optionIcons ? val.title : val.label}
          style={{
            color: colorValue
          }}
        >
          <EllipsisText disabled={disableEllipsis}>
            {optionIcons ? val.label : val.title || val.label}
          </EllipsisText>
        </span>
      )}
    </Option>
  );
};

export default Component;
