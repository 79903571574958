import _ from 'lodash';
import { IStateReducer, IAction, ITraineeInfo } from './types';
import { ISort } from 'src/Framework/Controls/Table/types';

const prefix = 'TRAINEE_INFO_';
export const actionsTypes = {
  SET: `${prefix}SET`,
  SET_LOADING: `${prefix}SET_LOADING`
} as const;

const initialState: IStateReducer = {
  providers: {}
};

export const defaultSorting: ISort = {
  sortBy: 'timestamp',
  sortType: 'desc'
};

export const defaultData: { info: ITraineeInfo; loading: boolean } = {
  info: {},
  loading: false
};

const Reducer = (
  state: IStateReducer = _.cloneDeep(initialState),
  action: IAction
): IStateReducer => {
  const traineeData = () =>
    state.providers[action.providerId] || _.cloneDeep(defaultData);
  switch (action.type) {
    case actionsTypes.SET_LOADING: {
      const data = traineeData();
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: {
            ...data,
            loading: action.payload
          }
        }
      };
    }
    case actionsTypes.SET: {
      const data = traineeData();
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: {
            ...data,
            info: action.payload
          }
        }
      };
    }
    default:
      return state;
  }
};

export default Reducer;
