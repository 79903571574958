import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

const transition = `transition: all 0.25s ease;`;

export const ExpandList = styled.div`
  max-height: 0;
  overflow: hidden;

  ${transition}
  &.open {
    max-height: 1000px;
  }
`;

export const ArrowContainer = styled.div`
  padding: 5px 5px 5px 0;
`;

export const Line = styled.div`
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  width: calc(100% - 32px);
  margin: 10px 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;

  ${transition}
  &.active {
    background-color: ${globalStyleVariables.newBrandingActiveMenuItemBG};
    color: ${globalStyleVariables.newBrandingActiveMenuItemColor};
    //border-right: 2px solid ${globalStyleVariables.borderColor};
  }

  .arrow {
    height: 8px;
    width: 8px;
    mask-size: contain;
    mask-position: center center;
    mask-repeat: no-repeat no-repeat;
    background: rgba(51, 51, 51, 1);

    ${transition}
    &.active {
      background: ${globalStyleVariables.brandGray};
      transform: rotate(45deg);
    }
  }

  &:hover,
  &:active,
  &:focus {
    //background-color: rgba(238, 250, 234, 0.5);
    background-color: ${globalStyleVariables.newBrandingHoverMenuItemBG};
    color: ${globalStyleVariables.newBrandingHoverMenuItemColor};
  }
`;

export const Title = styled.div`
  font-family: Lato;
  font-style: normal;
  font-size: 14px;
  margin: 0 0 0 5px;

  &.firstLevel {
    font-weight: bold;
    //color: black;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px 0;
  overflow: auto;
  margin: 0 4px 0 0;
`;
