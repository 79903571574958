import _ from 'lodash'
import { IAction, IStateReducer, IHospitalizationState } from './types'

export const actionsTypes = {
    SET_HOSPITLIZATIONS: 'SET_HOSPITLIZATIONS',
    SET_HOSPITLIZATIONS_LOADING: 'SET_HOSPITLIZATIONS_LOADING',
    SET_HOSPITLIZATIONS_LOADING_MORE: 'SET_HOSPITLIZATIONS_LOADING_MORE',
    SET_HOSPITLIZATIONS_SORT: 'SET_HOSPITLIZATIONS_SORT',
    SET_HOSPITLIZATIONS_INIT: 'SET_HOSPITLIZATIONS_INIT',
    SET_HOSPITLIZATIONS_STATUSES: 'SET_HOSPITLIZATIONS_STATUSES',
    SET_HOSPITLIZATIONS_TYPES: 'SET_HOSPITLIZATIONS_TYPES',
    SET_ITEM_HOSPITLIZATION: 'SET_ITEM_HOSPITLIZATION'
}

export const initialHospitalizationState: IHospitalizationState = {
    list: {
        summary: {},
        total: 0
    },
    loading: false,
    loadingMore: false,
    sort: {
        sortBy: null,
        sortType: null
    },
    statuses: {},
    types: {}

}

const initialState: IStateReducer = {};

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction) => {
    const checkExist = () => {
        const newState: IStateReducer = _.cloneDeep(state)
        if (!newState.hasOwnProperty(action.PatientId)) {
            newState[action.PatientId] = _.cloneDeep(initialHospitalizationState)
        }
        return newState
    }
    const changedState = (field: keyof typeof initialHospitalizationState, payload: any) => {
        let newState = checkExist()
        return ({
            ...newState, [action.PatientId]: {
                ...newState[action.PatientId],
                [field]: payload
            }
        })
    }

    switch (action.type) {
        case actionsTypes.SET_ITEM_HOSPITLIZATION: {
            let newState = checkExist()
            newState[action.PatientId].list.summary[action.payload.id] = action.payload
            return newState
        }
        case actionsTypes.SET_HOSPITLIZATIONS_TYPES: {
            return changedState('types', action.payload)
        }
        case actionsTypes.SET_HOSPITLIZATIONS_STATUSES: {
            return changedState('statuses', action.payload)
        }
        case actionsTypes.SET_HOSPITLIZATIONS_SORT: {
            return changedState('sort', action.payload)
        }
        case actionsTypes.SET_HOSPITLIZATIONS_LOADING_MORE: {
            return changedState('loadingMore', action.payload)
        }
        case actionsTypes.SET_HOSPITLIZATIONS_LOADING: {
            return changedState('loading', action.payload)
        }
        case actionsTypes.SET_HOSPITLIZATIONS: {
            return changedState('list', action.payload)
        }
        case actionsTypes.SET_HOSPITLIZATIONS_INIT: {
            return {
                ...state, [action.PatientId]: _.cloneDeep(initialHospitalizationState)
            }
        }
        default:
            return state;
    }
};

export default Reducer;
