import _ from 'lodash';
import { IPatientAdditionalInfo } from './types';

export interface IStateReducer {
    patients: Record<string, IPatientAdditionalInfo>
}

export interface IAction {
    type: typeof actionsTypes[keyof typeof actionsTypes];
    payload: any;
    patientId: string | number
}


const prefix = 'PATIENT_ADDITIONAL_INFO_';

export const actionsTypes = {
    SET_SUMMARY: `${prefix}SET_SUMMARY`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

const defaultPatientInfo: IPatientAdditionalInfo = {
    summary: {}
}

export const defaultData: IStateReducer = {
    patients: {},
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const patientInfo = state.patients[action.patientId] || _.cloneDeep(defaultPatientInfo)
    const actions: IActions = {
        [actionsTypes.SET_SUMMARY]: () => {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patientInfo,
                        summary: action.payload
                    }
                }
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
