import _ from 'lodash'
import { IStateReducer, IAction } from './types';

const prefix = 'OTHER_PARTY_';

export const actionsTypes = {
    SET: `${prefix}SET`,
};

const initialState: IStateReducer = {
    summary: {},
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(initialState),
    action: IAction
): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET:
            return {
                ...state,
                summary: action.payload
            };
        default:
            return state;
    }
};

export default Reducer;
