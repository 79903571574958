import PubSub from 'pubsub-js';

import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { AppUrl } from 'src/Framework/Common/AppUrl';
import { Alert } from 'src/Framework/Common/Notification';
import { enableAction } from 'src/Activities/RuleViewer/Store/RuleActions/RuleDetailsActions/RuleDetailsAction';
import {
  removeActivity,
  updateRuleViewerActivity,
  updateRuleEditorActivity
} from 'src/App/ActivityPanel/Store/ActivityActions';

import { changeUpdateStatus } from './RuleUpdateReducer';
import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';
import { CLEAR_RULE_EDITOR } from 'src/Activities/RuleEditor/Store/RuleActions/ActionTypes';
import { expiryCheck } from 'src/App/LoginPage/store/Actions';
import { ISort } from 'src/Framework/Controls/Table/types';
import store from 'src/store';
import { actionsTypes } from 'src/App/Admin/Pages/NoteTypes/store/Reducer';
import { IRuleDefinition } from 'src/Activities/Home/Cohort/store/types';
import { publishRule } from 'src/Activities/RuleEditor/Store/RuleActions/RuleDefinitionActions/RuleDefinitionActions';
import { notification } from 'antd';

export const ADD_RULES = 'ADD_RULES';
export const CLEAR_RULES = 'CLEAR_RULES';
export const UPDATE_RULE_DETAILS = 'UPDATE_RULE_DETAILS';
export const REMOVE_DRAFT = 'REMOVE_DRAFT';
export const UPDATE_DRAFT_STATUS = 'UPDATE_DRAFT_STATUS';
export const CLEAR_RULE_MANAGER = 'CLEAR_RULE_MANAGER';

export const SET_TABLE_SORT = `RULE_MANAGER_SET_TABLE_SORT`;

export const addRules = (result: any, pagination: any) => {
  return {
    type: ADD_RULES,
    ruleSummary: result,
    pagination: pagination
  };
};

export const setSorting = (sort: ISort) => {
  store.dispatch({
    type: actionsTypes.SET_TABLE_SORT,
    sort
  });
};

export const clearRules = () => {
  return {
    type: CLEAR_RULES
  };
};

export const getRules = (
  currentPage = 1,
  count = 20,
  sortBy = 'NameAsc',
  searchText = '',
  callBack?: any,
  IsPublished?: boolean,
  enabled?: boolean
): any => {
  let paginationDetails = {
    startIndex: (currentPage - 1) * count,
    count: count,
    sortBy: sortBy,
    filter: { searchString: searchText, ruleType: IsPublished ? 1 : 2, isPatientFilter: false, enabled }
  };

  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(ApiUrl.GET_RULES, paginationDetails, {
      withCredentials: true
    })
      .then((response) => {
        if (callBack) {
          callBack();
        }
        let data: any = response.data;
        if (data.success) {
          let pagination = {
            total: data.result.total,
            current: currentPage
          };
          dispatch(addRules(data.result.summary, pagination));
        }
      })
      .catch((error) => {
        if (callBack) callBack();
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};

export const enableOrDisableRule = (
  ruleId: any,
  ruleName: any,
  enabled: boolean,
  version: any,
  callBack?: any
): any => {
  let payload = {
    id: ruleId,
    name: ruleName,
    enabled: enabled, // Need to send from UI
    ruleVersion: version
  };
  return (dispatch: any) =>
    APIHandler.AxiosInstance.post('/Rule/EnableRule', payload, {
      withCredentials: true
    }).then((response: any) => {
      if (response.data.success) {
        if (callBack) callBack();
        else dispatch(enableAction(ruleId, enabled));
      } else {
        PubSub.publish('ERROR_TOAST', response.data.error);
      }
    });
};

export const updateRule = (
  ruleId: any,
  version: any,
  ruleDetails: any,
  activityId: string,
  onCancel: any,
  oldRuleName?: any,
  callback?: any,
  activityType?: any
): any => {
  return (dispatch: any) =>
    APIHandler.AxiosInstance.post(
      '/Rule/SaveRuleChanges?ruleId=' + ruleId + '&version=' + version,
      ruleDetails,
      { withCredentials: true }
    )
      .then((response: any) => {
        if (response.data.success) {
          Alert('success', 'Success', 'Rule Updated Successfully ');
          onCancel();
          dispatch(changeUpdateStatus(true));
          dispatch(updateRuleViewerActivity(oldRuleName, ruleDetails.name));
          dispatch(removeActivity(activityId));
          // dispatch(clearRuleEditor(ruleId));
          if (activityType === AppUrl.RULEVIEWER) {
            loadActivityTab(
              AppUrl.RULEVIEWER + '/' + ruleDetails.name,
              dispatch
            );
            dispatch(updateRuleEditorActivity(oldRuleName, ruleDetails.name));
          } else
            loadActivityTab(
              AppUrl.RULEEDITOR + '/' + ruleDetails.name,
              dispatch
            );
          if (callback) callback();
        } else {
          Alert('info', 'Information', response.data.error);
        }
      })
      .catch((error: any) => {
        dispatch(expiryCheck(error));
        Alert('error', 'Information', error);
      });
};

export const clearRuleEditor = (ruleId: string) => {
  return {
    type: CLEAR_RULE_EDITOR,
    id: ruleId
  };
};

export const removeDraft = (ruleId: string) => {
  return {
    type: REMOVE_DRAFT,
    ruleId: ruleId
  };
};

export const createRule = (rule: any) => {
  return APIHandler.AxiosInstance.post('/Rule/CreateRule', rule, {
    withCredentials: true
  });
};


export const exportRule = async (data: { ruleId: string, version: number }): Promise<IRuleDefinition | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post('/Rule/ExportRule', data, {
      withCredentials: true
    });
    if (response.data.success) {
      return response.data.result.Definition
    }
    return
  } catch (e) {
    return
  }
}

export const importRule = async (data: IRuleDefinition): Promise<IRuleDefinition | undefined> => {
  try {
    const { ruleId, ...body } = data
    const response = await APIHandler.AxiosInstance.post('/Rule/CreateRule', {
      name: body.ruleName,
      description: body.description,
      user: body.userName,
      ruleType: body.ruleType
    }, {
      withCredentials: true
    });
    if (response.data.success) {
      const id = response.data.result.id
      const res = await publishRule(id, body)
      if (res) {
        return res.data
      }
    } else {
      if (response.data.error) {
        notification.error({
          message: response.data.error
        })
      }
    }
    return
  } catch (e) {
    return
  }
}