import React, { FC, useState, useMemo, useEffect } from 'react';
import { Button } from 'antd';

import { getCorporateScreeningAccessToken } from '../PatientListActions';

interface IProps {
  email: any;
  allInfoExpanded: boolean;
}

const headerHeight = 114;

export const Component: FC<IProps> = ({ email, allInfoExpanded }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [isFirstLine, setFirstLine] = useState<boolean>(false);

  useEffect(() => {
    const elem = document.getElementById('screening-button');
    if (elem) {
      setFirstLine(elem.getBoundingClientRect().top < headerHeight);
    } else {
      return;
    }
  }, []);

  const isButtonActive = useMemo(() => {
    if (allInfoExpanded) {
      return true;
    } else {
      return isFirstLine;
    }
  }, [allInfoExpanded, isFirstLine]);

  const onClickCorporateScreening = async () => {
    try {
      setLoading(true);
      await getCorporateScreeningAccessToken(email);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button
      tabIndex={isButtonActive ? 0 : -1}
      id="screening-button"
      loading={loading}
      onClick={onClickCorporateScreening}
    >
      Ease
    </Button>
  );
};

export default Component;
