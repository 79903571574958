import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { IHtmlPreview } from "./Html/types";
import { IHeaderV1Preview } from "src/Activities/Counseling/NewNotes/Preview/DataGenerator/Previews/Header/V1/types";

export const getFormPreview = async ({ seedId }: { seedId: number }): Promise<{ sections: IHtmlPreview } | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            `${ApiUrl.GET_TEMPLATE_FORM_NEW}/preview`,
            { seedId },
            { withCredentials: true }
        );
        if (
            response.data.success &&
            response.data.result &&
            response.data.result.sections
        ) {
            return response.data.result
        }
        return
    } catch (e) {
        return
    }
};

export const getHeader = async ({ seedId }: {
    seedId: number
}): Promise<IHeaderV1Preview | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.FormRecordsHeaders, {
            withCredentials: true,
            params: {
                formType: 'legacy',
                formId: seedId
            }
        })
        if (response.data.success) {
            return response.data.result
        }
        return
    } catch (e) {
        return
    }
}