import React, { FC, useState } from 'react';

import Popover from 'src/Framework/Controls/Popover';

import Content from './Content';

import { Type, IData } from './types';

interface IProps {
  type: Type;
  value: {
    id: number;
  };
  children: ({ visible }: IData) => React.ReactElement;
  disableOnlyPublished?: boolean;
}

const Component: FC<IProps> = ({ children, value, type, disableOnlyPublished }: IProps) => {
  const [visible, setVisible] = useState(false);
  return (
    <Popover
      id=""
      content={<Content value={value} type={type} disableOnlyPublished={disableOnlyPublished} />}
      trigger="click"
      placement="bottomRight"
      onOpenChange={(visible) => setVisible(visible)}
    >
      <div style={{ cursor: 'pointer' }}>{children({ visible })}</div>
    </Popover>
  );
};

export default Component;
