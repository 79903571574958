import React from 'react';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const CheckedBoxSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.5"
      y="2.5"
      width="15"
      height="15"
      rx="1"
      fill={globalStyleVariables.newBrandingPrimary200}
    />
    <path
      d="M6.69922 10.8998L8.49922 12.6998L13.2992 7.2998"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
