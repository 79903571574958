import _ from 'lodash';
import moment from 'moment';
import { formattedDate } from 'src/Framework/Shared/Shared';
import {
  IScheduleSliceEvent,
  IScheduleEvent,
  IPatientInfoData,
  IEventMemberStatus
} from './Store/ScheduleEventsReducer/types';
import { isEventLate } from 'src/Activities/Schedule/Utils/Utils';
import store from 'src/store';
import { IconNames } from 'src/Framework/Controls/Icon';
import { generateOrderSliceId } from './TopBar/FilterBar/Order/utils';
import { defaultProvidersColumnsOrder } from './Store/ScheduleReducers/ScheduleDetailsReducer';
import {
  ICurrentClinicOption,
  IOrderValue
} from './Store/ScheduleReducers/ScheduleDetailsReducer/types';
import { onChangeProviderColumnsOrder } from './Store/ScheduleSlicesActions';
import { ClinicSimpleQueryResult } from 'src/App/Admin/Pages/Clinics/store/types';
import {
  capitalizeFirstLetter,
  clinicDisplayName
} from 'src/Framework/util/format';
import { defaultApptBackgroundSettings } from 'src/App/UserPreferences/store';
import { IAppointmentFull } from 'src/Activities/Schedule/Main/ScheduleCol/ScheduleOne';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { FuctionType, permissionChecking } from 'src/App/User/Permission';

export const eventName = 'Staff Appt';

interface IEvents {
  [sliceId: string]: {
    [uid: string]: IScheduleSliceEvent;
  };
}

export const filterExpiredEvents = (events: IEvents): IEvents => {
  const data = _.cloneDeep(events);
  Object.values(data).forEach((events) => {
    Object.entries(events).forEach(([key, event]) => {
      if (
        (event.isBlock || event.isReservation) &&
        event.blockReservationExpireTime &&
        moment() >= moment(event.blockReservationExpireTime)
      ) {
        delete events[key];
      }
    });
  });
  return data;
};

export const findUpdatedSlices = (
  scheduleSlices: any,
  slices: any,
  refreshAll: boolean,
  slicesProps: any
) => {
  let contentUpdated = false;
  let updatedSlices: any[] = [];
  let keys = Object.keys(slices);
  scheduleSlices.forEach((s: any) => {
    let exists = false;
    if (!s.checksum) {
      let index = updatedSlices.findIndex((f: any) => f.id === s.id);
      contentUpdated = true;
      if (index < 0) {
        updatedSlices.push(s);
      }
    } else {
      if ((keys && keys.length > 0) || refreshAll) {
        keys.forEach((key: any) => {
          if (s.id === key) {
            exists = true;
            if (s.checksum != slicesProps[key]['checksum']) {
              let index = updatedSlices.findIndex((f: any) => f.id === key);
              contentUpdated = true;
              if (index < 0) {
                updatedSlices.push(slicesProps[key]);
              }
            }
          }
        });
        if (!exists) {
          updatedSlices.push(s);
        }
      } else {
        updatedSlices = scheduleSlices;
      }
    }
  });
  updatedSlices = refreshAll ? scheduleSlices : updatedSlices;
  return { contentUpdated, updatedSlices };
};

export const reorderColumns = async (
  slices: any[],
  currentView: string,
  prev: number,
  next: number
) => {
  const order: IOrderValue =
    store.getState().user.preferences.providersColumnsOrder?.parsedValue ||
    defaultProvidersColumnsOrder;

  const arr = _.cloneDeep(slices);
  const element = arr[prev];
  arr.splice(prev, 1);
  arr.splice(next, 0, element);
  const request: Partial<IOrderValue> = {};
  const ids = arr.map((v) => generateOrderSliceId(v));
  switch (currentView) {
    case 'day': {
      request.dayColumnsIds = ids;
      break;
    }
    case 'workweek': {
      request.weekClinicCodes = ids;
      break;
    }
    case 'weekprovider': {
      request.weekProvidersCodes = ids;
      break;
    }
  }
  const res = await onChangeProviderColumnsOrder({
    ...order,
    ...request
  });
  if (res) {
  }
};

export const findSlicesToUpdateFromPush = (
  existingSlices: any,
  updatedSlices: any
) => {
  let slices = _.uniqBy(Object.values(existingSlices), 'id');
  let slicesToUpdate: any = [];

  if (slices && slices.length > 0) {
    Object.values(updatedSlices).forEach((sliceFromPush: any) => {
      slices.forEach((sliceInStore: any) => {
        let sliceString = sliceFromPush.replace('*', '');
        let sliceInStoreId = sliceInStore.id.slice(
          0,
          sliceInStore.id.indexOf(':date#')
        );
        if (sliceInStoreId === sliceString) {
          slicesToUpdate.push(sliceInStore);
        }
      });
    });
  }
  return slicesToUpdate;
};

export const isCurrentDayOfWeek = (date: string): boolean => {
  return formattedDate(date) === formattedDate(new Date().toString());
};

interface IEventStatus {
  date: string;
  eventStatus: IPatientInfoData['patientStatus'];
}

export const getStatus = ({ date, eventStatus }: IEventStatus) => {
  let scheduleEventDateTime = date;
  let isLate = isEventLate({ scheduleEventDateTime });
  if (isLate && !eventStatus.checkedIn) {
    const delay = store.getState().user.preferences.appointmentLateDelay?.value;
    if (delay) {
      const newDate = moment(scheduleEventDateTime).add(delay, 'm').toDate();
      isLate = isEventLate({
        scheduleEventDateTime: newDate
      });
    }
  }
  if (eventStatus.discharged) {
    return 'discharged';
  }
  if (eventStatus.admit) {
    return 'admitted';
  }
  if (eventStatus.ready) {
    return 'ready';
  }
  if (eventStatus.checkedIn) {
    return 'arrived';
  }
  if (isLate) {
    return 'late';
  }
  return 'yet to come';
};

export const getStatusColor = (appointment: IAppointmentFull) => {
  const apptBackgroundColor =
    store.getState().user.preferences.apptBackgroundColor?.parsedValue ||
    defaultApptBackgroundSettings;

  let scheduleEventDateTime = `${moment(appointment.date).format(
    'YYYY-MM-DD'
  )}T${appointment.startTime}:00`;
  const status = getStatus({
    date: scheduleEventDateTime,
    eventStatus: appointment.patientInfo.patientStatus
  });
  if (status === 'yet to come') {
    return appointment.color;
  } else {
    return apptBackgroundColor.colors[status];
  }
};

export const getStatusColorForGroup = (appointment: IAppointmentFull) => {
  const apptBackgroundColor =
    store.getState().user.preferences.apptBackgroundColor?.parsedValue ||
    defaultApptBackgroundSettings;
  let scheduleEventDateTime = `${moment(appointment.date).format(
    'YYYY-MM-DD'
  )}T${appointment.startTime}:00`;
  const status = getGroupStatus({
    date: scheduleEventDateTime,
    patients: appointment.patients!
  });
  if (status === 'yet to come') {
    return appointment.color;
  } else {
    return apptBackgroundColor.colors[status];
  }
};

export const getApptColor = (appt: IAppointmentFull) => {
  const apptBackgroundColor =
    store.getState().user.preferences.apptBackgroundColor?.parsedValue ||
    defaultApptBackgroundSettings;
  const currentDate = moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00';
  const apptDate = moment(appt.date).format('YYYY-MM-DD') + 'T00:00:00';
  return appt.scheduleEventTypeId === 4
    ? globalStyleVariables.cancelledScheduleEventBG
    : apptBackgroundColor.radio === 'apptType' ||
      moment(apptDate).isAfter(currentDate)
    ? appt.color
      ? appt.color
      : appt.reason?.color
    : appt.patientInfo?.patientStatus
    ? getStatusColor(appt)
    : appt.groupAppointment
    ? getStatusColorForGroup(appt)
    : appt.color;
};

interface IGroupEventStatus {
  date: string;
  patients: IScheduleEvent['patients'];
}

interface IScheduleEventStatus {
  date: string;
  patients: IEventMemberStatus[];
}

export const getGroupStatus = ({ date, patients }: IGroupEventStatus) => {
  const patientsInfo = Object.values(patients);
  const patientsStatuses = patientsInfo.map((item) =>
    getStatus({ date: date, eventStatus: item.patientStatus })
  );
  if (patientsStatuses.some((item) => item === 'discharged')) {
    return 'discharged';
  }
  if (patientsStatuses.some((item) => item === 'admitted')) {
    return 'admitted';
  }
  if (patientsStatuses.some((item) => item === 'ready')) {
    return 'ready';
  }
  if (patientsStatuses.some((item) => item === 'arrived')) {
    return 'arrived';
  }
  if (patientsStatuses.some((item) => item === 'late')) {
    return 'late';
  }
  return 'yet to come';
};
export const getEventStatus = ({ date, patients }: IScheduleEventStatus) => {
  if (patients.some((item) => item.status === 'arrived')) {
    return 'arrived';
  }
  if (moment(date, 'YYYY-MM-DD').add(1, 'day').isBefore(moment())) {
    return 'late';
  }
  return 'yet to come';
};

interface IApptType {
  typeAppointmentParticipantId: number;
  isBlock: boolean;
  isOutreach: boolean;
  isEvent: boolean;
}

interface IIconType {
  name: IconNames;
  text: string;
}

export const getApptIcon = (apptInfo: IApptType) => {
  const { isBlock, isOutreach, isEvent, typeAppointmentParticipantId } =
    apptInfo;
  let iconType: IIconType = {
    name: 'user2',
    text: ''
  };
  if (isEvent) {
    iconType = {
      name: 'event',
      text: eventName
    };
  } else if (!isBlock) {
    if (isOutreach) {
      iconType = {
        name: 'event',
        text: 'Outreach event'
      };
    } else {
      switch (typeAppointmentParticipantId) {
        case 4:
          iconType = {
            name: 'groupList',
            text: 'Group appointment'
          };
          break;
        case 3:
          iconType = {
            name: 'family',
            text: 'Family appointment'
          };
          break;
        case 2:
          iconType = {
            name: 'usersGroup',
            text: 'Couple appointment'
          };
          break;
        default:
          iconType = {
            name: 'user2',
            text: 'Single appointment'
          };
          break;
      }
    }
  } else {
    iconType = {
      name: 'scheduleBlock',
      text: 'Block'
    };
  }
  return iconType;
};

export const convertClinics = (
  clinics: ClinicSimpleQueryResult[]
): ICurrentClinicOption[] => {
  return clinics.map((v) => ({
    ...v,
    dayEndTime: v.endTime,
    dayStartTime: v.startTime,
    start: moment(v.startTime).format('LT'),
    end: moment(v.endTime).format('LT'),
    visitduration: v.visitTimeDuration,
    visitDuration: v.visitTimeDuration,
    dayWeek: v.workingDays.map((val) => capitalizeFirstLetter(val)),
    value: v.code,
    label: clinicDisplayName(v)
  }));
};

export enum EEventTypeCode {
  appointment = 1,
  block = 2,
  reservation = 3,
  staffAppointment = 7,
  outreach = 8
}

export enum EApptTypeCode {
  individual = 1,
  couple = 2,
  family = 3,
  group = 4
}

export const eventPermission = (
  eventTypeCode: EEventTypeCode,
  apptTypeCode?: EApptTypeCode
) => {
  if (eventTypeCode === EEventTypeCode.appointment) {
    const apptAddModifyPermission = permissionChecking({
      anyClinic: true,
      moduleId: 1,
      functionId: 3
    });
    if (!apptAddModifyPermission.success) {
      return {
        ...apptAddModifyPermission,
        firstAvailableOption: 0
      };
    }
  }

  if (eventTypeCode === EEventTypeCode.appointment && !apptTypeCode) {
    const firstAvailableOption = Object.values(EApptTypeCode).reduce(
      (acc, item) => {
        if (!!acc) {
          return acc;
        } else {
          if (
            permissionChecking({
              anyClinic: true,
              moduleId: 1,
              functionId: (+item + 1399) as FuctionType
            }).success
          ) {
            return +item;
          }
        }
        return acc;
      },
      0
    );

    return {
      success: !!firstAvailableOption,
      message: '',
      firstAvailableOption
    };
  }

  let eventFunction: FuctionType;

  switch (eventTypeCode) {
    case EEventTypeCode.appointment:
      eventFunction = 1400;
      if (apptTypeCode === EApptTypeCode.couple) {
        eventFunction = 1401;
      }
      if (apptTypeCode === EApptTypeCode.family) {
        eventFunction = 1402;
      }
      if (apptTypeCode === EApptTypeCode.group) {
        eventFunction = 1403;
      }
      break;
    case EEventTypeCode.block:
      eventFunction = 1406;
      break;
    case EEventTypeCode.reservation:
      eventFunction = 1404;
      break;
    case EEventTypeCode.staffAppointment:
      eventFunction = 1405;
      break;
    default:
      eventFunction = 1407;
  }

  return {
    ...permissionChecking({
      anyClinic: true,
      moduleId: 1,
      functionId: eventFunction
    }),
    firstAvailableOption: 0
  };
};
