import * as actionTypes from "./TxnSetActions";
import _ from 'lodash';

export interface ITxnElement {
    id: number
    name: string
    typeTxnClass: string
    typeTxnClassID: number
}
interface IItem {
    [key: string]: ITxnElement
}
interface IStateReducer {
    [key: string]: IItem
}

const initialState: IStateReducer = {
    laboratory: {},
    radiology: {},
    pharmacy: {},
    recall: {},
    patient_Education: {},
    nurse: {},
    referral: {}
};


interface IAction {
    type: string;
    payload: any;
}

const TxnSetReducer = (
    state: IStateReducer = _.cloneDeep(initialState),
    action: IAction
): IStateReducer => {
    switch (action.type) {
        case actionTypes.SET_TXN_SETS: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
};
export default TxnSetReducer;