export enum SSOTypes {
    incommon = 'incommon',
    okta = 'okta',
    azureAd = 'azureAd',
    medicat = 'medicat',
    openIdConnect = 'openIdConnect'
}

export interface IStateReducer {
    response: any,
    permissions: Partial<INavBarPermissions>,
    currentClinic: any,
    errors: any,
    connection: any,
    noteTypes: any
}

interface INavBarPermissions {
    activity: boolean
    admin: boolean
    billing: boolean
    cats: boolean
    compliance: boolean
    dashboard: boolean
    messages: boolean
    orders: boolean
    reports: boolean
    schedule: boolean
    scpm: boolean
    subjects: boolean
    systemsetup: boolean
    usersettings: boolean
    patientheadersettings: boolean
}
export interface IAuthResponseDto {
    accessToken: string
    refreshToken: string
    duoPreauth?: {
        deviceId: string
        deviceName: string
    };
    message: string
    success: boolean
    userInfo?: {
        clinicId: number
        user?: {
            tenantId: string
        }
    }
}

export interface ILoginRequest {
    userName: string
    password: string
}

export interface IChallengeRequest {
    deviceId: string
}

export interface IAction {
    type: string;
    payload: any;
}

export interface VerifyTokenResponse {
    authorized: boolean;
    securityToken: string;
}

export interface IPasswordExpiredResponse {
    userName: string;
    email?: null | string;
    phone?: null | string;
    isEnable: boolean;
    expired: boolean;
    expirationDate: string;
    clientId: number;
    clientNumber: string;
    hasProfile: boolean;
    lastPasswordDateTime: string;
    objectSid: string;
}

export interface IGetForgotPasswordData {
    questionText: string;
    username: string;
    challengeQuestionSessionCode: string;
    objectSid: string;
    firstname: string;
    lastname: string;
    passwordPolicy?: (PasswordPolicyEntity)[] | null;
  }
  export interface PasswordPolicyEntity {
    clientId: number;
    passwordPolicyRuleId: number;
    clientNumber: string;
    name: string;
    description: string;
    allowedCharacters: string;
    regularExpression: string;
    isComplexity: boolean;
    isMandatory: boolean;
    passwordPolicyId: number;
    isActive: boolean;
    displayOrder: number;
  }
  
  export interface IVerifyAndGetChangePassworData {
    challengeQuestionSessionCode: string;
    objectSid: string;
    tenantId: string;
    passwordPolicyRules?: (PasswordPolicyRulesEntity)[] | null;
  }
  export interface PasswordPolicyRulesEntity {
    clientId: number;
    passwordPolicyRuleId: number;
    clientNumber: string;
    name: string;
    description: string;
    allowedCharacters: string;
    regularExpression: string;
    isComplexity: boolean;
    isMandatory: boolean;
    passwordPolicyId: number;
    isActive: boolean;
    displayOrder: number;
  }
  