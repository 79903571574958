const ids = {
  headersFootersHeaderRadioButton: 'headers-footers-header-radio-button',
  headersFootersFooterRadioButton: 'headers-footers-footer-radio-button',
  headersFootersName: 'headers-footers-name',
  headersFootersDescription: 'headers-footers-description',
  headersFootersPreviewButton: 'headers-footers-preview-button',
  headersFootersTagsButton: 'headers-footers-tags-button',
  buildingBlocksName: 'building-blocks-name',
  buildingBlocksDescription: 'building-blocks-description',
  buildingBlocksPreviewButton: 'building-blocks-preview-button',
  buildingBlocksTagsButton: 'building-blocks-tags-button',
  formEditorTagsButton: 'form-editor-tags-button',
  formEditorPreviewButton: 'form-editor-preview-button',
  formEditorHeaderFooterButton: 'form-editor-header-footer-button',
  formEditorClinicsButton: 'form-editor-clinics-button',
  formEditorAssignmentButton: 'form-editor-assignment-button',
  formEditorFooterSelector: 'form-editor-footer-selector',
  formEditorHeaderSelector: 'form-editor-header-selector',
  formEditorClinicSelector: 'form-editor-clinic-selector',
  formEditorAddButton: 'form-editor-add-button',
  formEditorSettingsButton: 'form-editor-settings-button',
  formEditorRemoveButton: 'form-editor-remove-button',
  formsName: 'forms-name',
  formsDescription: 'forms-description',
  formsPreviewButton: 'forms-preview-button',
  formsTagsButton: 'forms-tags-button'
};

export default ids;
