import React, { FC, useEffect, useMemo, useRef } from 'react';

import { useQuery } from 'src/Framework/util/helps';

import { updateCurrentActivity } from 'src/App/ActivityPanel/Store/ActivityActions';
import { setNewPatientForm } from 'src/Activities/Forms/FormList/PatientFormActions';
import { postTemplateFormRecord } from 'src/Activities/Forms/FormList/PatientFormActions';
import { IM1FormRecord } from 'src/App/M1FormRecords/store/types';
import { patch } from 'src/App/M1FormRecords/store/Actions/index';

import M1Form from './M1Form';
import FormList from './FormList';
import { filterFormList, sortBy } from './utils';
import { IFormItem } from './FormList/types';

import LegacyFormContainer from './LegacyForm/Container';
import { MainContainer, Left, Right } from './Form_Styles';

export enum Type {
  LEGACY = 'legacy',
  M1 = 'm1'
}

export interface ILegacyForm extends IFormItem {
  type: Type.LEGACY;
}

export interface IM1Form extends IM1FormRecord {
  type: Type.M1;
  createdDts?: string;
}

export type IFormData = ILegacyForm | IM1Form;

interface IProps {
  history?: any;
  match?: any;
  subjectId?: any;
  formsPatients: IFormData[];
  filter: any;
  sort: any;
  clinicTypes: number[];
  setFilters: (value: any, patientId: string) => any;
  setSort: (value: any, patientId: string) => any;
}

export const filterAndSort = (
  forms: IFormData[],
  filter: IProps['filter'],
  sort: IProps['sort']
) => {
  const filterData = filterFormList(filter, forms);
  const sortData = sortBy(sort, filterData);
  return sortData;
};

const Component: FC<IProps> = (props: IProps) => {
  const { match, formsPatients, filter, clinicTypes, setFilters, setSort } =
    props;
  const searchQuery = useQuery();
  const componentRef = useRef(null);
  const selectedId = match.params.formId;
  const formType = searchQuery.get('type') as Type | null;
  useEffect(() => {
    const formId = match.params.formId;
    if (!formId) {
      getFirstSeedId();
    }
    if (componentRef.current) {
      // @ts-ignore
      componentRef.current.focus();
    }
  }, []);

  const list = useMemo(() => {
    const list = formsPatients.filter((v) => {
      if (!filter.showInvalid) {
        if (v.type === Type.LEGACY) {
          if (v.isActive) {
            return !v.invalid;
          }
          return false;
        }
        if (v.type === Type.M1) {
          return v.isActive;
        }
      }
      if (v.type === Type.LEGACY) {
        return v.isActive;
      }
      return true;
    });
    return filterAndSort(list, filter, props.sort);
  }, [formsPatients, filter, props.sort]);

  const changeSelectedSeedId = (id: string | number | null, type: Type) => {
    if (id) {
      const formId = match.params.formId;
      const url = match.url.split('/');
      url.splice(url.length - 1, 1);
      const linkUrl = formId ? url.join('/') : match.url;
      const link = `${linkUrl}/${id}?type=${type}`;
      updateCurrentActivity({
        targetURL: link
      });
    }
  };

  const changeSelectedForm = (form: IFormData) => {
    if (form.type === Type.LEGACY) {
      changeSelectedSeedId(form.seedId, Type.LEGACY);
    }
    if (form.type === Type.M1) {
      changeSelectedSeedId(form.id, Type.M1);
    }
  };

  const selectionChangedHandler = (item: IFormData | null) => {
    if (item) {
      changeSelectedForm(item);
    } else {
      getFirstSeedId();
    }
  };

  const onRemove = () => {
    getFirstSeedId();
  };

  const addForm = async (formTemplate: IFormData) => {
    if (formTemplate.type === Type.LEGACY) {
      const seedIds = [formTemplate.seedId];
      const patientId = +props.subjectId;
      const res = await postTemplateFormRecord({
        patientId,
        seedIds
      });
      if (res) {
        const value: any = Object.values(res)[0];
        if (value) {
          setNewPatientForm(value, patientId);
          changeSelectedForm({ ...value, type: Type.LEGACY });
        }
      }
    } else if (formTemplate.type === Type.M1) {
      changeSelectedForm(formTemplate);
    }
  };

  const getFirstSeedId = () => {
    const firstSeedId = list[0];
    if (firstSeedId) {
      changeSelectedForm(firstSeedId);
    }
  };

  const formSelected =
    selectedId && formType
      ? list.find((v) => {
          if (formType === Type.LEGACY && v.type === Type.LEGACY) {
            return v.seedId == +selectedId;
          }
          if (formType === Type.M1 && v.type === Type.M1) {
            return v.id == +selectedId;
          }
          return false;
        })
      : undefined;
  return (
    <MainContainer ref={componentRef} tabIndex={0}>
      <Left>
        <FormList
          match={props.match}
          selectionChanged={selectionChangedHandler}
          patientId={props.subjectId}
          formSelected={formSelected}
          formList={list}
          filter={props.filter}
          sort={props.sort}
          setSort={setSort}
          setFilters={setFilters}
          addForm={addForm}
          clinicTypes={clinicTypes}
        />
      </Left>
      <Right>
        {formSelected?.type === Type.M1 && (
          <M1Form
            key={formSelected.id}
            form={formSelected}
            subjectId={props.subjectId}
            clinicTypes={clinicTypes}
            afterRemove={onRemove}
            m1FormPatchRequest={patch}
          />
        )}
        {formSelected?.type === Type.LEGACY && (
          <LegacyFormContainer
            key={`${formSelected.seedId}-${formSelected.status}`}
            formSelected={formSelected}
            match={props.match}
            onRemove={onRemove}
          />
        )}
      </Right>
    </MainContainer>
  );
};

export default React.memo(Component);
