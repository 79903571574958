import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import { IProps } from './index';

import suneditor from 'suneditor';
import plugins from 'suneditor/src/plugins';
import SunEditor from 'suneditor/src/lib/core';
import { HtmlEditonUtils } from './utils';
import { v4 } from 'uuid';

const initEditor = suneditor.init({
  plugins: plugins,
  height: '400px',
  width: '100%',
  defaultStyle: 'font-family: Arial;',
  resizingBar: false,
  buttonList: [
    [
      'font',
      'fontSize',
      'formatBlock',
      'bold',
      'underline',
      'italic',
      'strike',
      'subscript',
      'superscript',
      'fontColor',
      'hiliteColor',
      'removeFormat',
      'outdent',
      'indent',
      'align',
      'horizontalRule',
      'list',
      'lineHeight',
      'table',
      'link',
      'image',
      'showBlocks',
      'codeView',
      'preview'
    ]
  ]
});

const defaultString = '<p></p>';

export const nToBr = (text: string) => {
  return text.replace(/\n/g, '<br />');
};

const Editor: React.ForwardRefRenderFunction<any, IProps> = (
  { readOnly, onChange, value, getWysiwygRef, readMode }: IProps,
  ref: any
) => {
  // This variable needed to prevent onChange event to trigger when we have outside changes
  const changedOutside = useRef(false);
  const id = useRef(v4());
  const mainWysiwygRef = useRef<SunEditor | null>(null);
  const containerRef = useRef<HTMLTextAreaElement>(null);
  const onTextChangeRef = useRef(onChange);
  const onDefaultValue = useRef(value);

  useLayoutEffect(() => {
    onTextChangeRef.current = onChange;
    onDefaultValue.current = value;
  });

  useEffect(() => {
    if (mainWysiwygRef?.current) {
      if (readOnly) {
        mainWysiwygRef.current?.readOnly(true);
      } else {
        mainWysiwygRef.current?.readOnly(false);
      }
    }
  }, [readOnly]);

  useEffect(() => {
    const newValue = nToBr(value || defaultString);
    const innerHTML = mainWysiwygRef.current?.getContents(true);
    if (innerHTML && innerHTML !== newValue) {
      changedOutside.current = true;
      mainWysiwygRef.current?.setContents(newValue);
    }
  }, [value]);

  useEffect(() => {
    const editor = initEditor.create(containerRef.current!, {});

    editor.setContents(nToBr(value || defaultString));

    mainWysiwygRef.current = editor;

    if (getWysiwygRef) {
      getWysiwygRef(editor);
    }

    if (readOnly) {
      editor.readOnly(true);
    }
    editor.onChange = (contents) => {
      if (changedOutside.current) {
        changedOutside.current = false;
        return;
      }
      console.log('On text changed', { contents });
      if (onTextChangeRef.current) {
        onTextChangeRef.current(contents);
      }
    };
    editor.onKeyUp = (e) => {
      const { html } = HtmlEditonUtils.autoReplace({
        editor,
        event: e as KeyboardEvent
      });
      if (html) {
        if (onDefaultValue.current !== html) {
          if (onTextChangeRef.current) {
            onTextChangeRef.current(html);
          }
        }
      }
    };
    editor.onKeyDown = (e) => {
      const { html } = HtmlEditonUtils.autoReplaceOnKeyDown({
        editor,
        event: e as KeyboardEvent
      });
      if (html) {
        if (onDefaultValue.current !== html) {
          if (onTextChangeRef.current) {
            onTextChangeRef.current(html);
          }
        }
      }
    };
    return () => {
      editor.destroy();
    };
  }, []);
  return <textarea id={id.current} ref={containerRef}></textarea>;
};

export default forwardRef(Editor);
