import React, { FC } from 'react';

import ButtonIcon, {
  ICONS_LIST,
  IIcon
} from 'src/Framework/Controls/ButtonIcon';

export interface IProps {
  onClick?: Function;
  buttonProps?: Partial<IIcon>;
}

const EditButton: FC<IProps> = ({ onClick, buttonProps }: IProps) => {
  return (
    <ButtonIcon
      haspopup="dialog"
      iconScale={2.2}
      name={ICONS_LIST.edit}
      onClick={(e: any) => {
        if (onClick) {
          onClick(e);
        }
      }}
      changeColorOnHover={true}
      {...buttonProps}
      containerProps={{
        'aria-label': 'Edit button',
        ...buttonProps?.containerProps
      }}
      label={buttonProps?.label || 'Modify'}
      tooltip={buttonProps?.tooltip || 'Modify'}
      isButton={buttonProps?.isButton || true}
    />
  );
};

export default React.memo(EditButton);
