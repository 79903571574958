interface IAction {
  type: string;
  payload: any;
}

interface IActor {
  id: number | string;
  actorType?: string;
  type?: string;
  displayName?: string;
}

export interface IMessage {
  id?: number;
  actors: {
    sender: IActor;
    receivers: Array<IActor>;
  };
  text: string;
  followupDate?: string | null;
  dts: string | null;
  clinicId?: string | null;
  priority?: string;
  readReceipt: boolean;
  statusId?: number;
  conversationId?: null | string;
  subject: string;
  type: string;
  userId?: string;
  folderIds?: Array<string>;
  attachmentIds?: Array<any>;
  hasFollowup?: boolean;
  attachments?: any;
  followups?: any;
  forms?: any;
  studentIds?: any;
  students?: Array<any>;
  formRecordSeedIds?: Array<number>;
  labels?: {
    labelId: number,
    linkedEntityType: string,
    linkedEntityId: number
  }[]
}

export interface IParams {
  message: IMessage;
  followUp: boolean;
  loading: boolean;
  familyVisible: boolean;
  selectFormVisible: boolean;
  attachments: Array<any>;
  type?: string;
}

export interface IStateReducer {
  internal: IParams;
  secure: IParams;
  newInternalMessageVisible: boolean;
  newSecureMessageVisible: boolean;
  clinicProviders: Array<any>;
  labels: any[];
}

const prefix = 'CREATE_MESSAGE_';

export const actionsTypes = {
  SET_INTERNAL: `${prefix}SET_INTERNAL`,
  SET_SECURE: `${prefix}SET_SECURE`,
  SET_INTERNAL_DEFAULT: `${prefix}SET_INTERNAL_DEFAULT`,
  SET_SECURE_DEFAULT: `${prefix}SET_SECURE_DEFAULT`,
  SET_INTERNAL_MESSAGE_VISIBLE: `${prefix}SET_INTERNAL_MESSAGE_VISIBLE`,
  SET_SECURE_MESSAGE_VISIBLE: `${prefix}SET_SECURE_MESSAGE_VISIBLE`,
  SET_CLINIC_PROVIDERS: `${prefix}SET_CLINIC_PROVIDERS`,
  SET_LABELS: `${prefix}SET_LABELS`
};

export const getInitialParams = (): IParams => {
  return {
    message: getInitialMessage(),
    type: '',
    attachments: [],
    familyVisible: false,
    followUp: false,
    loading: false,
    selectFormVisible: false
  };
};

export const getInitialMessage = () => {
  return {
    actors: {
      sender: {
        actorType: 'user',
        id: ''
      },
      receivers: []
    },
    clinicId: null,
    dts: '',
    followupDate: null,
    text: '',
    priority: 'normal',
    readReceipt: false,
    statusId: undefined,
    subject: '',
    type: 'internal',
    conversationId: null,
    students: [],
    labels: []
  };
};

const defaultParams = () => ({
  message: getInitialMessage(),
  followUp: false,
  loading: false,
  familyVisible: false,
  selectFormVisible: false,
  attachments: []
});

const initialState: IStateReducer = {
  internal: defaultParams(),
  secure: defaultParams(),
  newInternalMessageVisible: false,
  newSecureMessageVisible: false,
  clinicProviders: [],
  labels: []
};

const Reducer = (
  state: IStateReducer = { ...initialState },
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET_LABELS:
      return {
        ...state,
        labels: action.payload
      };
    case actionsTypes.SET_CLINIC_PROVIDERS:
      return {
        ...state,
        clinicProviders: action.payload
      };
    case actionsTypes.SET_SECURE_MESSAGE_VISIBLE:
      return {
        ...state,
        newSecureMessageVisible: action.payload
      };
    case actionsTypes.SET_INTERNAL_MESSAGE_VISIBLE:
      return {
        ...state,
        newInternalMessageVisible: action.payload
      };
    case actionsTypes.SET_INTERNAL:
      return {
        ...state,
        internal: { ...state.internal, ...action.payload }
      };
    case actionsTypes.SET_SECURE:
      return {
        ...state,
        secure: { ...state.secure, ...action.payload }
      };
    case actionsTypes.SET_INTERNAL_DEFAULT:
      return {
        ...state,
        internal: defaultParams()
      };
    case actionsTypes.SET_SECURE_DEFAULT:
      return {
        ...state,
        secure: defaultParams()
      };
    default:
      return state;
  }
};

export default Reducer;
