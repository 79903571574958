import store from "src/store"
import { actionsTypes } from ".."
import { MarketplaceBBSimpleDto, MarketplaceSimpleDto } from "../types"

export const setSummary = (payload: MarketplaceSimpleDto[]) => {
    store.dispatch({
        type: actionsTypes.SET_SUMMARY,
        payload
    })
}

export const insertImported = (payload: MarketplaceSimpleDto) => {
    const data = [...store.getState().adminPanel.marketplace.summary]
    const i = data.findIndex((v) => v.id === payload.importedFormId)
    if (i !== -1) {
        data[i] = payload
    }
    setSummary(data)
}

export const insertUpdateVersion = (payload: MarketplaceSimpleDto) => {
    const data = [...store.getState().adminPanel.marketplace.summary]
    const i = data.findIndex((v) => v.importedFormId === payload.importedFormId)
    if (i !== -1) {
        data[i] = payload
    }
    setSummary(data)
}

export const setBBSummary = (payload: MarketplaceBBSimpleDto[]) => {
    store.dispatch({
        type: actionsTypes.SET_BB_SUMMARY,
        payload
    })
}

export const insertBBImported = (payload: MarketplaceBBSimpleDto) => {
    const data = [...store.getState().adminPanel.marketplace.buldingBlocksSummary]
    const i = data.findIndex((v) => v.id === payload.importedEntityId)
    if (i !== -1) {
        data[i] = payload
    }
    setBBSummary(data)
}

export const insertBBUpdateVersion = (payload: MarketplaceBBSimpleDto) => {
    const data = [...store.getState().adminPanel.marketplace.buldingBlocksSummary]
    const i = data.findIndex((v) => v.importedEntityId === payload.importedEntityId)
    if (i !== -1) {
        data[i] = payload
    }
    setBBSummary(data)
}