
import * as actionType from "../../RuleActions/ActionTypes";
import _ from 'lodash';

interface IAction {
    type: string;
    ruleId: string;
    componentId: number;
    procedures: any[];
    pagination: any;
    id: string;
}
export interface IProceduresStore {
    ruleId: string;
    componentId: number;
    pagination: any
    procedures: any[];
}



const ProcedureOptionReducer = (state: IProceduresStore[] = [], action: IAction) => {
    switch (action.type) {
        case actionType.ADD_PROCEDURES: {
            let newState = _.cloneDeep(state)
            let procedureStore = newState.find((i: IProceduresStore) => i.ruleId === action.ruleId && i.componentId === action.componentId);
            if (procedureStore) {
                if (action.pagination.current > 1) {
                    procedureStore.procedures = [...procedureStore.procedures, ...action.procedures];
                }
                else {
                    procedureStore.procedures = action.procedures;
                }
                procedureStore.pagination = action.pagination;
            } else {
                let data: IProceduresStore = {
                    ruleId: action.ruleId,
                    componentId: action.componentId,
                    procedures: action.procedures,
                    pagination: action.pagination,
                }
                newState.push(data);
            }
            return newState;
        }
        case actionType.CLEAR_RULE_EDITOR: {
            let newState = _.cloneDeep(state)
            let iArray = newState.filter((i: IProceduresStore) => i.ruleId === action.id);
            if (iArray.length === 0) return newState;
            for (let i = 0; i < iArray.length; i++) {
                newState.splice(newState.indexOf(iArray[i]), 0);
            }
            return newState;
        }

        default:
            return state;
    }
};

export default ProcedureOptionReducer;
