import React, { useImperativeHandle, useState } from 'react';
import { Button } from 'antd';

import Modal from 'src/Framework/Controls/Modal';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import test_ids from 'src/tests-script/pages/Subject/Forms';

import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import { ModalContainer } from './styled';

interface IProps {
  summary: string;
  onAdded: (text: string) => Promise<any | undefined>;
}

export interface IModalHandles {
  show(): void;

  close(): void;
}

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { summary, onAdded },
  ref
) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState('');
  useModalFocus({ active: visible });
  const show = () => {
    setData(summary);
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setData('');
  };

  useImperativeHandle(ref, () => ({
    show,
    close
  }));

  const apply = async () => {
    setLoading(true);
    await onAdded(data);
    setLoading(false);
    close();
  };

  const checkDisabled = () => {
    return false;
  };

  const title = 'Form Summary';
  const defaultWidth = 500;
  const headerRight = (
    <Button
      id={test_ids.formsSummarySave}
      disabled={checkDisabled()}
      loading={loading}
      onClick={apply}
      shape="round"
    >
      Save
    </Button>
  );

  return (
    <Modal
      id={test_ids.formsSummaryModal}
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title
      }}
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      afterClose={setToDefault}
      headerBorder={true}
      buttons={headerRight}
    >
      <ModalContainer className="custom-checkbox">
        <SelectContainer
          type="area"
          id={test_ids.formsSummaryArea}
          label="Summary"
          star={false}
          value={data}
          onChange={(newData: string) => setData(newData)}
          bottomMargin={false}
          areaAutoReplace={false}
          inputProps={{
            rows: 6,
            maxLength: 256
          }}
        />
      </ModalContainer>
    </Modal>
  );
};

export default React.forwardRef(Component);
