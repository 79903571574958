import React, { FC } from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/es/input-number';

import { ICommon } from '../index';

export interface IInputNumber extends ICommon {
  type: 'number';
  inputProps?: Partial<InputNumberProps>;
  value?: number | undefined | null;
  onChange?: (value: number) => any;
}

const Component: FC<IInputNumber> = (props: IInputNumber) => {
  const { inputProps, value, onChange, id } = props;
  const areaLabel = typeof props.label === 'string' ? props.label : '';
  return (
    <>
      <label
        htmlFor={inputProps?.id ? inputProps?.id : id}
        className="visually-hidden"
      >
        {props.label}
      </label>
      <InputNumber
        aria-required={props.star}
        aria-label={areaLabel}
        id={id}
        {...inputProps}
        value={value ? value : 0}
        onChange={(val) => onChange && onChange(val ? +val : 0)}
      />
    </>
  );
};

export default Component;
