import { ISubjectsSummary } from "../Modal/Subjects";
import * as actionTypes from "./ComplianceActions";
import _ from 'lodash';
import { IFactsSummary } from '../Modal/Facts';
import { IMessagesSummary } from '../Modal/Messages';

const initialState: any = {
  pagination: {
    subjects: {},
    facts: {},
    messages: {},
    factsPatients: {},
  },
  subjects: [],
  facts: [],
  messages: [],
  factsPatients: [],
  counts: {
    unverifiedCountFacts: null,
    flaggedCountFacts: null,
    unproccesDocumentsCount: null,
    flaggedCountDocuments: null,
  },
  messageProviderForClinic: [],
  messageSelectedProviders: [],
};

interface IAction {
  type: string;
  pagination: any;
  subjectsSummary: ISubjectsSummary[];
  factsSummary: IFactsSummary[];
  messagesSummary: IMessagesSummary[];
  ruleId: string;
  isEnabled: boolean;
  ruleName: string;
  description: string;
  unverifiedCount: any;
  flaggedCount: any;
  payload: any;
}

const ComplianceReducer = (
  state: any = { ...initialState },
  action: IAction
) => {
  switch (action.type) {
    case actionTypes.ADD_SUBJECTS: {
      let newState = _.cloneDeep(state)
      if (action.pagination.current > 1) {
        let isExist: boolean = isExistSubject(newState.subjects, action.subjectsSummary)
        if (isExist) return state;
        newState.subjects = [...newState.subjects, ...action.subjectsSummary];
      } else {
        newState.subjects = action.subjectsSummary;
      }
      newState.pagination.subjects = action.pagination;
      return newState;
    }
    case actionTypes.ADD_FACTS: {
      let newState = _.cloneDeep(state)
      if (action.pagination.current > 1) {
        let isExist: boolean = isExistDocument(newState.facts, action.factsSummary)
        if (isExist) return state;
        newState.facts = [...newState.facts, ...action.factsSummary];
        newState.counts.unproccesDocumentsCount = action.unverifiedCount;
        newState.counts.flaggedCountDocuments = action.flaggedCount;
      } else {
        newState.facts = action.factsSummary;
        newState.counts.unproccesDocumentsCount = action.unverifiedCount;
        newState.counts.flaggedCountDocuments = action.flaggedCount;
      }
      newState.pagination.facts = action.pagination;
      return newState;
    }

    case actionTypes.ADD_FACTS_PATIENT_DETAIL: {
      let newState = _.cloneDeep(state)
      if (action.pagination.current > 1) {
        let isExist: boolean = isExistFactPatient(newState.factsPatients, action.factsSummary)
        if (isExist) return state;
        newState.factsPatients = [...newState.factsPatients, ...action.factsSummary];
        newState.counts.unverifiedCountFacts = action.unverifiedCount;
        newState.counts.flaggedCountFacts = action.flaggedCount;
      } else {
        newState.factsPatients = action.factsSummary;
        newState.counts.unverifiedCountFacts = action.unverifiedCount;
        newState.counts.flaggedCountFacts = action.flaggedCount;
      }
      newState.pagination.factsPatients = action.pagination;
      return newState;
    }
    case actionTypes.ADD_MESSAGES: {
      let newState = _.cloneDeep(state)
      if (action.pagination.current > 1) {
        let isExist: boolean = isExistMessage(newState.messages, action.messagesSummary)
        if (isExist) return state;
        newState.messages = [...newState.messages, ...action.messagesSummary];
      } else {
        newState.messages = action.messagesSummary;
      }
      newState.pagination.messages = action.pagination;
      return newState;
    }
    case actionTypes.CLEAR_DASHBOARD: {
      let newState = _.cloneDeep(state)
      newState = { ...initialState }
      return newState;
    }

    case actionTypes.GET_MESSAGE_PROVIDER_FOR_CLINIC: {
      let newState = _.cloneDeep(state)
      newState.messageProviderForClinic = action.payload;
      return newState;
    }

    case actionTypes.SET_MESSAGE_SELECTED_PROVIDERS: {
      let newState = _.cloneDeep(state)
      newState.messageSelectedProviders = action.payload;
      return newState;
    }

    default:
      return state;
  }
};
export default ComplianceReducer;

// to check whether a subject already exist in the store
function isExistSubject(stateSubjects: any[], actionSubjects: any[]): boolean {
  if (actionSubjects && actionSubjects.length > 0) {
    let action_subject: any = actionSubjects[0];
    let isExist: any = stateSubjects.find((subject: any) => {
      return subject.subjectId === action_subject.subjectId;
    });
    if (isExist) return true;
  }
  return false;
}

// to check whether a document already exist in the store
function isExistDocument(stateDocuments: any[], actionDocuments: any[]): boolean {
  if (actionDocuments && actionDocuments.length > 0) {
    let action_document: any = actionDocuments[0];
    let isExist: any = stateDocuments.find((document: any) => {
      return document.documentId === action_document.documentId;
    });
    if (isExist) return true;
  }
  return false;
}

function isExistFactPatient(stateFacts: any[], actionFacts: any[]): boolean {
  if (actionFacts && actionFacts.length > 0) {
    let action_fact: any = actionFacts[0];
    let isExist: any = stateFacts.find((fact: any) => {
      return fact.fact.factId === action_fact.fact.factId;
    });
    if (isExist) return true;
  }
  return false;
}

// to check whether a message already exist in the store
function isExistMessage(stateMessages: any[], actionMessagesSummary: any[]): boolean {
  if (actionMessagesSummary && actionMessagesSummary.length > 0) {
    let action_message: any = actionMessagesSummary[0];
    let isExist: any = stateMessages.find((message: any) => {
      return message.messageDetailId === action_message.messageDetailId;
    });
    if (isExist) return true;
  }
  return false;
}