import { BucketsList } from 'src/Framework/ApiSubscription';
import { RequestHelper } from 'src/Framework/ApiSubscription/RequestHelper';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { IProviderAssesment } from '../types';

import { setSummary, onCreateUpdateItem } from './Setters'

export const SubscriptionData = (providerId: string | number) => ({
    bucket: BucketsList.providerAssessment,
    filter: `${BucketsList.providerAssessment}.ProviderId == ${providerId}`
})

export const { post, get, patch, unsubscribe } = new RequestHelper<IProviderAssesment, [providerId: string | number], [providerId: string | number]>({
    link: ApiUrl.ProviderAssessments,
    onCreateUpdateItem,
    subscription: {
        subscriptionData: SubscriptionData,
        setSummary
    }
})
