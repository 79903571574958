import store from 'src/store';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import _ from 'lodash';

import { IFileDefinition } from './Reducer';
import { IFileSimpleDto } from 'src/Framework/Controls/FileUploader';

export const actionsTypes = {
  SET_PATIENT_ATTACHMENTS: 'SET_PATIENT_ATTACHMENTS',
  SET_PATIENT_ATTACHMENTS_SORT: 'SET_PATIENT_ATTACHMENTS_SORT'
};

export const onChangeSort = (patientId: string, payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_PATIENT_ATTACHMENTS_SORT,
    payload,
    patientId
  });
};

export const setAvailableAttachmentsData = (patientId: string, data: any) => {
  store.dispatch({
    type: actionsTypes.SET_PATIENT_ATTACHMENTS,
    payload: data, // data.filter((v: any) => v.isActive),
    patientId
  });
};

export const getPatientAttachments = async (patientId: string) => {
  try {
    const params: any = {
      patientId
    };
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_PATIENT_ATTACHMENTS}`,
      {
        withCredentials: true,
        params
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      setAvailableAttachmentsData(patientId, data);
      return data;
    }
    return;
  } catch (e) {
    return;
  }
};

export const getChartNoteAttachmentsData = async (
  patientId: string | number,
  noteId: string | number
): Promise<IFileDefinition[] | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_PATIENT_ATTACHMENTS}`,
      {
        withCredentials: true,
        params: {
          patientId
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      return data.filter(
        (attachment: any) => Number(attachment.noteId) === Number(noteId)
      );
    }
    return;
  } catch (e) {
    return;
  }
};

export const getNoteAttachmentsData = async (patientId: any, noteId: any) => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_PATIENT_ATTACHMENTS}`,
      {
        withCredentials: true,
        params: {
          patientId
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      return data.filter(
        (attachment: any) => attachment.noteSeedId === Number(noteId)
      );
    }
    return;
  } catch (e) {
    return;
  }
};

const onChangeItem = (patientId: string, payload: any) => {
  const data = [...store.getState().patientsAttachments[patientId].attachments];
  const index = data.findIndex(
    (v) => v.noteId === payload.noteId && v.file.id === payload.file.id
  );
  if (index !== -1) {
    data[index] = payload;
  } else {
    data.push(payload);
  }
  setAvailableAttachmentsData(patientId, data);
};

const onDeleteItem = (patientId: string, index: number) => {
  const data = _.orderBy(
    [...store.getState().patientsAttachments[patientId].attachments],
    store.getState().patientsAttachments[patientId].sort.sortBy || undefined,
    store.getState().patientsAttachments[patientId].sort.sortType || undefined
  );
  data[index].noteId = null;
  setAvailableAttachmentsData(patientId, data);
};

export const patch = async (data: any) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.UPDATE_ATTACHMENTS,
      data,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const item = response.data.result.summary[data.id];
      return item;
    }
    return;
  } catch (e) {
    return;
  }
};

export const linkAttachmentToNote = async (
  patientId: any,
  id: any,
  noteId: any,
  title?: string
) => {
  try {
    const body: any = {};
    if (title) {
      body.title = title;
    }
    const response = await APIHandler.AxiosInstance.put(
      `${ApiUrl.UPDATE_PATIENT_ATTACHMENT}/${id}/soapNotes/${noteId}`,
      body,
      { withCredentials: true }
    );
    if (response.data.success) {
      const result = response.data.result.summary[0];
      onChangeItem(patientId, result);
      return result;
    }
  } catch (e) {
    console.log({ e });
    return;
  }
};

export const unlinkAttachmentFromNote = async (
  patientId: any,
  id: any,
  noteId: any,
  index: number
) => {
  try {
    const response = await APIHandler.AxiosInstance.delete(
      `${ApiUrl.UPDATE_PATIENT_ATTACHMENT}/${id}/soapNotes/${noteId}`,
      { withCredentials: true }
    );
    if (response.data.success) {
      onDeleteItem(patientId, index);
    }
  } catch (e) {
    return;
  }
};

export const downloadAttachment = (fileId: any) => {
  return APIHandler.AxiosInstance.get(
    `${ApiUrl.FILES_MULTIPART_UPLOAD}/${fileId}`,
    {
      withCredentials: true,
      responseType: 'blob'
    }
  );
};

const fileCache: {
  [id: string]: IFileSimpleDto
} = {
}

export const getAttachmentById = async (
  fileId: string | number,
  useCache?: boolean
): Promise<IFileSimpleDto | undefined> => {
  try {
    if (useCache) {
      if (fileCache[fileId]) {
        return fileCache[fileId]
      }
    }
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.FILES_MULTIPART_UPLOAD}`,
      {
        withCredentials: true,
        params: {
          id: fileId
        }
      }
    );
    if (response.data.success) {
      const file = Object.values(response.data.result.summary)[0] as IFileSimpleDto;
      if (useCache) {
        fileCache[fileId] = file
      }
      return file
    }
    return;
  } catch (err) {
    return;
  }
};
