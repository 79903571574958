import styled from 'styled-components';

interface IContainer {
  marginBottom?: boolean
}

export const Container = styled.div<IContainer>`
  position: relative;
  ${({ marginBottom }) => marginBottom && `
    margin-bottom: 25px;
  `}

  .ql-container {
    height: 300px;
  }

  .ql-tooltip {
    left: 20px !important;
  }
  .ql-snow .ql-formats .ql-font .ql-picker-options .ql-picker-item {
  font-family: #{attr(data-value)};
    &::before {
      content: attr(data-value) !important;
    }
  }

  .ql-snow .ql-formats .ql-font .ql-picker.ql-font .ql-picker-label::before,
  .ql-snow .ql-formats .ql-font .ql-picker.ql-font .ql-picker-item::before {
    content: attr(data-value) !important;
  }

`;
