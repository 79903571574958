import React, { FC } from 'react';
import { Tooltip } from 'antd';

import { noData, prefix } from '.';

interface IProps {
  list: any;
  label: any;
  allInfoExpanded: boolean;
  name: string;
}

export const Component: FC<IProps> = ({
  list,
  label,
  allInfoExpanded,
  name
}: IProps) => {
  const joinList = list.length > 0 ? list : noData;
  return (
    <div className="with__tooltip" id={`${prefix}${name}`}>
      {list.length === 0 ? (
        <p>
          <span className="list-empty">{noData}</span>
        </p>
      ) : (
        list.map((v: any, index: number) => (
          <p
            key={v}
            style={{
              display: index === 0 || allInfoExpanded ? 'block' : 'none'
            }}
          >
            <span
              className="list-item"
              {...{
                'item-value': `${v}`
              }}
            >
              {v}
            </span>
            {!allInfoExpanded && index === 0 && list.length > 1 && (
              <Tooltip
                placement="bottom"
                title={
                  <>
                    {joinList.map((v: any) => (
                      <div key={v}>{v}</div>
                    ))}
                  </>
                }
              >
                <span className="tooltip-values">{list.length - 1}+</span>
              </Tooltip>
            )}
          </p>
        ))
      )}
    </div>
  );
};

export default Component;
