import _ from 'lodash';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

const ActionManager = {
    ADD_COHORT_COMPLIANCES_RULES: "ADD_COHORT_COMPLIANCES_RULES",
}

interface IStore {
    pagination: any,
    rules: any[],
}

interface IAction {
    type: string;
    payload: any;
}

const initialState: IStore = {
    pagination: null,
    rules: [],
}

const CohortComplianceRulesReducer = (
    state: IStore = initialState,
    action: IAction
) => {
    switch (action.type) {
        case ActionManager.ADD_COHORT_COMPLIANCES_RULES: {
            let newState = _.cloneDeep(state)
            if (action.payload.pagination.current > 1) {
                let isExistSameRule: boolean = isExistRule(newState.rules, action.payload.rules);
                if (isExistSameRule) return state;
                newState.rules = [...newState.rules, ...action.payload.rules];
            } else {
                newState.rules = action.payload.rules
            }
            newState.pagination = action.payload.pagination;
            return newState;
        }
        default: {
            return state;
        }

    }
};
export default CohortComplianceRulesReducer;

export const addPublishedRules = (payload: any) => {
    return {
        type: ActionManager.ADD_COHORT_COMPLIANCES_RULES,
        payload: payload
    }
};
export const getPublishedRules = (data: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_RULES, data, { withCredentials: true });
};

//to check whether a rule already exist in the store
function isExistRule(stateRules: any, actionRules: any): boolean {
    if (actionRules && actionRules.length > 0) {
        let action_rule: any = actionRules[0];
        let isExistRule: any = stateRules.find((rule: any) => {
            return rule.ruleId === action_rule.ruleId;
        });
        if (isExistRule) return true;
    }
    return false;
}
