import React, { FC, useEffect, useState } from 'react';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import {
  getDocumentLink,
  catchDocumentUrls
} from 'src/Framework/Controls/FilesDownloader';

import { Container, ErrorContainer } from './styled';
import _ from 'lodash';

interface IProps {
  file: {
    id: number;
    crc?: string;
  };
  height?: number | string;
  width?: number | string;
  circle?: boolean;
}

const Component: FC<IProps> = ({ file, height, width, circle }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [url, setUrl] = useState(catchDocumentUrls[`${file.id}-${file.crc}`]);
  const getImage = async () => {
    setLoading(true);
    setError(false);
    const res = await getDocumentLink(file);
    if (res) {
      setUrl(res);
    } else {
      setError(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (!url) {
      getImage();
    }
  }, []);
  useEffect(() => {
    getImage();
  }, [file]);
  return (
    <Container
      style={{
        width,
        height,
        borderRadius: circle ? '50%' : 2
      }}
    >
      {loading && <AbsoluteLoader />}
      {error && (
        <ErrorContainer>
          <span>
            Loading error <b onClick={getImage}>try again</b>
          </span>
        </ErrorContainer>
      )}
      {url ? (
        <div
          style={{
            width,
            height,
            backgroundImage: `url(${url})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
          }}
        />
      ) : null}
    </Container>
  );
};

Component.defaultProps = {
  height: 260,
  width: 425
};

export default React.memo(Component, (prev, next) => _.isEqual(prev, next));
