import React, { FC, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import NotFound from 'src/App/404';
import AppErrorBoundary from 'src/Framework/Errors/AppErrorBoundary';

import IconsPage from 'src/App/IconsPage';

import { MainContainer } from './styled';
import { ROUTES } from './routes';

import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import { FocusOverlayInstance } from 'src';
import * as actions from 'src/App/LoginPage/store/AccessToken';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import history from '../history';

interface IProps {}

const Component: FC<IProps> = ({}: IProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = () => {
      const tokens = actions.get();
      if (!tokens.accessToken) {
        history.push(ROUTES.Login);
      }
      setLoading(false);
    };
    init();
    FocusOverlayInstance?.destroy();
  }, []);
  return (
    <AppErrorBoundary>
      <MainContainer>
        {loading ? (
          <AbsoluteLoader />
        ) : (
          <Switch>
            <Route
              exact
              path={'/'}
              component={() => <Redirect to={ROUTES.Login} />}
            />
            <Route exact path={ROUTES.Login} component={Login} />
            <Route path={ROUTES.ManagementSystem} component={Dashboard} />
            {process.env.NODE_ENV !== 'production' && (
              <Route path={'/icons'} component={IconsPage} />
            )}
            <Route component={NotFound} />
          </Switch>
        )}
      </MainContainer>
    </AppErrorBoundary>
  );
};

export default React.memo(Component);
