import { IStateReducer, IAction } from './types';

const prefix = 'CONFLICTS_MANAGER_';

export const actionsTypes = {
  SET: `${prefix}SET`,
  SET_LOADING: `${prefix}SET_LOADING`,
  SET_FILTER: `${prefix}SET_FILTER`,
  SET_SORT: `${prefix}SET_SORT`
};

const initialState: IStateReducer = {
  summary: [],
  filter: {
    clinicId: null,
    providerId: null,
    period: []
  },
  loading: false,
  sort: {
    sortBy: 'startTime',
    sortType: 'desc'
  }
};

const Reducer = (
  state: IStateReducer = { ...initialState },
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionsTypes.SET:
      return {
        ...state,
        summary: action.payload
      };
    case actionsTypes.SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload
        }
      };
    case actionsTypes.SET_SORT:
      return {
        ...state,
        sort: action.payload
      };
    default:
      return state;
  }
};

export default Reducer;
