import { useCallback, useRef } from 'react';
import { setPatients } from 'src/App/TempData/store/Actions';
import { ITempPatient } from 'src/App/TempData/store/types';
import ApiSubscription, { BucketsList } from 'src/Framework/ApiSubscription';
import { patientDisplayName } from 'src/Framework/util/format';
import { useTempAppSelector } from 'src/store/TemporaryStore';

export const usePatients = () => {
  const inProgressInvokes = useRef({});
  const patients = useTempAppSelector((state) => state.tempData.patients);
  const getData = useCallback(async (patientId: number) => {
    if (inProgressInvokes.current[patientId]) return;
    inProgressInvokes.current[patientId] = true;
    const res = await ApiSubscription.instance.FindAndSubscribe<ITempPatient>({
      bucket: BucketsList.patient,
      filter: `patient.Id == ${patientId}`
    });
    if (res?.success) {
      setPatients(res.data);
      const data = Object.values(res.data)[0];
      return data;
    }
    return;
  }, []);

  const getMultiplePatientsData = useCallback(async (patientIds: number[]) => {
    if (patientIds.some((patientId) => inProgressInvokes.current[patientId])) {
      return;
    }
    const filterString = patientIds
      .map((id) => `patient.Id == ${id}`)
      .join(' || ');
    patientIds.forEach(
      (patientId) => (inProgressInvokes.current[patientId] = true)
    );
    const res = await ApiSubscription.instance.FindAndSubscribe<ITempPatient>({
      bucket: BucketsList.patient,
      filter: filterString
    });
    if (res?.success) {
      setPatients(res.data);
      return true;
    }
    return;
  }, []);

  const getPatient = (patientId: number) => {
    if (!patients[patientId]) {
      getData(patientId);
      return;
    }
    return patients[patientId];
  };
  const getPatientName = (patientId: number | null) => {
    if (!patientId) return '';
    if (!patients[patientId]) {
      getData(patientId);
      return '';
    }
    return patientDisplayName(patients[patientId]);
  };

  const getPatientsInfo = (patientIds: number[] | null) => {
    if (!patientIds) return;
    getMultiplePatientsData(patientIds);
  };

  const getPatientNamePromise = async (patientId: number | null) => {
    if (!patientId) return '';
    if (!patients[patientId]) {
      const res = await getData(patientId);
      if (res) {
        return patientDisplayName(res);
      }
      return '';
    }
    return patientDisplayName(patients[patientId]);
  };
  return {
    patients,
    getPatient,
    getPatientName,
    getPatientsInfo,
    getPatientNamePromise
  };
};
