import React, { FC } from 'react';
import { TimePicker } from 'antd';
import { TimePickerProps } from 'antd/es/time-picker';

import { ICommon } from '../index';
import moment from 'moment';

export interface ITimePicker extends ICommon {
  type: 'time-picker';
  inputProps?: Partial<TimePickerProps>;
  value?: any;
  isAMPM?: boolean;
  onChange?: (dateString: string) => any;
}

const Component: FC<ITimePicker> = (props) => {
  const { value, inputProps, onChange, isAMPM } = props;

  const format = isAMPM ? 'hh:mm A' : 'HH:mm';
  const areaLabel = typeof props.label === 'string' ? props.label : '';

  return (
    <>
      <label htmlFor={inputProps?.id} className="visually-hidden">
        {props.label}
      </label>
      <TimePicker
        aria-required={props.star}
        aria-label={areaLabel}
        {...inputProps}
        format={format}
        value={value ? moment(value, 'HH:mm A') : null}
        onSelect={(value) => {
          if (onChange) {
            const dateString = value.format(format);
            onChange(dateString);
          }
        }}
        onChange={(value, dateString) => {
          if (onChange) {
            onChange(dateString);
          }
        }}
        popupStyle={{
          ...inputProps?.popupStyle,
          zIndex: 10000
        }}
        popupClassName="time-picker-className"
        panelRender={(originNode) => (
          <div id={`${inputProps?.id}-time-picker-container`}>{originNode}</div>
        )}
      />
    </>
  );
};

export default Component;
