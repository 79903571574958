import React, { FC } from 'react';
import { Spin } from 'antd';

import { Spinner } from './styled';

interface IProps {
  size?: number;
  border?: number;
}

const Component: FC<IProps> = ({ size, border }: IProps) => {
  return (
    <Spin
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      indicator={<Spinner size={size} border={border} />}
    />
  );
};

export default Component;
