import React, { FC, useEffect, useRef } from 'react';

import { DefaultContainer } from './styled';

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  getRef?: (ref: HTMLDivElement | null) => any;
  disableFocusLoop?: boolean;
}

export const focusableDOMItems =
  'a[href]:not([disabled]),  button:not([disabled]), textarea:not([disabled]), div[tabindex="0"], div[role="textbox"][aria-label="Rich Text Editor, main"], input[type="text"]:not([disabled]):not([tabindex="-1"]):not([role="textbox"]), input[type="password"]:not([disabled]), input[type="time"]:not([disabled]), input[type="search"]:not([disabled]), input[type="email"]:not([disabled]), input[type="number"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), input:not([disabled]), select:not([disabled]):not([tabindex="-1"])';

const A11yAutofocusContainer: FC<IProps> = ({
  children,
  getRef,
  disableFocusLoop,
  ...otherProps
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef?.current?.focus) {
      containerRef.current?.focus();
    }
    if (getRef) {
      getRef(containerRef.current);
    }
  }, []);

  return (
    <DefaultContainer
      {...otherProps}
      ref={containerRef}
      tabIndex={0}
      onKeyDown={(e) => {
        if (!disableFocusLoop) {
          const focusableElements = (
            containerRef?.current as unknown as HTMLElement
          ).querySelectorAll(focusableDOMItems);
          const lastFocusableElement =
            focusableElements[focusableElements.length - 1];
          if (
            e.key === 'Tab' &&
            !e.shiftKey &&
            e.target === lastFocusableElement
          ) {
            if (containerRef?.current?.focus) {
              e.preventDefault();
              containerRef?.current?.focus();
            }
          }
        }
      }}
    >
      {children}
    </DefaultContainer>
  );
};

export default React.memo(A11yAutofocusContainer);
