import _ from 'lodash';
import { IColumn } from 'src/Framework/Controls/Table/types';
import { IMessage } from './store/types';
import { formattedDateTime } from 'src/Framework/Shared/Shared';

export const debouncer = (timeout: number) => _.debounce(f => f(), timeout, { leading: false })

export const MESSAGE_TYPES = {
    INTERNAL: 'internal',
    SECURE: 'secure'
}

export const MESSAGE_ACTIONS_TYPES = {
    REPLY: 'reply',
    FORWARD: 'forward',
    CREATE: 'create'
}

export const MAIN_FOLDERS_TYPES = {
    INBOX: 'inbox',
    OUTBOX: 'outbox'
}

export const convertMessageActors = (actors: any) => {
    return {
        receivers: actors.receivers.map((v: any) => ({
            ...v,
            id: v.id,
            actorType: v.type
        })),
        sender: {
            ...actors.sender,
            id: actors.sender.id,
            actorType: actors.sender.type,
        }
    }
}

export const convertProviders = (data: Array<any>) => {
    if (!data) {
        return []
    }
    const list = data.map((provider: any) => ({
        label: `${provider.lastName} ${provider.firstName}`,
        value: provider.code,
        groupName: provider.groupName,
        gender: provider.sex,
        provider
    }))
    return _.sortBy(
        list,
        'label'
    );
};

export const receiverRead = (value: IMessage) => {
    const receiver = value.actors.receivers[0]
    if (receiver) {
        const isPatient = receiver.type === 'patient'
        if (isPatient && receiver.isRead) {
            return `Receiver Read ${formattedDateTime(receiver.readDts)}`
        }
    }
    return ''
}

export const columns: IColumn[] = [
    {
        flex: '120px',
        centered: true,
        title: 'Follow Up',
        key: 'flag'
    },
    {
        title: 'Priority',
        flex: '150px',
        key: 'priority'
    },
    {
        title: 'To',
        flex: '150px',
        key: 'to'
    },
    {
        title: 'From',
        flex: '150px',
        key: 'from'
    },
    {
        title: '',
        key: 'hasAttachments',
        flex: '45px',
    },
    {
        title: 'Subject',
        flex: 'auto',
        key: 'subject',
        minWidth: 200
    },
    {
        title: '',
        flex: '150px',
        key: 'receiverRead'
    },
    {
        title: 'Status',
        flex: '150px',
        key: 'status'
    },
    {
        title: 'Date',
        flex: '100px',
        key: 'date'
    }
];