import _ from 'lodash'

import { IAction, IStateReducer, IAssessments } from './types'

const prefix = 'ASSESSMENTS_REDUCER_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_SORT: `${prefix}SET_SORT`,
}

export const initialData: IAssessments = {
    summary: {},
    sort: {
        sortBy: 'dateTimestamp',
        sortType: 'desc'
    }
}

const initialState: IStateReducer = {};

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET_SORT:
            return {
                ...state,
                [action.patientId]: {
                    ...state[action.patientId],
                    sort: action.payload
                }
            }
        case actionsTypes.SET:
            if (state[action.patientId]) {
                return {
                    ...state,
                    [action.patientId]: {
                        ...state[action.patientId],
                        summary: action.payload
                    }
                }
            }
            return {
                ...state,
                [action.patientId]: {
                    ..._.cloneDeep(initialData),
                    summary: action.payload
                }
            }
        default:
            return state;
    }
};

export default Reducer;
