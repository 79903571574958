import React, { FC, useEffect, useRef, useState } from 'react';
import TUIImageEditor from '@toast-ui/react-image-editor';
import Button from 'src/Framework/Controls/Button';

import 'tui-image-editor/dist/tui-image-editor.css';
import { getDocumentLink } from 'src/Framework/Controls/FilesDownloader';

import { EditorContainer, EditorSaveButton } from './styled';
import { dataURLtoFile } from 'src/Framework/Controls/FileUploader';

interface IProps {
  id: number;
  crc?: string;
  onChange: (file: File) => Promise<any>;
}

const Component: FC<IProps> = ({ id, crc, onChange }: IProps) => {
  const [data, setData] = useState(null);
  const imageEditRef = useRef<any>(null);
  useEffect(() => {
    const init = async () => {
      const res = await getDocumentLink({ id, crc });
      if (res) {
        setData(res);
      }
    };
    init();
  }, []);
  const upload = async (file: File) => {
    return await onChange(file);
  };
  return data ? (
    <EditorContainer>
      <EditorSaveButton>
        <Button
          id="save-button"
          onClick={async () => {
            const res = imageEditRef.current?.imageEditorInst.toDataURL();
            const file = dataURLtoFile(res, `${new Date().getTime()}.png`);
            await upload(file);
          }}
        >
          Save
        </Button>
      </EditorSaveButton>
      <TUIImageEditor
        ref={imageEditRef}
        includeUI={{
          menu: ['shape', 'crop', 'flip', 'rotate', 'draw', 'text'],
          loadImage: {
            path: data,
            name: 'Image'
          },
          uiSize: {
            width: '100%',
            height: '700px'
          }
        }}
        cssMaxHeight={500}
        cssMaxWidth={700}
        selectionStyle={{
          cornerSize: 20,
          rotatingPointOffset: 70
        }}
        usageStatistics={false}
      />
    </EditorContainer>
  ) : null;
};

export default Component;
