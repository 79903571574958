import * as actionTypes from './ActivityActions';
import { IActivity, ActivityType } from '../Models/Activity';
import _ from 'lodash';
import { AppUrl } from 'src/Framework/Common/AppUrl';
import { CLEAR_ACTIVITIES } from 'src/App/SideNavBar/Store/SideNavBarAction';
import CommonActivityItem from '../ActivityItem/CommonactivityItem';

export const getTabsFromLS = () => {
  const activities = localStorage.getItem('activity');

  if (activities) {
    const array = JSON.parse(activities);
    array.forEach((v: IActivity) => {
      v.renderComponent = CommonActivityItem;
      v.cache = {};
      v.openedTimes = 0;
    });
    return array;
  }
  return [];
};

const initialState: IActivity[] = getTabsFromLS();

export interface ITabsSettings {
  tabs: IActivity[];
  filters: any[];
}

interface IAction {
  type: string;
  payload: IActivity;
  activityId: string;
  sourceIndex: number;
  destinationIndex: number;
  oldRuleName: string;
  currentRuleName: string;
  componentId: string;
  cache: any;
  tabsSet?: IActivity[];
}

const ActivityReducer = (
  state: IActivity[] = [...initialState],
  action: IAction
) => {
  localStorage.setItem('activity', JSON.stringify(state));
  switch (action.type) {
    case actionTypes.ON_CHANGE_CURRENT: {
      let newState = _.cloneDeep(state);
      const index = newState.findIndex((v) => v.isCurrent);
      if (index !== -1) {
        newState[index].isCurrent = false;
      }
      const i = newState.findIndex(
        (v) => v.activityId === action.payload.activityId
      );
      if (i !== -1) {
        const activity = newState[i];
        activity.isCurrent = true;
        if (activity.hasOwnProperty('openedTimes')) {
          // @ts-ignore
          activity.openedTimes = activity.openedTimes + 1;
        }
      }
      localStorage.setItem('activity', JSON.stringify(newState));
      return newState;
    }
    case actionTypes.UPDATE_ACTIVITY_BY_ID: {
      let newState = _.cloneDeep(state);
      let index = newState.findIndex((a) => a.activityId === action.payload.activityId);
      if (index !== -1) {
        newState[index] = {
          ...newState[index],
          ...action.payload
        };
      }
      localStorage.setItem('activity', JSON.stringify(newState));
      return newState;
    }
    case actionTypes.UPDATE_CURRENT_ACTIVITY: {
      let newState = _.cloneDeep(state);
      let index = newState.findIndex((a) => a.isCurrent);
      if (index !== -1) {
        newState[index] = {
          ...newState[index],
          ...action.payload
        };
      }
      localStorage.setItem('activity', JSON.stringify(newState));
      return newState;
    }
    case actionTypes.UPDATE_CURRENT_TARGET_URL: {
      let newState = _.cloneDeep(state);
      let index = newState.findIndex((a) => a.isCurrent);
      if (index !== -1) {
        newState[index].targetURL = action.payload.targetURL;
      }
      localStorage.setItem('activity', JSON.stringify(newState));
      return newState;
    }
    case actionTypes.UPDATE_CURRENT_TARGET_URL_TITLE: {
      let newState = _.cloneDeep(state);
      let index = newState.findIndex((a) => a.isCurrent);
      if (index !== -1) {
        newState[index].targetURL = action.payload.targetURL;
        newState[index].title = action.payload.title;
      }
      localStorage.setItem('activity', JSON.stringify(newState));
      return newState;
    }
    case actionTypes.VIEW_ACTIVITY: {
      let newState = _.cloneDeep(state);
      const oldActivity: IActivity | undefined = newState.find(
        (a) => a.isCurrent
      );
      let oldIndex: number = 0;
      if (oldActivity) {
        oldIndex = newState.indexOf(oldActivity);
        oldActivity.isCurrent = false;
      }
      const activity: IActivity | undefined = newState.find(
        (a) =>
          `${a.type}` === `${action.payload.type}` &&
          `${a.content.id}` === `${action.payload.content.id}`
      );
      if (!activity) {
        action.payload.activityId = new Date().getTime().toString();
        action.payload.openedTimes = 1;
        newState.splice(oldIndex + 1, 0, action.payload);
        return newState;
      }
      activity.isCurrent = true;
      if (activity.targetURL !== action.payload.targetURL) {
        activity.targetURL = action.payload.targetURL;
      }
      if (activity.hasOwnProperty('openedTimes')) {
        // @ts-ignore
        activity.openedTimes = activity.openedTimes + 1;
      }
      if (
        activity.type === ActivityType.Subject &&
        activity.targetURL.includes(AppUrl.MESSAGES)
      )
        activity.targetURL = action.payload.targetURL;
      return newState;
    }

    case actionTypes.REMOVE_ACTIVITY: {
      let newState = [...state];
      let index: number | undefined = newState.findIndex(
        (a) => a.activityId === action.activityId
      );
      if (index === -1) return state;
      if (newState[index].isCurrent) {
        if (index - 1 !== -1) {
          const i = index - 1;
          const activity = { ...newState[i] };
          activity.isCurrent = true;
          if (activity.hasOwnProperty('openedTimes')) {
            // @ts-ignore
            activity.openedTimes = activity.openedTimes + 1;
          }
          newState[i] = activity;
        }
      }
      newState.splice(index, 1);
      localStorage.setItem('activity', JSON.stringify(newState));
      return newState;
    }
    case actionTypes.REORDER_ACTIVITY: {
      let newState = _.cloneDeep(state);
      const [removed] = newState.splice(action.sourceIndex, 1);
      newState.splice(action.destinationIndex, 0, removed);
      localStorage.setItem('activity', JSON.stringify(newState));
      return newState;
    }
    case actionTypes.UPDATE_RULE_VIEWER_ACTIVITY: {
      let newState = _.cloneDeep(state);
      let index: number | undefined = newState.findIndex(
        (a) =>
          a.content.id === action.oldRuleName &&
          a.type === ActivityType.RuleViewer
      );
      if (index > -1) {
        let viewerActivity: IActivity = newState[index];
        viewerActivity.cache = {};
        let urlPath = viewerActivity.targetURL.split('/');
        urlPath[urlPath.length - 1] = action.currentRuleName;
        let name: string = '';
        viewerActivity.content.id = action.currentRuleName;
        for (let i = 0; i < urlPath.length; i++) {
          if (i !== 0) name += '/' + urlPath[i];
        }
        viewerActivity.targetURL = name;
      }
      return newState;
    }
    case actionTypes.UPDATE_RULE_EDITOR_ACTIVITY: {
      let newState = _.cloneDeep(state);
      let index: number | undefined = newState.findIndex(
        (a) =>
          a.content.id === action.oldRuleName &&
          a.type === ActivityType.RuleEditor
      );
      if (index > -1) {
        let ruleEditorActivity: IActivity = newState[index];
        ruleEditorActivity.cache = {};
        let urlPath = ruleEditorActivity.targetURL.split('/');
        urlPath[urlPath.length - 1] = action.currentRuleName;
        let name: string = '';
        ruleEditorActivity.content.id = action.currentRuleName;
        for (let i = 0; i < urlPath.length; i++) {
          if (i !== 0) name += '/' + urlPath[i];
        }
        ruleEditorActivity.targetURL = name;
      }
      return newState;
    }
    case CLEAR_ACTIVITIES: {
      let newState = _.cloneDeep(state);
      newState = [...initialState];
      localStorage.setItem('activity', JSON.stringify(newState));
      return newState;
    }
    case actionTypes.UPDATE_ACTIVITY_CACHE: {
      let newState = _.cloneDeep(state);
      let index: number | undefined = newState.findIndex(
        (a) => a.activityId === action.activityId
      );
      if (index !== -1) {
        newState[index].cache = {};
      }
      return newState;
    }
    case actionTypes.CACHE_ACTIVITY: {
      let activity: IActivity | undefined = state.find(
        (a) => a.activityId === action.activityId
      );
      if (!activity) return state;
      activity.cache[action.componentId] = action.cache;
      return [...state];
    }
    case actionTypes.SET_ACTIVITIES: {
      if (action.tabsSet) {
        return _.cloneDeep(action.tabsSet);
      }
      return _.cloneDeep(state);
    }
    case actionTypes.DEFAULT: {
      return [];
    }
    default:
      return state;
  }
};
export default ActivityReducer;
