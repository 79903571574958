import styled from "styled-components";

export const MainContainer = styled.div`
    position: relative;
    display: flex;
    flex:1;
    flex-direction: column;
`

export const InnerContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
`