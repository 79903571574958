import _ from 'lodash';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { CohortActionManager } from '../Reducer';

const ComplianceActions = {
    ADD_COHORT_COMPLIANCES_REQUIRED: "ADD_COHORT_COMPLIANCES_REQUIRED",
    ADD_COHORT_COMPLIANCES_NONREQUIRED: "ADD_COHORT_COMPLIANCES_NONREQUIRED",
    UPDATE_COMPLYRATE: "UPDATE_COMPLYRATE",
    UPDATE_COMPLIANCE_LIST: "UPDATE_COMPLIANCE_LIST",
    REMOVE_COMPLIANCE: "REMOVE_COMPLIANCE",
    COMPLIANCE_STATUS: "COMPLIANCE_STATUS"
}

interface IAction {
    type: string;
    payload: any;
    id: string;
    compliance: ICohortCompliance;
    cohortId: string;
    includeStatus: boolean;
    complianceId: string;
    complianceStatus: boolean;
}

interface IComplianceStore {
    required: ICohortComplianceStore[];
    nonRequired: ICohortComplianceStore[];
    status?: boolean;
}
export interface ICohortComplianceStore {
    cohortId: string;
    pagination: any;
    compliances: ICohortCompliance[];
}
export interface ICohortCompliance {
    chrone: any;
    complianceRate: number;
    definition: any;
    description: string;
    enabled: boolean;
    id: string;
    includeStatus: boolean;
    jobId: string;
    jobStatus: string;
    name: string;
    ruleName: string;
    overrideName: string;
    ruleType: number;
    ruleVersion: number;
    version: number;
    nonComplianceRate: any;
    members: any;
    complianceCount: any;
    nonComplianceCount: any;
    rule: any;

}

const initialState: IComplianceStore = {
    required: [],
    nonRequired: [],
    status: false
}

const CohortComplianceReducer = (
    state: IComplianceStore = initialState,
    action: IAction
) => {
    switch (action.type) {
        case ComplianceActions.ADD_COHORT_COMPLIANCES_REQUIRED: {
            let newState = _.cloneDeep(state)
            let complianceStore = state.required.find((re: ICohortComplianceStore) => re.cohortId === action.payload.cohortId);
            if (!complianceStore) {
                newState.required.push(action.payload);
                return newState;
            }
            if (action.payload.pagination.current > 1) {
                let isExist: boolean = isExistCompliance(complianceStore.compliances, action.payload.compliances);
                if (isExist) return newState;
                complianceStore.pagination = action.payload.pagination;
                complianceStore.compliances = [...complianceStore.compliances, ...action.payload.compliances];
            } else {
                const i = newState.required.findIndex(v => v.cohortId === complianceStore?.cohortId)
                if (i !== -1) {
                    newState.required[i] = action.payload;
                }
            }
            return newState;
        }
        case ComplianceActions.ADD_COHORT_COMPLIANCES_NONREQUIRED: {
            let newState = _.cloneDeep(state)
            let complianceStore = newState.nonRequired.find((re: ICohortComplianceStore) => re.cohortId === action.payload.cohortId);
            if (!complianceStore) {
                newState.nonRequired.push(action.payload);
                return newState;
            }
            if (action.payload.pagination.current > 1) {
                let isExist: boolean = isExistCompliance(complianceStore.compliances, action.payload.compliances);
                if (isExist) return newState;
                complianceStore.pagination = action.payload.pagination;
                complianceStore.compliances = [...complianceStore.compliances, ...action.payload.compliances];
            } else {
                const i = newState.nonRequired.findIndex(v => v.cohortId === complianceStore?.cohortId)
                if (i !== -1) {
                    newState.nonRequired[i] = action.payload;
                }
            }
            return newState;
        }
        case CohortActionManager.CLEAR_COHORT: {
            let newState = _.cloneDeep(state)
            let rIndex = newState.required.findIndex((o: ICohortComplianceStore) => o.cohortId === action.id);
            let nrIndex = newState.nonRequired.findIndex((o: ICohortComplianceStore) => o.cohortId === action.id);
            if (rIndex > -1) newState.required.splice(rIndex, 1);
            if (nrIndex > -1) newState.nonRequired.splice(nrIndex, 1);
            return newState;
        }
        case ComplianceActions.UPDATE_COMPLYRATE: {
            let newState = _.cloneDeep(state)
            let store = [...newState.required, ...newState.nonRequired];
            let cohortCompliance = store.find((c: ICohortComplianceStore) => c.cohortId === action.payload.cohortId);
            if (!cohortCompliance) return newState;
            let compliance = cohortCompliance.compliances.find((c: ICohortCompliance) => c.id === action.payload.complianceId);
            if (!compliance) return newState;
            compliance.complianceRate = action.payload.complianceRate;
            return newState;
        }
        case ComplianceActions.UPDATE_COMPLIANCE_LIST: {
            let newState = _.cloneDeep(state)
            let nonreqComplianceStore = newState.nonRequired.find((nr: ICohortComplianceStore) => nr.cohortId === action.cohortId);
            let reqComplianceStore = newState.required.find((r: ICohortComplianceStore) => r.cohortId === action.cohortId);
            if (!nonreqComplianceStore || !reqComplianceStore) return newState;
            let index: any;
            if (action.compliance.includeStatus) {
                index = nonreqComplianceStore.compliances.findIndex((c: ICohortCompliance) => c.id === action.compliance.id);
                if (index > -1) {
                    nonreqComplianceStore.compliances.splice(index, 1);
                }
                reqComplianceStore.compliances.unshift({ ...action.compliance });
            }
            else {
                index = reqComplianceStore.compliances.findIndex((c: ICohortCompliance) => c.id === action.compliance.id);
                if (index > -1) {
                    reqComplianceStore.compliances.splice(index, 1);
                }
                nonreqComplianceStore.compliances.unshift({ ...action.compliance });
            }
            return newState;
        }
        case ComplianceActions.REMOVE_COMPLIANCE: {
            let newState = _.cloneDeep(state)
            let complianceArray: any = [];
            if (action.includeStatus) complianceArray = newState.required;
            else complianceArray = newState.nonRequired;
            let complianceStore: ICohortComplianceStore = complianceArray.find((c: ICohortComplianceStore) => c.cohortId === action.cohortId);
            if (!complianceStore) return newState;
            let index = complianceStore.compliances.findIndex((c: ICohortCompliance) => c.id === action.complianceId);
            if (index === -1) return newState;
            complianceStore.compliances.splice(index, 1)
            return newState;
        }

        case ComplianceActions.COMPLIANCE_STATUS: {
            let newState = _.cloneDeep(state)
            newState.status = action.complianceStatus;
            return newState
        }
        default: return state;

    }
};

// to check whether a duplicate compliance (required or non required) already exist in the store
function isExistCompliance(stateCompliance: any, actionCompliance: any): boolean {
    if (actionCompliance && actionCompliance.length > 0) {
        let action_compliance: any = actionCompliance[0];
        let isExist: any = stateCompliance.find((compliance: any) => {
            return compliance.id === action_compliance.id;
        });
        if (isExist) return true;
    }
    return false;
}


export default CohortComplianceReducer;

export const addCohortCompliances = (data: any, inclued: boolean) => {
    return {
        type: inclued ? ComplianceActions.ADD_COHORT_COMPLIANCES_REQUIRED : ComplianceActions.ADD_COHORT_COMPLIANCES_NONREQUIRED,
        payload: data
    }
};
export const updateCompliancePercentage = (data: any) => {
    return {
        type: ComplianceActions.UPDATE_COMPLYRATE,
        payload: data
    }
};
export const updateComplianceList = (compliance: any, cohortId: string) => {
    return {
        type: ComplianceActions.UPDATE_COMPLIANCE_LIST,
        compliance: compliance,
        cohortId: cohortId
    }
};

export const getCohortCompliances = (data: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_COHORT_COMPLIANCES, data, { withCredentials: true });
};

export const AddNewRule = (data: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.ADD_COMPLIANCE, data, { withCredentials: true })

};

export const removeCompliance = (cohortId: string, complianceId: string, includeStatus: boolean) => {
    return {
        type: ComplianceActions.REMOVE_COMPLIANCE,
        complianceId: complianceId,
        cohortId: cohortId,
        includeStatus: includeStatus
    }
}

export const complianceStatus = (complianceStatus: boolean) => {
    return {
        type: ComplianceActions.COMPLIANCE_STATUS,
        complianceStatus
    }
}