//Header settings for setup, initial settings if settings are not valid in db
import { getStudentStatusTerm } from 'src/Framework/util/patient-term-util';

export const headerSettingsInit = {
  sex: {
    order: 1,
    show: true
  },
  age: {
    order: 2,
    show: true
  },
  id: {
    order: 3,
    show: false
  },
  otherId: {
    order: 4,
    show: false
  },
  dob: {
    order: 5,
    show: false
  },
  chartNo: {
    order: 6,
    show: false
  },
  studentStatus: {
    order: 7,
    show: false
  },
  studentClass: {
    order: 8,
    show: false
  },
  enrollmentDate: {
    order: 9,
    show: true
  },
  international: {
    order: 10,
    show: false
  },
  school: {
    order: 11,
    show: true
  },
  email: {
    order: 12,
    show: true
  },
  status: {
    order: 13,
    show: false
  },
  statusDate: {
    order: 14,
    show: false
  },
  weight: {
    order: 15,
    show: true
  },
  allergies: {
    order: 16,
    show: true
  },
  medications: {
    order: 17,
    show: true
  },
  major: {
    order: 18,
    show: true
  },
  insurance: {
    order: 19,
    show: true
  },
  unreadResults: {
    order: 20,
    show: true
  },
  missingRequirements: {
    order: 21,
    show: true
  },
  futureAppts: {
    order: 22,
    show: true
  },
  futureOrders: {
    order: 23,
    show: true
  },
  reminders: {
    order: 24,
    show: true
  },
  height: {
    order: 25,
    show: false
  },
  pronoun: {
    order: 26,
    show: false
  },
  genderIdentity: {
    order: 27,
    show: false
  },
  eligibility: {
    order: 28,
    show: false
  },
  preferredPhone: {
    order: 29,
    show: false
  },
  lastVisit: {
    order: 30,
    show: false
  },
  text: {
    order: 31,
    show: false
  },
  cohorts: {
    order: 32,
    show: false
  },
  // ease: {
  //   order: 33,
  //   show: false
  // },
  primarySport: {
    order: 34,
    show: false
  },
  apptsKept: {
    order: 35,
    show: false
  },
  apptsCancelled: {
    order: 36,
    show: false
  },
  standing: {
    order: 37,
    show: false
  },
  residency: {
    order: 38,
    show: false
  },
  firstName: {
    order: 39,
    show: false
  },
  MI: {
    order: 40,
    show: false
  },
  suffix: {
    order: 41,
    show: false
  },
  billingProvider: {
    order: 42,
    show: false
  },
  sexualOrientation: {
    order: 43,
    show: false
  },
  ethnicity: {
    order: 44,
    show: false
  },
  race: {
    order: 45,
    show: false
  },
  maritalStatus: {
    order: 46,
    show: false
  },
  employment: {
    order: 47,
    show: false
  },
  address1: {
    order: 48,
    show: false
  },
  address2: {
    order: 49,
    show: false
  },
  city: {
    order: 50,
    show: false
  },
  zip: {
    order: 51,
    show: false
  },
  state: {
    order: 52,
    show: false
  },
  country: {
    order: 53,
    show: false
  },
  billingMethod: {
    order: 54,
    show: false
  },
  feeSchedule: {
    order: 55,
    show: false
  },
  residenceHall: {
    order: 58,
    show: false
  },
  campusHousingRoom: {
    order: 59,
    show: false
  },
  veteranStatus: {
    order: 60,
    show: false
  },
  citizenship: {
    order: 61,
    show: false
  },
  refugeeStatus: {
    order: 62,
    show: false
  },
  group: {
    order: 63,
    show: false
  },
  belongsTo: {
    order: 64,
    show: false
  },
  permAddress: {
    order: 65,
    show: false
  },
  permAddress2: {
    order: 66,
    show: false
  },
  permCity: {
    order: 67,
    show: false
  },
  permState: {
    order: 68,
    show: false
  },
  permZip: {
    order: 69,
    show: false
  },
  campusAddress: {
    order: 70,
    show: false
  },
  campusAddress2: {
    order: 71,
    show: false
  },
  clientLanguage: {
    order: 72,
    show: false
  },
  preferredLanguages: {
    order: 56,
    show: false
  },
  visaType: {
    order: 57,
    show: false
  },
  balance: {
    order: 73,
    show: false
  },
  memo: {
    order: 74,
    show: false
  }
};

//headerUI, merge both settings and data to create the display object
export const headerUIInit = {
  sex: {
    label: 'Sex',
    name: 'sex'
  },
  age: {
    label: 'Age',
    name: 'age'
  },
  id: {
    label: 'Account #',
    name: 'id'
  },
  otherId: {
    label: 'Other ID',
    name: 'otherId'
  },
  dob: {
    label: 'DOB',
    name: 'dob'
  },
  chartNo: {
    label: 'Chart No',
    name: 'chartNo'
  },
  studentStatus: {
    label: getStudentStatusTerm(),
    name: 'studentStatus'
  },
  studentClass: {
    label: 'Class',
    name: 'studentClass'
  },
  enrollmentDate: {
    label: 'Enrollment Date',
    name: 'enrollmentDate'
  },
  international: {
    label: 'International',
    name: 'international'
  },
  school: {
    label: 'School name',
    name: 'school'
  },
  email: {
    label: 'Email',
    name: 'email'
  },
  status: {
    label: 'Status',
    name: 'status'
  },
  statusDate: {
    label: 'Status Date',
    name: 'statusDate'
  },
  weight: {
    label: 'Weight',
    name: 'weight'
  },
  allergies: {
    label: 'Allergies',
    name: 'allergies'
  },
  medications: {
    label: 'Medications',
    name: 'medications'
  },
  major: {
    label: 'Major',
    name: 'major'
  },
  insurance: {
    label: 'Insurance',
    name: 'insurance'
  },
  unreadResults: {
    label: 'Unread Results',
    name: 'unreadResults'
  },
  missingRequirements: {
    label: 'Missing Immun',
    name: 'missingImmun'
  },
  futureAppts: {
    label: 'Future Appts',
    name: 'futureAppts'
  },
  futureOrders: {
    label: 'Future Orders',
    name: 'futureOrders'
  },
  reminders: {
    label: 'Reminders',
    name: 'reminders'
  },
  height: {
    label: 'Height',
    name: 'height'
  },
  pronoun: {
    label: 'Pronoun',
    name: 'pronoun'
  },
  genderIdentity: {
    label: 'Gender Identity',
    name: 'genderIdentity'
  },
  eligibility: {
    label: 'Eligibility',
    name: 'eligibility'
  },
  preferredPhone: {
    label: 'Preferred Phone',
    name: 'preferredPhone'
  },
  lastVisit: {
    label: 'Last Visit',
    name: 'lastVisit'
  },
  text: {
    label: 'Text to Set Up',
    name: 'textToSetUp'
  },
  cohorts: {
    label: 'Cohorts',
    name: 'cohorts'
  },
  // ease: {
  //   label: 'Ease',
  //   name: 'ease'
  // },
  primarySport: {
    label: 'Primary Sport',
    name: 'primarySport'
  },
  apptsKept: {
    label: 'Appts Kept ',
    name: 'apptsKept'
  },
  apptsCancelled: {
    label: 'Appts Cancelled',
    name: 'apptsCancelled'
  },
  standing: {
    label: 'Standing',
    name: 'standing'
  },
  residency: {
    label: 'Residence',
    name: 'residency'
  },
  firstName: {
    label: 'First Name',
    name: 'firstName'
  },
  MI: {
    label: 'MI',
    name: 'MI'
  },
  suffix: {
    label: 'Suffix',
    name: 'suffix'
  },
  billingProvider: {
    label: 'Billing Provider',
    name: 'billingProvider'
  },
  sexualOrientation: {
    label: 'Sexual Orientation',
    name: 'sexualOrientation'
  },
  ethnicity: {
    label: 'Ethnicity',
    name: 'ethnicity'
  },
  race: {
    label: 'Race',
    name: 'race'
  },
  maritalStatus: {
    label: 'Marital Status',
    name: 'maritalStatus'
  },
  employment: {
    label: 'Employment',
    name: 'employment'
  },
  address1: {
    label: 'Address 1',
    name: 'addres1'
  },
  address2: {
    label: 'Address 2',
    name: 'address2'
  },
  city: {
    label: 'City',
    name: 'city'
  },
  zip: {
    label: 'Zip',
    name: 'zip'
  },
  state: {
    label: 'State',
    name: 'state'
  },
  country: {
    label: 'County',
    name: 'country'
  },
  billingMethod: {
    label: 'Billing Method',
    name: 'billingMethod'
  },
  feeSchedule: {
    label: 'Fee Schedule',
    name: 'feeSchedule'
  },
  residenceHall: {
    label: 'Residence Hall',
    name: 'residenceHall'
  },
  campusHousingRoom: {
    label: 'Room',
    name: 'campusHousingRoom'
  },
  veteranStatus: {
    label: 'Veteran Status',
    name: 'veteranStatus'
  },
  citizenship: {
    label: 'Citizenship',
    name: 'citizenship'
  },
  refugeeStatus: {
    label: 'Refugee Status',
    name: 'refugeeStatus'
  },
  group: {
    label: 'Group',
    name: 'group'
  },
  belongsTo: {
    label: 'Belongs To',
    name: 'belongsTo'
  },
  permAddress: {
    label: 'Permanent Address',
    name: 'permAddress'
  },
  permAddress2: {
    label: 'Permanent Address 2',
    name: 'permAddress2'
  },
  permCity: {
    label: 'Permanent City',
    name: 'permCity'
  },
  permState: {
    label: 'Permanent State',
    name: 'permState'
  },
  permZip: {
    label: 'Permanent Zip',
    name: 'permZip'
  },
  campusAddress: {
    label: 'Campus Address 1',
    name: 'campusAddress'
  },
  campusAddress2: {
    label: 'Campus Address 2',
    name: 'campusAddress2'
  },
  clientLanguage: {
    label: 'Client Languages',
    name: 'clientLanguage'
  },
  preferredLanguages: {
    label: 'Preferred Language',
    name: 'preferredLanguages'
  },
  visaType: {
    label: 'Visa Type',
    name: 'visaType'
  },
  balance: {
    label: 'Account Balance',
    name: 'balance'
  },
  memo: {
    label: 'Memo',
    name: 'memo'
  }
};

//Header Values (from api)
export const headerDataInit = {
  sex: {
    value: ''
  },
  age: {
    value: ''
  },
  id: {
    value: ''
  },
  otherId: {
    value: ''
  },
  dob: {
    value: ''
  },
  chartNo: {
    value: ''
  },
  studentStatus: {
    value: ''
  },
  studentClass: {
    value: ''
  },
  enrollmentDate: {
    value: ''
  },
  international: {
    value: ''
  },
  school: {
    value: ''
  },
  email: {
    value: ''
  },
  status: {
    value: ''
  },
  statusDate: {
    value: ''
  },
  weight: {
    value: ''
  },
  allergies: {
    value: []
  },
  medications: {
    value: []
  },
  major: {
    value: ''
  },
  insurance: {
    value: ''
  },
  unreadResults: {
    value: []
  },
  missingRequirements: {
    value: []
  },
  futureAppts: {
    value: []
  },
  futureOrders: {
    value: []
  },
  reminders: {
    value: []
  },
  height: {
    value: ''
  },
  pronoun: {
    value: ''
  },
  genderIdentity: {
    value: []
  },
  eligibility: {
    value: ''
  },
  preferredPhone: {
    value: ''
  },
  lastVisit: {
    value: ''
  },
  text: {
    value: ''
  },
  cohorts: {
    value: []
  },
  ease: {
    value: ''
  },
  primarySport: {
    value: ''
  },
  apptsKept: {
    value: ''
  },
  apptsCancelled: {
    value: ''
  },
  standing: {
    value: ''
  },
  residency: {
    value: ''
  },
  firstName: {
    value: ''
  },
  MI: {
    value: ''
  },
  suffix: {
    value: ''
  },
  billingProvider: {
    value: ''
  },
  sexualOrientation: {
    value: ''
  },
  ethnicity: {
    value: ''
  },
  race: {
    value: ''
  },
  maritalStatus: {
    value: ''
  },
  employment: {
    value: ''
  },
  address1: {
    value: ''
  },
  address2: {
    value: ''
  },
  city: {
    value: ''
  },
  zip: {
    value: ''
  },
  state: {
    value: ''
  },
  country: {
    value: ''
  },
  billingMethod: {
    value: ''
  },
  feeSchedule: {
    value: ''
  },
  residenceHall: {
    value: ''
  },
  campusHousingRoom: {
    value: ''
  },
  veteranStatus: {
    value: ''
  },
  citizenship: {
    value: ''
  },
  refugeeStatus: {
    value: ''
  },
  group: {
    value: ''
  },
  belongsTo: {
    value: ''
  },
  permAddress: {
    value: ''
  },
  permAddress2: {
    value: ''
  },
  permCity: {
    value: ''
  },
  permState: {
    value: ''
  },
  permZip: {
    value: ''
  },
  campusAddress: {
    value: ''
  },
  campusAddress2: {
    value: ''
  },
  clientLanguage: {
    value: ''
  },
  preferredLanguages: {
    value: ''
  },
  visaType: {
    value: ''
  },
  balance: {
    value: ''
  },
  memo: {
    value: ''
  }
};
