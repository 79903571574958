import React, { FC } from 'react';

import { Container, Title, SubTitle, Description } from './styled';

interface IProps {}

const Component: FC<IProps> = ({}: IProps) => {
  return (
    <Container>
      <img
        src={require('src/Framework/Common/Svg/new-logo-big.svg').default}
        alt="logo"
        width="221"
      />
      <Title>404</Title>
      <SubTitle>Page not found</SubTitle>
      <Description>The page you are looking for does not exist</Description>
    </Container>
  );
};

export default Component;
