export interface IAction {
    type: string;
    payload: any;
    subjectId: any;
}


export enum NoteParamType {
    all = 'all',
    inProgress = 'inProgress'
}