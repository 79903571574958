export const moveEventPayload: any = {
  conflictsResponses: null,
  dts: undefined,
  old: {
    clinicCode: undefined,
    providerCode: undefined,
    scheduleEventDateTime: undefined,
    endTime: undefined,
  },
  new: {
    clinicCode: undefined,
    providerCode: undefined,
    scheduleEventDateTime: undefined,
    endTime: undefined,
  },
  appointmentBlockId: undefined,
  appointmentId: undefined,
  patientId: undefined,
};

export const initReleaseBeforeInterval = {
  day: 0,
  minute: 0,
  hour: 0,
};
export const newEventBlockv2 = {
  color: "#CCCCCC",
  groupId: undefined,
  scheduleEventBlockId: 0,
  page: 1,
  provider: {},
  isReservation: false,
  allDay: false,
  automaticDeletionIntervalInMinutes: undefined,
  releaseBeforeInterval: initReleaseBeforeInterval,
};

const newEventSeriesv2 = {
  dayOfMonthFilter: "",
  dayOfWeekFilter: "",
  startDate: undefined,
  endDate: undefined,
  startTime: undefined,
  endTime: undefined,
  occurrences: null,
  typeFrequencyId: 0,
  weekNumber: 0,
  lastDayofMonth: null,
  inactive: false,
};

const newScheduleEventv2 = {
  scheduleEventTypeId: 0,
  uid: undefined,
  appointmentBlockId: 0,
  appointmentId: 0,
  maxParticipants: undefined,
  visitCount: undefined,
  groupAppointment: false,
  typeAppointmentParticipantId: undefined,
  comment: undefined,
  endTime: undefined,
  createdDts: undefined,
  modifiedDts: undefined,
  page: undefined,
  providerCode: undefined,
  room: undefined,
  facilityCode: undefined,
  clinicCode: undefined,
  clinicName: undefined,
  userCode: undefined,
  modifiedUserCode: undefined,
  categoryCode: undefined,
  reason: undefined,
  scheduleEventDateTime: undefined,
  series: newEventSeriesv2,
  appointmentProviderLinkId: undefined,
  clinicProviderBatchId: undefined,
  sequenceId: 0,
  inSeries: false,
  isMultiProvider: false,
  seriesId: 0,
  blockSelfCheckin: false,
  telehealth: {
    patientConnectionInfo: "",
    providerConnectionInfo: ""
  }
};

export const newEventv2 = {
  scheduleEvent: newScheduleEventv2,
  forms: undefined,
  dts: undefined,
  patients: undefined,
  providerCodes: [],
  conflictsResponses: undefined,
  appointmentForms: undefined,
  updateSeries: false,
  // isMulti: false
};

export const addApptUIModel = {
  appointmentType: "individual",
  selectedSubjects: null,
  selectedClinic: null,
  selectedReason: null,
  maxParticipants: null,
  startTime: null,
  endTime: null,
  date: null,
  forms: null,
  duration: null,
  error: null,
  enableMultiProvider: false,
  popups: {
    history: false,
    scheduling: false,
    recurring: false,
    settings: false,
  },
};
export const defaultScheduleGroupEventData = {
  groupAppointment: false,
  id: 1,
  categoryCode: '',
  inReservation: false,
  inSeries: false,
  isBlock: false,
  isMultiProvider: false,
  isReservation: false,
  merged: true,
  modifiedUserCode: 'Medicat',
  note: '',
  occupyWidth: 0.925,
  occupyX: 0,
  order: 1,
  overflowing: 1,
  providerId: 0,
  scheduleEventTypeId: 1,
  typeAppointmentParticipantId: 4,
  userCode: 'Medicat'
};
export const defaultScheduleEventData = {
  groupAppointment: false,
  id: 1,
  categoryCode: '',
  inReservation: false,
  inSeries: false,
  isBlock: false,
  isMultiProvider: false,
  isReservation: false,
  merged: true,
  modifiedUserCode: 'Medicat',
  note: '',
  occupyWidth: 0.925,
  occupyX: 0,
  order: 1,
  overflowing: 1,
  providerId: 0,
  scheduleEventTypeId: 1,
  typeAppointmentParticipantId: 1,
  userCode: 'Medicat'
};
export const defaultPatientStatus = {
  checkedIn: false,
  checkedInTime: null,
  ready: false,
  readyTime: null,
  discharged: false,
  dischargeTime: null,
  admit: false,
  admitTime: null
};
