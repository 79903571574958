import styled from 'styled-components';

export const Container = styled.div``;

export const IconContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 0 0 2px;
  padding: 2px;
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  width: 220px;
  position: relative;
`;
