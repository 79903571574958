import React, { FC, useState, useMemo } from 'react';
import { Button, Row } from 'antd';
import cn from 'classnames';

import ClinicSelector from 'src/Framework/Controls/Selectors/ClinicSelector';
import ProviderSelector from 'src/Framework/Controls/Selectors/ProviderSelector';
import Icon from 'src/Framework/Controls/Icon';
import { getClinicById } from 'src/Framework/Controls/Selectors/ClinicSelector';
import {
  getProviderById,
  getProviderClinicAssignment,
  providerDisplayName
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import { permissionChecking } from 'src/App/User/Permission';
import { ILegacyForm } from 'src/Activities/Forms/Forms';

import { Container, Separate, SelectBlock } from './styled';
import { getUserById } from 'src/Framework/Controls/Selectors/UserSelector/utils';

interface IProps {
  form: ILegacyForm;
  onClickSend: (value: ISendData, form: ILegacyForm) => Promise<any>;
  onClickSave: (value: ILegacyForm) => any;
}

export interface ISendData {
  clinicId: null | string | number;
  providerId: null | string | number;
}

const Component: FC<IProps> = ({ form, onClickSend, onClickSave }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ISendData>({
    clinicId: form.clinicId,
    providerId: null
  });
  const routedProviderName = useMemo(() => {
    return providerDisplayName(form.providerId);
  }, [form.providerId]);
  const onChangeData = (obj: Partial<ISendData>) => {
    setData({
      ...data,
      ...obj
    });
  };
  const clinic = getClinicById(form.clinicId);
  const userName = getUserById(form.userId)?.name || '';
  return (
    <Container>
      <Row align="middle" justify="space-between" style={{ flex: 1 }}>
        <div>
          <Row align="middle">
            Clinic:&nbsp;&nbsp;<b>{clinic?.name}</b>
            <Separate />
            Created by:&nbsp;&nbsp;<b>{userName}</b>
            <Separate />
            Routed to:&nbsp;&nbsp;
            <b>{routedProviderName}</b>
          </Row>
        </div>
        <div>
          <Row align="middle">
            <b>Route to:</b>
            <SelectBlock>
              Clinic:
              <ClinicSelector
                multiple={false}
                value={data.clinicId}
                filterItem={(clinic) =>
                  clinic.typeId
                    ? permissionChecking({
                        anyClinic: false,
                        clinicId: clinic.id,
                        moduleId: 40,
                        functionId: 98
                      }).success
                    : false
                }
                onChange={(clinicId: string) => {
                  let providerId = data.providerId;
                  if (providerId) {
                    const prov = getProviderById(providerId);
                    if (prov) {
                      const a = getProviderClinicAssignment(
                        prov,
                        clinicId
                      )?.roles.includes('rendering');
                      if (!a) {
                        providerId = null;
                      }
                    }
                  }
                  onChangeData({ clinicId, providerId });
                }}
                selectProps={{
                  inputProps: {
                    placeholder: ''
                  },
                  bottomMargin: false,
                  hideLabel: true
                }}
              />
            </SelectBlock>
            <Separate />
            <SelectBlock>
              Provider:
              <ProviderSelector
                containerStyles={{
                  width: '100%'
                }}
                value={data.providerId}
                filterByClinics={data.clinicId ? [+data.clinicId] : []}
                roleFilter={'rendering'}
                onChange={(providerId: string) => onChangeData({ providerId })}
                selectProps={{
                  inputProps: {
                    placeholder: ''
                  },
                  bottomMargin: false,
                  hideLabel: true
                }}
              />
            </SelectBlock>
            <Separate />
            <Button
              loading={loading}
              disabled={
                form.status === 'locked' || !data.clinicId || !data.providerId
              }
              type="text"
              className={cn({
                'button-content': true,
                disabled:
                  form.status === 'locked' || !data.clinicId || !data.providerId
              })}
              onClick={async () => {
                setLoading(true);
                await onClickSave(form);
                await onClickSend(data, form);
                setLoading(false);
              }}
            >
              Send
              <Icon
                containerStyles={{ display: 'inline-block' }}
                name="paperPlane"
                color={globalStyleVariables.newBrandingPrimary200}
                size={16}
              />
            </Button>
          </Row>
        </div>
      </Row>
    </Container>
  );
};

export default React.memo(Component);
