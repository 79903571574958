import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'Lato';
  font-size: 12px;
  font-weight: 700;
  color: ${globalStyleVariables.darkGrey};
`;

export const Container = styled.div`
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    left: 0;
    transition: unset !important;
  }
`;
