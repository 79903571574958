import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import _ from 'lodash';
import cn from 'classnames';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import { providerDisplayName } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import AppErrorBoundary from 'src/Framework/Errors/AppErrorBoundary';
import { AppUrl } from 'src/Framework/Common/AppUrl';
import { formattedDate } from 'src/Framework/Shared/Shared';
import { patientDisplayName, currencyFormat } from 'src/Framework/util/format';
import store, { useAppSelector } from 'src/store';
import TESTS_IDS from 'src/tests-script/pages/counseling/Header';

import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';
import {
  headerUIInit,
  headerDataInit,
  headerSettingsInit
} from 'src/Activities/SystemSetup/PatientHeaderSettings/PatientHeaderSettingsData';
import { getColumnSettings } from 'src/Activities/SystemSetup/ColumnsSettings/ColumnSettings';
import { useGroupType } from 'src/App/Admin/Pages/GroupTypes/store/Hooks/useGroupType';
import { imageFormats } from '../Patients/Demographics/Controls/PatientImageReducer';
import {
  getPatientImage,
  addPatientImage
} from '../Patients/Demographics/Controls/PatientImageActions';
import { getPatientHeader } from './store/Actions';

import CorporateScreeningButton from './CorporateScreeningButton';
import List from './List';

import * as container from './styled';

interface IProps {
  subjectId: any;
  documentPage?: boolean;
  style?: any;
}

export const noData = 'No data';

export const prefix = 'patient-header-';

const formatPhone = (phoneString: string) => {
  const cleanedPhone = phoneString.replace(/[^\d]/g, '');
  if (cleanedPhone.length > 10) {
    return cleanedPhone.replace(
      /(\d{3})(\d{3})(\d{4})(\d{1,})/,
      '($1) $2-$3 Ext($4)'
    );
  } else if (cleanedPhone.length === 10) {
    return cleanedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else if (cleanedPhone.length >= 7) {
    return cleanedPhone.replace(/(\d{3})(\d{3})(\d{1,})/, '($1) $2-$3');
  } else if (cleanedPhone.length >= 4) {
    return cleanedPhone.replace(/(\d{3})(\d{1,})/, '($1) $2');
  }
  return '';
};

interface IListOption {
  value: number;
  label: string;
  code: string;
}

interface IProps {}

const Component: FC<IProps> = (props: IProps) => {
  const { subjectId, style, documentPage } = props;
  const data = useAppSelector(
    (state) => state.client.patientHeader[subjectId] || {}
  );
  const documentImage = useAppSelector(
    (state) => state.client.patientImage[subjectId] || {}
  );
  const patientHeader = useAppSelector(
    (state) => state.user.preferences.patientHeader?.value
  );
  const patientInfo = useAppSelector(
    (state) => state.client.patientInfo[subjectId] || {}
  );
  const patientLists = useAppSelector(
    (state) => state.client.lists.patientLists
  );
  const { lastName, firstName } = data;
  const [allInfoExpanded, setAllInfoExpanded] = useState(false);
  const [loading, setLoading] = useState(Object.keys(patientInfo).length === 0);
  useEffect(() => {
    const loadData = async () => {
      try {
        const getImage = async () => {
          const res = await getPatientImage(subjectId);
          let data2: any = res.data;
          if (data2.success) {
            store.dispatch(addPatientImage(data2.result, subjectId));
          }
        };
        getImage();
        if (Object.keys(patientInfo).length === 0) {
          setLoading(true);
        }
        await getPatientHeader(subjectId);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [subjectId]);
  const openAdminPage = useCallback(() => {
    let url = AppUrl.PATIENTHEADERSETTINGS;
    loadActivityTab(url, store.dispatch);
  }, []);
  const toggleMenuHeader = useCallback(() => {
    setAllInfoExpanded((value) => !value);
  }, []);
  const optionLists = useAppSelector(
    (state) => state.client.lists.patientLists
  );
  const expandButtonRef: React.MutableRefObject<any> = useRef();
  const firstFieldRef: React.MutableRefObject<any> = useRef();
  const sexualOrientationGT = useGroupType('typeSexualOrientation');
  const ethnicityGT = useGroupType('typeEthnicity2010');
  const raceGT = useGroupType('typeRace');
  const campusHousingGT = useGroupType('campusHousing');
  const groupGT = useGroupType('groupPatient');
  const maritalStatusOptions: IListOption[] = Object.values(
    optionLists['maritalStatus']
  );
  const employmentOptions: IListOption[] = Object.values(
    optionLists['employed']
  );
  const billingMethodOptions: IListOption[] = Object.values(
    optionLists['billingMethod']
  );
  const belongToOptions: IListOption[] = Object.values(optionLists['category']);
  const languageOptions: IListOption[] = Object.values(optionLists['language']);

  const onKeyDownAccessibilityLocal = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      if (e.currentTarget) {
        // @ts-ignore
        e.currentTarget.click();
      }
    }
    if (e.key === 'Tab' && allInfoExpanded) {
      if (e.shiftKey) {
        if (e.currentTarget === firstFieldRef.current) {
          e.preventDefault();
          expandButtonRef.current.focus();
        }
      } else {
        if (e.currentTarget === expandButtonRef.current) {
          e.preventDefault();
          firstFieldRef.current.focus();
        }
      }
    }
  };

  const sortedData = useMemo(() => {
    const uiInit = _.merge({}, headerUIInit, headerDataInit);
    const ui = getColumnSettings(uiInit, patientHeader || headerSettingsInit);
    if (data && Object.keys(data).length > 0) {
      Object.keys(ui).forEach((key) => {
        if (data.hasOwnProperty(key)) {
          ui[key].value = data[key];
          ui[key].originValue = _.cloneDeep(data[key]);
        } else {
          ui[key].value = null;
          ui[key].originValue = null;
        }
        ui['apptsKept'].value =
          data.appointmentCount && data.appointmentCheckedInCount
            ? `${data.appointmentCheckedInCount}/${
                data.appointmentCount
              } (${Math.round(
                (data.appointmentCheckedInCount / data.appointmentCount) * 100
              )}%)`
            : '';
        ui['apptsCancelled'].value =
          data.appointmentCount && data.appointmentCancelledCount
            ? `${data.appointmentCancelledCount}/${
                data.appointmentCount
              } (${Math.round(
                (data.appointmentCancelledCount / data.appointmentCount) * 100
              )}%)`
            : '';
        ui['dob'].value = ui['dob'].value ? formattedDate(ui['dob'].value) : '';
        ui['enrollmentDate'].value = ui['enrollmentDate'].value
          ? formattedDate(ui['enrollmentDate'].value)
          : '';
        ui['statusDate'].value = ui['statusDate'].value
          ? formattedDate(ui['statusDate'].value)
          : '';
        ui['preferredPhone'].value = ui['preferredPhone'].originValue
          ? formatPhone(ui['preferredPhone'].originValue)
          : '';
        ui['text'].value = ui['text'].originValue ? 'True' : 'False';
        ui['MI'].value = data.middleName
          ? data.middleName.slice(0, 1).toUpperCase()
          : '';
        ui['billingProvider'].value =
          data.billingProviderId && data.billingProviderId > 0
            ? providerDisplayName(data.billingProviderId)
            : '';
        ui['sexualOrientation'].value = data.typeSexualOrientationId
          ? sexualOrientationGT.finder(data.typeSexualOrientationId)?.name || ''
          : '';
        ui['ethnicity'].value = data.etnicityId
          ? ethnicityGT.finder(data.etnicityId)?.name || ''
          : '';
        ui['race'].value =
          data.race && data.race.length > 0
            ? data.race.map(
                (item: number) => raceGT.finder(item, 'id')?.name || ''
              )
            : '';
        ui['maritalStatus'].value = data.typeMaritalStatusId
          ? maritalStatusOptions?.find(
              (item) => item.value === data.typeMaritalStatusId
            )?.label || ''
          : '';
        ui['employment'].value = data.typeEmployeId
          ? employmentOptions?.find((item) => item.value === data.typeEmployeId)
              ?.label || ''
          : '';
        ui['billingMethod'].value = data.typeBillingId
          ? billingMethodOptions?.find(
              (item) => item.value === data.typeBillingId
            )?.label || ''
          : '';
        ui['preferredLanguages'].value = data.preferredLanguages
          ? languageOptions?.find(
              (item) => item.value === data.preferredLanguages
            )?.label || ''
          : '';
        ui['residenceHall'].value = data.campusHousingId
          ? campusHousingGT?.finder(data.campusHousingId, 'id')?.name || ''
          : '';
        ui['veteranStatus'].value = data.veteranStatus ? 'True' : 'False';
        ui['citizenship'].value = data.citizenship ? 'True' : 'False';
        ui['refugeeStatus'].value = data.refugeeStatus ? 'True' : 'False';
        ui['group'].value = data.groupId
          ? groupGT?.finder(data.groupId, 'id')?.name || ''
          : '';
        ui['belongsTo'].value =
          data.belongsTo && data.belongsTo.length > 0
            ? data.belongsTo.map(
                (item: string) =>
                  belongToOptions.find((option) => option.code === item)
                    ?.label || ''
              )
            : '';
        ui['clientLanguage'].value =
          data.clientLanguages && data.clientLanguages.length > 0
            ? data.clientLanguages.map(
                (item: number) =>
                  languageOptions.find((option) => option.value === item)
                    ?.label || ''
              )
            : '';
        ui['memo'].value = (
          <EllipsisText maxWidth={200}>{data.comment || ''}</EllipsisText>
        );
        ui['balance'].value = currencyFormat(data.accountBalance);
      });
    }

    if (ui['sex']) {
      const gender: any = Object.values(patientLists.gender).find(
        (v: any) => v.code === ui['sex'].value
      );
      ui['sex'].value = gender?.label;
    }

    if (Object.keys(ui['age']?.value).length > 0) {
      ui['age'].value = ui['age'].value.years;
      ui['age'].originValue = ui['age'].value.years;
    }
    return _.sortBy(ui, 'order').filter((v) => v.show);
  }, [patientLists, data]);

  const image = useMemo(() => {
    const ext = documentImage?.extension ? documentImage.extension : '.jpg';
    const f = imageFormats.find((s) => s.type === ext);
    const documentFormat = f ? f.format : '';
    return documentImage?.thumbContent
      ? documentFormat + documentImage.thumbContent
      : '';
  }, [documentImage]);
  const hasImage = documentImage?.thumbContent;
  const patientName = patientDisplayName(data);
  const patientHeaderValidation = data && Object.keys(data).length > 0;
  return (
    <AppErrorBoundary>
      <container.Wrapper
        id={TESTS_IDS.PATIENT_HEADER_INFO_PANEL}
        style={style}
        className={cn({
          active: allInfoExpanded
        })}
      >
        {loading && <AbsoluteLoader />}
        <container.Header>
          {patientHeaderValidation && (
            <>
              <container.ColumnPicture id="columnPicture">
                <container.Picture>
                  {hasImage ? (
                    <img src={image} alt={documentImage.fileName} />
                  ) : (
                    firstName[0] + lastName[0]
                  )}
                </container.Picture>
                <container.Name
                  tabIndex={0}
                  ref={firstFieldRef}
                  onKeyDown={onKeyDownAccessibilityLocal}
                >
                  <span id={`${prefix}patient-name`} title={patientName}>
                    <EllipsisText maxWidth={280}>{patientName}</EllipsisText>
                  </span>
                </container.Name>
              </container.ColumnPicture>
              {sortedData
                .filter((v) => v.name !== 'ease')
                .map(({ value, label, name, originValue }) => (
                  <container.Column key={`headerItem-${label}-${name}`}>
                    {name === 'ease' ? (
                      <div className="corporateScreening">
                        <CorporateScreeningButton
                          email={data.email}
                          allInfoExpanded={allInfoExpanded}
                        />
                      </div>
                    ) : (
                      <>
                        <span>{label}</span>
                        {Array.isArray(value) ? (
                          <List
                            list={value}
                            label={label}
                            allInfoExpanded={allInfoExpanded}
                            name={name}
                          />
                        ) : (
                          <p
                            id={`${prefix}${name}`}
                            {...{
                              'item-value': `${
                                name === 'international'
                                  ? value
                                    ? 'true'
                                    : 'false'
                                  : originValue
                              }`
                            }}
                          >
                            {name === 'international'
                              ? value
                                ? 'True'
                                : 'False'
                              : typeof value === 'string' && !value
                              ? noData
                              : value || noData}
                          </p>
                        )}
                      </>
                    )}
                  </container.Column>
                ))}
              <container.Icons style={documentPage ? { right: 55 } : {}}>
                {allInfoExpanded && (
                  <ButtonIcon
                    isButton={true}
                    name={ICONS_LIST.systemSetup}
                    label="Info panel Setup"
                    onClick={openAdminPage}
                    containerProps={{
                      onKeyDown: (e: React.KeyboardEvent<any>) =>
                        onKeyDownAccessibilityLocal(e)
                    }}
                    changeColorOnHover={true}
                  />
                )}
                <ButtonIcon
                  isButton={true}
                  name={
                    allInfoExpanded ? ICONS_LIST.arrowUp : ICONS_LIST.arrowDown
                  }
                  label={`${
                    allInfoExpanded ? 'Collapse' : 'Expand'
                  } info panel`}
                  isExpanded={allInfoExpanded}
                  controlsId={TESTS_IDS.PATIENT_HEADER_INFO_PANEL}
                  id={TESTS_IDS.EXPAND_BUTTON}
                  onClick={toggleMenuHeader}
                  containerProps={{
                    onKeyDown: (e: React.KeyboardEvent<any>) =>
                      onKeyDownAccessibilityLocal(e),
                    ref: expandButtonRef
                  }}
                  changeColorOnHover={true}
                  iconScale={2}
                />
              </container.Icons>
            </>
          )}
        </container.Header>
      </container.Wrapper>
    </AppErrorBoundary>
  );
};

export default React.memo(Component);
