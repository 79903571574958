import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

interface IContainer {
  hideBorder?: boolean;
}

export const Container = styled.div<IContainer>`
  padding: 10px 20px;
  border-top: ${({ hideBorder }) =>
    hideBorder ? 'unset' : '1px solid ' + globalStyleVariables.borderColor};

  .pagination-container {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .pagination-item {
      padding: 0 6px;
      text-align: center;
      margin: auto 4px;
      color: ${globalStyleVariables.middleGrey};
      background-color: ${globalStyleVariables.grey};
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      line-height: 1.43;
      font-size: 13px;
      height: 26px;
      min-width: 26px;
      user-select: none;

      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }

      &.selected {
        background-color: ${globalStyleVariables.brandBlue};
        color: white;
      }

      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: '';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid rgba(0, 0, 0, 0.87);
          border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }

        &.left {
          transform: rotate(-135deg) translate(-50%);
        }

        &.right {
          transform: rotate(45deg);
        }
      }

      &.disabled {
        pointer-events: none;

        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
`;

export const Text = styled.div`
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  color: ${globalStyleVariables.middleGrey};
  white-space: nowrap;
`;
