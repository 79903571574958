import React, { FC, useEffect, useState } from 'react';
import { IFileSimpleDto } from '../FileUploader';
import Item from './Item';

import { Container } from './styled';
import { IIcon } from '../ButtonIcon';
import { getAttachmentById } from 'src/Activities/Counseling/Dashboard/Attachments/store/Actions';
import NoData from '../Table/NoData';
import AbsoluteLoader from '../AbsoluteLoader';

interface IProps {
  files: IFileSimpleDto[];
  onWrapperKeyDown: any;
  popupRef: any;
  buttonProps?: Partial<IIcon>;
  patientId?: string | number;
  traineeId?: string | number;
  closePopup: () => any;
  buttonRef: any;
  useIds?: boolean;
  fileIds?: number[];
  firstItemRef: any;
}

const Component: FC<IProps> = (props: IProps) => {
  const {
    patientId,
    traineeId,
    buttonRef,
    firstItemRef,
    closePopup,
    buttonProps,
    popupRef,
    onWrapperKeyDown,
    useIds,
    fileIds
  } = props;
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<IFileSimpleDto[]>(
    useIds ? [] : props.files
  );
  useEffect(() => {
    const init = async () => {
      if (fileIds) {
        setLoading(true);
        const res = await Promise.all(
          fileIds.map((v) => getAttachmentById(v, true))
        );
        setLoading(false);
        const files: IFileSimpleDto[] = [];
        res.forEach((v) => {
          if (v) {
            files.push(v);
          }
        });
        setFiles(files);
      }
    };
    if (useIds) {
      init();
    } else {
      setFiles(props.files);
    }
  }, [props.files.map((v) => v?.id).toString(), fileIds]);
  return (
    <Container
      id="attachments-popover"
      tabIndex={0}
      ref={popupRef}
      onKeyDown={(e: React.KeyboardEvent) => onWrapperKeyDown(e)}
    >
      {loading && <AbsoluteLoader />}
      {files.map((v, index) => (
        <Item
          patientId={patientId}
          traineeId={traineeId}
          buttonRef={buttonRef}
          isFirst={index === 0}
          isLast={index === files.length - 1}
          firstItemRef={firstItemRef}
          key={v.id}
          item={v}
          buttonProps={buttonProps}
          closePopup={closePopup}
        />
      ))}
      {files.length === 0 && <NoData />}
    </Container>
  );
};

export default React.memo(Component);
