import React, { FC } from 'react';
import { Row } from 'antd';

import Icon from 'src/Framework/Controls/Icon';

import { SubTitle, RulesContainer, RuleTitle, IconContainer } from './styled';
import {
  hasLowerCase,
  hasUpperCase,
  hasNumberCase,
  hasSpecialCase
} from './utils';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

interface IRule {
  title: string;
  validation: boolean;
}

interface IProps {
  data: {
    newPassword: string;
    confirmPassword: string;
  };
}

const RuleItem = ({ value }: { value: IRule }) => {
  return (
    <Row align="middle" className="rule-item">
      <IconContainer>
        {value.validation ? (
          <Icon name={'checkCircle'} color={'#53C82E'} size={21} />
        ) : (
          <Icon name={'closeCircle'} color={globalStyleVariables.brandGray} />
        )}
      </IconContainer>
      <RuleTitle>{value.title}</RuleTitle>
    </Row>
  );
};

const Component: FC<IProps> = ({ data }: IProps) => {
  const rules1: IRule[] = [
    {
      title: 'Must be at least 15 to 75 characters.',
      validation: data.newPassword.length > 14 && data.newPassword.length < 76
    },
    {
      title: 'New password and Confirm password must match.',
      validation:
        data.newPassword.length !== 0 &&
        data.confirmPassword.length !== 0 &&
        data.newPassword === data.confirmPassword
    }
  ];

  const rules2: IRule[] = [
    {
      title: 'At least one upper case (A-Z)',
      validation: hasUpperCase(data.newPassword)
    },
    {
      title: 'At least one lower case (a-z)',
      validation: hasLowerCase(data.newPassword)
    },
    {
      title: 'At least one number (0-9)',
      validation: hasNumberCase(data.newPassword)
    },
    {
      title: 'At least one special character (~@#$%^&*()-_+’;:?.,!)',
      validation: hasSpecialCase(data.newPassword)
    }
  ];
  return (
    <>
      <SubTitle>All of the rules below:</SubTitle>
      <RulesContainer>
        <div>
          You may not repeat any password used in the past.
          <br />
          Your password may not include your name.
        </div>
        {rules1.map((v, index) => (
          <RuleItem key={index} value={v} />
        ))}
      </RulesContainer>
      <SubTitle>At least 3 out of the 4 rules below:</SubTitle>
      <RulesContainer>
        {rules2.map((v, index) => (
          <RuleItem key={index} value={v} />
        ))}
      </RulesContainer>
    </>
  );
};

export default Component;
