import React from "react";

export const FormCheckedSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.5 3.5H15.5C15.7652 3.5 16.0196 3.60536 16.2071 3.79289C16.3946 3.98043 16.5 4.23478 16.5 4.5V16.5C16.5 16.7652 16.3946 17.0196 16.2071 17.2071C16.0196 17.3946 15.7652 17.5 15.5 17.5H4.5C4.23478 17.5 3.98043 17.3946 3.79289 17.2071C3.60536 17.0196 3.5 16.7652 3.5 16.5V4.5C3.5 4.23478 3.60536 3.98043 3.79289 3.79289C3.98043 3.60536 4.23478 3.5 4.5 3.5H5.5" fill="#53C82E" />
        <path d="M14.5 3.5H15.5C15.7652 3.5 16.0196 3.60536 16.2071 3.79289C16.3946 3.98043 16.5 4.23478 16.5 4.5V16.5C16.5 16.7652 16.3946 17.0196 16.2071 17.2071C16.0196 17.3946 15.7652 17.5 15.5 17.5H4.5C4.23478 17.5 3.98043 17.3946 3.79289 17.2071C3.60536 17.0196 3.5 16.7652 3.5 16.5V4.5C3.5 4.23478 3.60536 3.98043 3.79289 3.79289C3.98043 3.60536 4.23478 3.5 4.5 3.5H5.5" stroke="#53C82E" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5 2.5H7.5V4.5H12.5V2.5Z" fill="#53C82E" stroke="#53C82E" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 11L9 13L13 9" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
