import { IStateReducer, IAction } from './types';

const prefix = 'ADMIN_CATEGORIES_';

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_LOADING: `${prefix}SET_LOADING`,
    SET_FILTER: `${prefix}SET_FILTER`,
};

const initialState: IStateReducer = {
    summary: {},
    loading: false,
    filter: {
        therapy: false
    }
};

const Reducer = (
    state: IStateReducer = { ...initialState },
    action: IAction
): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload
                }
            };
        case actionsTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case actionsTypes.SET:
            return {
                ...state,
                summary: action.payload
            };
        default:
            return state;
    }
};

export default Reducer;
