import React, { FC, useState } from 'react';
import { Row, Col } from 'antd';

import ButtonIcon, {
  ICONS_LIST,
  IIcon
} from 'src/Framework/Controls/ButtonIcon';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import EllipsisText from 'src/Framework/Controls/EllipsisText';

import { usePermission } from 'src/App/User/Permissions/Hook';
import { handleDownload } from '../FilesDownloader';
import FileViewerButton from '../FileViewer/Button';
import NewTabButton from '../FileViewer/NewTabButton';

import { ListItem } from './styled';

interface IProps {
  item: any;
  buttonProps?: Partial<IIcon>;
  firstItemRef?: React.MutableRefObject<any>;
  buttonRef?: React.MutableRefObject<any>;
  isFirst?: boolean;
  isLast?: boolean;
  closePopup?: Function;
  patientId?: string | number;
  traineeId?: string | number;
}

const Component: FC<IProps> = ({
  item,
  buttonProps,
  firstItemRef,
  buttonRef,
  isFirst,
  isLast,
  closePopup,
  patientId,
  traineeId
}: IProps) => {
  const downloadPermission = usePermission({
    anyClinic: true,
    moduleId: 110,
    functionId: 1221
  });
  const previewPermission = usePermission({
    anyClinic: true,
    moduleId: 110,
    functionId: 1220
  });
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    if (!downloadPermission.success) return;
    if (loading) return;
    setLoading(true);
    await handleDownload(item);
    setLoading(false);
  };
  const { originalFileName, fileName } = item;

  const onKeyDownPreview = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      if (e.currentTarget) {
        // @ts-ignore
        e.currentTarget.click();
      }
    }
    if (e.key === 'Tab' && e.shiftKey) {
      if (isFirst) {
        e.preventDefault();
        if (buttonRef?.current) {
          // @ts-ignore
          buttonRef.current.focus();
        }
      }
    }
  };
  const onKeyDownDownload = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      if (e.currentTarget) {
        // @ts-ignore
        e.currentTarget.click();
      }
    }
    if (e.key === 'Tab' && !e.shiftKey) {
      if (isLast) {
        e.preventDefault();
        if (buttonRef?.current) {
          // @ts-ignore
          buttonRef.current.focus();
        }
      }
    }
  };
  return (
    <ListItem>
      {loading && <AbsoluteLoader />}
      <Row align="middle" justify="space-between" gutter={8} wrap={false}>
        <Col flex={1}>
          <span className="file-name" onClick={onClick}>
            <EllipsisText>
              {originalFileName ? originalFileName : fileName}
            </EllipsisText>
          </span>
        </Col>
        <Col>
          <Row align="middle" gutter={8} wrap={false}>
            {previewPermission.success && (
              <Col>
                <NewTabButton
                  patientId={patientId}
                  traineeId={traineeId}
                  buttonProps={{
                    ...buttonProps,
                    containerProps: {
                      ...buttonProps?.containerProps,
                      ref: isFirst ? firstItemRef : undefined,
                      onKeyDown: (e: any) => onKeyDownPreview(e)
                    }
                  }}
                  item={item}
                />
              </Col>
            )}
            {previewPermission.success && (
              <Col>
                <FileViewerButton
                  buttonProps={{
                    ...buttonProps,
                    containerProps: {
                      ...buttonProps?.containerProps,
                      ref: isFirst ? firstItemRef : undefined,
                      onKeyDown: (e: any) => onKeyDownPreview(e)
                    }
                  }}
                  closePopup={closePopup}
                  parentButtonRef={buttonRef}
                  item={item}
                />
              </Col>
            )}
            {downloadPermission.success && (
              <Col>
                <ButtonIcon
                  isButton={true}
                  label="Download"
                  name={ICONS_LIST.download}
                  tooltip={'Download'}
                  onClick={onClick}
                  changeColorOnHover={true}
                  containerProps={{
                    onKeyDown: (e: any) => onKeyDownDownload(e)
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </ListItem>
  );
};

export default React.memo(Component);
