import { BucketsList } from "src/Framework/ApiSubscription";
import { RequestHelper } from "src/Framework/ApiSubscription/RequestHelper";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { IProviderConsent } from "../types";
import { setSummary, onCreateUpdateItem } from "./Setters";

export const SubscriptionData = (providerId: string | number) => ({
    bucket: BucketsList.providerConsent,
    filter: `${BucketsList.providerConsent}.ProviderId == ${providerId}`
})

export const { post, get, patch, unsubscribe } = new RequestHelper<IProviderConsent, [providerId: string | number], [providerId: string | number]>({
    link: ApiUrl.ProviderConsents,
    onCreateUpdateItem,
    subscription: {
        subscriptionData: SubscriptionData,
        setSummary
    }
})
