import { store } from "../../../../../index";
import { APIHandler } from "../../../../../Framework/Communication/ServerProxy";
import { ApiUrl } from "../../../../../Framework/Common/ApiUrl";
import _ from "lodash";

export const SET_DIAGNOSIS = "SET_DIAGNOSIS";
export const SET_DIAGNOSIS_GROUPS = "SET_DIAGNOSIS_GROUPS";
export const SET_ASSESSMENT_RISKS_LIST = "SET_ASSESSMENT_RISKS_LIST";

export const setDiagnosisGroups = (data: any[]) => {
    store.dispatch({
        type: SET_DIAGNOSIS_GROUPS,
        payload: data
    })
};
export const setAssessmentRisksList = (data: any) => {
    store.dispatch({
        type: SET_ASSESSMENT_RISKS_LIST,
        payload: data,
    });
};

export const getDiagnosisGroup = (clinicId: any) => {
    APIHandler.AxiosInstance.post(
        ApiUrl.GET_DIAGNOSIS_GROUPS,
        {
            clinicId: clinicId
        },
        { withCredentials: true }
    ).then(response => {
        if (response.status === 200 && response.data.result && response.data.result.summary) {
            setDiagnosisGroups(_.orderBy(response.data.result.summary, ['name']));
        }
    }).catch((error) => {
        console.log(error);
    });
}

export const getDiagnosisNew = (diagnosisGroup: number | undefined, searchText: string, count = 20, clinicId: any) => {
    return APIHandler.AxiosInstance.get(
        ApiUrl.DiagnosisCode, {
        withCredentials: true,
        params: {
            "diagnosisGroupId": diagnosisGroup,
            "searchString": searchText,
            "count": count,
            "sortBy": "name",
            "sortType": "asc",
            "startIndex": 0,
            "isActive": true,
            "clinicId": clinicId
        }
    }
    );
}

export const getDiagnosis = (diagnosisGroup: number | undefined, searchText: string, count = 20, clinicId: any) => {
    return APIHandler.AxiosInstance.get(
        ApiUrl.DIAGNOSIS, {
        withCredentials: true,
        params: {
            "diagnosisGroupId": diagnosisGroup,
            "searchString": searchText,
            "count": count,
            "sortBy": "name",
            "sortType": "asc",
            "startIndex": 0,
            "isActive": true,
            "clinicId": clinicId
        }
    }
    );
}

