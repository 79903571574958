import React, { FC, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import { IM1FormRecord } from 'src/App/M1FormRecords/store/types';
import Preview from '../Preview/index';

interface IProps {
  schema: any;
  formSubmission: React.MutableRefObject<any>;
  color?: string;
  form: IM1FormRecord;
}

const Component: FC<IProps> = ({
  schema,
  formSubmission,
  color,
  form
}: IProps) => {
  const componentRef = useRef(null);
  const [startPrint, setStartPrint] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setStartPrint(false);
    }
  });
  return (
    <>
      {startPrint && (
        <div style={{ display: 'none' }}>
          <div style={{ width: 1024, padding: 20 }} ref={componentRef}>
            <Preview
              form={form}
              schema={schema}
              submission={formSubmission.current}
              previewIsReady={() => {
                setTimeout(() => {
                  if (handlePrint) {
                    handlePrint();
                  }
                }, 250);
              }}
            />
          </div>
        </div>
      )}
      <ButtonIcon
        isButton={true}
        label="Print"
        color={color}
        changeColorOnHover={true}
        name={ICONS_LIST.print}
        loading={startPrint}
        tooltip="Print"
        onClick={() => {
          setStartPrint(true);
        }}
      />
    </>
  );
};

export default React.memo(Component);
