export const ADD_RULE_VIEWER_DETAILS = "ADD_RULE_VIEWER_DETAILS";
export const CHANGE_RULE_VIEWER_ENABLE_DISABLE ="CHANGE_RULE_VIEWER_ENABLE_DISABLE";
export const UPDATE_DRAFT_STATUS = "UPDATE_DRAFT_STATUS";
export const REMOVE_DRAFT_FROM_UI = "REMOVE_DRAFT_FROM_UI";
export const ENABLE_DISABLE_RULE_VIEWER = "ENABLE_DISABLE_RULE_VIEWER";

export const addRuleViewerDetails = (ruleDetails: any) => {
  return {
    type: ADD_RULE_VIEWER_DETAILS,
    payload: ruleDetails
  };
};

export const changeEnableOrDisableRule = (ruleDetails: any) => {
  return {
    type: CHANGE_RULE_VIEWER_ENABLE_DISABLE,
    payload: ruleDetails
  };
};

export const enableAction = (ruleId: string, isEnabled: boolean) => {
  return {
    type: ENABLE_DISABLE_RULE_VIEWER,
    ruleId: ruleId,
    isEnabled: isEnabled
  };
};
