import _ from 'lodash';

import { IStateReducer, IAction } from './types'

const prefix = 'MESSAGES_'

export const actionsTypes = {
    SET: `${prefix}DATA`,
    SET_SELECTED_CATEGORY: `${prefix}SELECTED_CATEGORY`,
    SET_FILTER: `${prefix}FILTER`,
    SET_FILTERED_LIST: `${prefix}FILTERED_LIST`,
    SET_STATUSES: `${prefix}SET_STATUSES`,
    SET_OPENED_ROWS: `${prefix}SET_OPENED_ROWS`,
    SET_LOADING: `${prefix}SET_LOADING`,
    SET_LOADING_SECURE: `${prefix}SET_LOADING_SECURE`,
    SET_LOADING_MORE: `${prefix}SET_LOADING_MORE`,
    SET_SELECTED_PROVIDERS: `${prefix}SET_SELECTED_PROVIDERS`,
    SET_FOLLOW_UP: `${prefix}SET_FOLLOW_UP`,
    SET_PROVIDERS: `${prefix}SET_PROVIDERS`,
    SET_INTERNAL_MESSAGES: `${prefix}SET_INTERNAL_MESSAGES`,
    SET_SECURE_MESSAGES: `${prefix}SET_ALL_MESSAGES`,
    SET_PAGINATION: `${prefix}SET_PAGINATION`,
};

const pageLength = 30;

export const initialFilter = { text: '', priorities: [], statuses: [], isFlagged: false, providerIds: [], after: null, before: null, recipient: null }

export const initialState: IStateReducer = {
    filter: _.cloneDeep(initialFilter),
    filteredList: null,
    selectedCategory: 'inbox:internal:sys0',
    statuses: {},
    messages: [],
    openedRows: [],
    loading: false,
    loadingSecure: false,
    loadingMore: false,
    selectedProviders: [],
    providers: [],
    followUpMessages: {
        summary: []
    },
    internalMessages: [],
    secureMessages: [],
    pagination: {
        pageNumber: 0,
        pageLength
    },
    pendingMessages: {}
};

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET_SECURE_MESSAGES: {
            const data = {
                ...state,
                secureMessages: action.payload
            }
            if (action.pendingMessage) {
                data.pendingMessages = {
                    ...data.pendingMessages,
                    [action.pendingMessage.id]: action.pendingMessage
                }
            }
            data.pendingMessages = _.pickBy(data.pendingMessages, (value) => value.isActive);
            return data
        }
        case actionsTypes.SET_INTERNAL_MESSAGES: {
            const data = {
                ...state,
                internalMessages: action.payload
            }
            if (action.pendingMessage) {
                data.pendingMessages = {
                    ...data.pendingMessages,
                    [action.pendingMessage.id]: action.pendingMessage
                }
            }
            data.pendingMessages = _.pickBy(data.pendingMessages, (value) => value.isActive);
            return data
        }
        case actionsTypes.SET_PROVIDERS:
            return {
                ...state,
                providers: action.payload
            }
        case actionsTypes.SET_FOLLOW_UP:
            return {
                ...state,
                followUpMessages: action.payload
            }
        case actionsTypes.SET_LOADING_SECURE:
            return {
                ...state,
                loadingSecure: action.payload
            }
        case actionsTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case actionsTypes.SET_LOADING_MORE:
            return {
                ...state,
                loadingMore: action.payload
            }
        case actionsTypes.SET_OPENED_ROWS:
            return {
                ...state,
                openedRows: action.payload
            }
        case actionsTypes.SET_STATUSES:
            return {
                ...state,
                statuses: action.payload
            }
        case actionsTypes.SET:
            return {
                ...state,
                messages: action.payload
            }
        case actionsTypes.SET_SELECTED_CATEGORY:
            return {
                ...state,
                selectedCategory: action.payload,
                openedRows: [],
                pendingMessages: {}
            }
        case actionsTypes.SET_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload
                }
            }
        case actionsTypes.SET_FILTERED_LIST:
            return {
                ...state,
                filteredList: action.payload
            }
        case actionsTypes.SET_SELECTED_PROVIDERS:
            return {
                ...state,
                selectedProviders: [...action.payload]
            }
        case actionsTypes.SET_PAGINATION:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        default:
            return state;
    }
};

export default Reducer;