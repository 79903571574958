import styled from 'styled-components';
import { animationTime } from './utils';

interface IContainer {}

export const Container = styled.div<IContainer>``;

export const Overflow = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex: 1;
  background: transparent;
  transition: all ${animationTime / 100}s ease-in-out;
  z-index: 1400;

  &.opened {
    background: rgba(255, 255, 255, 0.5);
  }
`;

interface ISlider {}

export const Slider = styled.div<ISlider>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -75vw;
  transition: all ${animationTime / 100}s ease-in-out;
  background-color: white;
  z-index: 1401;
  border-radius: 3px 0 0 0;

  &.opened {
    right: 0;
  }

  .resizable-container {
    display: flex;
    flex-direction: column;
  }
`;

export const ColResizer = styled.div`
  position: absolute;
  right: 0;
  width: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 1px;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:hover {
    > div {
      background-image: linear-gradient(
        rgba(0, 0, 0, 0.2) 60%,
        rgba(0, 0, 0, 0) 0%
      );
      background-position: right;
      background-size: 1px 10px;
      background-repeat: repeat-y;
      border-right: unset;
    }
  }
`;
export const A11yWrapper = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
