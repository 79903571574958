import React, { FC, useEffect, useState } from 'react';
import * as Actions from './Actions';
import { IHtmlPreview } from './Html/types';
import Html from './Html';
import { IHeaderV1Preview } from 'src/Activities/Counseling/NewNotes/Preview/DataGenerator/Previews/Header/V1/types';
import Header from './Header';

import { Container } from './styled';

interface IProps {
  seedId: number;
  previewIsReady?: () => any;
}

const Component: FC<IProps> = ({ seedId, previewIsReady }: IProps) => {
  const [header, setHeader] = useState<IHeaderV1Preview | null>(null);
  const [data, setData] = useState<IHtmlPreview | null>(null);
  useEffect(() => {
    const init = async () => {
      const [data, header] = await Promise.all([
        Actions.getFormPreview({ seedId }),
        Actions.getHeader({ seedId })
      ]);
      if (data) {
        setData(data.sections);
      }
      if (header) {
        setHeader(header);
      }
      if (previewIsReady) {
        previewIsReady();
      }
    };
    init();
  }, []);
  return (
    <Container>
      {header && <Header data={header} />}
      {data &&
        Object.values(data).map((value, i) => <Html key={i} value={value} />)}
    </Container>
  );
};

export default React.memo(Component);
