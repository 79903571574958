import { IRuleComponent, IValidationResult } from "../../Models/RuleDefinition";
import {
  IRuleEditorAction,
  AddRuleComponentAction,
  MoveRuleComponentAction,
  RemoveRuleComponentAction,
  AddOptionValueAction
} from "./RuleEditorActions";
import * as actionType from "../ActionTypes";
import { IRule } from "../../Models/RuleDefinition";
import { IRuleTemplate } from "../../Models/RuleTemplate";
import { APIHandler } from "../../../../../Framework/Communication/ServerProxy";

export const addRuleAction = (rule: IRule) => {
  return {
    type: actionType.ADD_RULE,
    value: rule
  };
};

export const addValidateResultAction = (result: IValidationResult) => {
  return {
    type: actionType.ADD_VALIDATE_RESULT,
    value: result
  };
};

export const addOptionValue = (
  newValue: any,
  componentId: number,
  keyName: string,
  oldValue: any,
  id: string,
  subType?: string,
  subComponent?: IRuleTemplate
) => {
  var action: IRuleEditorAction = new AddOptionValueAction(
    newValue,
    componentId,
    keyName,
    oldValue,
    id,
    subType,
    subComponent
  );
  return (dispatch: any) => {
    dispatch(updateActionStatus(action, id));
    action.perform(dispatch);
  };
};

const updateActionStatus = (action: any, ruleId: string) => {
  return {
    type: actionType.UPDATE_ACTION_STATUS,
    payload: action,
    ruleId: ruleId
  };
};

export const addRuleComponentAction = (
  ruleId: string,
  parentId: number,
  componentTemplate: any,
  compId: number
): any => {
  var action: IRuleEditorAction = new AddRuleComponentAction(
    ruleId,
    parentId,
    compId,
    componentTemplate
  );
  return (dispatch: any) => {
    dispatch(updateActionStatus(action, ruleId));
    action.perform(dispatch);
  };
};

export const moveRuleComponentAction = (
  ruleId: string,
  componentId: number,
  sourceId: number,
  targetId: number,
  sourceIndex: number,
  targetIndex: number
): any => {
  var action: IRuleEditorAction = new MoveRuleComponentAction(
    ruleId,
    componentId,
    sourceId,
    targetId,
    sourceIndex,
    targetIndex
  );
  return (dispatch: any) => {
    dispatch(updateActionStatus(action, ruleId));
    action.perform(dispatch);
  };
};

export const removeRuleComponentAction = (
  ruleId: string,
  component: IRuleComponent,
  parentId: number,
  index: number
): any => {
  let componentInfo = JSON.stringify({ component });
  var action: IRuleEditorAction = new RemoveRuleComponentAction(
    ruleId,
    componentInfo,
    parentId,
    index
  );
  return (dispatch: any) => {
    dispatch(updateActionStatus(action, ruleId));
    action.perform(dispatch);
  };
};

const updateActionStatusOnUndo = (ruleId: string, action: any) => {
  return {
    type: actionType.UNDO_UPDATE_ACTION,
    ruleId: ruleId,
    payload: action
  };
};

export const updateLogicalOperator = (ruleId: string, action: any, targetId: any) => {
  return {
    type: actionType.UPDATE_LOGICAL_OPERATOR,
    id: ruleId,
    value: action,
    targetId: targetId,
  };
};

export const undoAction = (
  currentActionIndex: any,
  editorActions: any,
  ruleId: string
): any => {
  if (
    currentActionIndex > -1 &&
    currentActionIndex <= editorActions.length - 1
  ) {
    return (dispatch: any) => {
      let action = editorActions[currentActionIndex];
      dispatch(updateActionStatusOnUndo(ruleId, action));
      if (currentActionIndex === editorActions.length) {
        action.undo(dispatch);
      } else {
        action.undo(dispatch);
      }
    };
  } else {
    return (dispatch: any) => { };
  }
};

const updateActionStatusOnRedo = (ruleId: string, action: any) => {
  return {
    type: actionType.REDO_UPDATE_ACTION,
    ruleId: ruleId,
    payload: action
  };
};

export const redoAction = (
  currentActionIndex: any,
  editorActions: any,
  ruleId: string
): any => {
  if (currentActionIndex > 0) {
    return (dispatch: any) => {
      var action: IRuleEditorAction = editorActions[currentActionIndex - 1];
      dispatch(updateActionStatusOnRedo(ruleId, action));
      action.redo(dispatch);
    };
  } else {
    return (dispatch: any) => { };
  }
};

export const removeActionStatus = (ruleId: string) => {
  return {
    type: actionType.REMOVE_ACTION_STATUS,
    ruleId: ruleId
  };
};

export const clearValidationResult = (ruleId: string) => {
  return {
    type: actionType.CLEAR_VALIDATION_RESULT,
    ruleId: ruleId
  };
};

export const saveDraft = (Id: any, ruleDef: any) => {
  return APIHandler.AxiosInstance.post("/Rule/saveDraft?ruleId=" + Id, ruleDef, { withCredentials: true });
};

export const publishRule = (Id: any, ruleDef: any) => {
  return APIHandler.AxiosInstance.post("/Rule/Publish?ruleId=" + Id, ruleDef, { withCredentials: true });
};

export const validateRule = (ruleDef: any) => {
  return APIHandler.AxiosInstance.post("/Rule/ValidateRule", ruleDef, { withCredentials: true });
};
