const ids = {
  formsAddButton: 'forms-add-button',
  formsSaveButton: 'forms-save-button',
  formsDeleteButton: 'forms-delete-button',
  formsConfirmButton: 'forms-confirm-button',
  formsCancelButton: 'forms-cancel-button',
  formsPreviewButton: 'forms-preview-button',
  formsClinicSelector: 'forms-clinic-selector',
  formsSearchField: 'forms-search-field',
  formsFilterModal: 'forms-filter-modal',
  formsSortModal: 'forms-sort-modal',
  formsAddFormModal: 'forms-add-form-modal',
  formsFilterApplyButton: 'apply-forms-filter-button',
  formsFilterResetButton: 'reset-forms-filter-button',
  formsSortApplyButton: 'apply-forms-sort-button',
  formsSortResetButton: 'reset-forms-sort-button',
  formsSummaryButton: 'forms-summary-button',
  formsSummaryModal: 'forms-summary-modal',
  formsSummarySave: 'forms-summary-save-button',
  formsSummaryArea: 'forms-summary-area'
};

export default ids;
