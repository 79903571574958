import { ScheduleTimeProps } from "src/Activities/Schedule/Common/Interfaces";
import { ICalendarSettings } from "./types";

export const defaultScheduleTimeProps: ScheduleTimeProps = {
    dayStartTime: '0:00',
    dayEndTime: '24:00',
    regularMeetingTime: 15,
    slotHeight: 1
};

export const defaultCalendarSettings: ICalendarSettings = Object.freeze({
    isShowingPatientName: true,
    isShowingReservationLabel: true,
    weekFilter: '5days',
    zoom: 1,
    showCancellations: false,
    favoriteId: null,
    initialLoadId: null,
    reservationsOnly: false,
    reservationLabelOpacity: true
})