import React, { FC, useRef, useMemo, useCallback } from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import test_ids from 'src/tests-script/pages/Subject/Forms';

import Modal, { IModalHandles } from './Modal';
import { Type } from 'src/Activities/Forms/Forms';
import { patchLegacyFormSummary } from 'src/Activities/Forms/FormList/PatientFormActions';
import { patchM1FormSummary } from 'src/App/M1FormRecords/store/Actions';

export interface ILegacyFormSummary {
  type: Type.LEGACY;
  summary: string;
  seedId: number;
}

export interface IM1FormSummary {
  type: Type.M1;
  summary: string | null;
  id: number;
}

type TFormSummary = ILegacyFormSummary | IM1FormSummary;

interface IProps {
  currentForm: TFormSummary;
  afterFormUpdate?: (formData: any) => void;
}

const Component: FC<IProps> = ({ currentForm, afterFormUpdate }: IProps) => {
  const ModalRef = useRef<IModalHandles>(null);
  const hasSummary = useMemo(() => {
    return !!currentForm.summary;
  }, [currentForm]);

  const updateSummary = useCallback(
    async (summary) => {
      let res: any;
      if (currentForm.type === Type.LEGACY) {
        res = await patchLegacyFormSummary(currentForm.seedId!, summary);
      } else {
        res = await patchM1FormSummary(currentForm.id, summary);
      }
      if (res && afterFormUpdate) {
        afterFormUpdate(res);
      }
    },
    [currentForm]
  );

  return (
    <>
      <Modal
        ref={ModalRef}
        summary={currentForm?.summary || ''}
        onAdded={updateSummary}
      />
      <ButtonIcon
        isButton={true}
        id={test_ids.formsSummaryButton}
        controlsId={test_ids.formsSummaryModal}
        label="Summary"
        tooltip="Summary"
        badge={hasSummary ? 1 : undefined}
        badgeColor={globalStyleVariables.red}
        badgeTextColor={globalStyleVariables.red}
        changeColorOnHover={true}
        name={ICONS_LIST.letterS}
        onClick={() => {
          ModalRef.current?.show();
        }}
        iconScale={2}
      />
    </>
  );
};

export default Component;
