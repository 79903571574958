import * as actionTypes from "./CohortManagerAction";
import _ from 'lodash';
import { IRule } from 'src/Activities/RuleEditor/Store/Models/RuleDefinition';

const initialState: IRule[] = []

interface IAction {
  type: string;
  cohortRuleDefinition: IRule;
}

const CohortRuleDefinitionReducer = (
  state: IRule[] = [...initialState],
  action: IAction
) => {
  switch (action.type) {
    case actionTypes.UPDATE_SELECTED_COHORT: {
      let newState = _.cloneDeep(state)
      let rule = newState.find((r: IRule) => r.id === action.cohortRuleDefinition.id)
      if (!rule) return [...newState, action.cohortRuleDefinition];
      newState[newState.indexOf(rule)] = action.cohortRuleDefinition;
      return newState;
    }
    case actionTypes.CLEAR_COHORT_MANAGER: {
      let newState = _.cloneDeep(state)
      newState = initialState;
      return newState;
    }

    default:
      return state;
  }
};
export default CohortRuleDefinitionReducer;
