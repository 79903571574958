import styled from 'styled-components';

interface IContainer {
  isPreview: boolean
}

export const Container = styled.div<IContainer>`
  ${({isPreview})=>isPreview && `
        pointer-events: none;
        filter: grayscale(100%);
  `}
`;

export const Label = styled.label`
  margin: 0 0 0 10px;
  cursor: pointer;
  pointer-events: none;
`;
