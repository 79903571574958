import styled from 'styled-components';
import { input_normal_bg_color } from 'src/CommonStyles/styles';

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 25px 0;
`;

export const ColorContainer = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 13px;
  border: 2px solid transparent;
  margin: 0 5px 0 0;
  cursor: pointer;

  &.enabled {
    border: 2px solid rgba(0, 0, 0, 0.2);
  }
`;

interface IContainer {
  gray?: boolean;
  labelInRow?: boolean;
}

export const Container = styled.div<IContainer>`
  position: relative;
  display: flex;
  width: 100%;

  ${({ labelInRow }) =>
    labelInRow
      ? `
    gap: 12px;
    aling-items: center;
  `
      : `
    flex-direction: column;
  `}
  .ant-select-selector {
    height: 32px !important;
  }

  .ant-select-selector,
  .ant-input,
  .ant-picker {
    background-color: white;
    ${(props) =>
      props.gray &&
      `
            background-color: ${input_normal_bg_color} !important;
            border: 1px solid ${input_normal_bg_color} !important;
        `}
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:hover,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    ${(props) =>
      props.gray &&
      `
            border-color:${input_normal_bg_color} !important;
        `}
  }

  .select {
    width: 100%;

    .arrow-down {
      height: 8px;
      width: 8px;
      mask-size: contain;
      mask-position: center center;
      mask-repeat: no-repeat no-repeat;
      background: #666666;
    }

    &.margin {
      margin: 0 0 25px 0;
    }
  }

  .label-in-row {
    width: calc(100% - 76px) !important;
  }

  .error {
    border: 1px solid red !important;
  }

  &.containerMargin {
    margin: 0 0 25px 0;
  }

  .ant-select-selection-placeholder {
    opacity: 1 !important;
    color: ${globalStyleVariables.placeholderColor} !important;
  }

  .ant-input::placeholder {
    color: ${globalStyleVariables.placeholderColor} !important;
  }

  .ant-picker-input input::placeholder {
    color: ${globalStyleVariables.placeholderColor} !important;
  }
`;

export const IssueMessage = styled.div`
  font-size: 12px;
  color: red;
  margin: 5px 0 0 0;
`;

export const ModalContainer = styled.div`
  padding: 20px;
`;

export const Text = styled.div`
  font-size: 16px;
`;
