import _ from 'lodash';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
// import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { updateCompliancePercentage, ICohortCompliance, updateComplianceList, complianceStatus } from '../Reducer';
import { ComplianceProgress } from './ComplianceProgress';
import { getCohortOverview, addCohortOverview } from '../../Reducer';
import { expiryCheck } from 'src/App/LoginPage/store/Actions';
import { store } from 'src';

const ActionManager = {
    ADD_COMPLIANCES_PREVIEW: "ADD_COMPLIANCE_PREVIEW",
    ADD_COMPLY_SUBJECTS: "ADD_COMPLY_SUBJECTS",
    ADD_DONTCOMPLY_SUBJECTS: "ADD_DONTCOMPLY_SUBJECTS"
}

export interface ICompliancePreviewStore {
    cohortId: string,
    complianceId: string,
    preview: any;
    complyPagination: any;
    dontComplyPagination: any;
}

interface IAction {
    type: string;
    payload: ICompliancePreviewStore;
    cohortId: string,
    complianceId: string;
    subjects: any[],
    pagination: any;
}

const CohortCompliancePreviewReducer = (
    state: ICompliancePreviewStore[] = [],
    action: IAction
) => {
    switch (action.type) {
        case ActionManager.ADD_COMPLIANCES_PREVIEW: {
            let newState = _.cloneDeep(state)
            let complianceStore = newState.find((cs: ICompliancePreviewStore) => cs.cohortId === action.payload.cohortId && cs.complianceId === action.payload.complianceId);
            if (!complianceStore) {
                newState.push(action.payload);
                return newState;
            }
            return newState;
        }
        case ActionManager.ADD_COMPLY_SUBJECTS: {
            let newState = _.cloneDeep(state)
            let complianceStore = newState.find((cs: ICompliancePreviewStore) => cs.cohortId === action.cohortId && cs.complianceId === action.complianceId);
            if (!complianceStore) return newState;
            if (action.pagination.current > 1) {
                let isExist: boolean = isExistCompliancePreview(complianceStore.preview.successList, action.subjects);
                if (isExist) return newState;
                complianceStore.preview.successList = [...complianceStore.preview.successList, ...action.subjects];
            } else {
                complianceStore.preview.successList = action.subjects;
            }
            complianceStore.complyPagination = action.pagination;
            return newState;
        }
        case ActionManager.ADD_DONTCOMPLY_SUBJECTS: {
            let newState = _.cloneDeep(state)
            let complianceStore = newState.find((cs: ICompliancePreviewStore) => cs.cohortId === action.cohortId && cs.complianceId === action.complianceId);
            if (!complianceStore) return newState;
            if (action.pagination.current > 1) {
                let isExist: boolean = isExistCompliancePreview(complianceStore.preview.failedList, action.subjects);
                if (isExist) return newState;
                complianceStore.preview.failedList = [...complianceStore.preview.failedList, ...action.subjects];
            } else {
                complianceStore.preview.failedList = action.subjects;
            }
            complianceStore.dontComplyPagination = action.pagination;
            return newState;
        }
        default: {
            return state;
        }

    }
};

// to check whether a failedListItem already exist in the store
function isExistCompliancePreview(stateList: any, actionList: any): boolean {
    if (actionList && actionList.length > 0) {
        let action_subject: any = actionList[0];
        let isExist: any = stateList.find((subject: any) => {
            return subject.subjectId === action_subject.subjectId;
        });
        if (isExist) return true;
    }
    return false;
}


export default CohortCompliancePreviewReducer;

export const addCompliancePreview = (data: any) => {
    return {
        type: ActionManager.ADD_COMPLIANCES_PREVIEW,
        payload: data
    }
};
const addComplianceSubjects = (subjects: any, complyStatus: number, pagination: any, cohortId: string, complianceId: string) => {
    return {
        type: complyStatus === 1 ? ActionManager.ADD_COMPLY_SUBJECTS : ActionManager.ADD_DONTCOMPLY_SUBJECTS,
        subjects: subjects,
        pagination: pagination,
        cohortId: cohortId,
        complianceId: complianceId
    }
};

export const getCompianceFullPreview = (data: any, callback?: any, compliance?: any): any => {
    return (dispatch: any) => {
        // APIHandler.AxiosInstance.post(ApiUrl.GET_COMPLIANCE_PREVIEW, data, { withCredentials: true })
        //     .then(response => {
        //         if (response.data.success) {
        // let result: any = response.data.result;
        // let preview = result.preview;
        let complyPagination = {
            current: 1,
            total: compliance.complianceCount,
        }
        let dontComplyPagination = {
            current: 1,
            total: compliance.nonComplianceCount,
        }
        dispatch(
            addCompliancePreview({
                preview: { successList: [], failedList: [], },
                cohortId: data.cohortId,
                complianceId: data.complianceId,
                complyPagination: complyPagination,
                dontComplyPagination: dontComplyPagination
            })
        );
        dispatch(
            updateCompliancePercentage({
                cohortId: data.cohortId,
                complianceId: data.complianceId,
                complianceRate: compliance.complianceRate
            })
        );
        if (callback) callback();
        if (compliance.jobStatus === 0) {
            let incrementAction = new ComplianceProgress(data);
            incrementAction.getIncrementalPreview(
                dispatch,
                compliance.continueToken,
                callback
            );
        }
    }
    // })
    // .catch(error => {
    //     dispatch(expiryCheck(error));
    // });
};

export const setIncludeStatus = async (
    cohortName: string,
    cohortId: string,
    compliance: ICohortCompliance
) => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            "/Cohort/SetIncludeStatus?CohortId=" +
            cohortId +
            "&ComplianceId=" +
            compliance.id +
            "&Checked=" +
            !compliance.includeStatus +
            "&ruleVersion=" +
            compliance.ruleVersion, null, { withCredentials: true }
        )
        let result: any = response.data.result;
        if (result.success) {
            compliance["includeStatus"] = !compliance.includeStatus;
            store.dispatch(complianceStatus(false))
            store.dispatch(updateComplianceList(compliance, cohortId))
            const response = await getCohortOverview({ cohortName: cohortName })
            if (response.data.success) {
                store.dispatch(addCohortOverview(response.data.result.overview))
            }
        };
    } catch (e) {

    }
};


export const getComplianceSubjects = (
    data: any,
    current: number,
    callback: any,
): any => {
    return (dispatch: any) => {
        APIHandler.AxiosInstance.post("/Cohort/SearchRequirementSubjects", data, { withCredentials: true })
            .then(response => {
                if (response.data.success) {
                    let result = response.data.result
                    let pagination = {
                        current: current,
                        total: result.total
                    }

                    dispatch(
                        addComplianceSubjects(result.summary, data.category, pagination, data.cohortId, data.ruleId)
                    );
                    callback();
                }
            })
            .catch(error => {
                dispatch(expiryCheck(error));
            });
    };
};