import React, { FC } from 'react';

import Spinner from './Spinner';

import { LoadingContainer } from './styled';

export interface IProps {
  backgroundColor?: string;
  size?: number;
  paddingTop?: number;
}

const AbsoluteLoader: FC<IProps> = ({
  backgroundColor,
  size,
  paddingTop
}: IProps) => {
  return (
    <LoadingContainer
      style={{
        backgroundColor,
        paddingTop
      }}
    >
      <Spinner size={size} />
    </LoadingContainer>
  );
};

AbsoluteLoader.defaultProps = {
  backgroundColor: ' rgba(255,255,255,0.3)',
  paddingTop: 0
};

export default AbsoluteLoader;
