import React, { FC } from 'react';

import CombinedSelector, {
  IOutProps as ICombinedProps
} from 'src/Framework/Controls/CombinedSelector';
import { ITypeStanding } from '../../store/types';

import { Container } from './styled';
import { useGroupType } from 'src/App/Admin/Pages/GroupTypes/store/Hooks/useGroupType';

type IProps = ICombinedProps<ITypeStanding, number> & {};

const Component: FC<IProps> = (props: IProps) => {
  const typeStanding = useGroupType('typeStanding');
  return (
    <Container>
      <CombinedSelector
        {...props}
        loading={typeStanding.loading}
        data={typeStanding.genericTypes.map((v) => ({
          label: v.name,
          value: v.id,
          isActive: v.isActive,
          dto: v
        }))}
      />
    </Container>
  );
};

export default Component;
