import React, { FC } from 'react';

import SelectContainer from '../../SelectContainer';
import { ISingle } from './types';

type IProps = ISingle & {
  allClinicsOptions: any;
  options: any;
};

const Component: FC<IProps> = ({
  value,
  allClinicsOptions,
  options,
  mandatory,
  onChange,
  selectProps
}: IProps) => {
  return (
    <SelectContainer
      type="select"
      label="Clinic"
      value={value ? `${value}` : undefined}
      allOptions={allClinicsOptions}
      options={options}
      star={mandatory}
      onChange={(value: any, option: any) => onChange(value, option)}
      {...selectProps}
      inputProps={{
        allowClear: true,
        ...selectProps?.inputProps
      }}
    />
  );
};

export default Component;
