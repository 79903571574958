import React, { FC } from 'react';
import { Row, Checkbox } from 'antd';
import {
  FiltersContainer,
  FilterItem,
  CheckboxesContainer,
  FilterTitle
} from './styled';

import { useGenericType } from 'src/App/GenericTypes/store/Actions/hooks';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { IProvider } from 'src/App/Admin/Pages/Providers/store/types';

import { IFilters, IShowContainer } from './Multiple';

interface IProps {
  providers: {
    [key: string]: IProvider;
  };
  filters: IFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
  showContainers: IShowContainer;
  setShowContainers: React.Dispatch<React.SetStateAction<IShowContainer>>;
}

const Component: FC<IProps> = ({
  providers,
  showContainers,
  setShowContainers,
  filters,
  setFilters
}: IProps) => {
  const typeGroupProvider = useGenericType('typeGroupProvider');
  const typeGender = useGenericType('typeGender');
  return (
    <FiltersContainer className="custom-checkbox">
      <FilterItem>
        <Row
          align="middle"
          className="filter-item"
          onClick={() => {
            setShowContainers({
              ...showContainers,
              groups: !showContainers.groups
            });
          }}
        >
          <ButtonIcon
            active={showContainers.groups}
            name={ICONS_LIST.filterUsers}
            toggled={showContainers.groups}
            isButton={true}
            label="Filter provider by Groups"
            controlsId="groups-filter"
          />
          <FilterTitle>Filter provider by Groups</FilterTitle>
        </Row>
        {showContainers.groups && (
          <CheckboxesContainer id="groups-filter">
            {typeGroupProvider.allGenericTypes
              .filter((v) => {
                const arr = Object.values(providers);
                return arr.find((val) => val.groupId === v.id);
              })
              .map((v) => {
                const i = filters.groups.indexOf(v.id);
                return (
                  <div key={v.id}>
                    <Checkbox
                      checked={i !== -1}
                      onClick={() => {
                        const groups = [...filters.groups];
                        if (i === -1) {
                          groups.push(v.id);
                        } else {
                          groups.splice(i, 1);
                        }
                        setFilters({
                          ...filters,
                          groups
                        });
                      }}
                    >
                      {v.name}
                      <span className="visually-hidden">group</span>
                    </Checkbox>
                  </div>
                );
              })}
          </CheckboxesContainer>
        )}
      </FilterItem>
      <FilterItem>
        <Row
          align="middle"
          className="filter-item"
          onClick={() => {
            setShowContainers({
              ...showContainers,
              gender: !showContainers.gender
            });
          }}
        >
          <ButtonIcon
            isButton={true}
            label="Filter provider by Sex"
            name={ICONS_LIST.gender}
            toggled={showContainers.gender}
            active={showContainers.gender}
            controlsId="gender-filter"
          />
          <FilterTitle>Filter provider by Sex</FilterTitle>
        </Row>
        {showContainers.gender && (
          <CheckboxesContainer id="gender-filter">
            {typeGender.allGenericTypes.map((v) => {
              const i = filters.genders.indexOf(v.code);
              return (
                <div key={v.code}>
                  <Checkbox
                    checked={i !== -1}
                    onClick={() => {
                      const genders = [...filters.genders];
                      if (i === -1) {
                        genders.push(v.code);
                      } else {
                        genders.splice(i, 1);
                      }
                      setFilters({
                        ...filters,
                        genders
                      });
                    }}
                  >
                    {v.gender} <span className="visually-hidden">gender</span>
                  </Checkbox>
                </div>
              );
            })}
          </CheckboxesContainer>
        )}
      </FilterItem>
    </FiltersContainer>
  );
};

export default Component;
