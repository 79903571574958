import React, { FC, useState } from 'react';
import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/es/tooltip/index';
import cn from 'classnames';

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import AbsoluteLoader from '../AbsoluteLoader';
import { ICONS_LIST } from '../ButtonIcon';

import { Container, Icon } from './styled';

export type IconNames = keyof typeof ICONS_LIST;

const defaultSize = 22;

export interface IProps {
  /**
   * @param size Icon size
   * @default 22
   */
  size?: number;
  /**
   * @param name You can find all icons list here -> {@link IconNames}
   */
  name: IconNames;
  /**
   * @param color Icon color
   * @default #0e6d9f
   */
  color?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  containerStyles?: React.CSSProperties;
  tooltip?: string | React.ReactElement;
  enableHover?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
  enabled?: boolean;
  disabledColor?: string;
  isForWaitingList?: boolean;
  /**
   * @param asyncOnClick when you will use this param for click you will have loading until request not end
   */
  asyncOnClick?: () => Promise<any>;
  id?: string;
  onShowAnimation?: {
    // visible: boolean;
    rotate?: boolean;
    rotateCollapse?: boolean;
  };
  roundIcon?: boolean;
  rotate?: number;
  tabIndex?: number;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  inheritFontColor?: boolean;
  tooltipProps?: Partial<TooltipProps>;
}

/**
 * You can find all icons by this link http://localhost:3000/icons
 */
const Component: FC<IProps> = ({
  size,
  name,
  color,
  containerProps,
  tooltip,
  enableHover,
  onClick,
  enabled,
  disabledColor,
  asyncOnClick,
  id,
  onShowAnimation,
  containerStyles,
  roundIcon,
  isForWaitingList = false,
  rotate,
  onKeyDown,
  inheritFontColor,
  tabIndex = -1,
  tooltipProps
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const render = () => {
    let style: React.CSSProperties = {};

    if (roundIcon) {
      style = {
        ...style,
        padding: 5,
        borderRadius: 3
      };
    }
    if (containerStyles) {
      style = {
        ...style,
        ...containerStyles
      };
    }
    if (enabled) {
      const height = (size || defaultSize) + 5;
      style = {
        ...style,
        background: globalStyleVariables.newBrandingPrimary200,
        height,
        width: height,
        borderRadius: height / 2
      };
    }
    const aditionalStyles: any = {};
    if (rotate) {
      aditionalStyles.transform = `rotate(${rotate}deg)`;
    }

    return (
      <Container
        tabIndex={tabIndex}
        id={id}
        {...containerProps}
        onClick={async (e) => {
          if (onClick || asyncOnClick) {
            e.preventDefault();
            e.stopPropagation();
          }
          if (onClick) {
            onClick(e);
          }
          if (asyncOnClick) {
            if (loading) return;
            setLoading(true);
            await asyncOnClick();
            setLoading(false);
          }
        }}
        onKeyDown={onKeyDown}
        style={{
          height: size,
          width: size,
          cursor: asyncOnClick || onClick || tooltip ? 'pointer' : 'unset',
          ...style
        }}
        className={cn(containerProps?.className, {
          onShowAnimation: onShowAnimation ? true : false,
          // visible: onShowAnimation?.visible || false,
          rotate: onShowAnimation?.rotate || false,
          'rotate-collapse': onShowAnimation?.rotateCollapse || false
        })}
      >
        {loading && <AbsoluteLoader />}
        <Icon
          color={
            enabled
              ? 'white'
              : disabledColor || inheritFontColor
              ? 'currentColor'
              : color
          }
          enableHover={enableHover}
          style={{
            height: size,
            width: size,
            WebkitMaskImage: `url(${ICONS_LIST[name]})`,
            maskImage: `url(${ICONS_LIST[name]})`,
            ...aditionalStyles
          }}
        />
      </Container>
    );
  };
  if (tooltip || onShowAnimation) {
    return (
      <Tooltip
        placement={isForWaitingList ? 'topRight' : 'top'}
        showArrow={!isForWaitingList}
        title={tooltip}
        className="icons-tooltip"
        overlayStyle={{ zIndex: 60000 }}
        overlayInnerStyle={{ maxHeight: 256, overflowY: 'auto' }}
        {...tooltipProps}
      >
        {render()}
      </Tooltip>
    );
  }
  return render();
};

Component.defaultProps = {
  size: 22,
  color: globalStyleVariables.newBrandingPrimary200
};

export default React.memo(Component);
