import styled from "styled-components"

interface IHtmlContainer {
  isRawPreview: boolean
}

export const HtmlContainer = styled.div<IHtmlContainer>`
    ${({ isRawPreview }) => isRawPreview && `
    #rawPreview,
    #rawPreview::before,
    #rawPreview::after,
    #rawPreview *:not(img),
    #rawPreview *:not(img)::before,
    #rawPreview *:not(img)::after {
      all: revert;
    }
    `}
  `