import { store } from "src";
import { ISort, TempFieldsForTable } from "src/Framework/Controls/Table/types";
import { actionsTypes } from "..";
import { IProviderEvaluation } from "../types";

export const changeSort = ({
    providerId,
    payload
}: {
    providerId: string;
    payload: ISort;
}) => {
    store.dispatch({
        type: actionsTypes.SET_SORT,
        payload,
        providerId
    });
};

export const setSummary = (payload: Record<string, IProviderEvaluation>, providerId: string | number) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload,
        providerId
    })
}

export const onCreateUpdateItem = (payload: IProviderEvaluation & Partial<TempFieldsForTable>, providerId: string | number) => {
    const data = { ...store.getState().trainee.dashboard.evaluations.providers[providerId]?.summary || {} }
    if (data[payload.id]) {
        data[payload.id] = { ...data[payload.id], ...payload }
    } else {
        data[payload.id] = payload
    }
    setSummary(data, providerId)
}