import { TypedUseSelectorHook, useSelector, shallowEqual, createSelectorHook } from 'react-redux';
import {
    combineReducers,
} from 'redux';
import { legacy_createStore as createStore } from 'redux'
import _ from 'lodash';

import TempDataReducer from 'src/App/TempData/store'
import React from 'react';

const appReducer = combineReducers({
    tempData: TempDataReducer
});

export const TempContext = React.createContext(null);

export type IStore = ReturnType<typeof appReducer>;

export const store = createStore(
    appReducer
);

export const useTempAppSelector: TypedUseSelectorHook<IStore> = createSelectorHook(TempContext as any);

export const useTempShallowEqualSelector = <TReturn>(
    selector: (state: IStore) => TReturn
) => useSelector<IStore, TReturn>(selector, shallowEqual);

export default store;