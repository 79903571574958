import React from 'react';
import { Row, Col, Checkbox } from 'antd';

import Modal from 'src/Framework/Controls/Modal';
import * as containers from 'src/Framework/Controls/ModalTabulator/ModalTabulator_Style';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import Button from 'src/Framework/Controls/Button';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import test_ids from 'src/tests-script/pages/Subject/Forms';

import { ModalContainer } from './styled';

interface IProps {
  onClickApplyHandler: any;
  subjectId: any;
  filter: any;
  sliderMode?: boolean;
}

interface IState {
  data: object[];
  searchText: string;
  status: any;
  dateOneSelected: string | null;
  dateTwoSelected: string | null;
  showInvalid: boolean | null;
  openFilterModal: boolean;
}

const options = [
  { value: 'All', title: 'All' },
  { value: 'inProgress', title: 'In Progress' },
  { value: 'signed', title: 'Signed' },
  { value: 'locked', title: 'Locked' }
];

const defaultWidth = 600;
const title = ' Filter Forms';

class FilterFormModal extends React.Component<IProps, IState> {
  public attachmentsTableRef: any;
  public parentElement: React.MutableRefObject<any>;

  constructor(props: IProps) {
    super(props);
    const { filter } = props;
    this.parentElement = React.createRef();
    this.state = {
      data: [],
      searchText: '',
      status: filter.status,
      dateOneSelected: filter.dateBegin,
      dateTwoSelected: filter.dateEnd,
      showInvalid: filter.showInvalid,
      openFilterModal: false
    };
  }

  closeModal = () => {
    this.setState({ openFilterModal: false });
    if (this.parentElement.current) {
      (this.parentElement.current as HTMLElement).focus();
      this.parentElement.current = null;
    }
  };

  onChangeSelectHandler = (status: any) => {
    this.setState({ status });
  };

  onDateOneHandler = (value: any) => {
    this.setState({ dateOneSelected: value });
  };

  onDateTwoHandler = (value: any) => {
    this.setState({ dateTwoSelected: value });
  };

  onClickResetHandler = () => {
    this.setState({
      dateOneSelected: null,
      dateTwoSelected: null,
      status: null,
      showInvalid: null
    });
  };

  onApplyButtonHandler() {
    const status = this.state.status;
    this.props.onClickApplyHandler(
      this.state.dateOneSelected,
      this.state.dateTwoSelected,
      status,
      this.state.showInvalid
    );
    this.closeModal();
  }

  onBlurHandler = () => {
    const showInvalid = this.state.showInvalid;
    this.setState({ showInvalid: !showInvalid });
  };

  modalButtons(): any {
    return (
      <containers.Buttons>
        <Button
          color={globalStyleVariables.newBrandingPrimary200}
          colorStyles={{
            blueBorder: true,
            boldFont: true
          }}
          id={test_ids.formsFilterResetButton}
          onClick={() => {
            this.onClickResetHandler();
          }}
        >
          Reset
        </Button>
        <Button
          color={globalStyleVariables.newBrandingPrimary200}
          colorStyles={{
            blueBorder: true,
            boldFont: true
          }}
          id={test_ids.formsFilterApplyButton}
          onClick={() => {
            this.onApplyButtonHandler();
          }}
        >
          Apply
        </Button>
      </containers.Buttons>
    );
  }

  open = () => {
    this.parentElement.current = document.activeElement as HTMLElement;
    this.setState({ openFilterModal: true });
  };

  render() {
    return (
      <Modal
        id={test_ids.formsFilterModal}
        isModalSlider={this.props.sliderMode}
        modalSliderProps={{
          defaultWidth: `${defaultWidth}px`,
          minWidth: `${defaultWidth}px`,
          isOpen: this.state.openFilterModal,
          onClose: () => this.closeModal(),
          title: title,
          headerRight: this.modalButtons()
        }}
        title={title}
        width={defaultWidth}
        modalProps={{
          destroyOnClose: true
        }}
        onClose={() => this.closeModal()}
        visible={this.state.openFilterModal}
        buttons={this.modalButtons()}
      >
        <ModalContainer className="custom-checkbox">
          <Row gutter={16}>
            <Col span={12}>
              <SelectContainer
                star={false}
                type="date"
                label="Date from"
                value={this.state.dateOneSelected}
                onChange={(value) => {
                  this.onDateOneHandler(value);
                }}
              />
            </Col>
            <Col span={12}>
              <SelectContainer
                star={false}
                type="date"
                label="Date to"
                value={this.state.dateTwoSelected}
                onChange={(value) => {
                  this.onDateTwoHandler(value);
                }}
              />
            </Col>
          </Row>
          <SelectContainer
            star={false}
            label="Status"
            type="select"
            options={options}
            value={this.state.status}
            onChange={(e: any) => this.onChangeSelectHandler(e)}
          />
          <Checkbox
            onChange={() => this.onBlurHandler()}
            checked={this.state.showInvalid ? true : false}
          >
            Show deleted forms
          </Checkbox>
        </ModalContainer>
      </Modal>
    );
  }
}

export default FilterFormModal;
