import styled, { css } from 'styled-components';
import { MenuTitleLink, ContentText } from 'src/CommonStyles/fonts';
import {
  border_color,
  active_color,
  input_active_text_color,
  red_lines,
  blue_color
} from 'src/CommonStyles/styles';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const ListContainer = styled.div`
  height: calc(100vh - 132px - 56px);
  overflow-y: auto;
  border-right: 1px solid #e6e6e6;

  .list-item-content {
    display: flex;
    padding: 1rem 10px 1rem 1rem;
    position: relative;
    flex-flow: column;
    cursor: pointer;
    padding-left: 15px;

    &:hover,
    &:active,
    &:focus {
      background-color: ${globalStyleVariables.newBrandingHoverMenuItemBG};
    }

    border-bottom: 1px solid ${border_color};
  }

  .active {
    background-color: ${globalStyleVariables.newBrandingActiveMenuItemBG};
    //padding-left: 13px;
  }
`;

export const ListItemContent = styled.div`
  display: flex;
  padding: 1rem 10px 1rem 1rem;
  position: relative;
  flex-flow: column;
  cursor: pointer;
  padding-left: 15px;

  &:hover,
  &:active,
  &:focus {
    background-color: ${globalStyleVariables.newBrandingHoverMenuItemBG};
  }

  &.active {
    background-color: ${globalStyleVariables.newBrandingActiveMenuItemBG};
    //padding-left: 13px;
  }

  border-bottom: 1px solid ${border_color};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3px;
  min-height: 35px;
`;

export const StatusAndTitle = styled.div`
  display: flex;
`;

export const Title = styled(MenuTitleLink)`
  display: flex;
  align-items: center;
  color: ${input_active_text_color};
  margin-left: 6px;
  line-height: 18px;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Date = styled(ContentText)`
  display: flex;
  align-items: center;
  color: ${input_active_text_color};
  flex-grow: 8;
  font-size:14px;
`;

export const NoteType = styled.span`
  background-color: ${border_color};
  border-radius: 8px;
  text-align: center;
  height: 28px;
  padding: 3px 10px;
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 234px;
`;

export const Send = styled.span`
  ${(props: { viaPortal: any }) =>
    props.viaPortal &&
    css`
      svg > path {
        stroke: white;
        fill: ${blue_color};
      }

      svg > circle {
        fill: ${blue_color};
        stroke: white;
      }
    `}
`;

export const IconContainer: any = styled.div`
  width: 24px;
  cursor: pointer;

  svg > path {
    stroke: ${(props: { color: any }) => props.color && props.color};
  }
`;
export const IconWithoutBackContainer: any = styled.div`
  cursor: pointer;
  height: 32px;

  svg > path {
    fill: none;
  }
`;

export const IconContainerGreen: any = styled.div`
  width: 24px;
  cursor: pointer;

  svg > path {
    stroke: ${active_color};
  }
`;

export const RightIcon: any = styled.div`
  svg > path {
    fill: none;
  }

  svg > rect {
    fill: ${red_lines};
  }

  svg > circle {
    stroke: ${red_lines};
  }
`;

export const NoteListIcons: any = styled.div`
  display: flex;

  .iconContainer {
    margin-left: 12px;
    height: 24px;
    font-size: 1rem !important;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const Invalid: any = styled.span`
  text-decoration: line-through;
`;
