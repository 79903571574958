import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Wrapper = styled.div`
  .column__one {
    min-width: 350px;
  }

  .column__two {
    min-width: 75px;
  }

  .column__three {
    min-width: 350px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-flow: column;

  .header {
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 74px;
      padding: 0 24px;

      &__title {
        display: flex;
        align-items: center;

        h1 {
          font-family: Encode Sans Expanded;
          font-style: normal;
          font-weight: 800;
          font-size: 18px;
          color: #000;
        }
      }

      &__actions {
        display: flex;
        align-items: center;

        button {
          background: white;
          border-radius: 50px;
          font-family: Lato;
          font-weight: bold;
          font-size: 14px;
          width: auto;
          padding: 0 16px;
          height: 32px;
          display: flex;
          align-items: center;
          text-align: center;

          &.ant-btn {
            border: 1px solid ${globalStyleVariables.newBrandingPrimary200};
            color: ${globalStyleVariables.newBrandingPrimary200};
          }

          &.cancel {
            color: #666666;
            border: none;
            outline: none;
          }
        }
      }
    }

    &__table {
      border-top: 1px solid #e6e6e6;
      height: 32px;
      background: #f7f7f7;
      display: flex;
      align-items: center;
      padding-left: 24px;

      p {
        font-family: Lato;
        font-weight: bold;
        font-size: 12px;
        color: #000000;
        margin: 0;
      }
    }
  }
`;

export const Content = styled.div`
  height: calc(100vh - 180px);
  overflow: auto;
`;

export const Table = styled.div`
  display: flex;
  flex-flow: column;
  padding-bottom: 50px;
`;

export const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-height: 40px;
  padding-left: 24px;

  p {
    font-family: Lato;
    font-size: 12px;
    color: #000000;
    margin: 0;
  }

  .select-column {
    .select__custom__control {
      min-height: 24px;
      width: 60px;
      border-radius: 2px;

      .select__custom__value-container {
        padding: 0 0 0 4px;

        .select__custom__single-value {
          margin: 0;
        }
      }

      .select__custom__indicators {
        .select__custom__indicator {
          padding: 0 8px 0 0;
        }
      }
    }
  }
`;
