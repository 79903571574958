import _ from 'lodash';

import { IStateReducer as IMessagesReducer, IPendingMessage } from 'src/Activities/Home/Messages/store/types'
import { initialState as initialData } from 'src/Activities/Home/Messages/store/Reducer'

const prefix = 'PATIENT_MESSAGES_'

export const actionsTypes = {
    SET: `${prefix}DATA`,
    SET_SELECTED_CATEGORY: `${prefix}SELECTED_CATEGORY`,
    SET_FILTER: `${prefix}FILTER`,
    SET_FILTERED_LIST: `${prefix}FILTERED_LIST`,
    SET_STATUSES: `${prefix}SET_STATUSES`,
    SET_OPENED_ROWS: `${prefix}SET_OPENED_ROWS`,
    SET_LOADING: `${prefix}SET_LOADING`,
    SET_LOADING_MORE: `${prefix}SET_LOADING_MORE`,
    SET_SELECTED_PROVIDERS: `${prefix}SET_SELECTED_PROVIDERS`,
    SET_FOLLOW_UP: `${prefix}SET_FOLLOW_UP`,
    SET_PROVIDERS: `${prefix}SET_PROVIDERS`,
    SET_ALL_MESSAGES: `${prefix}SET_ALL_MESSAGES`,
    SET_INITIAL_DATA: `${prefix}SET_INITIAL_DATA`,
    SET_INTERNAL_MESSAGES: `${prefix}SET_INTERNAL_MESSAGES`,
    SET_SECURE_MESSAGES: `${prefix}SET_SECURE_MESSAGES`,
    SET_PAGINATION: `${prefix}SET_PAGINATION`,
};

interface IStateReducer {
    patients: {
        [key: string]: IMessagesReducer
    }
}

interface IAction {
    type: string;
    payload: any;
    patientId: string;
    pendingMessage?: IPendingMessage
}

export const initialFilter = { text: '', priorities: [], statuses: [], isFlagged: false, providerIds: [], after: null, before: null, recipient: null }

export const data = {
    ..._.cloneDeep(initialData),
    selectedCategory: 'secure:sys0'
}

const initialState: IStateReducer = {
    patients: {}
};

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction): IStateReducer => {
    if (action.patientId) {
        if (!state.patients[action.patientId]) {
            state.patients[action.patientId] = _.cloneDeep(initialData)
        }
    }
    switch (action.type) {
        case actionsTypes.SET_SECURE_MESSAGES: {
            const patientData = {
                ...state.patients[action.patientId],
                secureMessages: action.payload
            }
            const data = {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: patientData
                }
            }
            if (action.pendingMessage) {
                patientData.pendingMessages = { ...patientData.pendingMessages, [action.pendingMessage.id]: action.pendingMessage }
            }
            patientData.pendingMessages = _.pickBy(patientData.pendingMessages, (value) => value.isActive);
            return data
        }
        case actionsTypes.SET_INTERNAL_MESSAGES: {
            const patientData = {
                ...state.patients[action.patientId],
                internalMessages: action.payload
            }
            const data = {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: patientData
                }
            }
            if (action.pendingMessage) {
                patientData.pendingMessages = { ...patientData.pendingMessages, [action.pendingMessage.id]: action.pendingMessage }
            }
            patientData.pendingMessages = _.pickBy(patientData.pendingMessages, (value) => value.isActive);
            return data
        }
        case actionsTypes.SET_ALL_MESSAGES:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        internalMessages: action.payload
                    }
                }

            }
        case actionsTypes.SET_LOADING:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        loading: action.payload
                    }
                }
            }
        case actionsTypes.SET_PAGINATION:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        pagination: {
                            ...state.patients[action.patientId].pagination,
                            ...action.payload
                        }
                    }
                }
            }
        case actionsTypes.SET_LOADING_MORE:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        loadingMore: action.payload
                    }
                }
            }
        case actionsTypes.SET_OPENED_ROWS:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        openedRows: action.payload
                    }
                }
            }
        case actionsTypes.SET:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        messages: action.payload
                    }
                }
            }
        case actionsTypes.SET_SELECTED_CATEGORY: {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        selectedCategory: action.payload,
                        openedRows: [],
                        pendingMessages: {}
                    }
                }
            }
        }
        case actionsTypes.SET_FILTER:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        filter: {
                            ...state.patients[action.patientId].filter,
                            ...action.payload
                        }
                    }
                }
            }
        case actionsTypes.SET_INITIAL_DATA:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...data
                    }
                }
            }
        default:
            return state;
    }
};

export default Reducer;