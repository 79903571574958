import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const ModalContainer = styled.div`
  padding: 20px;
`;

export const Text = styled.div`
  font-size: 16px;
`;

export const ButtonContainer = styled.div`
  .secondColorBackground {
    color: white;
    background: ${globalStyleVariables.newBrandingPrimary200};
  }

  .greenButton {
    color: ${globalStyleVariables.green};
    background: #eefaea;
    border-color: #eefaea;
    font-weight: bold;
  }

  .successButton {
    color: ${globalStyleVariables.newBrandingSuccessText};
    background: #eefaea;
    border-color: #eefaea;
    font-weight: bold;
  }

  .greenBorder {
    color: ${globalStyleVariables.green};
    border-color: ${globalStyleVariables.green};
  }

  .successBorder {
    color: ${globalStyleVariables.newBrandingSuccessText};
    border-color: ${globalStyleVariables.newBrandingSuccessText};
  }

  .orangeButton {
    color: ${globalStyleVariables.orange};
    border-color: ${globalStyleVariables.orange};
    background: transparent !important;
  }

  .transparent {
    background: transparent !important;
  }

  .greyBorder {
    color: ${globalStyleVariables.brandGray};
    border-color: ${globalStyleVariables.brandGray};
  }

  .blueBorder {
    color: ${globalStyleVariables.newBrandingPrimary200};
    border-color: ${globalStyleVariables.newBrandingPrimary200};
  }

  .redBorder {
    color: red;
    border-color: red;
  }

  .blue {
    color: ${globalStyleVariables.newBrandingPrimary200};
    border-color: transparent;
    background: #e7f0f5;
  }

  .boldFont {
    font-weight: bold;
  }

  .greyButton {
    filter: grayscale(1);
  }

  .disabled {
    pointer-events: none;
  }

  .text-button {
    width: 100%;
    border: none;
    background-color: transparent;
    box-shadow: none;
    color: black;
    font-size: 12px !important;

    &:active,
    &:focus,
    &:hover {
      background-color: transparent;
      color: black;
    }

    &.ant-btn {
      padding: 0 !important;
    }
  }
`;
