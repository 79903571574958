//----------------------------New Action Types-----------------------------------------------------------------
//RuleDefinitionActiontype
export const ADD_RULE="ADD_RULE";
export const REMOVE_RULE="REMOVE_RULE";
export const ADD_RULE_COMPONENT="ADD_RULE_COMPONENT";
export const MOVE_RULE_COMPONENT="MOVE_RULE_COMPONENT";
export const REMOVE_RULE_COMPONENT="REMOVE_RULE_COMPONENT";
export const SELECT_OPTION="SELECT_OPTION";
export const ADD_REMOVED_COMPONENT="ADD_REMOVED_COMPONENT";
export const CREATE_RULE_COMPONENT="CREATE_RULE_COMPONENT";
export const ADD_VALIDATE_RESULT="ADD_VALIDATE_RESULT";
export const ACCEPT_CHANGES="ACCEPT_CHANGES";

//RuleTemplateActiontype
export const ADD_RULE_TEMPLATE = "ADD_RULE_TEMPLATE";

//RuleOptionActionType
export const ADD_COMPONENT_OPTION = "ADD_COMPONENT_OPTION";
export const HIDE_RULE_OPTION_MODEL = "HIDE_RULE_OPTION_MODEL";
export const CLEAR_OPTION="CLEAR_OPTION";


//RuleDetails


//ImmunizationActionType


export const ADD_IMMUNIZATIONS="ADD_IMMUNIZATIONS";
export const ADD_IMMUNIZATIONS_SERIES="ADD_IMMUNIZATIONS_SERIES";
export const UPDATE_ACTION_STATUS="UPDATE_ACTION_STATUS";
export const UNDO_UPDATE_ACTION="UNDO_UPDATE_ACTION";
export const REDO_UPDATE_ACTION="REDO_UPDATE_ACTION";
export const ADD_PROCEDURES = "ADD_PROCEDURES";
export const ADD_IMMUNIZATION_PROCEDURES = "ADD_IMMUNIZATION_PROCEDURES";

export const CLEAR_RULE_EDITOR = "CLEAR_RULE_EDITOR";
export const REMOVE_ACTION_STATUS = "REMOVE_ACTION_STATUS";

export const CLEAR_VALIDATION_RESULT="CLEAR_VALIDATION_RESULT";
export const UPDATE_LOGICAL_OPERATOR="UPDATE_LOGICAL_OPERATOR";
export const UPDATE_RULE_TO_SHOW="UPDATE_RULE_TO_SHOW";




