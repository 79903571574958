import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

import * as Setters from './Setters';
import {
  EnumM1FormBuildingBlockType,
  IBlockRequest,
  IBuildBlocks,
  IFilters,
  IFullBuildBlock,
  ISimpleBuildBlock,
  ISimpleBlockVersion,
  ModelFormat,
  IFullBuildBlockVersion,
  IExtendedBuildBlock
} from '../types';

export const setFilters = (payload: Partial<IFilters>) => {
  Setters.filters(payload);
};

export const exportM1BuildingBlock = async (id: number): Promise<any> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.M1FormsBuildingBlocks}/${id}/export`,
      {
        withCredentials: true,
      }
    );
    if (response.data.success) {
      const data = response.data.result.content;
      return data;
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const importM1BuildingBlock = async (data: any): Promise<any> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.M1FormsBuildingBlocks}/import`,
      data,
      {
        withCredentials: true,
      }
    );
    if (response.data) {
      const data = response.data;
      return data;
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const get = async ({
  IncludeSharedFormInformation
}: {
  IncludeSharedFormInformation?: boolean
} = {}): Promise<
  Record<string, IBuildBlocks> | undefined
> => {
  try {
    Setters.loading(true);
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl['M1FormsBuildingBlocks'],
      {
        withCredentials: true,
        params: {
          format: ModelFormat.Simple,
          onlyLatest: true,
          types: [EnumM1FormBuildingBlockType.Content],
          IncludeSharedFormInformation
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      Setters.summary(data);
      return data;
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
    Setters.loading(false);
  }
};

export const post = async (
  block: IBlockRequest
): Promise<Record<string, ISimpleBuildBlock> | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl['M1FormsBuildingBlocks'],
      block,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      Setters.updates(data);
      return data;
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const patch = async (
  data: Partial<ISimpleBuildBlock> & { id: number }
): Promise<IExtendedBuildBlock | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.M1FormsBuildingBlocks}/${data.id}`,
      data,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const list: IExtendedBuildBlock[] = Object.values(response.data.result.summary)
      const data = list[0];
      if (data) {
        return data;
      }
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const deleteRequest = async (
  id: number
): Promise<boolean | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.delete(
      `${ApiUrl.M1FormsBuildingBlocks}/${id}`,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      return true
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const patchVersion = async (
  buildingBlockId: number,
  data: Partial<ISimpleBlockVersion> & { id: number }
): Promise<IExtendedBuildBlock | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.M1FormsBuildingBlocks}/${buildingBlockId}/versions/${data.id}`,
      data,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const list: IExtendedBuildBlock[] = Object.values(response.data.result.summary)
      const data = list[0];
      if (data) {
        return data;
      }
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

const parseScheme = (data: IFullBuildBlock) => {
  const schema = data.versions[0]?.schema
  if (schema) {
    try {
      data.versions[0].schema = JSON.parse(schema)
    } catch (e) {
      console.log('schema not parsed')
    }
  }
  console.log({ data })
  return data
}

export const postBuildingBlockVersion = async (data: Partial<IFullBuildBlockVersion> & {
  id: number
}): Promise<
  IExtendedBuildBlock | undefined
> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.M1FormsBuildingBlocks}/${data.id}/versions`,
      data,
      {
        withCredentials: true,
      }
    );
    if (response.data.success) {
      const list: IExtendedBuildBlock[] = Object.values(response.data.result.summary)
      const data = list[0];
      if (data) {
        return data
      }
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const getFullBuildingBlockForPreview = async (id: number | string, options: { disableOnlyPublished?: boolean }): Promise<
  IFullBuildBlock | undefined
> => {
  const { disableOnlyPublished } = options
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.M1FormsBuildingBlocks,
      {
        withCredentials: true,
        params: {
          id,
          onlyLatest: true,
          onlyPublished: disableOnlyPublished ? false : true,
          format: ModelFormat.Full
        }
      }
    );
    if (response.data.success) {
      const list: IFullBuildBlock[] = Object.values(response.data.result.summary)
      const data = list[0];
      if (data) {
        return parseScheme(data)
      }
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const getFullBuildingBlock = async (id: number | string): Promise<
  IFullBuildBlock | undefined
> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.M1FormsBuildingBlocks,
      {
        withCredentials: true,
        params: {
          id,
          onlyLatest: true,
          IncludeSharedFormInformation: true,
          format: ModelFormat.Full
        }
      }
    );
    if (response.data.success) {
      const list: IFullBuildBlock[] = Object.values(response.data.result.summary)
      const data = list[0];
      if (data) {
        return parseScheme(data)
      }
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const shareM1BuldingBlockTemplate = async (id: number): Promise<any> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.ShareFormBuildingBlockTemplate}/buildingBlocks/share/${id}`,
      {},
      {
        withCredentials: true,
      }
    );
    if (response.data.success) {
      return true
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};