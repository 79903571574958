import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import store from "src/store";

export const GET_PATIENT_INFO = "GET_PATIENT_INFO";
export const SAVE_PATIENT_INFO = "SAVE_PATIENT_INFO";
export const SET_PATIENT_INFO_VALIDATION = "SET_PATIENT_INFO_VALIDATION";
export const REMOVE_PATIENT_INFO = "REMOVE_PATIENT_INFO";
export const MERGE_PATIENT_INFO = "MERGE_PATIENT_INFO";

export const setPatientInfo = (payload: any, subjectId: any) => {
  store.dispatch({
    type: GET_PATIENT_INFO,
    payload,
    subjectId,
  });
};

export const mergePatientInfo = (patientInfo: any, subjectId: any) => {
  return {
    type: MERGE_PATIENT_INFO,
    payload: patientInfo,
    subjectId: subjectId,
  };
};

export const removePatientInfo = (subjectId: any) => {
  return {
    type: REMOVE_PATIENT_INFO,
    subjectId: subjectId,
  };
};

export const savePatientInfo = async (request: any, dispatch: any, callback?: any) => {
  try {
    const response = await APIHandler.AxiosInstance.post(ApiUrl.SAVE_PATIENT_INFO, request, { withCredentials: true });
    if (response.data.success) {
      const subjectId = request.subjectId;
      handleSavePatientInfoResponse(response, dispatch, subjectId, request.refresh, callback);
      return response
    }
    return
  } catch (e) {
    return
  }
};

export const validateEmail = (email: any) => {
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let result: any;
  if (email.value) result = pattern.test(String(email?.value).toLowerCase());
  if (email && email.value && result) {
    email.hasValidationEmail = false;
    email.validationEmailError = "";
    return true;
  } else {
    email.hasValidationEmail = true;
    email.validationEmailError = "Email is not valid";
    return false;
  }
}

export const validateField = (field: any, validation: number, showValidation: boolean) => {
  if (field.required && field.required === true) {
    if (field.value === null || field.value === undefined || field.value === "") {
      field.hasValidation = showValidation;
      field.validationError = "Field is mandatory";
    }
    else {
      validation--;
      field.hasValidation = false;
      field.validationError = undefined;
    }
  }
  return validation;
};

function handleSavePatientInfoResponse(response: any, dispatch: any, subjectId: any, refresh: boolean = false, callback?: any) {
  if (refresh && response.data && response.data.success && response.data.result) {
    if (subjectId <= 0) {
      subjectId = response.data.result.id;
    }
    if (subjectId > 0) {
      dispatch(updatePatientInfo(response.data.result, subjectId));
      if (callback) {
        callback(subjectId);
      }
    }
  }
}

export const updatePatientInfo = (patientInfo: any, subjectId: any) => {
  return {
    type: SAVE_PATIENT_INFO,
    payload: patientInfo,
    subjectId: subjectId,
  };
};
