import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FormNameContainer = styled.div`
  padding: 24px 24px 0 24px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 0 4px 0;

  .modal-forms-list {
    flex: 1 1 300px;
    overflow-y: auto;
  }

  .modal-forms-list-item {
    margin: 0 24px;
  }
`;

export const ListHeader = styled.div`
  background-color: ${globalStyleVariables.grey};
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: bold;
  font-size: 12px;
  flex: 0 0 36px;
`;
export const TagsContainer = styled.div`
  margin: 0 0 10px 0;
  min-height: 30px;
`;

export const ModalContainer = styled.div`
  height: 100%;
`;
