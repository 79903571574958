export interface IData {
    value: {
        fileId: number;
    };
}

export enum ContentTypes {
    html = '.html',
    txt = '.txt',
    pdf = '.pdf',
    json = '.json',
    tif = '.tif',
    tiff = '.tiff',
    bmp = '.bmp',
    jpg = '.jpg',
    png = '.png',
    jpeg = '.jpeg',
}

export enum Formats {
    tiff = 'image/tiff',
    html = 'text/html',
    pdf = 'application/pdf',
    text = 'text/plain',
    json = 'application/JSON',
    bmp = 'image/bmp',
    jpg = 'image/jpeg',
    png = 'image/png',
    avif = 'image/avif'
}