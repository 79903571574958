import _ from 'lodash';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { CohortActionManager } from '../../Reducer';

const ActionManager = {
    ADD_COHORT_MEMBERS_COMPLIANCES: "ADD_COHORT_MEMBERS_COMPLIANCES",
}

export interface IMemberComplianceStore {
    cohortId: string;
    subjectId: string;
    pagination: any;
    compliances: IMemberCompliance[];
}

export interface IMemberCompliance {
    id: string;
    ruleName: string;
    status: number;
    ruleVersion: number;
}

interface IAction {
    type: string;
    payload: IMemberComplianceStore;
    id: string;
}

const MemberComplianceReducer = (
    state: IMemberComplianceStore[] = [],
    action: IAction
) => {
    switch (action.type) {
        case ActionManager.ADD_COHORT_MEMBERS_COMPLIANCES: {
            let complianceStore = state.find((store: IMemberComplianceStore) => store.cohortId === action.payload.cohortId && store.subjectId === action.payload.subjectId);
            if (!complianceStore) {
                state.push(action.payload);
                return [...state];
            }
            if (action.payload.pagination.current > 1) {
                let isExist: boolean = isExists(complianceStore.compliances, action.payload.compliances);
                if (isExist) return [...state];
                complianceStore.pagination = action.payload.pagination;
                complianceStore.compliances = [...complianceStore.compliances, ...action.payload.compliances];
            } else {
                state[state.indexOf(complianceStore)] = action.payload;
            }
            return [...state];

        }
        case CohortActionManager.CLEAR_COHORT: {
            let memberCompliances = state.filter((o: IMemberComplianceStore) => o.cohortId === action.id);
            for (let i = 0; i < memberCompliances.length; i++) {
                let index = state.indexOf(memberCompliances[i]);
                state.splice(index, 1);
            }
            return [...state];
        }
        default: {
            return state;
        }

    }
};
export default MemberComplianceReducer;

export const addCohortMemberCompliances = (data: any) => {
    return {
        type: ActionManager.ADD_COHORT_MEMBERS_COMPLIANCES,
        payload: data
    }
};

export const getCohortMemberCompliances = (data: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_COHORT_MEMBERS_COMPLIANCES, data, { withCredentials: true });
};
export const getSubjcetBasicInfo = (subjectId: any) => {
    return APIHandler.AxiosInstance.get(ApiUrl.GET_SUBJECTS_BASIC_INFO + subjectId, { withCredentials: true });
};

// to check whether a rule already exist in the store
function isExists(stateList: any, actionList: any): boolean {
    if (actionList && actionList.length > 0) {
        let actionItem: any = actionList[0];
        let isExistRule: any = stateList.find((item: any) => {
            return item.id === actionItem.id;
        });
        if (isExistRule) return true;
    }
    return false;
}
