interface IConstructor {
    key: string
    subscribeByDefault?: boolean
}

type IStatus = "error" | "warning" | 'success'

interface ILog {
    date: Date,
    message: string
    data: any,
    parsedData: any
    status?: any
}

const parse = (data: string) => {
    try {
        return JSON.parse(data)
    } catch (e) {
        return data
    }
}

const DefaultSubscriptionKey = (key: string) => `Logger-${key}-DefaultSubscription`

const statusColors: {
    [key in IStatus]: string
} = {
    error: 'red',
    warning: 'yellow',
    success: 'green'
}

class Logger {
    static loggerInstances: Record<string, Logger> = {}
    private logData: ILog[] = []
    private subscribed = false;
    private key = ''
    constructor({ key, subscribeByDefault }: IConstructor) {
        this.key = key
        window[key] = this
        Logger.loggerInstances[key] = this
        const defaultSubscription = localStorage.getItem(DefaultSubscriptionKey(this.key))
        this.subscribed = subscribeByDefault || false || defaultSubscription === 'true'
    }
    setSubscriptionByDefault = () => {
        localStorage.setItem(DefaultSubscriptionKey(this.key), 'true')
        console.log(`%c (${this.key}) Default subscription enabled`, 'color:lightgreen;')
        this.subscribe()
    }
    unsetSubscriptionByDefault = () => {
        localStorage.removeItem(DefaultSubscriptionKey(this.key))
        console.log(`%c (${this.key}) Default subscription disabled`, 'color:yellow;')
        this.unsubscribe()
    }
    log = (message: string, data?: any, status?: IStatus) => {
        const newItem = {
            date: new Date(),
            message: message || '',
            data: data || '',
            parsedData: parse(data),
            status: status || 'success',
            loggerKey: this.key
        }
        const pushHandlerLog = this.loggedData
        this.loggedData = [...pushHandlerLog, newItem]
        if (this.subscription) {
            console.groupCollapsed('%c  %c' + ` ${newItem.message}${newItem.status ? ` - ${newItem.status}` : ''}`, 'background-color: ' + statusColors[newItem.status] || 'black' + '; margin-right: 10px', 'background-color: transparent');
            console.table([newItem]);
            console.groupEnd();
        }


    }
    clear = () => {
        this.loggedData = []
        console.log(`%c (${this.key}) logger log cleared`, 'color:lightgreen;')
        return ''
    }
    subscribe = () => {
        this.subscription = true
        console.log(`%c (${this.key}) logger subscribed`, 'color:lightgreen;')
        return ''
    }
    unsubscribe = () => {
        this.subscription = false
        console.log(`%c (${this.key}) logger unsubscribed`, 'color:yellow;')
        return ''
    }
    showLog = () => {
        console.groupCollapsed('Open to see log');
        this.loggedData.forEach((item) => {
            console.groupCollapsed('%c  %c' + ` ${item.message}${item.status ? ` - ${item.status}` : ''}`, 'background-color: ' + statusColors[item.status] || 'black' + '; margin-right: 10px', 'background-color: transparent');
            console.table([item]);
            console.groupEnd();
        })
        console.groupEnd();
    }
    private get subscription() {
        return this.subscribed
    }
    private set subscription(value) {
        this.subscribed = value
    }
    private get loggedData() {
        return this.logData
    }
    private set loggedData(value) {
        this.logData = value
    }
}

declare global {
    interface Window {
        loggerInstances: typeof Logger.loggerInstances;
    }
}

window.loggerInstances = Logger.loggerInstances;

export default Logger