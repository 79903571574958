import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';
import logo from 'src/Framework/Common/Svg/new-logo.svg';

export const Container = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 50000;
`;

export const Company = styled.div`
  position: relative;

  .logo-image {
    width: 86px;

    g {
      fill: ${globalStyleVariables.white};
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 25px;
    right: 25px;
    top: 0;
    bottom: 5px;
    pointer-events: none;
    border-bottom: 1px solid ${globalStyleVariables.white};
  }
`;

export const Message = styled.div`
  font-family: Encode Sans Expanded;
  font-size: 32px;
  font-weight: 250;
  line-height: 40px;
  text-align: center;
`;

export const Description = styled.div`
  font-family: Encode Sans Expanded;
  font-size: 14px;
  font-weight: 800;
  line-height: 17.5px;
  text-align: center;
  width: 220px;
`;

export const MessageContainer = styled.div`
  width: 276px;
  height: 264px;
  background-color: ${globalStyleVariables.newBrandingPrimary300};
  color: ${globalStyleVariables.white};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 38px;

  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position-y: -20px;
  background-size: contain;
`;
