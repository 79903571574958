import React, { ErrorInfo } from 'react';
import { Container, Title } from './styled';
import Button from '../Controls/Button';
import * as serviceWorkerRegistration from '../../registerServiceWorker';

interface IProps {}

interface IState {
  hasError: boolean;
  errorMessage: any;
  errorInfo: any;
  error: null | Error;
}

class AppErrorBoundary extends React.Component<IProps, IState> {
  state: IState = {
    hasError: false,
    errorMessage: '',
    errorInfo: {},
    error: null
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.logErrorToServices(error.toString(), errorInfo.componentStack);
    this.setState({ errorInfo, error });
  }
  clearCacheAndReload = async () => {
    serviceWorkerRegistration.unregister();
    const cacheNames = await caches.keys();
    const arr = cacheNames.map((cacheName) => {
      console.log('deleting cache', cacheName);
      return caches.delete(cacheName);
    });
    await Promise.all(arr);
    window.location.reload();
  };
  logErrorToServices = (errorString: string, componentStack: any) => {};
  render() {
    const { hasError, errorInfo, error } = this.state;
    if (hasError) {
      return (
        <Container>
          <img
            src={require('src/Framework/Common/Svg/new-logo-big.svg').default}
            alt="logo"
            width="221"
          />
          {error?.name === 'ChunkLoadError' ? (
            <>
              <Title>
                This application has been updated
                <br />
                Please refresh your browser to see the latest content.
              </Title>
              <Button
                id=""
                onClick={async () => {
                  await this.clearCacheAndReload();
                }}
              >
                Refresh
              </Button>
            </>
          ) : (
            <>
              <Title>Something went wrong</Title>
              <p>There was an error in loading this page.</p>
              <Button
                id=""
                onClick={async () => {
                  await this.clearCacheAndReload();
                }}
              >
                Try again
              </Button>
              <div style={{ marginTop: 20 }} className="error-card-my-5">
                <div className="error-card-header"></div>
                <div className="error-card-body">
                  <details className="error-details">
                    <summary>Click for error details</summary>
                    {errorInfo && errorInfo?.componentStack?.toString()}
                  </details>
                </div>
              </div>
            </>
          )}
        </Container>
      );
    }
    return this.props.children;
  }
}
export default AppErrorBoundary;
