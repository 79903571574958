import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 16px;
`;

export const Loader = styled.div`
  width: 32px;
  height: 32px;
  border: 3px solid rgb(83, 200, 46, 0.1);
  border-right: 3px solid ${globalStyleVariables.newBrandingPrimary200};
  border-top: 3px solid ${globalStyleVariables.newBrandingPrimary200};
`;

export const Message = styled.div`
  margin-left: 16px;
`;

export const WithoutTitleContainer = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Actions = styled.div`
  cursor: pointer;
`;
