import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

interface IContainer {
  marginBottom?: boolean;
  disableFitContent?: boolean
}

export const Container = styled.div<IContainer>`
  position: relative;
  ${({disableFitContent})=>!disableFitContent && `
    width: fit-content;
  `}
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${globalStyleVariables.newBrandingPrimary200};
    border-color: ${globalStyleVariables.newBrandingPrimary200};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${globalStyleVariables.newBrandingPrimary200};
  }
  ${({ marginBottom }) => marginBottom && `margin-bottom: 20px;`}
`;
