import _ from 'lodash';

import { IAction, IStateReducer } from './types';

const prefix = 'PLAN_';

export const actionsTypes = {
  SET: `${prefix}SET`,
  SET_LOADING: `${prefix}SET_LOADING`,
  SET_FILTER: `${prefix}SET_FILTER`,
  SET_TABLE_SORT: `${prefix}SET_SORT`
};

const initialState: IStateReducer = {
  summary: {},
  loading: false,
  filter: {
    search: '',
    showInactive: false
  },
  sort: {
    sortBy: 'code',
    sortType: 'asc'
  }
};

const Reducer = (
  state: IStateReducer = { ..._.cloneDeep(initialState) },
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload
        }
      };
    case actionsTypes.SET:
      return {
        ...state,
        summary: action.payload
      };
    case actionsTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionsTypes.SET_TABLE_SORT:
      return { ...state, sort: action.payload };
    default:
      return state;
  }
};

export default Reducer;
