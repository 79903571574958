import store from "src/store";
import { actionsTypes } from "..";
import { IM1FormRecordSubmission } from "../types";

export const setSummary = (payload: Record<string, IM1FormRecordSubmission>) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload,
    })
}

export const onCreateUpdateItem = (payload: IM1FormRecordSubmission) => {
    const data = { ...(store.getState().m1FormRecordSubmission.summary) }
    if (data[payload.formRecordId]) {
        data[payload.formRecordId] = { ...data[payload.formRecordId], ...payload }
    } else {
        data[payload.formRecordId] = payload
    }
    setSummary(data)
}