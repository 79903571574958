
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
// import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import _ from 'lodash';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { expiryCheck } from 'src/App/LoginPage/store/Actions';
import { Alert } from 'src/Framework/Common/Notification';
import { removeActivity } from 'src/App/ActivityPanel/Store/ActivityActions';
import { store } from 'src';

const ActionManager = {
    ADD_RECIPIENTS: "ADD_RECIPIENTS",
    SET_REPLY: "SET_REPLY",
    SET_FORWARD: "SET_FORWARD"
}
export const CLEAR_MSG_PARAMS = "CLEAR_MSG_PARAMS";

interface IAction {
    type: string;
    payload: any;
}

const initaialState = {
    pagination: null,
    recipients: [],
    replyFlag: false,
    forwardFlag: false,
    replyMessageDetails: []
}

const MessageRecipients = (
    state: any = { ...initaialState },
    action: IAction
) => {
    switch (action.type) {
        case ActionManager.ADD_RECIPIENTS: {
            let newState = _.cloneDeep(state)
            newState.pagination = action.payload.pagination;
            if (action.payload.pagination.current > 1) {
                newState.recipients = [...newState.recipients, ...action.payload.recipients];
            }
            else newState.recipients = action.payload.recipients;
            return newState;
        }

        case ActionManager.SET_REPLY: {
            let newState = _.cloneDeep(state)
            let msgObject = {
                forwardFlag: false,
                replyFlag: true,
                ...action.payload
            }
            let index = newState.replyMessageDetails.findIndex((r: any) => r.messageDetailId === action.payload.messageDetailId);
            if (index === -1) {
                newState.replyMessageDetails.push(msgObject);
            } else {
                newState.replyMessageDetails[index].replyFlag = true;
                newState.replyMessageDetails[index].forwardFlag = false;
            }

            return newState;
        }
        case ActionManager.SET_FORWARD: {
            let newState = _.cloneDeep(state)
            let msgObject = {
                forwardFlag: true,
                replyFlag: false,
                ...action.payload
            }
            let index = newState.replyMessageDetails.findIndex((r: any) => r.messageDetailId === action.payload.messageDetailId);
            if (index === -1) {
                newState.replyMessageDetails.push(msgObject);
            } else {
                newState.replyMessageDetails[index].replyFlag = false;
                newState.replyMessageDetails[index].forwardFlag = true;
            }
            return newState;
        }

        case CLEAR_MSG_PARAMS: {
            let newState = _.cloneDeep(state)
            newState.replyMessageDetails = [];
            return newState;
        }

        default: {
            return state;
        }

    }
};
export default MessageRecipients;

const addRecipients = (data: any) => {
    return {
        type: ActionManager.ADD_RECIPIENTS,
        payload: data
    }
};

export const setReply = (data: any) => {
    return {
        type: ActionManager.SET_REPLY,
        payload: data
    }
};
export const setForward = (data: any) => {
    return {
        type: ActionManager.SET_FORWARD,
        payload: data
    }
};

export const clearMessageParams = () => {
    return {
        type: CLEAR_MSG_PARAMS,
    }
};


export const getRecipients = async (serachString: any, current: any): Promise<any> => {
    const request = {
        searchString: serachString,
        count: 20,
        startIndex: current * 20,
        searchField: ["lastName", "firstName", "patientId", "nickName", "otherId"]
    };
    try {
        const response = await APIHandler.AxiosInstance.post(ApiUrl.GET_RECIPIENTS, request, { withCredentials: true })
        let data: any = response.data;
        if (data.success) {
            const pagination = {
                current: current + 1,
                total: data.result.total
            }
            store.dispatch(addRecipients({ pagination: pagination, recipients: data.result.summary }));
            return data.result.summary
        }
    } catch (error) {
        store.dispatch(expiryCheck(error))

    }
};
export const createMessage = (data: any, activityId: any) => {

    return (dispatch: any) => {
        APIHandler.AxiosInstance.post(ApiUrl.CREATE_MESSAGE, data, { withCredentials: true })
            .then(response => {
                let data: any = response.data;
                if (data.success) {
                    Alert("success", "Success", "Message sent successfully");
                    dispatch(removeActivity(activityId));
                }
            })
            .catch(error => {
                dispatch(expiryCheck(error))
            });
    };
};