import { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';

import { useAppSelector } from 'src/store';

import { MarketplaceExtendedDto } from '../types';
import * as actions from '../Actions';
import * as Setters from '../Actions/Setters';

// import { marketplaceDisplayName } from 'src/App/Admin/utils';

interface IUseMarketplace {
  preload?: boolean;
}

let cacheExtendedDtos: Record<string, MarketplaceExtendedDto> = {};

export const useMarketplace = ({ preload }: IUseMarketplace) => {
  const [loading, setLoading] = useState(false);
  const [extendedData, setExtendedData] =
    useState<Record<string, MarketplaceExtendedDto>>(cacheExtendedDtos);
  const data = useAppSelector((state) => state.adminPanel.marketplace.summary);
  const get = useCallback(async () => {
    setLoading(true);
    const res = await actions.getMarketplaceList();
    if (res) {
      Setters.setSummary(res);
      setLoading(false);
      return res;
    }
    setLoading(false);
    return;
  }, []);
  const getFormTemplateDetails = useCallback(
    async (...args: Parameters<typeof actions['getFormTemplateDetails']>) => {
      const res = await actions.getFormTemplateDetails(...args);
      if (res) {
        setExtendedData((v) => ({
          ...v,
          [res.id]: res
        }));
        return res;
      }
      return;
    },
    []
  );
  const postFormImport = useCallback(
    async (...args: Parameters<typeof actions['postFormImport']>) => {
      const res = await actions.postFormImport(...args);
      if (res) {
        Setters.insertImported(res);
        notification.success({
          message: 'Success',
          description:
            (res?.isReadOnly
              ? 'Read-only Form installed'
              : 'Form successfully downloaded') +
            ' and ready for use in Form Builder'
        });
        return res;
      }
      notification.error({
        message: 'Error',
        duration: 5,
        description:
          'Something went wrong, please try again in 5 minutes. If the issue persists, contact support.'
      });
      return;
    },
    []
  );
  const postFormUpdateVersion = useCallback(
    async (...args: Parameters<typeof actions['postFormUpdateVersion']>) => {
      const hideNotification = args[1];
      const res = await actions.postFormUpdateVersion(...args);
      if (res) {
        Setters.insertUpdateVersion(res);
        if (!hideNotification) {
          notification.success({
            message: 'Success',
            description:
              'Read-only Form updated and ready for use in Form Builder.'
          });
        }
        return res;
      }
      notification.error({
        message: 'Error',
        duration: 5,
        description:
          'Something went wrong, please try again in 5 minutes. If the issue persists, contact support.'
      });
      return;
    },
    []
  );
  useEffect(() => {
    if (preload) {
      get();
    }
  }, []);
  useEffect(() => {
    cacheExtendedDtos = extendedData;
  }, [extendedData]);
  return {
    data,
    extendedData,
    loading,
    actions: {
      get,
      getFormTemplateDetails,
      postFormImport,
      postFormUpdateVersion
    }
  };
};
