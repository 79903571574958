import store from 'src/store';
import { AppUrl } from 'src/Framework/Common/AppUrl';
import { ActivityType, IActivity } from '../Models/Activity';
import CommonActivityItem from '../ActivityItem/CommonactivityItem';
import {
  patientDisplayName,
  patientDisplayNameById
} from 'src/Framework/util/format';
import { getPermissionsData } from 'src/Activities/Subject/utils';
import { unsubscribePatientPage } from 'src/Activities/Subject/pushActions';
import { unsubscribeProviderPage } from 'src/App/Trainee/utils';
import { Cache } from 'src/Framework/CatchStateData';
import { uppercaseFirstLatter } from 'src/Framework/Shared/Shared';
import { FormViewerTypes } from 'src/App/FormViewer';
import { updateCurrentUserPreference } from 'src/App/Preferences/Actions';
import { PrefEnum, PrefEnumKeys } from 'src/App/Preferences/utils';
import { setPreference } from 'src/Activities/Compliance/Store/ComplianceActions';
import { getCurrentUserId } from 'src/App/User/Current/Setters';

const prefix = 'ACTIVITY_';

export const DEFAULT = 'DEFAULT';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const REMOVE_ACTIVITY = 'REMOVE_ACTIVITY';
export const VIEW_ACTIVITY = 'VIEW_ACTIVITY';
export const REORDER_ACTIVITY = 'REORDER_ACTIVITY';
export const UPDATE_ACTIVITY_CONTENT = 'UPDATE_ACTIVITY_CONTENT';
export const UPDATE_COMPOSE_MESSAGE_ACTIVITY =
  'UPDATE_COMPOSE_MESSAGE_ACTIVITY';
export const UPDATE_REPLY_MESSAGE_ACTIVITY = 'UPDATE_REPLY_MESSAGE_ACTIVITY';
export const UPDATE_MESSAGE_CENTER_ACTIVITY = 'UPDATE_MESSAGE_CENTER_ACTIVITY';
export const UPDATE_FACT_ACTIVITY = 'UPDATE_FACT_ACTIVITY';
export const UPDATE_MESSAGE_CENTER_ACTIVITY_URL =
  'UPDATE_MESSAGE_CENTER_ACTIVITY_URL';
export const UPDATE_EXECUTION_RATE = 'UPDATE_EXECUTION_RATE';
export const CACHE_ACTIVITY = 'CACHE_ACTIVITY';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const UPDATE_CURRENT_URL = 'UPDATE_CURRENT_URL';
export const UPDATE_RULE_CONTENT = 'UPDATE_RULE_CONTENT';
export const UPDATE_RULE_EDITOR = 'UPDATE_RULE_EDITOR';
export const UPDATE_RULE_OVERVIEW = 'UPDATE_RULE_OVERVIEW';
export const UPDATE_RULE_DRAFT_STATUS = 'UPDATE_RULE_DRAFT_STATUS';
export const UPDATE_TEMPLATE_OVERVIEW = 'UPDATE_TEMPLATE_OVERVIEW';
export const UPDATE_ACTIVITY_CACHE = 'UPDATE_ACTIVITY_CACHE';
export const UPDATE_RULE_VIEWER_ACTIVITY = 'UPDATE_RULE_VIEWER_ACTIVITY';
export const UPDATE_RULE_EDITOR_ACTIVITY = 'UPDATE_RULE_EDITOR_ACTIVITY';
export const UPDATE_CURRENT_ACTIVITY = 'UPDATE_CURRENT_ACTIVITY';
export const UPDATE_CURRENT_TARGET_URL = 'UPDATE_CURRENT_TARGET_URL';
export const UPDATE_CURRENT_TARGET_URL_TITLE =
  'UPDATE_CURRENT_TARGET_URL_TITLE';
export const ON_CHANGE_CURRENT = `${prefix}ON_CHANGE_CURRENT`;
export const UPDATE_ACTIVITY_BY_ID = `${prefix}UPDATE_ACTIVITY_BY_ID`;

export const ActivityPath = {
  CREATE_NOTE: 'CREATE_NOTE',
  COUNSELING_DASHBOARD: 'COUNSELING_DASHBOARD',
  COUNSELING_DASHBOARD2: 'COUNSELING_DASHBOARD2',
  COUNSELING_FORMS: 'COUNSELING_FORMS',
  CLIENT_DEMOGRAPHICS: 'DEMOGRAPHICS',
  COMPLIANCE_MESSAGE: 'COMPLIANCE_MESSAGE',
  COMPLIANCE_NEW_MESSAGE: 'COMPLIANCE_NEW_MESSAGE',
  COMPLIANCE_FACT: 'COMPLIANCE_FACT',
  COUNSELING_REFERRALS: 'COUNSELING_REFERRALS',
  COUNSELING_TEMPLATE_FORM_RECORD: 'COUNSELING_TEMPLATE_FORM_RECORD'
} as const;

type ActivityPathKeys = keyof typeof ActivityPath;
type ActivityPathValues = typeof ActivityPath[ActivityPathKeys];

function gotoTemplateFormRecord(
  patientId: string | number,
  templateFormRecordId: string | number
) {
  const url = `${AppUrl.CLIENT}/${patientId}${AppUrl.PATIENT_FORMS}/${templateFormRecordId}`;
  loadActivityTab(url, store.dispatch);
}

export const goToTraineeFormViewer = (id: number) => {
  const url = `${AppUrl.TRAINEE_FORM_VIEWER}/${id}`;
  loadActivityTab(
    url,
    store.dispatch,
    {
      activityType: ActivityType.TraineeFormViewer
    },
    {
      title: 'Form Viewer'
    }
  );
};

export const goToFormViewer = (id: number, type: FormViewerTypes) => {
  const url = `${AppUrl.FORM_VIEWER}/${id}?type=${type}`;
  loadActivityTab(
    url,
    store.dispatch,
    {
      activityType: ActivityType.FormViewer
    },
    {
      title: 'Form Viewer'
    }
  );
};

export const goToNoteViewer = (patientId: number, noteId: number) => {
  const url = `${AppUrl.NoteViewer}/${patientId}/${noteId}`;
  loadActivityTab(
    url,
    store.dispatch,
    {
      activityType: ActivityType.NoteViewer
    },
    {
      title: 'Note Viewer'
    }
  );
};

function gotoForm(subjectId: string | number, patient: any) {
  if (subjectId) {
    let url = AppUrl.CLIENT + '/' + subjectId + AppUrl.PATIENT_FORMS;
    loadActivityTab(
      url,
      store.dispatch,
      {
        activityType: ActivityType.Client
      },
      patient
        ? {
          title: patientDisplayName(patient)
        }
        : undefined
    );
  }
}

function gotoReferrals(patientId: string | number) {
  if (patientId) {
    const url =
      AppUrl.CLIENT + '/' + patientId + '/counseling/dashboard/referrals';
    loadActivityTab(url, store.dispatch);
  }
}

function gotoDashboard(subjectId: string | number) {
  if (subjectId) {
    let url = AppUrl.CLIENT + '/' + subjectId + '/counseling/dashboard';
    loadActivityTab(url, store.dispatch);
  }
}

function gotoDashboard2(subjectId: string | number, patient: any) {
  if (subjectId) {
    let url = AppUrl.CLIENT + '/' + subjectId + '/counseling/dashboard';
    loadActivityTab(
      url,
      store.dispatch,
      {
        activityType: ActivityType.Client
      },
      {
        title: patientDisplayName(patient)
      }
    );
  }
}

function gotoDemographics(subjectId: string | number, patient: any) {
  const url = `${AppUrl.CLIENT}/${subjectId}${AppUrl.PATIENTINFO}${AppUrl.DEMOGRAPHICS}`;
  loadActivityTab(
    url,
    store.dispatch,
    {
      activityType: ActivityType.Client
    },
    patient
      ? {
        title: patientDisplayName(patient)
      }
      : undefined
  );
}

function gotoCreateNote(subjectId: string | number, appointmentId: any) {
  const url = `${AppUrl.CLIENT}/${subjectId}${AppUrl.PATIENT_NOTES}${AppUrl.NOTE_TYPE}?appointmentId=${appointmentId}`;
  loadActivityTab(url, store.dispatch);
}

function gotoCreateFollowupNote(
  subjectId: string | number,
  referralId: number
) {
  const url = `${AppUrl.CLIENT}/${subjectId}${AppUrl.PATIENT_NOTES}${AppUrl.NOTE_TYPE}?referralId=${referralId}`;
  loadActivityTab(url, store.dispatch);
}

function goToComplianceMessages(subjectId: any) {
  let url =
    AppUrl.CLIENT + '/' + subjectId + AppUrl.COMPLIANCE + AppUrl.MESSAGES;
  loadActivityTab(url, store.dispatch);
}

function goToComplianceNewMessage(subjectId: any) {
  let url =
    AppUrl.CLIENT + '/' + subjectId + AppUrl.COMPLIANCE + AppUrl.NEWMESSAGE;
  loadActivityTab(url, store.dispatch, {
    activityType: ActivityType.NewMessage
  });
}

export const goToActivityPage = (url: string, props: any) => {
  loadActivityTab(
    url,
    store.dispatch,
    { activityType: ActivityType.ActivityPage },
    props
  );
};

export const goToPatientM1Form = (
  patientId: string | number,
  id: number,
  inCurrentTab = false
) => {
  const url = `/client/${patientId}${AppUrl.PATIENT_FORMS}/${id}?type=m1`;
  if (inCurrentTab) {
    updateCurrentActivity({
      targetURL: url
    });
  } else {
    loadActivityTab(url, store.dispatch);
  }
};

export const goToProviderM1Form = (providerId: string | number, id: number) => {
  const url = `/trainee/${providerId}/forms?id=${id}`;
  loadActivityTab(url, store.dispatch);
};

export const goToPatientLegacyForm = (
  patientId: string | number,
  id: number,
  inCurrentTab = false
) => {
  const url = `/client/${patientId}${AppUrl.PATIENT_FORMS}/${id}?type=legacy`;
  if (inCurrentTab) {
    updateCurrentActivity({
      targetURL: url
    });
  } else {
    loadActivityTab(url, store.dispatch);
  }
};

export const goToCohortByName = (cohortName: string) => {
  loadActivityTab(
    AppUrl.COHORT + `/${encodeURIComponent(cohortName)}` + AppUrl.MEMBER,
    store.dispatch,
    {
      activityType: ActivityType.Cohort
    }
  );
};

export const goToJobs = () => {
  loadActivityTab(AppUrl.JOBS, store.dispatch);
};

export const generateSecureMessageCacheId = (subjectId: string | number) =>
  `${AppUrl.SECUREMESSAGE}/${subjectId}`;

export const goToSecureMessage = (
  subjectId: number | string,
  params?: {
    [key: string]: any;
  }
) => {
  let link = `${AppUrl.SECUREMESSAGE}/${subjectId}`;
  if (params) {
    link += '?';
    let paramsArr: string[] = [];
    Object.entries(params).map(([key, value]) =>
      paramsArr.push(`${key}=${value}`)
    );
    link += paramsArr.join('&');
  }
  loadActivityTab(
    link,
    store.dispatch,
    {
      activityType: ActivityType.SecureMessageNew
    },
    {
      title: patientDisplayNameById(subjectId!),
      subtitle: 'New Secure Message',
      cacheId: generateSecureMessageCacheId(subjectId)
    }
  );
};
export const goToInternalMessage = () => {
  loadActivityTab(
    `${AppUrl.INTERNALMESSAGE}`,
    store.dispatch,
    {
      activityType: ActivityType.InternalMessageNew
    },
    {
      title: 'Internal Message',
      subtitle: 'New Internal Message'
    }
  );
};

export const forwardReplySecureMessage = (
  subjectId: number | string | undefined,
  messageId: number,
  type: 'forward' | 'reply'
) => {
  loadActivityTab(
    `${AppUrl.SECUREMESSAGE}/${type}/${messageId}`,
    store.dispatch,
    {
      activityType:
        type === 'forward'
          ? ActivityType.SecureMessageForward
          : ActivityType.SecureMessageReply
    },
    {
      title: subjectId ? patientDisplayNameById(subjectId) : 'Secure Message',
      subtitle: `${uppercaseFirstLatter(type)} Secure Message`
    }
  );
};
export const forwardReplyInternalMessage = (
  subjectId: number | string | undefined,
  messageId: number,
  type: 'forward' | 'reply'
) => {
  loadActivityTab(
    `${AppUrl.INTERNALMESSAGE}/${type}/${messageId}`,
    store.dispatch,
    {
      activityType:
        type === 'forward'
          ? ActivityType.InternalMessageForward
          : ActivityType.InternalMessageReply
    },
    {
      title: 'Internal Message',
      subtitle: `${uppercaseFirstLatter(type)} Internal Message`
    }
  );
};

export const goToProviderGoals = (providerId: string | number) => {
  loadActivityTab(
    `${AppUrl.TRAINEE}/${providerId}/dashboard/goals`,
    store.dispatch
  );
};

export const goToProviderAssignedClients = (providerId: string | number) => {
  loadActivityTab(
    `${AppUrl.TRAINEE}/${providerId}/assigned-clients`,
    store.dispatch
  );
};

export const goToCohortManager = () => {
  loadActivityTab(
    AppUrl.COHORT_MANAGER,
    store.dispatch,
    {
      activityType: ActivityType.NewCohortManager
    },
    {
      title: 'Cohort Manager',
      subtitle: ''
    }
  );
};

export const goToCohortManagerEdit = (name: string) => {
  loadActivityTab(
    `${AppUrl.COHORT_MANAGER_EDIT}/${encodeURIComponent(name)}`,
    store.dispatch,
    {
      activityType: ActivityType.NewCohortManager
    },
    {
      title: 'Cohort Manager',
      subtitle: ''
    }
  );
};

export const goToCohortCreation = () => {
  loadActivityTab(
    AppUrl.COHORT_MANAGER_CREATE,
    store.dispatch,
    {
      activityType: ActivityType.NewCohortCreation
    },
    {
      title: 'Cohort Manager',
      subtitle: 'New Cohort'
    }
  );
};

export const generateComplianceFactsUrl = (subjectId: string | number) =>
  AppUrl.CLIENT + '/' + subjectId + AppUrl.COMPLIANCE + AppUrl.FACTS;

function goToComplianceFacts(subjectId: any, props: any) {
  const url = generateComplianceFactsUrl(subjectId);
  loadActivityTab(
    url,
    store.dispatch,
    {
      activityType: ActivityType.Client
    },
    props
  );
}

export const gotoClient = (
  subjectId: string | number,
  path: ActivityPathValues,
  props?:
    | {
      templateFormRecordId: number | string;
    }
    | any
) => {
  const success = checkActivityPermission(path);
  if (success) {
    switch (path) {
      case ActivityPath.COUNSELING_TEMPLATE_FORM_RECORD: {
        return gotoTemplateFormRecord(subjectId, props.templateFormRecordId);
      }
      case ActivityPath.COUNSELING_REFERRALS: {
        return gotoReferrals(subjectId);
      }
      case ActivityPath.COUNSELING_DASHBOARD: {
        return gotoDashboard(subjectId);
      }
      case ActivityPath.COUNSELING_DASHBOARD2: {
        return gotoDashboard2(subjectId, props);
      }
      case ActivityPath.COUNSELING_FORMS: {
        return gotoForm(subjectId, props);
      }
      case ActivityPath.CREATE_NOTE: {
        if (props.referralId) {
          return gotoCreateFollowupNote(subjectId, props.referralId);
        } else {
          return gotoCreateNote(subjectId, props.appointmentId);
        }
      }
      case ActivityPath.COMPLIANCE_MESSAGE: {
        return goToComplianceMessages(subjectId);
      }
      case ActivityPath.COMPLIANCE_NEW_MESSAGE: {
        return goToComplianceNewMessage(subjectId);
      }
      case ActivityPath.COMPLIANCE_FACT: {
        return goToComplianceFacts(subjectId, props);
      }
      default: {
        return gotoDemographics(subjectId, props);
      }
    }
  }
  return gotoDemographics(subjectId, props);
};

function checkActivityPermission(path: ActivityPathValues) {
  const permissions = getPermissionsData();
  switch (path) {
    case ActivityPath.COUNSELING_TEMPLATE_FORM_RECORD: {
      return !permissions.HideCounseling;
    }
    case ActivityPath.COUNSELING_REFERRALS: {
      return !permissions.HideCounseling;
    }
    case ActivityPath.COUNSELING_DASHBOARD: {
      return !permissions.HideCounseling;
    }
    case ActivityPath.COUNSELING_DASHBOARD2: {
      return !permissions.HideCounseling;
    }
    case ActivityPath.COUNSELING_FORMS: {
      return true; //!counselingPermissions.HideCounseling;
    }
    case ActivityPath.CREATE_NOTE: {
      return permissions.noteCreation.success; //!counselingPermissions.HideCounseling;
    }
    case ActivityPath.COMPLIANCE_MESSAGE: {
      return !permissions.HideCompliance;
    }
    case ActivityPath.COMPLIANCE_NEW_MESSAGE: {
      return !permissions.HideCompliance;
    }
    case ActivityPath.COMPLIANCE_FACT: {
      return !permissions.HideCompliance;
    }
    case ActivityPath.CLIENT_DEMOGRAPHICS: {
      return true;
    }
  }
  return false;
}

export const addActivity = (
  content: any,
  renderComponent: any,
  activityType: string,
  closable: boolean,
  targetURL: string,
  isDraft: boolean = false,
  isCurrent: boolean = true
) => {
  let activityId: string = 'activity-' + new Date().getTime();
  return {
    type: ADD_ACTIVITY,
    payload: {
      activityId: activityId,
      content: content,
      renderComponent: renderComponent,
      type: activityType,
      closable: closable,
      targetURL: targetURL,
      isDraft: isDraft,
      isCurrent: isCurrent,
      cache: {}
    }
  };
};

export const getCurrentActivity = () => {
  const activity = store.getState().activity.find((v) => v.isCurrent);
  return activity;
};

export const updateActivity = (
  payload: Partial<IActivity> & {
    activityId: string;
  }
) => {
  store.dispatch({
    type: UPDATE_ACTIVITY_BY_ID,
    payload
  });
};

export const updateCurrentActivity = (payload: any) => {
  store.dispatch({
    type: UPDATE_CURRENT_ACTIVITY,
    payload
  });
};

export const updateCurrentTargetUrl = (payload: any) => {
  store.dispatch({
    type: UPDATE_CURRENT_TARGET_URL,
    payload
  });
};

export const updateCurrentTargetUrlTitle = (payload: any) => {
  store.dispatch({
    type: UPDATE_CURRENT_TARGET_URL_TITLE,
    payload
  });
};

export const defaultActivity = () => {
  store.dispatch({
    type: DEFAULT
  });
};

export const setActivities = (payload: IActivity[]) => {
  store.dispatch({
    type: SET_ACTIVITIES,
    tabsSet: payload
  });
};

export const setActivitiesToPrefs = (payload: IActivity[]) => {
  const userId = getCurrentUserId();
  const oldActivitiesPrefs =
    store.getState().user?.preferences?.currentTabs?.parsedValue;
  const activitiesPrefs = {
    ...oldActivitiesPrefs,
    tabs: payload
  };
  const data = {
    userId,
    preferences: [
      {
        userId,
        id: PrefEnum[PrefEnumKeys.currentTabs].id,
        name: PrefEnum[PrefEnumKeys.currentTabs].name,
        value: JSON.stringify(activitiesPrefs),
        defaultValue: ''
      }
    ]
  };
  updateCurrentUserPreference(data.preferences);
  setPreference(data);
};

export const removeCurrentActivity = () => {
  const currentActivity = store.getState().activity.find((v) => v.isCurrent);
  if (currentActivity) {
    store.dispatch(removeActivity(currentActivity.activityId));
  }
};

export const removeActivity = (activityId: string) => {
  const currentActivity = store
    .getState()
    .activity.find((v) => v.activityId === activityId);
  if (
    currentActivity?.type === ActivityType.InternalMessage ||
    currentActivity?.type === ActivityType.InternalMessageNew ||
    currentActivity?.type === ActivityType.InternalMessageForward ||
    currentActivity?.type === ActivityType.InternalMessageReply
  ) {
    Cache.remove(currentActivity.targetURL);
  }
  if (currentActivity?.type === ActivityType.Cohort) {
    Cache.remove(currentActivity.targetURL);
  }
  if (currentActivity?.type === ActivityType.NewCohortManager) {
    Cache.remove(currentActivity.targetURL);
  }
  if (currentActivity?.type === ActivityType.NewCohortCreation) {
    Cache.remove(currentActivity.targetURL);
  }
  if (currentActivity?.type === ActivityType.SecureMessageNew) {
    const url = currentActivity.targetURL.split('?');
    Cache.remove(url[0]);
  }
  if (currentActivity?.type === ActivityType.SecureMessageReply) {
    Cache.remove(currentActivity.targetURL);
  }
  if (currentActivity?.type === ActivityType.SecureMessageForward) {
    Cache.remove(currentActivity.targetURL);
  }
  if (currentActivity?.type === ActivityType.RuleEditor) {
    Cache.remove(currentActivity.targetURL);
  }
  if (currentActivity?.type === ActivityType.Client) {
    unsubscribePatientPage(currentActivity.content.id);
  }
  if (currentActivity?.type === ActivityType.Trainee) {
    unsubscribeProviderPage(currentActivity.content.id);
  }
  return {
    type: REMOVE_ACTIVITY,
    activityId: activityId
  };
};

export const updateRuleViewerActivity = (
  oldRuleName: string,
  currentRuleName: string
) => {
  return {
    type: UPDATE_RULE_VIEWER_ACTIVITY,
    oldRuleName: oldRuleName,
    currentRuleName: currentRuleName
  };
};
export const updateRuleEditorActivity = (
  oldRuleName: string,
  currentRuleName: string
) => {
  return {
    type: UPDATE_RULE_EDITOR_ACTIVITY,
    oldRuleName: oldRuleName,
    currentRuleName: currentRuleName
  };
};

export const reorderActivity = (
  sourceIndex: number,
  destinationIndex: number
) => {
  return {
    type: REORDER_ACTIVITY,
    sourceIndex: sourceIndex,
    destinationIndex: destinationIndex
  };
};

export const updateActivityContent = (
  cohortId: string,
  cohortName: string,
  complyRate: number,
  included: boolean
) => {
  return {
    type: UPDATE_ACTIVITY_CONTENT,
    cohortId: cohortId,
    cohortName: cohortName,
    complyRate: complyRate,
    included: included
  };
};
export const updateStudentComposeMessageActivity = (name: string) => {
  return {
    type: UPDATE_COMPOSE_MESSAGE_ACTIVITY,
    studentName: name
  };
};
export const updateStudentReplyMessageActivity = (name: string) => {
  return {
    type: UPDATE_REPLY_MESSAGE_ACTIVITY,
    studentName: name
  };
};
export const updateMessageCenterActivityContent = (
  countUpdateMsgCenter: any
) => {
  return {
    type: UPDATE_MESSAGE_CENTER_ACTIVITY,
    countUpdateMsgCenter: countUpdateMsgCenter
  };
};

export const updateStudentFactActivity = (name: string) => {
  return {
    type: UPDATE_FACT_ACTIVITY,
    studentName: name
  };
};
export const updateMessageCenterActivityURL = (
  targetURL: string,
  activityId: string
) => {
  return {
    type: UPDATE_MESSAGE_CENTER_ACTIVITY_URL,
    targetURL: targetURL,
    activityId: activityId
  };
};
export const updateCurrentActivityURL = (currentURL: string) => {
  return {
    type: UPDATE_CURRENT_URL,
    currentUrl: currentURL
  };
};

export const updateCohortExecutionRate = (cohortId: string, rate: number) => {
  return {
    type: UPDATE_EXECUTION_RATE,
    cohortId: cohortId,
    updateRate: rate
  };
};

export const updateCache = (
  activityId: string,
  componentId: string,
  cache: any
) => {
  return {
    type: CACHE_ACTIVITY,
    activityId: activityId,
    componentId: componentId,
    cache: cache
  };
};

export const updateRuleOverviewActivity = (rule: any) => {
  return {
    type: UPDATE_RULE_OVERVIEW,
    rule: rule
  };
};

export const updateTemplateOverviewActivity = (template: any) => {
  return {
    type: UPDATE_TEMPLATE_OVERVIEW,
    template: template
  };
};
export const updateActivityCache = (activityId: any) => {
  return {
    type: UPDATE_ACTIVITY_CACHE,
    activityId: activityId
  };
};

export const onChangeCurrentActivity = (payload: any) => {
  store.dispatch({
    type: ON_CHANGE_CURRENT,
    payload
  });
};

export var _dispatch: any = null;
export var _pathname: any = '';

export const loadActivityTab = (
  pathname: string,
  dispatch: any = store.dispatch,
  newTab?: {
    activityType: ActivityType;
  },
  props?: any
) => {
  if (_dispatch === null) _dispatch = dispatch;

  if (!pathname) return;
  if (newTab) {
    return loadActivity(newTab.activityType, pathname, props);
  }
  let isExistSubjectUrl: boolean = false;
  if (pathname?.includes(AppUrl.COMPLIANCE) && pathname?.includes('/subject'))
    isExistSubjectUrl = true;
  if (_pathname === pathname) return;
  if (pathname.startsWith(AppUrl.JOBS)) {
    return viewActivity(ActivityType.Jobs, pathname, {
      title: 'Jobs',
      subtitle: 'Cohorts'
    });
  }
  if (pathname.startsWith(AppUrl.SECUREMESSAGE)) {
    return viewActivity(ActivityType.SecureMessageNew, pathname, props);
  }
  if (pathname.startsWith(AppUrl.COHORT_MANAGER))
    viewActivity(ActivityType.NewCohortManager, pathname);
  if (pathname.startsWith(AppUrl.DASHBOARD))
    viewActivity(ActivityType.Dashboard, pathname);
  if (pathname.startsWith(AppUrl.ACTIVITY))
    viewActivity(ActivityType.Activity, pathname);
  else if (pathname.startsWith(AppUrl.SCHEDULE))
    viewActivity(ActivityType.Scheduler, pathname);
  else if (pathname === AppUrl.CLIENT)
    viewActivity(ActivityType.ClientManager, pathname);
  else if (pathname.startsWith(AppUrl.CLIENT))
    loadActivity(ActivityType.Client, pathname);
  else if (pathname.startsWith(AppUrl.ORDERS))
    viewActivity(ActivityType.Orders, pathname);
  else if (pathname.startsWith(AppUrl.BILLING))
    viewActivity(ActivityType.Billing, pathname);
  else if (pathname.startsWith(AppUrl.SCPM))
    viewActivity(ActivityType.SCPM, pathname);
  else if (pathname.startsWith(AppUrl.REPORTS))
    viewActivity(ActivityType.Reports, pathname);
  else if (pathname.startsWith(AppUrl.CATS))
    viewActivity(ActivityType.CATS, pathname);
  else if (pathname.startsWith(AppUrl.RULEEDITOR))
    loadActivity(ActivityType.RuleEditor, pathname);
  else if (pathname.startsWith(AppUrl.RULEVIEWER))
    loadActivity(ActivityType.RuleViewer, pathname);
  else if (pathname.startsWith(AppUrl.NOTIFICATIONVIEWER))
    loadActivity(ActivityType.NotificationViewer, pathname);
  else if (pathname.startsWith(AppUrl.SYSTEMSETUP))
    viewActivity(ActivityType.SystemSetup, pathname);
  else if (pathname.startsWith(AppUrl.MESSAGES))
    viewActivity(ActivityType.Messages, pathname);
  else if (pathname.startsWith(AppUrl.PATIENTHEADERSETTINGS))
    viewActivity(ActivityType.PatientHeaderSettings, pathname);
  else if (pathname.startsWith(AppUrl.DASHBOARDSETTINGS))
    viewActivity(ActivityType.DashboardSettings, pathname);
  else if (pathname.includes('reply'))
    loadActivity(ActivityType.ReplyMessage, pathname);
  else if (pathname.includes('forward'))
    loadActivity(ActivityType.ForwardMessage, pathname);
  else if (pathname.includes(AppUrl.NEWMESSAGE))
    loadActivity(ActivityType.NewMessage, pathname);
  else if (pathname.startsWith(AppUrl.COHORT))
    loadActivity(ActivityType.Cohort, pathname);
  else if (isExistSubjectUrl) loadActivity(ActivityType.Subject, pathname);
  else if (pathname === AppUrl.COHORTMANAGER)
    viewActivity(ActivityType.CohortManager, pathname);
  else if (pathname.startsWith(AppUrl.COMPLIANCE))
    viewActivity(ActivityType.Compliance, pathname);
  else if (pathname.startsWith(AppUrl.COHORTCOMPLIANCEVIEWER))
    loadActivity(ActivityType.CohortComplianceViewer, pathname);
  else if (pathname.startsWith(AppUrl.NEWCOHORT))
    viewActivity(ActivityType.NewCohort, pathname);
  else if (pathname.startsWith(AppUrl.SUBJECTS))
    viewActivity(ActivityType.Subjects, pathname);
  else if (pathname.startsWith(AppUrl.COUNSELING))
    viewActivity(ActivityType.Counseling, pathname);
  else if (pathname.startsWith(AppUrl.ADMIN))
    viewActivity(ActivityType.Admin, pathname, {
      title: 'Admin',
      subtitle: 'Panel'
    });
  else if (pathname.startsWith(AppUrl.WAITING_LIST_SETTINGS))
    viewActivity(ActivityType.WaitingListSettings, pathname, {
      title: 'Schedule',
      subtitle: 'List Settings'
    });
  else if (pathname.startsWith(AppUrl.USER_PREFERENCES)) {
    const name = localStorage.getItem('userName');
    viewActivity(ActivityType.UserPreferences, pathname, {
      title: `${name}`,
      subtitle: 'Preferences'
    });
  } else if (pathname.startsWith(AppUrl.BUILDING_BLOCK)) {
    viewActivity(ActivityType.BuildingBlock, pathname);
  } else if (pathname.startsWith(AppUrl.TRAINEE)) {
    loadActivity(ActivityType.Trainee, pathname);
  } else if (pathname.startsWith(AppUrl.CONFLICT_RESOLUTION_MANAGER)) {
    viewActivity(ActivityType.ConflictManager, pathname);
  }
};

export const loadActivity = (
  activityType: string,
  pathname: string,
  props?: any
) => {
  let params = pathname.split('/');
  let tempActivityId: string | number = activityType;
  if (params[1] === activityType && parseInt(params[2]) !== NaN) {
    tempActivityId = parseInt(params[2]);
  }
  if (
    params[1] === activityType &&
    params[4] === 'forms' &&
    parseInt(params[5]) !== NaN
  ) {
    tempActivityId = parseInt(params[5]);
  }

  if (params[2] && tempActivityId.toString() !== params[2]) {
    tempActivityId = params[2];
  }
  /// For Rule and Cohort
  if (
    (params[3] === ActivityType.RuleEditor ||
      params[3] === ActivityType.RuleViewer ||
      params[3] === ActivityType.NotificationViewer ||
      params[3] === ActivityType.Subject ||
      params[3] === ActivityType.Client ||
      params[3] === ActivityType.Activity ||
      params[3] === ActivityType.Cohort) &&
    params[3] === activityType &&
    parseInt(params[3]) !== NaN
  ) {
    tempActivityId = parseInt(params[4]);
  }
  if (
    (params[3] === ActivityType.RuleEditor ||
      params[3] === ActivityType.RuleViewer ||
      params[3] === ActivityType.NotificationViewer ||
      params[3] === ActivityType.Subject ||
      params[3] === ActivityType.Client ||
      params[3] === ActivityType.Activity ||
      params[3] === ActivityType.Cohort) &&
    tempActivityId.toString() !== params[3]
  ) {
    tempActivityId = params[4];
  }
  if (params[5] && params[5] === ActivityType.Messages) {
    if (
      (params[7] && params[7] === ActivityType.ReplyMessage) ||
      params[7] === ActivityType.ForwardMessage
    ) {
      tempActivityId = params[6];
    }
  }
  _dispatch({
    type: VIEW_ACTIVITY,
    payload: {
      content: { id: tempActivityId },
      renderComponent: CommonActivityItem,
      type: activityType,
      closable: true,
      isCurrent: true,
      targetURL: pathname,
      ...props
    }
  });
};

export const viewActivity = (
  activityType: string,
  pathname: string,
  props: any = {}
) => {
  if (_dispatch === null) {
    _dispatch = store.dispatch;
  }
  _dispatch({
    type: VIEW_ACTIVITY,
    payload: {
      content: { id: activityType },
      renderComponent: CommonActivityItem,
      type: activityType,
      closable: true,
      isCurrent: true,
      targetURL: pathname,
      ...props
    }
  });
};

export const updateSubjectInternalTab = (
  activityType: string,
  pathname: string
) => {
  _dispatch({
    type: VIEW_ACTIVITY,
    payload: {
      content: { id: activityType },
      renderComponent: CommonActivityItem,
      type: activityType,
      closable: true,
      isCurrent: true,
      targetURL: pathname
    }
  });
};
