import { BucketsList, generateSubscriptionFilter } from "src/Framework/ApiSubscription";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { RequestHelper } from "src/Framework/ApiSubscription/RequestHelper";
import { IAssignedSupervisors } from "../types";
import { setSummary, onCreateUpdateItem } from "./Setters";

type TypeGetArgs = [providerIds: (string | number)[], key: keyof IAssignedSupervisors]

export const SubscriptionData = (...args: TypeGetArgs) => {
    const [providerIds, key] = args
    return ({
        bucket: BucketsList.providerSupervisorAssignment,
        filter: generateSubscriptionFilter(BucketsList.providerSupervisorAssignment, providerIds, key)
    })
}

export const { post, get, deleteRequest, findAndUnsubscribe, unsubscribe } = new RequestHelper<IAssignedSupervisors, TypeGetArgs, [providerId: string | number], Partial<IAssignedSupervisors>[]>({
    link: ApiUrl.PROVIDER_ASSIGNED_SUPERVISOR,
    onCreateUpdateItem,
    subscription: {
        subscriptionData: SubscriptionData,
        setSummary
    }
})