import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import store from 'src/store';

import { actionsTypes } from './index';

export const getPatientHeader = async (subjectId: any) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.GET_PATIENT_HEADER,
      { subjectId },
      { withCredentials: true }
    );
    if (response.data.success) {
      const data = response.data.result;
      setPatientHeader(data, subjectId);
      return data;
    }
    return;
  } catch (e) {
    console.log({ e });
    return;
  }
};

export const setPatientHeader = (payload: any, subjectId: any) => {
  store.dispatch({
    type: actionsTypes.SET,
    payload,
    subjectId
  });
};
