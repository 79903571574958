import _ from 'lodash';
import { IStateReducer, IAction, IFormsSort, IFilter } from './types';

const prefix = 'PROVIDER_FORMS_';

export const actionsTypes = {
  SET_FILTER: `${prefix}SET_FORMS_FILTER`,
  SET_SORT: `${prefix}SET_FORMS_SORT`
} as const;

type IActions = Record<
  typeof actionsTypes[keyof typeof actionsTypes],
  () => IStateReducer
>;

const initialState: IStateReducer = {
  providers: {}
};

export const defaultFilterData: IFilter = {
  dateFrom: null,
  dateTo: null,
  status: null,
  inactive: false
};

export const defaultSortingData: IFormsSort = {
  name: null,
  date: null
};

export const defaultData: IStateReducer['providers'][number] = {
  filter: _.cloneDeep(defaultFilterData),
  sort: _.cloneDeep(defaultSortingData)
};

const Reducer = (
  state: IStateReducer = _.cloneDeep(initialState),
  action: IAction
): IStateReducer => {
  const providerData = () =>
    state.providers[action.providerId] || _.cloneDeep(defaultData);
  const data = providerData();
  const actions: IActions = {
    [actionsTypes.SET_SORT]: () => {
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: {
            ...data,
            sort: action.payload
          }
        }
      };
    },
    [actionsTypes.SET_FILTER]: () => {
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: {
            ...data,
            filter: action.payload
          }
        }
      };
    }
  };
  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};

export default Reducer;
