const ids = {
  CLEAR_BUTTON: 'clear-button',
  SAVE_BUTTON: 'save-button',
  SCHOOL: 'School-multiselect',
  Eligibility: 'Eligibility-multiselect',
  Standing: 'Standing-multiselect',
  Class: 'Class-multiselect',
  Residency: 'Residency-multiselect',
  student: 'student-status',
  ALL: 'all',
  international: 'international',
  EnrollmentFrom: 'enrollment-from',
  EnrollmentTo: 'enrollment-to',
  modal: 'cohort-filter-modal'
};

export default ids;
