import { notification } from 'antd';

const minAppSizeRequirements = [1366, 768];

export const checkResolution = () => {
  // Disabled for now
  return 
  const windowInnerWidth = window.innerWidth;
  const windowInnerHeight = window.innerHeight;
  if (
    windowInnerWidth < minAppSizeRequirements[0] ||
    windowInnerHeight < minAppSizeRequirements[1]
  ) {
    notification.error({
      message: `Your browser's window size does not match minimum requirements for application (${minAppSizeRequirements[0]}px * ${minAppSizeRequirements[1]}px)`
    });
  }
};
