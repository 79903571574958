import styled from "styled-components";

export const Container = styled.div`
    position: relative;
`

export const ModalContainer = styled.div`
    padding: 20px;
`

export const Text = styled.div`
    font-size: 16px;
`