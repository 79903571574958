import _ from 'lodash'
import { IAction, IStateReducer } from './types'

const prefix = 'JOBS_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_SORT: `${prefix}SET_SORT`
}

const initialState: IStateReducer = {
    result: {
        temporaryJobCount: 0,
        jobs: []
    },
    filters: {
        dateFrom: new Date().toString(),
        dateTo: new Date().toString()
    },
    sort: {
        sortBy: null,
        sortType: null
    },
};

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction):IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET_FILTER:
            return { ...state, filters: { ...state.filters, ...action.payload } }
        case actionsTypes.SET_SORT:
            return { ...state, sort: action.payload }
        case actionsTypes.SET:
            return { ...state, result: action.payload }
        default:
            return state;
    }
};

export default Reducer;
