import { IStateReducer, IAction } from './types';

const prefix = 'AUTOMATED_TASKS_';

export const actionsTypes = {
  SET: `${prefix}SET`,
  SET_LOADING: `${prefix}SET_LOADING`,
  SET_TABLE_SORT: `${prefix}SET_SORT`
};

const initialState: IStateReducer = {
  summary: {},
  loading: false,
  sort: {
    sortBy: 'sortingDate',
    sortType: 'desc'
  }
};

const Reducer = (
  state: IStateReducer = { ...initialState },
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET:
      return {
        ...state,
        summary: action.payload
      };
    case actionsTypes.SET_TABLE_SORT:
      return { ...state, sort: action.payload };
    default:
      return state;
  }
};

export default Reducer;
