import React, { FC, useCallback, useState, Fragment, useEffect } from 'react';

import Item from './Item';

import { Line, Container, AbsoluteContainer } from './styled';
import { idGenerator } from './utils';

export interface IItemTree {
  key: string;
  title: string;
  withoutClick?: boolean;
  children?: Array<IItemTree>;
}

interface IProps {
  defaultExpandedKeys: Array<string>;
  defaultActive: string;
  treeData: Array<IItemTree>;
  onClickActive: Function;
  rightContainerId?:string
}

const Component: FC<IProps> = ({
  treeData,
  defaultExpandedKeys,
  onClickActive,
  defaultActive,
  rightContainerId
}: IProps) => {
  const [active, setActive] = useState<string>(defaultActive);
  const [opened, setOpened] = useState(defaultExpandedKeys);

  useEffect(() => {
    const element = document.getElementById(idGenerator(defaultActive));
    if (element) {
      element.focus();
    }
  }, []);

  const onChangeOpened = useCallback(({ key }: IItemTree) => {
    setOpened((opened) => {
      const index = opened.indexOf(key);
      const array = [...opened];
      if (index !== -1) {
        array.splice(index, 1);
      } else {
        array.push(key);
      }
      return array;
    });
  }, []);

  const onChangeActive = useCallback((value: IItemTree) => {
    if (value.withoutClick) {
      return;
    }
    onClickActive(value);
    setActive(value.key);
  }, []);

  return (
    <AbsoluteContainer>
      <Container>
        {treeData.map((value) => (
          <ItemContainer
            key={value.key}
            value={value}
            active={active}
            opened={opened}
            onChangeActive={onChangeActive}
            onChangeOpened={onChangeOpened}
            rightContainerId={rightContainerId}
          />
        ))}
      </Container>
    </AbsoluteContainer>
  );
};

interface IItemContainer {
  value: IItemTree;
  opened: string[];
  active: string;
  onChangeOpened: Function;
  onChangeActive: Function;
  rightContainerId?: string;
}

const ItemContainer = React.memo(
  ({
    value,
    opened,
    active,
    onChangeOpened,
    onChangeActive,
    rightContainerId
  }: IItemContainer) => (
    <Fragment>
      <Item
        value={value}
        opened={opened}
        active={active}
        onChangeOpened={onChangeOpened}
        onChangeActive={onChangeActive}
        level={1}
        rightContainerId={rightContainerId}
      />
      <Line />
    </Fragment>
  )
);

Component.defaultProps = {
  defaultExpandedKeys: [],
  defaultActive: ''
};

export default React.memo(Component);
