import globalStyleVariables from "src/Framework/Styles/variables.module.scss";
import styled from "styled-components";

export const Container = styled.div`

`

export const Header = styled.div`
    padding: 20px;
`

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 400px;
`

export const Footer = styled.div`
    padding: 20px;
    border-top: 1px solid ${globalStyleVariables.borderColor};
`

export const AddedTableContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
`