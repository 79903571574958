import * as actionTypes from "./NoteContentActions";
import _ from 'lodash';

const initialState: any = {};

interface IAction {
    type: string;
    payload: any;
    id: any;
    property: string;
    seedId: any;
}

const NoteReducer = (
    state: any = { ...initialState },
    action: IAction
) => {
    switch (action.type) {
        case actionTypes.ADD_NOTE_OVERVIEW: {
            let newState = _.cloneDeep(state)
            newState[action.id] = action.payload;
            return newState;
        }
        case actionTypes.UPDATE_ASSESSMENT: {
            let newState = _.cloneDeep(state)
            newState[action.id].details.assessment = { ...action.payload };
            return newState;
        }
        case actionTypes.UPDATE_PLAN: {
            let newState = _.cloneDeep(state)
            newState[action.id].details.plan = { ...action.payload };
            return newState;
        }
        case actionTypes.UPDATE_MOFFSECTIONS: {
            let newState = _.cloneDeep(state)
            if (newState[action.id]) {
                newState[action.id].details.moffSections = { ...action.payload };
            }
            return newState;
        }
        case actionTypes.UPDATE_FORM_MOFF: {
            let newState = _.cloneDeep(state)
            newState[action.id].details.forms = { ...action.payload };
            return newState;
        }
        case actionTypes.UPDATE_CURRENT_PROVIDER: {
            let newState = _.cloneDeep(state)
            newState[action.id].currentProvider = { ...action.payload };
            return newState;
        }
        case actionTypes.UPDATE_PROPERTY: {
            let newState = _.cloneDeep(state)
            const data = action.payload;
            const info = _.assign(newState[action.id], data);
            newState[action.id] = info;
            return newState;
        }
        case actionTypes.SET_ATTACH_TO_NOTE: {
            let newState = _.cloneDeep(state)
            newState[action.id].details.forms = action.payload;
            return newState;
        }
        default:
            return state;
    }
};
export default NoteReducer;