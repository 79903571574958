
import _ from 'lodash';
import * as actionTypes from "./SideNavBarAction";

export interface IStateReducer {
  showHideNavBar: boolean
  visible: boolean
}

const initialState: IStateReducer = {
  showHideNavBar: false,
  visible: false,
}

interface IAction {
  type: string;

}

const SideNavBarReducer = (
  state: any = { ...initialState },
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionTypes.NAVBAR_SHOW_HIDE: {
      let newState = _.cloneDeep(state)
      newState.showHideNavBar = !newState.showHideNavBar
      return newState;
    }
    case actionTypes.NAVBAR_VISIBLE: {
      let newState = _.cloneDeep(state)
      newState.visible = true;
      return newState;
    }

    default:
      return state;
  }
};
export default SideNavBarReducer;
