import * as actionTypes from "./CohortManagerAction";
import { ICohort, ICohortSummary } from "../Models/Cohort";
import _ from 'lodash';

const initialState: any = {
  pagination: {
    current: 1
  },
  summary: []
}

interface IAction {
  type: string;
  value: any;
  cohortId: string;
  cohorts: ICohortSummary;
  cohortList: ICohort[];
  executionRate: number;
  jobStatus: number;
  successCount: number;
  pagination: any
  cohort: ICohort;
}

const CohortManagerReducer = (
  state: any = { ...initialState },
  action: IAction
) => {

  switch (action.type) {
    case actionTypes.ADD_COHORTS: {
      let newState = _.cloneDeep(state)
      if (action.pagination.current > 1) {
        const isExist: boolean = isExistCohort(newState.summary, action.cohortList);
        if (isExist) return state;
        newState.summary = [...newState.summary, ...action.cohortList];
      } else {
        newState.summary = action.cohortList;
      }
      newState.pagination = action.pagination;
      return newState;
    }
    case actionTypes.DELETE_COHORT: {
      let newState = _.cloneDeep(state)
      let index: number = newState.summary.findIndex(
        (cohort: ICohort) => cohort.id === action.cohortId
      );
      newState.summary.splice(index, 1);
      return newState;
    }

    case actionTypes.UPDATE_COHORT_LIST: {
      let newState = _.cloneDeep(state)
      let updatedState: any = newState;
      updatedState.summary.unshift(action.cohort);
      return updatedState;
    }

    case actionTypes.UPDATE_INCREMENTAL_STATUS: {
      let newState = _.cloneDeep(state)
      let cohort: ICohort | undefined = newState.summary.find(
        (co: ICohort) => co.id === action.cohortId
      );
      if (!cohort) return newState;
      cohort.complianceRate = action.executionRate;
      cohort.jobStatus = action.jobStatus;
      cohort.successCount = action.successCount;
      return newState;
    }
    case actionTypes.CLEAR_COHORT_MANAGER: {
      let newState = _.cloneDeep(state)
      newState.pagination = initialState.pagination;
      newState.summary = initialState.summary;
      return newState;
    }

    default:
      return state;
  }
};
export default CohortManagerReducer;


// to check whether a Cohort already exist in the store
function isExistCohort(stateCohorts: any, actionCohorts: any): boolean {
  if (actionCohorts && actionCohorts.length > 0) {
    let action_cohort: any = actionCohorts[0];
    let isExist: any = stateCohorts.find((cohort: any) => {
      return cohort.id === action_cohort.id;
    });
    if (isExist) return true;
  }
  return false;
}

