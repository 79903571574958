import {APIHandler} from "../../../../../Framework/Communication/ServerProxy";
import {ApiUrl} from "../../../../../Framework/Common/ApiUrl";
import moment from "moment";
import {store} from "../../../../../index";


const SET_REQ_STATUSES = "SET_REQ_STATUSES";
const SET_REQ_PRIORITIES = "SET_REQ_PRIORITIES";
const SET_REQ_ORDERS = "SET_REQ_ORDERS";
const SET_REQ_ORDER = "SET_REQ_ORDER";
const SET_REQ_ORDER_LAST_READ = "SET_REQ_ORDER_LAST_READ";
const SET_REFERRAL_RESULTS = "SET_REFERRAL_RESULTS";
const SET_REFERRAL_RESULTS_LOG = "SET_REFERRAL_RESULTS_LOG";
const SET_REQ_ORDERS_DASHBOARD = "SET_REQ_ORDERS_DASHBOARD";

const setReqStatuses = (data: any) => {
	return store.dispatch({
		type: SET_REQ_STATUSES,
		payload: data,
	});
};

const setReqPriorities = (data: any) => {
	return store.dispatch({
		type: SET_REQ_PRIORITIES,
		payload: data,
	});
};

const setReqOrders = (patientId: number, data: any) => {
	return store.dispatch({
		type: SET_REQ_ORDERS,
		patientId,
		payload: data,
	});
};


export const setReqOrdersDashboard = (data: any) => {
	return store.dispatch({
		type: SET_REQ_ORDERS_DASHBOARD,
		payload: data,
	});
};

const setReqOrder = (patientId: number, reqOrderId: number, data: any) => {
	return store.dispatch({
		type: SET_REQ_ORDER,
		patientId,
		reqOrderId,
		payload: data,
	});
};

const setReqOrderLog = (patientId: number, reqOrderId: number, data: any) => {
	return store.dispatch({
		type: SET_REFERRAL_RESULTS_LOG,
		patientId,
		reqOrderId,
		payload: data,
	});
};

const setReqOrderLastReadLog = (patientId: number, reqOrderId: number, data: any) => {
	return store.dispatch({
		type: SET_REQ_ORDER_LAST_READ,
		patientId,
		reqOrderId,
		payload: data,
	});
};

const setReferralResults = (reqOrderId: number, data: any) => {
	return store.dispatch({
		type: SET_REFERRAL_RESULTS,
		reqOrderId,
		payload: data,
	});
};

export const getReqStatuses = () => {
	APIHandler.AxiosInstance.get(ApiUrl.REQ_STATUS, {
		withCredentials: true, params: {}
	})
		.then(response => {
			if (response.data.result) {
				setReqStatuses(response.data.result.summary);
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getReqPriorities = () => {
	APIHandler.AxiosInstance.get(ApiUrl.REQ_TYPE, {
		withCredentials: true, params: {}
	})
		.then(response => {
			if (response.data.result) {
				setReqPriorities(response.data.result.summary);
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getReqOrders = (patientId?: any, reqOrderId?: any, clinicId?: any, typeReqPriorityIds?: number[], typeTxnClassIds?: number[], typeReqStatusIds?: number[]) => {
	APIHandler.AxiosInstance.get(ApiUrl.REQ_ORDER, {
		withCredentials: true, params: {
			patientId,
			id: reqOrderId,
			clinicId,
			typeReqPriorityIds,
			typeTxnClassIds,
			typeReqStatusIds
		}
	})
		.then(response => {
			if (response.data.result && response.data.result.summary) {
				if (patientId) {
					setReqOrders(patientId, response.data.result.summary);
				} else {
					setReqOrdersDashboard(response.data.result.summary)
				}
			} else {
				if (patientId) {
					setReqOrders(patientId, {});
				}
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

export const updateReqOrder = (patientId: number, reqOrderId: number, data: {
	priorityId?: number,
	statusId?: number,
	isDashboardShown?: boolean,
}) => {
	APIHandler.AxiosInstance.patch(`${ApiUrl.REQ_ORDER}/${reqOrderId}`, data, {
		withCredentials: true
	})
		.then(response => {
			if (response.data.result) {
				setReqOrder(patientId, reqOrderId, response.data.result);
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

export const updateReqOrderReadLog = (patientId: number, reqOrderId: number, read: boolean) => {
	APIHandler.AxiosInstance.post(`${ApiUrl.REQ_ORDER}/${reqOrderId}/readLog`, {
		dts: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
		isRead: read
	}, {
		withCredentials: true
	})
		.then(response => {
			if (response.data.result) {
				setReqOrderLastReadLog(patientId, reqOrderId, response.data.result);
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getReferralResults = (reqOrderId?: any) => {
	APIHandler.AxiosInstance.get(ApiUrl.REFERRAL_RESULTS, {
		withCredentials: true, params: {
			reqOrderId: reqOrderId
		}
	})
		.then(response => {
			if (response.data.result) {
				setReferralResults(reqOrderId, response.data.result.summary);
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

export const createReferralResult = (data: { comment: string, dts: string, isRead: boolean, reqOrderId: number, userId: number }) => {
	APIHandler.AxiosInstance.post(ApiUrl.REFERRAL_RESULTS, data, {
		withCredentials: true
	})
		.then(response => {
			if (response.data.result) {
				//setReferralResult(data.reqOrderId, response.data.result);
				setReferralResults(data.reqOrderId, response.data.result.summary[data.reqOrderId].referralResults);
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

export const updateReferralResultRead = (resultId: number, read: boolean, reqOrderId: number | null) => {
	APIHandler.AxiosInstance.patch(`${ApiUrl.REFERRAL_RESULTS}/${resultId}`, {
		isRead: read,
		dts: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
	}, {
		withCredentials: true
	})
		.then(response => {
			if (response.data.result) {
				getReferralResults(reqOrderId);
			}
		})
		.catch((error) => {
			console.log(error);
		});
};


export const getReferralResultsLog = (patientId: number, reqOrderId: number) => {
	APIHandler.AxiosInstance.get(ApiUrl.REFERRAL_RESULTS_LOG, {
		withCredentials: true, params: {
			reqOrderId: reqOrderId
		}
	})
		.then(response => {
			if (response.data.result) {
				setReqOrderLog(patientId, reqOrderId, response.data.result.summary);
			}
		})
		.catch((error) => {
			console.log(error);
		});
};