interface IAction {
    type: string;
    payload: any;
}

export interface ISort {
    sortBy: string | null;
    sortType: string | null;
}
export interface IStateReducer {
    data: any
    loading: boolean
    loadingMore: boolean
    error: boolean
    sort: ISort,
    hideItems: Array<string | number>
}

export const actionsTypes = {
    SET_REVIEWS: 'SET_REVIEWS',
    SET_SORT: 'SET_REVIEWS_SORT',
    SET_LOADING: 'SET_LOADING_REVIEW',
    SET_LOADING_MORE: 'SET_LOADING_REVIEW_MORE',
    SET_ERROR: 'SET_ERROR_REVIEW',
    SET_HIDE_ITEMS: 'SET_HIDE_ITEMS',
}

const initialState: IStateReducer = {
    loading: true,
    loadingMore: false,
    error: false,
    sort: {
        sortBy: null,
        sortType: null
    },
    data: {
        "summary": [],
        "total": 0,
        "count": 0,
        "startIndex": 0
    },
    hideItems: []
};

const Reducer = (state: any = { ...initialState }, action: IAction) => {
    switch (action.type) {
        case actionsTypes.SET_SORT:
            return { ...state, sort: action.payload }
        case actionsTypes.SET_ERROR:
            return { ...state, loading: action.payload }
        case actionsTypes.SET_LOADING_MORE:
            return { ...state, loadingMore: action.payload }
        case actionsTypes.SET_LOADING:
            return { ...state, loading: action.payload }
        case actionsTypes.SET_REVIEWS:
            return { ...state, data: action.payload }
        case actionsTypes.SET_HIDE_ITEMS:
            return { ...state, hideItems: action.payload }
        default:
            return state;
    }
};

export default Reducer;
