import ApiSubscription from 'src/Framework/ApiSubscription';
import * as assessmentActions from 'src/Activities/Counseling/Dashboard/Assessments/store/Actions';
import * as m1FormRecordActions from 'src/App/M1FormRecords/store/Actions';
import * as SummaryReviewLogActions from 'src/App/SummaryReviewLog/store/Actions';

export const enablePatientPush = false;

export const unsubscribePatientPage = (patientId: any) => {

    const unsubscribeArr = [
        assessmentActions.SubscriptionData(patientId),
        m1FormRecordActions.SubscriptionData(patientId),
        SummaryReviewLogActions.SubscriptionData(patientId),
    
    ];
    unsubscribeArr.forEach((value) => {
        ApiSubscription.instance.Unsubscribe(value);
    });
};
