import React, { FC } from 'react';
import useTags from 'src/App/Tags/Hooks/useTags';
import {
  InstitutionalIsCertification,
  TagDefinitionIds
} from 'src/App/Tags/store/utils';
import Certified from '.';

interface IProps {
  tags: number[];
}

export const InstitutionalDefinitionId = TagDefinitionIds.Institutional;

const CertifiedContainer: FC<IProps> = ({ tags }: IProps) => {
  const { values } = useTags({
    tagDefinitionId: InstitutionalDefinitionId
  });
  const isCertificationId = tags.find((v) => InstitutionalIsCertification(v));
  if (isCertificationId) {
    const value = values[isCertificationId];
    if (value) {
      return <Certified value={value} />;
    }
  }
  return null;
};

export default React.memo(CertifiedContainer);
