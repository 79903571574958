import { getMessage } from "src/Activities/Subject/Messages/Reducer";
import { ActivityPath, gotoClient } from "src/App/ActivityPanel/Store/ActivityActions";
import store from "src/store";
import { ISources, IStateReducer } from "./store/types";
import globalStyleVariables from "src/Framework/Styles/variables.module.scss";

export const complientStatusColor: Record<number, string> = {
    4: globalStyleVariables.green,
    3: globalStyleVariables.orange,
    2: globalStyleVariables.red,
    1: globalStyleVariables.darkGrey
}

export const FlaggedStatusOptions = [{
    value: null,
    label: 'All'
}, {
    value: true,
    label: 'Yes'
}, {
    value: false,
    label: 'No'
}]

export const defaultSources = (): ISources => ({
    ResidencyType: [],
    SchoolType: [],
    StudentClass: [],
    Standing: [],
    Eligibility: []
});

export const defaultCompliancePatientResult = (): IStateReducer['compliancePatients'] => ({
    startIndex: 0,
    count: 20,
    summary: {},
    total: 0
})


export const defaultHeaderStatistics = (): IStateReducer['headerStatistics'] => ({
    awaitingReviewPatientsNumber: 0,
    patientsNumberPerComplianceStatus: []
})

export const goToLegacyMessage = (subjectId: string | number, messageDetailId: number) => {
    const callBack = () => {
        localStorage.setItem('subjectUrl', 'messages');
        gotoClient(`${subjectId}`, ActivityPath.COMPLIANCE_MESSAGE, {});
        PubSub.publish('OPEN_THE_MESSAGE', {
            subjectId,
            messageDetailId
        });
    };
    localStorage.setItem('messageDetailId', messageDetailId.toString());
    store.dispatch(getMessage(messageDetailId, callBack));
}
