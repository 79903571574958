import React, { useRef } from 'react';
import cn from 'classnames';
import { Modal } from 'antd';
import { ModalProps } from 'antd/es/modal/index';
import Draggable from 'react-draggable';
import { v4 } from 'uuid';

import Header, { IProps as IHeaderProps } from './Header';
import Wrapper from './Wrapper';

import ModalSlider, { IModalSliderProps } from '../ModalSlider';

export interface IProps extends IHeaderProps {
  visible: boolean;
  children: React.ReactNode;
  onClose: Function;
  width?: number;
  containerStyles?: any;
  afterClose?: Function;
  hideHeader?: boolean;
  modalProps?: ModalProps;
  id?: string;
  hideClose?: boolean;
  isDragable?: boolean;
  isModalSlider?: boolean;
  modalSliderProps?: IModalSliderProps;
}

const Component = ({
  visible,
  title,
  children,
  onClose,
  buttons,
  containerStyles,
  width,
  back,
  onClickBack,
  afterClose,
  hideHeader,
  modalProps,
  headerProps,
  headerBorder,
  id,
  hideClose,
  customTitle,
  isModalSlider,
  modalSliderProps,
  isDragable
}: IProps) => {
  const wrapUUID = useRef(v4());

  if (isModalSlider && modalSliderProps) {
    return (
      <ModalSlider {...modalSliderProps} id={id}>
        {children}
      </ModalSlider>
    );
  }
  const onKeyDownMenu = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      // e.preventDefault();
    }
  };

  const onClickMenu = (e: any) => {
    e.stopPropagation();
  };

  return (
    <Modal
      // onClick={onClickMenu}
      // onKeyDown={onKeyDownMenu}
      focusTriggerAfterClose={true}
      keyboard={true}
      width={width}
      title=""
      open={visible}
      maskStyle={{
        backgroundColor: 'rgba(255,255,255,0.8)'
      }}
      footer={<div />}
      onCancel={() => onClose()}
      modalRender={(modal) => {
        return (
          <Wrapper wrapUUID={wrapUUID.current}>
            {isDragable ? (
              <Draggable handle=".handleDrag">{modal}</Draggable>
            ) : (
              modal
            )}
          </Wrapper>
        );
      }}
      {...modalProps}
      afterClose={() => afterClose && afterClose()}
      className={cn('modal-container ' + modalProps?.className || '', {
        'hide-modal-close-button': hideClose
      })}
      wrapProps={{
        ...modalProps?.wrapProps,
        id: wrapUUID.current
      }}
    >
      <div
        style={containerStyles}
        id={id}
        role="dialog"
        aria-modal="true"
        onClick={onClickMenu}
        onKeyDown={onKeyDownMenu}
      >
        {!hideHeader && (
          <Header
            title={title}
            buttons={buttons}
            back={back}
            onClickBack={onClickBack}
            headerProps={headerProps}
            headerBorder={headerBorder}
            customTitle={customTitle}
            isDragable={isDragable}
          />
        )}
        {children}
      </div>
    </Modal>
  );
};

Component.defaultProps = {
  width: 720,
  onClickBack: () => {},
  afterClose: () => {},
  hideHeader: false,
  modalProps: {},
  headerProps: {}
};

export default Component;
