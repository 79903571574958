import React from 'react';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import { Container } from './styled';

interface IProps {}

const Component: React.FC<IProps> = ({}: IProps) => {
  return (
    <Container>
      <div
        style={{
          width: '32px',
          height: '32px',
          border: '3px solid ${globalStyleVariables.newBrandingPrimary10010}',
          borderRight: `3px solid ${globalStyleVariables.newBrandingPrimary200}`,
          borderTop: `3px solid ${globalStyleVariables.newBrandingPrimary200}`
        }}
        className={'loader'}
      ></div>
    </Container>
  );
};

export default Component;
