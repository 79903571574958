import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Row } from 'antd';
import cn from 'classnames';

import ClinicSelector from 'src/Framework/Controls/Selectors/ClinicSelector';
import ProviderSelector from 'src/Framework/Controls/Selectors/ProviderSelector';
import Icon from 'src/Framework/Controls/Icon';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { getClinicById } from 'src/Framework/Controls/Selectors/ClinicSelector';
import {
  getProviderById,
  getProviderClinicAssignment,
  providerDisplayName
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';

import { permissionChecking } from 'src/App/User/Permission';
import { IPatchM1Record } from '..';
import {
  IM1FormRecord,
  M1FormRecordStatus
} from 'src/App/M1FormRecords/store/types';

import { Container, Separate, SelectBlock } from './styled';
import { ASSIGNMENT_TAGS } from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/types';
import { useM1Form } from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/Hooks/useM1Form';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { getUserById } from 'src/Framework/Controls/Selectors/UserSelector/utils';

interface IProps {
  form: IM1FormRecord;
  clinicTypes?: number[];
  subjectId: string;
  PatchRequest: IPatchM1Record;
}

export interface ISendData {
  clinicId: null | string | number;
  providerId: null | string | number;
}

const Component: FC<IProps> = ({
  form,
  clinicTypes,
  subjectId,
  PatchRequest
}: IProps) => {
  const { data: m1FormTemplates, actions } = useM1Form();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ISendData>({
    clinicId: form.clinicId,
    providerId: null
  });
  const routedProviderName = useMemo(() => {
    return form.assignedProviderId
      ? providerDisplayName(form.assignedProviderId)
      : '';
  }, [form.assignedProviderId]);

  useEffect(() => {
    const init = async () => {
      setLoadingData(true);
      await actions.getSimpleDto(form.formTemplateId);
      setLoadingData(false);
    };
    init();
  }, []);

  const onChangeData = (obj: Partial<ISendData>) => {
    setData({
      ...data,
      ...obj
    });
  };
  const clinic = getClinicById(form.clinicId);
  const user = getUserById(form.createUserId);

  const AllowToRouteBetweenAnyClinic = Object.values(m1FormTemplates)
    .find((v) => v.id === form.formTemplateId)
    ?.tags.includes(ASSIGNMENT_TAGS.AllowToRouteBetweenAnyClinic);
  return (
    <Container>
      {loadingData && <AbsoluteLoader />}
      <Row align="middle" justify="space-between" style={{ flex: 1 }}>
        <div>
          <Row align="middle">
            Clinic:&nbsp;&nbsp;<b>{clinic?.name}</b>
            <Separate />
            Created by:&nbsp;&nbsp;<b>{user?.name || ''}</b>
            <Separate />
            Routed to:&nbsp;&nbsp;
            <b>{routedProviderName}</b>
          </Row>
        </div>
        <div>
          <Row align="middle">
            <b>Route to:</b>
            <SelectBlock
              style={{
                pointerEvents:
                  form.status === M1FormRecordStatus.Locked ? 'none' : 'auto'
              }}
            >
              Clinic:
              <ClinicSelector
                multiple={false}
                value={data.clinicId}
                filterItem={(clinic) => {
                  if (AllowToRouteBetweenAnyClinic) {
                    return true;
                  }
                  const permission = permissionChecking({
                    anyClinic: false,
                    clinicId: clinic.id,
                    moduleId: 40,
                    functionId: 98
                  }).success;
                  return permission;
                }}
                onChange={(clinicId: string) => {
                  let providerId = data.providerId;
                  if (providerId) {
                    const prov = getProviderById(providerId);
                    if (prov) {
                      const a = getProviderClinicAssignment(
                        prov,
                        clinicId
                      )?.roles.includes('rendering');
                      if (!a) {
                        providerId = null;
                      }
                    }
                  }
                  onChangeData({ clinicId, providerId });
                }}
                selectProps={{
                  inputProps: {
                    placeholder: ''
                  },
                  bottomMargin: false,
                  hideLabel: true
                }}
              />
            </SelectBlock>
            <Separate />
            <SelectBlock
              style={{
                pointerEvents:
                  form.status === M1FormRecordStatus.Locked ? 'none' : 'auto'
              }}
            >
              Provider:
              <ProviderSelector
                value={data.providerId}
                filterByClinics={data.clinicId ? [+data.clinicId] : []}
                roleFilter={'rendering'}
                onChange={(providerId: string) => onChangeData({ providerId })}
                selectProps={{
                  inputProps: {
                    placeholder: ''
                  },
                  bottomMargin: false,
                  hideLabel: true
                }}
                containerStyles={{
                  width: '100%'
                }}
              />
            </SelectBlock>
            <Separate />
            <Button
              loading={loading}
              disabled={
                !data.clinicId ||
                !data.providerId ||
                form.status === M1FormRecordStatus.Locked
              }
              type="text"
              className={cn({
                'button-content': true,
                disabled: !data.clinicId || !data.providerId
              })}
              onClick={async () => {
                if (data.providerId && data.clinicId) {
                  setLoading(true);
                  await PatchRequest(
                    {
                      id: form.id,
                      assignedProviderId: +data.providerId,
                      clinicId: +data.clinicId
                    },
                    subjectId
                  );
                  setLoading(false);
                }
              }}
            >
              Send
              <Icon
                containerStyles={{ display: 'inline-block' }}
                name="paperPlane"
                color={globalStyleVariables.newBrandingPrimary200}
                size={16}
              />
            </Button>
          </Row>
        </div>
      </Row>
    </Container>
  );
};

export default React.memo(Component);
