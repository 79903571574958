import * as actionType from "../../RuleActions/ActionTypes";
import { ITemplateOption } from "../../Models/RuleTemplate";
import _ from 'lodash';

interface IAction {
  type: string;
  ruleId: string;
  option: ITemplateOption;
  componentId: number;
  optionVal: string;
  showModal: boolean;
  screenX: number;
  screenY: number;
  id: string;
  eventId?: string
}

export interface IOptionStore {
  ruleId: string;
  componentId: any;
  option: any;
  optionVal: string;
  showModal: boolean;
  screenX: number;
  screenY: number;
  ruleToShow?: any;
  eventId?: string;
}

const RuleOptionReducer = (state: IOptionStore[] = [], action: IAction) => {
  switch (action.type) {
    case actionType.ADD_COMPONENT_OPTION: {
      let newState = _.cloneDeep(state)
      const { ruleId, screenY, screenX, optionVal, option, componentId, eventId } = action
      let optionStore = newState.find((o: IOptionStore) => o.ruleId === action.ruleId);
      if (optionStore) {
        optionStore.componentId = componentId;
        optionStore.option = option;
        optionStore.optionVal = optionVal;
        optionStore.showModal = true;
        optionStore.screenX = screenX;
        optionStore.screenY = screenY;
        optionStore.eventId = eventId;
      } else {
        const value: IOptionStore = {
          ruleId,
          componentId,
          option,
          optionVal,
          showModal: true,
          screenX,
          screenY,
          eventId
        }
        newState.push(value);
      }
      return newState;
    }
    case actionType.HIDE_RULE_OPTION_MODEL: {
      let newState = _.cloneDeep(state)
      let optionStore = newState.find((o: IOptionStore) => o.ruleId === action.ruleId);
      if (optionStore)
        optionStore.showModal = false;
      return newState;
    }

    case actionType.CLEAR_RULE_EDITOR: {
      let newState = _.cloneDeep(state)
      let index = state.findIndex((o: any) => o.ruleId === action.id);
      if (index === -1) return newState;
      newState.splice(index, 1);
      return newState;
    }

    default:
      return state;
  }
};

export default RuleOptionReducer;
