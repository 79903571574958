import { TypedUseSelectorHook, useSelector, shallowEqual } from 'react-redux';
import { applyMiddleware, combineReducers, compose, Reducer } from 'redux';
import { legacy_createStore as createStore } from 'redux';

import thunk from 'redux-thunk';

import CohortCompliancePreviewReducer from 'src/Activities/Cohort/Compliance/ComplianceOverview/Reducer';
import CohortComplianceRulesReducer from 'src/Activities/Cohort/Compliance/ComplianceRules';
import CohortComplianceReducer from 'src/Activities/Cohort/Compliance/Reducer';
import MemberComplianceReducer from 'src/Activities/Cohort/Member/MemberCompliance/Reducer';
import CohortMemberReducer from 'src/Activities/Cohort/Member/Reducer';
import FactsLists from 'src/Activities/Subject/Facts/store/Reducer';
import NoteTypesReducer from 'src/App/Admin/Pages/NoteTypes/store/Reducer';
import CohortReducer from 'src/Activities/Cohort/Reducer';
import StatusReducer from 'src/Activities/CohortManager/NewCohort/StatusReducer';
import CohortManagerReducer from 'src/Activities/CohortManager/Store/CohortManagerReducer';
import CohortRuleDefinitionReducer from 'src/Activities/CohortManager/Store/CohortRuleDefinitionReducer';
import EnableCohortLoaderReducer from 'src/Activities/Compliance/CohortWidget/Store/cohortLoader';
import CohortWidgetReducer from 'src/Activities/Compliance/CohortWidget/Store/CohortWidgetReducer';
import ComplianceReducer from 'src/Activities/Compliance/Store/ComplianceReducer';
import ComplianceReviewReducer from 'src/Activities/Compliance/Store/Reviews/Reducer';
import CohortIdReducer from 'src/Activities/Compliance/Store/CurrentCohortReducer';
import NoteListReducer from 'src/Activities/Counseling/Notes/NoteList/Store/PatientNoteListReducer';
import PatientFormReducer from 'src/Activities/Forms/FormList/PatientFormReducer';
import FormReducer from 'src/Activities/Forms/FormsReducer';
import NoteContentReducer from 'src/Activities/Counseling/Notes/EditNote/Store/NoteContentReducer';
import NoteNavigationReducer from 'src/Activities/Counseling/Notes/EditNote/Store/NoteNavigationReducer';
import MessageRecipients from 'src/Activities/NewMessage/reucer';
import { PatientImageReducer } from 'src/Activities/Subject/Patients/Demographics/Controls/PatientImageReducer';
import { PatientInfoReducer } from 'src/Activities/Subject/Patients/store';
import { PatientHeaderReducer } from 'src/Activities/Subject/PatientHeader/store';
import PatientManagerReducer from 'src/Activities/Subject/Patients/PatientManager/PatientManagerReducer';
import RuleCommonReducer from 'src/Activities/RuleEditor/Store/RuleReducers/RuleCommonReducer/RuleCommonReducer';
import RuleDefinitionActionReducer from 'src/Activities/RuleEditor/Store/RuleReducers/RuleDefinitionReducer/RuleDefinitionActionReducer';
import RuleDefinitionReducer from 'src/Activities/RuleEditor/Store/RuleReducers/RuleDefinitionReducer/RuleDefinitionReducer';
import RuleDetailsReducer from 'src/Activities/RuleEditor/Store/RuleReducers/RuleDetailsReducer/RuleDetailsReducer';
import ImmunizationOptionReducer from 'src/Activities/RuleEditor/Store/RuleReducers/RuleOptionReducer/ImmunizationOptionReducer';
import ImmunizationProcedureOptionReducer from 'src/Activities/RuleEditor/Store/RuleReducers/RuleOptionReducer/ImmunizationProcedureOptionReducer';
import ImmunizationSeriesOptionReducer from 'src/Activities/RuleEditor/Store/RuleReducers/RuleOptionReducer/ImmunizationSeriesOptionReducers';
import ProcedureOptionReducer from 'src/Activities/RuleEditor/Store/RuleReducers/RuleOptionReducer/ProcedureOptionReducer';
import RuleOptionReducer from 'src/Activities/RuleEditor/Store/RuleReducers/RuleOptionReducer/RuleOptionReducer';
import RuleTemplateReducer from 'src/Activities/RuleEditor/Store/RuleReducers/RuleTemplateReducer/RuleTemplateReducer';
import RuleViewerDefinitionReducer from 'src/Activities/RuleViewer/Store/RuleReducers/RuleDefinitionReducer/RuleDefinitionReduer';
import RuleViewerDetailsReducer from 'src/Activities/RuleViewer/Store/RuleReducers/RuleDetailsReducer/RuleDetailsReducer';
import ScheduleDetailsReducer from 'src/Activities/Schedule/Store/ScheduleReducers/ScheduleDetailsReducer';
import { ScheduleSliceEventsReducer } from 'src/Activities/Schedule/Store/ScheduleSliceEventsReducer';
import { ScheduleSlicesReducer } from 'src/Activities/Schedule/Store/ScheduleSlicesReducer';
import ScheduleFavoriteReducer from 'src/Activities/Schedule/TopBar/FilterBar/Favorites/store';
import SubjectComplianceReducer from 'src/Activities/Subject/Compliance/Reducer';
import DocumentReducer from 'src/Activities/Subject/Facts/Requirements/Reducer';
import ImProReducer from 'src/Activities/Subject/Facts/ImProReducer';
import { PatientListsReducer } from 'src/Activities/Subject/PatientListReducer';
import SubjectReducer from 'src/Activities/Subject/Reducer';
import NotificationManagerReducer from 'src/Activities/SystemSetup/NotificationManager/Store/NotificationManagerReducer';
import RuleManagerReducer from 'src/Activities/SystemSetup/RulesManager/Store/RuleManagerReducer';
import RuleUpdateReducer from 'src/Activities/SystemSetup/RulesManager/Store/RuleUpdateReducer';
import ActivityReducer from '../App/ActivityPanel/Store/ActivityReducer';
import LoginReducer from '../App/LoginPage/store';
import { PreferencesReducer } from '../App/Preferences';
import SideNavBarReducer from '../App/SideNavBar/Store/SideNavBarReducer';
import MemberComplianceAnalysisReducer from '../Framework/Shared/ComplianceAnalysisReducer';
import MessageTemplateReducer from '../Framework/Shared/MessageTemplateReducer';
import NavigationReducer from 'src/Activities/Subject/Navigation/Store/NavigationReducer';
import CounselingDashboardReducer from 'src/Activities/Counseling/Dashboard/store';
import SummaryDetailReducer from 'src/Activities/Counseling/Dashboard/SummaryDetailReducer';
import CounselingNavigationReducer from 'src/Activities/Counseling/Dashboard/Navigation/Store/Reducer';
import ActivityUnassignedClientsReducer from 'src/Activities/Home/UnassignedClients/store/Reducer';
import { ScheduleEventsReducer } from '../Activities/Schedule/Store/ScheduleEventsReducer';
import SignalRReducer from '../App/SideNavBar/Store/SignalRReducer';
import { UserPermissionReducer } from '../App/User/Permissions';
import CurrentUserReducer from '../App/User/Current';
import NoteCommentsReducer from 'src/Activities/Counseling/NewNotes/Note/FixedSections/Comments/Store/NoteCommentsReducer';
import HospitalizationReducer from 'src/Activities/Counseling/Dashboard/Hospitalizations/store/Reducer';
import ReferralsReducer from '../Activities/Counseling/Dashboard/CounselingReferrals/store';
import TxnSetReducer from '../Activities/Counseling/Notes/EditNote/Store/TxnSetReducer';
import MessagesReducer from 'src/Activities/Home/Messages/store/Reducer';
import NewMessageReducer from 'src/Activities/Home/NewMessage/store/Reducer';
import ClinicProvidersReducer from 'src/Activities/Schedule/СlinicProviders/store/Reducer';
import ConsentsReducer from 'src/Activities/Counseling/Dashboard/Consents/store/Reducer';
import ProvidersReducer from 'src/App/Admin/Pages/Providers/store/Reducer';
import AssessmentsReducer from 'src/Activities/Counseling/Dashboard/Assessments/store/Reducer';

import AllergiesReducer from 'src/Activities/Counseling/Dashboard/Allergies/store';

import PatientsProvidersReducer from 'src/Activities/Counseling/Dashboard/PatientsProviders/store/Reducer';
import PatientsMessagesReducer from 'src/Activities/Subject/PatientMessages/store/Reducer';
import TypeRisksReducer from 'src/App/Admin/Pages/TypeRisks/store/Reducer';
import PatientsAlertsReducer from 'src/Activities/Counseling/Dashboard/Alerts/store/Reducer';
import AssessmentReducer from 'src/Activities/Counseling/Notes/EditNote/Store/DiagnosisReducer';
import PatientsDynamicsReducer from 'src/Activities/Counseling/Dashboard/Dynamics/store/Reducer';
import { ActivityAppointmentsReducer } from '../Activities/Home/Appointments/Store/ActivityAppointmentsReducer';
import PatientsDiagnosisReducer from 'src/Activities/Counseling/Dashboard/Diagnosis/store/Reducer';
import JobsReducer from 'src/App/Jobs/store/Reducer';
import TasksReducer from 'src/Activities/Home/Tasks/store/Reducer';
import TodosReducer from 'src/Activities/Home/Todos/store/Reducer';
import InsurancesReducer from 'src/Activities/Subject/Patients/Insurance/store/Reducer';
import DashboardReducer from 'src/Activities/Home/Dashboards/store/Reducer';
import ReferralsDataReducer from 'src/App/Referrals/store/Reducer';
import MyClientsReducer from 'src/Activities/Home/MyClients/store/Reducer';
import InsuranceCompaniesReducer from 'src/App/Admin/Pages/InsuranceCompanies/store';
import CategoriesReducer from 'src/App/Admin/Pages/Categories/store';
import AppointmentTypesReducer from 'src/App/Admin/Pages/AppointmentTypes/store';
import NotesOfConcernReducer from 'src/Activities/Subject/NotesOfConcern/store';
import WaitingListSettingsReducer from 'src/Activities/Schedule/WaitingListSettings/store';
import PatientsAttachmentsReducer from 'src/Activities/Counseling/Dashboard/Attachments/store/Reducer';
import NoteDetailsReducer from 'src/Activities/Counseling/NewNotes/Note/store';
import UserPreferencesReducer from 'src/App/UserPreferences/store';
import UkvpReducer from 'src/App/Ukvp/store';
import ClinicsReducer from 'src/App/Admin/Pages/Clinics/store';
import AppointmentsNotDischargedReducer from 'src/Activities/Schedule/TopBar/FilterBar/AppointmentsNotDischarged/store';
import AdminUsersReducer from 'src/App/Admin/Pages/Users/store';
import GenericTypesReducer from 'src/App/GenericTypes/store';
import UserPatientAccessReducer from 'src/Activities/Subject/Patients/Demographics/Controls/UserPatientAccess/store';
import PlanReducer from 'src/App/Admin/Pages/Plan/store';
import TableauReportsReducer from 'src/Activities/Home/Reports/store';
import AutomatedTasksReducer from 'src/App/Admin/Pages/AutomatedTasks/store';
import AutoReplaceReducer from 'src/App/UserPreferences/AutoReplace/store';
import ProviderTypesReducer from 'src/App/Admin/Pages/ProviderTypes/store';
import TemplateFormsReducer from 'src/App/TemplateForms/store';
import TagsReducer from 'src/App/Tags/store';
import UserGroupsReducer from 'src/App/Admin/Pages/UserGroups/store';
import RxDiscontinueReasonReducer from 'src/App/RxDiscontinueReason/store';
import MedicationsReducer from 'src/Activities/Counseling/Dashboard/Medications/store';
import OutreachReducer from 'src/Activities/Home/Outreach/store';
import MedicalReducer from 'src/Activities/Medical/store';
import ConflictsResolutionReducer from 'src/App/ConflictResolutionManager/store/index';
import BuildingBlocksReducer from 'src/App/Admin/Pages/FormBuilder/Lists/BuildingBlocks/store';
import HeadersAndFootersReducer from 'src/App/Admin/Pages/FormBuilder/Lists/HeadersAndFooters/store';
import FormsReducer from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store';
import SettingsReducer from 'src/App/Settings/store';
import GroupTxnReducer from 'src/App/GroupTxn/store';
import OtherPartyReducer from 'src/App/OtherParty/store';
import ProviderAssessmentsReducer from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Assessments/store';
import ProviderGoalsReducer from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Goals/store';
import ProviderConsentsReducer from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Consents/store';
import ProviderEvaluationsReducer from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Evaluations/store';
import ProviderContractsReducer from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Contracts/store';
import ProviderAgreementsReducer from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Agreements/store';
import ProviderAttachmentsReducer from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Attachments/store';
import SupervisorsReducer from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Dashboard/Supervisors/store';
import SupervisorRolesReducer from 'src/App/Admin/Pages/SupervisorRoles/store';
import M1FormRecordsReducer from 'src/App/M1FormRecords/store';
import M1FormRecordSubmission from 'src/App/M1FormRecordSubmission/store';
import ProviderTasksReducer from 'src/App/Trainee/Main/Pages/Tasks/store';
import ProviderAssignedClientsReducer from 'src/App/Trainee/Main/Pages/AssignedClients/store';
import ProviderFormsReducer from 'src/App/Trainee/Main/Pages/Forms/store';
import TraineeInfoReducer from 'src/App/Trainee/Main/store';
import TraineeContactsReducer from 'src/App/Trainee/Main/Pages/Contacts/store';
import TraineeNotesReducer from 'src/App/Trainee/Main/Pages/Supervision/Notes/store';
import ProviderDocumentsReducer from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Documents/store';
import ProviderActualsReducer from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Actuals/store';

import ActivitySupervisionReducer from 'src/Activities/Home/Supervision/store';
import ActivityComplianceReducer from 'src/Activities/Home/Cohort/store';
import SelfCheckinsReducer from 'src/App/SelfCheckins/store';
import HippaReducer from 'src/App/Hippa/store';

import AdminGenericTypeReducer from 'src/App/Admin/Pages/GroupTypes/store';
import SummaryReviewLogReducer from 'src/App/SummaryReviewLog/store';

import PatientMenuItemsReducer from 'src/App/Admin/Pages/PatientMenuItems/store';

import PatientTodoReducer from 'src/Activities/Subject/Todo/store';
import TxnClinicLinksReducer from 'src/App/Admin/Pages/TxnClinicLinks/store';
import DiagnosisClinicLinksReducer from 'src/App/Admin/Pages/DiagnosisClinicLinks/store';

import CheckInStatusReducer from 'src/App/Admin/Pages/CheckInStatus/store';
import TicketDetail from 'src/App/TicketDetail/store';
import ReqOrder from 'src/App/ReqOrder/store';
import TxnCode from 'src/App/TxnCode/store';
import AdditionalInfo from 'src/App/Admin/Pages/AdditionalInfo/store';
import PatientAdditionalInfo from 'src/Activities/Subject/Patients/AdditionalInfo/store';
import Inventory from 'src/App/Inventory/store';

import ReqPriority from 'src/App/ReqPriority/store';
import ticketDiagnosis from 'src/App/TicketDiagnosis/store';
import Marketplace from 'src/App/Admin/Pages/PublicForms/store';

import NoteRisks from 'src/App/NoteRisks/store';
import ProviderAlias from 'src/App/ProviderAlias/store';
import RcopiaNotificationCount from 'src/App/RcopiaNotificationCount/store';

import ProviderOutOfOffice from 'src/App/ProviderOutOfOffice/store';
import LetterTemplatesManagement from 'src/App/Admin/Pages/LetterTemplatesManagement/store';
import TransactionSetsManagement from 'src/App/Admin/Pages/TransactionSets/store';
import LetterField from 'src/App/LetterField/store';

import PortalProviderMessaging from 'src/App/PortalProviderMessaging/store';

import PatientPortalSetup from 'src/App/Admin/Pages/PatientPortalSetup/store';
import ResultCodesReducer from 'src/App/Admin/Pages/OrdersManager/ResultCodes/store';
import OrderReportReducer from 'src/App/Admin/Pages/OrdersManager/ReportSetup/store/OrderReport';
import OrderActionsReducer from 'src/App/Admin/Pages/OrdersManager/OrderActions/store';

import AdministrativeNotesReducer from 'src/Activities/Subject/Patients/AdministrativeNotes/store';

import PortalClinicAddressReducer from 'src/App/PortalClinicAddress/store';
import PortalLocationReducer from 'src/App/PortalLocation/store';

import PortalSymptomReducer from 'src/App/PortalSymptom/store';

import PortalLocationClinicProviderReducer from 'src/App/PortalLocationClinicProvider/store';
import PortalSymptomReasonsReducer from 'src/App/PortalSymptomReasons/store';
import PortalAppointmentReasonProviderLinkReducer from 'src/App/PortalAppointmentReasonProviderLink/store';

import ClinicProviderReducer from 'src/App/ClinicProvider/store';
import ClinicProviderAliasReducer from 'src/App/ClinicProviderAlias/store';
import StatusSetupReducer from 'src/App/Admin/Pages/OrdersManager/StatusSetup/store';

export const USER_LOGOUT = 'USER_LOGOUT';

const appReducer = combineReducers({
  activity: ActivityReducer,
  assessment: AssessmentReducer,
  rules: RuleManagerReducer,
  notifications: NotificationManagerReducer,
  ruleTemplates: RuleTemplateReducer,
  ruleMetaData: RuleDetailsReducer,
  ruleViewerMetaData: RuleViewerDetailsReducer,
  ruleDefinition: RuleDefinitionReducer,
  ruleViewerDefinition: RuleViewerDefinitionReducer,
  ruleOptions: RuleOptionReducer,
  ruleToShow: RuleCommonReducer,
  scheduleDetails: ScheduleDetailsReducer,
  scheduleEvents: ScheduleEventsReducer,
  schedule: combineReducers({
    sliceEvents: ScheduleSliceEventsReducer,
    slices: ScheduleSlicesReducer,
    favorites: ScheduleFavoriteReducer,
    clinicProviders: ClinicProvidersReducer,
    waitingListSettings: WaitingListSettingsReducer,
    appointmentsNotDischarged: AppointmentsNotDischargedReducer
  }),
  immunizations: ImmunizationOptionReducer,
  immunizationsSeries: ImmunizationSeriesOptionReducer,
  procedures: ProcedureOptionReducer,
  cohort: CohortReducer,
  subject: SubjectReducer,
  login: LoginReducer,
  document: DocumentReducer,
  cohortMember: CohortMemberReducer,
  actionStatus: RuleDefinitionActionReducer,
  compliance: ComplianceReducer,
  complianceReview: ComplianceReviewReducer,
  cohortWList: CohortWidgetReducer,
  cohortList: CohortManagerReducer,
  cohortRuleDefinition: CohortRuleDefinitionReducer,
  memberCompliance: MemberComplianceReducer,
  memberComplianceAnalysis: MemberComplianceAnalysisReducer,
  navBarHideShow: SideNavBarReducer,
  signalR: SignalRReducer,
  cohortCompliance: CohortComplianceReducer,
  complianceRules: CohortComplianceRulesReducer,
  ruleUpdateStatus: RuleUpdateReducer,
  cohortCompliancePreview: CohortCompliancePreviewReducer,
  cohortCreatStatus: StatusReducer,
  subjectCompliance: SubjectComplianceReducer,
  factImPro: ImProReducer,
  msgTemplate: MessageTemplateReducer,
  msgRecipients: MessageRecipients,
  imPros: ImmunizationProcedureOptionReducer,
  currentCohort: CohortIdReducer,
  cohortLoader: EnableCohortLoaderReducer,
  forms: FormReducer,
  notesContent: NoteContentReducer,
  notesNavigation: NoteNavigationReducer,
  notesComments: NoteCommentsReducer,
  user: combineReducers({
    current: CurrentUserReducer,
    permissions: UserPermissionReducer,
    preferences: PreferencesReducer,
    providers: ProvidersReducer,
    referrals: ReferralsDataReducer
  }),
  client: combineReducers({
    lists: PatientListsReducer,
    forms: PatientFormReducer,
    notes: NoteListReducer,
    patientInfo: PatientInfoReducer,
    userPatientAccess: UserPatientAccessReducer,
    patientImage: PatientImageReducer,
    patientManager: PatientManagerReducer,
    navigation: NavigationReducer,
    patientHeader: PatientHeaderReducer,
    summaryDetail: SummaryDetailReducer,
    counselingDashboard: CounselingDashboardReducer,
    counselingNavigation: CounselingNavigationReducer, //TODO: What is going on in this reducer?))
    noteDetails: NoteDetailsReducer
  }),
  home: combineReducers({
    unassignedClients: ActivityUnassignedClientsReducer,
    appointments: ActivityAppointmentsReducer,
    myClients: MyClientsReducer,
    tableauReports: TableauReportsReducer,
    outreach: OutreachReducer,
    supervision: ActivitySupervisionReducer,
    compliance: ActivityComplianceReducer
  }),
  hospitalizations: HospitalizationReducer,
  factsLists: FactsLists,
  reqOrders: ReferralsReducer,
  noteTypes: NoteTypesReducer,
  typeRisks: TypeRisksReducer,
  txnSets: TxnSetReducer,
  messages: MessagesReducer,
  new_message: NewMessageReducer,
  consents: ConsentsReducer,
  assessments: AssessmentsReducer,
  patientsProviders: PatientsProvidersReducer,
  patientsMessages: PatientsMessagesReducer,
  patientsAlerts: PatientsAlertsReducer,
  patientsDynamics: PatientsDynamicsReducer,
  patientsDiagnosis: PatientsDiagnosisReducer,
  jobs: JobsReducer,
  tasks: TasksReducer,
  todos: TodosReducer,
  insurances: InsurancesReducer,
  dashboard: DashboardReducer,
  adminPanel: combineReducers({
    diagnosisClinicLinks: DiagnosisClinicLinksReducer,
    txnClinicLinks: TxnClinicLinksReducer,
    insuranceCompanies: InsuranceCompaniesReducer,
    categories: CategoriesReducer,
    appointmentTypes: AppointmentTypesReducer,
    clinics: ClinicsReducer,
    users: AdminUsersReducer,
    plans: PlanReducer,
    automatedTasks: AutomatedTasksReducer,
    providerTypes: ProviderTypesReducer,
    userGroups: UserGroupsReducer,
    supervisionRoles: SupervisorRolesReducer,
    formBuilder: combineReducers({
      BuildingBlocksReducer,
      HeadersAndFootersReducer,
      FormsReducer
    }),
    groupTypes: AdminGenericTypeReducer,
    patientChartMenu: PatientMenuItemsReducer,
    checkInStatus: CheckInStatusReducer,
    additionalInfo: AdditionalInfo,
    marketplace: Marketplace,
    LetterTemplatesManagement,
    TransactionSetsManagement,
    PatientPortalSetup,
    ordersManager: combineReducers({
      ResultCodesReducer,
      OrderReportReducer,
      OrderActionsReducer,
      StatusSetupReducer
    })
  }),
  notesOfConcern: NotesOfConcernReducer,
  patientsAttachments: PatientsAttachmentsReducer,
  userPreferences: UserPreferencesReducer,
  ukvp: UkvpReducer,
  genericTypes: GenericTypesReducer,
  autoReplace: AutoReplaceReducer,
  tags: TagsReducer,
  templateForms: TemplateFormsReducer,
  rxDiscontinueReason: RxDiscontinueReasonReducer,
  medications: MedicationsReducer,
  medical: MedicalReducer,
  settings: SettingsReducer,
  groupTxn: GroupTxnReducer,
  conflictsResolution: ConflictsResolutionReducer,
  otherParty: OtherPartyReducer,
  trainee: combineReducers({
    dashboard: combineReducers({
      consents: ProviderConsentsReducer,
      assessments: ProviderAssessmentsReducer,
      agreements: ProviderAgreementsReducer,
      attachments: ProviderAttachmentsReducer,
      assignedSupervisors: SupervisorsReducer,
      goals: ProviderGoalsReducer,
      evaluations: ProviderEvaluationsReducer,
      contracts: ProviderContractsReducer,
      documents: ProviderDocumentsReducer,
      actuals: ProviderActualsReducer
    }),
    tasks: ProviderTasksReducer,
    assignedClients: ProviderAssignedClientsReducer,
    forms: ProviderFormsReducer,
    info: TraineeInfoReducer,
    contacts: TraineeContactsReducer,
    notes: TraineeNotesReducer
  }),
  m1FormRecords: M1FormRecordsReducer,
  m1FormRecordSubmission: M1FormRecordSubmission,
  selfCheckins: SelfCheckinsReducer,
  hippa: HippaReducer,
  summaryReviewLog: SummaryReviewLogReducer,
  allergies: AllergiesReducer,
  patientTodo: PatientTodoReducer,
  ticketDetail: TicketDetail,
  reqOrder: ReqOrder,
  txnCode: TxnCode,
  patientAdditionalInfo: PatientAdditionalInfo,
  inventory: Inventory,
  ReqPriority,
  ticketDiagnosis,
  providerAlias: ProviderAlias,
  noteRisks: NoteRisks,
  rcopiaNotificationCount: RcopiaNotificationCount,
  providerOutOfOffice: ProviderOutOfOffice,
  letterField: LetterField,
  PortalProviderMessaging,
  AdministrativeNotes: AdministrativeNotesReducer,
  PortalClinicAddress: PortalClinicAddressReducer,
  PortalLocation: PortalLocationReducer,
  PortalSymptom: PortalSymptomReducer,
  PortalSymptomReasons: PortalSymptomReasonsReducer,
  PortalLocationClinicProvider: PortalLocationClinicProviderReducer,
  PortalAppointmentReasonProviderLink:
    PortalAppointmentReasonProviderLinkReducer,
  ClinicProvider: ClinicProviderReducer,
  ClinicProviderAlias: ClinicProviderAliasReducer
});

export const useAppSelector: TypedUseSelectorHook<IStore> = useSelector;

export const useShallowEqualSelector = <TReturn>(
  selector: (state: IStore) => TReturn
) => useSelector<IStore, TReturn>(selector, shallowEqual);

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = () => {
  return (next: any) => {
    return (action: any) => {
      const result = next(action);
      return result;
    };
  };
};

const rootReducer: Reducer<IStore> = (state: any, action: any): any => {
  if (action && action.type === USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export type IStore = ReturnType<typeof appReducer>;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(logger, thunk))
);

export default store;
