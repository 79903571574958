import _ from "lodash";
import store from 'src/store';

import { actionTypes } from '.'

import { ApiUrl } from "../../Framework/Common/ApiUrl";
import { APIHandler } from "../../Framework/Communication/ServerProxy";
import { IPreference } from "./types";


export const updateCurrentUserPreference = (payload: any) => {
  store.dispatch({
    type: actionTypes.UPDATE_CURRENT_USER_PREFERENCES,
    payload
  })
};

export const getCurrentUserPreference = async (): Promise<Record<string, IPreference> | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(ApiUrl.GET_CURRENT_USER_PREFERENCE, "", {
      withCredentials: true,
    });
    if (response.data.success) {
      return response.data.result
    }
    return
  } catch (e) {
    console.log(e)
    return
  }
};

export const parsePrefs = (prefs: any) => {
  try {
    if (prefs.accessibilityOptions.value) {
      prefs.accessibilityOptions.value = JSON.parse(prefs.accessibilityOptions.value);
    } else {
      prefs.patientHeader.value = {};
      prefs.dashboardSettings.value = {};
    }
  }
  catch (e) {
    console.log(e);
  }
  try {

    if (prefs.patientHeader.value) {
      prefs.patientHeader.value = JSON.parse(prefs.patientHeader.value);
    } else {
      prefs.patientHeader.value = {};
    }
  }
  catch (e) {
    prefs.patientHeader.value = {};
    console.log(e);
  }
  try {
    if (prefs.dashboardSettings.value) {
      prefs.dashboardSettings.value = JSON.parse(prefs.dashboardSettings.value);
    } else {
      prefs.dashboardSettings.value = {};
    }
  }
  catch (e) {
    prefs.dashboardSettings.value = {};
    console.log(e);
  }
  if (prefs.patientTerm) {
    localStorage.setItem("patientTerm", prefs.patientTerm.value);
  }
  return prefs;
}

export const handleAccessibilityOptions = (opt: any): any => {
  return (dispatch: any) => {
    dispatch(addAccessibilityOption(opt));
    let optClone = _.cloneDeep(opt);
    optClone.value = JSON.stringify(opt.value);
    let request = {
      userId: optClone.userId,
      preferences: [optClone],
    };
    return request;
  };
};

export const handlePatientHeaderSettings = (opt: any) => {
  return (dispatch: any) => {
    dispatch(addPatientHeader(opt));
    let optClone = _.cloneDeep(opt);
    optClone.value = JSON.stringify(opt.value);
    let request = {
      userId: optClone.userId,
      preferences: [optClone],
    };
    return request;
  };
};

export const handleDashboardSettings = (opt: any): any => {
  return (dispatch: any) => {
    dispatch(addDashboardSettings(opt));
    let optClone = _.cloneDeep(opt);
    console.log(optClone)
    optClone.value = JSON.stringify(opt.value);
    let request = {
      userId: optClone.userId,
      preferences: [optClone],
    };
    return request;
  };
};

export const setPreference = async (data: any) => {
  return await APIHandler.AxiosInstance.post(ApiUrl.SAVE_USER_PREFERENCES, data, {
    withCredentials: true,
  });
};

export const addPatientHeader = (data: any) => {
  return {
    type: actionTypes.SET_PATIENT_HEADER,
    payload: data,
  };
};

export const addDashboardSettings = (data: any) => {
  return {
    type: actionTypes.SET_DASHBOARD_SETTINGS,
    payload: data,
  };
};

export const addAccessibilityOption = (keyboard: any) => {
  return {
    type: actionTypes.SET_ACCESSIBILITY_OPTION,
    payload: keyboard,
  };
};

export const setCurrentUserPreference = (payload: any) => {
  store.dispatch({
    type: actionTypes.GET_CURRENT_USER_PREFERENCE,
    payload,
  })
}

export const addCurrentUserPreference = (preferences: any) => {
  return {
    type: actionTypes.GET_CURRENT_USER_PREFERENCE,
    payload: preferences,
  };
};

export const collapseRequirement = (payload: any) => {
  store.dispatch({
    type: actionTypes.COLLAPSE_REQUIREMENTS,
    payload
  })
}
