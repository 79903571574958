import _ from "lodash";

const prefix = 'PATIENT_HEADER';

export const actionsTypes = {
  SET: `${prefix}SET`,
};

const initState: any = {};

export interface IState { }

export interface IAction {
  type: string;
  payload: any;
  subjectId: any;
}

export const PatientHeaderReducer = (state: IState = initState, action: IAction) => {
  switch (action.type) {
    case actionsTypes.SET: {
      const newState = _.cloneDeep(state);
      const key = action.subjectId;
      newState[key] = action.payload;
      return newState;
    }
    default:
      return state;
  }
};