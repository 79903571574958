import { ISort } from 'src/Framework/Controls/Table/types';

export const SET_VIEW = 'SET_VIEW';
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
export const SET_VALIDATION = 'SET_VALIDATION';
export const SET_NEW_STUDENT = 'SET_NEW_STUDENT';
export const SET_SORT_PATIENTS = 'SET_SORT_PATIENTS';

export const setView = (data: any) => {
  return {
    type: SET_VIEW,
    payload: data
  };
};

export const setSearchData = (data: any) => {
  return {
    type: SET_SEARCH_DATA,
    payload: data
  };
};

export const setValidation = (data: any) => {
  return {
    type: SET_VALIDATION,
    payload: data
  };
};

export const setNewStudent = (newSubjectId: any, addNewStudent: boolean) => {
  let data = {
    newSubjectId,
    addNewStudent
  };
  return {
    type: SET_NEW_STUDENT,
    payload: data
  };
};

export const sortPatients = (data: ISort) => {
  return {
    type: SET_SORT_PATIENTS,
    payload: data
  };
};
