import globalStyleVariables from 'src/Framework/Styles/variables.module.scss'

import styled from "styled-components";

export const ModalBody = styled.div`
    padding: 24px 24px 0 24px;
    border-top: 1px solid ${globalStyleVariables.borderColor};
`

export const SubTitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #333333;
`

export const RulesContainer = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #333333;
    margin: 0 0 10px 0;
    padding: 5px 10px;
    .rule-item {
        margin: 8px 0 0 0;
        line-height: 12px;
    }
`

export const RuleTitle = styled.div`
    margin: 0 5px;
`

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
`