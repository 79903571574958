import store, { USER_LOGOUT } from 'src/store';

export const NAVBAR_SHOW_HIDE = "NAVBAR_SHOW_HIDE";
export const NAVBAR_VISIBLE = "NAVBAR_VISIBLE";
export const CLEAR_ACTIVITIES = "CLEAR_ACTIVITIES";


export const navbarShowHide = () => {
  return {
    type: NAVBAR_SHOW_HIDE
  };
};


export const navbarVisible = () => {
  return {
    type: NAVBAR_VISIBLE
  };
};


export const handleLogout = () => {
  store.dispatch({
    type: USER_LOGOUT
  })
};

export const clearActivities = () => {
  return {
    type: CLEAR_ACTIVITIES
  };
};

