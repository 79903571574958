import _ from 'lodash';

import { getClinicByCode } from 'src/Framework/Controls/Selectors/ClinicSelector';
import { getReasonByID } from 'src/Framework/Controls/Selectors/ReasonSelector/utils';
import store from 'src/store';
import {
  IProvider,
  IProviderPushDTO,
  IRoles
} from 'src/App/Admin/Pages/Providers/store/types';

export const getProviderByCode = (code: string): IProvider | undefined => {
  const providers = Object.values(store.getState().user.providers.summary);
  return providers.find((v) => v.code === code);
};

export const getCurrentProvider = () => {
  const providerId = store.getState().user.preferences.userProviderId?.value;
  return providerId ? +providerId : null;
};
export const getCurrentProviderInfo = () => {
  const providerId = store.getState().user.preferences.userProviderId?.value;
  const providers = store.getState().user.providers.summary;
  const currentProvider = providerId ? providers[providerId + ''] : null;
  const info = {
    gender: '',
    groupName: '',
    label: '',
    provider: {},
    value: ''
  };
  if (currentProvider) {
    info.gender = currentProvider.gender;
    info.value = currentProvider.code;
    info.label = currentProvider.lastName + ' ' + currentProvider.firstName;
    info.provider = currentProvider;
    return info;
  } else {
    return null;
  }
};

export const getCurrentProviderCode = () => {
  const provider = store.getState().user.preferences.userProviderCode?.value;
  return provider ? provider : null;
};

export const getCurrentBillingProvider = () => {
  const providerId = store.getState().user.preferences.billingProviderId?.value;
  return providerId ? +providerId : null;
};

export const getProviderById = (id: string | number): IProvider | undefined => {
  const providers = store.getState().user.providers.summary;
  return providers[id];
};

export const clinicProviderRoleChecker = (
  clinicId: number | string | null | undefined,
  providerId: string | number | null | undefined,
  role: IRoles
): boolean => {
  if (!clinicId || !providerId) return false;
  const providers = store.getState().user.providers.summary;
  const provider = providers[providerId];
  if (provider) {
    const clinicAssignment = getProviderClinicAssignment(provider, clinicId);
    if (clinicAssignment) {
      return clinicAssignment.roles.includes(role);
    }
  }
  return false;
};

export const clinicHasRoleProviders = (
  clinicId: number | string,
  role: IRoles
) => {
  const providers = Object.values(store.getState().user.providers.summary);
  return providers
    .filter(
      (v) =>
        v.isActive &&
        getProviderClinicAssignment(v, clinicId)?.roles.includes(role)
    )
    .sort((a, b) =>
      providerDisplayName(a.providerId)?.localeCompare(
        providerDisplayName(b.providerId)
      )
    );
};

export const providerGenerateName = (provider: IProvider) => {
  return `${provider.firstName} ${provider.lastName}`;
};

export const providerDisplayName = (
  providerId: number | string | undefined | null
) => {
  if (!providerId) {
    return '';
  }
  const provider = getProviderById(providerId);
  if (provider) {
    return providerGenerateName(provider);
  }
  return '';
};
export const providerDisplayNameByCode = (providerCode: string) => {
  if (!providerCode) {
    return '';
  }
  const provider = getProviderByCode(providerCode);
  if (provider) {
    return providerGenerateName(provider);
  }
  return '';
};

export const providersValidForReason = (
  reasonId: number,
  providerIds: number[],
  clinicCode: string
) => {
  const providerTypes = store.getState().adminPanel.providerTypes.summary;
  const providers = providerIds.map((id) => getProviderById(id));
  const selectedClinic = getClinicByCode(clinicCode);
  const filtered = providers
    .filter((v) => {
      const allSuggestedAppointmentReasons = _.uniq(
        v?.providerTypeIds.reduce((acc: number[], typeId) => {
          return acc.concat(
            Object.values(
              providerTypes[typeId]?.suggestedAppointmentReasons
            ).map((item) => item.appointmentReasonId)
          );
        }, [])
      );
      const allSuggestedAppointmentReasonsClinics = _.uniq(
        allSuggestedAppointmentReasons.map(
          (reasonId) => getReasonByID(reasonId)?.clinicId
        )
      );
      // no assigned providerType
      if (v?.providerTypeIds?.length === 0) {
        return true;
      } else if (
        // no AppointmentReasons assigned to some of providerTypes
        v?.providerTypeIds?.some(
          (id) =>
            Object.keys(providerTypes[id]?.suggestedAppointmentReasons)
              .length === 0
        )
      ) {
        return true;
      } else if (
        // no AppointmentReasons assigned to selected clinic
        !allSuggestedAppointmentReasonsClinics.includes(selectedClinic?.id)
      ) {
        return true;
      } else {
        return v?.providerTypeIds.find(
          (val) => providerTypes[val]?.suggestedAppointmentReasons[reasonId]
        );
      }
    })
    .filter((v) => v);
  return filtered as IProvider[];
};

export const allProvidersByClinicsAndRole = (
  clinicIds: (number | undefined)[],
  role: IRoles
) => {
  const providers = Object.values(store.getState().user.providers.summary);
  return providers.filter((v) => {
    if (!v.isActive) {
      return false;
    }
    const hasRole = clinicIds.some((clinicId) =>
      clinicId !== undefined
        ? getProviderClinicAssignment(v, clinicId)?.roles.includes(role)
        : false
    );
    return hasRole;
  });
};

export const getProviderClinicAssignment = (
  provider: IProvider | null | undefined | Partial<IProvider>,
  clinicId: number | string
) => {
  if (!provider?.clinicAssignments) {
    return null;
  }
  const assignment = Object.values(provider.clinicAssignments).find(
    (v) => `${v.clinicId}` === `${clinicId}`
  );
  if (assignment) {
    return assignment;
  }
  return null;
};

export const getClinicConfig = (
  provider: IProvider | null | undefined | Partial<IProvider>,
  clinicId: number | string
) => {
  if (!provider?.clinicConfigs) {
    return null;
  }
  const config = Object.values(provider.clinicConfigs).find(
    (v) => `${v.clinicId}` === `${clinicId}`
  );
  if (config) {
    return config;
  }
  return null;
};

export const isProviderTypeValid = (
  providerId: string | number,
  providerTypes: number[]
) => {
  const provider = store.getState().user.providers.summary[providerId];
  if (provider) {
    return provider.providerTypeIds.some((v) => providerTypes.includes(v));
  }
  return false;
};

export const getCleanedProviderCodes = (
  clinicIds: number[],
  allProvidersCodes: string[],
  roleFilter: IRoles
) => {
  let cleanedProviders = allProvidersCodes;
  if (clinicIds.length !== 0) {
    const selectedProviders = allProvidersCodes.map(
      (item) => getProviderByCode(item) as IProviderPushDTO
    );
    cleanedProviders = selectedProviders
      .filter((v) =>
        clinicIds.find((id) => {
          const clinic = getProviderClinicAssignment(v, id);
          if (clinic) {
            return clinic.roles.includes(roleFilter);
          }
          return false;
        })
      )
      .map((item) => item?.code);
  }
  return cleanedProviders;
};
