import React from 'react';
import { notification } from 'antd';
import Spinner from '../Controls/AbsoluteLoader/Spinner';

const refreshingKey = 'refreshing';

export const RefreshingNotification = () => {
  notification.open({
    key: refreshingKey,
    icon: (
      <div>
        <Spinner />
      </div>
    ),
    message: 'Session refreshing...',
    duration: 0
  });
};

export const RefreshingNotificationClose = () => {
  notification.close(refreshingKey);
};
