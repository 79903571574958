import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { getClinics } from '../Controls/Selectors/ClinicSelector';
import { formattedDate } from '../Shared/Shared';

function getMinutesBetweenDates(
  startDate: string,
  endDate: string,
  duration: number
) {
  var diff = Math.abs(
    new Date(endDate).getTime() - new Date(startDate).getTime()
  );
  return Math.floor(diff / 1000 / 60);
}

interface IDurationByClinic {
  [clinicId: string]: {
    label: string;
    value: string;
  }[];
}

function convertMinsToHrsMins(num: number) {
  const h = Math.floor(num / 60);
  const m = Math.round(num % 60);

  const b = h < 10 ? '0' + h : h;
  const c = m < 10 ? '0' + m : m;

  return `${b}:${c}:00`;
}

export const durationsByClinic = (): IDurationByClinic => {
  const obj: IDurationByClinic = {};
  const data = getClinics();
  data.forEach((v: any) => {
    const duration = v.visitTimeDuration;
    const time = getMinutesBetweenDates(v.startTime, v.endTime, duration);
    const options = [];
    for (let i = 1; i <= time / duration; i++) {
      const a = i * duration;
      const output = convertMinsToHrsMins(a);
      options.push({
        label: output,
        value: output
      });
    }
    obj[v.id] = options;
  });
  return obj;
};

interface IItem {
  id: string;
  children?: IItem[];
  parentId?: string | null;
}

export function list_to_tree<T>(list: Array<T & IItem>) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    list[i].children = [];
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId && node.parentId !== node.id) {
      const children = list[map[node.parentId]].children;
      if (children) {
        children.push(node);
      }
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export const debouncer = (timeout: number) =>
  _.debounce((f) => f(), timeout, { leading: false });

// Perfomance update, prevent unnessasary rerendering
// Check rerendering by selected fields and prevent other
export function areEqual<T>(prevProps: T, nextProps: T, fields: (keyof T)[]) {
  const prev: Partial<T> = {};
  const next: Partial<T> = {};
  fields.forEach((key) => {
    prev[key] = prevProps[key];
    next[key] = nextProps[key];
  });
  return _.isEqual(prev, next);
}

export function getContrastColor(color: string) {
  const rgba = color.match(/\d+/g);
  if (!rgba) {
    return '#000000';
  }
  const brightness =
    +rgba[0] * 0.299 +
    +rgba[1] * 0.587 +
    +rgba[2] * 0.114 +
    (1 - +rgba[3]) * 255;

  return brightness > 186 ? '#000000' : '#FFFFFF';
}

export function pickTextColorBasedOnBgColorSimple(
  bgColor: string,
  lightColor: string = 'white',
  darkColor: string = 'black'
) {
  // const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  // const r = parseInt(color.substring(0, 2), 16); // hexToR
  // const g = parseInt(color.substring(2, 4), 16); // hexToG
  // const b = parseInt(color.substring(4, 6), 16); // hexToB
  // const a = r * 0.299 + g * 0.587 + b * 0.114;
  // return a >= 110 ?
  //     darkColor : lightColor;
  return darkColor;
}

export const isLettersAndNumbers = (text: string): boolean => {
  if (text.length === 0) return true;
  if (text.match(/^[0-9a-zA-Z]+$/)) return true;
  return false;
};

export const separatedTimeRange = (separator: number) => {
  const a = 60 / separator;
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  const timeArray = [],
    h = d.getHours(),
    m = d.getMinutes();
  for (let i = h; i < 24; ++i) {
    for (let j = i == h ? Math.ceil(m / separator) : 0; j < a; ++j) {
      const minutes = j * separator || '00';
      const val = (i < 10 ? '0' : '') + i + ':' + minutes;
      const d = new Date();
      d.setHours(i);
      d.setMinutes(+minutes);
      timeArray.push({
        value: val,
        label: moment(d).format('LT')
      });
    }
  }
  return timeArray;
};

export function rgbToHex(color: string) {
  color = '' + color;
  if (!color || color.indexOf('rgb') < 0) {
    return;
  }

  if (color.charAt(0) == '#') {
    return color;
  }

  var nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color);

  if (!nums) return '';

  const r = parseInt(nums[2], 10).toString(16),
    g = parseInt(nums[3], 10).toString(16),
    b = parseInt(nums[4], 10).toString(16);

  return (
    '#' +
    ((r.length == 1 ? '0' + r : r) +
      (g.length == 1 ? '0' + g : g) +
      (b.length == 1 ? '0' + b : b))
  );
}

export const rgbaToHex = (color: string): string => {
  if (/^rgb/.test(color)) {
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');

    // rgb to hex
    // eslint-disable-next-line no-bitwise
    let hex = `#${(
      (1 << 24) +
      (parseInt(rgba[0], 10) << 16) +
      (parseInt(rgba[1], 10) << 8) +
      parseInt(rgba[2], 10)
    )
      .toString(16)
      .slice(1)}`;

    // added alpha param if exists
    if (rgba[4]) {
      const alpha = Math.round(0o1 * 255);
      const hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
      hex += hexAlpha;
    }

    return hex;
  }
  return color;
};

export const compareDateTimes = (date1: string, date2: string) => {
  const dateTime1 = new Date(date1).getTime();
  const dateTime2 = new Date(date2).getTime();
  if (dateTime1 > dateTime2) {
    return 1;
  }
  if (dateTime1 < dateTime2) {
    return -1;
  }
  return 0;
};

export const compareDates = (date1: string, date2: string) => {
  const dateTime1 = new Date(moment(date1).format('MM/DD/YYYY')).getTime();
  const dateTime2 = new Date(moment(date2).format('MM/DD/YYYY')).getTime();
  if (dateTime1 > dateTime2) {
    return 1;
  }
  if (dateTime1 < dateTime2) {
    return -1;
  }
  return 0;
};

export const filterListHelper = <T>(
  list: T[],
  arr: {
    field: keyof T;
  }[],
  search: string
) => {
  if (arr.length === 0 || !search) return list;
  return list.filter((item) =>
    arr.some((filter) =>
      item[filter.field]?.['toLowerCase']().includes(search.toLowerCase())
    )
  );
};

export const getAge = (dateOfBirth: string, dateToCalculate = new Date()) => {
  const dob = new Date(dateOfBirth).getTime();
  const dateToCompare = new Date(dateToCalculate).getTime();
  const age = (dateToCompare - dob) / (365 * 24 * 60 * 60 * 1000);
  return Math.floor(age);
};

export const dateInRange = (
  start: string,
  end: string,
  current: string
): boolean => {
  const c = new Date(current).getTime();
  const s = new Date(start).getTime();
  const e = new Date(end).getTime();
  return s <= c && e >= c;
};

export function hasDuplicates(arr: any[]) {
  return new Set(arr).size !== arr.length;
}

const AsyncFunction = async function () {}.constructor;

/**
 * This method only for functions not arrow functions
 */
export const isAsync = (func: Function) => {
  return func instanceof AsyncFunction;
};

export const ReactObjectMemo = <T extends React.ComponentType<any>>(
  Component: T
) =>
  React.memo(Component, (prev, next) => {
    const changed = {};
    Object.entries(next).forEach(([key, value]) => {
      if (typeof value === 'object') {
        if (!_.isEqual(prev[key], value)) {
          changed[key] = { prev: prev[key], next: value };
        }
      } else {
        if (prev[key] !== value) {
          changed[key] = { prev: prev[key], next: value };
        }
      }
    });
    const isSame = Object.keys(changed).length === 0;
    return isSame;
  });

export const MemoChangedLogger = <T extends React.ComponentType<any>>(
  Component: T
) =>
  React.memo(Component, (prev, next) => {
    const changed = {};
    Object.entries(next).forEach(([key, value]) => {
      if (prev[key] !== value) {
        changed[key] = { prev: prev[key], next: value };
      }
    });
    const isSame = Object.keys(changed).length === 0;
    console.log({ changed, isSame });
    return isSame;
  });

export const MemoComponent: <T extends React.ComponentType<any>>(
  c: T,
  areEqual?: (
    prev: React.ComponentProps<T>,
    next: React.ComponentProps<T>
  ) => boolean
) => T = React.memo;

export const parseJwt = (
  token: string
): {
  exp: number;
  expired: boolean;
} | null => {
  try {
    const parsed = JSON.parse(atob(token.split('.')[1]));
    return { ...parsed, expired: Date.now() >= parsed.exp * 1000 };
  } catch (e) {
    return null;
  }
};

export function usePreviousProps<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const includesInStrings = (data: string[], search: string) => {
  return data.some((value) =>
    value.toLowerCase().includes(search.toLowerCase())
  );
};

export const hashParser = (hash: string) => {
  const str = hash.substring(1);
  const result = str.split('&').reduce(function (res, item) {
    const parts = item.split('=');
    res[parts[0]] = parts[1];
    return res;
  }, {});
  return result;
};

export function isFileImage(file: { type: string }) {
  return file && file['type'].split('/')[0] === 'image';
}

export const isISO8859_1 = (str: string) => {
  for (var i = 0; i < str.length; i++) {
    var code = str.charCodeAt(i);
    if (code > 255) {
      return false;
    }
  }
  return true;
};

export const createdTimeFormat = (dateTime: string) => {
  const offset = new Date(dateTime).getTimezoneOffset();
  const date_past = new Date(dateTime).getTime() - offset * 60 * 1000;
  const date_now = new Date().getTime();
  // get total seconds between the times
  var delta = Math.abs(date_now - date_past) / 1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;
  const inString = `${days ? `${days} days ` : ''}${
    hours ? `${hours} hours ` : ''
  }${minutes ? `${minutes} minutes` : ''}`;
  return {
    days,
    hours,
    minutes,
    inString
  };
};

export const daysToDate = (date: string | null | undefined) => {
  if (!date) return 0;
  const startDate = moment(formattedDate(new Date().toString()), 'MM/DD/YYYY');
  const endDate = moment(formattedDate(date), 'MM/DD/YYYY');
  const days = startDate.diff(endDate, 'days');
  if (days < 1) {
    return 0;
  }
  return days;
};

export const daysBetweenDates = (
  dateOne: string | null | undefined,
  dateTwo: string | null | undefined
) => {
  if (!dateOne || !dateTwo) return 0;
  const startDate = moment(formattedDate(dateOne), 'MM/DD/YYYY');
  const endDate = moment(formattedDate(dateTwo), 'MM/DD/YYYY');
  const days = startDate.diff(endDate, 'days');
  return Math.abs(days);
};
