import _ from 'lodash';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { CohortActionManager } from '../Reducer';

const ActionManager = {
    ADD_COHORT_MEMBERS: "ADD_COHORT_MEMBERS",
}

export interface IMemberStore {
    cohortId: string;
    pagination: any;
    members: ICohortMember[];
}

export interface ICohortMember {
    firstName: string;
    lastName: string;
    subjectId: number;
    complianceStatus: number;
}


interface IAction {
    type: string;
    payload: IMemberStore;
    id: string;
}

const CohortMemberReducer = (
    state: IMemberStore[] = [],
    action: IAction
) => {
    switch (action.type) {
        case ActionManager.ADD_COHORT_MEMBERS: {
            let newState = _.cloneDeep(state)
            let memberStore = newState.find((store: IMemberStore) => store.cohortId === action.payload.cohortId);
            if (!memberStore) {
                newState.push(action.payload);
                return newState;
            }
            if (action.payload.pagination.current > 1) {
                let isExist: boolean = isExistCohortMember(memberStore.members, action.payload.members);
                if (isExist) return newState;
                memberStore.pagination = action.payload.pagination;
                if (memberStore.members && action.payload.members)
                    memberStore.members = [...memberStore.members, ...action.payload.members];
            } else {
                newState[newState.indexOf(memberStore)] = action.payload;
            }
            return newState;

        }
        case CohortActionManager.CLEAR_COHORT: {
            let newState = _.cloneDeep(state)
            let index = newState.findIndex((o: IMemberStore) => o.cohortId === action.id);
            if (index === -1) return newState;
            newState.splice(index, 1);
            return newState;
        }
        default: {
            return state;
        }

    }
};
export default CohortMemberReducer;

export const addCohortMembers = (data: any) => {
    return {
        type: ActionManager.ADD_COHORT_MEMBERS,
        payload: data
    }
};

export const getCohortMembers = (data: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_COHORT_MEMBERS, data, { withCredentials: true });
};


// to check whether a member already exist in the store
function isExistCohortMember(stateMembers: any, actionMembers: any): boolean {
    if (actionMembers && actionMembers.length > 0 && stateMembers) {
        let action_member: any = actionMembers[0];
        let isExist: any = stateMembers.find((member: any) => {
            return member.subjectId === action_member.subjectId;
        });
        if (isExist) return true;
    }
    return false;
}
