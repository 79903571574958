import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Form = styled.div`
  position: relative;
  background: #f7f7f7;
  padding: 2rem;
  overflow: scroll;
  height: 100%;

  .disabled {
    pointer-events: none;
    filter: grayscale(100%);
    opacity: 0.6;
  }

  &.other__contact__form {
    padding: 0;
    background: #ffffff;
    height: 100%;
    border-left: 1px solid #e6e6e6;

    .table__react__tabulator {
      .tabulator {
        height: calc(100vh - 190px);
      }
    }
  }

  .control {
    position: relative;

    .icon__control {
      font-size: 6px;
      line-height: normal;
      margin-right: 2px;
      color: rgb(249, 180, 11);
      padding-right: 2px;
    }

    .error__copy {
    }

    label {
      color: #666666;
      font-weight: normal;
      font-size: 14px;
      font-family: Lato;
      display: block;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      height: 18px;
    }

    input[type='text'],
    input[type='email'] {
      box-sizing: border-box;
      height: 32px;
      background: #f7f7f7;
      font-size: 14px;
      color: #333333;
      font-family: Lato;
      font-weight: normal;
      display: flex;
      padding: 0 1rem;
      width: 100%;
      border: none;

      &.error-border {
        border: 1px solid #f9b40b;
      }
    }

    .read-only {
      box-sizing: border-box;
      height: 32px;
      align-items: center;
      font-size: 12px;
      color: #333333;
      font-family: Lato;
      font-weight: normal;
      display: flex;
      padding: 0 1rem;
      width: 100%;
    }

    .select-pi {
      * {
        font-size: 14px;
      }

      &.error-border {
        border: 1px solid #f9b40b;
      }

      &__custom__menu {
        border-top: 1px solid ${globalStyleVariables.newBrandingPrimary200};

        .select__custom__option {
          min-height: 38px;
        }
      }

      &__custom__control {
        min-height: 32px;

        &--is-focused {
          border: 1px solid #2c7fab;
        }

        > div {
          padding: 0 8px;
          font-size: 14px;
          color: #333333;
          font-family: Lato;
          font-weight: normal;
          overflow: hidden;
          white-space: nowrap;
          width: calc(100% - 30px);
        }

        .select__custom__indicators {
          width: auto;
        }
      }
    }

    .select__custom__control {
      min-height: 32px;
    }

    &__date {
      [role='grid'] {
        [data='content'] {
          height: 32px;

          [data='container'] {
            overflow: hidden;
            white-space: nowrap;
            width: calc(100% - 30px);
            font-size: 14px;
            color: #333333;
            font-family: Lato;
            font-weight: normal;
          }
        }
      }
    }

    &__checkbox {
      svg {
        rect {
          stroke: #666666;
        }
      }

      span {
        color: #000000;
        font-family: Lato;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const LoadingContainer: any = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loader {
    border: 6px solid rgb(83, 200, 46, 0.1);
    border-radius: 50%;
    border-top: 6px solid ${globalStyleVariables.newBrandingPrimary200};
    border-right: 6px solid ${globalStyleVariables.newBrandingPrimary200};
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1.2s linear infinite;
    animation: spin 1.2s linear infinite;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: ${(props: { padding?: string; marginBottom?: string }) =>
    props.padding ? props.padding : '1rem'};
  border: 1px solid #e6e6e6;
  width: 100%;
  margin-bottom: ${(props: { padding?: string; marginBottom?: string }) =>
    props.marginBottom ? props.marginBottom : '2rem'};
  flex-flow: row wrap;
`;

export const InputSearch = styled.input`
  height: 38px;
  padding: 0px 8px;
  border: none;
  width: 100%;
  background-color: #f7f7f7;
  font-family: Lato;
  font-size: 14px;
  padding: 0 28px;
`;

export const BoxTitle = styled.h5`
  text-align: left;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const Divider = styled.div`
  width: 100%;
  min-width: 100%;
  margin: 4px 0 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
`;

export const TableContainer = styled.div`
  display: flex;
  width: 310px;
  height: 160px;
`;

export const ComponentHeader = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  justify-content: flex-end;
  align-items: center;
  padding: ${(props: { padding?: string }) =>
    props.padding ? props.padding : '1rem'};
  border-bottom: 1px solid #e6e6e6;
  background: #ffffff;
`;
