import React, { FC, useCallback, useState } from 'react';
import { Resizable } from 're-resizable';
import { Row, Col } from 'antd';

import Tree, { IItemTree } from 'src/Framework/Controls/Tree';

import history from '../../../history';

import {
  handleClasses,
  PagesList,
  SIDEBAR_DATA,
  AdminRightContainerID,
  checkActive,
  getTreeData
} from './utils';

import {
  SideBarContainer,
  ColResizer,
  SidebarHeader,
  SidebarFooter,
  LogoutButton,
  SidebarBody
} from './styled';
import Icon from 'src/Framework/Controls/Icon';

import * as usersActions from 'src/App/LoginPage/store/Actions';

interface IProps {
  sidebar?: any;
}

const handleComponent = {
  right: (
    <ColResizer>
      <div />
    </ColResizer>
  )
};

export const adminSideBarId = 'main-sidebar';

const defaultSize = { width: '240px', height: '100%' };

const Component: FC<IProps> = ({}: IProps) => {
  const menuData = getTreeData(SIDEBAR_DATA);
  const [defaultActive] = useState(checkActive(menuData).key);
  const onClickActive = useCallback((value: IItemTree) => {
    const item = PagesList[value.key];
    if (item) {
      const element = document.getElementById(AdminRightContainerID);
      if (element) {
        element.focus();
      }
      history.push(item.path);
      return;
    }
    return history.push('/404');
  }, []);
  const onClickLogout = useCallback(() => {
    usersActions.logout();
  }, []);
  return (
    <SideBarContainer id={adminSideBarId} tabIndex={0} aria-label="Navigation">
      <Resizable
        minWidth={'150px'}
        maxWidth={'50vw'}
        defaultSize={defaultSize}
        handleComponent={handleComponent}
        handleClasses={handleClasses}
      >
        <SidebarHeader>medicat</SidebarHeader>
        <SidebarBody>
          <Tree
            defaultExpandedKeys={['sharingLibrary']}
            defaultActive={defaultActive}
            treeData={menuData.treeData}
            onClickActive={onClickActive}
            rightContainerId={AdminRightContainerID}
          />
        </SidebarBody>
        <SidebarFooter>
          <LogoutButton onClick={onClickLogout}>
            <Row align="middle" gutter={6}>
              <Col>
                <Icon name="logout" />
              </Col>
              <Col>
                <b>Log Out</b>
              </Col>
            </Row>
          </LogoutButton>
        </SidebarFooter>
      </Resizable>
    </SideBarContainer>
  );
};

export default React.memo(Component);
