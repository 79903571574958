import globalStyleVariables from "src/Framework/Styles/variables.module.scss";
import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 300px;
`

export const ListItem = styled.div`
    position: relative;
    cursor: pointer;
    padding: 10px !important;
    justify-content: flex-start;
    transition: all 0.25s ease-out;
    background-color: rgba(255,255,255);
    border-bottom: 1px solid ${globalStyleVariables.borderColor};
    .file-name {
        display: block;
        // width: 200px;
        overflow: hidden;
    }
    .loading-container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`