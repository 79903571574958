import store from 'src/store';

import { actionsTypes } from '../index'

export const summary = (payload: any) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload
    })
}

export const loading = (payload: boolean) => {
    store.dispatch({
        type: actionsTypes.SET_LOADING,
        payload
    })
}