import { AxiosRequestConfig } from "axios"

const ObjectChecker = (data: any) => {
    if (Array.isArray(data)) {
        data.forEach((value) => ObjectChecker(value))
    } else {
        if (data && typeof data === 'object') {
            Object.entries(data).forEach(([key, value]) => {
                if (typeof value === 'string') {
                    data[key] = value.trim();
                } else {
                    if (data && typeof value === 'object') {
                        ObjectChecker(value)
                    }
                }
            })
        }
    }
}

const DataChecker = (data: any) => {
    if (data && typeof data === 'object') {
        ObjectChecker(data)
    }
    return data
}

export default (request: AxiosRequestConfig) => {
    if (request.data) {
        try {
            const newData = DataChecker(request.data)
            request.data = newData
        } catch (e) {
            console.log("RequestMapping has some issue", e)
        }
    }
    return request
}