import React, { useImperativeHandle, useState } from 'react';
import _ from 'lodash';

import Modal from 'src/Framework/Controls/Modal';
import Editor from './Editor';

interface IProps {
  onChange: (file: File) => Promise<any>;
}

type IDocument = null | {
  id: number;
  crc?: string;
};

export interface IModalHandles {
  show(obj: { value: IDocument }): void;
  close(): void;
}

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { onChange },
  ref
) => {
  const [visible, setVisible] = useState(false);
  const [document, setDocument] = useState<IDocument>(null);

  const show = async ({ value }: any) => {
    if (value) {
      setDocument(value);
    }
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setDocument(null);
  };

  useImperativeHandle(ref, () => ({
    show: (obj: any) => show(obj),
    close
  }));

  const onChangeDocument = async (file: File) => {
    await onChange(file);
    close();
  };

  const title = 'Editor';
  return (
    <Modal
      width={700}
      title={title}
      visible={visible}
      onClose={close}
      modalProps={{
        destroyOnClose: true
      }}
      afterClose={setToDefault}
    >
      <div>
        {document && (
          <Editor
            key={`${document.id}${document.crc}`}
            id={document.id}
            crc={document.crc}
            onChange={onChangeDocument}
          />
        )}
      </div>
    </Modal>
  );
};

export default React.forwardRef(Component);
