import _ from 'lodash';
import * as actionTypes from './SummaryDetailActions';

const initialState: any = {};

interface IAction {
  type: string;
  payload: any;
  subjectId: any;
  elementId: number;
}

const SummaryDetailReducer = (state: any = { ...initialState }, action: IAction) => {
  let key = action.subjectId;
  const checkExist = () => {
    let newState = _.cloneDeep(state)
    if (!newState[key]) {
      newState[key] = _.cloneDeep(initialState)
    }
    return newState
  }

  switch (action.type) {
    case actionTypes.SET_SUMMARY_DETAIL: {
      let newState = checkExist()
      let category = action.payload.category;
      newState[key][category] = action.payload.data;
      return newState;
    }
    case actionTypes.SET_CCAP: {
      let newState = checkExist()
      newState[key]["ccaps"][action.elementId] = action.payload;
      return newState;
    }
    default:
      return state;
  }
};

export default SummaryDetailReducer;