import styled from "styled-components";
import { box_shadow, input_disabled_text_color } from "src/CommonStyles/styles";

export const MainContainer = styled.div`
  flex-direction: row;
  overflow: auto hidden;
  display: flex;

  .canReadOnly {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    *:focus {
      outline: 0;
    }

    svg > path {
      fill: white;
      stroke: ${input_disabled_text_color};
    }

    svg > path:first-of-type {
      fill: white;
      stroke: white;
    }

    svg > circle {
      stroke: white;
      fill: white;
    }

    svg > rect {
      stroke: ${input_disabled_text_color};
      fill: white;
    }
  }
`;

export const Left = styled.div`
  min-height: 10rem;
  width: 31%;
  position: relative;
  flex-direction: column;
  display: flex;
`;

export const Right = styled.div`
  position: relative;
  width: 69%;
  display: flex;
  flex-direction: column;
  background-color: ${box_shadow};
  // overflow: auto;
`;

export const SelectedForm = styled.div`
position: relative;
display: flex;
flex:1;
flex-direction: column;
  background-color: white;
  border: 16px solid #E6E6E6;
`;