import _ from 'lodash';
import { IStateReducer, IAction } from './types';
import { defaultData as defaultFilterData } from '../Pages/CohortCreation/Audience/FilterModal';
import { defaultCompliancePatientResult, defaultHeaderStatistics } from '../utils';

const prefix = 'ACTIVITY_COMPLIANCE_';

export const actionsTypes = {
    SET_MESSAGES_PROVIDERS: `${prefix}SET_MESSAGES_PROVIDERS`,
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_SORT: `${prefix}SET_SORT`,
    SET_COMPLIANCE_PATIENTS: `${prefix}SET_COMPLIANCE_PATIENTS`,
    SET_COMPLIANCE_MESSAGES: `${prefix}SET_COMPLIANCE_MESSAGES`,
    SET_HEADER_STATISTICS: `${prefix}SET_HEADER_STATISTICS`,
    SET_SEARCH_MESSAGE: `${prefix}SET_SEARCH_MESSAGE`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

export const defaultData: IStateReducer = {
    filter: defaultFilterData(),
    messagesProviderIds: [],
    sort: {
        sortBy: 'patientId',
        sortType: 'asc'
    },
    searchMessage: '',
    compliancePatients: defaultCompliancePatientResult(),
    complianceMessages: [],
    headerStatistics: defaultHeaderStatistics()
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const actions: IActions = {
        [actionsTypes.SET_SEARCH_MESSAGE]: () => {
            return {
                ...state,
                searchMessage: action.payload
            }
        },
        [actionsTypes.SET_HEADER_STATISTICS]: () => {
            return {
                ...state,
                headerStatistics: action.payload
            }
        },
        [actionsTypes.SET_COMPLIANCE_MESSAGES]: () => {
            return {
                ...state,
                complianceMessages: action.payload
            }
        },
        [actionsTypes.SET_COMPLIANCE_PATIENTS]: () => {
            return {
                ...state,
                compliancePatients: action.payload
            }
        },
        [actionsTypes.SET_MESSAGES_PROVIDERS]: () => {
            return {
                ...state,
                messagesProviderIds: action.payload
            }
        },
        [actionsTypes.SET_SORT]: () => {
            return {
                ...state,
                sort: action.payload
            };
        },
        [actionsTypes.SET_FILTER]: () => {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                }
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
