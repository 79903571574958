export enum PrefEnumKeys {
  ActivityComplianceFilter = 'ActivityComplianceFilter',
  MessageProviderFilter = 'MessageProviderFilter',
  ActivityTrainingFilter = 'ActivityTrainingFilter',
  HIPAAAlert = 'HIPAAAlert',
  PatientChartMenuSettings = 'PatientChartMenuSettings',
  DefaultMessageFolder = 'DefaultMessageFolder',
  ApptReasonDisplay = 'ApptReasonDisplay',
  CalendarOpacityLevel = 'CalendarOpacityLevel',
  ActivityBlasterDashboardSettings = 'ActivityBlasterDashboardSettings',
  SystemShortcuts = 'systemShortcuts',
  userGeneralSettings = 'userGeneralSettings',
  TodosSettings = 'TodosSettings',
  ActivityReferralsSettings = 'activityReferralsSettings',
  patientFieldOrder = 'patientFieldOrder',
  currentTabs = 'currentTabs',
  activityTasksFilters = 'ActivityTasksFilters',
  activityClientListFilters = 'activityClientListFilters',
  apptDischargeLockedNote = 'apptDischargeLockedNote',
  orderManagerSettings = 'orderManagerSettings',
  noteSelectedFormsInSection = 'noteSelectedFormsInSection',
  ActivityDocumentManager = 'ActivityDocumentManager'
}

export const PrefEnum = {
  [PrefEnumKeys.noteSelectedFormsInSection]: {
    name: 'NoteSelectedFormsInSection',
    id: 0
  },
  [PrefEnumKeys.ActivityDocumentManager]: {
    name: 'ActivityDocumentManager',
    id: 3058
  },
  [PrefEnumKeys.orderManagerSettings]: {
    name: 'OrderManagerSettings',
    id: 3057
  },
  [PrefEnumKeys.patientFieldOrder]: {
    name: 'PatientFieldOrder',
    id: 512
  },
  [PrefEnumKeys.ActivityReferralsSettings]: {
    name: 'ActivityReferralsSettings',
    id: 3052
  },
  [PrefEnumKeys.TodosSettings]: {
    name: 'TodosSettings',
    id: 3051
  },
  [PrefEnumKeys.userGeneralSettings]: {
    name: 'UserGeneralSettings',
    id: 3050
  },
  [PrefEnumKeys.ActivityBlasterDashboardSettings]: {
    name: 'ActivityBlasterDashboardSettings',
    id: 3048
  },
  [PrefEnumKeys.CalendarOpacityLevel]: {
    name: 'CalendarOpacityLevel',
    id: 3047
  },
  [PrefEnumKeys.ActivityComplianceFilter]: {
    name: 'ActivityComplianceFilter',
    id: 3042
  },
  [PrefEnumKeys.MessageProviderFilter]: {
    name: 'MessageProviderFilter',
    id: 3019
  },
  [PrefEnumKeys.ActivityTrainingFilter]: {
    name: 'ActivityTrainingFilter',
    id: 3043
  },
  [PrefEnumKeys.HIPAAAlert]: {
    name: 'HIPAAAlert',
    id: 508
  },
  [PrefEnumKeys.PatientChartMenuSettings]: {
    name: 'PatientChartMenuSettings',
    id: 3044
  },
  [PrefEnumKeys.DefaultMessageFolder]: {
    name: 'DefaultMessageFolder',
    id: 3045
  },
  [PrefEnumKeys.ApptReasonDisplay]: {
    name: 'ApptReasonDisplay',
    id: 3046
  },
  [PrefEnumKeys.SystemShortcuts]: {
    name: 'systemShortcuts',
    id: 3049
  },
  [PrefEnumKeys.currentTabs]: {
    name: 'currentTabs',
    id: 3053
  },
  [PrefEnumKeys.activityTasksFilters]: {
    name: 'ActivityTasksFilters',
    id: 3054
  },
  [PrefEnumKeys.apptDischargeLockedNote]: {
    name: 'ApptDischargeLockedNote',
    id: 3055
  },
  [PrefEnumKeys.activityClientListFilters]: {
    name: 'ActivityClientListFilters',
    id: 3056
  }
} as const;
