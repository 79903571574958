import React, { useEffect, useState, useRef, useCallback } from 'react';
import _ from 'lodash';
import { useRouteMatch } from 'react-router-dom';
import { v4 } from 'uuid';

import Loader from '../Loader';
import Header from './Header';

import { IProps, ISort } from './types';

import {
  Container,
  ListInnerContainer,
  ListContainer,
  AbsoluteLoading
} from './styled';
import { Cache } from 'src/Framework/CatchStateData';

import RenderMain from './RenderMain';
import { usePreviousProps } from 'src/Framework/util/helps';
import Pagination from './Pagination';

const scrollSync = (headerId: any, bodyId: any) => {
  const element = document.getElementById(bodyId);
  const header = document.getElementById(headerId);
  if (element) {
    element.addEventListener('scroll', (e: any) => {
      if (header?.scrollLeft !== undefined) {
        header.scrollLeft = e.target.scrollLeft;
      }
    });
  }
  if(header) {
    header.addEventListener('scroll', (e: any) => {
      if (element?.scrollLeft !== undefined) {
        element.scrollLeft = e.target.scrollLeft;
      }
    });
  }
};

export const defaultInfinityProps = {
  loader: <Loader />,
  loadMore: () => {}
};

export const minColWidth = 92;

export const defaultPagination = {
  pageNumber: 1,
  pageLength: 20
};

const Component = <T, A>(props: IProps<T, A>) => {
  const {
    columns,
    data,
    sort,
    onChangeSort,
    horizontalItemPadding,
    verticalItemPadding,
    sortArrowIcon,
    headerBackground,
    loading,
    hideHeader,
    fontColor,
    dragAndDropProps,
    absoluteContainer,
    dragMarginLeft,
    bodyContainerId,
    containerStyleProps,
    enablePagination,
    dataSource,
    pushHightlightColor,
    innerPagination,
    disableScrollSync,
    enableBorder,
    onChangeInnerPagination,
    enableRelativePosition
  } = props;

  const prevProps = usePreviousProps({ data, dataSource });
  const [pagination, setPagination] = useState(_.cloneDeep(defaultPagination));
  const { pageNumber, pageLength } = pagination;
  const match = useRouteMatch();
  const [uniqueIdHeader] = useState(() => _.uniqueId('table_header_'));
  const [uniqueIdTable] = useState(() =>
    bodyContainerId ? bodyContainerId : _.uniqueId('table_body_')
  );
  const captionID = useRef(v4());
  const bodyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (disableScrollSync) {
      return;
    }
    if (bodyRef.current) {
      Cache.setScrollPositionByRef(
        match.url,
        uniqueIdTable || 'tableScroll',
        bodyRef.current
      );
    }
    scrollSync(uniqueIdHeader, uniqueIdTable);
  }, []);

  useEffect(() => {
    if (innerPagination) {
      setPagination(innerPagination);
    }
  }, [innerPagination]);

  useEffect(() => {
    if (prevProps) {
      if (prevProps.data?.length !== data?.length) {
        setPagination(_.cloneDeep(defaultPagination));
      }
      if (prevProps.dataSource?.length !== dataSource?.length) {
        setPagination(_.cloneDeep(defaultPagination));
      }
    }
  }, [data]);

  const onSort = useCallback(
    (sortBy: string) => {
      if (data?.length === 0 || dataSource?.length === 0) return;
      if (!sort || !onChangeSort) return;
      let sortType: ISort['sortType'] = null;
      if (sortBy === sort.sortBy) {
        if (!sort.sortType) {
          sortType = 'asc';
        } else {
          if (sort.sortType === 'asc') {
            sortType = 'desc';
          }
        }
      } else {
        sortType = 'asc';
      }
      onChangeSort({
        sortBy: !sortType ? null : sortBy,
        sortType
      });
    },
    [onChangeSort, sort, data, dataSource]
  );
  const onScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      Cache.onScroll(e, match.url, bodyContainerId || 'tableScroll');
    },
    [match]
  );
  const hideSortArrows = data?.length === 0 || dataSource?.length === 0;
  const { absoluteLoading, ...renderMainProps } = props;
  return (
    <Container
      style={containerStyleProps}
      pushHightlightColor={pushHightlightColor}
      role="table"
      aria-labelledby={captionID.current}
      enableBorder={enableBorder}
    >
      <div role="caption" className={'visually-hidden'} id={captionID.current}>
        {props.tableCaption || 'Data table'}
      </div>
      {absoluteLoading && (
        <AbsoluteLoading>
          <Loader />
        </AbsoluteLoading>
      )}
      {!hideHeader && (
        <Header
          sortArrowIcon={sortArrowIcon}
          onSort={onSort}
          sort={hideSortArrows ? undefined : sort}
          fontColor={fontColor}
          verticalItemPadding={verticalItemPadding}
          horizontalItemPadding={horizontalItemPadding}
          uniqueIdHeader={uniqueIdHeader}
          headerBackground={headerBackground}
          columns={columns}
          dragMarginLeft={dragMarginLeft}
          dragAndDropProps={dragAndDropProps}
          uniqueIdTable={uniqueIdTable}
        />
      )}
      {loading ? (
        <Loader />
      ) : absoluteContainer ? (
        <ListContainer key={`pagination-${pageNumber}`}>
          <ListInnerContainer
            ref={bodyRef}
            id={uniqueIdTable}
            onScroll={onScroll}
          >
            <RenderMain
              {...renderMainProps}
              pageLength={pageLength}
              pageNumber={pageNumber}
              uniqueIdHeader={uniqueIdHeader}
            />
          </ListInnerContainer>
        </ListContainer>
      ) : enableRelativePosition ? (
        <ListInnerContainer
          style={{ position: 'relative' }}
          ref={bodyRef}
          id={uniqueIdTable}
          onScroll={onScroll}
        >
          <RenderMain
            {...renderMainProps}
            pageLength={pageLength}
            pageNumber={pageNumber}
            uniqueIdHeader={uniqueIdHeader}
          />
        </ListInnerContainer>
      ) : (
        <RenderMain
          {...renderMainProps}
          pageLength={pageLength}
          pageNumber={pageNumber}
          uniqueIdHeader={uniqueIdHeader}
        />
      )}
      {props.paginationControls && (
        <Pagination pagination={props.paginationControls} />
      )}
      {enablePagination && dataSource?.length !== 0 && (
        <Pagination
          pagination={{
            pageLength: pagination.pageLength,
            startIndex: (pagination.pageNumber - 1) * pagination.pageLength,
            total: dataSource?.length || 0,
            onChangePage: (pageNumber) => {
              const page = pageNumber + 1;
              setPagination((p) => ({
                ...p,
                pageNumber: page
              }));
              if (onChangeInnerPagination) {
                onChangeInnerPagination(page);
              }
            }
          }}
        />
      )}
    </Container>
  );
};

Component.defaultProps = {
  horizontalItemPadding: 24,
  verticalItemPadding: 12,
  sortArrowIcon: require('src/Framework/Common/Svg/sort-icon.svg').default,
  headerBackground: '#F7F7F7',
  fontColor: 'black',
  loading: false,
  infinityProps: {},
  infinityContainerProps: {}
};

const typedMemo: <T extends React.ComponentType<any>>(
  c: T,
  areEqual?: (
    prev: React.ComponentProps<T>,
    next: React.ComponentProps<T>
  ) => boolean
) => T = React.memo;

export default typedMemo(Component);
