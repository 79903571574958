import React, { FC } from 'react';

import HeaderV1 from 'src/Activities/Counseling/NewNotes/Preview/DataGenerator/Previews/Header/V1';
import { IHeaderV1Preview } from 'src/Activities/Counseling/NewNotes/Preview/DataGenerator/Previews/Header/V1/types';

interface IProps {
  data?: IHeaderV1Preview;
}

const Component: FC<IProps> = ({ data }: IProps) => {
  return (
    <>
      {data ? (
        <div style={{ marginBottom: 20 }}>
          <HeaderV1 value={data} />
        </div>
      ) : null}
    </>
  );
};

export default React.memo(Component);
