import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const ClinicPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.14);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 350;
`;

export const ClinicBoxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 25rem;
  height: 100%;
`;

export const ClinicBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-top: 0.4rem solid ${globalStyleVariables.newBrandingPrimary200};
  box-shadow: 0px 4px 12px rgba(19, 65, 98, 0.06);
  margin-top: 1rem;
  padding: 5rem;
  flex-direction: column;
`;

export const ClinicTitle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 3rem;

  .clinic-box-title {
    font-family: Encode Sans Expanded;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    width: 30rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
`;

export const ButtonContainer = styled.div`
  .ant-btn {
    border: 1px solid ${globalStyleVariables.newBrandingPrimary200};
    color: ${globalStyleVariables.newBrandingPrimary200};

    :hover {
      background: ${globalStyleVariables.newBrandingPrimary200};
      color: #ffffff;
    }

    :active {
      background: ${globalStyleVariables.newBrandingPrimary200};
      border: 1px solid ${globalStyleVariables.newBrandingPrimary200};
      color: #ffffff;
    }
  }

  .confirm-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    border-radius: 5rem;
    height: 4rem;
    width: 40rem;
    margin-top: 2rem;
    cursor: pointer;
  }

  .disable-login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    //background: '';
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    border-radius: 5rem;
    height: 4rem;
    width: 40rem;
    margin-top: 2rem;
    cursor: pointer;
  }

  .cancel-button {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #666666;
    border: none;
    margin-top: 2rem;
    box-shadow: none;
  }
`;
