import _ from 'lodash'
import { IAction, IStateReducer, IPatientStore } from './types'

const prefix = 'PATIENTS_NOTES_OF_CONCERN_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_SORT: `${prefix}SET_SORT`,
    SET_FILTER: `${prefix}SET_FILTER`,
}

export const initialData: IPatientStore = {
    summary: {},
    filter: {
        clinics: []
    },
    sort: {
        sortBy: 'dts',
        sortType: 'desc'
    }
}

const initialState: IStateReducer = {
    patients: {},
};

const Reducer = (state: IStateReducer = { ..._.cloneDeep(initialState) }, action: IAction): IStateReducer => {
    if (!state.patients[action.patientId]) {
        state.patients[action.patientId] = _.cloneDeep(initialData)
    }
    switch (action.type) {
        case actionsTypes.SET_FILTER:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        filter: {
                            ...state.patients[action.patientId]?.filter,
                            ...action.payload
                        }
                    }
                }
            }
        case actionsTypes.SET_SORT:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        sort: action.payload
                    }
                }
            }
        case actionsTypes.SET:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        summary: action.payload
                    }
                }
            }
        default:
            return state;
    }
};

export default Reducer;
