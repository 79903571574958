import _ from 'lodash';

import { IAction, IStateReducer } from './types';

const prefix = 'WAITING_LIST_SETTINGS_';

export const actionsTypes = {
  SET: `${prefix}SET`,
  SET_LOADING: `${prefix}SET_LOADING`
};

const initialState: IStateReducer = {
  summary: [],
  loading: false
};

const Reducer = (
  state: IStateReducer = _.cloneDeep(initialState),
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET:
      return { ...state, summary: action.payload };
    case actionsTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default Reducer;
