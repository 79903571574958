import React, { FC } from 'react';

import ButtonIcon, {
  ICONS_LIST,
  IIcon
} from 'src/Framework/Controls/ButtonIcon';
import { isContentTypeSupport } from 'src/Framework/Controls/FileViewer/utils';
import { AppUrl } from 'src/Framework/Common/AppUrl';
import store from 'src/store';

import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';
import { ActivityType } from 'src/App/ActivityPanel/Models/Activity';
import { patientDisplayNameById } from 'src/Framework/util/format';
import { providerDisplayName } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';

interface IProps {
  item: any;
  buttonProps?: Partial<IIcon>;
  patientId?: string | number;
  traineeId?: string | number;
}

const FileViewerButton: FC<IProps> = ({
  item,
  buttonProps,
  patientId,
  traineeId
}: IProps) => {
  const onClickPreview = () => {
    let nameToShow = '';
    let ownerLink = '';
    if (patientId) {
      ownerLink = '?patientId=' + patientId;
      nameToShow = patientDisplayNameById(patientId);
    } else if (traineeId) {
      ownerLink = '?traineeId=' + traineeId;
      nameToShow = providerDisplayName(traineeId);
    }

    loadActivityTab(
      `${AppUrl.FILE_VIEWER}/${item.id}${ownerLink}`,
      store.dispatch,
      {
        activityType: ActivityType.FileViewer
      },
      {
        subtitle: nameToShow
      }
    );
  };
  const { contentType } = item;
  const previewSupport = isContentTypeSupport(contentType);

  return (
    <ButtonIcon
      isButton={true}
      label={
        !previewSupport
          ? 'Preview not supported for this file type'
          : 'Preview in new tab'
      }
      {...buttonProps}
      name={ICONS_LIST.newTab}
      tooltip={
        !previewSupport
          ? 'Preview not supported for this file type'
          : 'Preview in new tab'
      }
      disabled={!previewSupport}
      onClick={() => onClickPreview()}
      changeColorOnHover={true}
    />
  );
};

export default React.memo(FileViewerButton);
