import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

import * as notesActions from '../../NoteList/Store/PatientNoteListActions'
import { NoteFullDto } from "src/Activities/Counseling/NewNotes/Note/store/types";

export const ADD_NOTE_OVERVIEW = "ADD_NOTE_OVERVIEW";
export const ADD_DOCUMENT_FILE = "ADD_DOCUMENT_FILE";
export const SET_ATTACH_TO_NOTE = "SET_ATTACH_TO_NOTE";
export const SET_NOTE_TYPE = "SET_NOTE_TYPE";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const UPDATE_ASSESSMENT = "UPDATE_ASSESSMENT";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const UPDATE_MOFFSECTIONS = "UPDATE_MOFFSECTIONS";
export const UPDATE_FORM_MOFF = "UPDATE_FORM_MOFF";
export const UPDATE_CURRENT_PROVIDER = "UPDATE_CURRENT_PROVIDER";


export const addNoteOverview = (id: any, data: any) => {
  return {
    type: ADD_NOTE_OVERVIEW,
    payload: data,
    id: id
  };
};

export const updateAssessment = (id: any, data: any) => {
  return {
    type: UPDATE_ASSESSMENT,
    payload: data,
    id: id
  };
};
export const updatePlan = (id: any, data: any) => {
  return {
    type: UPDATE_PLAN,
    payload: data,
    id: id
  };
};

export const updateMoffSections = (id: any, data: any) => {
  return {
    type: UPDATE_MOFFSECTIONS,
    payload: data,
    id: id
  };
};
export const updateFormsMoffContent = (id: any, data: any) => {
  return {
    type: UPDATE_FORM_MOFF,
    payload: data,
    id: id
  };
};

export const updateProperty = (id: any, data: any) => {
  return {
    type: UPDATE_PROPERTY,
    payload: data,
    id: id
  };
};

export const setAttachToNote = (id: any, data: any) => {
  return {
    type: SET_ATTACH_TO_NOTE,
    payload: data,
    id: id
  }
};

export const updateCurrentProvider = (id: any, data: any) => {
  return {
    type: UPDATE_CURRENT_PROVIDER,
    payload: data,
    id: id
  }
};

//Deprecate
export const sendNote = (noteId: any, providerId: any) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.SEND_NOTE,
    {
      "noteId": noteId,
      "providerId": providerId
    },
    { withCredentials: true }
  );
}

export const getNotesV2IncludeLegacy = async (id: number | string, patientId: number | string): Promise<NoteFullDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.GET_NOTESV2,
      {
        withCredentials: true, params: {
          id,
          patientId,
          format: 'full',
          includeLegacyNotes: true
        }
      }
    );
    if (response.data.success) {
      const data: any = Object.values(response.data.result.summary)[0]
      return data
    }
    return
  } catch (e) {
    console.log({ e })
    return
  }
}

export const getNotesV2 = async (id: number | string, patientId: number | string): Promise<NoteFullDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.GET_NOTESV2,
      {
        withCredentials: true, params: {
          id,
          patientId,
          format: 'full',
          includeLegacyNotes: true
        }
      }
    );
    if (response.data.success) {
      const data: any = Object.values(response.data.result.summary)[0]
      return data
    }
    return
  } catch (e) {
    console.log({ e })
    return
  }
}

interface ICreateNotesV2 {
  noteTypeV2Id: number,
  patientId: number,
  appointmentId: string | undefined,
  renderingProviderId: number,
  billingProviderId: number
  clinicId: number
}

export const createNotesV2 = async (data: ICreateNotesV2) => {
  try {
    const { noteTypeV2Id, patientId, appointmentId, clinicId, billingProviderId, renderingProviderId } = data
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.GET_NOTESV2,
      {
        billingProviderId,
        noteTypeV2Id,
        patientId,
        clinicId,
        appointmentId,
        renderingProviderId
      },
      { withCredentials: true, interceptError: true }
    );
    if (response.data.success) {
      const data = Object.values(response.data.result.summary)[0]
      notesActions.addNewNote(data, patientId)
      return data
    }
    return
  } catch (e) {
    console.log({ e })
    return
  }
}
//Deprecate
export const getNote = (noteId: number | string) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_NOTES,
    {
      "id": noteId,
    },
    { withCredentials: true }
  );
}


export const getAutocompleteForPlan = (txnClass: any, searchText: string, txnSet: number, clinicId?: number) => {
  if (txnSet === 0) {
    return APIHandler.AxiosInstance.get(
      ApiUrl.TXNCODES,
      {
        withCredentials: true,
        params: {
          "typeTxnClasses": [txnClass],
          "searchString": searchText,
          "isActive": true,
          "count": 40,
          clinicId
        },
      }
    );
  } else {
    return APIHandler.AxiosInstance.get(
      ApiUrl.TXNCODES,
      {
        withCredentials: true,
        params: {
          "typeTxnClasses": [txnClass],
          "txnSetId": txnSet,
          "isActive": true,
          "searchString": searchText,
          "count": 40,
          clinicId
        },
      }
    );
  }

}

export const getAutocompletePlan = (selectedService: any, searchText: string) => {
  return APIHandler.AxiosInstance.get(
    ApiUrl.TXNCODES,
    {
      withCredentials: true,
      params: {
        "typeTxnClasses": [selectedService],
        "isActive": true,
        "searchString": searchText,
        "count": 40
      },
    }
  );
}

export const signNoteHighestLevelMark = async (noteId: number, providerUserIds: number[]) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.GET_NOTESV2}/${noteId}/sign`,
      {
        noteId,
        signatureType: 3,
        providerUserIds
      },
      {
        withCredentials: true,
        interceptError: true,
        interceptSuccess: true,
        successInterceptorMessage: "The note has been locked"
      }
    );
    if (response.data.success) {
      const data: any = Object.values(response.data.result.summary)[0];
      return data
    }
  } catch (e) {
    return
  }
}

export const signNote = (noteId: number, signatureType: number) => {
  return APIHandler.AxiosInstance.post(
    `${ApiUrl.GET_NOTESV2}/${noteId}/sign`,
    {
      noteId,
      signatureType
    },
    {
      withCredentials: true,
      interceptError: true,
      interceptSuccess: true,
      successInterceptorMessage: "The note has been " + (signatureType === 3 ? "locked" : "signed")
    }
  );
}



export const updateNoteClinicBilling = (noteId: number, clinicId: number, billingProviderId: number) => {
  return APIHandler.AxiosInstance.patch(
    `${ApiUrl.NOTES}/${noteId}`,
    {
      clinicId,
      billingProviderId,
      id: noteId
    },
    { withCredentials: true }
  )
}

export const deleteNote = (noteId: number) => {
  return APIHandler.AxiosInstance.patch(
    `${ApiUrl.NOTES}/${noteId}`,
    {
      invalid: true
    },
    { withCredentials: true }
  )
}

export const getNotePreview = (noteId: number, typeNote?: string) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_NOTE_PREVIEW,
    {
      noteId,
      typeNote
    },
    { withCredentials: true }
  )
}