import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div``;

export const ContainerModal = styled.div`
  position: relative;
  padding: 20px;
`;


export const NewContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${globalStyleVariables.newBrandingPrimary200};
`;
