import styled from "styled-components";

interface IMessage {
  height: number
  exist: boolean
}

export const Message = styled.div<IMessage>`
  font-size: 12px;
  color: red;
  margin: ${({ exist }) => exist && `5px 0 0 0`};
  transition: all 0.25s ease;
  height: 0px;
  opacity: 0;
  ${({ height }) => height && `
    opacity: 1;
    height: ${height}px;
  `}
`;