import { ActivityType } from "src/App/ActivityPanel/Models/Activity";
import { loadActivityTab } from "src/App/ActivityPanel/Store/ActivityActions";
import { AppUrl } from "src/Framework/Common/AppUrl"
import store from "src/store";

import { ISimpleBuildBlock } from "./store/types";

export const nameMaxLength = 256

export const pageCount = 30

export const buildingBlockPageUrl = (id: number) => {
    return `${AppUrl.BUILDING_BLOCK}/${id}/edit`
}

export const openBuildingBlockTab = (value: ISimpleBuildBlock) => {
    loadActivityTab(
        buildingBlockPageUrl(value.id),
        store.dispatch,
        {
            activityType: ActivityType.BuildingBlock
        },
        {
            title: 'Admin Building block',
            subtitle: value.name
        }
    );
}