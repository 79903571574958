import React, { FC, useEffect, useState } from 'react';
import FormContainer from 'src/App/FormBuilder/FormContainer';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import { IFullBuildingForm } from '../../Lists/Forms/store/types';

import * as buildingFormsActions from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/Actions';
import { ISimpleBuildBlock } from '../../Lists/BuildingBlocks/store/types';
import _ from 'lodash';

interface IProps {
  data: IFullBuildingForm;
  buildingBlockList: Record<string, ISimpleBuildBlock>;
}

const Component: FC<IProps> = ({ data, buildingBlockList }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [displaySchema, setDisplaySchema] = useState<any>(null);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const version = _.cloneDeep(data.versions[0]);
      if (version.designSchema) {
        version.designSchema.rows.forEach((row) => {
          row.columns.forEach((col) => {
            if (col.buildingBlockId) {
              const bbData = buildingBlockList[col.buildingBlockId];
              if (bbData) {
                col.buildingBlockVersion = bbData.latestPublishedVersion;
              }
            }
          });
        });
      }
      const res = await buildingFormsActions.preview(version);
      if (res) {
        setDisplaySchema(res.displaySchema);
      }
      setLoading(false);
    };
    init();
  }, [data]);
  return (
    <>
      {loading && <AbsoluteLoader />}
      <FormContainer
        schema={displaySchema || {}}
        submission={{}}
        onChange={() => {}}
        isAbsoluteContainer={true}
      />
    </>
  );
};

export default Component;
