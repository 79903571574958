import { ISort } from "src/Framework/Controls/Table/types";
import store from "src/store";
import { actionsTypes } from "..";
import { IProviderConsent } from "../types";

export const setSummary = (payload: Record<string, IProviderConsent>, providerId: string | number) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload,
        providerId
    })
}

export const onCreateUpdateItem = (payload: any, providerId: string | number) => {
    const data = { ...(store.getState().trainee.dashboard.consents.providers[providerId]?.summary || {}) }
    if (data[payload.id]) {
        data[payload.id] = { ...data[payload.id], ...payload }
    } else {
        data[payload.id] = payload
    }
    setSummary(data, providerId)
}


export const changeSort = ({
    providerId,
    payload
}: {
    providerId: string;
    payload: ISort;
}) => {
    store.dispatch({
        type: actionsTypes.SET_SORT,
        payload,
        providerId
    });
};
