import styled from "styled-components";
import {
    gray_background_color, blue_color, gray_font_color
} from 'src/CommonStyles/styles';
import {DeleteCircle} from "../../Common/Svg/attachments/DeleteCircle";

export const Attachment: any = styled.div`
    cursor: pointer;
    position: relative;
    margin: 0 8px 5px 0;
    display: flex;
    flex-direction: row;
    .label{
        font-size: 12px;
        margin-top: 6px;
        margin-left: 8px;
    }
`;

export const SquaredContainer = styled.div`
    background-color: ${gray_background_color};
    height: 28px;
    width: 36px;
    left: 0px;
    top: 0px;
    border-radius: 2px;
    padding: 4px 8px 4px 8px;
`;

export const Attachments = styled.div` 
    margin: 8px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const DeleteIcon = styled(DeleteCircle)`
    cursor: pointer;
    position: absolute;
    right: -6px;
    top: -8px;
    svg>path {          
        stroke: ${gray_font_color};
    }
    &:hover svg>path {         
        stroke: ${blue_color};
    }
`