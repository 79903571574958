import _ from 'lodash'

import { IAction, IStateReducer } from './types'

const prefix = 'MY_CLIENTS_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_LOADING: `${prefix}SET_LOADING`,
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_WATCHLIST: `${prefix}SET_WATCHLIST`,
    SET_SORT: `${prefix}SET_SORT`
}

const initialState: IStateReducer = {
    summary: [],
    sort: {
        sortBy: 'lastName',
        sortType: 'asc'
    },
    loading: false,
    filter: {
        providerId: null,
        isOnWatchlist: null,
        search: '',
        providerType: null
    },
    watchList: {}
};

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET_SORT:
            return {
                ...state,
                sort: {
                    ...state.sort,
                    ...action.payload
                }
            }
        case actionsTypes.SET_WATCHLIST:
            return {
                ...state,
                watchList: action.payload
            }
        case actionsTypes.SET_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload
                }
            }
        case actionsTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case actionsTypes.SET:
            return {
                ...state,
                summary: action.payload
            }
        default:
            return state;
    }
};

export default Reducer;
