import { IStateReducer, IAction } from './types';

const prefix = 'RISK_TYPES_';

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_LOADING: `${prefix}SET_LOADING`,
    CHANGE_SELECTED_GROUP: `${prefix}CHANGE_SELECTED_GROUP`,
};

const initialState: IStateReducer = {
    summary: [],
    loading: true,
    riskSelectedGroup: 'academic'
};

const Reducer = (
    state: IStateReducer = { ...initialState },
    action: IAction
): IStateReducer => {
    switch (action.type) {
        case actionsTypes.CHANGE_SELECTED_GROUP:
            return {
                ...state,
                riskSelectedGroup: action.payload
            };
        case actionsTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case actionsTypes.SET:
            return {
                ...state,
                summary: action.payload
            };
        default:
            return state;
    }
};

export default Reducer;
