import React from 'react';

import CombinedSelector, {
  IOutProps as ICombinedProps
} from 'src/Framework/Controls/CombinedSelector';
import { MemoComponent } from 'src/Framework/util/helps';

import {
  IGroupTypes,
  MergedInterfaces
} from 'src/App/Admin/Pages/GroupTypes/store/types';
import { useGroupType } from 'src/App/Admin/Pages/GroupTypes/store/Hooks/useGroupType';

import { Container } from './styled';

type Key = keyof IGroupTypes;

type IProps<T extends Key> = ICombinedProps<IGroupTypes[T]> & {
  groupId: T;
  labelField?: keyof IGroupTypes[T];
  valueField?: keyof IGroupTypes[T];
};

const GroupTypesSelector = <T extends Key>(props: IProps<T>) => {
  const { groupId, labelField, valueField, ...otherProps } = props;
  const data = useGroupType(groupId);
  return (
    <Container>
      <CombinedSelector
        {...otherProps}
        loading={data.loading}
        data={data.allGenericTypes.map((v: any) => {
          const value = v as any;
          let isActive = true;
          if ('isActive' in value) {
            isActive = value.isActive;
          }
          if ('inactive' in value) {
            isActive = value.inactive === false;
          }
          return {
            label: value[(labelField as keyof MergedInterfaces) || 'name'],
            value: value[(valueField as keyof MergedInterfaces) || 'id'],
            isActive: isActive,
            dto: v as IGroupTypes[T]
          };
        })}
      />
    </Container>
  );
};

export default MemoComponent(GroupTypesSelector);

type IPropsByCode<T extends Key> = ICombinedProps<IGroupTypes[T], string> & {
  groupId: T;
  labelField?: keyof IGroupTypes[T];
  valueField?: keyof IGroupTypes[T];
};

const GroupTypesSelectorByCodeComponent = <T extends Key>(
  props: IPropsByCode<T>
) => {
  const { groupId, labelField, valueField, ...otherProps } = props;
  const data = useGroupType(groupId);
  return (
    <Container>
      <CombinedSelector
        {...otherProps}
        loading={data.loading}
        data={data.allGenericTypes.map((v: any) => {
          const value = v as MergedInterfaces;
          return {
            label: value[(labelField as keyof MergedInterfaces) || 'name'],
            value: value[(valueField as keyof MergedInterfaces) || 'code'],
            isActive: 'isActive' in value ? value.isActive : true,
            dto: v as IGroupTypes[T]
          };
        })}
      />
    </Container>
  );
};

export const GroupTypesSelectorByCode = MemoComponent(
  GroupTypesSelectorByCodeComponent
);
