import styled from "styled-components";

export const MenuItem: any = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
`;

export const LabelName: any = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
`;

export const TitleNavigationItem: any = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
`;


export const SectionNames: any = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
`;

export const MenuTitleLink: any = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
`;

export const ContentText: any = styled.span`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
`;

export const MandatoryField: any = styled.span`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
`;

export const HeaderSection = styled.div`
    font-family: Encode Sans Expanded;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 20px;
`;

export const TitleSection: any = styled.div`
    font-family: Encode Sans Expanded;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
`;

export const NavHeader = styled.span`
    font-family: Encode Sans Expanded;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
`;

export const Name = styled.div`
    font-family: Encode Sans Expanded;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
`;

export const ModalTitle = styled.div`
    font-family: Encode Sans Expanded;
    font-weight: 800;
    line-height: 40px;
    font-size: 18px;
`;