
import _ from "lodash";

import { IStateReducer, IAction, INoteDetails } from './types'

const prefix = `NEW_NOTE_`

export const actionsTypes = {
    SET_CONTROL_VALIDATION_STATUS: `${prefix}SET_CONTROL_VALIDATION_STATUS`,
    SET_CONTROL_DETAILS: `${prefix}SET_CONTROL_DETAILS`,
    SET_NOTE: `${prefix}SET_NOTE`,
    PATCH_NOTE: `${prefix}PATCH_NOTE`,
    CHANGE_FORM_SORT: `${prefix}CHANGE_FORM_SORT`,
    ON_CHANGE_SELECTED_FORM: `${prefix}ON_CHANGE_SELECTED_FORM`,
    SET_NOTES_WITH_CURRENT_APPT: `${prefix}SET_NOTES_WITH_CURRENT_APPT`,
    SET_APPT_EVENT: `${prefix}SET_APPT_EVENT`,
    SET_APPENDS: `${prefix}SET_APPENDS`,
}

const defaultSort = {
    sortBy: null,
    sortType: null
}

const initialNoteState: INoteDetails = {
    validations: {},
    details: {},
    note: null,
    formSort: {
        ...defaultSort
    },
    selectedFormSeedId: null,
    notesWithCurrentAppt: {},
    apptEvent: null,
    appends: {}
}

const initialState: IStateReducer = {};

const Reducer = (state: IStateReducer = _.cloneDeep(initialState), action: IAction): IStateReducer => {
    const checkExist = () => {
        const patientData = state[action.patientId] || {}
        const noteState = state[action.patientId]?.[action.noteId] || _.cloneDeep(initialNoteState);
        const noteValidationsState = state[action.patientId]?.[action.noteId]?.validations || {}
        const formSort = state[action.patientId]?.[action.noteId]?.formSort || { ...defaultSort }
        const currentNote = state[action.patientId]?.[action.noteId]?.note || {}
        return {
            patientData,
            noteState,
            noteValidationsState,
            formSort,
            currentNote
        }
    }
    switch (action.type) {
        case actionsTypes.SET_APPENDS: {
            const { patientData, noteState } = checkExist()
            return {
                ...state,
                [action.patientId]: {
                    ...patientData,
                    [action.noteId]: {
                        ...noteState,
                        appends: {
                            ...action.payload
                        }
                    }
                }
            }
        }
        case actionsTypes.SET_APPT_EVENT: {
            const { patientData, noteState } = checkExist()
            return {
                ...state,
                [action.patientId]: {
                    ...patientData,
                    [action.noteId]: {
                        ...noteState,
                        apptEvent: action.payload
                    }
                }
            }
        }
        case actionsTypes.SET_NOTES_WITH_CURRENT_APPT: {
            const { patientData, noteState } = checkExist()
            return {
                ...state,
                [action.patientId]: {
                    ...patientData,
                    [action.noteId]: {
                        ...noteState,
                        notesWithCurrentAppt: action.payload
                    }
                }
            }
        }
        case actionsTypes.ON_CHANGE_SELECTED_FORM: {
            const { patientData, noteState } = checkExist()
            return {
                ...state,
                [action.patientId]: {
                    ...patientData,
                    [action.noteId]: {
                        ...noteState,
                        selectedFormSeedId: action.payload?.seedId
                    }
                }
            }
        }
        case actionsTypes.CHANGE_FORM_SORT: {
            const { patientData, noteState, formSort } = checkExist()
            return {
                ...state,
                [action.patientId]: {
                    ...patientData,
                    [action.noteId]: {
                        ...noteState,
                        formSort: { ...formSort, ...action.payload }
                    }
                }
            }
        }
        case actionsTypes.PATCH_NOTE: {
            const { patientData, noteState, currentNote } = checkExist()
            return {
                ...state,
                [action.patientId]: {
                    ...patientData,
                    [action.noteId]: {
                        ...noteState,
                        note: {
                            ...currentNote,
                            ...action.payload
                        }
                    }
                }
            }
        }
        case actionsTypes.SET_NOTE: {
            const { patientData, noteState } = checkExist()
            return {
                ...state,
                [action.patientId]: {
                    ...patientData,
                    [action.noteId]: {
                        ...noteState,
                        note: action.payload
                    }
                }
            }
        }
        case actionsTypes.SET_CONTROL_VALIDATION_STATUS: {
            const { patientData, noteState, noteValidationsState } = checkExist()
            return {
                ...state,
                [action.patientId]: {
                    ...patientData,
                    [action.noteId]: {
                        ...noteState,
                        validations: {
                            ...noteValidationsState,
                            [action.control.id]: action.payload
                        }
                    }
                }
            }
        }
        case actionsTypes.SET_CONTROL_DETAILS: {
            const { patientData, noteState } = checkExist()
            return {
                ...state,
                [action.patientId]: {
                    ...patientData,
                    [action.noteId]: {
                        ...noteState,
                        details: action.payload
                    }
                }
            }
        }
        default:
            return state;
    }
};

export default Reducer;
