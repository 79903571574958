import React, { FC, useEffect } from 'react';

interface IProps {
  wrapUUID: string;
  children: React.ReactNode;
}

const Component: FC<IProps> = ({ wrapUUID, children }) => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey) {
        e.stopPropagation();
      }
    };
    setTimeout(() => {
      const element = document.getElementById(wrapUUID);
      if (element) {
        element.addEventListener('keydown', onKeyDown);
      }
      return () => {
        if (element) {
          element.removeEventListener('keydown', onKeyDown);
        }
      };
    }, 500);
  }, []);

  return children as React.ReactElement;
};

export default React.memo(Component);
