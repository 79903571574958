export interface IResponse<T = any> {
    headers: {
        message: string
        success: boolean
    }
    payload: {
        data: Record<string, T>
        subscriptionId: string
    }

}

export enum Operation {
    Upsert = 'upsert',
    Delete = 'delete'
}

export interface IListenerCallback<B, T = any> {
    change: T
    entity: {
        bucket: B
        id: string
    }
    operation: Operation
}

export enum StatusType {
    CLOSE = 'close',
    RECONNECTING = 'reconnecting',
    RECONNECTED = 'reconnected',
}

export type OnReconnecionStatusChanged = (status: StatusType) => any