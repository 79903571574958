import * as actionTypes from "./FormsActions";
import _ from 'lodash';

const initialState: any = {};

interface IAction {
    type: string;
    payload: any;
    id: any;
}

const FormReducer = (
    state: any = { ...initialState },
    action: IAction
) => {
    switch (action.type) {
        case actionTypes.ADD_FORM_OVERVIEW: {
            let newState = _.cloneDeep(state)
            newState[action.id] = action.payload;
            return newState;
        }
        default:
            return state;
    }
};
export default FormReducer;