export function hasLowerCase(str: string): boolean {
    return (/[a-z]/.test(str));
}

export function hasUpperCase(str: string): boolean {
    return (/[A-Z]/.test(str));
}

export function hasNumberCase(str: string): boolean {
    return (/\d/.test(str));
}

export function hasSpecialCase(str: string): boolean {
    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (format.test(str)) {
        return true;
    } else {
        return false;
    }
}