import React, { FC, useState } from 'react';

import Tooltip from 'src/Framework/Controls/Tooltip';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { CheckedBoxSvg } from 'src/Framework/Common/Svg/CheckedBoxSvg';
import { ClearCheckSvg } from 'src/Framework/Common/Svg/ClearCheckSvg';
import { UnCheckedBoxSvg } from 'src/Framework/Common/Svg/UnCheckedBoxSvg';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

import { Container, CheckAll } from './styled';

export interface IProps {
  checkedAll: boolean;
  uncheckedAll: boolean;
  id: string;
  tooltip?: string | React.ReactNode;
  asyncChange?: boolean;
  marginBottom?: boolean;
  onChange: Function;
  children?: React.ReactNode;
}

const CheckboxComponent: FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    checkedAll,
    uncheckedAll,
    onChange,
    asyncChange,
    marginBottom,
    id,
    tooltip
  } = props;
  const change = async (e: any) => {
    if (asyncChange) {
      setLoading(true);
      if (onChange) {
        await onChange(e);
      }
      setLoading(false);
      return;
    }
    if (onChange) {
      onChange(e);
    }
  };

  const getCheckBoxState = () => {
    if (checkedAll) {
      return <CheckedBoxSvg />;
    } else if (uncheckedAll) {
      return <UnCheckedBoxSvg />;
    } else {
      return <ClearCheckSvg />;
    }
  };

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <Container className="custom-checkbox" marginBottom={marginBottom}>
          {loading && <AbsoluteLoader />}
          <CheckAll
            tabIndex={0}
            role="checkbox"
            aria-label="select / unselect all items"
            aria-checked={checkedAll}
            className={'verify'}
            onClick={(e: any) => change(e)}
            onKeyDown={onKeyDownAccessibility}
            id={id}
          >
            {getCheckBoxState()}
            {props.children}
          </CheckAll>
        </Container>
      </Tooltip>
    );
  }
  return (
    <Container className="custom-checkbox" marginBottom={marginBottom}>
      {loading && <AbsoluteLoader />}
      <CheckAll
        tabIndex={0}
        role="checkbox"
        aria-label="select / unselect all items"
        aria-checked={checkedAll}
        className={'verify'}
        onClick={(e: any) => change(e)}
        onKeyDown={onKeyDownAccessibility}
        id={id}
      >
        {getCheckBoxState()}
        {props.children}
      </CheckAll>
    </Container>
  );
};

export default React.memo(CheckboxComponent);
