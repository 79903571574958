import { IRuleComponent } from "../../Models/RuleDefinition";
import * as actionType from "../ActionTypes";
import { IRuleTemplate } from "../../Models/RuleTemplate";

import { actionsTypes } from '../../RuleReducers/RuleDefinitionReducer/RuleDefinitionReducer'
import store from "src/store";

export const onChangeRuleDefinition = (ruleId: string, definition: any) => {
  store.dispatch({
    type: actionsTypes.SET_DEFINITION,
    ruleId,
    payload: definition
  })
}

export interface IRuleEditorAction {
  perform(dispatch: any): any;
  undo(dispatch: any): any;
  redo(dispatch: any): any;
}

export class AddRuleComponentAction implements IRuleEditorAction {
  private _ruleId: string;
  private _parentId: number;
  private _componentId: number;
  private _componentTemplate: any;


  constructor(
    ruleId: string,
    parentId: number,
    componentId: number,
    componentTemplate: any,
  ) {
    this._ruleId = ruleId;
    this._parentId = parentId;
    this._componentId = componentId;
    this._componentTemplate = componentTemplate;
  }
  public perform(dispatch: any) {
    dispatch({
      type: actionType.CREATE_RULE_COMPONENT,
      id: this._ruleId,
      parentId: this._parentId,
      componentId: this._componentId,
      componentTemplate: this._componentTemplate,
    });
  }

  public undo(dispatch: any) {
    dispatch({
      type: actionType.REMOVE_RULE_COMPONENT,
      id: this._ruleId,
      sourceId: this._parentId,
      componentId: this._componentId,
    });
  }

  public redo(dispatch: any) {
    this.perform(dispatch);
  }
}
export class MoveRuleComponentAction implements IRuleEditorAction {
  private _ruleId: string;
  private _sourceId: number;
  private _componentId: number;
  private _parentId: number;
  private _sourceIndex: number;
  private _targetIndex: number;

  constructor(
    ruleId: string,
    componentId: number,
    sourceId: number,
    parentId: number,
    sourceIndex: number,
    targetIndex: number,
  ) {
    this._ruleId = ruleId;
    this._sourceId = sourceId;
    this._componentId = componentId;
    this._parentId = parentId;
    this._sourceIndex = sourceIndex;
    this._targetIndex = targetIndex;
  }

  public perform(dispatch: any) {
    dispatch({
      type: actionType.MOVE_RULE_COMPONENT,
      id: this._ruleId,
      sourceId: this._sourceId,
      componentId: this._componentId,
      targetId: this._parentId,
      targetIndex: this._targetIndex,
      sourceIndex: this._sourceIndex
    });
  }

  public undo(dispatch: any) {
    dispatch({
      type: actionType.MOVE_RULE_COMPONENT,
      id: this._ruleId,
      sourceId: this._parentId,
      componentId: this._componentId,
      targetId: this._sourceId,
      sourceIndex: this._targetIndex,
      targetIndex: this._sourceIndex
    });
  }

  public redo(dispatch: any) {
    this.perform(dispatch);
  }
}
export class RemoveRuleComponentAction implements IRuleEditorAction {
  private _ruleId: string;
  private _component: IRuleComponent;
  private _parentId: number;
  private _index: number;

  constructor(
    ruleId: string,
    component: string,
    parentId: number,
    index: number,
  ) {
    this._ruleId = ruleId;
    this._component = JSON.parse(component).component;
    this._parentId = parentId;
    this._index = index;
  }

  public perform(dispatch: any) {
    dispatch({
      type: actionType.REMOVE_RULE_COMPONENT,
      id: this._ruleId,
      componentId: this._component.id,
      sourceId: this._parentId,
      index: this._index,
    });
  }

  public undo(dispatch: any) {
    dispatch({
      type: actionType.ADD_RULE_COMPONENT,
      id: this._ruleId,
      parentId: this._parentId,
      component: this._component,
      index: this._index,
    });
  }

  public redo(dispatch: any) {
    this.perform(dispatch);
  }
}

export class AddOptionValueAction implements IRuleEditorAction {
  private _newValue: any;
  private _componentId: number;
  private _keyName: string;
  private _oldValue: any;
  private _ruleId: string;
  private _subType: string | undefined;
  private _subComponent: IRuleTemplate | undefined;

  constructor(
    newValue: any,
    componentId: number,
    keyName: string,
    oldValue: any,
    ruleId: string,
    subType?: string,
    subComponent?: IRuleTemplate
  ) {
    this._newValue = newValue;
    this._componentId = componentId;
    this._keyName = keyName;
    this._oldValue = oldValue;
    this._ruleId = ruleId;
    this._subType = subType;
    this._subComponent = subComponent;
  }

  public perform(dispatch: any) {
    dispatch({
      type: actionType.SELECT_OPTION,
      newValue: this._newValue,
      componentId: this._componentId,
      keyName: this._keyName,
      oldValue: this._oldValue,
      id: this._ruleId,
      subType: this._subType,
      subComponent: this._subComponent
    });
  }

  public undo(dispatch: any) {
    dispatch({
      type: actionType.SELECT_OPTION,
      newValue: this._oldValue ? this._oldValue : null,
      componentId: this._componentId,
      keyName: this._keyName,
      oldValue: this._newValue,
      id: this._ruleId,
      subType: this._subType,
      subComponent: this._subComponent
    });
  }

  public redo(dispatch: any) {
    this.perform(dispatch);
  }
}
