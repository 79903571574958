import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${globalStyleVariables.borderColor};
`;

interface IBlock {
  border?: boolean;
}

export const Block = styled.div<IBlock>`
  padding: 20px;
  ${({ border }) =>
    border && `border-right: 1px solid ${globalStyleVariables.borderColor};`}
`;
