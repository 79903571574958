import React, { FC, useEffect, useRef } from 'react';
import { Input } from 'antd';
import cn from 'classnames';

import { IOtherSelects } from '../index';
import store from 'src/store';
import { TextAreaRef } from 'antd/es/input/TextArea';

const { TextArea } = Input;

type IOwnProps = IOtherSelects;

interface IProps extends IOwnProps {}

function reverse(s: string) {
  return s.split('').reverse().join('');
}

function removeLastSpace(str: string) {
  if (str.charAt(str.length - 1) === ' ') {
    return str.slice(0, -1);
  } else {
    return str;
  }
}

const getLastWord = (sentense: string) => {
  const text = removeLastSpace(sentense);
  const checkEnter = (word: string) => {
    const enters = word.split('\n').filter((v) => v);
    return enters[enters.length - 1];
  };
  const space = text.split(' ');
  return checkEnter(space[space.length - 1]);
};

const changeLastWord = (
  sentense: string,
  lastWord: string,
  replaced: string
) => {
  const s = reverse(sentense);
  const l = reverse(lastWord);
  const r = reverse(replaced);
  const finish = reverse(s.replace(l, r));
  return finish;
};

export const spellFinder = (text: string) => {
  const lastChar = text[text.length - 1];
  if (lastChar === ' ' || lastChar === '\n') {
    let newText = text.slice(0, text.length - 1);
    newText = newText.replace(/\n/g, ' ');
    const arr = newText.split(' ');
    const lastWord = [...arr].reverse().find((v) => v);
    if (lastWord) {
      const replaceList = Object.values(store.getState().autoReplace.summary);
      const replaced = replaceList.find(
        (v) => v.replaceWord.toLowerCase() === lastWord.toLowerCase()
      );
      if (replaced) {
        return {
          lastWord,
          index: text.lastIndexOf(lastWord),
          replaced
        };
      }
    }
  }
  return;
};

export const spellReplacer = (text: string) => {
  const replaceList = Object.values(store.getState().autoReplace.summary);
  const lastWord = getLastWord(text) || '';
  const findReplaced = replaceList.find(
    (v) => v.replaceWord.toLowerCase() === lastWord.toLowerCase()
  );
  if (findReplaced) {
    return {
      text: changeLastWord(text, lastWord, findReplaced.withWord),
      changed: true
    };
  }
  return { text, changed: false };
};

const Component: FC<IProps> = (props: IProps) => {
  const inputRef = useRef<TextAreaRef>(null);
  const currentKey = useRef<string | null>(null);
  const spellReplacerSelection = useRef<null | number>(null);
  const {
    label,
    inputProps,
    value,
    bottomMargin,
    className,
    onChange,
    areaAutoReplace,
    hideLabel,
    id
  } = props;

  useEffect(() => {
    if (spellReplacerSelection.current !== null) {
      inputRef.current?.resizableTextArea?.textArea.setSelectionRange(
        spellReplacerSelection.current,
        spellReplacerSelection.current
      );
      spellReplacerSelection.current = null;
    }
  }, [props.value]);
  return (
    <>
      <label
        htmlFor={inputProps.id ? inputProps.id : id}
        className="visually-hidden"
      >
        {label}
      </label>
      <TextArea
        aria-label={props.label}
        aria-required={props.star}
        id={id}
        ref={inputRef}
        {...inputProps}
        value={value}
        className={cn({
          select: true,
          margin: bottomMargin,
          [`${className}`]: className
        })}
        placeholder={
          inputProps?.hasOwnProperty('placeholder')
            ? inputProps?.placeholder
            : hideLabel
            ? label
            : ''
        }
        onKeyDown={(e) => {
          e.stopPropagation();
          if (onChange && areaAutoReplace) {
            currentKey.current = e.code;
          }
          inputProps?.onKeyDown && inputProps?.onKeyDown(e);
        }}
        onKeyUp={(e) => {
          currentKey.current = null;
        }}
        onChange={(e) => {
          if (onChange) {
            if (areaAutoReplace) {
              if (['Enter', 'Space'].some((v) => v === currentKey.current)) {
                const onChangeLogic = (
                  firstPart: string,
                  secondPart?: string
                ) => {
                  let text = firstPart;
                  const replacer = spellReplacer(text);
                  if (replacer.changed) {
                    text = replacer.text;
                    if (
                      inputProps?.maxLength &&
                      text.length > inputProps?.maxLength
                    ) {
                      text = text.slice(0, inputProps?.maxLength);
                    }
                    onChange(text + (secondPart || ''));
                    spellReplacerSelection.current = text.length;
                    return replacer;
                  }
                  return;
                };
                if (e.target.selectionStart === e.target.selectionEnd) {
                  const firstPart = e.target.value.slice(
                    0,
                    e.target.selectionStart
                  );
                  const secondPart = e.target.value.slice(
                    e.target.selectionStart,
                    e.target.value.length
                  );
                  const changed = onChangeLogic(firstPart, secondPart);
                  if (changed?.changed) {
                    return;
                  }
                } else {
                  const changed = onChangeLogic(e.target.value);
                  if (changed?.changed) {
                    return;
                  }
                }
              }
            }

            onChange(e.target.value);
          }
        }}
      />
    </>
  );
};

export default React.memo(Component);
