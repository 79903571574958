import { IRuleSummary } from '../Models/Rule';
import * as actionTypes from './RuleManagerActions';
import _ from 'lodash';
import { actionsTypes } from 'src/App/Admin/Pages/NoteTypes/store/Reducer';
import { ISort } from 'src/Framework/Controls/Table/types';

const initialState: any = {
  pagination: {
    current: 1
  },
  summary: [],
  sort: {
    sortBy: 'nameString',
    sortType: 'asc'
  }
};

interface IAction {
  type: string;
  pagination: any;
  ruleSummary: IRuleSummary[];
  ruleId: string;
  isEnabled: boolean;
  ruleName: string;
  description: string;
  sort?: Partial<ISort>;
}

const RuleManagerReducer = (
  state: any = { ...initialState },
  action: IAction
) => {
  switch (action.type) {
    case actionTypes.ADD_RULES: {
      let newState = _.cloneDeep(state);
      if (action.pagination.current > 1) {
        let isExistSameRule: boolean = isExistRule(
          newState.summary,
          action.ruleSummary
        );
        if (isExistSameRule) return state;
        newState.summary = [...newState.summary, ...action.ruleSummary];
      } else {
        newState.summary = action.ruleSummary;
      }
      newState.pagination = action.pagination;
      return newState;
    }

    case actionTypes.CLEAR_RULES: {
      let newState = _.cloneDeep(state);
      newState = initialState;
      return newState;
    }

    case actionTypes.UPDATE_RULE_DETAILS: {
      let newState = _.cloneDeep(state);
      let rules: IRuleSummary[] = [...newState.summary];
      let rule: IRuleSummary | undefined = rules.find(
        (r: IRuleSummary) => r.ruleId === action.ruleId
      );
      if (!rule) return state;
      rule.ruleName = action.ruleName;
      rule.description = action.description;
      newState.summary = rules;
      return newState;
    }

    case actionTypes.REMOVE_DRAFT: {
      let newState = _.cloneDeep(state);
      let rules: any[] = [...newState.summary];
      let index: number = rules.findIndex(
        (r: IRuleSummary) => r.ruleId === action.ruleId
      );
      console.log(rules);
      rules.splice(index, 1);
      newState = rules;
      return newState;
    }
    case actionsTypes.SET_TABLE_SORT:
      return { ...state, sort: action.sort };
    default:
      return state;
  }
};
export default RuleManagerReducer;

// to check whether a rule already exist in the store
function isExistRule(stateRules: any, actionRules: any): boolean {
  if (actionRules && actionRules.length > 0) {
    let action_rule: any = actionRules[0];
    let isExistRule: any = stateRules.find((rule: any) => {
      return rule.ruleId === action_rule.ruleId;
    });
    if (isExistRule) return true;
  }
  return false;
}
