import _ from 'lodash'
import { IAction, IStateReducer, IProviderStore } from './types'

const prefix = 'PATIENTS_PROVIDERS_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_SORT: `${prefix}SET_SORT`,
}

export const initialData: IProviderStore = {
    summary: {},
    sort: {
        sortBy: 'dts',
        sortType: 'desc'
    }
}

const initialState: IStateReducer = {
    patients: {},
};

const Reducer = (state: IStateReducer = { ..._.cloneDeep(initialState) }, action: IAction): IStateReducer => {
    if (!state.patients[action.patientId]) {
        state.patients[action.patientId] = _.cloneDeep(initialData)
    }
    switch (action.type) {
        case actionsTypes.SET_SORT:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        sort: action.payload
                    }
                }
            }
        case actionsTypes.SET:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        summary: action.payload
                    }
                }
            }
        default:
            return state;
    }
};

export default Reducer;
