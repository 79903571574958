import { IStateReducer, IAction } from './types';

const sufix = 'SCHEDULE_FAVORITES_';

export const actionsTypes = {
  SET_DATA: `${sufix}SET_DATA`,
  PATCH_DATA_ITEM: `${sufix}PATCH_DATA_ITEM`,
  SET_LOADING: `${sufix}SET_LOADING`,
  SET_LOADING_MORE: `${sufix}SET_LOADING_MORE`,
  SET_ERROR: `${sufix}SET_ERROR`
};

const initialState: IStateReducer = {
  loading: true,
  loadingMore: false,
  error: false,
  data: {
    summary: [],
    total: 0,
    count: 0,
    startIndex: 0
  }
};

const Reducer = (
  state: IStateReducer = { ...initialState },
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET_ERROR:
      return { ...state, loading: action.payload };
    case actionsTypes.SET_LOADING_MORE:
      return { ...state, loadingMore: action.payload };
    case actionsTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case actionsTypes.SET_DATA:
      return { ...state, data: action.payload };
    case actionsTypes.PATCH_DATA_ITEM:
      const summaryData = [...state.data.summary];
      const index = summaryData.findIndex(
        (v: any) => v.id === action.payload.id
      );
      if (index !== -1) {
        summaryData[index] = { ...action.payload, ...JSON.parse(action.payload.favorite) };
      }
      return { ...state, data: { ...state.data, summary: summaryData } };
    default:
      return state;
  }
};

export default Reducer;
