import React, { FC, useCallback } from 'react';
import { IBuildingForm } from '../store/types';

import { Container } from './styled';
import { Col, Row, notification } from 'antd';
import Button from 'src/Framework/Controls/Button';
import { useMarketplace } from 'src/App/Admin/Pages/PublicForms/store/Hooks/useMarketplace';
import * as formsActions from '../store/Actions';

interface IProps {
  forms: IBuildingForm[];
}

const Component: FC<IProps> = ({ forms }: IProps) => {
  const { actions } = useMarketplace({});

  const onClickUpdate = useCallback(async () => {
    const res = await Promise.all(
      forms.map((form) =>
        actions.postFormUpdateVersion(form.importedEntityId, true)
      )
    );
    if (res.some((v) => !v)) {
      notification.error({
        message: 'Error',
        duration: 5,
        description:
          'Something went wrong. Please wait for 5 minutes and restart the update.'
      });
    }
    await formsActions.get({
      IncludeSharedFormInformation: true
    });
    notification.success({
      message: 'Success',
      duration: 5,
      description: 'Read-only Forms have been successfully updated.'
    });
  }, [forms]);
  return (
    <Container>
      <Row align="middle" gutter={16} justify="space-between">
        <Col>
          New versions of the read only forms available.{' '}
          <b>Please update your forms.</b>
        </Col>
        <Col>
          <Button
            id=""
            onClick={onClickUpdate}
            colorStyles={{
              transparent: true
            }}
          >
            Update All
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(Component);
