import * as actionTypes from "./NoteCommentsActions";
import _ from 'lodash';
import { IReducer } from './types'


const initialState: IReducer = {
    noteId: null,
    threads: null,
    threadsUnread: false,
    threadsUnresolved: false,
    show: false,
};

interface IAction {
    type: string;
    threads: any;
    noteId: any;
    property: string;
    show: boolean;
    userId: number;
}

const NoteCommentsReducer = (
    state: IReducer = _.cloneDeep(initialState),
    action: IAction
): IReducer => {
    let hasThreadsUnresolved: boolean = false;
    let hasThreadsUnread: boolean = false;
    if (action.threads && Object.values(action.threads).length > 0) {
        const userId: string | null = localStorage.getItem("userId");
        if (userId) {
            const threads = Object.values(action.threads);
            hasThreadsUnresolved = !!threads.find((thread: any) => (thread.state === "open"));
            hasThreadsUnread = !!threads.find((thread: any) => (!thread.isReadByUser));
        }
    }
    switch (action.type) {
        case actionTypes.SET_NOTE_THREADS:
            return {
                ...state,
                noteId: action.noteId,
                threads: action.threads,
                threadsUnread: hasThreadsUnread,
                threadsUnresolved: hasThreadsUnresolved
            };
        case actionTypes.SET_SHOW_COMMENTS_BAR:
            return {
                ...state,
                show: !state.show
            };
        default:
            return state;
    }
};
export default NoteCommentsReducer;