import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import cn from 'classnames';
import LazyLoader from 'src/Framework/Controls/LazyLoader';
import ReactDOM from 'react-dom';

import Icon from 'src/Framework/Controls/Icon';
import { IFileSimpleDto } from 'src/Framework/Controls/FileUploader';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { Formats, IData } from './types';
import { ImageFormats, generateCanvasFromTiff } from './utils';

import {
  downloadAttachment,
  getAttachmentById
} from 'src/Activities/Counseling/Dashboard/Attachments/store/Actions';

import {
  TiffContainer,
  ViewerContainer,
  RotateButtonContainer,
  PrintContainer
} from './styled';

const LazyImageViewer = React.lazy(
  () => import(/* webpackChunkName: "ImageViewer" */ './ImageViewer')
);

export interface IGetOnLoadedData {
  ref: HTMLDivElement | null;
  format: Formats;
  blobUrl: string;
  file: IFileSimpleDto | undefined;
}

interface IProps {
  data: IData;
  getOnLoadedData?: (data: IGetOnLoadedData) => any;
  newTab?: boolean;
  hasHeader?: boolean;
}

const config = {
  header: { disableHeader: true }
};

const Component: FC<IProps> = ({
  data,
  getOnLoadedData: getContainerRef,
  newTab,
  hasHeader
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const printRef = useRef<HTMLDivElement>(null);
  const DocViewerRef = useRef<any>(null);
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  const [format, setFormat] = useState<Formats | null>(null);
  const [loading, setLoading] = useState(false);
  const [tiffUrls, setTiffUrls] = useState<string[] | null>(null);
  const [rotate, setRotate] = useState(0);
  useEffect(() => {
    const getFile = async () => {
      setLoading(true);
      const [response, file] = await Promise.all([
        downloadAttachment(data.value.fileId),
        getAttachmentById(data.value.fileId)
      ]);

      const format = response.data.type;
      const blob = new Blob([response.data], { type: response.data.type });
      const blobUrl = window.URL.createObjectURL(blob);
      setFormat(format);
      if (format === Formats.pdf) {
        setTimeout(() => {
          const controls = document.getElementById('pdf-controls');
          if (controls) {
            const element = document.createElement('div');
            controls.appendChild(element);
            ReactDOM.render(
              <RotateButtonContainer onClick={onClickRotate}>
                <Icon size={20} name="refresh" color="black" />
              </RotateButtonContainer>,
              element as HTMLElement
            );
          }
        }, 200);
      }
      if (format === Formats.tiff) {
        const buffer = await blob.arrayBuffer();
        const canvas = generateCanvasFromTiff(buffer);
        if (canvas) {
          setTiffUrls(
            canvas.map((v: any) => {
              return v.toDataURL();
            })
          );
        }
      } else {
        setBlobUrl(blobUrl);
      }

      setLoading(false);
      if (getContainerRef) {
        getContainerRef({
          ref: printRef.current,
          blobUrl,
          format,
          file
        });
      }
    };
    getFile();
  }, []);
  const onClickRotate = useCallback(() => {
    setRotate((rotate) => {
      if (rotate === 3) {
        return 0;
      }
      return rotate + 1;
    });
  }, []);
  const documents = useMemo(() => {
    if (!blobUrl) {
      return [];
    }
    return [
      {
        uri: blobUrl
      }
    ];
  }, [blobUrl]);
  const tiffImages = useMemo(() => {
    if (!tiffUrls) return [];
    return tiffUrls.map((v, i) => ({
      src: v,
      alt: `image-${i}`,
      key: `image-${i}`
    }));
  }, [tiffUrls]);
  const blobImages = useMemo(() => {
    if (!blobUrl) return [];
    return [
      {
        src: blobUrl,
        alt: `image-1`,
        key: `image-1`
      }
    ];
  }, [blobUrl]);
  const isImage = format ? ImageFormats.includes(format) : false;
  return (
    <ViewerContainer
      ref={containerRef}
      className={cn({
        html: format === Formats.html,
        pdf: format === Formats.pdf
      })}
      rotate={rotate}
      newTab={newTab}
      hasHeader={hasHeader}
    >
      <PrintContainer ref={printRef} newTab={newTab} hasHeader={hasHeader}>
        {loading && <AbsoluteLoader />}
        {tiffUrls && (
          <TiffContainer>
            <LazyLoader>
              <LazyImageViewer images={tiffImages} />
            </LazyLoader>
          </TiffContainer>
        )}
        {blobUrl && (
          <>
            {isImage ? (
              <LazyLoader>
                <LazyImageViewer images={blobImages} />
              </LazyLoader>
            ) : (
              <DocViewer
                ref={DocViewerRef}
                documents={documents}
                pluginRenderers={DocViewerRenderers}
                config={config}
              />
            )}
          </>
        )}
      </PrintContainer>
    </ViewerContainer>
  );
};

export default React.memo(Component);
