import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .loader {
    border: 6px solid ${globalStyleVariables.newBrandingPrimary10010};
    border-radius: 50%;
    border-top: 6px solid ${globalStyleVariables.newBrandingPrimary200};
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1.2s linear infinite; /* Safari */
    animation: spin 1.2s linear infinite;
  }
`;
