import React, { FC } from 'react';

import { Container, Title } from './styled';

interface IProps {
  title: string;
  children?: React.ReactNode;
}

const Component: FC<IProps> = ({ title, children }: IProps) => {
  return (
    <Container>
      <Title tabIndex={0}>{title}</Title>
      {children}
    </Container>
  );
};

Component.defaultProps = {
  children: <></>
};

export default React.memo(Component);
