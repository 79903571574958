import { BucketsList } from "src/Framework/ApiSubscription";
import { RequestHelper } from "src/Framework/ApiSubscription/RequestHelper";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { IControl, IM1NoteCreation, IM1NoteFullDTO, IM1NoteSimpleDTO, INotePreview, SectionType } from "../types";
import { setSummary, onCreateUpdateItem } from "./Setters";
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { IFileSimpleDto } from "src/Framework/Controls/FileUploader";

export const SubscriptionData = (providerId: string | number) => ({
    bucket: BucketsList.M1Note,
    filter: `${BucketsList.M1Note}.SubjectProviderId == ${providerId}`
})

export const { get, post, patch, unsubscribe } = new RequestHelper<IM1NoteSimpleDTO, [providerId: string | number], [providerId: string | number], IM1NoteCreation>({
    link: ApiUrl.M1Notes,
    onCreateUpdateItem,
    subscription: {
        subscriptionData: SubscriptionData,
        setSummary
    }
})

export const parse = (data: string) => {
    try {
        return JSON.parse(data)
    } catch (e) {
        return null
    }
}

export const getM1Preview = async (noteId: number): Promise<INotePreview | undefined> => {
    try {
        const res = await APIHandler.AxiosInstance.get(`${ApiUrl.M1Notes}/${noteId}/preview`)
        if (res.data.success) {
            const data = res.data.result.sections
            for (let i = 0; i < data.length; i++) {
                if (data[i]?.sectionType === SectionType.buildingBlock) {
                    data[i].schema = parse(data[i].schema)
                }
            }
            return res.data.result
        }
        return
    } catch (e) {
        return
    }
}


export const getM1Note = async (noteId: string | number): Promise<IM1NoteFullDTO | undefined> => {
    try {
        const res = await APIHandler.AxiosInstance.get(`${ApiUrl.M1Notes}`, {
            params: {
                ids: noteId,
                format: 'full'
            }
        })
        if (res.data.success) {
            const value = Object.values(res.data.result.summary)[0] as IM1NoteFullDTO
            if (value) {
                Object.entries(value.schemas).forEach(([key, v]) => {
                    value.schemas[key] = parse(value.schemas[key]) || {}
                })
                return value
            }
        }
        return
    } catch (e) {
        return
    }
}

export const putM1NoteDetails = async (data: {
    details: Record<string, IControl>
}, noteId: number | string): Promise<boolean | undefined> => {
    try {
        const res = await APIHandler.AxiosInstance.put(`${ApiUrl.M1Notes}/${noteId}/details`, data)
        if (res.data.success) {
            return true
        }
        return
    } catch (e) {
        return
    }
}

export const putM1NoteForm = async (noteId: number | string, formRecordId: number | string): Promise<boolean | undefined> => {
    try {
        const res = await APIHandler.AxiosInstance.put(`${ApiUrl.M1Notes}/${noteId}/m1forms/${formRecordId}`)
        if (res.data.success) {
            return true
        }
        return
    } catch (e) {
        return
    }
}

export const deleteM1NoteForm = async (noteId: number | string, formRecordId: number | string): Promise<boolean | undefined> => {
    try {
        const res = await APIHandler.AxiosInstance.delete(`${ApiUrl.M1Notes}/${noteId}/m1forms/${formRecordId}`)
        if (res.data.success) {
            return true
        }
        return
    } catch (e) {
        return
    }
}

export const postM1NoteAppend = async (noteId: number | string, text: string): Promise<boolean | undefined> => {
    try {
        const res = await APIHandler.AxiosInstance.post(`${ApiUrl.M1Notes}/${noteId}/append`, { text })
        if (res.data.success) {
            return true
        }
        return
    } catch (e) {
        return
    }
}

export const putM1NoteAttachment = async (noteId: number | string, fileId: string | number): Promise<IFileSimpleDto | undefined> => {
    try {
        const res = await APIHandler.AxiosInstance.put(`${ApiUrl.M1Notes}/${noteId}/files/${fileId}`)
        if (res.data.success) {
            return res.data.result
        }
        return
    } catch (e) {
        return
    }
}

export const deleteM1NoteAttachment = async (noteId: number | string, fileId: string | number): Promise<boolean | undefined> => {
    try {
        const res = await APIHandler.AxiosInstance.delete(`${ApiUrl.M1Notes}/${noteId}/files/${fileId}`)
        if (res.data.success) {
            return true
        }
        return
    } catch (e) {
        return
    }
}    