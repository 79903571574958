import _ from 'lodash';
import { IStateReducer, IAction } from './types';

const prefix = `UserPatientAccess_`;

export const actionsTypes = {
  SET: `${prefix}SET`
};

const initialState: IStateReducer = {
  patients: {}
};

const Reducer = (
  state = _.cloneDeep(initialState),
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET:
      return {
        ...state,
        patients: {
          [action.patientId]: {
            ...state.patients[action.patientId],
            summary: {
              ...action.payload
            }
          }
        }
      };
    default:
      return state;
  }
};

export default Reducer;
