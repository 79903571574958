export const recurringViewOptions = [
  { value: 1, label: 'Daily' },
  { value: 2, label: 'Weekly' },
  { value: 3, label: 'Biweekly' },
  { value: 4, label: 'Monthly - Day' },
  { value: 5, label: 'Monthly - Week' }
];

export const daysOptions = [
  { value: '1', label: '1', isSelected: 0 },
  { value: '2', label: '2', isSelected: 0 },
  { value: '3', label: '3', isSelected: 0 },
  { value: '4', label: '4', isSelected: 0 },
  { value: '5', label: '5', isSelected: 0 },
  { value: '6', label: '6', isSelected: 0 },
  { value: '7', label: '7', isSelected: 0 },
  { value: '8', label: '8', isSelected: 0 },
  { value: '9', label: '9', isSelected: 0 },
  { value: '10', label: '10', isSelected: 0 },
  { value: '11', label: '11', isSelected: 0 },
  { value: '12', label: '12', isSelected: 0 },
  { value: '13', label: '13', isSelected: 0 },
  { value: '14', label: '14', isSelected: 0 },
  { value: '15', label: '15', isSelected: 0 },
  { value: '16', label: '16', isSelected: 0 },
  { value: '17', label: '17', isSelected: 0 },
  { value: '18', label: '18', isSelected: 0 },
  { value: '19', label: '19', isSelected: 0 },
  { value: '20', label: '20', isSelected: 0 },
  { value: '21', label: '21', isSelected: 0 },
  { value: '22', label: '22', isSelected: 0 },
  { value: '23', label: '23', isSelected: 0 },
  { value: '24', label: '24', isSelected: 0 },
  { value: '25', label: '25', isSelected: 0 },
  { value: '26', label: '26', isSelected: 0 },
  { value: '27', label: '27', isSelected: 0 },
  { value: '28', label: '28', isSelected: 0 },
  { value: '29', label: '29', isSelected: 0 },
  { value: '30', label: '30', isSelected: 0 },
  { value: '31', label: '31', isSelected: 0 },
  { value: 'Last', label: 'Last', isSelected: 0 }
];
export const weekNumberOptions = [
  { value: 1, label: 'First' },
  { value: 2, label: 'Second' },
  { value: 3, label: 'Third' },
  { value: 4, label: 'Fourth' }
];

export const initWeekdayOptions = [
  {
    value: 'Sunday',
    label: 'Sunday',
    isSelected: false,
    code: 1,
    startOfTheWeek: true
  },
  { value: 'Monday', label: 'Monday', isSelected: false, code: 2 },
  { value: 'Tuesday', label: 'Tuesday', isSelected: false, code: 3 },
  { value: 'Wednesday', label: 'Wednesday', isSelected: false, code: 4 },
  { value: 'Thursday', label: 'Thursday', isSelected: false, code: 5 },
  { value: 'Friday', label: 'Friday', isSelected: false, code: 6 },
  {
    value: 'Saturday',
    label: 'Saturday',
    isSelected: false,
    code: 7,
    endOfTheWeek: true
  }
];

export const weekArray = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const genderList = {
  T: 'Transgender',
  M: 'Male',
  F: 'Female'
};
export const TFtoYN = {
  True: 'Yes',
  False: 'No',
  F: 'No',
  T: 'Yes'
};
export const TFtoTF = {
  True: 'True',
  False: 'False',
  F: 'False',
  T: 'True'
};
export const viewOptions = [
  { value: 'day', label: 'Day' },
  { value: 'workweek', label: 'My Week' },
  { value: 'weekprovider', label: 'Week Provider(s)' }
];

export const defaultTimeLines = [
  {
    label: '8:00',
    isBig: true,
    noShow: false
  },
  {
    label: '8:15',
    isBig: false,
    noShow: false
  },
  {
    label: '8:30',
    isBig: true,
    noShow: false
  },
  {
    label: '8:45',
    isBig: false,
    noShow: false
  },
  {
    label: '9:00',
    isBig: true,
    noShow: false
  },
  {
    label: '9:15',
    isBig: false,
    noShow: false
  },
  {
    label: '9:30',
    isBig: true,
    noShow: false
  },
  {
    label: '9:45',
    isBig: false,
    noShow: false
  },
  {
    label: '10:00',
    isBig: true,
    noShow: false
  },
  {
    label: '10:15',
    isBig: false,
    noShow: false
  },
  {
    label: '10:30',
    isBig: true,
    noShow: false
  },
  {
    label: '10:45',
    isBig: false,
    noShow: false
  }
];
