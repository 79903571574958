import _ from 'lodash';
import { IReqOrder } from './types';

export interface IStateReducer {
    patients: Record<string, IReqOrderPatient>
}

interface IReqOrderPatient {
    summary: Record<string, IReqOrder>
}

export interface IAction {
    type: typeof actionsTypes[keyof typeof actionsTypes];
    payload: any;
    patientId: string | number
}

export const defaultReqOrderPatient: IReqOrderPatient = {
    summary: {}
}


const prefix = 'REQ_ORDERS_';

export const actionsTypes = {
    SET_SUMMARY: `${prefix}SET_SUMMARY`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

export const defaultData: IStateReducer = {
    patients: {},
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const patient = state.patients[action.patientId] || _.cloneDeep(defaultReqOrderPatient)
    const actions: IActions = {
        [actionsTypes.SET_SUMMARY]: () => {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patient,
                        summary: action.payload
                    }
                }
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
