import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import store from 'src/store';

import { noteActionTypes } from './PatientNoteListReducer';
import { NoteSimpleDto } from 'src/Activities/Counseling/NewNotes/Note/store/types';

export const SET_SECTION = 'SET_SECTION';
export const SET_NEW_NOTE = 'SET_NEW_NOTE';
export const OPEN_ADD_FORM = 'OPEN_ADD_FORM';
export const OPEN_ADD_ATTACHMENT = 'OPEN_ADD_ATTACHMENT';
export const SET_NOTES = 'SET_NOTES';
export const SET_NOTE = 'SET_NOTE';
export const SET_NOTES_TO_DISPLAY = 'SET_NOTES_TO_DISPLAY';
export const NOTE_LIST_SELECTED_ITEM = 'NOTE_LIST_SELECTED_ITEM';
export const SET_SORT_NOTES = 'SET_SORT_NOTES';
export const SET_FILTERS = 'NOTES_LIST_SET_FILTERS';
export const SET_SCROLL_NOTES = 'SET_SCROLL_NOTES';
export const ADD_NEW_NOTE = 'ADD_NEW_NOTE';

export const setInProgressFilter = (payload: any, subjectId: any) => {
  store.dispatch({
    type: noteActionTypes.SET_IN_PROGRESS_FILTER,
    payload,
    subjectId
  });
};

export const setInProgressSort = (payload: any, subjectId: any) => {
  store.dispatch({
    type: noteActionTypes.SET_IN_PROGRESS_SORT,
    payload,
    subjectId
  });
};

export const addNewNote = (data: any, subjectId: any) => {
  store.dispatch({
    type: ADD_NEW_NOTE,
    payload: data,
    subjectId
  });
};

export const setSection = (data: any, subjectId: any) => {
  return {
    type: SET_SECTION,
    payload: data,
    subjectId
  };
};

export const setNewNote = (data: any, subjectId: any) => {
  return {
    type: SET_NEW_NOTE,
    payload: data,
    subjectId
  };
};

export const openAddForm = (data: any, subjectId: any) => {
  return {
    type: OPEN_ADD_FORM,
    payload: data,
    subjectId
  };
};

export const setNotes = (data: any, subjectId: any) => {
  store.dispatch({
    type: SET_NOTES,
    payload: data,
    subjectId
  });
};

export const setChartNotes = (data: any, subjectId: any) => {
  store.dispatch({
    type: noteActionTypes.SET_CHART_NOTES,
    payload: data,
    subjectId
  });
};

export const setNote = (data: any, subjectId: any, noteId: any) => {
  return {
    type: SET_NOTE,
    payload: data,
    noteId,
    subjectId
  };
};

export const setSelectedItem = (data: any, subjectId: any) => {
  return {
    type: NOTE_LIST_SELECTED_ITEM,
    subjectId: subjectId,
    payload: data
  };
};

export const setSortNotes = (data: any, subjectId: any) => {
  return {
    type: SET_SORT_NOTES,
    subjectId: subjectId,
    payload: data
  };
};

export const setFilters = (data: any, subjectId: any) => {
  store.dispatch({
    type: SET_FILTERS,
    subjectId: subjectId,
    payload: data
  });
};

export const setScrollNote = (data: number, subjectId: string | number) => {
  return {
    type: SET_SCROLL_NOTES,
    subjectId: subjectId,
    payload: data
  };
};

export const getClinicalNoteMetadata = (patientId: any) => {
  return APIHandler.AxiosInstance.get(ApiUrl.NOTES, {
    withCredentials: true,
    params: {
      patientId,
      isActive: true,
      format: 'simple',
      includeLegacyNotes: true
    }
  });
};

export const getNewNotesList = async (
  patientId: number | string
): Promise<Record<string, NoteSimpleDto> | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(ApiUrl.NOTES, {
      withCredentials: true,
      params: {
        patientId,
        isActive: true,
        format: 'simple',
        includeLegacyNotes: true
      }
    });
    if (response.data.success) {
      const data = response.data.result.summary;
      // Object.keys(data).forEach((key) => {
      //   if (!data[key].noteTypeV2Id) {
      //     delete data[key]
      //   }
      // })
      return data;
    }
    return;
  } catch (e) {
    return;
  }
};

export const getM1NotesList = async (
  patientId: number,
  clinicIds: number[]
): Promise<Record<string, NoteSimpleDto> | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(ApiUrl.NOTES, {
      withCredentials: true,
      params: {
        patientId,
        clinicIds,
        isActive: true,
        format: 'simple',
        includeLegacyNotes: false
      }
    });
    if (response.data.success) {
      const data = response.data.result.summary;
      return data;
    }
    return;
  } catch (e) {
    return;
  }
};
