import { ActivityType } from "src/App/ActivityPanel/Models/Activity";
import { loadActivityTab } from "src/App/ActivityPanel/Store/ActivityActions";
import { AppUrl } from "src/Framework/Common/AppUrl"
import store from "src/store";

import { IBuildingForm } from "./store/types";

export const buildingFormPageUrl = (id: number) => {
    return `${AppUrl.BUILDING_FORM}/${id}/edit`
}

export const openBuildingFormTab = (value: IBuildingForm) => {
    loadActivityTab(
        buildingFormPageUrl(value.id),
        store.dispatch,
        {
            activityType: ActivityType.BuildingBlock
        },
        {
            title: 'Admin Form',
            subtitle: value.name
        }
    );
}

export const pageCount = 30