import _ from 'lodash';
import * as actionTypes from './PatientNoteListActions';
import { IAction, INoteList, IFilter } from './NoteInterfaces';

const prefix = `PATIENT_NOTES_`

export const noteActionTypes = {
    SET_IN_PROGRESS_FILTER: `${prefix}SET_IN_PROGRESS_FILTER`,
    SET_IN_PROGRESS_SORT: `${prefix}SET_IN_PROGRESS_SORT`,
    SET_CHART_NOTES: `${prefix}SET_CHART_NOTES`
}


interface IElement {
    currentSection: string
    newNote: string
    openAddForm: boolean
    notesInProgress: any[]
    notes: {
        [key: string]: INoteList
    },
    chartNotes: {
        [key: string]: INoteList
    },
    scrollNotes: number
    noteType: any
    noteListSelectedItem?: any
    sortNotes: ISort
    filter: IFilter
    inProgress: {
        filter: IFilter
        sortNotes: ISort
    }
}

interface ISort {
    name?: null | string
    date?: null | string
}


interface IStateReducer {
    [key: string]: IElement
}

export const initialFilter: IFilter = {
    authorUser: null,
    authorProvider: null,
    billingProvider: null,
    clinic: null,
    status: null,
    noteType: null,
    showDeleted: false,
    from: null,
    to: null,
    source: undefined
}


const initialState: IElement = {
    currentSection: "",
    newNote: "",
    openAddForm: false,
    notesInProgress: [],
    notes: {},
    chartNotes: {},
    scrollNotes: 0,
    noteType: {},
    sortNotes: {},
    filter: _.cloneDeep(initialFilter),
    inProgress: {
        filter: _.cloneDeep(initialFilter),
        sortNotes: {},
    }
};


const PatientNoteReducer = (
    state: IStateReducer = {},
    action: IAction
): IStateReducer => {
    const checkExist = () => {
        let newState = _.cloneDeep(state)
        if (!newState[action.subjectId]) {
            newState[action.subjectId] = _.cloneDeep(initialState);
        }
        return newState
    }

    switch (action.type) {
        case noteActionTypes.SET_CHART_NOTES: {
            let newState = checkExist()
            newState[action.subjectId].chartNotes = action.payload;
            return newState;
        }
        case noteActionTypes.SET_IN_PROGRESS_SORT: {
            let newState = checkExist()
            newState[action.subjectId].inProgress.sortNotes = action.payload;
            return newState;
        }
        case noteActionTypes.SET_IN_PROGRESS_FILTER: {
            let newState = checkExist()
            newState[action.subjectId].inProgress.filter = action.payload;
            return newState;
        }
        case actionTypes.SET_SECTION: {
            let newState = checkExist()
            newState[action.subjectId].currentSection = action.payload;
            return newState;
        }
        case actionTypes.SET_NEW_NOTE: {
            let newState = checkExist()
            newState[action.subjectId].newNote = action.payload;
            return newState;
        }
        case actionTypes.OPEN_ADD_FORM: {
            let newState = checkExist()
            newState.openAddForm = action.payload;
            return newState;
        }
        case actionTypes.ADD_NEW_NOTE: {
            let newState = checkExist()
            const note = newState[action.subjectId].notes[action.payload.id] || {}
            newState[action.subjectId].notes = {
                ...newState[action.subjectId].notes,
                [action.payload.id]: { ...note, ...action.payload }
            };
            return newState;
        }
        case actionTypes.SET_NOTES: {
            let newState = checkExist()
            newState[action.subjectId].notes = action.payload;
            return newState;
        }
        case actionTypes.SET_NOTE: {
            let newState = checkExist()
            newState[action.subjectId].notes[action.noteId] = action.payload;
            return newState;
        }
        case actionTypes.NOTE_LIST_SELECTED_ITEM: {
            let newState = checkExist()
            newState[action.subjectId].noteListSelectedItem = action.payload;
            return newState;
        }
        case actionTypes.SET_SORT_NOTES: {
            let newState = checkExist()
            newState[action.subjectId].sortNotes = action.payload;
            return newState;
        }
        case actionTypes.SET_FILTERS: {
            let newState = checkExist()
            newState[action.subjectId].filter = action.payload;
            return newState;
        }
        case actionTypes.SET_SCROLL_NOTES: {
            let newState = checkExist()
            newState[action.subjectId].scrollNotes = action.payload;
            return newState;
        }
        default:
            return state;
    }
};
export default PatientNoteReducer;