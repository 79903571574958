import _ from 'lodash';
import moment from 'moment';

import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { store } from 'src/index';
import { setReqOrdersDashboard } from 'src/Activities/Counseling/Dashboard/Referrals/store/ReferralsActions';

import { actionsTypes } from '.';
import { IReferral } from './types';
import { ISort } from 'src/Framework/Controls/Table/types';

export const SET_REQ_STATUSES = 'SET_REQ_STATUSES';
export const SET_REQ_PRIORITIES = 'SET_REQ_PRIORITIES';
export const SET_REQ_ORDERS = 'SET_REQ_ORDERS';
export const SET_REQ_ORDER = 'SET_REQ_ORDER';
export const SET_REQ_ORDER_LAST_READ = 'SET_REQ_ORDER_LAST_READ';
export const SET_REFERRAL_RESULTS = 'SET_REFERRAL_RESULTS';
export const SET_REFERRAL_RESULTS_LOG = 'SET_REFERRAL_RESULTS_LOG';
export const SET_REFERRAL_RESULT = 'SET_REFERRAL_RESULT';
export const SET_REQ_ORDERS_DASHBOARD = 'SET_REQ_ORDERS_DASHBOARD';

export const setSort = (payload: ISort, patientId: string) => {
  store.dispatch({
    type: actionsTypes.SET_SORT,
    payload,
    patientId
  });
};

export const setReferralChanges = (payload: any, patientId: string) => {
  store.dispatch({
    type: actionsTypes.CHANGE_REFERRAL,
    payload,
    patientId
  });
};

export const setReqStatuses = (data: any) => {
  return store.dispatch({
    type: SET_REQ_STATUSES,
    payload: data
  });
};

export const setReqPriorities = (data: any) => {
  return store.dispatch({
    type: SET_REQ_PRIORITIES,
    payload: data
  });
};

export const setReqOrders = (patientId: number, data: any) => {
  return store.dispatch({
    type: SET_REQ_ORDERS,
    patientId,
    payload: data
  });
};

export const setReqOrder = (
  patientId: number,
  reqOrderId: number,
  data: any
) => {
  return store.dispatch({
    type: SET_REQ_ORDER,
    patientId,
    reqOrderId,
    payload: data
  });
};

export const setReqOrderLog = (
  patientId: number,
  reqOrderId: number,
  data: any
) => {
  return store.dispatch({
    type: SET_REFERRAL_RESULTS_LOG,
    patientId,
    reqOrderId,
    payload: data
  });
};

export const setReqOrderLastReadLog = (
  patientId: number,
  reqOrderId: number,
  data: any
) => {
  return store.dispatch({
    type: SET_REQ_ORDER_LAST_READ,
    patientId,
    reqOrderId,
    payload: data
  });
};

export const setReferralResults = (
  reqOrderId: number,
  payload: any,
  patientId?: string
) => {
  return store.dispatch({
    type: SET_REFERRAL_RESULTS,
    reqOrderId,
    payload,
    patientId
  });
};

export const setReferralResult = (reqOrderId: number, data: any) => {
  return store.dispatch({
    type: SET_REFERRAL_RESULT,
    reqOrderId,
    payload: data
  });
};

export const getReqStatuses = async () => {
  try {
    const response = await APIHandler.AxiosInstance.get(ApiUrl.REQ_STATUS, {
      withCredentials: true,
      params: {}
    });
    if (response.data.result) {
      const data = response.data.result.summary;
      setReqStatuses(data);
      return data;
    }
  } catch (error) {
    console.log(error);
    return;
  }
};

export const getReqPriorities = () => {
  APIHandler.AxiosInstance.get(ApiUrl.REQ_TYPE, {
    withCredentials: true,
    params: {}
  })
    .then((response) => {
      if (response.data.result) {
        setReqPriorities(response.data.result.summary);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getReqOrderItem = async (patientId: any, id: number) => {
  try {
    const response = await APIHandler.AxiosInstance.get(ApiUrl.REQ_ORDER, {
      withCredentials: true,
      params: {
        patientId,
        id
      }
    });
    if (response.data.success) {
      const data = response.data.result.summary[id];
      // const data = Object.values(response.data.result.summary)[0];
      return data;
    }
    return;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const getReqOrders = async (
  patientId?: any,
  reqOrderId?: any,
  clinicId?: any,
  typeReqPriorityIds?: number[],
  typeTxnClassIds?: number[],
  typeReqStatusIds?: number[]
) => {
  try {
    const renderingProviderIds =
      store.getState().dashboard.filters.supervisonProvidersIds;
    const response = await APIHandler.AxiosInstance.get(ApiUrl.REQ_ORDER, {
      withCredentials: true,
      params: {
        patientId,
        id: reqOrderId,
        clinicId,
        typeReqPriorityIds,
        typeTxnClassIds,
        typeReqStatusIds,
        renderingProviderIds
      }
    });
    if (response.data.result && response.data.result.summary) {
      if (patientId) {
        setReqOrders(patientId, response.data.result.summary);
      } else {
        setReqOrdersDashboard(_.values(response.data.result.summary));
      }
    } else {
      if (patientId) {
        setReqOrders(patientId, {});
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateReqOrder = async (
  patientId: number,
  reqOrderId: number,
  data: Partial<IReferral>
) => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.REQ_ORDER}/${reqOrderId}`,
      data,
      {
        withCredentials: true
      }
    );
    if (response.data.result) {
      setReqOrder(patientId, reqOrderId, response.data.result);
      return response.data.result;
    }
    return;
  } catch (error) {
    console.log(error);
  }
};

export const updateReqOrderReadLog = (
  patientId: number,
  reqOrderId: number,
  read: boolean
) => {
  APIHandler.AxiosInstance.post(
    `${ApiUrl.REQ_ORDER}/${reqOrderId}/readLog`,
    {
      dts: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
      isRead: read
    },
    {
      withCredentials: true
    }
  )
    .then((response) => {
      if (response.data.result) {
        setReqOrderLastReadLog(patientId, reqOrderId, response.data.result);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getReferralResults = async (reqOrderId?: any) => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.REFERRAL_RESULTS,
      {
        withCredentials: true,
        params: {
          reqOrderId: reqOrderId
        }
      }
    );
    if (response.data.result) {
      const data = response.data.result.summary;
      setReferralResults(reqOrderId, response.data.result.summary);
      return data;
    }
    return;
  } catch (error) {
    console.log({ error });
    return;
  }
};

export const createReferralResult = async (
  data: {
    comment: string;
    dts: string;
    isRead: boolean;
    reqOrderId: number;
    userId: number;
  },
  subjectId: string
) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.REFERRAL_RESULTS,
      data,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const values = Object.values(response.data.result.summary);
      values.forEach((value: any) => {
        setReferralResults(value.id, value.referralResults, subjectId);
        setReferralChanges(value, subjectId);
      });
      return values;
    }
    return;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const updateReferralResultRead = (
  resultId: number,
  read: boolean,
  reqOrderId: number | null
) => {
  APIHandler.AxiosInstance.patch(
    `${ApiUrl.REFERRAL_RESULTS}/${resultId}`,
    {
      isRead: read,
      dts: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
    },
    {
      withCredentials: true
    }
  )
    .then((response) => {
      if (response.data.result) {
        getReferralResults(reqOrderId);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getReferralResultsLog = (
  patientId: number,
  reqOrderId: number
) => {
  APIHandler.AxiosInstance.get(ApiUrl.REFERRAL_RESULTS_LOG, {
    withCredentials: true,
    params: {
      reqOrderId: reqOrderId
    }
  })
    .then((response) => {
      if (response.data.result) {
        setReqOrderLog(patientId, reqOrderId, response.data.result.summary);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createReferral = async (data: any) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.REQ_ORDER,
      data,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data.result) {
      const referral: any = Object.values(response.data.result.summary)[0];
      setReqOrder(data.patientId, referral.id, referral);
      return referral;
    }
  } catch (error) {
    console.log(error);
  }
};
