import React, { FC, useState, useRef, useCallback } from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import Popover from 'src/Framework/Controls/Popover';

import { IIcon } from '../ButtonIcon';

import Content from './Content';

export interface IProps {
  files: Array<any>;
  buttonSize?: number;
  children?: React.ReactChild;
  buttonProps?: Partial<IIcon>;
  patientId?: string | number;
  traineeId?: string | number;
  useIds?: boolean;
  fileIds?: number[];
}

const Component: FC<IProps> = ({
  files,
  buttonSize,
  buttonProps,
  children,
  patientId,
  traineeId,
  useIds,
  fileIds
}: IProps) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<React.MutableRefObject<any>>(null);
  const popupRef = useRef(null);
  const firstItemRef = useRef<React.MutableRefObject<any>>(null);
  const style = {
    ...buttonProps?.containerProps?.style
  };

  const onButtonKeyDown = (e: React.KeyboardEvent) => {
    if (
      (e.key === 'Tab' && e.shiftKey && open) ||
      (e.key === 'Escape' && open)
    ) {
      e.preventDefault();
      e.stopPropagation();
      setOpen(false);
    }
    if (e.key === 'Tab' && !e.shiftKey && open) {
      e.preventDefault();
      if (popupRef.current) {
        //@ts-ignore
        popupRef.current.focus();
      }
    }
    if (
      e.key === 'Enter' ||
      (e.code === 'Space' && (e.target as HTMLElement).tagName === 'DIV')
    ) {
      if (e.currentTarget) {
        // @ts-ignore
        e.currentTarget.click();
      }
    }
  };

  const onWrapperKeyDown = (e: React.KeyboardEvent) => {
    if (
      (e.key === 'Tab' && e.shiftKey && e.target === e.currentTarget) ||
      e.key === 'Escape'
    ) {
      e.preventDefault();
      e.stopPropagation();
      if (buttonRef.current) {
        //@ts-ignore
        buttonRef.current.focus();
      }
    }
  };

  const closePopup = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Popover
      id="popover"
      title={null}
      trigger="click"
      open={open}
      removePaddings={true}
      overlayClassName="outreact-notes-popup"
      globalOverlay={true}
      destroyTooltipOnHide={false}
      onOpenChange={(open) => setOpen(open)}
      content={
        <Content
          popupRef={popupRef}
          files={files}
          useIds={useIds}
          fileIds={fileIds}
          traineeId={traineeId}
          buttonRef={buttonRef}
          firstItemRef={firstItemRef}
          patientId={patientId}
          onWrapperKeyDown={onWrapperKeyDown}
          closePopup={closePopup}
        />
      }
    >
      {children ? (
        children
      ) : (
        <ButtonIcon
          isButton={true}
          label="attachments"
          haspopup="listbox"
          controlsId="attachments-popover"
          size={buttonSize}
          tooltip={
            useIds
              ? `(${(fileIds || []).length}) files`
              : files.length === 1
              ? files[0].originalFileName
                ? files[0].originalFileName
                : files[0].fileName
              : `(${files.length}) files`
          }
          {...buttonProps}
          onClick={() => {
            setOpen((open) => !open);
          }}
          containerProps={{
            style,
            ref: buttonRef,
            onKeyDown: (e: React.KeyboardEvent) => onButtonKeyDown(e)
          }}
          name={ICONS_LIST.attachment}
        />
      )}
    </Popover>
  );
};

Component.defaultProps = {
  buttonSize: 32,
  buttonProps: {}
};

export default React.memo(Component);
