import axios from 'axios'

import { APIHandler, configData } from 'src/Framework/Communication/ServerProxy';
import { ManagementApiUrl } from 'src/ManagementSystem/common/ManagementApiUrl';

import * as AccessToken from 'src/App/LoginPage/store/AccessToken'

export const clearingData = () => {
    localStorage.clear();
    delete APIHandler.AxiosInstance.defaults.headers.Authorization;
}

export const login = async (data: {
    username: string
    password: string
}): Promise<any> => {
    try {
        clearingData()
        const response = await APIHandler.AxiosInstance.post(
            `${ManagementApiUrl.Auth}/login`,
            data,
            { withCredentials: true }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result
                AccessToken.set(data)
                return data
            }
        }
        return;
    } catch (e) {
        return;
    }
};

export const refreshToken = async ({ refreshToken }: { refreshToken: string }) => {
    try {
        const response = await axios.post(
            `${configData?.baseUrl}Auth/revoke-token`,
            {},
            {
                withCredentials: true, params: {
                    requestedToken: refreshToken
                }
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result
                AccessToken.set(data)
                return data
            }
        }
        return;
    } catch (e) {
        return;
    }
}