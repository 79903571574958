import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

interface ISpinner {
  size?: number;
  border?: number;
  color?: string
}

export const Spinner = styled.div<ISpinner>`
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  ${({ size }) => {
    const a = size || 24;
    return `
            height: ${a}px;
            width: ${a}px;
        `;
  }}
  color: transparent;
  position: relative;
  display: inline-block;
  border: ${({ border }) => border || 2}px solid;
  border-radius: 50%;
  border-right-color: ${({ color }) => color || globalStyleVariables.newBrandingPrimary200};
  animation: rotate 1s linear infinite;
`;
