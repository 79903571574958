import React, { useImperativeHandle, useState } from 'react';
import { Button } from 'antd';
import _ from 'lodash';

import Modal from 'src/Framework/Controls/Modal';
import SelectContainer from 'src/Framework/Controls/SelectContainer';

import * as formActions from 'src/Activities/Forms/FormsActions';
import { ILegacyForm } from 'src/Activities/Forms/Forms';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import { ModalContainer } from './styled';

interface IProps {
  currentForm: ILegacyForm;
  onAdded: (value: any) => any;
  appends: any[];
}

export interface IModalHandles {
  show(obj: any): void;

  close(): void;
}

interface IData {
  appendText: string;
}

const defaultData: IData = {
  appendText: ''
};

const getInitData = () => ({
  ..._.cloneDeep(defaultData)
});

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { currentForm, onAdded, appends },
  ref
) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IData>(getInitData());
  useModalFocus({ active: visible });
  const show = async ({}: any) => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setData(getInitData());
  };

  useImperativeHandle(ref, () => ({
    show: (obj: any) => show(obj),
    close
  }));

  const apply = async () => {
    setLoading(true);
    const res: any = await formActions.postAppendForm(
      currentForm.seedId,
      data.appendText
    );
    if (res) {
      onAdded(res);
      close();
    }
    setLoading(false);
  };

  const onChangeData = (obj: Partial<IData>) => {
    setData({
      ...data,
      ...obj
    });
  };

  const checkDisabled = () => {
    return false;
  };

  const title = `Append ${currentForm.summary}`;
  const defaultWidth = 750;
  const headerRight = (
    <Button
      disabled={checkDisabled()}
      loading={loading}
      onClick={apply}
      shape="round"
    >
      Save
    </Button>
  );

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title
      }}
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      afterClose={setToDefault}
      headerBorder={true}
      buttons={headerRight}
    >
      <ModalContainer className="custom-checkbox">
        <SelectContainer
          type="area"
          label="Append"
          star={false}
          value={data.appendText}
          onChange={(appendText: string) => {
            onChangeData({ appendText });
          }}
          bottomMargin={false}
          areaAutoReplace={true}
          inputProps={{
            rows: 6
          }}
        />
      </ModalContainer>
    </Modal>
  );
};

export default React.forwardRef(Component);
