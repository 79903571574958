import React, { useImperativeHandle, useRef, useState } from 'react';
import { Row, Col } from 'antd';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import RemoveButton from 'src/Framework/Controls/Table/RemoveButton';
import Spinner from 'src/Framework/Controls/AbsoluteLoader/Spinner';
import AlertPopUp, {
  IModalHandles as IAlertPopUp
} from 'src/Framework/Controls/AlertPopUp';
import AppErrorBoundary from 'src/Framework/Errors/AppErrorBoundary';
import test_ids from 'src/tests-script/pages/Subject/Forms';

import {
  IM1FormRecord,
  M1FormRecordStatus
} from 'src/App/M1FormRecords/store/types';
import Summary, { IM1FormSummary } from 'src/Activities/Forms/Summary';
import Append from './Append';
import Print from './Print';
import { IPatchM1Record } from '..';
import { usePermission } from 'src/App/User/Permissions/Hook';
import CreateSecureMessageButton from 'src/App/SecureMessages/Components/CreateSecureMessageButton';

import { ButtonContainer, ActionBarContainer, VerticalLine } from './styled';

interface IProps {
  onClickShowPreview: any;
  formSelected: IM1FormRecord;
  printRef?: any;
  subjectId: string;
  onRemove: () => any;
  onClickSave: () => any;
  containerClassname?: string;
  verticalLineHeight?: number;
  preview?: boolean;
  isNotValid?: boolean;
  PatchRequest: IPatchM1Record;
  schema: any;
  formSubmission: React.MutableRefObject<any>;
  isViewerMode?: boolean;
  color?: string;
  hideRemoveButton?: boolean;
}

export interface IModalHandles {
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormListActionBar: React.ForwardRefRenderFunction<
  IModalHandles,
  IProps
> = (props, ref) => {
  const [saving, setSaving] = useState(false);
  const AlertPopUpRef = useRef<IAlertPopUp>(null);
  const {
    formSelected,
    onClickShowPreview,
    // onClickSave,
    containerClassname,
    verticalLineHeight,
    preview,
    isNotValid,
    subjectId,
    onRemove,
    PatchRequest,
    schema,
    formSubmission,
    isViewerMode,
    color,
    hideRemoveButton
  } = props;
  useImperativeHandle(ref, () => ({
    setSaving
  }));
  const notDeleted = formSelected.isActive !== false;
  const deleteFormPermission = usePermission({
    anyClinic: false,
    clinicId: formSelected.clinicId,
    moduleId: 40,
    functionId: 310
  });

  return (
    <AppErrorBoundary>
      <AlertPopUp ref={AlertPopUpRef} />
      <ActionBarContainer className={containerClassname} saving={saving}>
        {saving && (
          <ButtonContainer>
            <Row align="middle" gutter={8}>
              <Col>
                <Spinner />
              </Col>
              <Col>Auto Saving...</Col>
            </Row>
          </ButtonContainer>
        )}
        {/* <ButtonContainer>
              <Button
                id="save-button"
                disabled={formSelected.status !== M1FormRecordStatus.InProgress}
                shape="round"
                onClick={async () => await onClickSave()}
              >
                Save
              </Button>
            </ButtonContainer> */}
        <ButtonContainer>
          <Summary currentForm={formSelected as unknown as IM1FormSummary} />
        </ButtonContainer>
        {formSelected.status === 'locked' && (
          <ButtonContainer>
            <CreateSecureMessageButton
              patientId={subjectId}
              tooltip="Send the form as PDF in a secure message"
              params={{
                formId: formSelected.id,
                formType: 'm1'
              }}
            />
          </ButtonContainer>
        )}
        <ButtonContainer>
          <Print
            form={formSelected}
            schema={schema}
            formSubmission={formSubmission}
            color={color}
          />
        </ButtonContainer>
        {notDeleted && !isViewerMode && (
          <ButtonContainer>
            <Append
              readOnly={
                formSelected.status === M1FormRecordStatus.Locked ||
                formSelected.status === M1FormRecordStatus.Signed
              }
              currentForm={formSelected}
            />
          </ButtonContainer>
        )}

        <ButtonContainer>
          <ButtonIcon
            isButton={true}
            id={test_ids.formsPreviewButton}
            label="Form Preview"
            toggled={preview}
            color={color}
            changeColorOnHover={true}
            name={ICONS_LIST.eye2}
            active={preview}
            tooltip="Form Preview"
            onClick={() => onClickShowPreview()}
          />
        </ButtonContainer>
        <ButtonContainer>
          {/* <ButtonIcon
                changeColorOnHover={true}
                active={formSelected.isRestricted}
                iconScale={1.6}
                name={ICONS_LIST.restricted}
                tooltip="Restrict Access"
                asyncOnClick={async () => {
                  await formListActions.patchPatientForms(
                    {
                      seedId: formSelected.seedId,
                      isRestricted: !formSelected.isRestricted
                    },
                    patientId
                  );
                }}
              /> */}
        </ButtonContainer>
        {/* <PreviewPrinter formId={props.formSelected.seedId} /> */}
        {!isViewerMode && (
          <>
            {notDeleted && !hideRemoveButton && (
              <>
                <ButtonContainer>
                  <RemoveButton
                    id={test_ids.formsDeleteButton}
                    buttonIconProps={{
                      disabled: !deleteFormPermission.success,
                      tooltip: !deleteFormPermission.success
                        ? deleteFormPermission.message
                        : ''
                    }}
                    removeAction={async () => {
                      AlertPopUpRef.current?.show({
                        content:
                          'Are you sure you would like to remove this form?',
                        action: 'delete',
                        closeAfterConfirm: false,
                        onConfirm: async () => {
                          AlertPopUpRef.current?.show({
                            loading: true
                          });
                          const res = await PatchRequest(
                            {
                              isActive: false,
                              id: formSelected.id
                            },
                            subjectId
                          );
                          if (res?.success) {
                            onRemove();
                          }
                          AlertPopUpRef.current?.close();
                        }
                      });
                    }}
                  />
                </ButtonContainer>
              </>
            )}
            <VerticalLine style={{ height: verticalLineHeight || 32 }} />
            {notDeleted && (
              <>
                <ButtonContainer>
                  <ButtonIcon
                    isButton={true}
                    label="Sign"
                    toggled={formSelected.status === M1FormRecordStatus.Signed}
                    changeColorOnHover={true}
                    disabled={
                      isNotValid ||
                      formSelected.status === M1FormRecordStatus.Locked
                    }
                    active={formSelected.status === M1FormRecordStatus.Signed}
                    name={ICONS_LIST.sign}
                    tooltip="Sign"
                    asyncOnClick={async () => {
                      if (
                        formSelected.status === M1FormRecordStatus.InProgress ||
                        formSelected.status === M1FormRecordStatus.Signed
                      ) {
                        await PatchRequest(
                          {
                            id: formSelected.id,
                            status: M1FormRecordStatus.Signed
                          },
                          subjectId
                        );
                      }
                    }}
                  />
                </ButtonContainer>
                <ButtonContainer>
                  <ButtonIcon
                    isButton={true}
                    label="Lock Form"
                    toggled={formSelected.status === M1FormRecordStatus.Locked}
                    disabled={
                      isNotValid ||
                      formSelected.status === M1FormRecordStatus.Locked
                    }
                    changeColorOnHover={true}
                    active={formSelected.status === M1FormRecordStatus.Locked}
                    name={ICONS_LIST.lock}
                    tooltip="Lock Form"
                    asyncOnClick={async () => {
                      await PatchRequest(
                        {
                          id: formSelected.id,
                          status: M1FormRecordStatus.Locked
                        },
                        subjectId
                      );
                    }}
                  />
                </ButtonContainer>
              </>
            )}
          </>
        )}
      </ActionBarContainer>
    </AppErrorBoundary>
  );
};
export default React.forwardRef(FormListActionBar);
