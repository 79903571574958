import _ from 'lodash';
import {
  IResultCodesReducer,
  IAction
} from 'src/App/Admin/Pages/OrdersManager/ResultCodes/store/types';

const prefix = 'RESULT_CODES_';

export const actionsTypes = {
  SET_SUMMARY: `${prefix}SET_SUMMARY`
} as const;

type IActions = Record<
  typeof actionsTypes[keyof typeof actionsTypes],
  () => IResultCodesReducer
>;

export const defaultData: IResultCodesReducer = {
  summary: {}
};

const Reducer = (
  state: IResultCodesReducer = _.cloneDeep(defaultData),
  action: IAction
): IResultCodesReducer => {
  const actions: IActions = {
    [actionsTypes.SET_SUMMARY]: () => {
      return {
        ...state,
        summary: action.payload
      };
    }
  };
  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};

export default Reducer;
