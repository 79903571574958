import _ from 'lodash'
import { actionsTypes } from "./Actions";

interface IStateReducer {
    [patientId: string]: IPatientAttachments
}

export interface IPatientAttachments {
    attachments: Array<IFileDefinition>
    sort: {
        sortBy: string | null
        sortType: "desc" | "asc" | null
    }
}

interface IAttachmentsFile {
    id: number,
    dts: string;
    userId: number,
    contentLength: number,
    contentType: string,
    fileName: string,
    originalFileName: string | null,
    noteIds: number[],
    isDashboardShown: boolean
}

export interface IFileDefinition {
    category: string,
    file: IAttachmentsFile;
    title: string;
    noteId: null | number;
    index?: number
}

interface IAction {
    type: string;
    payload: any;
    patientId: string;
}


export const initialAttachments = {
    attachments: [],
    sort: {
        sortBy: null,
        sortType: null
    }
}

const initialState: IStateReducer = {};

const Reducer = (state: IStateReducer = _.cloneDeep(initialState), action: IAction): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET_PATIENT_ATTACHMENTS_SORT:
            return {
                ...state,
                [action.patientId]: {
                    ...state[action.patientId],
                    sort: action.payload
                }
            }
        case actionsTypes.SET_PATIENT_ATTACHMENTS:
            if (state[action.patientId]) {
                return {
                    ...state,
                    [action.patientId]: {
                        ...state[action.patientId],
                        attachments: action.payload
                    }
                }
            }
            return {
                ...state,
                [action.patientId]: {
                    ..._.cloneDeep(initialAttachments),
                    attachments: action.payload
                }
            }
        default:
            return state;
    }
};

export default Reducer;
