import React from 'react';
import _ from 'lodash';
import {
  Attachment,
  Attachments,
  SquaredContainer,
  DeleteIcon
} from './styled';
import { RoundedClipSvg } from 'src/Framework/Common/Svg/attachments/RoundedClipSvg';
import { SquaredJpg } from 'src/Framework/Common/Svg/attachments/SquaredJpg';
import { SquaredPdf } from 'src/Framework/Common/Svg/attachments/SquaredPdf';
import { SquaredCsv } from 'src/Framework/Common/Svg/attachments/SquaredCsv';
import { SquaredAny } from 'src/Framework/Common/Svg/attachments/SquaredAny';
import Tooltip from 'antd/lib/tooltip';

import AttachmentsPopoverButton, {
  IProps as IPopoverProps
} from '../AttachmentsPopoverButton';
import { Alert } from '../../Common/Notification';
import { downloadAttachment } from 'src/Activities/Counseling/Dashboard/Attachments/store/Actions';

interface IFileDownloader {
  files: IFile[];
  type: string;
  withSizeLabel: boolean;
  withNameLabel: boolean;
  tooltip: boolean;
  tooltipPosition: string;
  download: boolean;
  deletable: boolean;
  setFiles: (files: IFile[]) => void;
  iconStyles: any;
  iconContainerStyles: any;
  containerStyles: any;
  // Props for popover
  popoverEnable?: boolean;
  popoverProps?: Partial<IPopoverProps>;
  onChangeEventFiles?: Function;
  id?: string;
  patientId?: string | number;
  traineeId?: string | number;
  useIds?: boolean;
  fileIds?: number[];
}

export interface IFile {
  id?: number;
  size?: number;
  name?: string;
  originalFileName?: string;
  contentType?: string;
  type?: string;
  deletable?: boolean;
}

const formatBytes = (bytes: number) => {
  //const decimals = 0;
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  //const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return Math.trunc(bytes / Math.pow(k, i)) + ' ' + sizes[i];
};

export const catchDocumentUrls = {};

export const getDocumentLink = async (file: any) => {
  try {
    const key = `${file.id}-${file.crc}`;
    if (catchDocumentUrls[key]) {
      return catchDocumentUrls[key];
    }
    const response = await downloadAttachment(file.id);
    if (response) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.data.type })
      );
      catchDocumentUrls[key] = url;
      return url;
    }
    return;
  } catch (error) {
    console.log(error);
    return;
  } finally {
  }
};

interface IFileDownload {
  id?: number | string;
  originalFileName?: string;
  fileName?: string;
  name?: string;
}

export const handleDownload = async (file: IFileDownload) => {
  try {
    if (!file.id) return;
    const response = await downloadAttachment(file.id);
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.setAttribute(
      'download',
      file.originalFileName || file.fileName || file.name || ''
    );
    link.href = url;
    link.click();
    window.URL.revokeObjectURL(url);
    return response;
  } catch (error) {
    console.log(error);
    return;
  } finally {
  }
};

export const handleOpenNewTabFiles = async (files: any) => {
  const urlsArray: string[] = [];
  try {
    console.log(files);
    await Promise.all(
      _.values(files).map(async (file) => {
        try {
          const response = await downloadAttachment(file.id);
          const url: string = window.URL.createObjectURL(response.data);
          urlsArray.push(url);
        } catch (error) {
          console.log('error' + error);
          Alert('error', 'Error generating file url', '');
        }
      })
    );
    urlsArray.forEach((url) => {
      if (window && url) {
        // @ts-ignore
        window.open(url, '_blank').focus();
      }
    });
  } catch (error) {
    console.log(error);
    Alert('error', 'Error while opening files', '');
  }
  return urlsArray;
};

const getFileIcon = (type: string | null, styles: any) => {
  switch (type) {
    case 'image/jpg':
      return (
        <SquaredJpg
          style={{
            ...styles,
            display: 'block',
            margin: 'auto',
            height: '100%'
          }}
        />
      );
    case '.jpg':
      return (
        <SquaredJpg
          style={{
            ...styles,
            display: 'block',
            margin: 'auto',
            height: '100%'
          }}
        />
      );
    case 'application/pdf':
      return (
        <SquaredPdf
          style={{
            ...styles,
            display: 'block',
            margin: 'auto',
            height: '100%'
          }}
        />
      );
    case '.pdf':
      return (
        <SquaredPdf
          style={{
            ...styles,
            display: 'block',
            margin: 'auto',
            height: '100%'
          }}
        />
      );
    case 'text/csv':
      return (
        <SquaredCsv
          style={{
            ...styles,
            display: 'block',
            margin: 'auto',
            height: '100%'
          }}
        />
      );
    case '.csv':
      return (
        <SquaredCsv
          style={{
            ...styles,
            display: 'block',
            margin: 'auto',
            height: '100%'
          }}
        />
      );
    default:
      return (
        <SquaredAny
          style={{
            ...styles,
            display: 'block',
            margin: 'auto',
            height: '100%'
          }}
        />
      );
  }
};

const FilesDownloader = (props: IFileDownloader) => {
  const {
    type,
    iconStyles,
    withNameLabel,
    withSizeLabel,
    files,
    popoverProps,
    popoverEnable,
    onChangeEventFiles,
    id,
    patientId,
    traineeId,
    useIds,
    fileIds
  } = props;

  const getTypeVisualization = (file: IFile) => {
    switch (type) {
      case 'rounded':
        return (
          <>
            <div>
              <RoundedClipSvg style={iconStyles || {}} />
            </div>
            {file.name && withNameLabel && (
              <div className="label">{file.name}</div>
            )}
            {file.size && withSizeLabel && (
              <div className="label">{formatBytes(file.size)}</div>
            )}
          </>
        );
      case 'squared':
        return (
          <>
            <SquaredContainer>
              {getFileIcon(file.type ? file.type : null, iconStyles)}
            </SquaredContainer>
            {file.name && props.withNameLabel && (
              <div className="label">{file.name}</div>
            )}
            {file.size && withSizeLabel && (
              <div className="label">{formatBytes(file.size)}</div>
            )}
          </>
        );
      default:
        return (
          <>
            <div>{file.name}</div>
            {file.size && withSizeLabel && (
              <div className="label">{formatBytes(file.size)}</div>
            )}
          </>
        );
    }
  };

  const handleDelete = (deletedFile: IFile, index: number) => {
    const newFiles: IFile[] = files.filter(
      (file: IFile) => !_.isEqual(file, deletedFile)
    );
    props.setFiles(newFiles);
    if (onChangeEventFiles) {
      onChangeEventFiles(index);
    }
  };
  return (
    <Attachments style={props.containerStyles}>
      {popoverEnable ? (
        <AttachmentsPopoverButton
          files={files}
          {...popoverProps}
          useIds={useIds}
          fileIds={fileIds}
          patientId={patientId}
          traineeId={traineeId}
        />
      ) : (
        files.map((file: IFile, index: number) => {
          const Component = () => (
            <Attachment
              style={props.iconContainerStyles}
              key={`attachment-${file.id}`}
              onClick={() => {
                if (props.download && file.id && file.id !== 0) {
                  handleDownload(file);
                }
              }}
            >
              {props.setFiles !== null &&
                props.deletable &&
                file.deletable !== false && (
                  <DeleteIcon
                    id={`${id}${index}-delete-file`}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleDelete(file, index);
                    }}
                  />
                )}
              {getTypeVisualization(file)}
            </Attachment>
          );
          return props.tooltip ? (
            <Tooltip
              trigger="hover"
              overlayStyle={{ zIndex: 30000 }}
              placement="top"
              title={file.name}
              key={`tooltip-${file.id}`}
            >
              <div>
                <Component />
              </div>
            </Tooltip>
          ) : (
            <Component key={`tooltip-${file.id}`} />
          );
        })
      )}
    </Attachments>
  );
};

FilesDownloader.defaultProps = {
  type: 'rounded', // can be squared or rounded
  withSizeLabel: false,
  withNameLabel: false,
  tooltip: true,
  tooltipPosition: 'top',
  download: true,
  deletable: true,
  setFiles: null,
  iconStyles: {},
  iconContainerStyles: {},
  containerStyles: {}
};
export default FilesDownloader;
