import { permissionChecking } from "src/App/User/Permission";
import store from "src/store";


export const getNoteCreationPermission = () => {
    const noteCreation = permissionChecking({
        anyClinic: true,
        moduleId: 40,
        functionId: 1205
    });
    return noteCreation
}

export const getPermissionsData = () => {
    const medicatDemo = store.getState().genericTypes.summary.medicatDemo
    const noteCreation = getNoteCreationPermission()
    const showMedical = permissionChecking({
        anyClinic: false,
        filterClinic: (clinic) => clinic.typeId === 2,
        moduleId: 40,
        functionId: 23
    })
    const showComplianceNavigation = permissionChecking({
        anyClinic: true,
        moduleId: 60,
        functionId: 23
    })
    const showPatientView = permissionChecking({
        anyClinic: true,
        functionId: 1,
        moduleId: 4
    })
    const showSecureMessage = permissionChecking({
        anyClinic: true,
        functionId: 259,
        moduleId: 40
    })
    const showEHRAccess = permissionChecking({
        anyClinic: true,
        moduleId: 40,
        functionId: 23
    })
    const showCounseling = permissionChecking({
        anyClinic: false,
        filterClinic: (clinic) => clinic.typeId === 1,
        moduleId: 40,
        functionId: 23
    })
    const showNotesOfConcern = permissionChecking({
        anyClinic: true,
        moduleId: 40,
        functionId: 1020
    })

    let PersonalDetailsAccessMessage = ''
    const HidePersonalDetailsMedical = medicatDemo.find((v) => v.key === 'PatientSection:HidePersonalDetails')
        ?.value === 'True'
    const HidePersonalDetails = HidePersonalDetailsMedical || !showPatientView.success
    if (HidePersonalDetailsMedical) {
        PersonalDetailsAccessMessage = 'Personal details hidded in medical demo'
    }
    if (!showPatientView.success) {
        PersonalDetailsAccessMessage = showPatientView.message
    }

    let MessagesAccessMessage = ''
    const HideMessagesMedical = medicatDemo.find((v) => v.key === 'PatientSection:HideMessages')?.value ===
        'True'
    const HideMessages = HideMessagesMedical || !showSecureMessage.success
    if (HideMessagesMedical) {
        MessagesAccessMessage = 'Messages hidded in medical demo'
    }
    if (!showSecureMessage.success) {
        MessagesAccessMessage = showSecureMessage.message
    }

    let DashboardAccessMessage = ''
    const HideDashboard = !showEHRAccess.success
    if (!showEHRAccess.success) {
        DashboardAccessMessage = showEHRAccess.message
    }

    const HideCounseling =
        medicatDemo.find((v) => v.key === 'PatientSection:HideCounseling')
            ?.value === 'True' || !showCounseling.success


    const HideMedical =
        medicatDemo.find((v) => v.key === 'PatientSection:HideMedical')?.value ===
        'True' || !showMedical.success


    let ComplianceAccessMessage = ''
    const ComplianceMedical = medicatDemo.find((v) => v.key === 'PatientSection:HideCompliance')
        ?.value === 'True'
    const HideCompliance = ComplianceMedical || !showComplianceNavigation.success
    if (ComplianceMedical) {
        ComplianceAccessMessage = 'Compliance hidded in medical demo'
    }
    if (!showComplianceNavigation.success) {
        ComplianceAccessMessage = showComplianceNavigation.message
    }

    let NoteOfConcernAccessMessage = ''
    if (!showNotesOfConcern.success) {
        NoteOfConcernAccessMessage = showNotesOfConcern.message
    }

    return {
        HideDashboard,
        HidePersonalDetails,
        HideMessages,
        HideCounseling,
        HideMedical,
        HideCompliance,
        showNotesOfConcern: showNotesOfConcern.success,

        PersonalDetailsAccessMessage,
        MessagesAccessMessage,
        DashboardAccessMessage,
        ComplianceAccessMessage,
        NoteOfConcernAccessMessage,
        noteCreation
    };
};