import { SharedFormStatus } from 'src/ManagementSystem/Pages/Dashboard/Components/Status/utils';
import { M1FormBuildingBlockVersionStatus } from '../../BuildingBlocks/store/types';
import { ISort } from 'src/Framework/Controls/Table/types';

export interface IPublicForm {
  id: number;
}

export interface IPublicFormsResponse {
  count: number;
  startIndex: number;
  total: number;
  summary: IPublicForm[];
}

export interface IStateReducer {
  summary: Record<string, IBuildingForm>; // Need to add interface
  loading: boolean;
  filters: IFilters;
  sort: ISort;
}

export interface IFilters {
  searchString: string;
  isInactive: boolean;
  pageNumber: number;
}

export interface IAction {
  type: string;
  payload: any;
}

export interface IBuildingForm {
  createDts: string;
  createUserId: number;
  draftVersion: number;
  latestPublishedVersion: number;
  id: number;
  isActive: boolean;
  groupTemplateFormId: number | null;
  modifyDts: string | null;
  modifyUserId: number | null;
  tags: number[];
  name: string;
  clinicIds: number[];
  isShared: null | boolean;
  isShareAvailable: boolean;
  isReadOnly: boolean;
  isImported: boolean;
  isUpdateAvailable: boolean;
  importedEntityId: number;
  sharedFormStatus: SharedFormStatus;
}

export interface IExtendedBuildingForm extends IBuildingForm {
  versions: IFormVersion[];
}

export interface IFullBuildingForm extends IExtendedBuildingForm {
  versions: IFullFormVersion[];
}

interface ISchemaComponent {
  columns?: {
    components: ISchemaComponent[];
  }[];
  type?: string;
  key?: string;
  defaultValue?: {
    data?: Record<string, any>;
  };
}

export interface IDisplaySchema {
  components: ISchemaComponent[];
}

export interface IFullFormVersion extends IFormVersion {
  designSchema: IDesignSchema | null;
  displaySchema: null | IDisplaySchema;
  footerId: number | null;
  footerVersion: number | null;
  headerId: number | null;
  headerVersion: number | null;
}

export interface IFormVersion {
  createDts: string;
  createUserId: number;
  description: string | null;
  footerId: null | number;
  footerVersion: null | number;
  formTemplateId: number;
  headerId: null | number;
  headerVersion: null | number;
  name: string | null;
  status: M1FormBuildingBlockVersionStatus;
  version: number;
}

export interface IDesignSchema {
  rows: IRow[];
}

export interface IRow {
  id: string;
  columns: ICol[];
}

export interface ICol {
  id: string;
  buildingBlockId: number | null;
  buildingBlockVersion: number | null;
}

export enum ASSIGNMENT_TAGS {
  FlowSheet = 8001,
  MentalStatusExam = 8002,
  TreatmentPlan = 8003,
  SupervisionIntern = 8004,
  Patient = 8005,
  POINT_OF_CARE = 8006,
  AllowToRouteBetweenAnyClinic = 8007,
  ShowInNotePreview
}
