import { BucketsList } from 'src/Framework/ApiSubscription';
import { RequestHelper } from 'src/Framework/ApiSubscription/RequestHelper';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

import { setSummary, onCreateUpdateItem } from './Setters';

import { SummaryReviewLog } from '../types'

export const SubscriptionData = (patientId: string | number) => ({
    bucket: BucketsList.summaryReviewLog,
    filter: `${BucketsList.summaryReviewLog}.PatientId == ${patientId}`
});

export const { post, get, patch, deleteRequest, unsubscribe } = new RequestHelper<SummaryReviewLog, [patientId: string], [], {
    data: Partial<SummaryReviewLog>[]
}>({
    link: ApiUrl.SummaryReviewLog,
    onCreateUpdateItem,
    subscription: {
        subscriptionData: SubscriptionData,
        setSummary: setSummary
    }
});
