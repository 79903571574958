export enum AccessibilityMove {
  COL = 'col',
  ROW = 'row'
}

export const onKeyDownAccessibility = (
  e: React.KeyboardEvent<HTMLDivElement>,
  onEnter?: (() => any) | undefined,
  type?: AccessibilityMove
) => {
  if (
    e.key === 'Enter' ||
    e.code === 'Space' ||
    e.key === 'ArrowRight' ||
    e.key === 'ArrowDown' ||
    e.key === 'ArrowLeft' ||
    e.key === 'ArrowUp'
  ) {
    e.preventDefault();
  }

  if (
    e.key === 'Enter' ||
    (e.code === 'Space' && (e.target as HTMLElement).tagName === 'DIV')
  ) {
    if (e.currentTarget) {
      // @ts-ignore
      e.currentTarget.click();
      if (onEnter) {
        onEnter();
      }
    }
  }

  if (!type || type === AccessibilityMove.ROW) {
    if (e.key === 'ArrowRight') {
      if (document.activeElement?.nextElementSibling) {
        // @ts-ignore
        document.activeElement.nextElementSibling.focus();
      }
    }
    if (e.key === 'ArrowLeft') {
      if (document.activeElement?.previousElementSibling) {
        // @ts-ignore
        document.activeElement.previousElementSibling.focus();
      }
    }
  }

  if (!type || type === AccessibilityMove.COL) {
    if (e.key === 'ArrowDown') {
      if (document.activeElement?.nextElementSibling) {
        // @ts-ignore
        document.activeElement.nextElementSibling.focus();
      }
    }
    if (e.key === 'ArrowUp') {
      if (document.activeElement?.previousElementSibling) {
        // @ts-ignore
        document.activeElement.previousElementSibling.focus();
      }
    }
  }
};

export const onKeyDownAccessibilityNext = (
  e: React.KeyboardEvent<HTMLDivElement>,
  onEnter?: (() => any) | undefined,
  type?: AccessibilityMove
) => {
  if (
    e.key === 'Enter' ||
    e.key === 'ArrowRight' ||
    e.key === 'ArrowDown' ||
    e.key === 'ArrowLeft' ||
    e.key === 'ArrowUp'
  ) {
    e.preventDefault();
  }
  if (e.key === 'Enter') {
    if (e.currentTarget) {
      // @ts-ignore
      e.currentTarget.click();
      if (onEnter) {
        onEnter();
      }
    }
  }

  if (!type || type === AccessibilityMove.ROW) {
    if (e.key === 'ArrowRight') {
      if (document.activeElement?.nextSibling) {
        // @ts-ignore
        document.activeElement.nextSibling.focus();
      }
    }
    if (e.key === 'ArrowLeft') {
      if (document.activeElement?.previousSibling) {
        // @ts-ignore
        document.activeElement.previousSibling.focus();
      }
    }
  }

  if (!type || type === AccessibilityMove.COL) {
    if (e.key === 'ArrowDown') {
      if (document.activeElement?.nextSibling) {
        // @ts-ignore
        document.activeElement.nextSibling.focus();
      }
    }
    if (e.key === 'ArrowUp') {
      if (document.activeElement?.previousSibling) {
        // @ts-ignore
        document.activeElement.previousSibling.focus();
      }
    }
  }
};

export const BlurToNext = () => {
  if (document.activeElement) {
    // @ts-ignore
    document.activeElement.blur();
  }
};

interface IKeys {
  Enter: (e: React.KeyboardEvent<HTMLDivElement>) => any;
  ArrowRight: (e: React.KeyboardEvent<HTMLDivElement>) => any;
  ArrowUp: (e: React.KeyboardEvent<HTMLDivElement>) => any;
  ArrowLeft: (e: React.KeyboardEvent<HTMLDivElement>) => any;
  ArrowDown: (e: React.KeyboardEvent<HTMLDivElement>) => any;
}

export const onKeyDownCallbacks = (
  e: React.KeyboardEvent<HTMLDivElement>,
  { Enter, ArrowRight, ArrowUp, ArrowLeft, ArrowDown }: Partial<IKeys>
) => {
  if (
    e.key === 'Enter' ||
    e.key === 'ArrowRight' ||
    e.key === 'ArrowDown' ||
    e.key === 'ArrowLeft' ||
    e.key === 'ArrowUp'
  ) {
    e.preventDefault();
  }
  if (e.key === 'Enter' && Enter) {
    Enter(e);
  }
  if (e.key === 'ArrowRight' && ArrowRight) {
    ArrowRight(e);
  }
  if (e.key === 'ArrowUp' && ArrowUp) {
    ArrowUp(e);
  }
  if (e.key === 'ArrowLeft' && ArrowLeft) {
    ArrowLeft(e);
  }
  if (e.key === 'ArrowDown' && ArrowDown) {
    ArrowDown(e);
  }
};

export const onNextRootSibling = () => {
  const rootElement = document.activeElement?.parentElement;
  if (rootElement) {
    rootElement.focus();
  }
};
