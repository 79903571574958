import { IDefaultSortOptions, IRenderItemData, ITableRenderItemData } from './types';

const defaultValues = {
  defaultSortBy: 'id',
  defaultSortType: 'desc'
};
export const tableListOrders = <T>(
  list: ITableRenderItemData<T>[],
  sortBy: string | null,
  sortType: 'asc' | 'desc' | null,
  options?: IDefaultSortOptions
) => {
  const defaultOptions = options || defaultValues;
  const { defaultSortBy, defaultSortType } = defaultOptions;
  const type = `${sortType || defaultSortType}`;
  const sortField = `${sortBy || defaultSortBy}`;
  const arr = [...list];
  let hasBatch = false
  const sortData = (data: ITableRenderItemData<T>[]) => {
    data.sort((a, b) => {
      const aL = a[sortField];
      const bL = b[sortField];
      if (aL === undefined && bL === undefined) {
        return 0
      }
      if (typeof aL === 'number' && typeof bL === 'number') {
        return aL - bL;
      }
      return `${aL === null ? '' : aL}`?.localeCompare(`${bL === null ? '' : bL}`);
    });
    if (type === 'desc') {
      data.reverse();
    }
  }
  arr.forEach((value) => {
    if (value.batchSettings) {
      hasBatch = true
      sortData(value.batchSettings.children)
    }
  })
  if (!hasBatch) {
    sortData(arr)
  }
  return arr;
};
export const tableSearch = <T,>({ search, dataSource, renderItemData }: {
  dataSource: T[],
  renderItemData: IRenderItemData<T>,
  search: string
}) => {
  if (search.length > 0) {
    const searchValue = search.toLowerCase()
    return dataSource.filter((v, i) => renderItemData(v, i).searchFields?.some(val => val.toLowerCase().includes(searchValue)))
  }
  return dataSource
}