import _ from 'lodash';
import { IStateReducer, IAction, TypeRadio, TypeModes, TypeGroupBy } from './types';

const prefix = 'ACTIVITY_SUPERVISION_';

export const actionsTypes = {
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_SORT: `${prefix}SET_SORT`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

export const defaultData: IStateReducer = {
    filter: {
        providersTrainees: [],
        providersSupervisors: [],
        radio: TypeRadio.TRAINEE,
        providerTypes: [],
        mode: TypeModes.GOALS,
        groupBy: TypeGroupBy.NONE
    },
    sort: {
        sortBy: null,
        sortType: null
    }
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const actions: IActions = {
        [actionsTypes.SET_SORT]: () => {
            return {
                ...state,
                sort: action.payload
            };
        },
        [actionsTypes.SET_FILTER]: () => {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                }
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
