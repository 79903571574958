import globalStyleVariables from "src/Framework/Styles/variables.module.scss";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex:1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${globalStyleVariables.newBrandingPrimary300};
`

export const LoginContainer = styled.div`
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 4px 56px 0px rgba(0, 0, 0, 0.08);
    border-top: 4px solid ${globalStyleVariables.newBrandingPrimary300};
    width: 360px;
    z-index: 2;
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    border-bottom: 1px solid ${globalStyleVariables.borderColor};
    font-family: Encode Sans Expanded;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
`

export const Body = styled.div`
    padding: 20px;
`

export const TitleContainer = styled.div`
    padding: 0 0 60px 0;
`

export const Title = styled.div`
   text-align: center;
    font-family: Encode Sans Expanded;
    font-size: 52px;
    font-style: normal;
    font-weight: 800;
    line-height: 42.5px;
    color: white;
`

export const SubTitle = styled.div`
    font-family: Encode Sans Expanded;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    color: white;
`

export const AbsoluteLogo1 = styled.div`
    position: absolute;
    left: 5px;
    bottom: -5px;
    z-index: 1;
    img {
        width: 60vw;
    }
`

export const AbsoluteLogo2 = styled.div`
    position: absolute;
    left: 80vw;
    top: -8vh;
    z-index: 1;
    img {
        width: 60vw;
    }
`

export const AbsoluteIcon = styled.div`
    position: absolute;
    left: 56vw;
    top: 65vh;
    z-index: 1;
`

interface IIcon {
    icon: any
}

export const Icon = styled.div<IIcon>`
  mask-size: contain;
  mask-position: center center;
  mask-repeat: no-repeat no-repeat;
  background-color: rgba(29, 55, 95, 1);
  width: 150px;
  height: 150px;;
  mask-image: url(${(props) => props.icon});
  -webkit-mask-image: url(${(props) => props.icon});
`;