import React from 'react';
import { Tooltip } from 'antd';

import MandatoryStar from './MandatoryStar';

import { Container, Label, Title } from './styled';

export interface IProps {
  name: string | React.ReactNode;
  star?: boolean;
  helpTooltip?: string;
  labelInRow?: boolean;
  labelClassName?: string;
}

const ElementTitle: React.FC<IProps> = ({
  name,
  star,
  helpTooltip,
  labelInRow,
  labelClassName
}: IProps) => {
  return (
    <Container>
      <Title className={labelClassName}>
        {star && <MandatoryStar />}
        <Label labelInRow={labelInRow}>{name}</Label>
      </Title>
      {helpTooltip && (
        <Tooltip
          placement="top"
          title={helpTooltip}
          overlayClassName="select-help-tooltip"
        >
          <img
            alt="help tooltip for selector"
            className="help-icon"
            src={require('src/Framework/Common/Svg/help.svg').default}
          />
        </Tooltip>
      )}
    </Container>
  );
};

export default ElementTitle;
