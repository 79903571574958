import * as actionType from "../../RuleActions/ActionTypes";
import { IRuleTemplate } from "../../Models/RuleTemplate";
import _ from "lodash";

const initialState: IRuleTemplate[] = [];
interface IAction {
  type: string;
  value: any;
}

const RuleTemplateReducer = (
  state: IRuleTemplate[] = initialState,
  action: IAction
) => {
  switch (action.type) {
    case actionType.ADD_RULE_TEMPLATE: {
      let newState = _.cloneDeep(state);
      newState = action.value
      return newState;
    }
    default:
      return state;
  }
};

export default RuleTemplateReducer;
