import store from 'src/store';
import {
  AuthenticationType,
  BoolString,
  IAppointmentsValuePairs,
  IAuthenticationValuePairs,
  IDocumentIploadKeyValuePairs,
  IEducationKeyValuePairs,
  IFormsKeyValuePairs,
  IImmunizationKeyValuePairs,
  IInsuranceKeyValuePairs,
  ILabResultKeyValuePairs,
  IMainValuePairs,
  ISecureMessageValuePairs,
  IStatementsKeyValuePairs,
  IStatusKeyValuePairs,
  IVisitPublishKeyValuePairs,
  PortalOptionData,
  PortalOptionGroup
} from './types';

export const defaultKeyValuePairs: {
  [key in PortalOptionGroup]?: () => PortalOptionData[key];
} = {
  [PortalOptionGroup.Authentication]: (): IAuthenticationValuePairs => ({
    allowNewPatient: BoolString.False,
    allowParentsToLogin: BoolString.False,
    allowReregistration: BoolString.False,
    alreadyRegisteredMessage: '',
    authenticationType: AuthenticationType.PatientPortal,
    casAttribute: '',
    casLoginURL: '',
    casLogoutURL: '',
    casMedicatAttribute: '',
    displaySplash: BoolString.False,
    dualLandingPageMessage: '',
    inactiveMessage: '',
    ineligibleMessage: '',
    ldapAttribute: '',
    ldapFailOver: BoolString.False,
    ldapPassword: '',
    ldapPort: '',
    ldapSearchAttribute: '',
    ldapSearchDomain: '',
    ldapSearchFilterField: '',
    ldapServerName: '',
    ldapUserName: '',
    lockAccount: BoolString.False,
    lockAccountMessage: '',
    lockAccountTimes: '',
    loginMessage: '',
    mppAuthButton: '',
    noEmailMessage: '',
    password_AllowUserNameAsPassword: BoolString.False,
    password_AllowUserNameAsPassword_FailureMessage: '',
    password_FailureMessage_ReturnComposite: BoolString.False,
    password_MinimumLength: '',
    password_MinimumLength_FailureMessage: '',
    password_RequireMixedCase: BoolString.False,
    password_RequireMixedCase_FailureMessage: '',
    password_RequireMixedLettersAndNumbers: BoolString.False,
    password_RequireMixedLettersAndNumbers_FailureMessage: '',
    password_RequireSpecialCharacter: BoolString.False,
    password_RequireSpecialCharacter_FailureMessage: '',
    patientNotFoundMessage: '',
    preventInactiveLogin: BoolString.False,
    preventIneligibleLogin: BoolString.False,
    registrationFields: '',
    registrationIDField: '',
    shibAttribute: '',
    shibLogoutURL: '',
    shibMedicatAttribute: '',
    ssoButton: '',
    studentIDCaption: '',
    unregisterableMessage: '',
    userNameInUse: '',
    clinicMessage: '',
    allowDualAuth: BoolString.False,
    confirmationEmail: ''
  }),
  [PortalOptionGroup.Forms]: (): IFormsKeyValuePairs => ({
    formsNotCompleted: '',
    hideNoAnswer: BoolString.False
  }),
  [PortalOptionGroup.Main]: (): IMainValuePairs => ({
    address1: '',
    address2: '',
    afterHoursPolicy: '',
    allowEditEmergencyContacts: BoolString.False,
    allowEditEthnicity: BoolString.False,
    allowEditGenderIdentity: BoolString.False,
    allowEditPreferredPronoun: BoolString.False,
    allowEditRace: BoolString.False,
    allowEditSexualOrientation: BoolString.False,
    allowEditUserInformation: BoolString.False,
    appointmentLinkName: '',
    city: '',
    clinicID: '',
    clinicMessage: '',
    clinicSpecificOptions: '',
    covid: '',
    education: BoolString.False,
    educationLinkName: '',
    errorMessage: '',
    forms: BoolString.False,
    homeMessage: '',
    immunizationLinkName: '',
    immunizations: BoolString.False,
    insurance: BoolString.False,
    insuranceLinkName: '',
    intakeForms: BoolString.False,
    isImmunitrax: BoolString.False,
    isMCS: BoolString.False,
    labResultLinkName: '',
    labResults: BoolString.False,
    logo: '',
    mapLink: '',
    messagesLinkName: '',
    messaging: BoolString.False,
    name: '',
    operatingHours: '',
    personalRecord: BoolString.False,
    phone: '',
    prescriptions: BoolString.False,
    privacyPolicy: '',
    productName: '',
    qrFax: BoolString.False,
    scheduling: BoolString.False,
    selectChildLabel: '',
    selectChildMessage: '',
    selfTestLinkName: '',
    state: '',
    statement: BoolString.False,
    statusLinkName: '',
    timeZoneOffset: '',
    toDoMessage: '',
    upload: BoolString.False,
    uploadLinkName: '',
    url: '',
    userID: '',
    validateDemographicInput: '',
    visits: BoolString.False,
    visitsLinkName: '',
    welcomeMessage: '',
    zip: '',
    statementLinkName: '',
    formsLinkName: '',
    displayOptInText: BoolString.False,
    allowCheckin: BoolString.False
  }),
  [PortalOptionGroup.DocumentUpload]: (): IDocumentIploadKeyValuePairs => ({
    displayAllDocuments: BoolString.False,
    faxCoverSheetMessage: '',
    uploadMessage: '',
    uploadsNotCompleted: '',
    immunizationDatesMessage: ''
  }),
  [PortalOptionGroup.VisitPublish]: (): IVisitPublishKeyValuePairs => ({
    includeNotes: BoolString.False,
    includeSummary: BoolString.False,
    visitsAfterDate: '',
    visitsClinicId: '',
    visitsMessage: ''
  }),
  [PortalOptionGroup.Statements]: (): IStatementsKeyValuePairs => ({
    allowOnlineBillPay: BoolString.False,
    onlySendToOnline: BoolString.False,
    showBalanceDue: BoolString.False,
    statementLinkName: '',
    statementMessage: '',
    statementType: null
  }),
  [PortalOptionGroup.Education]: (): IEducationKeyValuePairs => ({
    educationMessage: ''
  }),
  [PortalOptionGroup.LabResults]: (): ILabResultKeyValuePairs => ({
    labResultMessage: ''
  }),
  [PortalOptionGroup.Insurance]: (): IInsuranceKeyValuePairs => ({
    allowInsuranceEntry: BoolString.False,
    allowInternationalInsurance: BoolString.False,
    allowPolicyHolderEntry: BoolString.False,
    allowRenewInsurance: BoolString.False,
    allowUploadInsuranceCard: BoolString.False,
    checkInsuranceEligibility: BoolString.False,
    expireDate: '',
    expireInsuranceCardPic: BoolString.False,
    insuranceCardExpireType: null,
    insuranceElectSHIPOption: '',
    insuranceMessage: '',
    insuranceSHIPMessage: '',
    insuranceWaiver: BoolString.False,
    insuranceWaiverDeniedMessage: '',
    insuranceWaiverFailedMessage: '',
    insuranceWaiverTemplateClinicID: '',
    insuranceWaiverTemplateFormID: '',
    insuranceWaiverTemplateProviderID: '',
    insuranceWaiverTemplateScore: '',
    insuranceWaiveSHIPOption: '',
    policyHolderHeading: '',
    requireInsuranceCardUpload: BoolString.False,
    saveHCFA: BoolString.False
  }),
  [PortalOptionGroup.Immunization]: (): IImmunizationKeyValuePairs => ({
    awaitingReviewMessage: '',
    displayImmunizationStatus: BoolString.False,
    groupAllImmunizations: BoolString.False,
    immFacility: '',
    immProvider: '',
    immunizationDatesMessage: '',
    immunizationEntryMessage: '',
    immunizationHistoryMessage: '',
    immunizationMessage: '',
    immunizationMessageAfterSave: '',
    isM1: BoolString.False,
    onePageDisplay: BoolString.False,
    onlyDisplayRequirements: BoolString.False,
    requireImmunizationDocUpload: BoolString.False,
    viewHistoricalHeading: '',
    viewMissingRequirmentHeading: '',
    viewServicesRenderedHeading: '',
    viewSubmittedUnverifiedHeading: ''
  }),
  [PortalOptionGroup.Status]: (): IStatusKeyValuePairs => ({
    hideCOVIDHistory: BoolString.False,
    covidLabProviderID: '',
    covidLabResultAfterSave: '',
    covidLabTxnCodeID: '',
    covidLabEntry: BoolString.False,
    hideCOVIDMedallion: BoolString.False
  }),
  [PortalOptionGroup.SecureMessage]: (): ISecureMessageValuePairs => ({
    allowAttachments: BoolString.False,
    allowCompose: BoolString.False,
    allowIneligibleMessaging: BoolString.False,
    allowRepy: BoolString.False,
    clinicMessaging: BoolString.False,
    messageLength: '',
    messagesDisplayed: '',
    patientProviderMessaging: BoolString.False,
    replyOption: '',
    replyProviders: '',
    secureMessageEmail: '',
    secureMessageEmailSubject: '',
    secureMessageMessage: '',
    secureMessageText: ''
  }),
  [PortalOptionGroup.Appointments]: (): IAppointmentsValuePairs => ({
    advancedScheduling: BoolString.False,
    allowCheckin: BoolString.False,
    displayOptInText: BoolString.False,
    allowFreeTextSymptom: BoolString.False,
    allowGroupAppointments: BoolString.False,
    allowIneligibleAppointment: BoolString.False,
    allowSameDayAppt: BoolString.False,
    appointmentMessage: '',
    appointmentsDisplayed: '',
    cancelBeforeApptMessage: '',
    cancellationEmailMessage: '',
    checkinDistance: '',
    checkinModule: BoolString.False,
    confirmationEmail: '',
    confirmationEmailMessage: '',
    currentlySheduledAppointmentTimeCheck: '',
    defaultSearch: '',
    displayAppointmentHistory: BoolString.False,
    displayDefaultProvider: BoolString.False,
    displayProviderTeam: BoolString.False,
    doNotCheckDistance: BoolString.False,
    emergencyEmail: '',
    emergencySymptomClinicCode: '',
    emergencySymptomMessage: '',
    emergencySymptomProviderCode: '',
    emergencySymptomTxnCode: '',
    excessAppointmentMessage: '',
    hideUpcomingAppointments: BoolString.False,
    locationsHeader: '',
    maxAppointmentsPerDay: '',
    minutesBeforeScheduleMessage: '',
    nextAvailableSearchDays: '',
    noAppointmentsFound: '',
    noApptAfter: '',
    noApptBeforeMinutes: '',
    noCancelApptBeforeMinutes: '',
    patientProviderScheduling: BoolString.False,
    patientProviderTeamScheduling: BoolString.False,
    requireSymptoms: BoolString.False,
    sendConfirmationEmail: BoolString.False,
    showSpecialInstructions: BoolString.False,
    showSymptoms: BoolString.False,
    symptomFreeTextLabel: '',
    symptomsHeader: '',
    walkinWindow: ''
  })
};


export const getDisclaimerStatus = ()=>{
  return store.getState().adminPanel.PatientPortalSetup.disclaimer
}