import React from 'react';
import SelectContainer from 'src/Framework/Controls/SelectContainer';

import { IOption, ISingle } from './types';

interface IProps<S> extends ISingle<S> {
  options: IOption<S>[];
  allOptions: IOption<S>[];
}

const Component = <S,>({
  value,
  onChange,
  selectProps,
  options,
  name,
  allOptions,
  id
}: IProps<S>) => {
  return (
    <SelectContainer
      label={name}
      {...selectProps}
      id={id}
      options={options}
      allOptions={allOptions}
      type="select"
      value={value}
      onChange={(value: S | null, option: any) => {
        onChange(value ? value : null, option);
      }}
    />
  );
};

export default Component;
