import { APIHandler } from "../../../../../Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
export const ADD_RULE_DETAILS = "ADD_RULE_DETAILS";
export const CHANGE_RULE_ENABLE_DISABLE ="CHANGE_RULE_ENABLE_DISABLE";
export const UPDATE_DRAFT_STATUS = "UPDATE_DRAFT_STATUS";
export const REMOVE_DRAFT_FROM_UI = "REMOVE_DRAFT_FROM_UI";
export const ENABLE_DISABLE_RULE = "ENABLE_DISABLE_RULE";

export const addRuleDetails = (ruleDetails: any) => {
  return {
    type: ADD_RULE_DETAILS,
    payload: ruleDetails
  };
};

export const getRuleDetails = (name: string) => {
  return APIHandler.AxiosInstance.get(ApiUrl.GET_RULE_METADATA+ "?ruleName=" + name, { withCredentials: true }) 
};

export const updateDraftStatus = (ruleId: any) => {
  return {
    type: UPDATE_DRAFT_STATUS,
    ruleId: ruleId
  };
};

export const removeDraftFromUI = (ruleId: string) => {
  return {
    type: REMOVE_DRAFT_FROM_UI,
    ruleId: ruleId
  };
};