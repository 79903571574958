import styled, { createGlobalStyle } from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const GlobalStyle = createGlobalStyle`

  .custom__checkbox {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    margin-left: 11px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input[type="checkbox"] {
      margin-top: 10px;
      width: 25px;
      height: 25px;
    }

    input[type="radio"] {
      margin-top: 10px;
      width: 25px;
      height: 25px;
    }

    input[type="checkbox"] {
      visibility: hidden;
      display: none;
    }

    input {
      background-color: #53c82e;
    }

    .checkmark {
      position: absolute;
      top: 2.5px;
      left: 0;
      height: 16px;
      width: 16px;
      border-radius: 2px;
      background-color: #fff;
      border: 1px solid #cccccc;
    }

    &:hover input ~ .checkmark {
      background-color: #fff;
    }

    input:checked ~ .checkmark {
      background-color: #53c82e;
      color: #fff;
      border: solid #53c82e 1px;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 4px;
      top: 0;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  /* Accessibility */
  body {
    .tippy-popper {
      max-width: unset !important;
    }

    .accessibility--menu__state {
      &--show {
        display: flex;
      }

      &--hide {
        display: none;
      }

      &--showWhiteBG {
        a {
          svg {
            circle {
              fill: transparent !important;
            }

            path {
              stroke: ${globalStyleVariables.newBrandingPrimary200} !important;
            }
          }
        }
      }

    }

    /* Keyboard Nav */

    &.accessibility--user__keyboard {
      .accessibility--user__element--selected {
        outline: dashed 2px #c00 !important;
      }
    }

    /* Contrast */

    &.accessibility--contrast__high-contrast {
      filter: contrast(135%);
      -webkit-filter: contrast(135%);
      -moz-filter: contrast(135%);
      -ms-filter: contrast(135%);
      -o-filter: contrast(135%);
    }

    &.accessibility--contrast__monochrome {
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      background-color: rgba(0, 0, 0, 0) !important;
    }

    &.accessibility--contrast__high-saturation {
      filter: saturate(200%);
      -webkit-filter: saturate(200%);
      -moz-filter: saturate(200%);
      -ms-filter: saturate(200%);
      -o-filter: saturate(200%);
    }

    &.accessibility--contrast__low-saturation {
      filter: saturate(50%);
      -webkit-filter: saturate(50%);
      -moz-filter: saturate(50%);
      -ms-filter: saturate(50%);
      -o-filter: saturate(50%);
    }

    //&.accessibility--contrast__light--color,
    //&.accessibility--contrast__dark--color {
    //  .accessibility--menu__overflowOpen {
    //    background: transparent !important;
    //  }
    //
    //  button {
    //    span {
    //      background: transparent !important;
    //    }
    //  }
    //
    //  .waiting-group-icon-more,
    //  .tippy-popper,
    //  .icon-accessibility {
    //    background: transparent !important;
    //  }
    //}

    // &.accessibility--contrast__dark--color {
    //   abbr, article, aside, b, big, body, button, div, em, footer,
    //   form, h1, h1, h3, h4, h5, h6, header, i, input, label, li, main,
    //   nav, p, select, small, span, strong, td, textarea, th, ul {
    //     background-color: #000 !important;
    //     border-color: #fff !important;
      //     color: ${globalStyleVariables.newBrandingPrimary200} !important;
    //   }
    //
    //   [role=link], [role=link] *, a, a * {
    //     border-color: #fff !important;
    //     color: #fcff3c !important;
    //   }
    //
    //   .accessibility--menu__open {
    //     ul {
    //       li {
    //         > div {
    //           div, span {
    //             background: transparent !important;
    //           }
    //
    //           svg {
    //             path {
    //               stroke: #0e6c9d !important;
    //             }
    //
    //             circle {
    //               stroke: #0e6c9d !important;
    //             }
    //           }
    //
    //           &.active, &:hover {
    //             background: #fff !important;
    //             color: #000 !important;
    //
    //             span {
    //               color: #0e6c9d !important
    //             }
    //
    //             .lines {
    //               &.accessibility--lines--first,
    //               &.accessibility--lines--second,
    //               &.accessibility--lines--third,
    //               &.accessibility--lines--fourth {
    //                 span {
    //                   background: #0e6c9d !important;
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    //
    //   .ant-select-selection.ant-select-selection--single {
    //     input {
    //       background: transparent !important;
    //     }
    //
    //     &:hover {
    //       background-color: #000 !important;
    //     }
    //
    //     &:active {
    //       background-color: #000 !important;
    //     }
    //   }
    //
    //   .schedule-content-layout {
    //     background: transparent !important;
    //
    //     div {
    //       background: transparent !important;
    //     }
    //   }
    //
    //   .medicat-calendar__month-view .medicat-calendar__tile--active .tileContent {
    //     background: #ffffff !important;
    //   }
    // }

    //&.accessibility--contrast__light--color {
    //  abbr, article, aside, b, big, body, button, div, em, footer,
    //  form, h1, h1, h3, h4, h5, h6, header, i, input, label, li, main,
    //  nav, p, select, small, span, strong, td, textarea, th, ul {
    //    background-color: #fff !important;
    //    color: #000 !important;
    //    border-color: #000 !important;
    //  }
    //
    //  .accessibility--contrast--third {
    //    &:after {
    //      background-color: #000 !important;
    //    }
    //  }
    //
    //  .accessibility--menu__open {
    //    ul {
    //      li {
    //        > div {
    //          div, span {
    //            background: transparent !important;
    //            color: #000 !important;
    //          }
    //
    //          svg {
    //            path {
    //              stroke: #000 !important;
    //            }
    //          }
    //
    //          &.active, &:hover {
    //            background: #000 !important;
    //            color: #000 !important;
    //
    //            div, span {
    //              background: transparent !important;
    //              color: #fff !important;
    //            }
    //
    //            .lines {
    //              &.accessibility--lines--first,
    //              &.accessibility--lines--second,
    //              &.accessibility--lines--third,
    //              &.accessibility--lines--fourth {
    //                span {
    //                  background: #fff !important;
    //                }
    //              }
    //            }
    //
    //            svg {
    //              path {
    //                stroke: #fff !important;
    //              }
    //            }
    //          }
    //        }
    //      }
    //    }
    //  }
    //
    //  a {
    //    border-color: #000 !important;
    //  }
    //
    //  [role=link], [role=link] *, a, a * {
    //    color: #0000d3 !important;
    //    background-color: #fff !important;
    //  }
    //
    //  .ant-select-selection.ant-select-selection--single {
    //    input {
    //      background: transparent !important;
    //    }
    //
    //    &:hover {
    //      background-color: #fff !important;
    //    }
    //
    //    &:active {
    //      background-color: #fff !important;
    //    }
    //  }
    //
    //  .schedule-content-layout {
    //    background: transparent !important;
    //
    //    div {
    //      background: transparent !important;
    //    }
    //  }
    //
    //  .medicat-calendar__month-view .medicat-calendar__tile--active .tileContent {
    //    background: #000 !important;
    //    color: #fff !important;
    //  }
    //}

    //&.accessibility--contrast__desaturate--color {
    //  filter: grayscale(100%) !important;
    //  -webkit-filter: grayscale(100%) !important;
    //}

    /* Bigger text */

    &.accessibility--user__biggerText--first {
      div, span {
        font-size: 1.4rem !important;
      }
    }

    &.accessibility--user__biggerText--second {
      div, span {
        font-size: 1.5rem !important;
      }
    }

    &.accessibility--user__biggerText--third {
      div, span {
        font-size: 1.6rem !important;
      }
    }


    &.accessibility--user__biggerText--fourth {
      div, span {
        font-size: 1.8rem !important;
      }
    }

    /* Highlight links */

    &.accessibility--highlight_links {
      a, button, [role=link], [role=menuitem], [role=tab], [role=button] {
        outline-color: ${globalStyleVariables.linkBorder} !important;
        outline-style: dashed !important;
        outline-width: 3px !important;
        outline-offset: -2px !important;
      }
    }

    &.accessibility--dark-theme--first {
      filter: brightness(0.5);
      -webkit-filter: brightness(0.5);
      -moz-filter: brightness(0.5);
      -ms-filter: brightness(0.5);
      -o-filter: brightness(0.5);
    }

    &.accessibility--dark-theme--second {
      filter: invert(85%);
      -webkit-filter: invert(85%);
      -moz-filter: invert(85%);
      -ms-filter: invert(85%);
      -o-filter: invert(85%);

      img {
        filter: invert(118%);
      }
    }

    &.accessibility--dark-theme--third {
      filter: invert(97%) hue-rotate(180deg);
      -webkit-filter: invert(97%) hue-rotate(180deg);
      -moz-filter: invert(97%) hue-rotate(180deg);
      -ms-filter: invert(97%) hue-rotate(180deg);
      -o-filter: invert(97%) hue-rotate(180deg);

      img {
        filter: invert(103%) hue-rotate(180deg);
      }
    }

    //&.accessibility--user__highlight {
    //  [role=link], [role=link] *, a, a * {
    //    text-decoration: underline !important;
    //    color: #ff0 !important;
    //    background-color: #000 !important;
    //  }
    //}

    /* Cursor */

    &.accessibility--user__cursor * {
      cursor: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyOS4xODhweCIgaGVpZ2h0PSI0My42MjVweCIgdmlld0JveD0iMCAwIDI5LjE4OCA0My42MjUiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI5LjE4OCA0My42MjUiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwb2x5Z29uIGZpbGw9IiNGRkZGRkYiIHN0cm9rZT0iI0Q5REFEOSIgc3Ryb2tlLXdpZHRoPSIxLjE0MDYiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgcG9pbnRzPSIyLjgsNC41NDkgMjYuODQ3LDE5LjkwMiAxNi45NjQsMjIuNzAxIDI0LjIzOSwzNy43NDkgMTguMjc4LDQyLjAxNyA5Ljc0MSwzMC43MjQgMS4xMzgsMzUuODA5ICIvPjxnPjxnPjxnPjxwYXRoIGZpbGw9IiMyMTI2MjciIGQ9Ik0yOS4xNzUsMjEuMTU1YzAuMDcxLTAuNjEzLTAuMTY1LTEuMjUzLTAuNjM1LTEuNTczTDIuMTY1LDAuMjU4Yy0wLjQyNC0wLjMyLTAuOTg4LTAuMzQ2LTEuNDM1LTAuMDUzQzAuMjgyLDAuNDk3LDAsMS4wMywwLDEuNjE3djM0LjE3MWMwLDAuNjEzLDAuMzA2LDEuMTQ2LDAuNzc2LDEuNDM5YzAuNDcxLDAuMjY3LDEuMDU5LDAuMjEzLDEuNDgyLTAuMTZsNy40ODItNi4zNDRsNi44NDcsMTIuMTU1YzAuMjU5LDAuNDgsMC43MjksMC43NDYsMS4yLDAuNzQ2YzAuMjM1LDAsMC40OTQtMC4wOCwwLjcwNi0wLjIxM2w2Ljk4OC00LjU4NWMwLjMyOS0wLjIxMywwLjU2NS0wLjU4NiwwLjY1OS0xLjAxM2MwLjA5NC0wLjQyNiwwLjAyNC0wLjg4LTAuMTg4LTEuMjI2bC02LjM3Ni0xMS4zODJsOC42MTEtMi43NDVDMjguNzA1LDIyLjI3NCwyOS4xMDUsMjEuNzY4LDI5LjE3NSwyMS4xNTV6IE0xNi45NjQsMjIuNzAxYy0wLjQyNCwwLjEzMy0wLjc3NiwwLjUwNi0wLjk0MSwwLjk2Yy0wLjE2NSwwLjQ4LTAuMTE4LDEuMDEzLDAuMTE4LDEuNDM5bDYuNTg4LDExLjc4MWwtNC41NDEsMi45ODVsLTYuODk0LTEyLjMxNWMtMC4yMTItMC4zNzMtMC41NDEtMC42NC0wLjk0MS0wLjcyYy0wLjA5NC0wLjAyNy0wLjE2NS0wLjAyNy0wLjI1OS0wLjAyN2MtMC4zMDYsMC0wLjU4OCwwLjEwNy0wLjg0NywwLjMyTDIuOCwzMi41OVY0LjU0OWwyMS41OTksMTUuODA2TDE2Ljk2NCwyMi43MDF6Ii8+PC9nPjwvZz48L2c+PC9nPjwvc3ZnPg==), auto !important
    }

    /* Reading */

    &.accessibility--user__reading {
      .accessibility--user__reading--line {
        display: none;
        box-sizing: border-box;
        background: #000;
        width: 100% !important;
        min-width: 100% !important;
        position: absolute !important;
        height: 12px !important;
        border: solid 3px #fff300;
        border-radius: 5px;
        top: 20px;
        z-index: 2147483647;

        &.show {
          display: block;
        }
      }
    }

    /* Magnifier */

    &.accessibility--user__magnifier {
      zoom: 150%;

      .accessibility--button__bigger {
        > div {
          pointer-events: none;
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

    .table__react__tabulator {
      .tabulator {
        height: calc(100vh - 260px);

        .tabulator-tableHolder {
          .tabulator-table {
            .tabulator-row {
              border-bottom: none;
              min-height: 79px;
              display: flex;
              align-items: center;

              &:hover,
              &:active,
              &:focus {
                background: ${globalStyleVariables.newBrandingHoverMenuItemBG};
              }

              .tabulator-cell {
                padding: 0;
                height: 100% !important;
                font-family: Lato;
                font-weight: normal;
                font-size: 12px;
                padding: 0 0 0 25px;
                color: #000000;
                text-transform: capitalize;
                display: flex;
                min-height: 79px;
                align-items: center;

                .tabulator-icon-edit,
                .tabulator-icon-delete {
                  width: 32px;
                  height: 32px;
                  background: #f7f7f7;
                  border-radius: 24px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .tabulator-icon-edit {
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
        }

        .tabulator-header {
          padding-right: 0;
          border-bottom-width: 1px;
          border-top: none;
          height: 67px;

          .tabulator-headers {
            height: 100%;

            .tabulator-col {
              height: 100% !important;
              background-color: #ffffff;

              &:hover {
                background-color: transparent;
              }

              &.tabulator-sortable {
                &[aria-sort="none"] {
                  .tabulator-col-content {
                    .tabulator-arrow {
                      border-bottom: 8px solid ${globalStyleVariables.brandGray};
                    }
                  }
                }

                &[aria-sort="asc"] {
                  .tabulator-col-content {
                    .tabulator-arrow {
                      border-bottom: 8px solid ${globalStyleVariables.brandGray};
                    }
                  }
                }

                &[aria-sort="desc"] {
                  .tabulator-col-content {
                    .tabulator-arrow {
                      border-top: 8px solid ${globalStyleVariables.brandGray};
                    }
                  }
                }
              }

              .tabulator-col-content {
                padding: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .tabulator-col-title {
                  display: flex;
                  padding: 0 0 0 24px;
                  width: auto;
                  font-weight: bold;
                  font-family: Lato;
                  font-size: 12px;
                  color: #000000;
                  white-space: normal;
                }

                .tabulator-arrow {
                  position: relative;
                  display: flex;
                  top: inherit;
                  right: inherit;
                  border-left: 4px solid transparent;
                  border-right: 4px solid transparent;
                  margin-left: 8px;
                }
              }
            }
          }
        }
      }
    }

    .ant-popover {
      &.history-grid {
        .ant-popover-inner-content {
          padding: 0;
        }
      }
    }

    .toggle-button {
      .ant-switch {
        min-width: 32px;
        height: 16px;
        cursor: pointer;

        .ant-switch-handle {
          width: 12px;
          height: 12px;
          box-shadow: none;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .ant-switch-checked {
        .ant-switch-handle {
          left: calc(100% - 12px - 2px);
          width: 12px;
          height: 12px;
          box-shadow: none;
        }
      }
    }

    .medicat-popover {
      width: auto;
      z-index: 5000;
      box-shadow: 0 1px 10px -3px;
      background: white;

      .medicat-tab-select-wrapper {
        overflow: hidden;

        .medicat-tabulator {
          overflow: auto;
          position: relative;
        }
      }
    }

    .assessment-popover {
      width: 500px;
      z-index: 5000;
      box-shadow: 0 1px 10px -3px;
      background: white;
    }

    .ccaps-popover {
      width: 500px;
      z-index: 1000;
      box-shadow: 0 1px 10px -3px;
      background: white;
    }

    .modal__image--wrapper {
      .ant-modal-content {
        height: 100%;

        .ant-modal-body {
          height: calc(100% - 73px);

          .modal__image--content {
            height: 100%;
            overflow: overlay;
          }
        }
      }
    }

    .preferred__control {
      z-index: 9999;

      &--container {
        display: flex;
        align-items: center;
        font-family: Lato;
        font-weight: normal;
        font-size: 12px;

        &--copy {
          display: block;
          padding-right: 8px;
          color: ${globalStyleVariables.newBrandingPrimary200}
          text-transform: capitalize;
        }

        &--number {
          display: block;
          color: #333333;
        }
      }

      &--number {
        box-sizing: border-box;
        height: 32px;
        background: #f7f7f7;
        font-size: 12px;
        color: #333333;
        font-family: Lato;
        font-weight: normal;
        padding: 0;
        z-index: 1;
        width: 100%;
        border: none;
        cursor: pointer;
        position: relative;

        &--active {
          border: 1px solid ${globalStyleVariables.newBrandingPrimary200};
        }

        &.is-focused {
          border: 1px solid #2c7fab;

        }

        &__wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          padding: 0 0 0 1rem;
          position: relative;
        }
      }

      &--icon {
        width: 26px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
      }

      &--wrapper {
        border: 1px solid ${globalStyleVariables.newBrandingPrimary200};
        background: #fff;

        p {
          font-size: 12px;
          color: #000000;
          font-family: Lato;
          font-weight: normal;
          width: 80px;
          border-right: 1px solid #E6E6E6;
          height: 35px;
          padding-left: 8px;
          margin: 0;
          display: flex;
          align-items: center;
        }

        &--content {
          display: flex;
          border-top: 1px solid #E6E6E6;
          height: 35px;
          justify-content: space-between;
          width: 100%;
          align-items: center;

          .preferred__control--wrapper--content__right {
            .info {
              line-height: 34px;
              margin-left: 8px;
              font-size: 12px;
            }
          }

          &:last-child {
            .preferred__control--wrapper--content__right {
              input {
                &:focus {
                  border-bottom: none;
                }
              }
            }
          }

          &__left {
            width: 80px;
            border-right: 1px solid #E6E6E6;
            box-sizing: border-box;
            display: flex;
            height: 100%;

            label {
              padding-left: 11px;
              margin: 0;
              display: flex;
              align-items: center;
              height: 100%;
              width: 80px;
              cursor: pointer;

              span {
                font-size: 12px;
                color: #000000;
                font-family: Lato;
                font-weight: bold;
                text-transform: capitalize;
                padding-left: 9px;
              }
            }
          }

          &__right {
            flex: 1;
            display: flex;
            height: 100%;

            input {
              border: none;
              height: 100%;
              font-family: Lato;
              font-weight: normal;
              font-size: 11px;
              color: #000000;
              padding: 0 10px;
              width: 100%;
              border: none;
              transition: border 0.5s ease;

              &:focus {
                border: 2px solid ${globalStyleVariables.newBrandingPrimary200};
                border-right: none;
              }
            }
          }
        }
      }
    }
  }
`;

export const MainContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  min-height: 500px;
`;
