
import * as actionType from "../../RuleActions/ActionTypes";
import _ from 'lodash';

interface IAction {
    type: string;
    ruleId: string;
    componentId: number;
    imPros: any;
    pagination: any;
    id: string;
}
export interface IImmunizationsProcedureStore {
    ruleId: string;
    componentId: number;
    pagination: any
    imPros: any[];
}


const ImmunizationProcedureOptionReducer = (state: IImmunizationsProcedureStore[] = [], action: IAction) => {
    switch (action.type) {
        case actionType.ADD_IMMUNIZATION_PROCEDURES: {
            let newState = _.cloneDeep(state)
            let immunizationStore = newState.find((i: IImmunizationsProcedureStore) => i.ruleId === action.ruleId && i.componentId === action.componentId);
            if (immunizationStore) {
                if (action.pagination.current > 1) {
                    immunizationStore.imPros = [...immunizationStore.imPros, ...action.imPros];
                }
                else {
                    immunizationStore.imPros = action.imPros;
                }
                immunizationStore.pagination = action.pagination;
            } else {
                let data: IImmunizationsProcedureStore = {
                    ruleId: action.ruleId,
                    componentId: action.componentId,
                    imPros: action.imPros,
                    pagination: action.pagination,
                }
                newState.push(data);
            }
            return newState;
        }
        case actionType.CLEAR_RULE_EDITOR: {
            let newState = _.cloneDeep(state)
            let iArray = newState.filter((i: IImmunizationsProcedureStore) => i.ruleId === action.id);
            if (iArray.length === 0) return newState;
            for (let i = 0; i < iArray.length; i++) {
                newState.splice(newState.indexOf(iArray[i]), 0);
            }
            return newState;
        }


        default:
            return state;
    }
};

export default ImmunizationProcedureOptionReducer;
