import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/es/tooltip';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

interface IProps extends TooltipPropsWithTitle {}

const Component: FC<IProps> = ({ children, ...otherProps }: IProps) => {
  return (
    <Tooltip {...otherProps} zIndex={globalStyleVariables.tooltipZIndex}>
      {children}
    </Tooltip>
  );
};

export default Component;
