import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div`
  position: relative;
`;

export const ModalContainer = styled.div`
  padding: 20px;

  .ant-btn:hover,
  .ant-btn:focus {
    color: ${globalStyleVariables.newBrandingPrimary200};
    border-color: ${globalStyleVariables.newBrandingPrimary200};
  }
`;

export const Text = styled.div`
  font-size: 16px;
`;
