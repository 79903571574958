import ApiSubscription, { BucketsList } from "."
import { IFindRequest } from "./SubscriptionClass"
import { APIHandler } from "../Communication/ServerProxy"

type OnCreateUpdateItem<T, RArgs extends any[] = never> = (value: T, ...args: RArgs) => any
type SubscriptionDataType<T extends any[] = never> = (...args: T) => IFindRequest<typeof BucketsList>
type SummaryType<T, SummaryArgs extends any[] = never> = (summary: Record<string, T>, ...args: SummaryArgs) => any
type ParseMethod<T> = (data: Record<string, T>) => Record<string, T>

export interface IPatchResponse {
    success: boolean
}

interface IPostResponse<T = any> {
    success: boolean
    result?: Partial<T>
}

interface IConstructor<T, SArgs extends any[] = never, RArgs extends any[] = never> {
    link: string
    onCreateUpdateItem?: OnCreateUpdateItem<T, RArgs>
    subscription: {
        subscriptionData: SubscriptionDataType<SArgs>
        setSummary: SummaryType<T, SArgs>
        parseMethod?: ParseMethod<T>
    }
}

/** T - Object interface, SArgs - Args for get request that will include in subscription, RArgs - Args for post, patch requests that will include in onCreateUpdateItem  */
export class RequestHelper<T = any, SArgs extends any[] = any, RArgs extends any[] = any, PostBody = Partial<T>> {
    link = ''

    onCreateUpdateItem?: OnCreateUpdateItem<T, RArgs>
    SubscriptionData: SubscriptionDataType<SArgs>
    setSummary?: SummaryType<T, SArgs>
    parseMethod?: ParseMethod<T>

    constructor(props: IConstructor<T, SArgs, RArgs>) {
        const { link, onCreateUpdateItem, subscription } = props
        this.link = link
        this.onCreateUpdateItem = onCreateUpdateItem;
        this.SubscriptionData = subscription.subscriptionData;
        this.setSummary = subscription?.setSummary;
        this.parseMethod = subscription?.parseMethod;
    }

    public generateSubscriptionPath = (...args: SArgs) => {
        const data = this.SubscriptionData(...args);
        return `${data.bucket} - ${data.filter}`
    }

    public patch = async (body: Partial<T> & { id: number }, ...args: RArgs): Promise<IPatchResponse | undefined> => {
        try {
            const { id, ...otherBody } = body
            const response = await APIHandler.AxiosInstance.patch(`${this.link}/${id}`, otherBody, { withCredentials: true, interceptError: true })
            if (response.data) {
                if (response.data.success) {
                    return response.data
                }
            }
            return;
        } catch (e) {
            console.log({ e })
            return
        } finally {
        }
    }

    public post = async (body: PostBody, ...args: RArgs): Promise<IPostResponse<PostBody> | undefined> => {
        try {
            const response = await APIHandler.AxiosInstance.post(`${this.link}`, body, { withCredentials: true, interceptError: true })
            if (response.data) {
                if (response.data.success) {
                    return response.data
                }
            }
            return;
        } catch (e) {
            console.log({ e })
            return
        } finally {
        }
    }

    public put = async (body: T & { id: number }, ...args: RArgs): Promise<IPostResponse | undefined> => {
        try {
            const response = await APIHandler.AxiosInstance.put(`${this.link}/${body.id}`, body, { withCredentials: true, interceptError: true })
            if (response.data) {
                if (response.data.success) {
                    return response.data
                }
            }
            return;
        } catch (e) {
            console.log({ e })
            return
        } finally {
        }
    }

    public deleteRequest = async (body: { id: number }): Promise<boolean | undefined> => {
        try {
            const { id } = body
            const response = await APIHandler.AxiosInstance.delete(`${this.link}/${id}`, { withCredentials: true, interceptError: true })
            if (response.data) {
                if (response.data.success) {
                    return true
                }
            }
            return;
        } catch (e) {
            console.log({ e })
            return
        } finally {
        }
    }
    /**Subscription with Find and Subscribe */
    public get = async (...args: SArgs) => {
        if (this.SubscriptionData) {
            const req = this.SubscriptionData(...args)
            const response = await ApiSubscription.instance.FindAndSubscribe<T>(req, this.parseMethod)
            if (response?.success) {
                if (this.setSummary) {
                    this.setSummary(response.data, ...args)
                }
                return response
            }
        }
        return
    }

    public findAndUnsubscribe = async (...args: SArgs) => {
        if (this.SubscriptionData) {
            const req = this.SubscriptionData(...args)
            const response = await ApiSubscription.instance.FindAndSubscribe<T>(req, this.parseMethod)
            await this.unsubscribe(...args)
            if (response?.success) {
                return response
            }
        }
        return
    }

    /**UnSubscription*/
    public unsubscribe = async (...args: SArgs) => {
        if (this.SubscriptionData) {
            const req = this.SubscriptionData(...args)
            await ApiSubscription.instance.Unsubscribe(req);
        }
        return
    }
}