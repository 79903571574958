import _ from 'lodash';
import { IStateReducer, IAction } from './types';

const prefix = 'LETTER_TEMPLATES_MANAGEMENT_';

export const actionsTypes = {
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_SORT: `${prefix}SET_SORT`,
    SET_SUMMARY: `${prefix}SET_SUMMARY`

} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

export const defaultData: IStateReducer = {
    filter: {
        showInactive: false,
        search: ''
    },
    sort: {
        sortBy: null,
        sortType: null
    },
    summary: {}
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const actions: IActions = {
        [actionsTypes.SET_SUMMARY]: () => {
            return {
                ...state,
                summary: action.payload
            };
        },
        [actionsTypes.SET_SORT]: () => {
            return {
                ...state,
                sort: action.payload
            };
        },
        [actionsTypes.SET_FILTER]: () => {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                }
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
