import React, { useImperativeHandle, useState, useRef } from 'react';
import _ from 'lodash';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';

import { CheckGreenCircle } from 'src/Framework/Common/Svg/CheckGreenCircle';
import { CloseDeletePopup } from 'src/Framework/Common/Svg/CloseDeletePopup';

import {
  WithoutTitleContainer,
  Message,
  Actions,
  Loader,
  ActionsContainer
} from './styles';
import { Box } from 'src/CommonStyles/styles';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

const borderStyle = {
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  borderWidth: '0px 0px 0px 4px',
  borderStyle: 'solid'
};

interface IData {
  onCancel: any;
  onConfirm: any;
  content?: string | React.ReactNode;
  action?: 'delete' | 'info' | '';
  hideClose?: boolean;
  loading?: boolean;
  loadingMessage?: string;
  hideOk?: boolean;
  closeAfterConfirm?: boolean;
  maskCancel?: boolean;
  modalProps: Partial<ModalProps>;
  id?: string;
  callerRef?: React.MutableRefObject<any>;
  onConfirmAsync?: () => Promise<any>;
}

export interface IModalHandles {
  show(obj: Partial<IData>): void;

  close(): void;
}

const getColor = (action: string = '') => {
  switch (action) {
    case 'delete':
      return 'rgb(255, 40, 40)';
    case 'info':
      return globalStyleVariables.newBrandingPrimary200;
    default:
      return '#ffffff';
  }
};

const defaultData: IData = {
  action: '',
  onConfirm: () => {},
  onCancel: () => {},
  content: '',
  hideClose: false,
  loading: false,
  loadingMessage: '',
  hideOk: false,
  closeAfterConfirm: true,
  maskCancel: true,
  modalProps: {},
  id: 'confirm-popup'
};

const Component: React.ForwardRefRenderFunction<IModalHandles, any> = (
  {},
  ref
) => {
  const [asyncLoading, setAsyncLoading] = useState(false);
  const [data, setData] = useState<IData>(_.cloneDeep(defaultData));
  const [visible, setVisible] = useState(false);
  const messageRef = useRef(null);
  const show = (obj: Partial<IData>) => {
    onChangeData(obj);
    setVisible(true);
    if (messageRef.current) {
      //@ts-ignore
      messageRef.current.focus();
    }
  };
  const close = () => {
    if (data?.callerRef?.current) {
      data.callerRef.current.focus();
    }
    setVisible(false);
  };

  const setToDefault = () => {
    setData(_.cloneDeep(defaultData));
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IData) => show(obj),
    close
  }));

  const onChangeData = (obj: Partial<IData>) => {
    setData({ ...data, ...obj });
  };

  const {
    action,
    onCancel,
    onConfirm,
    loading,
    loadingMessage,
    content,
    hideClose,
    hideOk,
    closeAfterConfirm,
    maskCancel,
    id,
    onConfirmAsync
  } = data;
  const color: string = getColor(action);

  const onCancelModal = () => {
    onCancel();
    close();
  };

  const onConfirmModal = async () => {
    if (onConfirmAsync) {
      setAsyncLoading(true);
      await onConfirmAsync();
      setAsyncLoading(false);
      close();
      return;
    }
    onConfirm();
    if (closeAfterConfirm) {
      close();
    }
  };

  return (
    <Modal
      footer={false}
      width={'416px'}
      closable={false}
      style={{ top: '300px' }}
      open={visible}
      onCancel={maskCancel ? onCancelModal : close}
      afterClose={() => setToDefault()}
      maskStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.14)',
        zIndex: globalStyleVariables.alertPopupZIndex
      }}
      wrapClassName="alert-pop-up-wrap"
      bodyStyle={{
        ...borderStyle,
        borderColor: color,
        zIndex: globalStyleVariables.alertPopupZIndex
      }}
      {...data.modalProps}
    >
      <WithoutTitleContainer tabIndex={0} ref={messageRef}>
        {loading || asyncLoading ? (
          <>
            <Message>{loadingMessage || 'Loading...'}</Message>
            <Box
              style={{
                width: '100%',
                height: '100%',
                justifyContent: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Loader className={'little_loader'} />
              </div>
            </Box>
          </>
        ) : (
          <>
            <Message>{content}</Message>
            <ActionsContainer>
              {!hideClose && (
                <Actions
                  role="button"
                  aria-label="Close"
                  id={`${id}-close-alert`}
                  onClick={onCancelModal}
                  tabIndex={0}
                  onKeyDown={onKeyDownAccessibility}
                >
                  <CloseDeletePopup />
                </Actions>
              )}
              {!hideOk && (
                <Actions
                  role="button"
                  aria-label="Confirm"
                  tabIndex={0}
                  onKeyDown={onKeyDownAccessibility}
                  id={`${id}-approve-alert`}
                  style={{ marginLeft: 16 }}
                  onClick={onConfirmModal}
                >
                  <CheckGreenCircle />
                </Actions>
              )}
            </ActionsContainer>
          </>
        )}
      </WithoutTitleContainer>
    </Modal>
  );
};

export default React.forwardRef(Component);
