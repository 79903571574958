import React, {
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  useLayoutEffect
} from 'react';
import _ from 'lodash';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { debouncer } from 'src/Framework/util/helps';

import { getFormTemplateWithVersion } from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/Actions';
import {
  // IDisplaySchema,
  IFullBuildingForm
} from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/types';
// import * as patientInformationActions from 'src/App/PatientInformation/store/Actions';
import {
  IM1FormRecord,
  M1FormRecordStatus
} from 'src/App/M1FormRecords/store/types';
import * as M1FormRecordSubmissionActions from 'src/App/M1FormRecordSubmission/store/Actions';
// import { pathToFields } from 'src/App/FormBuilder/M1PatientForm';

import Header from './Header';
import Preview from './Preview';
import FormListActionBar, { IModalHandles } from './FormListActionBar';

import A11yAutofocusContainer from 'src/App/Accessibility/Components/A11yAutofocusContainer';

import FormContainer, {
  FormIOInstance,
  IGetFormValidation
} from 'src/App/FormBuilder/FormContainer';
import { FormBlock, FormAbsoluteBlock } from './styled';
import { parsedData } from 'src/Activities/Schedule/Utils/Utils';
// import { defaultValueField } from 'src/App/FormBuilder/utils';

// import { useAppSelector } from 'src/store';
// import { notification } from 'antd';

export type IPatchM1Record = (
  body: Partial<IM1FormRecord> & { id: number },
  subjectId: string | number
) => Promise<any>;

interface IProps {
  form: IM1FormRecord;
  subjectId: string;
  clinicTypes?: number[];
  afterRemove: () => any;
  m1FormPatchRequest: IPatchM1Record;
  hideHeader?: boolean;
  isViewerMode?: boolean;
  color?: string;
  getFormValidation?: IGetFormValidation;
  readOnly?: boolean;
  hideRemoveButton?: boolean;
}

// const parseData = (value: string) => {
//   try {
//     return JSON.parse(value);
//   } catch (e) {
//     return null;
//   }
// };

// const findPatientFormComponentIds = (displaySchema: null | IDisplaySchema) => {
//   if (!displaySchema) return [];
//   const ids: {
//     key: string;
//     fields: string[];
//   }[] = [];
//   const find = (components: IDisplaySchema['components']) => {
//     components.forEach((v) => {
//       if (v.type === 'm1PatientForm') {
//         if (v.key && v[defaultValueField]?.data) {
//           ids.push({
//             key: v.key,
//             fields: Object.keys(v[defaultValueField].data)
//           });
//         }
//       }
//       if (v.columns) {
//         v.columns.forEach((val) => {
//           if (val.components) {
//             find(val.components);
//           }
//         });
//       }
//     });
//   };
//   find(displaySchema.components);
//   return ids;
// };

const Component: FC<IProps> = ({
  form,
  clinicTypes,
  subjectId,
  afterRemove,
  m1FormPatchRequest,
  hideHeader,
  isViewerMode,
  color,
  getFormValidation,
  readOnly,
  hideRemoveButton
}: IProps) => {
  const actionBarRef = useRef<IModalHandles>(null);
  const debounce = useRef(debouncer(1000));
  const formSubmission = useRef<any>(null);
  const formIOInstance = useRef<FormIOInstance | null>(null);
  const [defaultSubmission, setDefaultSubmission] = useState<any>(null);
  const [data, setData] = useState<IFullBuildingForm | null>(null);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(
    form.status === 'signed' || form.status === 'locked'
  );
  const [isValid, setIsValid] = useState(true);
  const isValidRef = useRef(true);

  useLayoutEffect(() => {
    isValidRef.current = isValid;
  });
  // const submission = useAppSelector(
  //   (state) => state.m1FormRecordSubmission.summary[form.id] || null
  // );
  // useEffect(() => {
  //   if (submission && formSubmission.current) {
  //     const data = parseData(submission.text);
  //     if (!_.isEqual(formSubmission.current, data)) {
  //       // notification.info({
  //       //   message: 'Form was updated',
  //       //   description: 'Form submission was changed'
  //       // });
  //       // formSubmission.current = data;
  //       // formIOInstance.current?.setSubmission({
  //       //   data
  //       // });
  //     }
  //   }
  // }, [submission]);

  const getData = useCallback(() => {
    setData(null);
    const init = async () => {
      if (loading) return;
      setLoading(true);
      const response = await getFormTemplateWithVersion(
        form.formTemplateId,
        form.formTemplateVersion
      );
      if (response) {
        const res = await M1FormRecordSubmissionActions.findAndUnsubscribe(
          form.id
        );
        if (res?.success) {
          const value = Object.values(res.data)[0];
          if (value) {
            const data = parsedData(value.text);
            if (data) {
              formSubmission.current = data;
              setDefaultSubmission(data);
            }
          } else {
            formSubmission.current = {};
            setDefaultSubmission({});
          }
        }
        setDefaultSubmission({});
        setData(response);
      }
      setLoading(false);
    };
    init();
  }, [form.status, form.history, loading]);
  useEffect(() => {
    getData();
  }, [form.status, form.history]);
  const onClickSave = useCallback(async () => {
    actionBarRef.current?.setSaving(true);
    const res = await m1FormPatchRequest(
      {
        id: form.id,
        submission: JSON.stringify(formSubmission.current)
      },
      subjectId
    );
    if (res) {
    }
    actionBarRef.current?.setSaving(false);
  }, []);
  const onClickShowPreview = useCallback(() => {
    setPreview((value) => !value);
  }, []);
  const onChange = useCallback(
    (submission: any, isValid: boolean, b, formReadyTrigger, validator) => {
      if (getFormValidation && validator) {
        getFormValidation(validator);
      }
      if (isValid !== isValidRef.current) {
        setIsValid(isValid);
      }
      if (!formReadyTrigger) {
        if (!_.isEqual(formSubmission.current, submission)) {
          formSubmission.current = submission;
          debounce.current(onClickSave);
        }
      }
    },
    []
  );
  const getFormioInstance = useCallback((instance: any) => {
    formIOInstance.current = instance;
    if (formIOInstance.current) {
      formIOInstance.current.setSubmission({
        data: formSubmission.current
      });
    }
  }, []);
  const displaySchema = data?.versions[0]?.displaySchema;
  const formForPatientInfo = useMemo(() => {
    return {
      assignedProviderId: form.assignedProviderId,
      subjectPatientId: form.subjectPatientId,
      clinicId: form.clinicId
    };
  }, [form.assignedProviderId, form.subjectPatientId, form.clinicId]);
  return (
    <A11yAutofocusContainer
      style={{ position: 'relative', backgroundColor: 'white' }}
    >
      {loading && <AbsoluteLoader />}
      {!hideHeader && (
        <Header
          form={form}
          subjectId={subjectId}
          clinicTypes={clinicTypes}
          PatchRequest={m1FormPatchRequest}
        />
      )}
      <FormListActionBar
        ref={actionBarRef}
        hideRemoveButton={hideRemoveButton}
        onClickShowPreview={onClickShowPreview}
        formSelected={form}
        printRef={null}
        subjectId={subjectId}
        onRemove={afterRemove}
        onClickSave={onClickSave}
        preview={preview}
        isNotValid={!isValid}
        PatchRequest={m1FormPatchRequest}
        schema={displaySchema}
        formSubmission={formSubmission}
        isViewerMode={isViewerMode}
        color={color}
      />
      <FormBlock>
        <FormAbsoluteBlock
          className="absolute-container"
          onKeyDown={(e) => e.stopPropagation()}
        >
          {displaySchema && defaultSubmission ? (
            preview ? (
              <Preview
                schema={displaySchema}
                submission={formSubmission.current}
                form={form}
              />
            ) : (
              <FormContainer
                form={formForPatientInfo}
                readOnly={
                  readOnly ||
                  form.status === M1FormRecordStatus.Signed ||
                  form.status === M1FormRecordStatus.Locked
                }
                schema={displaySchema}
                submission={formSubmission.current}
                onChange={onChange}
                getFormioInstance={getFormioInstance}
              />
            )
          ) : null}
        </FormAbsoluteBlock>
      </FormBlock>
    </A11yAutofocusContainer>
  );
};

export default React.memo(Component);
