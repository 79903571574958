import _ from 'lodash';
import { IStateReducer, IAction } from './types';

const prefix = `CLINICS_`;

export const actionsTypes = {
  SET: `${prefix}SET_CLINICS`,
  SET_TYPES: `${prefix}SET_TYPES`,
  SET_LOADING: `${prefix}SET_LOADING`,
  SET_SORT: `${prefix}SET_SORT`
};

const initialState: IStateReducer = {
  summary: {},
  types: {},
  loading: false,
  sort: {
    sortBy: 'name',
    sortType: 'asc'
  }
};

const Reducer = (
  state = _.cloneDeep(initialState),
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET_SORT:
      return {
        ...state,
        sort: action.payload
      };
    case actionsTypes.SET:
      return { ...state, summary: action.payload };
    case actionsTypes.SET_TYPES:
      return { ...state, types: action.payload };
    case actionsTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default Reducer;
