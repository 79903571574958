import _ from "lodash";
import * as actionTypes from "./PatientImageActions";

const initState: IState = {};

interface IState { }
export const imageFormats = [
  { format: "data:image/jpg;base64,", type: ".jpg" },
  { format: "data:image/jpg;base64,", type: ".JPG" },
  { format: "data:image/jpeg;base64,", type: ".jpeg" },
  { format: "data:image/jpeg;base64,", type: ".JPEG" },
  { format: "data:image/jpeg;base64,", type: ".jfif" },
  // { format: "data:image/jpeg;base64,", type: ".pjp" },
  // { format: "data:image/jpeg;base64,", type: ".pjpeg" },
  // { format: "data:application/pdf;base64,", type: ".pdf" },
  // { format: "data:application/pdf;base64,", type: ".PDF" },
  { format: "data:image/png;base64,", type: ".png" },
  { format: "data:image/png;base64,", type: ".PNG" },
  { format: "data:image/gif;base64,", type: ".gif" },
  { format: "data:image/gif;base64,", type: ".gif" }];

interface IAction {
  type: string;
  payload: any;
  subjectId: any;
}

export const PatientImageReducer = (state: IState = initState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_PATIENT_IMAGE: {
      let newState = _.cloneDeep(state);
      let key = action.subjectId;
      newState[key] = action.payload;
      return newState;
    }
    default:
      return state;
  }
};
