import moment from 'moment';
import _ from 'lodash';
import { defaultProvidersColumnsOrder } from 'src/Activities/Schedule/Store/ScheduleReducers/ScheduleDetailsReducer';
import {
  IOrderValue,
  ISlice
} from 'src/Activities/Schedule/Store/ScheduleReducers/ScheduleDetailsReducer/types';

export const generateOrderSliceId = (value: ISlice) => {
  return `schedule-tenant#${value.tenantId}:clinic#${(
    value.clinicCode || ''
  ).toLowerCase()}:provider#${(value.providerCode || '').toLowerCase()}`;
};

export const onOrderChangeDay = (order: IOrderValue, list: ISlice[]) => {
  const itemsWithOrder = list.filter((listItem) =>
    order.dayColumnsIds?.some(
      (orderItem) =>
        orderItem.substring(orderItem.indexOf(':provider#') + 10) ===
        listItem.providerCode.toLowerCase()
    )
  );
  const itemsWithoutOrder = list.filter((listItem) =>
    order.dayColumnsIds?.every(
      (orderItem) =>
        orderItem.substring(orderItem.indexOf(':provider#') + 10) !==
        listItem.providerCode.toLowerCase()
    )
  );
  return itemsWithOrder
    .sort((a, b) => {
      if (!order.dayColumnsIds) {
        return 0;
      }
      return (
        order.dayColumnsIds.indexOf(generateOrderSliceId(a)) -
        order.dayColumnsIds.indexOf(generateOrderSliceId(b))
      );
    })
    .concat(itemsWithoutOrder);
};

export const onOrderBrokeDay = (order: IOrderValue, list: ISlice[]) => {
  const newOrder = order.dayColumnsIds?.filter((orderItem) =>
    list.find((slice) => slice.id.includes(orderItem))
  );
  return onOrderChangeDay({ dayColumnsIds: newOrder }, list);
};

export const getOrderValue = (value: string): IOrderValue => {
  if (!value) return defaultProvidersColumnsOrder;
  try {
    return JSON.parse(value);
  } catch (e) {
    return defaultProvidersColumnsOrder;
  }
};

export const getOrderByClinicCodes = (order: IOrderValue, slices: ISlice[]) => {
  const uniqueClinicCodes = [...new Set(slices.map((v) => v.clinicCode))];
  return uniqueClinicCodes.sort((a, b) => {
    if (!order.weekClinicCodes) {
      return 0;
    }
    return order.weekClinicCodes.indexOf(a) - order.weekClinicCodes.indexOf(b);
  });
};

export const getOrderByProviderCodes = (
  order: IOrderValue,
  slices: ISlice[]
) => {
  const uniqueClinicCodes = [...new Set(slices.map((v) => v.providerCode))];
  const itemsWithOrder = uniqueClinicCodes.filter((item) =>
    order.weekProvidersCodes?.includes(item)
  );
  const itemsWithoutOrder = uniqueClinicCodes.filter(
    (item) => !order.weekProvidersCodes?.includes(item)
  );
  return itemsWithOrder
    .sort((a, b) => {
      if (!order.weekProvidersCodes) {
        return 0;
      }
      return (
        order.weekProvidersCodes.indexOf(a) -
        order.weekProvidersCodes.indexOf(b)
      );
    })
    .concat(itemsWithoutOrder);
};

const orderCalculation = (codes: string[], slices: ISlice[], field: string) => {
  const grouped = _.groupBy(slices, (a) => a.weekDay);
  // Object.entries(grouped).forEach(([key, value]) => {
  //     value.sort((a, b) => {
  //       return codes.indexOf(a[field]) - codes.indexOf(b[field]);
  //     });
  // });
  for (let dayData in grouped) {
    const allItems = grouped[dayData];
    const itemsWithOrder = allItems.filter((item) =>
      codes.includes(item[field])
    );
    const itemsWithoutOrder = allItems.filter(
      (item) => !codes.includes(item[field])
    );
    grouped[dayData] = itemsWithOrder
      .sort((a, b) => {
        return codes.indexOf(a[field]) - codes.indexOf(b[field]);
      })
      .concat(itemsWithoutOrder);
  }
  const keys = Object.keys(grouped).sort((a, b) => {
    const val1 = moment(a, 'ddd dddd').weekday();
    const val2 = moment(b, 'ddd dddd').weekday();
    if (val1 > val2) {
      return 1;
    }
    if (val1 < val2) {
      return -1;
    }
    return 0;
  });
  const arrays = keys.map((key) => grouped[key]);
  return arrays.reduce((a, b) => a.concat(b), []);
};

export const getListByProviderCodes = (codes: string[], slices: ISlice[]) => {
  return orderCalculation(codes, slices, 'providerCode');
};

export const getListByClinicCodes = (codes: string[], slices: ISlice[]) => {
  return orderCalculation(codes, slices, 'clinicCode');
};
