import _ from 'lodash';
import * as actionTypes from '../PatientInfoActions';
import { IStateReducer, IAction } from './types';

const initState: IStateReducer = {};

export const defaultPatientInfoData: IStateReducer[''] = {
  contacts: {},
  patientDemographic: {
    otherId: '',
    typePreferredPhoneId: null,
    typePreferredPronounId: null,
    email: '',
    firstName: '',
    lastName: '',
    nickName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    isActive: true,
    deceased: false,
    exempt: false,
    birth: null,
    genderIdentity: [],
    sex: '',
    ethnicity: null,
    race: [],
    typeMaritalStatusId: 2,
    employerCode: '',
    typeEmployedId: 3,
    referralCode: '',
    chartNo: '',
    typeBillingId: 2,
    feeScheduleId: 1,
    department: '',
    lastUpdated: null,
    lastUpdatedUserName: null
  },
  patientStudentInfo: {
    typeStudentStatusId: null,
    major: '',
    standing: '',
    typeClassId: null,
    schoolName: '',
    enrolmentDate: null,
    typeResidencyId: null,
    typeEligibilityId: 2,
    typeSportId: null,
    belongsTo: [],
    groupId: null,
    groupProviderId: null,
    campusAddress2: '',
    campusAddress: '',
    permCity: '',
    permState: '',
    campusHousingId: null,
    campusHousingRoom: null,
    veteranStatus: false,
    citizenship: false,
    refugeeStatus: false
  },
  hideCounselingActivity: true,
  isRestrictedAccess: false,
  pictureName: null,
  claimProfile: null
};

export const PatientInfoReducer = (
  state: IStateReducer = _.cloneDeep(initState),
  action: IAction
): IStateReducer => {
  switch (action.type) {
    //Full replace of a subject
    case actionTypes.GET_PATIENT_INFO: {
      let newState = _.cloneDeep(state);
      let key = action.subjectId;
      newState[key] = {
        ...(newState[key] || _.cloneDeep(defaultPatientInfoData)),
        ...action.payload
      };
      return newState;
    }

    //Deeply recurse a subject and merge all properties
    case actionTypes.MERGE_PATIENT_INFO: {
      let newState = _.cloneDeep(state);
      let key = action.subjectId;
      newState[key] = action.payload;
      return newState;
    }

    //Partial replace of a subject, replaces one or more keys at the root level of the subject (demographics, studentInfo, contacts...)
    case actionTypes.SAVE_PATIENT_INFO: {
      let newState = _.cloneDeep(state);
      let key = action.subjectId;
      let pat = newState[key];
      let newPat = _.cloneDeep(defaultPatientInfoData);
      if (pat) {
        newPat = Object.assign(newPat, pat, action.payload);
      } else {
        newPat = action.payload;
      }
      newState[key] = newPat;
      return newState;
    }

    //Remove a subject from the store, usually for new patient
    case actionTypes.REMOVE_PATIENT_INFO: {
      let newState = _.cloneDeep(state);
      let key = action.subjectId;
      if (newState[key]) {
        delete newState[key];
      }
      return newState;
    }

    default:
      return state;
  }
};
