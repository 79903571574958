import React, { FC, useState, useEffect, useRef, useMemo } from 'react';
import _ from 'lodash';

import Checkbox from 'src/Framework/Controls/Checkbox';
import Icon from 'src/Framework/Controls/Icon';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import TreeStateCheckbox from 'src/Framework/Controls/ThreeStateCheckbox';
import { useAppSelector } from 'src/store';

import { ICommon } from './index';

import {
  getProviderByCode,
  getProviderById,
  providerDisplayName
} from './utils';
import Filters from './Filters';
import {
  Tag,
  SearchContainer,
  OriginNodeContainer,
  SelectAllContainer,
  InactiveProvider,
  InactiveProvidersHeader,
  InactiveProvidersContainer,
  SelectorContainer
} from './styled';

export interface IMultiple {
  multiple: true;
  value: (string | number)[];
  enableFilters?: boolean;
  enableSelectAll?: boolean;
  ellipsisMaxWidth?: number;
  showedAll?: boolean;
  disableMinWidth?: boolean;
}

interface IProps extends IMultiple, ICommon {
  options: any[];
}

export interface IFilters {
  groups: number[];
  genders: string[];
}

export interface IShowContainer {
  gender: boolean;
  groups: boolean;
}

const Component: FC<IProps> = ({
  value,
  onChange,
  options,
  selectProps,
  disableSearch,
  blurChange,
  enableFilters,
  useCode,
  enableSelectAll,
  ellipsisMaxWidth,
  disabled,
  showedAll,
  disableMinWidth
}: IProps) => {
  const providers = useAppSelector((state) => state.user.providers.summary);
  const [showContainers, setShowContainers] = useState<IShowContainer>({
    gender: false,
    groups: false
  });
  const [filters, setFilters] = useState<IFilters>({
    groups: [],
    genders: []
  });
  const [search, setSearch] = useState('');
  const [currentValue, setCurrentValue] = useState<(string | number)[] | null>(
    null
  );
  const filtersRef = useRef(null);
  const inputRef = useRef(null);
  const inactiveProviders = useMemo(
    () =>
      value.filter((item) => !options.find((v) => `${v.value}` === `${item}`)),
    [providers, value, options]
  );

  const providersOptionsValues = useMemo(
    () => options.map((item) => item.value).join(','),
    [options]
  );

  const providersOptions = useMemo(
    () =>
      options.filter((option) => {
        const provider = useCode
          ? getProviderByCode(option.value)
          : getProviderById(option.value);
        if (!provider) return false;
        if (filters.groups.length !== 0) {
          const i = provider.groupId
            ? filters.groups.indexOf(provider.groupId)
            : -1;
          if (i === -1) {
            return false;
          }
        }

        if (filters.genders.length !== 0) {
          const i = filters.genders.indexOf(provider.gender);
          if (i === -1) {
            return false;
          }
        }
        return providerDisplayName(provider.providerId)
          .toLowerCase()
          .includes(search.toLowerCase());
      }),
    [
      providersOptionsValues,
      filters.groups.join(''),
      filters.genders.join(''),
      search
    ]
  );

  useEffect(() => {
    let currentValue = value.map((v) => `${v}`);
    if (showedAll) {
      currentValue = options.map((v) => v.value);
    }
    setCurrentValue(currentValue);
  }, [value]);

  const returnToInput = () => {
    if (inputRef.current) {
      //@ts-ignore
      inputRef.current.focus();
    }
  };

  const values = (blurChange ? currentValue : value.map((v) => `${v}`)) || [];
  const selectedProviders = values.map((v) => {
    const provider = useCode
      ? Object.values(providers).find((val) => val.code === v)
      : providers[v];
    return provider ? providerDisplayName(provider.providerId) : '';
  });
  const allSelected =
    values.length > 0 ? values.length === options.length : false;
  const allUnselected = values && values.length === 0;

  const onChangeValue = (value: any, option?: any) => {
    if (blurChange) {
      setCurrentValue(value);
    } else {
      onChange(value, option);
    }
  };

  const onKeyDownAccessibility = (e: React.KeyboardEvent) => {
    // e.stopPropagation();
    if (
      e.shiftKey &&
      e.key === 'Tab' &&
      //@ts-ignore
      e.target?.id &&
      //@ts-ignore
      e.target?.id === 'provider-selector-dropdown'
    ) {
      e.preventDefault();
      returnToInput();
    }
  };

  return (
    <SelectorContainer>
      <SelectContainer
        className="multiselector"
        disableEllipsis={false}
        type="select"
        label="Providers"
        homeRef={inputRef}
        value={values.length === 0 ? [undefined] : values}
        options={providersOptions}
        star={false}
        onChange={(value: any, option: any) => {
          const newValues = value.filter(
            (v: any) => v !== 'undefined' && v !== undefined
          );
          onChangeValue(newValues, option);
        }}
        {...selectProps}
        checkboxOptions={true}
        inputProps={{
          id: 'multiple-providers-selector',
          ref: inputRef,
          ['aria-label']:
            'Providers' +
            '. ' +
            (selectedProviders && selectedProviders.join(', ')),
          style: {
            minWidth: !disableMinWidth ? 240 : undefined,
            overflow: 'clip'
          },
          menuItemSelectedIcon: '',
          disabled,
          ...selectProps?.inputProps,
          placeholder: null,
          dropdownRender: (originNode: any) => {
            return (
              <div
                id="provider-selector-dropdown"
                tabIndex={0}
                ref={filtersRef}
                onKeyDown={(e) => onKeyDownAccessibility(e)}
              >
                {enableSelectAll && (
                  <SelectAllContainer disabled={options.length === 0}>
                    <TreeStateCheckbox
                      checkedAll={allSelected}
                      uncheckedAll={allUnselected}
                      onChange={() => {
                        if (allSelected) {
                          onChangeValue([]);
                        } else {
                          const all = options.map((v) => v.value);
                          onChangeValue(all);
                        }
                      }}
                      id="select-all"
                    >
                      Select / Unselect All
                    </TreeStateCheckbox>
                  </SelectAllContainer>
                )}
                {!disableSearch && (
                  <SearchContainer>
                    {enableFilters && (
                      <Filters
                        providers={providers}
                        filters={filters}
                        setFilters={setFilters}
                        showContainers={showContainers}
                        setShowContainers={setShowContainers}
                      />
                    )}
                    <SelectContainer
                      type="input"
                      hideLabel={true}
                      label="Search"
                      value={search}
                      onChange={(val: string) => setSearch(val)}
                      bottomMargin={false}
                      inputProps={{
                        onKeyDown: (e) => {
                          e.stopPropagation();
                          if (
                            e.key === 'Tab' &&
                            !e.shiftKey &&
                            providersOptions.length === 0
                          ) {
                            e.preventDefault();
                            returnToInput();
                          }
                        },
                        prefix: (
                          <Icon
                            size={18}
                            name="search"
                            color="rgba(0,0,0,0.2)"
                          />
                        )
                      }}
                    />
                  </SearchContainer>
                )}
                {inactiveProviders.length > 0 && (
                  <InactiveProvidersContainer>
                    <InactiveProvidersHeader>
                      Inactive Providers
                    </InactiveProvidersHeader>
                    <div>
                      {inactiveProviders.map((item) => (
                        <InactiveProvider>
                          <Checkbox
                            checked={true}
                            id={`inactive-provider-${item}-checkbox`}
                            onChange={() => {
                              onChangeValue(_.without(currentValue, item + ''));
                            }}
                          >
                            {useCode
                              ? providerDisplayName(
                                  getProviderByCode(item as string)?.providerId!
                                )
                              : providerDisplayName(item)}
                          </Checkbox>
                        </InactiveProvider>
                      ))}
                    </div>
                  </InactiveProvidersContainer>
                )}
                <OriginNodeContainer
                  id={`${
                    selectProps?.id || selectProps?.inputProps?.id
                  }-select-dropdown-container`}
                >
                  {originNode}
                </OriginNodeContainer>
              </div>
            );
          },
          showArrow: true,
          showSearch: false,
          mode: 'multiple',
          onKeyDown: (e: React.KeyboardEvent) => {
            const element = document.getElementById(
              'provider-selector-dropdown'
            );
            let opened = true;
            if (
              !element ||
              (element?.parentElement?.parentElement?.classList &&
                element?.parentElement?.parentElement?.classList.contains(
                  'ant-select-dropdown-hidden'
                ))
            ) {
              opened = false;
            }
            if (
              e.key === 'Tab' &&
              !e.shiftKey &&
              opened &&
              (e.target as HTMLElement).id === 'multiple-providers-selector'
            ) {
              e.preventDefault();
              //@ts-ignore
              filtersRef.current.focus();
            }
          },
          onDropdownVisibleChange: (value: boolean) => {
            // if (value) {
            //   setTimeout(() => {
            //     if (filtersRef.current) {
            //       (filtersRef.current as unknown as HTMLElement).focus();
            //     }
            //   }, 200);
            // }
            if (!value) {
              if (blurChange) {
                onChange(currentValue);
              }
              setSearch('');
              setShowContainers({ gender: false, groups: false });
              setFilters({ groups: [], genders: [] });
            }
          },
          tagRender: (props: any) => {
            if (values[0] === props.value) {
              if (values.length > 0) {
                return (
                  <Tag>
                    <EllipsisText maxWidth={ellipsisMaxWidth}>
                      {selectedProviders.join(', ')}
                    </EllipsisText>
                  </Tag>
                );
              } else {
                return null;
              }
            }
            return null;
          }
        }}
        containerClassName="multiProviderContainer"
      />
    </SelectorContainer>
  );
};

export default Component;
