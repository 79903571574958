import _ from 'lodash'
import { IStateReducer, IAction } from './types';

const prefix = 'APPOINTMENTS_NOT_DISCHARGED_';

export const actionsTypes = {
    SET: `${prefix}SET`,
};

const initialState: IStateReducer = {
    notDischargedCountSummary: [],
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(initialState),
    action: IAction
): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET:
            return function () {
                const currentArr = [...state.notDischargedCountSummary]
                action.payload?.forEach((v: any) => {
                    const i = currentArr.findIndex((val) => val.clinicCode === v.clinicCode)
                    if (i !== -1) {
                        currentArr[i] = v
                    } else {
                        currentArr.push(v)
                    }
                })
                return {
                    ...state,
                    notDischargedCountSummary: currentArr
                }
            }();
        default:
            return state;
    }
};

export default Reducer;
