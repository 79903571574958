//@ts-ignore
import baseEditForm from 'formiojs/components/_classes/component/Component.form';

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: 'display',
        components: [
          {
            // You can ignore existing fields.
            key: 'placeholder',
            ignore: true
          },
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true
          },
          {
            key: 'autofocus',
            disabled: true,
            ignore: true
          }
        ]
      },
      {
        key: 'data',
        components: [
          {
            key: 'multiple',
            ignore: true
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Values',
            key: 'data',
            tooltip: 'Values tooltip',
            weight: 10,
            reorder: true,
            defaultValue: [
              {
                label: '',
                value: '',
                score: '',
                isPromptForExtraInfo: false
              }
            ],
            components: [
              {
                label: 'Label',
                key: 'label',
                input: true,
                type: 'textfield',
                validate: {
                  required: true
                }
              },
              {
                label: 'Value',
                key: 'value',
                input: true,
                type: 'number',
                validate: {
                  required: true
                }
              },
              {
                label: 'Score',
                key: 'score',
                input: true,
                type: 'number'
              },
              {
                type: 'checkbox',
                input: true,
                weight: 180,
                label: 'Extra Answer',
                key: 'isPromptForExtraInfo',
                tooltip: 'Extra answer tooltip'
              }
            ]
          }
        ]
      },
      {
        key: 'validation',
        components: []
      },
      {
        key: 'api',
        components: []
      },
      {
        key: 'conditional',
        components: []
      },
      {
        key: 'logic',
        components: []
      }
    ],
    ...extend
  );
};
