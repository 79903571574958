
import _ from "lodash";

import { IStateReducer, IAction, IFilter, IPatientItem } from './types'

const prefix = `MEDICAL_`

export const actionsTypes = {
    SET_NOTE_FILTER: `${prefix}SET_NOTE_FILTER`,
    SET_NOTE_SORT: `${prefix}SET_NOTE_SORT`,
    SET_IN_PROGRESS_NOTE_FILTER: `${prefix}SET_IN_PROGRESS_NOTE_FILTER`,
    SET_IN_PROGRESS_NOTE_SORT: `${prefix}SET_IN_PROGRESS_NOTE_SORT`,
    SET_INIT_PATIENT: `${prefix}SET_INIT_PATIENT`,
}

export const initialFilter: IFilter = {
    authorUser: null,
    authorProvider: null,
    billingProvider: null,
    clinic: null,
    status: null,
    noteType: null,
    showDeleted: false,
    from: null,
    to: null,
    source: undefined
}

const initPatient: IPatientItem = {
    list: {
        sortNotes: { name: null, date: "desc" },
        filter: _.cloneDeep(initialFilter),
    },
    inProgress: {
        sortNotes: { name: null, date: "desc" },
        filter: _.cloneDeep(initialFilter),
    }
}
const initialState: IStateReducer = {
    patients: {}
};

const Reducer = (state: IStateReducer = _.cloneDeep(initialState), action: IAction): IStateReducer => {
    const checkExist = (patientId: number | string) => {
        return state.patients[patientId] || _.cloneDeep(initPatient)
    }
    switch (action.type) {
        case actionsTypes.SET_INIT_PATIENT: {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: checkExist(action.patientId)
                }
            }
        }
        case actionsTypes.SET_IN_PROGRESS_NOTE_SORT: {
            const patient = checkExist(action.patientId)
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patient,
                        inProgress: {
                            ...patient.inProgress,
                            sortNotes: {
                                ...patient.inProgress.sortNotes,
                                ...action.payload
                            }
                        }

                    }
                }
            }
        }
        case actionsTypes.SET_IN_PROGRESS_NOTE_FILTER: {
            const patient = checkExist(action.patientId)
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patient,
                        inProgress: {
                            ...patient.inProgress,
                            filter: {
                                ...patient.inProgress.filter,
                                ...action.payload
                            }
                        }

                    }
                }
            }
        }
        case actionsTypes.SET_NOTE_SORT: {
            const patient = checkExist(action.patientId)
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patient,
                        list: {
                            ...patient.list,
                            sortNotes: {
                                ...patient.list.sortNotes,
                                ...action.payload
                            }
                        }

                    }
                }
            }
        }
        case actionsTypes.SET_NOTE_FILTER: {
            const patient = checkExist(action.patientId)
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patient,
                        list: {
                            ...patient.list,
                            filter: {
                                ...patient.list.filter,
                                ...action.payload
                            }
                        }

                    }
                }
            }
        }
        default:
            return state;
    }
};

export default Reducer;
