import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import moment from "moment";
import { IConvoThreadSimpleDto } from "../../../store/types";

export const SET_NOTE_THREADS = "SET_NOTE_THREADS";
export const SET_SHOW_COMMENTS_BAR = "SET_SHOW_COMMENTS_BAR";

export const setNoteThreads = (data: string, noteId: any) => {
    return {
        type: SET_NOTE_THREADS,
        noteId,
        threads: data
    }
};

export const toogleCommentsBar = () => {
    return {
        type: SET_SHOW_COMMENTS_BAR,
    }
};

export const getNoteThreads = async (noteId: number, callBack?: any): Promise<Record<string, IConvoThreadSimpleDto> | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.NOTE_THREADS,
            {
                withCredentials: true,
                params: {
                    noteId,
                    orderby: "position",
                    sortType: "desc",
                    isActive: true
                }
            })
        if (response.data.result) {
            const data = response.data.result.summary
            if (callBack) {
                callBack();
            }
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
};

export const createNoteThread = async (thread: { noteId: number; state: string | number; position: number; dts: string; }) => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            ApiUrl.NOTE_THREADS,
            thread,
            { withCredentials: true }
        )
        if (response.data.result) {

            return true
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}

export const updateNoteThread = async (noteId: number, threadId: number, state: string | number) => {
    try {
        const data: any = {
            state
        }
        const response = await APIHandler.AxiosInstance.patch(
            ApiUrl.NOTE_THREADS + `/${threadId}`,
            {
                ...data
            },
            { withCredentials: true }
        )
        if (response.data.result) {
            if (state === 2) {
                await updateReadNoteThread(0, threadId, true)
            }
            return true
        }
        return
    } catch (e) {
        console.log({ e });
        return
    }
}

export const updateReadNoteThread = async (noteId: number, threadId: number, read: boolean) => {
    try {
        const response = await APIHandler.AxiosInstance.patch(
            ApiUrl.NOTE_THREADS + `/${threadId}`,
            {
                isReadByUser: read,
                dts: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
            },
            { withCredentials: true }
        )
        if (response.data.result) {
            return true
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}

export const createNoteThreadComment = async (thread: { noteId: number; state: string | number; position: number; dts: string; }, comment: { providerId: number | string | null; text: string; dts: string; }, callBack: any) => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            ApiUrl.NOTE_THREADS,
            thread,
            { withCredentials: true }
        )
        if (response.data.result) {
            const threadId: number = response.data.result.id;
            const res = await createNoteComment(thread.noteId, { ...comment, threadId }, callBack);
            if (res) {
                return true
            }
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}

export const createNoteComment = async (noteId: number, comment: { threadId: number, providerId: number | string | null; text: string; dts: string; }, callBack: any) => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            ApiUrl.NOTE_COMMENTS,
            {
                ...comment,
                convoThreadId: comment.threadId
            },
            { withCredentials: true }
        )
        if (response.data.result) {
            return true
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}

export const updateNoteThreadComment = async (noteId: number, commentId: number, text: string) => {
    try {
        const response = await APIHandler.AxiosInstance.patch(
            ApiUrl.NOTE_COMMENTS + `/${commentId}`,
            {
                text,
            },
            { withCredentials: true }
        )
        if (response.data.result) {
            return true
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}

export const deleteNoteThreadComment = async (noteId: number, commentId: number, comment: string) => {
    try {
        const response = await APIHandler.AxiosInstance.patch(
            ApiUrl.NOTE_COMMENTS + `/${commentId}`,
            {
                isActive: false,
            },
            { withCredentials: true }
        )
        if (response.data.result) {
            return true
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}

export const deleteNoteThread = async (noteId: number, threadId: number) => {
    try {
        const response = await APIHandler.AxiosInstance.patch(
            ApiUrl.NOTE_THREADS + `/${threadId}`,
            {
                isActive: false
            },
            { withCredentials: true }
        )
        if (response.data.result) {
            return true
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}

export const deleteNoteThreadAndComment = async (noteId: number, commentId: number, threadId: number) => {
    try {
        const response = await APIHandler.AxiosInstance.patch(
            ApiUrl.NOTE_COMMENTS + `/${commentId}`,
            {
                isActive: false,
            },
            { withCredentials: true }
        )
        if (response.data.result) {
            return true
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}