import { IStateReducer, IAction } from './types';

const prefix = 'USER_GROUPS_';

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_PATIENTS: `${prefix}SET_PATIENTS`,
};

const initialState: IStateReducer = {
    providers: {},
    patients: {},
};

const Reducer = (
    state: IStateReducer = { ...initialState },
    action: IAction
): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET:
            return {
                ...state,
                providers: action.payload
            };
        case actionsTypes.SET_PATIENTS:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export default Reducer;
