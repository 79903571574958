import _ from "lodash";

import { IStateReducer, IAction } from './types'

export const actionsTypes = {
  REMOVE_SINGLE_SUBJECT_FROM_EVENT: 'REMOVE_SINGLE_SUBJECT_FROM_EVENT',
  ADD_SCHEDULE_EVENT: 'ADD_SCHEDULE_EVENT',
  REMOVE_SCHEDULE_EVENT: 'REMOVE_SCHEDULE_EVENT'
}

const initState: IStateReducer = {};

export const ScheduleEventsReducer = (
  state: IStateReducer = _.cloneDeep(initState),
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.ADD_SCHEDULE_EVENT: {
      let event = action.payload.scheduleEvent;
      if (event) {
        let uid = event.uid;
        state[uid] = action.payload;
      }
      return {
        ...state
      };
    }
    case actionsTypes.REMOVE_SCHEDULE_EVENT: {
      let uid = action.payload;
      if (uid) {
        delete state[uid];
      }
      return {
        ...state
      };
    }
    case actionsTypes.REMOVE_SINGLE_SUBJECT_FROM_EVENT: {
      let updatedEventInfo = state[action.payload.uid];
      const index = updatedEventInfo.patients.indexOf(action.payload.subjectId);
      if (index > -1) {
        updatedEventInfo.patients.splice(index, 1);
        updatedEventInfo.patientsInfo.splice(index, 1);
      }
      state[action.payload.uid] = { ...updatedEventInfo };
      return {
        ...state
      };
    }
    default:
      return state;
  }
};
