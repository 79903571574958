import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div`
  width: 100%;
  .title-uppercase {
    .ant-collapse-header-text {
      text-transform: uppercase;
    }
  }
  
  .ant-collapse > .ant-collapse-item {
    background-color: white;
  }

  .ant-collapse-header {
    pointer-events: initial;
    font-family: Encode Sans Expanded;
    font-weight: 500 !important;
    color: ${globalStyleVariables.middleGrey} !important;
    border-top: 1px solid ${globalStyleVariables.borderColor};
    padding-left: 24px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .ant-collapse-content-box {
    padding-left: 24px !important;
  }

  .no-side-paddings {
    .ant-collapse-content > .ant-collapse-content-box {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .no-vertical-paddings {
    .ant-collapse-content > .ant-collapse-content-box {
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    }
  }

  .no-header-side-paddings {
    .ant-collapse-header {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .no-header-vertical-paddings {
    .ant-collapse-header {
      padding-top: 2px !important;
      padding-bottom: 8px !important;
    }
  }

  .no-border {
    .ant-collapse-header {
      border-top: unset;
    }
  }
`;
