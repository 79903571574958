import { BucketsList, generateSubscriptionFilter } from "src/Framework/ApiSubscription";
import { RequestHelper } from "src/Framework/ApiSubscription/RequestHelper";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ITraineeGoal } from "../types";
import { setSummary, onCreateUpdateItem } from "./Setters";

type TypeGetArgs = [providerIds: (string | number)[], key: keyof ITraineeGoal]

export const SubscriptionData = (...args: TypeGetArgs) => {
    const [providerIds, key] = args
    return ({
        bucket: BucketsList.traineeGoal,
        filter: generateSubscriptionFilter(BucketsList.traineeGoal, providerIds, key)
    })
}

export const { post, get, put, unsubscribe, findAndUnsubscribe } = new RequestHelper<ITraineeGoal, TypeGetArgs>({
    link: ApiUrl.TraineeGoals,
    onCreateUpdateItem,
    subscription: {
        subscriptionData: SubscriptionData,
        setSummary
    }
})

export const deleteGoal = async (id: number): Promise<boolean | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.delete(`${ApiUrl.TraineeGoals}/${id}`, {
            withCredentials: true,
        });
        if (response.data.success) {
            return true
        }
        return
    } catch (e) {
        return
    }
}

export const postRecalculate = async (id: number): Promise<boolean | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(`${ApiUrl.TraineeGoals}/${id}/Recalculate`, {}, {
            withCredentials: true,
        });
        if (response.data.success) {
            return true
        }
        return
    } catch (e) {
        return
    }
}