import _ from 'lodash';
import { IStateReducer, IAction } from './types';

const prefix = 'PROVIDER_GOALS_';

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_LOADING: `${prefix}SET_LOADING`,
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_SORT: `${prefix}SET_SORT`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

const initialState: IStateReducer = {
    providers: {}
};

export const defaultData: IStateReducer['providers'][number] = {
    summary: {},
    loading: false,
    filter: {
    },
    sort: {
        sortBy: null,
        sortType: null
    }
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(initialState),
    action: IAction
): IStateReducer => {
    const providerData = () => state.providers[action.providerId] || _.cloneDeep(defaultData);
    const data = providerData();
    const actions: IActions = {
        [actionsTypes.SET_SORT]: () => {
            return {
                ...state,
                providers: {
                    ...state.providers,
                    [action.providerId]: {
                        ...data,
                        sort: action.payload
                    }
                }
            };
        },
        [actionsTypes.SET_FILTER]: () => {
            return {
                ...state,
                providers: {
                    ...state.providers,
                    [action.providerId]: {
                        ...data,
                        filter: action.payload
                    }
                }
            };
        },
        [actionsTypes.SET_LOADING]: () => {
            return {
                ...state,
                providers: {
                    ...state.providers,
                    [action.providerId]: {
                        ...data,
                        loading: action.payload
                    }
                }
            };
        },
        [actionsTypes.SET]: () => {
            return {
                ...state,
                providers: {
                    ...state.providers,
                    [action.providerId]: {
                        ...data,
                        summary: action.payload
                    }
                }
            };
        }
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
