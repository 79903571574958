// import store from 'src/store';
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl";

import { IFormioForm } from './types'

export const getFormRecord = async ({
    seedId,
}: {
    seedId: string | number
}): Promise<IFormioForm | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.GET_TEMPLATE_FORM_RECORD_DETAIL_NEW, {
            withCredentials: true,
            params: {
                seedId,
                'v': '2'
            }
        })
        if (response.data.success) {
            return response.data.result
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}
