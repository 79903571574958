import React, { FC, useEffect, useState } from 'react';

import FormContainer from 'src/App/FormBuilder/FormContainer';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import * as buildingBlockActions from 'src/App/Admin/Pages/FormBuilder/Lists/BuildingBlocks/store/Actions';
import * as buildingFormActions from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/Actions';
import { IFullBuildBlock } from 'src/App/Admin/Pages/FormBuilder/Lists/BuildingBlocks/store/types';

import { Container } from './styled';
import { IFullBuildingForm } from '../../Lists/Forms/store/types';

import { Type } from './types';
import FormPreviewContainer from './FormPreviewContainer';

interface IProps {
  type: Type;
  value: {
    id: number;
  };
  disableOnlyPublished?: boolean;
}

const Component: FC<IProps> = ({
  value,
  type,
  disableOnlyPublished
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IFullBuildBlock | null>(null);
  const [dataForm, setDataForm] = useState<IFullBuildingForm | null>(null);
  const [previewData, setPreviewData] = useState<IFullBuildingForm | null>(
    null
  );
  const getDataBlock = async () => {
    setLoading(true);
    const res = await buildingBlockActions.getFullBuildingBlockForPreview(
      value.id,
      { disableOnlyPublished }
    );
    if (res) {
      setData(res);
    }
    setLoading(false);
  };
  const getDataForm = async () => {
    setLoading(true);
    const res = await buildingFormActions.getFullBuildingFormForPreview(
      value.id,
      { disableOnlyPublished }
    );
    if (res) {
      if (res?.versions[0]?.displaySchema === null) {
        setPreviewData(res);
      } else {
        setDataForm(res);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (type === 'block') {
      getDataBlock();
    }
    if (type === 'form') {
      getDataForm();
    }
  }, []);
  return (
    <Container>
      {loading && <AbsoluteLoader />}
      {dataForm && (
        <FormContainer
          schema={dataForm?.versions[0]?.displaySchema || {}}
          submission={{}}
          onChange={() => {}}
        />
      )}
      {data && (
        <FormContainer
          schema={data.versions[0]?.schema || {}}
          submission={{}}
          onChange={() => {}}
        />
      )}
      {previewData && <FormPreviewContainer data={previewData} />}
    </Container>
  );
};

export default Component;
