import store from 'src/store';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

import { IGenericTypes } from '../types';

import { actionsTypes } from '../index';

export const TYPES: {
  [key in keyof IGenericTypes]: string;
} = {
  typeSpecialty: ApiUrl.TypeSpecialty,
  typeServiceProvider: ApiUrl.TypeServiceProvider,
  typeGroupProvider: ApiUrl.GroupProvider,
  typeGender: ApiUrl.TypeGender,
  medicatDemo: ApiUrl.MedicatDemo,
  typeCampusStatus: ApiUrl.TypeCampusStatus,
  typeEligibility: ApiUrl.TypeEligibility,
  typeSchool: ApiUrl.TypeSchool,
  typeStanding: ApiUrl.TypeStanding,
  typeClass: ApiUrl.TypeClass,
  typeResidency: ApiUrl.TypeResidency,
  typeRelationship: ApiUrl.TypeRelationship,
  CampusHousing: ApiUrl.CampusHousing,
  typeMedicareSecondaryPayor: ApiUrl.typeMedicareSecondaryPayor,
  typePatientStatus: ApiUrl.typePatientStatus,
  typeOver90DayReasons: ApiUrl.typeOver90DayReasons,
  typeDisability: ApiUrl.typeDisability,
  typeServiceAuth: ApiUrl.typeServiceAuth,
  typeCHAPReferral: ApiUrl.typeCHAPReferral,
  typeClaim: ApiUrl.typeClaim,
  typeEPSDTReferralCode: ApiUrl.typeEPSDTReferralCode,
  typeAbortionSterilization: ApiUrl.typeAbortionSterilization,
  typeImmuneStatus: ApiUrl.typeImmuneStatus,
  typeStudentStatus: ApiUrl.TypeStudentStatus,
};

const setGenericTypes = (payload: any) => {
  store.dispatch({
    payload,
    type: actionsTypes.SET_GENERIC_TYPES
  });
};

export const get = async (types: (keyof typeof TYPES)[]) => {
  try {
    const arr = types.map((v) =>
      APIHandler.AxiosInstance.get(TYPES[v], { withCredentials: true })
    );
    // @ts-ignore
    const response = await Promise.allSettled(arr);
    const responses = {};
    response.forEach((res: any, index: number) => {
      if (res.value && res.value.data.success) {
        const data = res.value.data.result.summary;
        responses[types[index]] = data;
      }
    });
    setGenericTypes({
      ...responses
    });
    return responses;
  } catch (e) {
    console.log({ e });
    return;
  } finally {
  }
};
