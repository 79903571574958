import React, { FC, useEffect, useRef } from 'react';

import CreateNewPassword, { IModalHandles } from './CreateNewPassword';

interface IProps {}

const Component: FC<IProps> = ({}: IProps) => {
  const modalRef = useRef<IModalHandles>(null);
  useEffect(() => {
    modalRef.current?.show({});
  }, []);
  return (
    <div>
      <CreateNewPassword ref={modalRef} />
    </div>
  );
};

export default React.memo(Component);
