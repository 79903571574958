import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

interface IPrintContainer {
  newTab?: boolean;
  hasHeader?: boolean;
}

export const PrintContainer = styled.div<IPrintContainer>`
  min-height: 250px;
  display: flex;
  flex-direction: column;
  max-height: ${({ newTab, hasHeader }) =>
    newTab ? `calc(100vh - 110px${hasHeader ? ' - 60px' : ''})` : '70vh'};
`;

interface IViewerContainer {
  rotate: number;
  newTab?: boolean;
  hasHeader?: boolean;
}

export const ViewerContainer = styled.div<IViewerContainer>`
  position: relative;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  max-height: ${({ newTab, hasHeader }) =>
    newTab ? `calc(100vh - 110px${hasHeader ? ' - 60px' : ''})` : '70vh'};
  overflow: auto;

  &.html {
    height: 750px;
  }

  .react-doc-viewer {
    overflow: auto;

    &.html {
      height: 750px;
    }

    .react-doc-viewer {
      overflow: auto;
    }

    .react-pdf__Document {
      overflow: auto;
      width: 100%;
    }

    .react-pdf__Page__canvas {
      transform-origin: center center;
      transform: rotate(${({ rotate }) => rotate * 90}deg)
        translateY(
          ${({ rotate }) => (rotate === 1 ? -15 : rotate === 3 ? 15 : 0)}%
        );
    }

    #pdf-pagination {
      margin-right: 28px;
    }

    #pdf-download {
      display: none;
    }
  }

  .react-pdf__Document {
    overflow: auto;
    width: 100%;
  }

  .react-pdf__Page__canvas {
    transform-origin: center center;
    transform: rotate(${({ rotate }) => rotate * 90}deg)
      translateY(
        ${({ rotate }) => (rotate === 1 ? -15 : rotate === 3 ? 15 : 0)}%
      );
  }

  #pdf-pagination {
    margin-right: 28px;
  }

  #pdf-download {
    display: none;
  }

  #pdf-renderer {
    #pdf-controls {
      justify-content: flex-start !important;
    }
  }
  .react-pdf__Page__textContent.textLayer, .react-pdf__Page__annotations.annotationLayer {
    display: none;
  }
`;

export const TiffContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TiffItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
`;

export const RotateButtonContainer = styled.div`
  position: absolute;
  left: 145px;
  top: 8px;
  z-index: 10;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0px;
  margin: 0px 0px 0px 5px;
  text-align: center;
  font-size: 18px;
  border: 0px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 35px;
  opacity: 1;
  pointer-events: all;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 3px;
`;

export const ImageViewerContainer = styled.div`
  ul {
    list-style-type: none;

    li {
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 5px 0;

      img {
        cursor: pointer;
      }
    }
  }
`;
