import _ from 'lodash';
import { IDataPagination } from './types';

export interface IStateReducer {
    patients: {
        [patientId: string]: {
            data: IDataPagination
        }
    }
}

export interface IAction {
    type: typeof actionsTypes[keyof typeof actionsTypes];
    payload: any;
    patientId: string | number
}


const prefix = 'ADMINISTRATIVE_NOTES_';

export const actionsTypes = {
    SET_DATA: `${prefix}SET_DATA`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

export const defaultData: IStateReducer = {
    patients: {}
};

export const defaultPatientData = (): IStateReducer['patients'][''] => ({
    data: {
        total: 0,
        summary: [],
        startIndex: 0,
        count: 20
    }
})

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const patientData = () => state.patients[action.patientId] || defaultPatientData()
    const actions: IActions = {
        [actionsTypes.SET_DATA]: () => {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patientData(),
                        data: action.payload
                    }
                }
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
