import React, { useState } from 'react';

import EllipsisText from '../../EllipsisText';
import SelectContainer from '../../SelectContainer';
import { ISelect } from 'src/Framework/Controls/SelectContainer/Components/Select';

import { Tag, OriginNodeContainer, Container } from './styled';

interface IOption<T> {
  value: T;
  label: string | React.ReactElement;
}

/**
 * @param T - type of your value field
 */

export interface IProps<T = string | number> {
  id: string;
  label: string | React.ReactNode;
  value: T[];
  onChange: (value: T[], option: any) => any;
  onBlur?: any;
  options: IOption<T>[];
  allOptions?: IOption<T>[];
  selectProps?: Partial<ISelect>;
  ellipsisTextMaxWidth?: number;
}

/**
 * Multiple selector all fields that expect this component you can check here -> {@link IProps}
 * @param T - type of your value field
 */
const Component = <T,>(props: IProps<T>) => {
  const {
    label,
    value,
    onBlur,
    onChange,
    selectProps,
    options,
    id,
    allOptions,
    ellipsisTextMaxWidth
  } = props;
  const [visible, setVisible] = useState(false);
  const selectedOptions = value
    .map((v) => {
      if (allOptions) {
        return allOptions.find((val) => val.value === v)?.label;
      }
      return options.find((val) => val.value === v)?.label;
    })
    .filter((v) => v);
  return (
    <Container>
      <SelectContainer
        label={label}
        onBlur={() => onBlur && onBlur(value)}
        {...selectProps}
        type="select"
        value={value}
        onChange={onChange}
        options={options}
        checkboxOptions={true}
        allOptions={allOptions}
        className="multiselector"
        inputProps={{
          ['aria-label']:
            label + '. ' + (selectedOptions && selectedOptions.join(', ')),
          ...selectProps?.inputProps,
          id,
          showArrow: true,
          mode: 'multiple',
          open: visible,
          showSearch: false,
          menuItemSelectedIcon: '',
          dropdownRender: (originNode: any) => (
            <div onMouseDown={(e) => e.preventDefault()}>
              <OriginNodeContainer id={`${id}-select-dropdown-container`}>
                {originNode}
              </OriginNodeContainer>
            </div>
          ),
          onDropdownVisibleChange: (value: boolean) => {
            if (selectProps?.inputProps?.onDropdownVisibleChange) {
              selectProps.inputProps.onDropdownVisibleChange(value);
            }
            if (value !== visible) {
              setVisible(value);
            }
          },
          tagRender: (props: any) => {
            if (value.length > 0 && value[0] === props.value) {
              return (
                <Tag>
                  <EllipsisText maxWidth={ellipsisTextMaxWidth || 500}>
                    {selectedOptions.join(', ')}
                  </EllipsisText>
                </Tag>
              );
            }
            return null;
          }
        }}
      />
    </Container>
  );
};

export default Component;
