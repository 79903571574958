import React, { FC } from 'react';
import { DatePicker } from 'antd';
import { IOtherSelects } from '../index';
import moment from 'moment';
import classNames from 'classnames';
import { dateRangeFormat } from 'src/Framework/Shared/Shared';

const RangePicker = DatePicker.RangePicker;

const Component: FC<IOtherSelects> = (props: IOtherSelects) => {
  const {
    inputProps,
    range,
    bottomMargin,
    hasError,
    className,
    onChange,
    containerRef,
    connectToContainerOnScroll
  } = props;
  return (
    <>
      <label htmlFor={inputProps.id} className="visually-hidden">
        {props.label}
      </label>
      <RangePicker
        aria-required={props.star}
        aria-label={props.label}
        {...inputProps}
        getPopupContainer={
          inputProps.getPopupContainer
            ? inputProps.getPopupContainer
            : connectToContainerOnScroll && containerRef
            ? () => containerRef.current
            : undefined
        }
        showTime={false}
        value={[
          range && range[0]
            ? moment(range[0], dateRangeFormat).startOf('day')
            : null,
          range && range[1]
            ? moment(range[1], dateRangeFormat).endOf('day')
            : null
        ]}
        className={classNames({
          select: true,
          margin: bottomMargin,
          error: hasError,
          [`${className}`]: className
        })}
        onChange={(momentValues: any, dateValues: any) => {
          console.log('dateValues', dateValues);
          onChange && onChange([dateValues[0] || null, dateValues[1] || null]);
        }}
        popupStyle={{
          zIndex: 10000
        }}
        format={[dateRangeFormat, dateRangeFormat]}
      />
    </>
  );
};

export default Component;
