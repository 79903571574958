import React, { FC } from 'react';
import * as DOMPurify from 'dompurify';

interface IProps {
  value: string | undefined | null;
}

const HtmlRenderer: FC<IProps> = ({ value }: IProps) => {
  return (
    <div className="sun-editor-editable" style={{ padding: 0 }}>
      <div
        dangerouslySetInnerHTML={{
          __html: value ? DOMPurify.sanitize(value) : ''
        }}
      />
    </div>
  );
};

export default React.memo(HtmlRenderer);
