import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

import store from 'src/store';

import { ApiUrl as ScheduleApiUrl } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ApiUrl';
import { setPreference } from 'src/Activities/Compliance/Store/ComplianceActions';
import { updateCurrentUserPreference } from 'src/App/Preferences/Actions';
import { IFilters } from './ActivityAppointmentsReducer';

export const SET_APPOINTMENTS = 'SET_APPOINTMENTS';
export const SET_DATEROWS = 'SET_DATEROWS';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_ROOM_TYPES = 'SET_ROOM_TYPES';
export const SET_EMR_TYPES = 'SET_EMR_TYPES';
export const SET_ACUITY_TYPES = 'SET_ACUITY_TYPES';
export const SET_PAGINATION_DATA = 'SET_PAGINATION_DATA';
export const SET_LOADING = 'SET_LOADING';

export const setFiltersPref = (filters: Partial<IFilters>) => {
  const userId = localStorage.getItem('userId');
  const data = {
    userId,
    preferences: [
      {
        userId,
        id: 3029,
        name: 'ActivityApptFilters',
        value: JSON.stringify(filters),
        defaultValue: ''
      }
    ]
  };
  updateCurrentUserPreference(data.preferences);
  setPreference(data);
};

export const getAppointments = (filters: IFilters, callback?: any) => {
  const data: any = {
    startDate: filters.fromDate,
    endDate: filters.toDate,
    clinicCodes: filters?.clinicCodes,
    providerCodes: filters.providersCodes
  };
  APIHandler.AxiosInstance.post(
    ScheduleApiUrl.GET_ACTIVITY_APPOINTMENTS,
    data,
    {
      withCredentials: true
    }
  )
    .then((response) => {
      let result = response.data.result;
      if (response.data.success) {
        setAppointments(result.events);
        callback && callback(result.events);
      }
    })
    .catch(() => {
      callback && callback([]);
    });
};

export const getAppointmentsNew = (callback?: Function) => {
  const filters = store.getState().home.appointments.filters;
  const data: any = {
    startDate: filters.fromDate,
    endDate: filters.toDate,
    clinicCodes: filters?.clinicCodes,
    providerCodes: filters.providersCodes,
    includeCancellations: filters.showCancelled
  };
  setLoading(true);
  APIHandler.AxiosInstance.post(
    ScheduleApiUrl.GET_ACTIVITY_APPOINTMENTS,
    data,
    {
      withCredentials: true
    }
  )
    .then((response) => {
      let result = response.data.result;
      if (response.data.success) {
        callback && callback();
        setAppointments(result.events);
      }
    })
    .catch((error) => console.error(error))
    .finally(() => setLoading(false));
};

export const getRoomTypes = () => {
  APIHandler.AxiosInstance.get(ApiUrl.ROOM_TYPES, { withCredentials: true })
    .then((response) => {
      if (response.data.result) {
        setRoomTypes(response.data.result.summary);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getAcuityTypes = () => {
  APIHandler.AxiosInstance.get(ApiUrl.ACUITY_TYPES, { withCredentials: true })
    .then((response) => {
      if (response.data.result) {
        setAcuityTypes(response.data.result.summary);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateColumnPreferences = async (value: any) => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: 3020,
        userId,
        name: 'ActivityAppointmentCols',
        value: JSON.stringify(value),
        defaultValue: ''
      }
    ]
  };
  try {
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.log({ error });
  }
};

export const getEmrTypes = () => {
  APIHandler.AxiosInstance.get(ApiUrl.EMR_TYPES, { withCredentials: true })
    .then((response) => {
      if (response.data.result) {
        setEmrTypes(response.data.result.summary);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateActivityAppointment = async (payload: any) => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.APPOINTMENTS}/${payload.appointmentId}/activity`,
      {
        ...payload
      },
      { withCredentials: true }
    );
    if (response.data.success) {
      return response.data.result;
    }
    return;
  } catch (e) {
    return;
  }
};

export const patchActivityAppointment = async (payload: any) => {
  try {
    const res = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.APPOINTMENTS}/${payload.appointmentId}`,
      {
        ...payload
      },
      { withCredentials: true }
    );
    if (res.data.success) {
      return res.data.result;
    }
    return;
  } catch (e) {
    return;
  }
};

export const setAppointments = (appointments: any[]) => {
  store.dispatch({
    type: SET_APPOINTMENTS,
    payload: appointments
  });
};

export const setDateRows = (dateRows: any[]) => {
  store.dispatch({
    type: SET_DATEROWS,
    payload: dateRows
  });
};

export const setFilters = (filters: IFilters) => {
  store.dispatch({
    type: SET_FILTERS,
    payload: filters
  });
};
export const setLoading = (newState: boolean) => {
  store.dispatch({
    type: SET_LOADING,
    payload: newState
  });
};

const setRoomTypes = (roomTypes: any[]) => {
  store.dispatch({
    type: SET_ROOM_TYPES,
    payload: roomTypes
  });
};

const setAcuityTypes = (acuityTypes: any[]) => {
  store.dispatch({
    type: SET_ACUITY_TYPES,
    payload: acuityTypes
  });
};

const setEmrTypes = (emrTypes: any[]) => {
  store.dispatch({
    type: SET_EMR_TYPES,
    payload: emrTypes
  });
};

export const setPagination = (pagination: any) => {
  store.dispatch({
    type: SET_PAGINATION_DATA,
    payload: pagination
  });
};
