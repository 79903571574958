import { store } from "../../../../../index";
import { APIHandler } from "../../../../../Framework/Communication/ServerProxy";
import { ApiUrl } from "../../../../../Framework/Common/ApiUrl";

export const SET_TXN_SETS = "SET_TXN_SETS";


export const setTxnSets = (data: string) => {
    store.dispatch({
        type: SET_TXN_SETS,
        payload: data
    })
};

let loadingSets = false

export const getTxnSets = async () => {
    if (loadingSets) return
    try {
        loadingSets = true;
        const response = await APIHandler.AxiosInstance.post(
            ApiUrl.GET_SET_INFO_FOR_PLAN,
            {},
            { withCredentials: true }
        )
        if (response.status === 200 && response.data.result) {
            setTxnSets(response.data.result);
        }
    } catch (e) {
        console.log({ e })
    } finally {
        loadingSets = false;
    }
};