import _ from 'lodash';
import { IAction, IStateReducer } from './types';

const prefix = 'PROVIDERS_';

export const actionsTypes = {
  SET_ALL_PROVIDERS: `${prefix}SET_ALL_PROVIDERS`,
  SET_LOADING: `${prefix}SET_LOADING`,
  SET_FILTER: `${prefix}SET_FILTER`,
  SET_SUPERVISOR: `${prefix}SET_SUPERVISOR`,
  SET_FACILITIES: `${prefix}SET_FACILITIES`,
  SET_SORT: `${prefix}SET_SORT`
};

const initialState: IStateReducer = {
  summary: {},
  supervisor: [],
  facilities: {},
  loading: false,
  filter: {
    search: '',
    showInactive: false
  },
  sort: {
    sortBy: 'code',
    sortType: 'asc'
  }
};

const Reducer = (
  state: IStateReducer = { ..._.cloneDeep(initialState) },
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET_SORT:
      return {
        ...state,
        sort: action.payload
      };
    case actionsTypes.SET_FACILITIES:
      return {
        ...state,
        facilities: action.payload
      };
    case actionsTypes.SET_SUPERVISOR:
      return {
        ...state,
        supervisor: action.payload
      };
    case actionsTypes.SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload
        }
      };
    case actionsTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionsTypes.SET_ALL_PROVIDERS:
      return {
        ...state,
        summary: { ...action.payload }
      };
    default:
      return state;
  }
};

export default Reducer;
