//@ts-ignore
import baseEditForm from 'formiojs/components/_classes/component/Component.form';
import { componentType } from '.';
import { defaultValueField } from '../utils';

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: 'display',
        components: [
          {
            // You can ignore existing fields.
            key: 'placeholder',
            ignore: true
          },
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true
          },
          {
            key: 'autofocus',
            disabled: true,
            ignore: true
          }
        ]
      },
      {
        key: 'data',
        components: [
          {
            type: componentType,
            key: defaultValueField,
            weight: 10,
            reorder: true
          },
          {
            key: 'multiple',
            ignore: true
          }
        ]
      },
      {
        key: 'validation',
        components: []
      },
      {
        key: 'api',
        components: []
      },
      {
        key: 'conditional',
        components: []
      },
      {
        key: 'logic',
        components: []
      }
    ],
    ...extend
  );
};
