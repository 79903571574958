export enum EnumFields {
    SocialSecurityNo = "socialSecurityNo",
    OtherId = "otherId",
    ChartNo = "chartNo",
    Eligibility = "eligibility",
    Age = "age",
    PrimaryInsurance = "primaryInsurance",
    SecondaryInsurance = "secondaryInsurance",
    LastName = "lastName",
    MobilePhone = "cellPhone",
    Sex = "sex",
    AccountNo = "accountNo",
    BirthDate = "birthDate",
    HomePhone = "homePhone",
    WorkPhone = "workPhone",
    Comment = "comment",
    Suffix = "suffix",
    Address = "address",
    CampusAddress = "campusAddress",
    Email = "email",
    EnrollmentDate = "enrollmentDate",
    International = "international",
    Major = "major",
    SchoolName = "schoolName",
    FirstName = "firstName",
    Standing = "standing",
    Employer = "employer",
    FeeSchedule = "feeSchedule",
    ProviderCode = "providerCode",
    BillingMethod = "billingMethod",
    Employed = "employed",
    MaritalStatus = "maritalStatus",
    Pager = "pager",
    Group = "group",
    StudentStatus = "studentStatus",
    AddressLine2 = "addressLine2",
    Class = "class",
    MiddleInitial = "middleInitial",
    NickName = "nickName",
    PrimaryPolicyNo = "primaryPolicyNo",
    SecondaryPolicyNo = "secondaryPolicyNo",
    PrimaryPolicyEffectiveDate = "primaryPolicyEffectiveDate",
    SecondaryPolicyEffectiveDate = "secondaryPolicyEffectiveDate",
    PrimaryPolicyExpirationDate = "primaryPolicyExpirationDate",
    SecondaryPolicyExpirationDate = "secondaryPolicyExpirationDate",
    City = "city",
    State = "state",
    Zip = "zip",
    EmergencyContact = "emergencyContact",
    EmergencyRelationship = "emergencyRelationship",
    EmergencyPhone1 = "emergencyPhone1",
    EmergencyPhone2 = "emergencyPhone2",
    ProviderTeam = "providerTeam",
    GenderIdentity = "genderIdentity",
    PrimaryPolicyGroupNo = "primaryPolicyGroupNo",
    PrimaryPolicySubscriberName = "primaryPolicySubscriberName",
    PrimaryPolicySubscriberDOB = "primaryPolicySubscriberDOB",
    Pronouns = "pronouns",
    Sport = "sport",
    GPA = "gpa",
    AcademicAdvisor = "academicAdvisor",
    MealPlan = "mealPlan",
    CampusHousingBuildingName = "campusHousingBuildingName",
    CampusHousingFloor = "campusHousingFloor",
    CampusHousingRoom = "campusHousingRoom"
}