import { ISort, TempFieldsForTable } from "src/Framework/Controls/Table/types";
import store from "src/store";
import { actionsTypes } from "..";
import { IAssignedSupervisors } from "../types";

export const setSummary = (payload: Record<string, IAssignedSupervisors>, providerIds: (string | number)[]) => {
    providerIds.forEach((providerId) => {
        const data = {}
        Object.entries(payload).forEach(([key, value]) => {
            if (`${value.providerTraineeId}` === `${providerId}`) {
                data[key] = value
            }
        })
        store.dispatch({
            type: actionsTypes.SET,
            payload: data,
            providerId
        })
    })
}

export const onCreateUpdateItem = (payload: IAssignedSupervisors & Partial<TempFieldsForTable>) => {
    const data = { ...(store.getState().trainee.dashboard.assignedSupervisors.providers[payload.providerTraineeId].summary || {}) }
    if (data[payload.id]) {
        data[payload.id] = { ...data[payload.id], ...payload }
    } else {
        data[payload.id] = payload
    }
    setSummary(data, [payload.providerTraineeId])
}

export const onRemoveItem = (id: string | number) => {
    const providers = store.getState().trainee.dashboard.assignedSupervisors.providers
    const findProviderKey = Object.keys(providers).find((v) => providers[v]?.summary[id])
    if (findProviderKey) {
        const data = { ...(providers[findProviderKey]?.summary || {}) }
        delete data[id]
        setSummary(data, [findProviderKey])
    }
}

export const setSort = (payload: ISort, providerId: string) => {
    store.dispatch({
        type: actionsTypes.SET_SORT,
        payload,
        providerId
    });
};
