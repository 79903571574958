import React, { useImperativeHandle, useState } from 'react';

import { Row, Col, Button } from 'antd';

import Modal from 'src/Framework/Controls/Modal';

import { ModalContainer, Text } from './styled';
import { IInputEditConfirm } from '.';

interface IProps {
  data: IInputEditConfirm;
  onApprove: Function;
  onReject: Function;
}

const prefix = `approve-modal-`;

export interface IModalHandles {
  show(obj: any): void;
  close(): void;
}

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { data, onApprove, onReject },
  ref
) => {
  const [visible, setVisible] = useState(false);

  const show = async ({ value }: any) => {
    if (value) {
    }
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {};

  useImperativeHandle(ref, () => ({
    show: (obj: any) => show(obj),
    close
  }));

  return (
    <Modal
      width={500}
      title="Edit"
      visible={visible}
      onClose={close}
      headerBorder={true}
      afterClose={setToDefault}
      modalProps={{
        destroyOnClose: true
      }}
    >
      <ModalContainer>
        <Row
          gutter={16}
          style={{
            flexWrap: 'nowrap'
          }}
          align="middle"
        >
          <Col>
            <Text>{data.approveText}</Text>
          </Col>
          <Col>
            <Row
              gutter={16}
              style={{
                flexWrap: 'nowrap'
              }}
              align="middle"
            >
              <Col>
                <Button
                  id={`${prefix}yes`}
                  onClick={() => {
                    close();
                    onApprove();
                  }}
                >
                  Yes
                </Button>
              </Col>
              <Col>
                <Button
                  id={`${prefix}no`}
                  onClick={() => {
                    close();
                    onReject();
                  }}
                >
                  No
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalContainer>
    </Modal>
  );
};

export default React.forwardRef(Component);
