import React, { FC } from 'react';
import { IM1FormRecord } from 'src/App/M1FormRecords/store/types';
import { getUserById } from 'src/Framework/Controls/Selectors/UserSelector/utils';
import { formattedDateTime } from 'src/Framework/Shared/Shared';

interface IProps {
  form: {
    history: IM1FormRecord['history'];
  };
}

const Component: FC<IProps> = ({ form }: IProps) => {
  return (
    <div>
      {form.history
        .sort((a, b) => new Date(a.dts).getTime() - new Date(b.dts).getTime())
        .map((v) => (
          <div key={v.dts}>
            {v.action === 'sign' && (
              <i>
                {v.actionContent} by: {getUserById(v.userId)?.name} on{' '}
                {formattedDateTime(v.dts)}
              </i>
            )}
            {v.action === 'append' && (
              <i>
                Appended: {v.actionContent} {getUserById(v.userId)?.name} on{' '}
                {formattedDateTime(v.dts)}
              </i>
            )}
          </div>
        ))}
    </div>
  );
};

export default React.memo(Component);
