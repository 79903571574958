import store from 'src/store'
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';


export const GET_SUMMARY_RX = "GET_SUMMARY_RX";
export const SET_SUMMARY_DETAIL = "SET_SUMMARY_DETAIL";
export const SET_CCAP = "SET_CCAP";

//Alerts
export const getAlerts = (subjectId: any) => {
  // let request = getRequest(subjectId);
  // return APIHandler.AxiosInstance.post(ApiUrl.GET_SUMMARY_DETAIL_RX, request, { withCredentials: true });
};


//Assessments
export const getAssessments = (subjectId: any) => {
  // let request = getRequest(subjectId);
  // return APIHandler.AxiosInstance.post(ApiUrl.GET_SUMMARY_DETAIL_RX, request, { withCredentials: true });
};



//Attachments
export const getAttachments = (subjectId: any) => {
  // let request = getRequest(subjectId);
  // return APIHandler.AxiosInstance.post(ApiUrl.GET_SUMMARY_DETAIL_RX, request, { withCredentials: true });
};



//Consents
export const getConsents = (subjectId: any) => {
  // let request = getRequest(subjectId);
  // return APIHandler.AxiosInstance.post(ApiUrl.GET_SUMMARY_DETAIL_RX, request, { withCredentials: true });
};

export const getRequest = (subjectId: any) => {
  return {
    patientId: subjectId,
  };
};

//Medications
export const getSummaryDetailRx = (subjectId: any) => {
  let request = getRequest(subjectId);
  return APIHandler.AxiosInstance.post(ApiUrl.GET_SUMMARY_DETAIL_RX, request, { withCredentials: true });
};

export const setSummaryDetail = (data: any, category: any, subjectId: any) => {
  return {
    type: SET_SUMMARY_DETAIL,
    payload: { category: category, data: data },
    subjectId
  }
};

//Diagnosis
export const getDiagnosis = (subjectId: any) => {
  // let request = getRequest(subjectId);
  // return APIHandler.AxiosInstance.post(ApiUrl.GET_SUMMARY_DETAIL_RX, request, { withCredentials: true });
};


//Hospitalizations
export const getHospitalizations = (subjectId: any) => {
  // let request = getRequest(subjectId);
  // return APIHandler.AxiosInstance.post(ApiUrl.GET_SUMMARY_DETAIL_RX, request, { withCredentials: true });
};




//Risks
export const getRisks = async (subjectId: any) => {
  try {
    const response = await APIHandler.AxiosInstance.get(ApiUrl.GET_PATIENT_RISKS, {
      withCredentials: true, params: {
        patientId: subjectId
      }
    })
    if (response.data.result) {
      store.dispatch(setSummaryDetail(response.data.result.summary, "risks", subjectId))
    }
  } catch (error) {
    console.log(error);
  }
};


//CCAPS
export const getCcaps = async (subjectId: any) => {
  try {
    const response = await APIHandler.AxiosInstance.get(ApiUrl.GET_PATIENT_CCAPS, {
      withCredentials: true, params: {
        patientId: subjectId
      }
    })
    if (response.data.result) {
      store.dispatch(setSummaryDetail(response.data.result.summary, "ccaps", subjectId))
    }
  } catch (error) {
    console.log(error);
  }
};


