import _ from 'lodash';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

const ActionManager = {
  ADD_DOCUMENT_FILE: "ADD_DOCUMENT_FILE",
  ADD_DOCUMENT: "ADD_DOCUMENT",
}


const initialState: any = {
  data: [],
  documents: {},
};

interface IDocument {
  content: string,
  contentLength: number,
  contentType: string
}

interface IAction {
  type: string;
  payload: IDocument;
}

const DocumentReducer = (
  state: any = { ...initialState },
  action: IAction
) => {
  switch (action.type) {
    case ActionManager.ADD_DOCUMENT_FILE: {
      let newState = _.cloneDeep(state)
      newState.data = action.payload
      return newState;

    }
    case ActionManager.ADD_DOCUMENT: {
      let newState = _.cloneDeep(state)
      return newState;

    }
    default: {
      return state;
    }

  }
};
export default DocumentReducer;

export const format = [
  { format: "data:image/jpg;base64,", type: ".jpg" },
  { format: "data:image/jpg;base64,", type: ".JPG" },
  { format: "data:image/jpeg;base64,", type: ".jpeg" },
  { format: "data:image/jpeg;base64,", type: ".JPEG" },
  { format: "data:image/jpeg;base64,", type: ".jfif" },
  { format: "data:image/jpeg;base64,", type: ".pjp" },
  { format: "data:image/jpeg;base64,", type: ".pjpeg" },
  { format: "data:application/pdf;base64,", type: ".pdf" },
  { format: "data:application/pdf;base64,", type: ".PDF" },
  { format: "data:image/png;base64,", type: ".png" },
  { format: "data:image/png;base64,", type: ".PNG" },
  { format: "data:image/gif;base64,", type: ".gif" },
  { format: "data:image/gif;base64,", type: ".gif" }];

export const addDocument = (data: any, documentId: IDocument) => {
  return {
    type: ActionManager.ADD_DOCUMENT,
    payload: data,
  }
};

export const getDocument = (
  documentId: number,
  getOriginal: boolean = false,
) => {
  return APIHandler.AxiosInstance.get(ApiUrl.GET_DOCUMENT + `?documentId=${documentId}&getOriginal=${getOriginal}`, { withCredentials: true });
};




export const updateDocument = (
  verifyAll: any,
  subjectId: any,
  includedIds: any,
  excludedIds: any,
  requests: any,
  callback?: any
): any => {
  let details = {
    verifyAll: verifyAll,
    subjectId: subjectId,
    includedIds: includedIds,
    excludedIds: excludedIds,
    requests: requests,
  }
  return APIHandler.AxiosInstance.post(ApiUrl.UPDATE_FACT_STATUS, details, { withCredentials: true })

};

export const updateDocumentDescription = (
  documentId: number,
  comment: string,
) => {
  return APIHandler.AxiosInstance.post(ApiUrl.UPDATE_DOCUMENT_DESCRIPTION + `?documentId=${documentId}&comment=${comment}`, null, { withCredentials: true });
};
