import React, { useImperativeHandle, useState } from 'react';
import _ from 'lodash';

import Modal from 'src/Framework/Controls/Modal';
import Button from 'src/Framework/Controls/Button';
import Checkbox from 'src/Framework/Controls/Checkbox';
import { clinicIsCounseling } from 'src/Framework/Controls/Selectors/ClinicSelector';

import { ITagValueSimpleDto } from 'src/App/Tags/store/types';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import { ModalContainer, CheckboxContainer } from './styled';

interface IProps {
  onChange: (data: IData) => any;
  values: Record<string, ITagValueSimpleDto>;
  clinicIds: number[];
}

export interface IData {
  tags: number[];
}

export interface IModalHandles {
  show(obj: IData): void;

  close(): void;
}

const defaultData: IData = {
  tags: []
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { values, onChange, clinicIds },
  ref
) => {
  const [data, setData] = useState<IData>(_.cloneDeep(defaultData));
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  useModalFocus({ active: visible });
  const show = ({ tags }: IData) => {
    setData({ tags });
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setData(_.cloneDeep(defaultData));
  };

  useImperativeHandle(ref, () => ({
    show,
    close
  }));

  const apply = async () => {
    setLoading(true);
    const res = await onChange(data);
    if (res) {
      close();
    }
    setLoading(false);
  };

  const checkDisabled = () => {
    return false;
  };
  const list = Object.values(values).filter((v) => {
    if (!v.isActive) {
      return false;
    }
    // Mental Status exam || Treatment Plan
    if (v.id === 8002 || v.id === 8003) {
      const hasCounselingClinic = clinicIds.find((val) =>
        clinicIsCounseling(val)
      );
      if (!hasCounselingClinic) {
        return false;
      }
    }
    return true;
  });

  const title = 'Add Assignment';
  const headerRight = (
    <Button
      id="save-button"
      disabled={checkDisabled()}
      loading={loading}
      onClick={apply}
      shape="round"
      className="green-button"
    >
      Save
    </Button>
  );
  const defaultWidth = 400;

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title
      }}
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      headerBorder={true}
      afterClose={setToDefault}
      modalProps={{
        destroyOnClose: true
      }}
      buttons={headerRight}
    >
      <ModalContainer>
        {list.map((v) => {
          const i = data.tags.indexOf(v.id);
          return (
            <CheckboxContainer key={v.id}>
              <Checkbox
                id={`${v.id}`}
                checked={i !== -1}
                onClick={() => {
                  setData(({ tags }) => {
                    const data = [...tags];
                    if (i === -1) {
                      data.push(v.id);
                    } else {
                      data.splice(i, 1);
                    }
                    return { tags: data };
                  });
                }}
              >
                {v.value}
              </Checkbox>
            </CheckboxContainer>
          );
        })}
      </ModalContainer>
    </Modal>
  );
};

export default React.forwardRef(Component);
