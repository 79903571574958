
import { APIHandler } from "../../../../Framework/Communication/ServerProxy";
import { addCompliancePreview, getCompianceFullPreview } from './Reducer';
import { updateCompliancePercentage } from '../Reducer';
import { expiryCheck } from 'src/App/LoginPage/store/Actions';

interface PreviewData {
  userId: string;
  cohortId: string;
  memberId: string;
  complianceId: string;
  jobId: string;
  count: 0;
}

interface IIncrementActions {
  getIncrementalPreview(dispatch: any, continueToken: string): any;
}

export class ComplianceProgress implements IIncrementActions {
  private data: PreviewData;
  constructor(data: PreviewData) {
    this.data = data;
  }

  public getIncrementalPreview(dispatch: any, continueToken: string, callback?:any) {
    setTimeout(() => {
      APIHandler.AxiosInstance.get(
        "Cohort/GetComplianceIncrementalPreview?continueToken=" + continueToken, { withCredentials: true }
      )
        .then(response => {
          let result: any = response.data.result;
          if (result.success) {
            let preview = result.preview;
            dispatch(
              addCompliancePreview(
                { preview: preview, cohortId: this.data.cohortId, complianceId: this.data.complianceId }
              )
            );
            dispatch(
              updateCompliancePercentage({
                cohortId: this.data.cohortId,
                complianceId: this.data.complianceId,
                complianceRate: preview.complyRate
              })
            );
            if (callback) callback();
            if (preview.jobStatus === 0)
              this.getIncrementalPreview(dispatch, preview.continueToken, callback);
            if (preview.jobStatus === -1) {
              dispatch(getCompianceFullPreview(this.data, callback));
            }
          }
        })
        .catch(error => {
          dispatch(expiryCheck( error));
         });
    }, 2000);
  }
}
