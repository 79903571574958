import _ from 'lodash'

import { IStateReducer, IAction } from './types';

const prefix = 'AUTO_REPLACE_SETUP_';

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_LOADING: `${prefix}SET_LOADING`,
};

const initialState: IStateReducer = {
    summary: {},
    loading: false,
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(initialState),
    action: IAction
): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET:
            return {
                ...state,
                summary: action.payload
            };
        default:
            return state;
    }
};

export default Reducer;
