import React, { FC } from 'react';
import cn from 'classnames';

import { Title, TitleContainer, ExpandList, ArrowContainer } from './styled';

import { IItemTree } from './index';
import { idGenerator } from './utils';
import {
  AccessibilityMove,
  onKeyDownAccessibility,
  onKeyDownCallbacks
} from 'src/Framework/util/accessibility';

const TreeArrow = require('src/Framework/Common/Svg/tree-arrow.svg').default;

const ArrowStyle = {
  WebkitMaskImage: `url(${TreeArrow})`,
  maskImage: `url(${TreeArrow})`
};

interface IProps {
  value: IItemTree;
  active: string;
  opened: Array<string>;
  onChangeOpened: Function;
  onChangeActive: Function;
  level: number;
  index?: number;
  rightContainerId?: string;
}

const Component: FC<IProps> = ({ ...props }: IProps) => {
  const {
    value,
    active,
    opened,
    onChangeOpened,
    onChangeActive,
    level,
    index,
    rightContainerId
  } = props;

  const currentActive = active === value.key;
  const currentOpen = opened.indexOf(value.key) !== -1;

  const onOpen = () => {
    onChangeOpened(value);
  };
  const onActive = (e: any) => {
    e.stopPropagation();
    onChangeActive(value);
  };
  const containChildren = value.children && value.children.length !== 0;
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (containChildren) {
      onOpen();
    } else {
      onActive(e);
    }
  };
  return (
    <>
      <TitleContainer
        id={idGenerator(value.key)}
        tabIndex={currentActive || level === 1 || index === 0 ? 0 : -1}
        aria-label={value.title}
        onClick={onClick}
        className={cn({
          active: currentActive
        })}
        style={{
          paddingLeft: level * 16
        }}
        onKeyDown={(e) => {
          onKeyDownAccessibility(e, undefined, AccessibilityMove.COL);
          if (rightContainerId) {
            onKeyDownCallbacks(e, {
              ArrowRight: () => {
                const element = document.getElementById(rightContainerId);
                if (element) {
                  element.focus();
                }
              }
            });
          }
        }}
      >
        {containChildren && (
          <ArrowContainer>
            <div
              className={cn({
                arrow: true,
                active: currentOpen
              })}
              style={ArrowStyle}
            />
          </ArrowContainer>
        )}
        <Title
          onClick={onClick}
          className={cn({
            firstLevel: level === 1
          })}
        >
          {value.title}
        </Title>
      </TitleContainer>
      {value.children && value.children.length !== 0 && (
        <ExpandList
          className={cn({
            open: currentOpen
          })}
        >
          {value.children.map((v, index: number) => (
            <Component {...props} key={v.key} value={v} level={level + 1} index={index} />
          ))}
        </ExpandList>
      )}
    </>
  );
};
export default React.memo(Component);
