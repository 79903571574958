import { store } from 'src';
import { TempFieldsForTable } from 'src/Framework/Controls/Table/types';
import { actionsTypes } from '..';
import { IProviderContract } from '../types';

export const changeSort = ({
  providerId,
  payload
}: {
  providerId: string;
  payload: any;
}) => {
  store.dispatch({
    type: actionsTypes.SET_SORT,
    payload,
    providerId
  });
};

export const setSummary = (
  payload: Record<string, IProviderContract>,
  providerId: string | number
) => {
  store.dispatch({
    type: actionsTypes.SET,
    payload,
    providerId
  });
};

export const onCreateUpdateItem = (
  payload: IProviderContract & Partial<TempFieldsForTable>,
  providerId: string | number
) => {
  const data = {
    ...(store.getState().trainee.dashboard.contracts.providers[providerId]
      ?.summary || {})
  };
  if (data[payload.id]) {
    data[payload.id] = { ...data[payload.id], ...payload };
  } else {
    data[payload.id] = payload;
  }
  setSummary(data, providerId);
};
