import { IFileSimpleDto } from "src/Framework/Controls/FileUploader";

export interface IStateReducer {
    providers: {
        [providerId: string]: {
            summary: Record<string, IM1NoteSimpleDTO>;
            loading: boolean;
            filter: Partial<IFilter>;
            sort: ISortNote;
        };
    };
}

export interface ISortNote {
    name: 'asc' | 'desc' | null,
    date: 'asc' | 'desc' | null,
}


export interface IAction {
    type: string;
    payload: any;
    providerId: any;
}

export interface IFilter {
    noteTypeId: number
    clinicId: number;
    assignedProviderId: number | null;
    billingProviderId: number | null;
    isActive: boolean;
    createUserId: number;
    createDts: string;
    status: M1NoteStatus;
    from: string | null
    to: string | null
}

export interface IM1NoteSimpleDTO {
    id: number;
    isActive: boolean;
    noteTypeId: number;
    noteTypeName: string
    clinicId: number;
    assignedProviderId: number | null;
    billingProviderId: number | null;
    status: M1NoteStatus;
    summary: string;
    isRestricted: boolean;
    createDts: string;
    createUserId: number;
    modifyDts: null | string;
    modifyUserId: null | number;
    subjectProviderId: number;
    history: IHistory[];
    linkedFormRecordIds: any[];
    files: IFileSimpleDto[]
}

export type IControl = INarrativeControl | IM1FormControl;

export enum ControlType {
    narrative = 'narrative',
    m1Form = 'm1Form',
    plan = "plan",
    diagnosis = 'diagnosis'
}

export interface INarrativeControl {
    controlType: ControlType.narrative;
    text: string;
    code: string;
}

export interface IM1FormControl {
    controlId: string;
    controlType: ControlType.m1Form;
    submission: any;
    formTemplateId: number;
    formTemplateVersion: number;
}

export enum IHistoryAction {
    sign = 'sign',
    append = 'append',
    send = 'send',
    lock = 'lock'
}

export interface IHistory {
    action: IHistoryAction;
    actionContent: string;
    dts: string;
    userId: number
}

export interface IM1NoteFullDTO extends IM1NoteSimpleDTO {
    details: Record<string, IControl>;
    schemas: Record<string, any>;

}

export enum M1NoteStatus {
    locked = 'locked',
    invalid = 'invalid',
    softSigned = 'softsigned',
    signed = 'finalSigned',
    inProgress = 'inProgress'
}

export interface IM1NoteCreation {
    noteTypeId: number;
    clinicId: number | null;
    assignedProviderId: number | null;
    billingProviderId: number | null;
    subject: {
        id: number;
        subjectType: M1NoteSubjectType;
    };
}

export enum M1NoteSubjectType {
    Patient = 'patient',
    Provider = 'provider'
}

export interface INotePreview {
    sections: INotePreviewItem[];
}

export interface INotePreviewV4Common {
    controlType: ControlType;
    controlTypeVersion: number
    sectionType?: SectionType
}

export type INotePreviewItem =
    | INotePreviewSection
    | INotePreviewFooter
    | INotePreviewBuildingBlock
    | INotePreviewHeader;

export interface INotePreviewCommon {
    result?: string;
    value?: string;
    rawPreview?: string
}

export interface INotePreviewSection extends INotePreviewCommon {
    sectionType: SectionType.section;
    controlType: ControlType.narrative;

}

export interface INotePreviewFooter extends INotePreviewCommon {
    sectionType: SectionType.footer;
    controlType?: undefined;
}

export interface INotePreviewHeader extends INotePreviewCommon {
    sectionType: SectionType.header;
    controlType?: undefined;
}

export interface INotePreviewLinkedM1Form extends INotePreviewCommon {
    sectionType: SectionType.linkedM1Form;
    controlType?: undefined;
    linkedM1FormRecords?: (LinkedM1FormRecordsEntity)[] | null;
}

export interface LinkedM1FormRecordsEntity {
    formRecordId: number;
    formTemplateId: number;
    formTemplateVersion: number;
    formTemplateName: string;
    subject: Subject;
    history?: (IHistory & {
        userName: string | null
    } | null)[] | null;
}
export interface Subject {
    id: number;
    subjectType: string;
}


export interface INotePreviewAttachedFile extends INotePreviewCommon {
    sectionType: SectionType.attachedFile;
    controlType?: undefined;
    attachments?: (AttachmentsEntity)[] | null;
}

export interface AttachmentsEntity {
    id: number;
    contentLength: number;
    fileName: string;
    contentType: string;
    originalFileName: string;
    dts: string;
    crc: string;
    userName: string | null
}


export type INotePreviewOtherTypes = INotePreviewHeader | INotePreviewFooter | INotePreviewSection | INotePreviewLinkedM1Form | INotePreviewAttachedFile

export interface INotePreviewBuildingBlock {
    sectionType: SectionType.buildingBlock;
    controlType: ControlType.m1Form;
    formTemplateId: number;
    formTemplateVersion: number;
    schema: object | null;
    submission: {
        data: any;
    };
}

export enum SectionType {
    section = 'section',
    footer = 'footer',
    header = 'header',
    buildingBlock = 'buildingBlock',
    linkedM1Form = 'linkedM1Forms',
    attachedFile = 'attachments'
}

export interface INarrativeControlValue {
    id: string;
    controlType: ControlType.narrative;
    buildingBlockId: 'narrative';
    text: string;
    key: string;
    config: any;
}

export interface IValidator {
    [key: string]: IElementStatus;
}

export type IElementStatus = 'error' | 'warning';

export type onChangeNote = (
    obj: Partial<IM1NoteFullDTO> & { id: number }
) => Promise<object | undefined>;
