import React, { FC, useCallback, useEffect, useState } from 'react';
import { Container, FormContainer } from './styled';

import * as M1RecordActions from 'src/App/M1FormRecords/store/Actions';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { useAppSelector } from 'src/store';
import M1Form from 'src/Activities/Forms/M1Form';

import { patch } from 'src/App/M1FormRecords/store/Actions/index';
import PatientHeader from 'src/Activities/Subject/PatientHeader';
import { usePatients } from 'src/App/Patients/Hooks/usePatient';
import {
  removeCurrentActivity,
  updateCurrentActivity
} from 'src/App/ActivityPanel/Store/ActivityActions';

interface IProps {
  match: any;
}

const Component: FC<IProps> = ({ match }: IProps) => {
  const id = match.params.id;
  const patients = usePatients();
  const form = useAppSelector((state) => {
    if (!id) return;
    const patient = Object.values(state.m1FormRecords.patients).find(
      (v) => v.summary[id]
    );
    return patient?.summary[id];
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      if (id) {
        setLoading(true);
        const res = await M1RecordActions.M1RecordRequests.get(id);
        if (res) {
          const data = Object.values(res.data)[0];
          if (data) {
            const patientName = await patients.getPatientNamePromise(
              data.subjectPatientId
            );
            updateCurrentActivity({
              title: patientName,
              subtitle: data.formTemplateName
            });
          }
        }
        setLoading(false);
      }
    };
    getData();
    return ()=>{
      M1RecordActions.M1RecordRequests.unsubscribe(id)
    }
  }, []);

  const afterRemove = useCallback(() => {
    removeCurrentActivity();
  }, []);
  return (
    <Container>
      {loading ? (
        <AbsoluteLoader backgroundColor="white" />
      ) : (
        <>
          {form && (
            <>
              <PatientHeader subjectId={`${form.subjectPatientId}`} />
              <FormContainer>
                <M1Form
                  form={form}
                  subjectId={`${form.subjectPatientId}`}
                  afterRemove={afterRemove}
                  m1FormPatchRequest={patch}
                />
              </FormContainer>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default React.memo(Component);
