import _ from 'lodash';
import { IStateReducer, IAction } from './types';

const prefix = 'ADMIN_PATIENT_MENU_ITEMS_';

export const actionsTypes = {
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_SUMMARY: `${prefix}SET_SUMMARY`,
    SET_USER_PROJECTION: `${prefix}SET_USER_PROJECTION`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

export const defaultData: IStateReducer = {
    filter: {
        search: ''
    },
    summary: [],
    userProjectionSummary: []
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const actions: IActions = {
        [actionsTypes.SET_FILTER]: () => {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                }
            };
        },
        [actionsTypes.SET_SUMMARY]: () => {
            return {
                ...state,
                summary: action.payload
            };
        },
        [actionsTypes.SET_USER_PROJECTION]: () => {
            return {
                ...state,
                userProjectionSummary: action.payload
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
