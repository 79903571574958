import * as actionTypes from "./DiagnosisActions";
import _ from 'lodash';

const initialState: IAssessmentReducer = {
    diagnosisGroups: [],
    risksList: undefined
};


interface IAction {
    type: string;
    payload: any;
}

interface IDiagnosisGroup {
    id: number
    inactive: boolean
    name: string
}

interface IAssessmentReducer {
    diagnosisGroups: IDiagnosisGroup[];
    risksList: any;
}


const AssessmentReducer = (
    state: IAssessmentReducer = { ...initialState },
    action: IAction
): IAssessmentReducer => {
    switch (action.type) {
        case actionTypes.SET_DIAGNOSIS_GROUPS: {
            return {
                ...state,
                diagnosisGroups: action.payload
            };
        }
        case actionTypes.SET_ASSESSMENT_RISKS_LIST: {
            return {
                ...state,
                risksList: action.payload
            };
        }
        default:
            return state;
    }
};
export default AssessmentReducer;