import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { IAuthResponseDto } from "./types";

const access_token = 'accessToken'
const refresh_token = 'refreshToken'

export const insert = () => {
    const data = get()
    if (data.accessToken && data.refreshToken) {
        set({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken
        })
    }
    return
}

export const get = () => {
    return {
        accessToken: localStorage.getItem(access_token),
        refreshToken: localStorage.getItem(refresh_token)
    }
}

export const set = (data: Pick<IAuthResponseDto, "accessToken" | "refreshToken">) => {
    if (APIHandler.AxiosInstance) {
        APIHandler.AxiosInstance.defaults.headers.Authorization = `Bearer ${data.accessToken}`
    }
    localStorage.setItem(access_token, data.accessToken)
    if (data.refreshToken) {
        localStorage.setItem(refresh_token, data.refreshToken)
    }
}

export const remove = () => {
    if (APIHandler.AxiosInstance) {
        APIHandler.AxiosInstance.defaults.headers.Authorization = undefined
    }
    localStorage.removeItem(access_token)
    localStorage.removeItem(refresh_token)
}