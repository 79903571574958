import _ from "lodash";
import * as actionTypes from "./PatientListActions";


const initState: IState = {
  patientLists: {
    gender: {}
  },
  providers: undefined,
  clinicProviders: undefined,
  clinics: undefined
};


export interface IState {
  patientLists: {
    gender: {
      [key: string]: {
        code: string
        label: string
        value: number
      }
    }
    [key: string]: any
  };
  providers: any;
  clinicProviders: any;
  clinics: any
}

export interface IAction {
  type: string;
  payload: any;
}

export const PatientListsReducer = (state: IState = initState, action: IAction): IState => {
  switch (action.type) {
    case actionTypes.GET_PATIENT_LIST: {
      let newState = _.cloneDeep(state);
      newState.patientLists = action.payload;
      return newState;
    }
    case actionTypes.SET_PROVIDERS: {
      let newState = _.cloneDeep(state);
      newState.providers = action.payload;
      return newState;
    }
    case actionTypes.SET_CURRENT_CLINIC_PROVIDERS: {
      let newState = _.cloneDeep(state);
      newState.clinicProviders = action.payload;
      return newState;
    }
    case actionTypes.SET_ALL_CLINICS: {
      let newState = _.cloneDeep(state);
      newState.clinics = action.payload;
      return newState;
    }
    default:
      return state;
  }
};
