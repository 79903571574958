import React from 'react';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import Multiple from './Multiple';
import Single from './Single';

import { ISingle, IMultiple, IValueType } from './types';
import { Container } from './styled';
import { MemoComponent } from 'src/Framework/util/helps';

type ISelect<S> = ISingle<S> | IMultiple<S>;

interface IOtherProps<T> {
  filter?: (value: T) => boolean;
  loading?: boolean;
  disableInnerSort?: boolean;
}

/**
 * @param T - DTO that you will use for this component
 * @param S - type of value that you need (string or number)
 */
export type IOutProps<T, S = IValueType> = ISelect<S> & IOtherProps<T>;

type IProps<T, S = IValueType> = IOutProps<T, S> & {
  data: {
    label: string | React.ReactElement;
    title?: string;
    value: S;
    isActive: boolean;
    color?: string;
    dto: T;
    optionSearch?: string;
  }[];
};

const validateLabel = (label: any) => {
  if (typeof label === 'string') {
    return label;
  }
  return '';
};

const Component = <T, S>(props: IProps<T, S>) => {
  const { type, filter, data, loading, disableInnerSort } = props;
  const allOptions = data;
  const options = (
    filter ? data.filter((value) => filter(value.dto)) : data
  ).filter((v) => v.isActive);

  if (!disableInnerSort) {
    options.sort((a, b) =>
      typeof a.label === 'string' && typeof b.label === 'string'
        ? validateLabel(a.label)?.localeCompare(b.label)
        : 0
    );
  }
  return (
    <Container>
      {loading && <AbsoluteLoader />}
      {type === 'multiple' && (
        <Multiple<S>
          {...(props as IMultiple<S>)}
          type="multiple"
          options={options}
        />
      )}
      {(type === undefined || type === 'single') && (
        <Single
          {...(props as ISingle<S>)}
          type="single"
          options={options}
          allOptions={allOptions}
        />
      )}
    </Container>
  );
};

export default MemoComponent(Component);
