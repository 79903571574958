import { TempFieldsForTable } from 'src/Framework/Controls/Table/types';
import store from 'src/store';
import { actionsTypes } from '..';
import { IM1FormRecord } from '../types';

export const setPatientSummary = (payload: Record<string, IM1FormRecord>) => {
  const firstItem = Object.values(payload)[0];
  if (firstItem) {
    store.dispatch({
      type: actionsTypes.SET_PATIENT_SUMMARY,
      payload,
      patientId: firstItem.subjectPatientId
    });
  }
};

export const setProviderSummary = (
  payload: Record<string, IM1FormRecord>,
  providerId: string | number
) => {
  store.dispatch({
    type: actionsTypes.SET_PROVIDER_SUMMARY,
    payload,
    providerId
  });
};

export const setM1FormsInNote = (payload: Record<string, IM1FormRecord>) => {
  const firstItem = Object.values(payload)[0];
  if (firstItem) {
    store.dispatch({
      type: actionsTypes.SET_NOTE_SUMMARY,
      payload,
      patientId: firstItem.subjectPatientId
    });
  }
};

export const onCreateUpdateM1FormInNoteItem = (
  payload: IM1FormRecord & Partial<TempFieldsForTable>
) => {
  if (payload.noteId) {
    const data = {
      ...(store.getState().m1FormRecords.patients[payload.subjectPatientId]
        ?.summaryInNotes || {})
    };
    if (data[payload.id]) {
      data[payload.id] = { ...data[payload.id], ...payload };
    } else {
      data[payload.id] = payload;
    }
    setM1FormsInNote(data);
  }
};

export const onCreateUpdatePatientItem = (
  payload: IM1FormRecord & Partial<TempFieldsForTable>
) => {
  if (payload.noteId) {
    onCreateUpdateM1FormInNoteItem(payload);
  } else {
    const data = {
      ...(store.getState().m1FormRecords.patients[payload.subjectPatientId]
        ?.summary || {})
    };
    if (data[payload.id]) {
      data[payload.id] = { ...data[payload.id], ...payload };
    } else {
      data[payload.id] = payload;
    }
    setPatientSummary(data);
  }
};

export const onCreateUpdateProviderItem = (
  payload: IM1FormRecord & Partial<TempFieldsForTable>,
  providerId: string | number
) => {
  const data = {
    ...(store.getState().m1FormRecords.providers[providerId]?.summary || {})
  };
  if (data[payload.id]) {
    data[payload.id] = { ...data[payload.id], ...payload };
  } else {
    data[payload.id] = payload;
  }
  setProviderSummary(data, providerId);
};
