import React, { FC, useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent, CheckboxProps } from 'antd/es/checkbox';

import Tooltip from 'src/Framework/Controls/Tooltip';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import { Container } from './styled';

export interface IProps extends CheckboxProps {
  checked: boolean | undefined;
  id: string;
  tooltip?: string | React.ReactNode;
  asyncChange?: boolean;
  marginBottom?: boolean;
  disableFitContent?: boolean;
}

/**
 * {@link CheckboxComponent} used like antd container
 * @note value and checked inside unsed to solve issue for QA tests
 */
const CheckboxComponent: FC<IProps> = ({
  checked,
  tooltip,
  asyncChange,
  marginBottom,
  disableFitContent,
  ...otherProps
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const change = async (e: CheckboxChangeEvent) => {
    if (asyncChange) {
      setLoading(true);
      if (otherProps.onChange) {
        await otherProps.onChange(e);
      }
      setLoading(false);
      return;
    }
    if (otherProps.onChange) {
      otherProps.onChange(e);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<any>) => {
    if (
      e.key === 'Enter' ||
      (e.code === 'Space' && (e.target as HTMLElement).tagName === 'INPUT')
    ) {
      e.preventDefault();
      e.stopPropagation();
      if (e.currentTarget) {
        // @ts-ignore
        e.currentTarget.click();
      }
    }
  };
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <Container
          className="custom-checkbox"
          disableFitContent={disableFitContent}
          marginBottom={marginBottom}
        >
          {loading && <AbsoluteLoader />}
          <Checkbox
            {...otherProps}
            onChange={change}
            checked={checked}
            value={checked}
            onKeyDown={onKeyDown}
          />
        </Container>
      </Tooltip>
    );
  }
  return (
    <Container
      className="custom-checkbox"
      disableFitContent={disableFitContent}
      marginBottom={marginBottom}
    >
      {loading && <AbsoluteLoader />}
      <Checkbox
        {...otherProps}
        onChange={change}
        checked={checked}
        value={checked}
        onKeyDown={onKeyDown}
      />
    </Container>
  );
};

export default React.memo(CheckboxComponent);
