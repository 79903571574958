import styled from "styled-components";

interface ITagContainer {
    whiteBorders?: boolean
}

export const TagContainer = styled.div<ITagContainer>`
    padding: 2px 8px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #333333;
    ${({ whiteBorders }) => whiteBorders && `
      color: white;
      border: 1px solid white;
    `}
  
  `;