import * as actionType from "../../RuleActions/ActionTypes";
import _ from "lodash";

interface IAction {
  ruleId: any;
  type: string;
}
const initialState: any = {
  ruleToShow: null,
};
const RuleCommonReducer = (
  state: any = { ...initialState },
  action: IAction
) => {
  switch (action.type) {
    case actionType.UPDATE_RULE_TO_SHOW: {
      let newState = _.cloneDeep(state);
      newState.ruleToShow = action.ruleId
      return newState;
    }

    default:
      return state;
  }
};

export default RuleCommonReducer;
