import React, { FC, useEffect, useState } from 'react';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import { Container } from './styled';

import * as actions from './store/Actions';
import { IFormioForm } from './store/types';

import FormContainer, {
  IChange,
  IFormForPatientFields
} from '../FormContainer';

interface IForm {
  schema: IFormioForm['schema'];
  submission: IFormioForm['submission'];
}
interface IProps {
  seedId?: number | string;
  defaultSchema?: IForm;
  readOnly?: boolean;
  onChangeSubmission: IChange;
  previewMode?: boolean;
  form?: IFormForPatientFields;
}

const Component: FC<IProps> = ({
  seedId,
  defaultSchema,
  onChangeSubmission,
  readOnly,
  previewMode,
  form: M1Form
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<IForm | null>(() => {
    if (defaultSchema) {
      return defaultSchema;
    }
    return null;
  });
  const [defaultData, setDefaultData] = useState(defaultSchema);
  useEffect(() => {
    setDefaultData(defaultSchema);
  }, [readOnly, previewMode]);
  useEffect(() => {
    if (seedId) {
      const init = async () => {
        setLoading(true);
        const res = await actions.getFormRecord({ seedId });
        if (res) {
          setForm(res);
        } else {
          setForm(null);
        }
        setLoading(false);
      };
      init();
    }
  }, [seedId]);
  return (
    <Container>
      {loading && <AbsoluteLoader />}
      {!seedId && defaultData && (
        <FormContainer
          form={M1Form}
          schema={defaultData.schema}
          submission={defaultData.submission?.data || {}}
          onChange={onChangeSubmission}
          readOnly={readOnly}
          previewMode={previewMode}
        />
      )}
      {seedId && form && (
        <FormContainer
          form={M1Form}
          schema={form.schema}
          submission={form.submission.data}
          onChange={onChangeSubmission}
          readOnly={readOnly}
          previewMode={previewMode}
        />
      )}
    </Container>
  );  
};

export default React.memo(Component);
