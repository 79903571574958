import React, { FC, useCallback, useRef } from 'react';

import { isContentTypeSupport } from './utils';
import FileViewerModal, { IModalHandles } from './Modal';

interface IProps {
  item: any;
  parentButtonRef?: React.MutableRefObject<any>;
  children: (data: {
    previewSupport: boolean;
    onClickPreview: () => any;
  }) => React.ReactElement;
}

const FileViewerWrapper: FC<IProps> = ({
  item,
  children,
  parentButtonRef
}: IProps) => {
  const FileViewerModalRef = useRef<IModalHandles>(null);
  const { contentType } = item;
  const previewSupport = isContentTypeSupport(contentType);
  const onClickPreview = useCallback(() => {
    FileViewerModalRef.current?.show({
      value: {
        fileId: item.id
      }
    });
  }, []);
  return (
    <>
      <FileViewerModal
        ref={FileViewerModalRef}
        parentButtonRef={parentButtonRef}
      />
      {children({
        onClickPreview,
        previewSupport
      })}
    </>
  );
};

export default React.memo(FileViewerWrapper);
