import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import _ from 'lodash';
import { expiryCheck } from 'src/App/LoginPage/store/Actions';

const ActionManager = {
    ADD_MESSAGE_TEMPLATE: "ADD_MESSAGE_TEMPLATE",
}

interface IAction {
    type: string;
    payload: any;
}

const initaialState = {
    pagination: null,
    templates: []
}

const MessageTemplateReducer = (
    state: any = { ...initaialState },
    action: IAction
) => {
    switch (action.type) {
        case ActionManager.ADD_MESSAGE_TEMPLATE: {
            let newState = _.cloneDeep(state)
            newState.pagination = action.payload.pagination;
            if (action.payload.pagination > 1) {
                newState.templates = [...newState.templates, action.payload.templates];
            }
            else newState.templates = action.payload.templates;
            return newState;
        }
        default: {
            return state;
        }

    }
};
export default MessageTemplateReducer;

const addMessageTemplate = (data: any) => {
    return {
        type: ActionManager.ADD_MESSAGE_TEMPLATE,
        payload: data
    }
};

export const getMessageTemplate = (
    current = 0,
    searchString = ""
): any => {
    let data = {
        filter: { searchString: searchString },
        sortBy: "",
        startIndex: 0,
        count: 0,
    }
    return (dispatch: any) => {
        APIHandler.AxiosInstance.post(ApiUrl.GET_MESSAGE_TEMPLATE, data, { withCredentials: true })
            .then(response => {
                if (response.data.success) {
                    let pagination = {
                        current: current + 1,
                        total: response.data.result.total
                    }
                    dispatch(addMessageTemplate({ pagination: pagination, templates: response.data.result.summary }))
                }
            })
            .catch(error => dispatch(expiryCheck(error)));
    };
};

export const getTemplateContent = (
    id: any
) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_MESSAGE_TEMPLATE_CONTENT + id, null, { withCredentials: true });
};