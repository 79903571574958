import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Tag = styled.div`
  padding: 2px 4px;
  margin: 0 2px 0 0;
`;

export const OriginNodeContainer = styled.div`
  .ant-select-item-option-selected {
    background-color: unset !important;
  }

  .ant-select-item-option-active {
    background-color: ${globalStyleVariables.activeOption} !important;
  }

  .ant-checkbox-wrapper {
    font-size: 14px;
  }
`;

export const Container = styled.div`
  .ant-select-selector > span:nth-of-type(1) {
    width: calc(100% - 20px);
  }

  .ant-select-selection-search-input {
    min-width: max-content !important;
  }

  & .multiselector {
    position: relative;

    .ant-select-selector {
      .ant-select-selection-overflow {
        .ant-select-selection-search {
          position: unset !important;

          input {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
`;
