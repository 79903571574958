import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row } from 'antd';

// @ts-ignore
import ReactComponent from '@formio/react/lib/components/ReactComponent';
import settingsForm from './settingsForm';

import { Container, Label } from './styled';
import { getComponentState, getValidatedValue } from '../utils';

export const componentType = 'm1multiselect';

// const editorKey = 'defaultValue';

interface IValue {
  label: string;
  value: string;
  score: string;
  isPromptForExtraInfo: boolean;
  answerText?: string;
  checked?: boolean;
}

interface IProps {
  value: IValue[];
  component: {
    data?: IValue[];
    key: string;
    disabled: boolean;
  };
  onChange: Function;
  readOnly: boolean;
  root: any;
}

interface IState {
  value: IValue[];
}

const M1multiselectCustomComp = class extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  setValue = (data: IValue[]) => {
    this.setState(
      {
        value: data
      },
      () => {
        const { onChange } = this.props;
        // @ts-ignore
        const { changed, changes, ...other } = this.state.value;
        onChange(Object.values(other), this.state.value);
      }
    );
  };

  render() {
    const { component, readOnly, root } = this.props;
    const { isPreview } = getComponentState(root);
    if (!component.data) return null;
    const disabled = component.disabled;
    const value = getValidatedValue(this.state.value);
    const data = value && value.length !== 0 ? value : [];
    return component.data.map((v, index) => {
      const i = data.findIndex((val) => val.value === v.value);
      const currentValue = i == -1 ? { ...v } : { ...v, ...data[i] };
      const checked = i !== -1;
      return (
        <Container isPreview={isPreview}>
          <Row
            align="middle"
            onClick={() => {
              if (disabled) return;
              if (!readOnly) {
                const arr = [...data];
                if (i === -1) {
                  arr.push(v);
                } else {
                  arr.splice(i, 1);
                }
                this.setValue(arr);
              }
            }}
          >
            <input
              disabled={disabled}
              checked={checked}
              type="checkbox"
              id={'checkbox' + v.label}
            />
            <Label htmlFor={'checkbox' + v.label}>{currentValue.label}</Label>
          </Row>
          {currentValue.isPromptForExtraInfo &&
            checked &&
            (isPreview ? (
              currentValue.answerText
            ) : (
              <input
                type="input"
                style={{ width: '100%' }}
                value={currentValue.answerText}
                onChange={(e) => {
                  if (!readOnly) {
                    const arr = [...data];
                    arr[i] = {
                      ...arr[i],
                      answerText: e.target.value
                    };
                    this.setValue(arr);
                  }
                }}
              />
            ))}
        </Container>
      );
    });
  }
};

export default class M1multiselect extends ReactComponent {
  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Multiselect',
      icon: 'th-list',
      group: 'Data',
      documentation: '',
      weight: -10,
      schema: M1multiselect.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    // @ts-ignore
    return ReactComponent.schema({
      type: componentType,
      label: 'Multiselect Label',
      multiple: true
    });
  }

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element: any, ref: any) {
    if (!element) return null;
    return ReactDOM.render(
      <M1multiselectCustomComp
        ref={ref}
        // @ts-ignore
        component={this.component} // These are the component settings if you want to use them to render the component.
        // @ts-ignore
        value={this.dataValue} // The starting value of the component.
        // @ts-ignore
        onChange={this.updateValue}
        // @ts-ignore
        readOnly={this.root.options.readOnly}
        // @ts-ignore
        root={this.root}
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element: any) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
