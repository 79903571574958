import _ from 'lodash';

import { IStateReducer, IAction, IApptBackgroundSettings } from './types';

const prefix = `USER_PREFERENCES_`;

export const actionsTypes = {
  SET_APPT: `${prefix}SET_APPT`,
  CHANGE_APPT: `${prefix}CHANGE_APPT`,
  SET_FACILITIES: `${prefix}SET_FACILITIES`
};

const initialState: IStateReducer = {
  apptSubscription: {},
  facilities: {}
};

export const defaultApptBackgroundColor = '#818181';

export const defaultApptBackgroundSettings: IApptBackgroundSettings = {
  radio: 'apptType',
  colors: {
    late: defaultApptBackgroundColor,
    arrived: defaultApptBackgroundColor,
    ready: defaultApptBackgroundColor,
    admitted: defaultApptBackgroundColor,
    discharged: defaultApptBackgroundColor
  }
};

const Reducer = (
  state: IStateReducer = _.cloneDeep(initialState),
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.CHANGE_APPT:
      const a = state.apptSubscription[action.payload.Id] || {};
      return {
        ...state,
        apptSubscription: {
          ...state.apptSubscription,
          [action.payload.Id]: {
            ...a,
            ...action.payload
          }
        }
      };
    case actionsTypes.SET_APPT:
      return {
        ...state,
        apptSubscription: {
          ...action.payload
        }
      };
    case actionsTypes.SET_FACILITIES:
      return {
        ...state,
        facilities: action.payload
      };
    default:
      return state;
  }
};

export default Reducer;
