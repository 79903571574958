import styled from 'styled-components';

interface IContainer {
  marginBottom?: boolean;
}

export const Container = styled.div<IContainer>`
  ${({ marginBottom }) => marginBottom && `margin-bottom: 20px;`}
`;

export const CheckAll = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
  font-family: Lato;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  user-select: none;
`;
