import _ from "lodash"
import { useEffect, useState } from "react"
import { useAppSelector } from "src/store"
import { permissionChecking, PermissionCheckingAnyClinic, PermissionCheckingOnClinic } from "../Permission"

export const usePermission = (data: PermissionCheckingAnyClinic | PermissionCheckingOnClinic) => {
    const [result, setResult] = useState(permissionChecking(data))
    const permission = useAppSelector((state) => state.user.permissions)
    useEffect(() => {
        const permissionResult = permissionChecking(data)
        if (!_.isEqual(result, permissionResult)) {
            setResult(permissionResult)
        }
    }, [permission])
    return result
}