import { useEffect, useState } from 'react';
import {
  // register,
  unregister
} from './registerServiceWorker';

const ServiceWorkerWrapper = () => {
  const [showReload] = useState(false);

  // const onSWUpdate = (registration: ServiceWorkerRegistration) => {
  //   console.log('service worker updated, skip waiting called');
  //   registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
  //   setShowReload(true);
  // };

  useEffect(() => {
    unregister();
    // register({ onUpdate: onSWUpdate });
  }, []);

  return { showReload };
};

export default ServiceWorkerWrapper;
