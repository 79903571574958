import React from 'react';

interface IProps {
  stroke?: string;
}

export const RestrictedSvg = ({ stroke }: IProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="#F7F7F7"
    />
    <circle cx="16" cy="16" r="7.5" stroke="#3A5B8B" />
    <rect
      x="9.68164"
      y="20.9043"
      width="15.8718"
      height="2"
      rx="1"
      transform="rotate(-45 9.68164 20.9043)"
      fill="#3A5B8B"
    />
  </svg>
);
