import * as React from 'react';
import { connect } from 'react-redux';

import Modal from 'src/Framework/Controls/Modal';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import * as containers from 'src/Framework/Controls/ModalTabulator/ModalTabulator_Style';
import Button from 'src/Framework/Controls/Button';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { IStore } from 'src/store';
import test_ids from 'src/tests-script/pages/Subject/Forms';

import { ModalContainer } from './SortModal_Style';

interface IProps {
  openSortModal?: boolean;
  onClickApplyHandler: any;
  visible: boolean;
  closeSortModal: any;
  title: string;
  sortData?: any;
  sliderMode?: boolean;
}

interface IState {
  name: any;
  date: any;
}

const optionsName = [
  { value: 'asc', title: 'A-Z' },
  { value: 'desc', title: 'Z-A' }
];
const optionsDate = [
  { value: 'desc', title: 'Newest First' },
  { value: 'asc', title: 'Oldest First' }
];

const defaultWidth = 450;

class SortFormModal extends React.Component<IProps, IState> {
  public parentElement: React.MutableRefObject<any>;

  constructor(props: IProps) {
    super(props);

    this.parentElement = React.createRef();
    this.state = {
      name: null,
      date: null
    };
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props) {
      if (this.props.sortData && this.props.sortData.name) {
        this.setState({ name: this.props.sortData.name });
      }
      if (this.props.sortData && this.props.sortData.date) {
        this.setState({ date: this.props.sortData.date });
      }
      if (this.props.visible !== prevProps.visible) {
        if (this.props.visible) {
          this.parentElement.current = document.activeElement as HTMLElement;
        } else {
          if (this.parentElement.current) {
            (this.parentElement.current as HTMLElement).focus();
            this.parentElement.current = null;
          }
        }
      }
    }
  }

  closeModal() {
    this.setState({ name: null, date: null });
    this.props.closeSortModal();
  }

  onApplyButtonHandler() {
    this.props.onClickApplyHandler(this.state.name, this.state.date);
    this.closeModal();
  }

  onResetHandler() {
    this.setState({ name: null, date: null });
  }

  modalButtons(): any {
    return (
      <containers.Buttons>
        <Button
          color={globalStyleVariables.newBrandingPrimary200}
          colorStyles={{
            blueBorder: true,
            boldFont: true
          }}
          id={test_ids.formsSortResetButton}
          onClick={() => {
            this.onResetHandler();
          }}
        >
          Reset
        </Button>
        <Button
          color={globalStyleVariables.newBrandingPrimary200}
          colorStyles={{
            blueBorder: true,
            boldFont: true
          }}
          id={test_ids.formsSortApplyButton}
          onClick={() => {
            this.onApplyButtonHandler();
          }}
        >
          Apply
        </Button>
      </containers.Buttons>
    );
  }

  onChangeNameHandler = (value: any) => {
    this.setState({ name: value });
  };

  onChangeDateHandler(value: any) {
    this.setState({ date: value });
  }

  render() {
    return (
      <Modal
        id={test_ids.formsSortModal}
        isModalSlider={this.props.sliderMode}
        modalSliderProps={{
          defaultWidth: `${defaultWidth}px`,
          minWidth: `${defaultWidth}px`,
          isOpen: this.props.visible,
          onClose: () => this.closeModal(),
          title: this.props.title,
          headerRight: this.modalButtons()
        }}
        modalProps={{
          destroyOnClose: true
        }}
        title={this.props.title}
        onClose={() => this.closeModal()}
        width={defaultWidth}
        visible={this.props.visible}
        buttons={this.modalButtons()}
      >
        <ModalContainer>
          <SelectContainer
            star={false}
            type="select"
            label="Name"
            options={optionsName}
            value={this.state.name}
            onChange={(e: any) => this.onChangeNameHandler(e)}
            inputProps={{
              allowClear: true
            }}
          />
          <SelectContainer
            star={false}
            type="select"
            label="Date"
            options={optionsDate}
            value={this.state.date}
            onChange={(e: any) => this.onChangeDateHandler(e)}
            bottomMargin={false}
            inputProps={{
              allowClear: true
            }}
          />
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: IProps) => {
  return {
    openSortModal: state.forms.openSortForm
  };
};
export default connect(mapStateToProps)(SortFormModal);
