import * as actionType from "../../RuleActions/ActionTypes";
import {
  IRule,
  IRuleComponent,
  ILogicalOperatorRuleComponent,
  ISpecificOperatorRuleComponent,
  IRuleStore
} from "../../Models/RuleDefinition";
import { ITemplateOption } from "../../Models/RuleTemplate";
import _ from "lodash";

const prefix = 'RULE_EDITOR';

export const actionsTypes = {
  SET_DEFINITION: `${prefix}SET_DEFINITION`
};

const initialState: IRuleStore[] = [];
let parentRule: any = null
interface IAction {
  id: string;
  type: string;
  value: any;
  parentId: number;
  componentId: number;
  componentTemplate: any;
  sourceId: number;
  targetId: number;
  keyName: string;
  component: IRuleComponent;
  sourceIndex: number;
  targetIndex: number;
  index: number;
  newValue: any;
  subType: string;
  ruleId: string;
  subComponent: IRuleComponent;
  payload: any
}

const RuleDefinitionReducer = (
  state: IRuleStore[] = initialState,
  action: IAction
): any => {
  switch (action.type) {
    case actionsTypes.SET_DEFINITION: {
      const arr = [...state]
      const i = arr.findIndex(
        (r: IRuleStore) => r.rule.id === action.ruleId
      );
      if (i !== -1) {
        const item = arr[i]
        arr[i] = {
          ...item,
          rule: {
            ...item.rule,
            definition: action.payload
          }
        }
      }
      return arr
    }
    case actionType.ADD_RULE: {
      RuleTypes = [];
      let ruleStore: IRuleStore | undefined = state.find(
        (r: IRuleStore) => r.rule.id === action.value.id
      );
      if (!ruleStore)
        return [
          ...state,
          {
            rule: _.cloneDeep(action.value),
            publishedRule: _.cloneDeep(action.value)
          }
        ];
      ruleStore.isDirty = false;
      ruleStore.rule = _.cloneDeep(action.value);
      ruleStore.publishedRule = _.cloneDeep(action.value);
      return [...state];
    }

    case actionType.ADD_VALIDATE_RESULT: {
      let ruleStore: IRuleStore | undefined = state.find(
        (r: IRuleStore) => r.rule.id === action.value.id
      );
      if (!ruleStore) return state;
      ruleStore.validationResult = action.value;
      return [...state];
    }

    case actionType.REMOVE_RULE: {
      RuleTypes = [];
      let index: number = state.findIndex(
        (r: IRuleStore) => r.rule.id === action.id
      );
      if (index > -1) {
        state.splice(index, 1);
        return [...state];
      }
      return [...state];
    }

    case actionType.CREATE_RULE_COMPONENT: {
      RuleTypes = [];
      let ruleStore: IRuleStore | undefined = state.find(
        (r: IRuleStore) => r.rule.id === action.id
      );
      if (!ruleStore) return state;
      ruleStore.isDirty = true;
      ruleStore.validationResult = {
        id: "",
        success: true,
        trace: null
      };
      let rule: IRule = ruleStore.rule;
      if (!rule) return state;

      let component: IRuleComponent = createRuleComponent(
        action.componentId,
        action.componentTemplate
      );
      if (!component) return state;

      if (action.parentId) {
        let parent: ILogicalOperatorRuleComponent | null = findRuleComponent(
          rule,
          action.parentId
        );
        if (!parent) return state;
        parent.operands.push(component);
      } else {
        rule.definition = component;
      }
      return [...state];
    }
    case actionType.ADD_RULE_COMPONENT: {
      RuleTypes = [];
      let ruleStore: IRuleStore | undefined = state.find(
        (r: IRuleStore) => r.rule.id === action.id
      );
      if (!ruleStore) return state;
      ruleStore.isDirty = true;
      ruleStore.validationResult = {
        id: "",
        success: true,
        trace: null
      };
      let rule: IRule = ruleStore.rule;
      if (!rule) return state;
      let component: IRuleComponent = action.component;
      if (!component) return state;
      if (action.parentId) {
        let parent: ILogicalOperatorRuleComponent | null = findRuleComponent(
          rule,
          action.parentId
        );
        if (!parent) return state;
        parent.operands.splice(action.index, 0, component);
      } else {
        rule.definition = component;
      }
      return [...state];
    }
    case actionType.MOVE_RULE_COMPONENT: {
      RuleTypes = [];
      let ruleStore: IRuleStore | undefined = state.find(
        (r: IRuleStore) => r.rule.id === action.id
      );
      if (!ruleStore) return state;
      ruleStore.isDirty = true;
      let rule: IRule = ruleStore.rule;
      if (!rule) return state;
      let source: ILogicalOperatorRuleComponent = findRuleComponent(
        rule,
        action.sourceId
      );
      if (!source) return state;
      let target: ILogicalOperatorRuleComponent = findRuleComponent(
        rule,
        action.targetId
      );
      if (!target) return state;
      let sourceIndex: number = action.sourceIndex;
      let targetIndex: number = action.targetIndex;
      if (sourceIndex < 0) return state;
      let comp: IRuleComponent = source.operands[sourceIndex];
      source.operands.splice(sourceIndex, 1);
      target.operands.splice(targetIndex, 0, comp);
      return [...state];
    }
    case actionType.REMOVE_RULE_COMPONENT: {
      RuleTypes = [];
      let ruleStore: IRuleStore | undefined = state.find(
        (r: IRuleStore) => r.rule.id === action.id
      );
      if (!ruleStore) return state;
      ruleStore.isDirty = true;
      let rule: IRule = ruleStore.rule;
      if (!rule) return state;
      if (action.sourceId) {
        let source: ILogicalOperatorRuleComponent = findRuleComponent(
          rule,
          action.sourceId
        );
        if (!source) return state;
        let index: number = source.operands.findIndex(
          (o: IRuleComponent) => o.id === action.componentId
        );
        if (index < 0) return state;

        source.operands.splice(index, 1);
      } else {
        rule.definition = null;
      }

      return [...state];
    }
    case actionType.SELECT_OPTION: {
      RuleTypes = [];
      let ruleStore: IRuleStore | undefined = state.find(
        (r: IRuleStore) => r.rule.id === action.id
      );
      if (!ruleStore) return state;
      ruleStore.isDirty = true;
      ruleStore.validationResult = {
        id: "",
        success: true,
        trace: null
      };
      let rule: IRule = ruleStore.rule;
      if (!rule) return state;
      if (action.componentId) {
        let component: ISpecificOperatorRuleComponent | null = findRuleComponent(
          rule,
          action.componentId
        );
        if (!component) return state;
        let seriesObj = null;
        if (action.subType === "series") {
          component["series"] = [];

          let value: any = action.newValue;
          for (let i = 2; i <= value; i++) {
            for (let j = 1; j < i; j++) {
              seriesObj = createSeriesOptions(
                action.subComponent["TemplateOptions"],
                [i, j],
              );
              component["series"].push(seriesObj);
            }
          }
        }
        let value = action.newValue;
        if (action.keyName === "Procedure-type" && action.newValue) {
          value = {
            id: action.newValue.id,
            results: action.newValue.results,
            name: action.newValue.name,
            option: action.newValue.results && action.newValue.results.length > 0 ? true : false
          };
        }
        component[action.keyName] = value;
      }
      return [...state];
    }
    case actionType.CLEAR_RULE_EDITOR: {
      let index = state.findIndex((r: IRuleStore) => r.rule.id === action.id);
      if (index === -1) return state;
      state.splice(index, 1);
      return [...state];
    }
    case actionType.UPDATE_LOGICAL_OPERATOR: {
      RuleTypes = [];
      let ruleStore: IRuleStore | undefined = state.find(
        (r: IRuleStore) => r.rule.id === action.id
      );
      if (!ruleStore) return state;
      ruleStore.isDirty = true;
      let rule: IRule = ruleStore.rule;
      if (!rule) return state;
      let target: ILogicalOperatorRuleComponent = findRuleComponent(
        rule,
        action.targetId
      );
      if (!target) return state;
      target.ruleComponent = action.value;
      return [...state];
    }

    case actionType.CLEAR_VALIDATION_RESULT: {
      let ruleStore: IRuleStore | undefined = state.find(
        (r: IRuleStore) => r.rule.id === action.ruleId
      );
      if (!ruleStore) return state;
      ruleStore.validationResult = {
        id: "",
        success: true,
        trace: null
      };
      return [...state];
    }

    default:
      return state;
  }
};

export const findRuleComponent = (rule: IRule, id: number): any => {
  if (!rule.definition) return null;
  if (rule.definition.id === id) return rule.definition;
  parentRule = rule.definition;
  if (isContainerRuleComponent(rule.definition)) {
    return findInnerRuleComponent(
      rule.definition as ILogicalOperatorRuleComponent,
      id
    );
  }
  if (rule.definition["series"] && rule.definition["series"].length > 0) {
    let comp = rule.definition["series"].find((s: any) => s.id === id);
    if (comp) return comp;
  }
  return null;
};



const findInnerRuleComponent = (
  parent: ILogicalOperatorRuleComponent,
  id: number
): IRuleComponent | null => {
  let comp: any = parent.operands.find((o: IRuleComponent) => o.id === id);
  if (comp) return comp;
  let containers: ILogicalOperatorRuleComponent[] = parent.operands
    .filter((o: IRuleComponent) => isContainerRuleComponent(o))
    .map((r: IRuleComponent) => r as ILogicalOperatorRuleComponent);
  for (let index: number = 0; index < containers.length; index++) {
    comp = findInnerRuleComponent(containers[index], id);
    if (comp) return comp;
  }
  if (containers.length === 0) {
    let compWithSeries: any[] = parentRule.operands.filter(
      (o: IRuleComponent) => o["series"] && o["series"].length > 0
    );
    let compWithSeriesCurrent: any[] = parent.operands.filter(
      (o: IRuleComponent) => o["series"] && o["series"].length > 0
    );
    for (let index: number = 0; index < compWithSeries.length; index++) {
      comp = compWithSeries[index].series.find((s: any) => s.id === id);
      if (comp) return comp;
    }
    for (let index: number = 0; index < compWithSeriesCurrent.length; index++) {
      comp = compWithSeriesCurrent[index].series.find((s: any) => s.id === id);
      if (comp) return comp;
    }
  }

  return null;
};

const createRuleComponent = (id: number, template: any): any => {
  let comp: any = {
    id: id,
    ruleComponent: template.Name
  };
  if (!template.TemplateOptions) {
    comp["operands"] = [];
    return comp;
  }

  if (template.componentType === "series") comp["series"] = [];
  else
    Object.keys(template.TemplateOptions).forEach((optionName: string) => {
      let option: any = template.TemplateOptions[optionName];
      let keyName = option.keyName;

      comp[keyName] = null;
    });
  return comp;
};

export function createSeriesOptions(
  templateOptions: ITemplateOption,
  comb: any[],
): any {
  const comp: any = {};
  comp["id"] = new Date().getTime() + Math.random();
  Object.keys(templateOptions).forEach((optionName: string) => {
    let option: any = templateOptions[optionName];
    let keyName = option.keyName;
    if (keyName === "initDoseValue") {
      comp[keyName] = comb[0];
    } else if (keyName === "finalDoseValue") {
      comp[keyName] = comb[1];
    } else {
      comp[keyName] = null;
    }
  });
  return comp;
}

export const isContainerRuleComponent = (rule: IRuleComponent): boolean => {
  return (
    rule.ruleComponent === "logical-and" ||
    rule.ruleComponent === "logical-or" ||
    rule.ruleComponent === "logical-not"
  );
};

export default RuleDefinitionReducer;

export let RuleTypes: any[] = [];
