import React, { FC, useMemo } from 'react';
import { Resizable } from 're-resizable';

import { IModalSliderProps } from './index';
import Header from './Header';

import { ColResizer, A11yWrapper } from './styled';

interface IProps extends IModalSliderProps {}

const handleComponent = {
  left: (
    <ColResizer>
      <div />
    </ColResizer>
  )
};

const enable = {
  left: true
};

const Component: FC<IProps> = (props) => {
  const { defaultWidth, children, onClose, minWidth, id, hideHeader } = props;

  const upperLimit = useMemo(() => {
    const screenWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    return Math.floor(screenWidth * 0.9);
  }, []);

  const defaultSize = useMemo(() => {
    return {
      width: defaultWidth
        ? parseInt(defaultWidth, 10) > upperLimit
          ? `${upperLimit}px`
          : defaultWidth
        : '500px',
      height: '100%'
    };
  }, [defaultWidth, upperLimit]);

  return (
    <Resizable
      minWidth={
        minWidth
          ? parseInt(minWidth, 10) > upperLimit
            ? `${upperLimit}px`
            : minWidth
          : '250px'
      }
      maxWidth={`${upperLimit}px`}
      defaultSize={defaultSize}
      handleComponent={handleComponent}
      enable={enable}
      className="slider-resizable-container"
    >
      <A11yWrapper id={id} role="dialog" aria-modal="true">
        {!hideHeader && <Header {...props} onClose={onClose} />}
        {children}
      </A11yWrapper>
    </Resizable>
  );
};

export default React.memo(Component);
