import { IStateReducer, IAction } from './types';

const prefix = 'UKVP_';

export const actionsTypes = {
    SET: `${prefix}SET`,
};

const initialState: IStateReducer = {
    summary: {}
};

const Reducer = (
    state: IStateReducer = { ...initialState },
    action: IAction
): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export default Reducer;
