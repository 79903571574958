import React, { FC } from 'react';
import { IHeaderV1Preview } from './types';
import { formattedDate } from 'src/Framework/Shared/Shared';

interface IProps {
  value: IHeaderV1Preview;
}

function formatPhoneNumber(phoneNumber: string | null) {
  if (!phoneNumber) {
    return phoneNumber;
  }

  // Remove non-digit characters
  var phoneDigits = phoneNumber.replace(/\D/g, '');
  var extension = '';
  if (phoneDigits.length > 10) {
    extension = phoneDigits.substring(10);
    phoneDigits = phoneDigits.substring(0, 10);
  }
  // Format the digits
  var result = phoneDigits.replace(
    /(\d{1,3})(\d{0,3})(\d{0,4})/,
    ' ($1) $2-$3'
  );

  if (extension) {
    result += ' Ext(' + extension + ')';
  }

  return result;
}

function formatZipCode(zipCode: string | null | undefined) {
  if (zipCode === null || zipCode === undefined || zipCode === '') {
    return zipCode;
  }

  if (zipCode.length > 5) {
    zipCode = zipCode.slice(0, 5) + '-' + zipCode.slice(5);
  }

  return zipCode;
}

const Component: FC<IProps> = ({ value }: IProps) => {
  const {
    patientFirstName,
    patientLastName,
    patientId,
    noteDate,
    patientDoB,
    clinicName,
    clinicAddress1,
    clinicCity,
    clinicState,
    clinicZip,
    clinicPhone1
  } = value;
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          borderBottom: '1px solid rgba(0,0,0,0.5)',
          padding: '0 0 20px 0',
          margin: '0 0 20px 0'
        }}
      >
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <span>
              <b>Name:</b> {patientFirstName} {patientLastName}
            </span>
            <span>
              <b>Account No:</b> {patientId}
            </span>
          </div>
        </div>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <span>
              <b>Date:</b> {formattedDate(noteDate)}
            </span>
            <span>
              <b>DOB:</b> {formattedDate(patientDoB)}
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <b>{clinicName}</b>
        <b>{clinicAddress1}</b>
        <b>
          {clinicCity}, {clinicState} {formatZipCode(clinicZip)}
        </b>
        <b>{formatPhoneNumber(clinicPhone1)}</b>
      </div>
    </div>
  );
};

export default React.memo(Component);
