import _ from 'lodash';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

const ActionManager = {
    ADD_SUBJCET_COMPLIANCES: "ADD_SUBJCET_COMPLIANCES",
}


interface IAction {
    type: string;
    payload: any;
    id: string;
}

const SubjectComplianceReducer = (
    state: any[] = [],
    action: IAction
) => {
    switch (action.type) {
        case ActionManager.ADD_SUBJCET_COMPLIANCES: {
            let newState = _.cloneDeep(state)
            let complianceStore = newState.find((store: any) => store.subjectId === action.payload.subjectId);
            if (!complianceStore) {
                newState.push(action.payload);
                return newState;
            }
            if (action.payload.pagination.current > 1) {
                let isExist: boolean = isExists(complianceStore.compliances, action.payload.compliances);
                if (isExist) return newState;
                complianceStore.compliances = [...complianceStore.compliances, ...action.payload.compliances];
                complianceStore.pagination = action.payload.pagination;
            } else {
                newState[newState.indexOf(complianceStore)] = action.payload;
            }
            return newState;

        }

        default: {
            return state;
        }

    }
};
export default SubjectComplianceReducer;

export const addSubjectCompliances = (data: any) => {
    return {
        type: ActionManager.ADD_SUBJCET_COMPLIANCES,
        payload: data
    }
};

export const getSubjectCompliances = (data: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_SUBJECT_COMPLIANCES, data, { withCredentials: true });
};

function isExists(stateList: any, actionList: any): boolean {
    if (actionList && actionList.length > 0) {
        let actionItem: any = actionList[0];
        let isExistRule: any = stateList.find((item: any) => {
            return item.complianceId === actionItem.complianceId;
        });
        if (isExistRule) return true;
    }
    return false;
}