import { IFileSimpleDto } from 'src/Framework/Controls/FileUploader';

export interface ITxnCodePagination {
  count: number;
  startIndex: number;
  total: number;
  summary: TxnCodeSimple[];
}

export enum TypeTxnClass {
  PointOfCare = 'pointOfCare',
  Radiology = 'radiology',
  Procedure = 'procedure',
  Laboratory = 'laboratory',
  Pharmacy = 'pharmacy',
  Recall = 'recall',
  PatientEducation = 'patientEducation',
  Allergy = 'allergy',
  Nurse = 'nurse',
  Referral = 'referral',
  Plan = 'plan'
}

// export enum ETypeTxnClass {
//   Procedure = 0,
//   Laboratory = 1,
//   Radiology = 2,
//   Pharmacy = 3,
//   Recall = 4,
//   PatientEducation = 5,
//   Allergy = 6,
//   Nurse = 7,
//   Referral = 8,
//   Hospitalization = 9,
//   Plan = 10,
//   PointOfCare = 11,
//   ChargeAdjustment = 12,
//   InsurancePayment = 13,
//   PaymentAdjustment = 14
// }

export enum ETypeTxnClassId {
  procedure = 0,
  laboratory = 1,
  radiology = 2,
  pharmacy = 3,
  recall = 4,
  patienteducation = 5,
  allergy = 6,
  nurse = 7,
  referral = 8,
  plan = 10,
  pointOfCare = 11,
  // hospitalization = 9,
  // chargeAdjustment = 12,
  // insurancePayment = 13,
  // paymentAdjustment = 14
}

// export const typeTxnClassIdToNames = {
//   '0': 'procedure',
//   '1': 'laboratory',
//   '2': 'radiology',
//   '3': 'pharmacy',
//   '4': 'recall',
//   '5': 'patientEducation',
//   '6': 'allergy',
//   '7': 'nurse',
//   '8': 'referral',
//   '9': 'hospitalization',
//   '10': 'plan',
//   '11': 'pointOfCare',
//   '12': 'chargeAdjustment',
//   '13': 'insurancePayment',
//   '14': 'paymentAdjustment'
// };

export enum TypeTxnName {
  Message = 'Message',
  InsurancePayment = 'Insurance Payment',
  ChargeAdj = 'Charge Adj.',
  PaymentAdj = 'Payment Adj.',
  Payment = 'Payment',
  Charge = 'Charge',
  Memo = 'Memo',
  Date = 'Date'
}

export enum TxnFrontEndType {
  PaymentAdjustment = 'PaymentAdjustment',
  Payment = 'Payment',
  ChargeAdjustment = 'ChargeAdjustment',
  InsurancePayment = 'InsurancePayment',
  Homework = 'Homework',
  Education = 'Education',
  ProcedureAndServices = 'ProcedureAndServices',
  Laboratory = 'Laboratory',
  Radiology = 'Radiology',
  Pharmacy = 'Pharmacy',
  PointOfCare = 'PointOfCare',
  ReferralAndRecalls = 'ReferralAndRecalls',
  Allergy = 'Allergy',
  Nurse = 'Nurse',
  ReferralOrders = 'ReferralOrders',
  Rx = 'Rx'
}

export enum PaymentType {
  Cash = 'Cash',
  Check = 'Check',
  Credit = 'Credit',
  Message = 'Message'
}

export enum DrugType {
  OTC = 'OTC',
  RX = 'RX'
}

export interface TxnCodeSimple {
  id: number;
  code: string;
  cptCode?: string | number;
  file: IFileSimpleDto | null;
  typeTxnClassId: number;
  typeTxnName: TypeTxnName;
  excludeFromVisitSummary: boolean;
  groupId: number | null;
  isActive: boolean;
  name: string;
  onlineName: string;
  shortDescription: string;
  typeTxnClass: TypeTxnClass;
  feType: TxnFrontEndType | null;
}

export interface ITxnCodeBilling {
  cptCode: string;
  defaultExclude: boolean;
  defaultUnits: number;
  dental: boolean;
  glAccountCode: string;
  modifier1: string;
  modifier2: string;
  noc: string;
  revenueCode: string;
  taxable: boolean;
  txnFees: ITxnFee[];
  typeOfServiceCode: string;
  uB92: boolean;
  upc: string;
  paymentType: '' | PaymentType;
  adjustmentTypeId: null | number;
}

export interface ITransactionComponent {
  isIncludeToTicket: null | boolean;
  txnIds: number[];
}

export interface ResultDto {
  result: string | null;
  id?: number;
  // this param only for frontend
  uuid?: string
}

export interface TxnCodeResultDto {
  resultRequired: boolean
  results: ResultDto[]
}

export interface TxnCode extends TxnCodeSimple {
  isComponent: boolean | null;
  transactionComponent: ITransactionComponent | null;
  codeUploadVersion: null | string;
  officialDescriptionLong: null | string;
  officialDescriptionMedium: null | string;
  officialDescriptionShort: null | string;
  file: IFileSimpleDto | null;
  fileId: number | null;
  billing: ITxnCodeBilling | null;
  templateFormSeedId: number | null;
  m1FormTemplateId: number | null;
  result: TxnCodeResultDto | null
  immunization: {
    complianceDocument: boolean;
    allowableWaiversId: number[];
    typeCDCDefinitionID: number | null;
    visDate: string;
    immunization: boolean;
    immuneDefinition: {
      dose: number;
      showOnline: boolean;
      viewOnline: boolean;
      onlineName: string;
      consumerComment: string;
      typeOSHImmEntryFrameId: number | null;
    };
  } | null;
  inventory: {
    reOrderLevel: null | number;
    reOrder: boolean;
    inventory: boolean;
    inventories: IInventory[];
  } | null;
  order: {
    alertMessage: string;
    commentRequired: boolean;
    defaultComment: string;
    typeSpecimenId: number | null;
    typeTemperatureId: number | null;
    resultM1FormTemplateId: number | null;
    resultRequired: boolean
    labType: number | null;
  } | null;
  returnIn: {
    day: number;
    month: number;
    year: number;
    isToDoForProvider: boolean | null;
  } | null;
  medication: {
    ndc: string;
    rxCUI: string;
    unii: string;
    rxnormDisplay: string;
    allergyTypeId: number | null;
    drugType: DrugType | null;
  } | null;
}

export interface IInventory {
  id: number;
  manufacturerCode: string;
  description: string;
  brandName: string;
  lotNo: string;
  expiration: string;
  countHand: number;
  packagesAvailable: number;
  typePackagingUnitId: number;
  unitsPerPackage: number;
  totalCost: number;
  unitsAvailable: number;
  costPerUnit: number;
}

export interface ITxnFee {
  typeFeeId: number;
  amount: number;
  rvu: number;
  instruction: string;
  alternateCPT: string;
}
