import React, { FC } from 'react';

import { TagContainer } from './styled';

interface IProps {
  children: React.ReactNode;
  whiteBorders?: boolean;
}

const Component: FC<IProps> = ({ children, whiteBorders }: IProps) => {
  return <TagContainer whiteBorders={whiteBorders}>{children}</TagContainer>;
};

export default React.memo(Component);
