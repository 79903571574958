import _ from 'lodash';
import * as actionTypes from './PatientManagerActions';

const initialState: any = {
  currentSection: 'Demographics',
  searchData: [],
  // [negative number] = initial state, show green or empty
  // 0 = validated, show green
  // [positive number] = number of validation error in the section, show astrix for this
  sectionValidation: {
    demographics: -9999999,
    studentInfo: -9999999
  },
  newStudent: {},
  sort: {
    sortBy: null,
    sortType: null
  }
};

interface IAction {
  type: string;
  payload: any;
}

const PatientManagerReducer = (
  state: any = { ...initialState },
  action: IAction
) => {
  switch (action.type) {
    case actionTypes.SET_VIEW: {
      let newState = _.cloneDeep(state);
      newState.currentSection = action.payload;
      return newState;
    }
    case actionTypes.SET_SEARCH_DATA: {
      let newState = _.cloneDeep(state);
      newState.searchData = action.payload;
      return newState;
    }
    case actionTypes.SET_VALIDATION: {
      let newState = _.cloneDeep(state);
      newState.sectionValidation = action.payload;
      return newState;
    }
    case actionTypes.SET_NEW_STUDENT: {
      let newState = _.cloneDeep(state);
      newState.newStudent = action.payload;
      return newState;
    }
    case actionTypes.SET_SORT_PATIENTS:
      let newState = _.cloneDeep(state);
      return {
        ...newState,
        sort: action.payload
      };

    default:
      return state;
  }
};
export default PatientManagerReducer;
