import React, { FC, Fragment, useCallback, useMemo, useRef } from 'react';
import useLongPress from 'src/Framework/Hooks/useLongPress';
import Loader, { IHandles } from './Loader';

import { Container } from './styled';

interface IProps {
  onLongPress?: () => any;
  onClick?: () => any;
  children: (data?: {
    longPressEvents: ReturnType<typeof useLongPress>['events'];
    longPressTriggered: boolean;
  }) => React.ReactChild;
}

const Component: FC<IProps> = ({ onLongPress, children, onClick }: IProps) => {
  const loaderRef = useRef<IHandles>(null);
  const onChangePercentage = useCallback((value: number) => {
    loaderRef.current?.onChange(value);
  }, []);
  const { events, longPressTriggered } = useLongPress({
    onLongPress,
    onChangePercentage,
    onClick
  });
  const data = useMemo(() => {
    return {
      longPressEvents: events,
      longPressTriggered
    };
  }, [events, longPressTriggered]);
  if (!onLongPress) return <Fragment>{children()}</Fragment>;
  return (
    <Container>
      <Loader ref={loaderRef} />
      {children(data)}
    </Container>
  );
};

export default React.memo(Component);
