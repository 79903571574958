import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Wrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  flex-flow: column nowrap;
  background: #ffffff;
  transition: all 0.25s ease;
  overflow: hidden;
  transition: all 0.25s ease;
  z-index: 110;
  border-bottom: 1px solid ${globalStyleVariables.borderColor};
  max-height: 60px;
  min-height: 60px;

  &.active {
    max-height: 500px;
    overflow: auto;
    transition: max-height 0.25s ease-in;
  }
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: flex-start;
  background: #ffffff;
  padding: 0 125px 0 0;
`;

export const ColumnPicture = styled.div`
  margin: 10px 0;
  padding: 0 24px;
  display: flex;
  align-items: center;
  position: relative;
  flex-flow: row nowrap;

  &:after {
    content: '';
    display: block;
    position: absolute;
    background: #e6e6e6;
    width: 1px;
    height: 40px;
    top: 0;
    right: 0;
  }
`;

export const Picture = styled.div`
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  border: 1px solid #333333;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
  font-size: 16px;
  font-family: Lato;
  margin-right: 8px;
  font-weight: 900;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Name = styled.div`
  font-family: Encode Sans Expanded;
  //font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  color: #333333;
  font-weight: 800;
  flex-flow: row nowrap;
  justify-content: flex-start;
  white-space: nowrap;
  max-width: 320px;

  .nick_name {
    font-weight: 400;
    margin-left: 4px;
  }
`;

export const Icons = styled.div`
  position: absolute;
  right: 24px;
  top: 16px;
  display: flex;
  gap: 8px;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;

  &#firstRow {
    .show-all-values {
      display: none;
    }
  }

  .corporateScreening {
    margin: 0 10px;
  }

  &#secondRow {
    padding: 0;
    flex-flow: row wrap;

    .tooltip-values {
      display: none;
    }

    .with__tooltip {
      p {
        display: none;
      }
    }

    .show-all-values {
      p {
        display: block;
        white-space: pre;
      }
    }
  }
`;

export const Column = styled.div`
  font-family: Lato;
  font-weight: normal;
  padding: 0 24px;
  position: relative;
  margin: 10px 0;
  white-space: nowrap;

  &:after {
    content: '';
    display: block;
    position: absolute;
    background: #e6e6e6;
    width: 1px;
    height: 40px;
    top: 0;
    right: 0;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  span {
    font-size: 12px;
    color: ${globalStyleVariables.middleGrey};
    margin: 4px 0 0;
    white-space: nowrap;
  }

  p {
    font-size: 14px;
    color: #333333;
    margin: 0;
  }

  .__react_component_tooltip {
    padding: 8px;
    display: flex;
    flex-flow: column;
    font-size: 12px;
    align-items: flex-start;

    .multi-line {
      padding: 0;
    }

    &.type-dark {
      background: rgba(51, 51, 51, 0.7);

      &.place-bottom:after {
        border-bottom-color: rgba(51, 51, 51, 0.7);
      }
    }
  }

  .with__tooltip {
    p {
      white-space: nowrap;
      display: flex;
      align-items: center;

      .tooltip-values {
        padding: 0 4px;
        height: 24px;
        border-radius: 2px;
        color: ${globalStyleVariables.newBrandingPrimary200};
        background: ${globalStyleVariables.newBrandingPrimary10010};
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.25s ease;
        margin-left: 10px;

        &:hover,
        &:focus {
          color: white;
          background: ${globalStyleVariables.newBrandingPrimary200};
        }
      }
    }
  }
`;

export const RowHidden = styled.div`
  opacity: 0;
  display: flex;
  flex-flow: row wrap;
  visibility: hidden;
  height: 0;
  padding-right: 110px;

  .show-all-values {
    p {
      display: block;
      white-space: pre;
    }
  }

  .with__tooltip {
    p {
      display: none;
    }
  }

  @media only screen and (max-width: 991px) {
    flex-flow: column;
  }
`;

export const Body = styled.div`
  display: flex;
  position: relative;
  top: 0;
  background: #ffffff;

  &.second-row-show {
    background: #ffffff;
    transition: all 0.25s ease;
    width: 100%;
    z-index: 1;
  }
`;
