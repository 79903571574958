import _ from 'lodash';

const StatusActionManager = {
    ADD_STATUS_ITEM: "ADD_NEW_STATUS_ITEM",
    REMOVE_STATUS_ITEM: "REMOVE_STATUS_ITEM",
    CHANGE_STATUS_ITEM: "CHANGE_STATUS_ITEM",
}

interface IAction {
    type: string;
    payload: any;
    activityId: any;
}

const StatusReducer = (
    state: any[] = [],
    action: IAction
) => {
    switch (action.type) {
        case StatusActionManager.ADD_STATUS_ITEM: {
            let newState = _.cloneDeep(state)
            let index = newState.findIndex((o: any) => o.activityId === action.payload.activityId);
            if (index === -1) {
                newState.push(action.payload);
                return newState;
            }
            return newState;
        }
        case StatusActionManager.REMOVE_STATUS_ITEM: {
            let newState = _.cloneDeep(state)
            let index = newState.findIndex((o: any) => o.activityId === action.activityId);
            if (index === -1) return newState;
            newState.splice(index, 1);
            return newState;
        }
        case StatusActionManager.CHANGE_STATUS_ITEM: {
            let newState = _.cloneDeep(state)
            let status = newState.find((o: any) => o.activityId === action.activityId);
            if (!status) return newState;
            status.selectRule = !status.selectRule;
            return newState;
        }
        default: {
            return state;
        }

    }
};
export default StatusReducer;

export const addStatus = (data: any) => {
    return {
        type: StatusActionManager.ADD_STATUS_ITEM,
        payload: data
    }
};
export const removeStatus = (activityId: any) => {
    return {
        type: StatusActionManager.REMOVE_STATUS_ITEM,
        activityId: activityId
    }
};
export const changeStatus = (activityId: any) => {
    return {
        type: StatusActionManager.CHANGE_STATUS_ITEM,
        activityId: activityId
    }
};
