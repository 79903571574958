import React, { FC, useCallback, useMemo, useRef } from 'react';
import { Form, Components } from '@formio/react';

import { Templates } from 'formiojs';

import _ from 'lodash';
import { IProps } from './index';
import { spellReplacer } from 'src/Framework/Controls/SelectContainer/Components/Area';

import M1multiselect, {
  componentType as m1multiselectType
} from '../M1multiselect';
import M1Image, { componentType as m1ImageType } from '../M1Image';
import M1PatientForm, {
  componentType as m1PatientFormType
} from '../M1PatientForm';
import { _FormLogger } from './logger';
import { FormProps } from '@formio/react/lib/components/Form';
import { formattedDateTime } from 'src/Framework/Shared/Shared';

const radioTemplate = Templates.current.radio.html;

const selectTemplate = Templates.current.select.html;

const inputTemplate = Templates.current.input.html;

Templates.current = {
  input: {
    html: (ctx: any) => {
      if (ctx.component.type === 'datetime') {
        const key = ctx.key;
        const value = ctx.data?.[key];
        if (value) {
          ctx.value = formattedDateTime(value);
          return inputTemplate(ctx);
        }
      }
      return inputTemplate(ctx);
    }
  },
  radio: {
    html: (ctx: any) => {
      if (ctx.instance.type === 'radio') {
        const value = ctx.submission.data[ctx.key];
        if (/^\d+$/.test(value)) {
          const values = ctx.values;
          const answer = values.find(
            (v: any) => `${v.value}` === `${value}`
          )?.label;
          return radioTemplate(ctx) + (answer || '');
        }
      }
      return radioTemplate(ctx);
    }
  },
  select: {
    html: (ctx: any) => {
      if (ctx.instance.type === 'select') {
        if (ctx.component.dataSrc === 'json') {
          if (typeof ctx.value === 'object') {
            const keys = Object.keys(ctx.value).length;
            if (keys === 0) {
              ctx.value = '';
            } else {
              ctx.value = ctx.value.label ?? '';
            }
          }
        } else {
          ctx.value = `${ctx.value}`;
        }
      }
      return selectTemplate(ctx);
    }
  }
};

Components.setComponents({
  [m1multiselectType]: M1multiselect,
  [m1ImageType]: M1Image,
  [m1PatientFormType]: M1PatientForm
});

export const validator = (instance: any) => {
  let value: any[] = [];
  const data: {
    instance: any;
    validated: {
      level: 'error';
    }[];
  }[] = [];
  const checker = (components: any) => {
    components.forEach((subInstance: any) => {
      if (subInstance.visible) {
        const check = subInstance.validator.check(subInstance);
        data.push({
          instance: subInstance,
          validated: check
        });
        value = [...value, ...check];
        if (subInstance.components) {
          checker(subInstance.components);
        }
      }
    });
  };
  checker(instance.components);
  return { value, data };
};

const Component: FC<IProps> = (props: IProps) => {
  const {
    schema,
    submission,
    onChange,
    readOnly,
    previewMode,
    getFormioInstance
  } = props;
  const formInstance = useRef<any>(null);
  const options = useMemo(() => {
    const optionsData: FormProps['options'] = {};
    if (previewMode) {
      // @ts-ignore
      optionsData.renderMode = 'html';
      optionsData.readOnly = true;
    }
    if (readOnly) {
      optionsData.readOnly = true;
    }
    return optionsData;
  }, [readOnly, previewMode]);

  const memoSubmission = useMemo(() => {
    return { data: submission };
  }, [submission]);
  const change = useCallback((data: any) => {
    _FormLogger.log('OnChangeTriggered - init', { data, formInstance });
    if (readOnly) return;
    const newData = _.cloneDeep(data.data);
    if (data.changed) {
      const changed = data.changed;
      _FormLogger.log('OnChangeTriggered - changed', { changed });
      if (changed) {
        const validationData = validator(data.changed.instance.root);
        const validated = validationData.value;
        _FormLogger.log('Validated', { validated });
        const isTextArea = changed.component.type === 'textarea';
        const isTextField = changed.component.type === 'textfield';
        if (isTextArea || isTextField) {
          _FormLogger.log('SpellReplacer', { isTextArea, isTextField });
          const lastCharacter = changed.value.slice(-1);
          if (lastCharacter === ' ' || lastCharacter === '\n') {
            _FormLogger.log('SpellReplacer', 'Trigger enabled');
            const key = changed.component.key;
            const replacer = spellReplacer(changed.value);
            _FormLogger.log('SpellReplacer', { replacer });
            if (replacer.changed) {
              let changedText = replacer.text;
              if (lastCharacter === ' ') {
                changedText = changedText.slice(0, -1);
              }
              if (lastCharacter === '\n') {
                changedText = changedText.slice(0, -1);
              }
              newData[key] = changedText;
              _FormLogger.log('SpellReplacer', { changedText });
              data.changed.instance.setValue(changedText);
            }
          }
        }
        onChange(newData, validated.length === 0, validated, false, {
          validationData
        });
      }
    } else {
      _FormLogger.log('OnChangeTriggered - changed empty', {});
      if (formInstance.current) {
        const validationData = validator(formInstance.current);
        const validated = validationData.value;
        _FormLogger.log('Validated', { validated, formReadyTrigger: true });
        onChange(newData, validated.length === 0, validated, true, {
          validationData
        });
      }
    }
  }, []);
  const formReady = useCallback((formioInstance: any) => {
    _FormLogger.log('FormReady', { formioInstance });
    formInstance.current = formioInstance;
    if (getFormioInstance) {
      getFormioInstance(formioInstance);
    }
  }, []);
  return (
    <Form
      form={schema}
      submission={memoSubmission}
      onChange={change}
      options={options}
      formReady={formReady}
    />
  );
};

export default React.memo(Component);
