import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const ActivityDiv = styled.div`
  width: 19rem;
  min-width: 19rem;
  display: flex;
  padding: 0.5rem;
  padding-bottom: 4px;
  padding-top: 4px;
  height: 100%;
  cursor: pointer;
  color: ${(props: { isCurrent: boolean }) =>
    props.isCurrent ? '#333333' : '#cccccc'};
`;
export const ActivityItemWrapper = styled.div`
  width: 17rem;
  display: flex;
  padding: 0.2rem;
  padding-bottom: 0;
`;
export const ActivityLine = styled.div`
  margin-left: 0.2rem;
  height: 40px;

  svg {
    height: 100%;
  }
`;

export const ActivityItemContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`;

export const ActivityHeaderText = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
  min-height: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 12rem;
`;

export const ActivityClose = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 400;
  width: 20px;
  height: 20px;
  color: grey;

  &:hover {
    background-color: #e84444;
    color: #ffffff;
    border-radius: 50%;
  }
`;
export const ActivityBodyContent = styled.div`
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
  display: inline-block;
  /* text-transform: capitalize; */

  span {
    text-transform: capitalize;
  }
`;
export const ActivityIcon = styled.div`
  width: 2rem;
  height: 2rem;

  svg {
    stroke: ${(props: { isCurrent?: boolean }) =>
      props.isCurrent ? globalStyleVariables.newBrandingPrimary200 : '#cccccc'};
  }
`;
