import _ from 'lodash';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

export const CohortActionManager = {
    ADD_COHORT_OVERVIEW: "ADD_COHORT_OVERVIEW",
    CLEAR_COHORT: "CLEAR_COHORT"
}

export interface ICohortOverview {
    cohortId: string;
    included: boolean;
    name: string;
    description: string;
    membershipId: string;
    members: number;
    jobId: string;
    jobStatus: number;
    required: number;
    nonRequired: number;
    ruleVersion: number;
    ruleName: string,
    complianceCount: number,
    nonComplianceCount: number,
    complianceRate: number,
    nonComplianceRate: number
}

interface IAction {
    type: string;
    payload: ICohortOverview;
    id: string;
}

const CohortReducer = (
    state: ICohortOverview[] = [],
    action: IAction
) => {
    switch (action.type) {
        case CohortActionManager.ADD_COHORT_OVERVIEW: {
            let newState = _.cloneDeep(state)
            let index = newState.findIndex((o: ICohortOverview) => o.cohortId === action.payload.cohortId);
            if (index === -1) newState.push(action.payload);
            else newState[index] = action.payload;
            return newState;
        }
        case CohortActionManager.CLEAR_COHORT: {
            let newState = _.cloneDeep(state)
            let index = newState.findIndex((o: ICohortOverview) => o.cohortId === action.id);
            if (index === -1) return newState;
            newState.splice(index, 1);
            return newState;
        }
        default: {
            return state;
        }

    }
};
export default CohortReducer;

export const addCohortOverview = (data: any) => {
    return {
        type: CohortActionManager.ADD_COHORT_OVERVIEW,
        payload: data
    }
};

export const getCohortOverview = (data: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_COHORT_OVERVIEW, data, { withCredentials: true });
};

export const removeComplianceCriteria = (data: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.REMOVECOMPLIANCECRITERIA, data, { withCredentials: true });
}

export const getCohortId = (data: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_COHORT_ID, data, { withCredentials: true });
}