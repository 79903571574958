import React, { FC, useState } from 'react';
import cn from 'classnames';
import { Button, Row, Col, Tooltip } from 'antd';
import { ButtonProps } from 'antd/es/button';
import { TooltipProps } from 'antd/es/tooltip';

import Modal from 'src/Framework/Controls/Modal';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

import { ModalContainer, Text, ButtonContainer } from './styled';

interface IColorStyles {
  grey: boolean;
  green: boolean;
  greenBorder: boolean;
  success: boolean;
  successBorder: boolean;
  greyBorder: boolean;
  blueBorder: boolean;
  blue: boolean;
  boldFont: boolean;
  redBorder: boolean;
  secondColorBackground: boolean;
  orange: boolean;
  transparent: boolean;
}

export interface IProps extends ButtonProps {
  tooltip?: Partial<TooltipProps>;
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  id: string;
  aprove?: {
    title: string;
    text?: string;
  };
  absoluteLoading?: boolean;
  colorStyles?: Partial<IColorStyles>;
  disableClickLoader?: boolean;
  buttonRef?: React.MutableRefObject<any>;
}

/**
 *  {@link Component} used like container for antd Button component
 * Also have some feature like
 * @param approve - for approve modal
 * @note And loader if onClick is Promise (this needs some update)
 */
const Component: FC<IProps> = ({
  onClick,
  aprove,
  id,
  tooltip,
  colorStyles,
  absoluteLoading,
  disableClickLoader,
  ...otherProps
}: IProps) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const onClickAction = async () => {
    if (loading) return;
    setLoading(true);
    if (onClick) {
      await onClick();
      close();
    }
    setLoading(false);
  };
  const close = () => {
    setVisible(false);
  };
  const renderButton = () => (
    <ButtonContainer>
      <Button
        ref={otherProps.buttonRef}
        id={id}
        shape="round"
        loading={loadingButton}
        {...otherProps}
        className={cn([
          otherProps.className,
          {
            orangeButton: colorStyles?.orange,
            greyButton: colorStyles?.grey,
            greenButton: colorStyles?.green,
            greenBorder: colorStyles?.greenBorder,
            successButton: colorStyles?.success,
            successBorder: colorStyles?.successBorder,
            blueBorder: colorStyles?.blueBorder,
            greyBorder: colorStyles?.greyBorder,
            blue: colorStyles?.blue,
            boldFont: colorStyles?.boldFont,
            redBorder: colorStyles?.redBorder,
            secondColorBackground: colorStyles?.secondColorBackground,
            transparent: colorStyles?.transparent,
            disabled: otherProps.disabled
          }
        ])}
        onClick={async (e) => {
          if (aprove) {
            setVisible(true);
          } else {
            if (onClick) {
              if (loadingButton) return;
              if (!disableClickLoader) {
                setLoadingButton(true);
              }
              await onClick(e);
              setLoadingButton(false);
            }
          }
        }}
        onKeyDown={onKeyDownAccessibility}
      >
        {absoluteLoading && <AbsoluteLoader />}
        {otherProps.children}
      </Button>
    </ButtonContainer>
  );
  return (
    <>
      {tooltip ? (
        <Tooltip
          overlayStyle={{
            zIndex: 10000
          }}
          title=""
          {...tooltip}
        >
          <div>{renderButton()}</div>
        </Tooltip>
      ) : (
        renderButton()
      )}
      {aprove && (
        <Modal
          width={500}
          title={aprove.title}
          visible={visible}
          onClose={close}
          headerBorder={true}
          modalProps={{
            wrapClassName: 'alert-modal',
            destroyOnClose: true,
            centered: true
          }}
        >
          <ModalContainer>
            <Row
              gutter={16}
              style={{
                flexWrap: 'nowrap'
              }}
              align="middle"
            >
              <Col>
                <Text>{aprove.text || 'Are you sure?'}</Text>
              </Col>
              <Col>
                <Row
                  gutter={16}
                  style={{
                    flexWrap: 'nowrap'
                  }}
                  align="middle"
                >
                  <Col>
                    <Button
                      id={`${id}approve-modal-yes`}
                      loading={loading}
                      onClick={onClickAction}
                      shape="round"
                    >
                      Yes
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      id={`${id}approve-modal-no`}
                      onClick={close}
                      shape="round"
                    >
                      No
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

export default React.memo(Component);
