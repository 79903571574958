import * as actionTypes from "./NoteNavigationActions";
import _ from 'lodash';

const initialState: any = {
    noteId: null,
    currentSection: "section0",
    currentPosition: 0,
    orderedTemplates: [],
    isScrolling: false,
    loadingMoff: false,
    savingNote: false,
    sectionsMissingMandatory: false,
};

interface IAction {
    type: string;
    payload: any;
    id: any;
    property: string;
    valid?: boolean;
}

const NoteReducer = (
    state: any = { ...initialState },
    action: IAction
) => {
    switch (action.type) {
        case actionTypes.SET_CURRENT_SECTION: {
            let newState = _.cloneDeep(state)
            newState.noteId = action.id;
            newState.currentSection = action.payload;
            return newState;
        }
        case actionTypes.SET_CURRENT_POSITION: {
            let newState = _.cloneDeep(state)
            newState.noteId = action.id;
            newState.currentPosition = action.payload;
            return newState;
        }
        case actionTypes.IS_SCROLLING: {
            let newState = _.cloneDeep(state)
            newState.noteId = action.id;
            newState.isScrolling = action.payload;
            return newState;
        }
        case actionTypes.ORDERED_TEMPLATES: {
            let newState = _.cloneDeep(state)
            newState.noteId = action.id;
            newState.orderedTemplates = action.payload;
            return newState;
        }
        case actionTypes.LOADING_MOFF: {
            let newState = _.cloneDeep(state)
            newState.noteId = action.id;
            newState.loadingMoff = action.payload;
            return newState;
        }
        case actionTypes.SAVING_NOTE: {
            let newState = _.cloneDeep(state)
            newState.savingNote = action.payload;
            return newState;
        }
        case actionTypes.SET_SECTIONS_VALID: {
            let newState = _.cloneDeep(state)
            newState.sectionsMissingMandatory = true;
            return newState;
        }
        default:
            return state;
    }
};
export default NoteReducer;