import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';

import { useAppSelector } from 'src/store';
import { IProvider, IRoles } from 'src/App/Admin/Pages/Providers/store/types';
import { ISelect } from 'src/Framework/Controls/SelectContainer/Components/Select';

import { Container } from './styled';

import Multiple, { IMultiple } from './Multiple';
import Single, { ISingle } from './Single';
import { getProviderClinicAssignment, providerDisplayName } from './utils';
import { useProviderOutOfOffice } from 'src/App/ProviderOutOfOffice/store/Hooks/useProviderOutOfOffice';
// import { Col, Row } from 'antd';
// import Icon from '../../Icon';
// import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
// import EllipsisText from '../../EllipsisText';

type SelectProps = ISingle | IMultiple;

export interface ICommon {
  selectProps?: Partial<ISelect>;
  filterByClinic?: any;
  disabled?: boolean;
  roleFilter?: IRoles;
  disableFilterRole?: boolean;
  onChange: (value: any, option?: any) => any;
  disableSearch?: boolean;
  blurChange?: boolean;
  filterByClinics?: number[];
  useCode?: boolean;
  containerStyles?: React.CSSProperties;
  providerFilter?: (provider: IProvider) => boolean;
  filterByReasons?: number[];
  isSecureFilterByReasons?: boolean;
  disableOutOfOfficeValidation?: boolean;
}

type IOwnProps = ICommon & SelectProps;
export type IProps = IOwnProps;

const Component: FC<IProps> = (props: IProps) => {
  const providerOutOfOffice = useProviderOutOfOffice();
  const providers = useAppSelector((state) => state.user.providers.summary);
  const providerTypes = useAppSelector(
    (state) => state.adminPanel.providerTypes.summary
  );
  const {
    onChange,
    value,
    filterByClinic,
    roleFilter,
    disableFilterRole,
    multiple,
    blurChange,
    filterByClinics,
    useCode,
    containerStyles,
    providerFilter,
    isSecureFilterByReasons,
    filterByReasons
    // disableOutOfOfficeValidation
  } = props;
  const [currentValue, setCurrentValue] = useState(null);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  // const titleGenerator = (providerId: number) => {
  //   const outOfOffice = providerOutOfOffice.data[providerId];
  //   const name = providerDisplayName(providerId);
  //   const errors = [];
  //   if (outOfOffice?.outOfOffice) {
  //     errors.push('Out of Office');
  //   }
  //   if (outOfOffice?.autoReply) {
  //     errors.push('Auto Reply');
  //   }
  //   const disabled = disableOutOfOfficeValidation
  //     ? false
  //     : outOfOffice?.outOfOffice;
  //   return {
  //     title:
  //       !disableOutOfOfficeValidation && errors.length > 0 ? (
  //         <Row
  //           align="middle"
  //           gutter={4}
  //           wrap={false}
  //           style={{
  //             cursor: disabled ? 'not-allowed' : undefined
  //           }}
  //         >
  //           <Col flex={1}>
  //             <EllipsisText>{name}</EllipsisText>
  //           </Col>
  //           <Col>
  //             <Icon
  //               name="information2"
  //               color={globalStyleVariables.red}
  //               tooltip={errors.join(', ')}
  //             />
  //           </Col>
  //         </Row>
  //       ) : (
  //         <EllipsisText>{name}</EllipsisText>
  //       ),
  //     disabled: disabled
  //   };
  // };

  const filterByReasonsTrigger = useMemo(() => {
    if (filterByReasons) {
      return filterByReasons.join(',');
    }
    return '';
  }, [filterByReasons]);

  const filterByClinicsTrigger = useMemo(() => {
    if (filterByClinics) {
      return filterByClinics.join(',');
    }
    return '';
  }, [filterByClinics]);

  const options = useMemo(() => {
    let data = Object.values(providers).filter((v) => v.isActive);
    if (filterByReasons) {
      let filteredData = data.filter((v) =>
        v.providerTypeIds.find((val) =>
          filterByReasons.find(
            (v) => providerTypes[val]?.suggestedAppointmentReasons[v]
          )
        )
      );
      if (filteredData.length !== 0) {
        data = filteredData;
      } else {
        if (!isSecureFilterByReasons) {
          data = filteredData;
        }
      }
    }
    if (filterByClinics) {
      if (filterByClinics.length !== 0) {
        data = data.filter((v) =>
          filterByClinics.find((id) => {
            const clinic = getProviderClinicAssignment(v, id);
            if (clinic) {
              if (disableFilterRole) {
                return true;
              }
              return clinic.roles.includes(
                roleFilter ? roleFilter : 'rendering'
              );
            }
            return false;
          })
        );
      }
    }
    if (filterByClinic) {
      data = data.filter((v) => {
        const clinic = getProviderClinicAssignment(v, filterByClinic);
        if (clinic) {
          if (disableFilterRole) {
            return true;
          }
          return clinic.roles.includes(roleFilter ? roleFilter : 'rendering');
        }
        return false;
      });
    }
    if (providerFilter) {
      data = data.filter((v) => providerFilter(v));
    }

    return data
      .map((value) => {
        const name = providerDisplayName(value.providerId);
        return {
          title: name,
          value: useCode ? value.code : `${value.providerId}`,
          provider: value,
          optionSearch: name
        };
      })
      .sort((a, b) => a.optionSearch?.localeCompare(b.optionSearch));
  }, [
    providerFilter,
    filterByClinic,
    disableFilterRole,
    filterByClinicsTrigger,
    providers,
    filterByReasonsTrigger,
    roleFilter,
    useCode,
    providerTypes,
    providerOutOfOffice
  ]);
  const allOptions = useMemo(() => {
    return Object.values(providers).map((value) => {
      const name = providerDisplayName(value.providerId);
      return {
        title: name,
        value: useCode ? value.code : `${value.providerId}`,
        optionSearch: name
      };
    });
  }, [providers, useCode]);
  const onChangeValue = useCallback(
    (value: any, option: any) => {
      if (blurChange) {
        setCurrentValue(value);
      } else {
        onChange(value, option);
      }
    },
    [blurChange, onChange]
  );
  return (
    <Container style={containerStyles}>
      {multiple ? (
        <Multiple {...props} options={options} multiple={true} />
      ) : (
        <Single
          {...props}
          multiple={false}
          options={options}
          allOptions={allOptions}
          currentValue={currentValue}
          onChangeValue={onChangeValue}
        />
      )}
    </Container>
  );
};

export default React.memo(Component);
