import React, { FC, Suspense } from 'react';
import AbsoluteLoader from '../AbsoluteLoader';

interface IProps {
  children: React.ReactNode;
}

const Component: FC<IProps> = ({ children }: IProps) => {
  return <Suspense fallback={<AbsoluteLoader />}>{children}</Suspense>;
};

 

export default Component;
