import store from 'src/store/TemporaryStore';

import { actionsTypes } from './index'
import { ITempPatient } from './types';

export const set = (payload: any) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload
    })
}

export const setPatients = (payload: Record<string, ITempPatient>) => {
    store.dispatch({
        type: actionsTypes.SET_PATIENTS,
        payload
    })
}