import React from "react";

export const SquaredPdf = (props: any) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M12.5054 0.630005V5.63001H17.5054" stroke="#111111" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.5044 8.13V5.63L12.5044 0.630005H2.50439V8.13" stroke="#111111" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.50439 16.88V19.38H17.5044V16.88" stroke="#111111" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.21929 11.9457C8.21929 12.3444 8.10179 12.6525 7.86679 12.87C7.63179 13.0875 7.29741 13.1957 6.86429 13.1957H6.59304V14.38H5.62866V10.8107H6.86429C7.31491 10.8107 7.65366 10.9088 7.87991 11.1063C8.10616 11.3038 8.21929 11.5825 8.21929 11.9457ZM6.59304 12.41H6.76866C6.91366 12.41 7.02866 12.3694 7.11429 12.2882C7.19991 12.2069 7.24241 12.0944 7.24241 11.9513C7.24241 11.71 7.10929 11.59 6.84179 11.59H6.59304V12.41Z" fill="#111111"/>
        <path d="M11.8172 12.5194C11.8172 13.115 11.6535 13.5744 11.3254 13.8963C10.9972 14.2182 10.5366 14.38 9.94223 14.38H8.78723V10.8107H10.0229C10.596 10.8107 11.0385 10.9569 11.3497 11.25C11.661 11.5432 11.8172 11.9663 11.8172 12.5194ZM10.8166 12.5538C10.8166 12.2269 10.7516 11.9844 10.6222 11.8263C10.4929 11.6682 10.2966 11.5894 10.0329 11.5894H9.75223V13.5888H9.96723C10.2604 13.5888 10.4747 13.5038 10.6116 13.3338C10.7485 13.1638 10.8166 12.9038 10.8166 12.5538Z" fill="#111111"/>
        <path d="M13.4073 14.38H12.4573V10.8107H14.5667V11.5844H13.4073V12.2657H14.4767V13.0394H13.4073V14.38Z" fill="#111111"/>
        <path d="M19.3799 8.12988H0.629883V16.8799H19.3799V8.12988Z" stroke="#111111" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);
