import store from 'src/store';

export const getUserByCode = (code: string) => {
  const list = Object.values(store.getState().adminPanel.users.summary);
  return list.find((v) => v.code === code);
};

export const getUserById = (id: number | string | null | undefined) => {
  if (id === null || id === undefined) {
    return
  }
  const value = store.getState().adminPanel.users.summary[id];
  if (value) {
    return value;
  }
  return;
};
