import styled from "styled-components";

export const Container = styled.div`
`

export const ModalContainer = styled.div`
    padding: 20px 20px 10px 20px;
`

export const TagContainer = styled.div`
    padding: 2px 8px;
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,0.1);
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #333333;
`

export const CheckboxContainer = styled.div`
    margin: 0 0 10px 0;
`