import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { Col, Row } from 'antd';

import Button from 'src/Framework/Controls/Button';
import Modal from 'src/Framework/Controls/Modal';
import Table from 'src/Framework/Controls/Table';
import RemoveButton from 'src/Framework/Controls/Table/RemoveButton';
import TableHeader from 'src/Framework/Controls/Table/Header';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import { IColumn } from 'src/Framework/Controls/Table/types';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { useValidator } from 'src/Framework/util/formValidator';
import { debouncer } from 'src/Framework/util/helps';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import * as actions from '../store/Actions';
import { IPublicForm, IPublicFormsResponse } from '../store/types';
import { initialInfinityProps, infinityContainerProps } from './utils';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import { Container, Header, Body, Footer, AddedTableContainer } from './styled';

interface IProps {
  onApplyClose: () => any;
}

interface IShow {}

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

const modalProps = {
  destroyOnClose: true
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { onApplyClose },
  ref
) => {
  const debounce = useRef(debouncer(500));
  const [data, setData] = useState<IPublicFormsResponse>({
    count: 0,
    summary: [],
    startIndex: 0,
    total: 0
  });
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [visible, setVisible] = useState(false);
  const [selectedForms, setSelectedForms] = useState<IPublicForm[]>([]);
  useModalFocus({ active: visible });
  const show = async ({}: IShow) => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setSearch('');
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  const apply = async () => {
    const res = await actions.importPublicForms(selectedForms.map((v) => v.id));
    if (res) {
      await onApplyClose();
      close();
    }
  };

  const validation = useValidator([], []);

  const getData = ({
    searchString,
    pageNumber
  }: {
    searchString: string;
    pageNumber: number;
  }) => {
    debounce.current(async () => {
      setLoading(true);
      const res = await actions.getPublicForms({
        pageNumber,
        searchString
      });
      if (res) {
        setData((data) => ({
          ...res,
          summary:
            pageNumber === 0 ? res.summary : [...data.summary, ...res.summary]
        }));
      }
      setLoading(false);
    });
  };

  const onSearch = (searchString: string) => {
    if (loading) return;
    setSearch(searchString);
    getData({ pageNumber: 0, searchString });
  };

  const addForm = useCallback((value: IPublicForm) => {
    setSelectedForms((data) => {
      const arr = [...data];
      const i = arr.findIndex((v) => v.id === value.id);
      if (i === -1) {
        arr.push(value);
      }
      return arr;
    });
  }, []);

  const removeForm = useCallback((value: IPublicForm) => {
    setSelectedForms((data) => {
      const arr = [...data];
      const i = arr.findIndex((v) => v.id === value.id);
      if (i !== -1) {
        arr.splice(i, 1);
      }
      return arr;
    });
  }, []);

  const dataSource = useMemo(() => {
    return [{}] as IPublicForm[];
  }, [data]);

  const renderItemData = useCallback((value: typeof dataSource[number]) => {
    return {
      ...value,
      actions: (
        <Row align="middle" gutter={8}>
          <Col></Col>
          <Col>
            <ButtonIcon name={ICONS_LIST.add} onClick={() => addForm(value)} />
          </Col>
        </Row>
      )
    };
  }, []);

  const dataSource2 = useMemo(() => {
    return selectedForms;
  }, [selectedForms]);

  const renderItemData2 = useCallback((value: typeof dataSource2[number]) => {
    return {
      ...value,
      remove: (
        <RemoveButton
          removeAction={async () => {
            removeForm(value);
          }}
        />
      )
    };
  }, []);

  const hasMore = data.summary.length < data.total;

  const title = 'Import Public Forms';
  const defaultWidth = 1024;

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        title
      }}
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      headerBorder={true}
      afterClose={setToDefault}
      modalProps={modalProps}
    >
      <Container>
        <Header>
          <SelectContainer
            label="Search public forms"
            type="input"
            value={search}
            onChange={onSearch}
            bottomMargin={false}
            searchIcon={true}
            hideLabel={true}
          />
        </Header>
        <Body>
          <Row style={{ flex: 1 }}>
            <Col flex={1} style={{ display: 'flex' }}>
              <Table
                dataSource={dataSource}
                renderItemData={renderItemData}
                absoluteContainer={true}
                columns={columns}
                rowHover={true}
                infinity={true}
                infinityProps={{
                  ...initialInfinityProps,
                  hasMore: hasMore,
                  loadMore: (page: number) => {
                    if (hasMore) {
                      getData({
                        pageNumber: page,
                        searchString: search
                      });
                    }
                  }
                }}
                infinityContainerProps={infinityContainerProps}
              />
            </Col>
            <Col
              flex={'300px'}
              style={{
                display: 'flex',
                flexDirection: 'column',
                borderLeft: `1px solid ${globalStyleVariables.borderColor}`
              }}
            >
              <TableHeader
                columns={[
                  {
                    title: `${selectedForms.length} Added`,
                    flex: '',
                    key: ''
                  }
                ]}
              />
              <AddedTableContainer>
                <Table
                  dataSource={dataSource2}
                  renderItemData={renderItemData2}
                  absoluteContainer={true}
                  columns={columns2}
                  rowHover={true}
                />
              </AddedTableContainer>
            </Col>
          </Row>
        </Body>
        <Footer>
          <Row align="middle" gutter={16} justify="end">
            <Col>
              <Button
                id="remove-all"
                colorStyles={{ greyBorder: true }}
                onClick={() => {
                  setSelectedForms([]);
                }}
              >
                Remove all
              </Button>
            </Col>
            <Col>
              <Button
                id="apply"
                disabled={!validation}
                colorStyles={{ blueBorder: true }}
                onClick={apply}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Footer>
      </Container>
    </Modal>
  );
};

export default React.forwardRef(Component);

const columns2: IColumn[] = [
  {
    title: 'Name',
    key: 'name',
    flex: 'auto',
    ellipsis: true
  },
  {
    title: 'University',
    key: 'university',
    flex: '140px',
    ellipsis: true
  },
  {
    title: '',
    key: 'remove',
    flex: '60px',
    centered: true
  }
];

const columns: IColumn[] = [
  {
    title: 'Name',
    key: 'name',
    flex: 'auto',
    ellipsis: true
  },
  {
    title: 'Tags',
    key: 'tags',
    flex: '200px'
  },
  {
    title: 'Created by',
    key: 'createdBy',
    flex: '120px',
    ellipsis: true
  },
  {
    title: 'Actions',
    key: 'actions',
    flex: '160px'
  }
];
