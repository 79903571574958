import React, { FC, useRef, useState } from 'react';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import { providerDisplayName } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { useAppSelector } from 'src/store';

import { IProviderPushDTO } from 'src/App/Admin/Pages/Providers/store/types';
import Filters from './Filters';
import { ICommon } from './index';

export interface ISingle {
  multiple?: false;
  enableFilters?: boolean;
  value: any;
}

interface IProps extends ISingle, ICommon {
  currentValue: any;
  options: {
    title: any;
    value: any;
    provider: IProviderPushDTO;
    optionSearch: string;
  }[];
  allOptions: any;
  onChangeValue: any;
}

export interface IShowContainer {
  gender: boolean;
  groups: boolean;
}

export interface IFilters {
  groups: number[];
  genders: string[];
}

const Component: FC<IProps> = (props: IProps) => {
  const providers = useAppSelector((state) => state.user.providers.summary);
  const [showContainers, setShowContainers] = useState<IShowContainer>({
    gender: false,
    groups: false
  });

  const [isOpened, setOpened] = useState<boolean>(false);

  const [filters, setFilters] = useState<IFilters>({
    groups: [],
    genders: []
  });
  const {
    onChange,
    value,
    selectProps,
    disabled,
    disableSearch,
    blurChange,
    currentValue,
    options,
    allOptions,
    onChangeValue
  } = props;
  const filtersRef = useRef(null);
  const inputRef = useRef(null);
  const filteredOptions = options.filter((v) => {
    const provider = v.provider;
    if (filters.groups.length !== 0) {
      const i = provider.groupId
        ? filters.groups.indexOf(provider.groupId)
        : -1;
      if (i === -1) {
        return false;
      }
    }

    if (filters.genders.length !== 0) {
      const i = filters.genders.indexOf(provider.gender);
      if (i === -1) {
        return false;
      }
    }
    return true;
  });

  const returnToInput = () => {
    if (inputRef.current) {
      //@ts-ignore
      inputRef.current.focus();
    }
  };

  const onKeyDownAccessibility = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (
      e.shiftKey &&
      e.key === 'Tab' &&
      //@ts-ignore
      e.target?.id &&
      //@ts-ignore
      e.target?.id === 'provider-select-dropdown-container'
    ) {
      e.preventDefault();
      returnToInput();
    }
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
    }
  };
  const onKeyDownAccessibilityInput = (e: React.KeyboardEvent) => {
    // e.stopPropagation();
    if (!e.shiftKey && e.key === 'Tab' && isOpened) {
      e.preventDefault();
      if (filtersRef.current) {
        (filtersRef.current as unknown as HTMLElement).focus();
      }
    }
  };
  return (
    <SelectContainer
      disableEllipsis={false}
      isOpened={isOpened}
      rowSelectOptions={true}
      homeRef={inputRef}
      type="select"
      label="Provider"
      value={
        blurChange
          ? currentValue
            ? `${currentValue}`
            : undefined
          : value
          ? `${value}`
          : undefined
      }
      options={filteredOptions}
      allOptions={allOptions}
      star={false}
      onChange={(value: any) => {
        returnToInput();
        onChangeValue(value);
      }}
      {...selectProps}
      inputProps={{
        id: 'single-provider-selector',
        onKeyDown: (e: React.KeyboardEvent) => onKeyDownAccessibilityInput(e),
        'aria-label': `${
          props.selectProps?.label ? props.selectProps?.label : 'Provider'
        }. ${
          blurChange
            ? currentValue
              ? providerDisplayName(currentValue)
              : 'Not selected'
            : value
            ? providerDisplayName(value)
            : 'Not selected'
        }`,
        ref: inputRef,
        onDropdownVisibleChange: (visible: boolean) => {
          if (!visible) {
            if (blurChange) {
              onChange(currentValue);
            }
            setOpened(false);
            setShowContainers({ gender: false, groups: false });
            setFilters({ groups: [], genders: [] });
          }
          if (visible) {
            setOpened(true);
          }
        },
        allowClear: true,
        dropdownRender: (originNode: any) => (
          <div
            tabIndex={0}
            ref={filtersRef}
            onKeyDown={(e) => onKeyDownAccessibility(e)}
            // id={`${
            //   selectProps?.inputProps?.id || selectProps?.id
            // }-select-dropdown-container`}
            id="provider-select-dropdown-container"
          >
            <div style={{ padding: '0 10px' }}>
              <Filters
                providers={providers}
                filters={filters}
                setFilters={setFilters}
                showContainers={showContainers}
                setShowContainers={setShowContainers}
              />
            </div>
            <div>{originNode}</div>
          </div>
        ),
        ...selectProps?.inputProps,
        disabled,
        showSearch: !disableSearch,
        optionFilterProp: 'optionSearch',
        filterOption: (input: any, option: any) =>
          options
            .find((v: any) => v.value == option.value)
            ?.optionSearch.toLowerCase()
            .includes(input.toLowerCase())
      }}
    />
  );
};

export default React.memo(Component);
