import React, { FC, useEffect } from 'react';

import MultipleSelector from '../MultipleSelector';
import { IMultiple, IOption } from './types';

interface IProps extends IMultiple {
  options: IOption[];
  allClinicsOptions: IOption[];
}

const Component: FC<IProps> = ({
  value,
  onChange,
  selectProps,
  options,
  disableAutoSetter,
  allClinicsOptions
}: IProps) => {
  const values = value.map((v) => `${v}`);
  useEffect(() => {
    const values = value.map((v) => `${v}`);
    const optionsValues = options.map((v) => `${v.value}`);
    const existedValues = values.filter((v) => optionsValues.includes(v));
    if (!disableAutoSetter && existedValues.length !== values.length) {
      onChange(existedValues.map((v) => +v));
    }
  }, [value]);
  return (
    <MultipleSelector
      id="clinics-selector"
      label="Clinics"
      {...selectProps}
      options={options}
      allOptions={allClinicsOptions}
      value={values}
      onChange={(value) => {
        onChange(value.map((v) => +v));
      }}
    />
  );
};

export default Component;
