import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  overflow: hidden;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: black;

  b {
    color: ${globalStyleVariables.newBrandingPrimary200};
    cursor: pointer;
  }
`;
