import styled from 'styled-components';

export const MainContainer = styled.div``;

export const Container = styled.div`
  .sketch-picker {
    box-shadow: unset !important;
    width: unset !important;
  }
`;

export const Color = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  position: absolute;
  left: -26px;
  top: -4px;
`;

export const PreferedColorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 4px 10px 0px 10px;
`;

export const PreferedItem = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px solid transparent;
  margin: 0 4px 4px 0;
  cursor: pointer;

  &.enabled {
    border: 2px solid rgba(0, 0, 0, 0.2);
  }
`;
