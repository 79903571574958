import React, { FC } from 'react';
import cn from 'classnames';

import { AutoComplete } from 'antd';
import { AutoCompleteProps } from 'antd/es/auto-complete';

import { ICommon } from '../index';

export interface IAutoComplete extends ICommon {
  type: 'auto-complete';
  inputProps?: Partial<AutoCompleteProps>;
  disablePlaceholder?: boolean;
  className?: string;
  onChange?: (value: string, option: any) => any;
  options?: any[];
  value?: string | undefined;
  onSearch?: (search: string) => any;
}

const Component: FC<IAutoComplete> = (props: IAutoComplete) => {
  const {
    label,
    inputProps,
    value,
    className,
    bottomMargin,
    disablePlaceholder,
    onChange,
    options,
    onSearch,
    hideLabel,
    connectToContainerOnScroll,
    containerRef,
    id
  } = props;
  const areaLabel = typeof label === 'string' ? label : '';
  return (
    <AutoComplete
      id={id}
      aria-required={props.star}
      aria-label={areaLabel}
      {...inputProps}
      getPopupContainer={
        inputProps?.getPopupContainer
          ? inputProps?.getPopupContainer
          : connectToContainerOnScroll && containerRef
          ? () => containerRef.current
          : undefined
      }
      value={value}
      className={cn({
        select: true,
        margin: bottomMargin,
        [`${className}`]: className
      })}
      placeholder={
        disablePlaceholder
          ? ''
          : inputProps?.hasOwnProperty('placeholder')
          ? inputProps?.placeholder
          : hideLabel
          ? areaLabel
          : ''
      }
      onSelect={(e: any, option: any) => {
        if (onChange) {
          onChange(e, option);
        }
      }}
      options={options}
      onSearch={(value) => {
        if (onSearch) {
          onSearch(value);
        }
      }}
      dropdownStyle={{
        zIndex: 10000
      }}
    />
  );
};

export default Component;
