import styled from "styled-components";
import { TitleSection } from 'src/CommonStyles/fonts';
import {
  input_placeholder_text_color, border_color
} from "src/CommonStyles/styles";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.3rem 0 2rem;
  height: 64px;
  border-bottom: 4px solid ${border_color};
  border-right: 1px solid ${border_color};
`;

export const Title = styled(TitleSection)`
  text-transform: uppercase;
  color: ${input_placeholder_text_color};
  margin: 0;
`;

export const Actions = styled.div`
  display:flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin: 0 15px 0 0;
`
