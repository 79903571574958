export const SET_ACCORDION_EXPAND = "SET_ACCORDION_EXPAND";
export const SET_NAVIGATION_DATA = "SET_NAVIGATION_DATA";
export const SET_NOTES_IN_PROGRESS = "SET_NOTES_IN_PROGRESS";

export const getNavigationMenuItemInformation = (section: any, menuItem: any, information: any) => {
	return {
		[section]: {
			"items": {
				[menuItem]: {
					information: information
				}
			}
		}
	};
}

export const setNavigationData = (subjectId: any, data: any) => {
	return {
		type: SET_NAVIGATION_DATA,
		subjectId: subjectId,
		payload: data
	}
}

export const setNotesInProgress = (subjectId: any, data: any) => {
	return {
		type: SET_NOTES_IN_PROGRESS,
		subjectId: subjectId,
		payload: data
	}
}
export const setAccordionExpandCollapse = (subjectId: any, name: any, expanded: any) => {
	return {
		type: SET_ACCORDION_EXPAND,
		subjectId: subjectId,
		payload: {
			name: name,
			expanded: expanded
		}
	}
};