export interface IStateReducer {
  summary: IWaitingList[];
  loading: boolean;
}

export interface IAction {
  type: string;
  payload: any;
}

export enum TypeListEnum {
  roster = 'roster',
  triage = 'triage'
}

export interface IWaitingListProps {
  code: string;
  description: string;
  id: number;
  isActive: boolean;
  isDeleteOnMove: boolean;
  isGroup: boolean;
  isMedicatOne: boolean;
  typeList: TypeListEnum;
  isTimeCalculate?: boolean;
  showInAppointment?: boolean;
}

export interface IWaitingList {
  clinicCode: string;
  clinicId: number;
  id: number;
  maxPages: number;
  showInAppointment: boolean;
  waitingList: IWaitingListProps;
}
