import React, { FC, useRef } from 'react';
import { Row, Col } from 'antd';

import Icon from 'src/Framework/Controls/Icon';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { ITagValueSimpleDto } from '../../store/types';

import Modal, { IModalHandles, IData } from './Modal';

import { Container } from './styled';

import TagContainer from '../TagContainer';

interface IProps {
  values: Record<string, ITagValueSimpleDto>;
  tags: number[];
  tagDefinitionId: number;
  onChange: (data: IData) => Promise<any>;
  tagId?: string;
  disableAddButton?: boolean;
  whiteBorders?: boolean;
}

const Component: FC<IProps> = ({
  values,
  tags,
  tagDefinitionId,
  onChange,
  disableAddButton,
  tagId = 'tags-button',
  whiteBorders
}: IProps) => {
  const ModalRef = useRef<IModalHandles>(null);
  return (
    <Container>
      <Modal
        ref={ModalRef}
        tagDefinitionId={tagDefinitionId}
        onChange={async (data) => {
          return await onChange(data);
        }}
      />
      <Row gutter={[8, 8]}>
        {tags.map((id) => {
          const item = values[id];
          if (!item) return null;
          return (
            <Col key={id}>
              <TagContainer whiteBorders={whiteBorders}>
                {item?.value}
              </TagContainer>
            </Col>
          );
        })}
        {!disableAddButton && (
          <Col>
            <Icon
              id={tagId}
              name="add"
              color="white"
              size={20}
              roundIcon={true}
              containerStyles={{
                backgroundColor: globalStyleVariables.newBrandingPrimary200
              }}
              onClick={() => {
                ModalRef.current?.show({ tags });
              }}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Component;
