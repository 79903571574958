import React, { FC } from 'react';

import { Container, SubTitle, Description } from './styled';

interface IProps {
  message: string;
}

const Component: FC<IProps> = ({ message }: IProps) => {
  return (
    <Container>
      <img
        src={require('src/Framework/Common/Svg/new-logo-big.svg').default}
        alt="logo"
        width="221"
      />
      <SubTitle>Access issue</SubTitle>
      <Description>{message}</Description>
    </Container>
  );
};

export default React.memo(Component);
