import React from 'react';
import { notification } from 'antd';

export const Alert = (
  type: string,
  msg: string,
  desc: string,
  duration?: number
) => {
  notification[type]({
    message: msg,
    description: <span style={{ whiteSpace: 'pre-wrap' }}>{desc}</span>,
    duration: duration === undefined ? 3 : duration
  });
};
