import { BucketsList } from 'src/Framework/ApiSubscription';
import { RequestHelper } from 'src/Framework/ApiSubscription/RequestHelper';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { ITraineeContact } from '../types';

import { setSummary, onCreateUpdateItem } from './Setters';

export const SubscriptionData = (providerId: string | number) => ({
  bucket: BucketsList.traineeContact,
  filter: `${BucketsList.traineeContact}.TraineeId == ${providerId}`
});

export const { post, get, patch, deleteRequest, unsubscribe } = new RequestHelper<
  ITraineeContact,
  [providerId: string | number],
  [providerId: string | number]
>({
  link: ApiUrl.TraineeContacts,
  onCreateUpdateItem,
  subscription: {
    subscriptionData: SubscriptionData,
    setSummary: setSummary
  }
});
