import React, { FC, useCallback, useState } from 'react';

import {
  Container,
  LoginContainer,
  Header,
  Body,
  TitleContainer,
  Title,
  SubTitle,
  AbsoluteLogo1,
  AbsoluteLogo2,
  AbsoluteIcon,
  Icon
} from './styled';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import { Col, Row } from 'antd';
import Button from 'src/Framework/Controls/Button';
import { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import * as actions from './store/Actions';

import history from '../../../history';
import { ROUTES } from 'src/ManagementSystem/routes';

interface IProps {}

const Component: FC<IProps> = ({}: IProps) => {
  const [data, setData] = useState({
    username: '',
    password: ''
  });
  const onClickGo = useCallback(async () => {
    const res = await actions.login(data);
    if (res) {
      history.push(ROUTES.ManagementSystem);
    }
  }, [data]);
  const onChangeData = useCallback((obj: Partial<typeof data>) => {
    setData((data) => ({ ...data, ...obj }));
  }, []);
  return (
    <Container>
      <AbsoluteLogo1>
        <img alt="logo1" src={require('./logo.svg').default} />
      </AbsoluteLogo1>
      <AbsoluteLogo2>
        <img alt="logo2" src={require('./logo.svg').default} />
      </AbsoluteLogo2>
      <AbsoluteIcon>
        <Icon icon={ICONS_LIST.formDraft} />
      </AbsoluteIcon>
      <TitleContainer>
        <Title>medicat</Title>
        <SubTitle>management system</SubTitle>
      </TitleContainer>
      <LoginContainer>
        <Header>Log In</Header>
        <Body>
          <SelectContainer
            star={false}
            type="input"
            label="Name"
            value={data.username}
            onChange={(username) => onChangeData({ username })}
          />
          <SelectContainer
            star={false}
            type="input"
            label="Password"
            value={data.password}
            onChange={(password) => onChangeData({ password })}
            inputProps={{
              type: 'password'
            }}
          />
          <Row align="middle" justify="center">
            <Col>
              <Button
                id=""
                style={{ width: 140 }}
                colorStyles={{
                  secondColorBackground: true
                }}
                onClick={onClickGo}
              >
                GO
              </Button>
            </Col>
          </Row>
        </Body>
      </LoginContainer>
    </Container>
  );
};

export default React.memo(Component);
