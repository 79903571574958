import React, { FC, useEffect, useState } from 'react';

import * as Actions from './Actions';

import HeaderV1 from 'src/Activities/Counseling/NewNotes/Preview/DataGenerator/Previews/Header/V1';
import { IHeaderV1Preview } from 'src/Activities/Counseling/NewNotes/Preview/DataGenerator/Previews/Header/V1/types';

interface IProps {
  formId: number;
  dataIsReady?: () => any;
  defaultData?: IHeaderV1Preview;
}

const Component: FC<IProps> = ({
  formId,
  dataIsReady,
  defaultData
}: IProps) => {
  const [data, setData] = useState<IHeaderV1Preview | null>(
    defaultData ? defaultData : null
  );
  useEffect(() => {
    const init = async () => {
      const response = await Actions.getHeader({ formId });
      if (response) {
        setData(response);
      }
      if (dataIsReady) {
        dataIsReady();
      }
    };
    if (!defaultData) {
      init();
    }
  }, []);
  return (
    <>
      {data ? (
        <div style={{ marginBottom: 20 }}>
          <HeaderV1 value={data} />
        </div>
      ) : null}
    </>
  );
};

export default React.memo(Component);
