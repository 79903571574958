import { store } from 'src';
import { actionsTypes } from '..';
import { ITraineeContact } from '../types';
import { ISort } from 'src/Framework/Controls/Table/types';

export const setSummary = (
  payload: Record<string, ITraineeContact>,
  providerId: string | number
) => {
  store.dispatch({
    type: actionsTypes.SET,
    payload,
    providerId
  });
};

export const onCreateUpdateItem = (
  payload: any,
  providerId: string | number
) => {
  const data = {
    ...(store.getState().trainee.contacts.providers[providerId]?.summary || {})
  };
  if (data[payload.id]) {
    data[payload.id] = { ...data[payload.id], ...payload };
  } else {
    data[payload.id] = payload;
  }
  setSummary(data, providerId);
};

export const onRemoveItem = (id: string | number) => {
  const contacts = store.getState().trainee.contacts.providers;
  const findProviderKey = Object.keys(contacts).find(
    (v) => contacts[v]?.summary[id]
  );
  if (findProviderKey) {
    const data = { ...(contacts[findProviderKey]?.summary || {}) };
    delete data[id];
    setSummary(data, findProviderKey);
  }
};

export const changeSort = ({
  providerId,
  payload
}: {
  providerId: string;
  payload: ISort;
}) => {
  store.dispatch({
    type: actionsTypes.SET_SORT,
    payload,
    providerId
  });
};
