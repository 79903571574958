import _ from 'lodash';
import { IStateReducer, IAction } from './types';


const prefix = 'M1_FORM_RECORDS_';

export const actionsTypes = {
    SET_PATIENT_SUMMARY: `${prefix}SET_PATIENT_SUMMARY`,
    SET_PATIENT_LOADING: `${prefix}SET_PATIENT_LOADING`,
    SET_PROVIDER_SUMMARY: `${prefix}SET_PROVIDER_SUMMARY`,
    SET_PROVIDER_LOADING: `${prefix}SET_PROVIDER_LOADING`,
    SET_NOTE_SUMMARY: `${prefix}SET_NOTE_SUMMARY`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

const initialState: IStateReducer = {
    patients: {},
    providers: {}
};

export const defaultData: IStateReducer['patients'][number] = {
    summary: {},
    summaryInNotes: {},
    loading: false,
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(initialState),
    action: IAction
): IStateReducer => {
    const patientData = () => state.patients[action.patientId] || _.cloneDeep(defaultData);
    const providerData = () => state.providers[action.patientId] || _.cloneDeep(defaultData);
    const actions: IActions = {
        [actionsTypes.SET_NOTE_SUMMARY]: () => {
            const data = patientData();
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...data,
                        summaryInNotes: {
                            ...data.summaryInNotes,
                            ...action.payload
                        }
                    }
                }
            };
        },
        [actionsTypes.SET_PROVIDER_LOADING]: () => {
            const data = providerData();
            return {
                ...state,
                providers: {
                    ...state.providers,
                    [action.providerId]: {
                        ...data,
                        loading: action.payload
                    }
                }
            };
        },
        [actionsTypes.SET_PROVIDER_SUMMARY]: () => {
            const data = providerData();
            return {
                ...state,
                providers: {
                    ...state.providers,
                    [action.providerId]: {
                        ...data,
                        summary: action.payload
                    }
                }
            };
        },
        [actionsTypes.SET_PATIENT_LOADING]: () => {
            const data = patientData();
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...data,
                        loading: action.payload
                    }
                }
            };
        },
        [actionsTypes.SET_PATIENT_SUMMARY]: () => {
            const data = patientData();
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...data,
                        summary: action.payload
                    }
                }
            };
        }
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
