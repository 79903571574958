import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

export const getForm = async (seedId: string | number) => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.GET_TEMPLATE_FORM_RECORD_DETAIL_NEW,
            {
                withCredentials: true, params: {
                    seedId,
                    v: 2
                }
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data: any = response.data.result;
                if (data) {
                    return data;
                }
            }
        }
        return;
    } catch (e) {
        console.log({ e });
        return;
    } finally {
    }
};