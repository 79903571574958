import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 22px 50px 26px 24px;
  border-top: 4px solid ${globalStyleVariables.newBrandingPrimary200};

  .back-button {
    height: 30px;
    width: 30px;
    mask-size: contain;
    mask-position: center center;
    mask-repeat: no-repeat no-repeat;
    background: ${globalStyleVariables.newBrandingPrimary200};
    margin: 0 10px 0 0;
    cursor: pointer;

    &:active {
      transform: scale(0.9);
    }
  }

  &.headerBorder {
    border-bottom: 1px solid ${globalStyleVariables.borderColor};
  }
`;

export const Title = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: #000000;

  .buttons-container {
    display: flex;
    align-items: center;
  }
`;
