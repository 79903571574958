import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import {
  MarketplaceBBExtendedDto,
  MarketplaceBBSimpleDto,
  MarketplaceExtendedDto,
  MarketplaceSimpleDto
} from '../types';

export const getMarketplaceList = async (): Promise<
  MarketplaceSimpleDto[] | undefined
> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.ShareFormTemplate}/marketplace-list`,
      {
        withCredentials: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data = response.data.result as MarketplaceSimpleDto[];
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

const parseScheme = (data: MarketplaceExtendedDto) => {
  const schema = data.displaySchemeJson;
  if (schema) {
    try {
      data.displaySchemeJson = JSON.parse(schema);
    } catch (e) {
      console.log('schema not parsed');
    }
  }
  return data;
};

export const getFormTemplateDetails = async ({
  formTemplateId,
  isInstalled
}: {
  formTemplateId: number;
  isInstalled: boolean;
}): Promise<MarketplaceExtendedDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.ShareFormTemplate}/form-template-details`,
      {
        withCredentials: true,
        params: {
          formTemplateId,
          isInstalled
        }
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data = response.data.result as MarketplaceExtendedDto;
        return parseScheme(data);
      }
    }
    return;
  } catch (e) {
    return;
  }
};

export const postFormUpdateVersion = async (
  sharedFormId: number,
  hideNotification?: boolean
): Promise<MarketplaceSimpleDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.ShareFormTemplate}/update-version`,
      { sharedFormId },
      {
        withCredentials: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data = response.data.result;
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  }
};

export const postFormImport = async (
  publicFormId: number
): Promise<MarketplaceSimpleDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.ShareFormTemplate}/form/import`,
      { publicFormIds: [publicFormId] },
      {
        withCredentials: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data = response.data.result;
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  }
};

export const getBBMarketplaceList = async (): Promise<
  MarketplaceBBSimpleDto[] | undefined
> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.ShareFormBuildingBlockTemplate}/marketplace-list`,
      {
        withCredentials: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data = response.data.result as MarketplaceBBSimpleDto[];
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

const parseSchemeBB = (data: MarketplaceBBExtendedDto) => {
  const schema = data.displaySchemeJson;
  if (schema) {
    try {
      data.displaySchemeJson = JSON.parse(schema);
    } catch (e) {
      console.log('schema not parsed');
    }
  }
  return data;
};

export const getBBDetails = async ({
  buildingBlockId,
  isInstalled
}: {
  buildingBlockId: number;
  isInstalled: boolean;
}): Promise<MarketplaceBBExtendedDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.ShareFormBuildingBlockTemplate}/building-block-details`,
      {
        withCredentials: true,
        params: {
          buildingBlockId,
          isInstalled
        }
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data = response.data.result as MarketplaceBBExtendedDto;
        return parseSchemeBB(data);
      }
    }
    return;
  } catch (e) {
    return;
  }
};

export const postBBUpdateVersion = async (
  sharedBuildingBlockId: number,
  hideNotification?: boolean
): Promise<MarketplaceBBSimpleDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.ShareFormBuildingBlockTemplate}/update-version`,
      { sharedBuildingBlockId },
      {
        withCredentials: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data = response.data.result;
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  }
};

export const postBBImport = async (
  id: number
): Promise<MarketplaceBBSimpleDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.ShareFormBuildingBlockTemplate}/buildingBlocks/import`,
      { publicBBIds: [id] },
      {
        withCredentials: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data = response.data.result;
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  }
};
