import { IHeaderV1Preview } from "src/Activities/Counseling/NewNotes/Preview/DataGenerator/Previews/Header/V1/types";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { APIHandler } from "src/Framework/Communication/ServerProxy";


export const getHeader = async ({ formId }: {
    formId: number
}): Promise<IHeaderV1Preview | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.FormRecordsHeaders, {
            withCredentials: true,
            params: {
                formType: 'm1',
                formId
            }
        })
        if (response.data.success) {
            return response.data.result
        }
        return
    } catch (e) {
        return
    }
}