import React from 'react';
import { useEffect, useState } from 'react';

import Spinner from 'src/Framework/Controls/AbsoluteLoader/Spinner';
import { useAppSelector } from 'src/store';
import { IGenericTypes } from '../types';

import { LoadingContainer } from '../../styled';

import * as actions from './index';

type Key = keyof IGenericTypes;

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

type Element<T extends Key> = ArrayElement<IGenericTypes[T]>;

/**
 * Hook used for requesting generic type if we don't have it in store
 *
 * Deprecated
 */
export const useGenericType = <T extends Key>(type: T) => {
  const [loading, setLoading] = useState(false);
  const data = useAppSelector(
    (state) => state.genericTypes.summary[type] || []
  );
  useEffect(() => {
    const init = async () => {
      if (data.length === 0) {
        setLoading(true);
        await actions.get([type]);
        setLoading(false);
      }
    };
    init();
  }, []);
  const genericType = data as IGenericTypes[T];
  return {
    /** Loading status */
    loading,
    /**
     * List of all generic types, includes inActive
     */
    allGenericTypes: genericType,
    /**
     * List of active generic types
     */
    genericTypes: (genericType as any).filter((v: any) => {
      if (v.hasOwnProperty('isActive')) {
        return v.isActive;
      }
      return true;
    }) as IGenericTypes[T],
    /**
     *
     * @param value used for finding element
     * @param field by what field need to find element
     * @default id
     * @return Element
     */
    finder: (
      value: string | number | null | undefined,
      field?: keyof Element<T>
    ): Element<T> | null => {
      const fieldName = field || 'id';
      const i = genericType.findIndex((v: any) => v[fieldName] === value);
      if (i !== -1) {
        return genericType[i] as Element<T>;
      }
      return null;
    },
    /**
     * Method will give you loader component when data still in pending and value that our need after
     * @param value
     * @param findValue
     * @param byField - default "id"
     * @returns
     */
    loadingFinder: (
      value: string | number | null | undefined,
      findValue: keyof Element<T>,
      byField?: keyof Element<T>
    ): string | null | React.ReactNode => {
      if (loading) {
        return (
          <LoadingContainer>
            <Spinner size={14} />
          </LoadingContainer>
        );
      }
      const fieldName = byField || 'id';
      const i = genericType.findIndex((v: any) => v[fieldName] === value);
      if (i !== -1) {
        return (genericType[i] as any)[findValue] || '';
      }
      return null;
    }
  };
};
