import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';

import Spinner from 'src/Framework/Controls/AbsoluteLoader/Spinner';
import { useAppSelector } from 'src/store';

import { LoadingContainer } from '../../styled';

import * as actions from '../Actions';
import * as Setters from '../Actions/Setters';

import { IGroupTypes } from '../types';
import ApiSubscription from 'src/Framework/ApiSubscription';

type Key = keyof IGroupTypes;

const globalLoading: {
  [key: string]: boolean;
} = {};

export const useGroupType = <T extends Key>(
  type: T,
  disableInitLoad = false
) => {
  const [loading, setLoading] = useState(false);
  const [requestHelper] = useState(() => {
    return new actions.GenericTypeRequestHelper({
      genericTypeId: type
    });
  });
  const data = useAppSelector(
    (state) => state.adminPanel.groupTypes.summary[type] || {}
  ) as Record<string, IGroupTypes[T]>;
  useEffect(() => {
    const init = async () => {
      const alreadySubscribed =
        ApiSubscription.instance.subscribedPaths[requestHelper.pathKey];
      if (alreadySubscribed || globalLoading[type]) {
        return;
      }
      setLoading(true);
      globalLoading[type] = true;
      const res = await requestHelper.RequestHelper.get();
      if (res?.success) {
        Setters.setSummary(res.data, type);
      }
      globalLoading[type] = false;
      setLoading(false);
    };
    if (!disableInitLoad) {
      init();
    }
  }, []);
  const genericType = useMemo(() => {
    return Object.values(data);
  }, [data]);

  const filteredGenericType = useMemo(() => {
    return genericType.filter((v: any) => {
      if (v.hasOwnProperty('isActive')) {
        return v.isActive;
      }
      return true;
    });
  }, [genericType]);
  return {
    /** Loading status */
    loading,
    /**
     * List of all generic types, includes inActive
     */
    allGenericTypes: genericType,
    /**
     * List of active generic types
     */
    genericTypes: filteredGenericType,
    /**
     *
     * @param value used for finding element
     * @param field by what field need to find element
     * @default id
     * @return Element
     */
    finder: (value: any, field?: keyof (typeof genericType)[number]) => {
      const fieldName = field || 'id';
      const i = genericType.findIndex((v) => v[fieldName] === value);
      if (i !== -1) {
        return genericType[i];
      }
      return null;
    },
    /**
     * Method will give you loader component when data still in pending and value that our need after
     * @param value
     * @param findValue
     * @param byField - default "id"
     * @returns
     */
    loadingFinder: (
      value: any,
      findValue: keyof (typeof genericType)[number],
      byField?: keyof (typeof genericType)[number]
    ): string | null | React.ReactNode => {
      if (loading) {
        return (
          <LoadingContainer>
            <Spinner size={14} />
          </LoadingContainer>
        );
      }
      const fieldName = byField || 'id';
      const i = genericType.findIndex((v) => v[fieldName] === value);
      if (i !== -1) {
        return genericType[i][findValue] || '';
      }
      return null;
    },
    // RequestHelper actions
    actions: requestHelper.RequestHelper,
    Setters
  };
};
