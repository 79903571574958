import globalStyleVariables from "src/Framework/Styles/variables.module.scss";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 4px solid ${globalStyleVariables.green};
`

export const CreatePasswordContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${globalStyleVariables.borderColor};
    border-top: 4px solid ${globalStyleVariables.green};
    width: 940px;
`

export const Header = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${globalStyleVariables.borderColor};
`

export const Title = styled.div`
    font-family: 'Encode Sans Expanded';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    margin-bottom: 10px;
`

export const SubTitle = styled.div`
    margin: 0 0 10px 0;
`

export const Body = styled.div`
    display: flex;
    padding: 20px;
    height: 385px;
`

export const CenteredContainer = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align:center;
`

export const Footer = styled.div`
    border-top: 1px solid ${globalStyleVariables.borderColor};
    padding: 20px;
`