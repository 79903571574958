import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  padding: 3px 15px;
  font-size: 12px;
  border-bottom: 1px solid #e6e6e6;

  .button-content {
    display: flex;
    gap: 8px;
    align-items: center;

    &.disabled {
      div {
        filter: grayscale(100%);
        opacity: 0.6;
      }
    }
  }

  .ant-btn {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const Separate = styled.div`
  width: 1px;
  height: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0 10px;
`;

export const SelectBlock = styled.div`
  width: 240px;
  display: flex;
  gap: 8px;
  align-items: center;

  &:first-of-type {
    margin-left: 8px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: unset !important;
    border: unset !important;
  }
`;
