import _ from "lodash";
import { SwitchStrings } from 'src/Framework/Common/Strings';
import { IStateReducer, IAction, IPref } from './types'

const prefix = `PREF_`

export const actionTypes = {
  UPDATE_CURRENT_USER_PREFERENCES: `${prefix}UPDATE_CURRENT_USER_PREFERENCES`,
  GET_CURRENT_USER_PREFERENCE: `${prefix}GET_CURRENT_USER_PREFERENCE`,
  SET_ACCESSIBILITY_OPTION: `${prefix}SET_ACCESSIBILITY_OPTION`,
  SET_PATIENT_HEADER: `${prefix}SET_PATIENT_HEADER`,
  SET_DASHBOARD_SETTINGS: `${prefix}SET_DASHBOARD_SETTINGS`,
  COLLAPSE_REQUIREMENTS: `${prefix}COLLAPSE_REQUIREMENTS`,
}

const cdStorage = localStorage.getItem('complianceDashboard')
const complianceDashboard = cdStorage ? JSON.parse(cdStorage) : {
  id: 3018,
  value: SwitchStrings.task
}

const parsePreferrences = (data: Record<string, IPref>): any => {
  Object.entries(data).forEach(([key, value]) => {
    parsePreferrenceValue(value)
  })
  return data
}

export const parsePreferrenceValue = <T = any>(pref: IPref<T>) => {
  try {
    pref.parsedValue = JSON.parse(pref.value)
    return pref
  } catch (e) {
    pref.parsedValue = null as any
    return pref
  }
}

export const PreferencesReducer = (
  state: IStateReducer = {
    complianceDashboard
  },
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_USER_PREFERENCES: {
      let newState = _.clone(state);
      action.payload.forEach((value: any) => {
        const key = Object.keys(newState).find((key: string) => newState[key]?.id === value.id)
        if (key) {
          newState[key] = parsePreferrenceValue(value)
        }
      })
      return newState;
    }
    case actionTypes.GET_CURRENT_USER_PREFERENCE: {
      let newState = _.clone(state);
      newState = { ...parsePreferrences(action.payload) };
      return newState;
    }
    case actionTypes.SET_ACCESSIBILITY_OPTION: {
      let newState = _.clone(state);
      newState.accessibilityOptions = { ...parsePreferrenceValue(action.payload) };
      return newState;
    }
    case actionTypes.SET_PATIENT_HEADER: {
      let newState = _.clone(state);
      newState.patientHeader = { ...parsePreferrenceValue(action.payload) };
      return newState;
    }
    case actionTypes.SET_DASHBOARD_SETTINGS: {
      let newState = _.clone(state);
      newState.dashboardSettings = { ...parsePreferrenceValue(action.payload) };
      return newState;
    }
    case actionTypes.COLLAPSE_REQUIREMENTS: {
      let newState = _.clone(state);
      if (newState.collapseRequirements) {
        newState.collapseRequirements.value = action.payload;
      }
      return newState;
    }
    default:
      return state;
  }
};
