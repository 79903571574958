import _ from 'lodash';
import { IAction, IAlertReducer, IStateReducer } from './types'

const prefix = 'ALERTS_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_TYPES: `${prefix}SET_TYPES`,
    SET_FILTER: `${prefix}SET_FILTER`,
}

export const initialData: IAlertReducer = {
    summary: [],
    filter: {
        valid: true
    }
}

const initialState: IStateReducer = {
    patients: {},
    patientAlertTypes: []
};

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET_TYPES:
            return {
                ...state,
                patientAlertTypes: action.payload
            }
        case actionsTypes.SET_FILTER:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        filter: {
                            ...action.payload
                        }
                    }
                }
            }
        case actionsTypes.SET:
            if (state.patients[action.patientId]) {
                return {
                    ...state,
                    patients: {
                        ...state.patients,
                        [action.patientId]: {
                            ...state.patients[action.patientId],
                            summary: action.payload
                        }
                    }
                }
            }
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ..._.cloneDeep(initialData),
                        summary: action.payload
                    }
                }

            }
        default:
            return state;
    }
};

export default Reducer;
