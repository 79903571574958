import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router';

import { MainContainer } from './App_Styles';

import { AppUrl } from 'src/Framework/Common/AppUrl';

import { IActivity } from './ActivityPanel/Models/Activity';

import { getPermissions, internalLogin } from './LoginPage/store/Actions';
import { navbarVisible } from './SideNavBar/Store/SideNavBarAction';
import {
  loadActivityTab,
  onChangeCurrentActivity
} from './ActivityPanel/Store/ActivityActions';
import { enableCohortLoader } from 'src/Activities/Compliance/CohortWidget/Store/cohortLoader';
import { getCohortList } from 'src/Activities/Compliance/CohortWidget/Store/CohortWidgetAction';

import AppErrorBoundary from 'src/Framework/Errors/AppErrorBoundary';

import { Box } from '../CommonStyles/styles';

import LazyLoader from 'src/Framework/Controls/LazyLoader';

import store, { IStore } from 'src/store';
import { hashParser } from 'src/Framework/util/helps';
import { SSO_DATA } from './SSO/utils';
import { ISSOHash } from './SSO/types';
import ForgotPassword from './ForgotPassword';

const LazyLoginPage = React.lazy(() => import('./LoginPage'));
const LazyForgotPassword = React.lazy(
  () => import('./LoginPage/ForgotPassword')
);
const LazyWrappedComponents = React.lazy(() => import('./WrappedComponents'));
const LazySetupProfile = React.lazy(() => import('./SetupProfile'));
const LazyNotePreview = React.lazy(() => import('./NotePreview'));
const LazyNotePreviewV2 = React.lazy(() => import('./NotePreview/V2'));
const LazyFormPreview = React.lazy(() => import('./FormPreview'));
const LazyFormTemplatePreview = React.lazy(
  () => import('./FormTemplatePreview')
);

interface IOwnProps {
  location: any;
  history: any;
  match: any;
}

interface IProps extends IOwnProps {
  activities: IActivity[];
  navBarHideShow: any;
  navBarVisible: any;
}

interface IState {
  loading: boolean;
  userActive: boolean;
  sessionCheckInterval: any;
  fileViewer: boolean;
  internalLoginToken: any;
  hashData: ISSOHash | null;
}

class App extends React.Component<IProps, IState> {
  public sessionCheckInterval: any = null;

  private _url: string;

  constructor(props: any) {
    super(props);
    const params = new URLSearchParams(this.props.location.search);
    const user = localStorage.getItem('user');
    const hashData = window.location.hash
      ? hashParser(window.location.hash)
      : null;
    this.state = {
      loading: false,
      userActive: user ? true : false,
      sessionCheckInterval: null,
      fileViewer: window.location.pathname.includes(`documentList/`),
      internalLoginToken: params.get('token'),
      hashData
    };
    this._url =
      window.location.pathname + window.location.search + window.location.hash;
  }

  shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    const currentActivity = nextProps.activities.find(
      (a) => a.isCurrent === true
    );
    const prevCurrentActivity = this.props.activities.find(
      (a) => a.isCurrent === true
    );
    if (JSON.stringify(currentActivity) !== JSON.stringify(prevCurrentActivity))
      return true;
    return (
      this.props.location.pathname !== nextProps.location.pathname ||
      this.props.navBarHideShow !== nextProps.navBarHideShow ||
      this.state.internalLoginToken !== nextState.internalLoginToken
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const path =
      nextProps.location.pathname +
      nextProps.location.search +
      nextProps.location.hash;
    if (
      JSON.stringify(
        this.props.activities.map((item) => ({
          isCurrent: item.isCurrent,
          targetURL: item.targetURL,
          activityId: item.activityId
        }))
      ) !==
      JSON.stringify(
        nextProps.activities.map((item) => ({
          isCurrent: item.isCurrent,
          targetURL: item.targetURL,
          activityId: item.activityId
        }))
      )
    ) {
      const currentActivity = nextProps.activities.find(
        (a) => a.isCurrent === true
      );
      if (currentActivity && path !== currentActivity.targetURL) {
        nextProps.history.push(currentActivity.targetURL);
      } else if (!currentActivity) {
        if (localStorage.getItem('user')) {
          nextProps.history.push('/home');
        }
      }
    } else {
      const existingActivity = nextProps.activities
        .filter((item) => !item.isCurrent)
        .find((item) => item.targetURL === path);
      if (
        nextProps.history.action &&
        nextProps.history.action === 'POP' &&
        existingActivity
      ) {
        onChangeCurrentActivity(existingActivity);
      }
    }
    if (localStorage.getItem('user') || localStorage.getItem('sessionValid')) {
      this.setState({
        userActive: true
      });
    }
  }

  componentDidMount = async () => {
    if (this.props.location.pathname.includes(AppUrl.forgotPassword)) {
      return;
    }
    if (this.props.location.pathname.includes('/sso/')) {
      return;
    }
    if (
      this.props.location.pathname.includes(AppUrl.NotePreview) ||
      this.props.location.pathname.includes(AppUrl.FormPreview) ||
      this.props.location.pathname.includes(AppUrl.FormTemplatePreview)
    ) {
      return;
    }
    const { hashData } = this.state;
    const id_token = hashData?.id_token;
    if (hashData && id_token) {
    } else {
      if (this.state.internalLoginToken) {
        this.setState({
          userActive: true
        });
        internalLogin(
          this.state.internalLoginToken,
          store.dispatch,
          (clinicId: number, error: any) =>
            this.internalLoginCallBack(clinicId, error),
          () => {
            this.props.history.push(AppUrl.SESSION_LOGIN);
            this.props.history.go();
          }
        );
      } else {
        const user = localStorage.getItem('user');
        const { location } = window;
        if (location && location.pathname) {
          const currentUrl =
            location.pathname + location.search + location.hash;
          let splitPath = currentUrl.split('/');
          let url = '';
          if (splitPath && splitPath.length > 0) {
            let len: number = splitPath.length;
            for (let i = 0; i < len; i++) {
              if (splitPath[i]) url += '/' + splitPath[i];
            }
          }
          if (user) {
            store.dispatch(enableCohortLoader(true));
            store.dispatch(getCohortList(1, 30, 'nameAsc', '', {}, null, null));
          }
          const currentActivity = this.props.activities.find(
            (a) => a.isCurrent === true
          );
          if (currentActivity?.targetURL !== url) {
            const newUrl = url.replace(/%20/g, ' ');
            loadActivityTab(newUrl, store.dispatch);
          }
        }
        if (user) {
          if (!this.props.navBarVisible) {
            store.dispatch(navbarVisible());
          }
          this.setState({
            userActive: true
          });
          if (this._url === '/') {
            this.props.history.push('/home');
          }
        } else {
          if (window.location.pathname.includes(AppUrl.SETUP_PROFILE)) {
          } else {
            if (localStorage.getItem('sessionValid') !== '401') {
              this.props.history.push(
                '/login' + window.location.search + window.location.hash
              );
            }
          }
        }
        if (window.location.pathname.includes(`documentList/`)) {
          this.setState({
            fileViewer: true
          });
        }
      }
    }
  };

  internalLoginCallBack = (clinicId: number, error: any) => {
    const callBackClinics = () => {
      this.setState({
        internalLoginToken: null
      });
      this.props.history.push(this._url);
      this.props.history.go();
    };
    store.dispatch(enableCohortLoader(true));
    store.dispatch(getCohortList(1, 30, 'nameAsc', '', {}, null, null));
    getPermissions(clinicId, callBackClinics);
  };

  LoginPath = () => (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={AppUrl.LOGIN} />} />
      <Route
        path={AppUrl.LOGIN}
        render={() => (
          <LazyLoader>
            <LazyLoginPage />
          </LazyLoader>
        )}
      />
      <Route
        exact
        path={AppUrl.FORGOT}
        render={() => (
          <LazyLoader>
            <LazyForgotPassword />
          </LazyLoader>
        )}
      />
    </Switch>
  );

  LoaderComponent = () => (
    <Box style={{ margin: 'auto' }}>
      <div className="loader" />
    </Box>
  );

  WrappedComponents = (routerProps: any) => {
    return (
      <LazyLoader>
        <LazyWrappedComponents
          {...routerProps}
          navBarHideShow={this.props.navBarHideShow}
          navBarVisible={this.props.navBarVisible}
          fileViewer={this.state.fileViewer}
        />
      </LazyLoader>
    );
  };

  SetupProfile = (routerProps: any) => {
    return (
      <LazyLoader>
        <LazySetupProfile {...routerProps} />
      </LazyLoader>
    );
  };

  forgotPasswordComponent = () => (
    <LazyLoader>
      <LazyForgotPassword />
    </LazyLoader>
  );

  render() {
    return (
      <AppErrorBoundary>
        <MainContainer>
          <Switch>
            <Route exact path="/" component={this.LoginPath} />
            <Route exact path={AppUrl.LOGIN} component={this.LoginPath} />
            {Object.entries(SSO_DATA).map(([key, value]) => (
              <Route
                key={key}
                exact
                path={value.path}
                render={() => {
                  const Component = value.component;
                  return (
                    <LazyLoader>
                      <Component />
                    </LazyLoader>
                  );
                }}
              />
            ))}
            <Route
              exact
              path={AppUrl.SETUP_PROFILE}
              component={this.SetupProfile}
            />
            <Route
              exact
              path={AppUrl.forgotPassword}
              render={() => (
                <LazyLoader>
                  <ForgotPassword />
                </LazyLoader>
              )}
            />
            <Route
              exact
              path={`${AppUrl.NotePreview}/v2`}
              render={() => (
                <LazyLoader>
                  <LazyNotePreviewV2 />
                </LazyLoader>
              )}
            />
            <Route
              exact
              path={`${AppUrl.FormPreview}`}
              render={() => (
                <LazyLoader>
                  <LazyFormPreview />
                </LazyLoader>
              )}
            />
            <Route
              exact
              path={`${AppUrl.FormTemplatePreview}`}
              render={() => (
                <LazyLoader>
                  <LazyFormTemplatePreview />
                </LazyLoader>
              )}
            />
            <Route
              exact
              path={`${AppUrl.NotePreview}/:noteId`}
              render={(props) => (
                <LazyLoader>
                  <LazyNotePreview {...props} />
                </LazyLoader>
              )}
            />
            <Route
              path={AppUrl.SESSION_LOGIN}
              render={() => (
                <LazyLoader>
                  <LazyLoginPage />
                </LazyLoader>
              )}
            />
            <Route
              exact
              path={AppUrl.FORGOT}
              component={this.forgotPasswordComponent}
            />
            {this.state.userActive || this.state.internalLoginToken ? (
              <Route
                path="/"
                component={
                  this.state.internalLoginToken
                    ? this.LoaderComponent
                    : this.WrappedComponents
                }
              />
            ) : (
              <Redirect to={AppUrl.LOGIN} />
            )}
          </Switch>
        </MainContainer>
      </AppErrorBoundary>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  return {
    activities: state.activity,
    navBarHideShow: state.navBarHideShow.showHideNavBar,
    navBarVisible: state.navBarHideShow.visible
  };
};

export default withRouter(connect(mapStateToProps)(App));
