import globalStyleVariables from "src/Framework/Styles/variables.module.scss";
import styled from "styled-components";

export const Text = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: ${globalStyleVariables.newBrandingPrimary200};
    white-space: nowrap;
`