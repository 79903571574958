import _ from 'lodash';
import * as actionTypes from './Actions';
import { IStateReducer, IAction } from './types'

const prefix = 'COUNSELING_DASHBOARD_';

export const actionsTypes = {
  SET_FILTER: `${prefix}SET_FILTER`,
};

const initialState: IStateReducer = {
  availableCategories: {},
  clientAttachments: [],
  filter: {
    onlyCounseling: true
  }
};

export const dashboardInit: any = {
  items: {},
  data: {},
}


const DashboardReducer = (state: IStateReducer = _.cloneDeep(initialState), action: IAction): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload
        }
      }
    }
    case actionTypes.SET_AVAILABLE_CATEGORIES: {
      let newState = _.cloneDeep(state)
      newState.availableCategories = action.payload;
      return newState;
    }
    default:
      return state;
  }
};

export default DashboardReducer;