import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';

import { ButtonContainer, InputContainer, ClinicTitle } from './styled';
import { ClinicPageContainer, ClinicBoxContainer, ClinicBox } from './styled';
import { expiryCheck } from '../store/Actions';
import { setPreference } from 'src/Activities/Compliance/Store/ComplianceActions';
import { addCurrentUser } from '../../User/Current/Actions';
import store, { IStore } from 'src/store';
import history from 'src/history';
import ClinicSelector, {
  getCurrentClinicId
} from 'src/Framework/Controls/Selectors/ClinicSelector';

export const setClinicUser = (clinic: any, userId: any, dispatch?: any) => {
  if (clinic) {
    localStorage.setItem('clinic', clinic?.name);
    localStorage.setItem('clinicId', clinic?.id);
    localStorage.setItem('clinicCode', clinic?.code);
    localStorage.setItem('userId', userId);
    if (dispatch) {
      let userContext = {
        clinicId: `${clinic.id}`,
        clinicCode: `${clinic.code}`,
        userId: `${userId}`
      };
      dispatch(addCurrentUser(userContext));
    }
  }
};

interface IState {
  selectedClinic: any;
  loading: boolean;
}

interface IOwnProps {
  history: any;
  loginResponse: any;
  match: any;
}

interface IProps extends IOwnProps {
  clinics: IStore['adminPanel']['clinics']['summary'];
}

class ClinicPage extends React.Component<IProps, IState> {
  public titleRef: any = React.createRef();

  constructor(props: any) {
    super(props);
    this.state = {
      selectedClinic: getCurrentClinicId(),
      loading: false
    };
  }

  onClickCancel() {
    this.props.history.goBack();
    setTimeout(() => {
      document.getElementById('main-navigation-menu')?.focus();
      setTimeout(() => {
        document.getElementById('clinic-selection-button')?.focus();
      }, 200);
    }, 200);
  }

  modalKeydownHandler(e: KeyboardEvent) {
    if (e.key === 'Tab') {
      const firstElement = document.getElementById('clinic-selector-title');
      const lastElement = document.getElementById(
        'clinic-selector-cancel-button'
      );
      if (e.target === firstElement && e.shiftKey) {
        e.preventDefault();
        if (lastElement) {
          lastElement.focus();
        }
      }
      if (e.target === lastElement && !e.shiftKey) {
        e.preventDefault();
        if (firstElement) {
          firstElement.focus();
        }
      }
    }
    if (e.key === 'Escape') {
      history.goBack();
      setTimeout(() => {
        document.getElementById('main-navigation-menu')?.focus();
        setTimeout(() => {
          document.getElementById('clinic-selection-button')?.focus();
        }, 200);
      }, 200);
    }
  }

  componentDidMount() {
    if (this.titleRef.current) {
      this.titleRef.current.focus();
    }
    document.addEventListener('keydown', this.modalKeydownHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.modalKeydownHandler);
  }

  handleClinic = () => {
    const { selectedClinic } = this.state;
    if (selectedClinic !== getCurrentClinicId()) {
      const userId = localStorage.getItem('userId');
      const dispatch = store.dispatch;
      const data = {
        userId: userId,
        preferences: [
          {
            id: 3001,
            userId: userId,
            name: 'ClinicCode',
            value: selectedClinic,
            defaultValue: ''
          }
        ]
      };
      setPreference(data)
        .then((response) => {
          let data: any = response.data;
          if (data.success && data.result.success) {
            this.props.history.push('/home');
            window.location.reload();
          }
        })
        .catch((error) => {
          dispatch(expiryCheck(error));
          console.log(error);
        });
    } else {
      this.onClickCancel();
    }
  };

  onSelectClinic = (val: any) => {
    this.setState({
      selectedClinic: val
    });
  };

  render() {
    return (
      <ClinicPageContainer>
        <ClinicBoxContainer>
          <ClinicBox>
            <ClinicTitle
              tabIndex={0}
              ref={this.titleRef}
              id="clinic-selector-title"
            >
              <span className={'clinic-box-title'}>
                Select Clinic to work in
              </span>
            </ClinicTitle>
            <InputContainer>
              <ClinicSelector
                multiple={false}
                value={this.state.selectedClinic}
                onChange={(id: string) => this.onSelectClinic(id)}
                mandatory={false}
                selectProps={{
                  bottomMargin: false,
                  inputProps: {
                    allowClear: false
                  }
                }}
              />
            </InputContainer>
            <ButtonContainer onClick={() => this.handleClinic()}>
              <Button
                className={'disable-login-button'}
                loading={this.state.loading}
              >
                CONFIRM
              </Button>
            </ButtonContainer>
            <Button
              id="clinic-selector-cancel-button"
              className={''}
              style={{
                marginTop: 10,
                fontFamily: 'Lato',
                fontSize: 14,
                border: 'none',
                width: '100px',
                backgroundColor: 'transparent',
                boxShadow: 'none'
              }}
              onClick={() => this.onClickCancel()}
              loading={this.state.loading}
            >
              Cancel
            </Button>
          </ClinicBox>
        </ClinicBoxContainer>
      </ClinicPageContainer>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    loginResponse: state.login.response,
    clinics: state.adminPanel.clinics.summary
  };
};

export default connect(mapStateToProps)(ClinicPage);
