// @ts-ignore
import UTIF from 'utif';
import { ContentTypes, Formats } from "./types"

export const ImageFormats = [Formats.tiff, Formats.bmp, Formats.jpg, Formats.png, Formats.avif];

const supportedContentTypes = [ContentTypes.bmp, ContentTypes.html, ContentTypes.jpeg, ContentTypes.jpg, ContentTypes.pdf, ContentTypes.png, ContentTypes.tif, ContentTypes.tiff, ContentTypes.txt]

export const isContentTypeSupport = (contentType: string) => {
    const find = supportedContentTypes.find((v) => v?.toLowerCase() === contentType?.toLowerCase())
    return find ? true : false
}

export const generateCanvasFromTiff = (buffer: ArrayBuffer) => {
    var ifds = UTIF.decode(buffer);
    const _tiffs = ifds.map((ifd: any, index: any) => {
        UTIF.decodeImage(buffer, ifd);
        var rgba = UTIF.toRGBA8(ifd);
        var canvas = document.createElement('canvas');
        canvas.width = ifd.width;
        canvas.height = ifd.height;
        var ctx = canvas.getContext('2d');
        if (ctx) {
            var img = ctx.createImageData(ifd.width, ifd.height);
            img.data.set(rgba);
            ctx.putImageData(img, 0, 0);
            if (index === 0) {
            }
            return canvas;
        }
        return;
    });
    if (_tiffs && _tiffs[0]) {
        return _tiffs
    }
    return
}

export const generateAndDownloadFile = (content: string, fileName: string, contentType: Formats, fileType?: ContentTypes) => {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = `${fileName}${fileType}`;
    a.click();
}