import _ from 'lodash'
import moment from 'moment'

import { IAction, IStateReducer } from './types'

const prefix = 'TODOS_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_LOADING: `${prefix}SET_LOADING`,
    SET_RANGE: `${prefix}SET_RANGE`,
    SET_SORT: `${prefix}SET_SORT`
}

export const defaultFilter = {
    assignedClinicId: null,
    assignedProviderIds: [],
    assignedUserId: null,
    isDone: null,
}

const initialState: IStateReducer = {
    summary: [],
    loading: false,
    filters: { ...defaultFilter },
    range: {
        dateFrom: moment().startOf('year').format('MM/DD/YYYY'),
        dateTo: moment().add(14, "days").format('MM/DD/YYYY')
    },
    sort: {
        sortBy: null,
        sortType: null
    },
};

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET: {
            return { ...state, summary: action.payload }
        }
        case actionsTypes.SET_LOADING: {
            return { ...state, loading: action.payload }
        }
        case actionsTypes.SET_FILTER: {
            return { ...state, filters: { ...state.filters, ...action.payload } }
        }
        case actionsTypes.SET_RANGE: {
            return { ...state, range: { ...state.range, ...action.payload } }
        }
        case actionsTypes.SET_SORT: {
            return {
                ...state,
                sort: { ...state.sort, ...action.payload }
            }
        }
        default:
            return state;
    }
};

export default Reducer;
