import store from "src/store";

import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"

import { actionsTypes } from './index'
import { ITagDefinitionSimpleDto, ITagValueSimpleDto, ITagLinkSimpleDto } from './types';

const setTagsDefinitions = (payload: Record<string, ITagDefinitionSimpleDto>) => {
    store.dispatch({
        type: actionsTypes.SET_TAGS_DEFINITIONS,
        payload
    })
}

const setTagsValues = (payload: Record<string, ITagValueSimpleDto>, tagDefinitionId: number) => {
    store.dispatch({
        type: actionsTypes.SET_TAGS_VALUES,
        payload,
        tagDefinitionId
    })
}

const setTagsLinks = (payload: Record<string, ITagLinkSimpleDto>, tagValueId: number) => {
    store.dispatch({
        type: actionsTypes.SET_TAGS_LINKS,
        payload,
        tagValueId
    })
}

const onChangeTagLink = (payload: ITagLinkSimpleDto) => {
    const tagValueId = payload.tagValue.id
    const data = { ...store.getState().tags.links[tagValueId] } || {}
    data[payload.id] = {
        ...payload
    }
    store.dispatch({
        type: actionsTypes.SET_TAGS_LINKS,
        payload: data,
        tagValueId
    })
}

const onRemoveTagLink = (payload: ITagLinkSimpleDto) => {
    const tagValueId = payload.tagValue.id
    const data = { ...store.getState().tags.links[tagValueId] } || {}
    delete data[payload.id]
    store.dispatch({
        type: actionsTypes.SET_TAGS_LINKS,
        payload: data,
        tagValueId
    })
}

export const getTagsDefinitions = async (): Promise<Record<string, ITagDefinitionSimpleDto> | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.TAGS_DEFINITIONS, {
            withCredentials: true
        })
        if (response.data.success) {
            const data = response.data.result.summary
            setTagsDefinitions(data)
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const postTagsValues = async (tagDefinitionId: number, value: string): Promise<Record<string, ITagValueSimpleDto> | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(ApiUrl.TAGS_VALUES, {
            tagDefinitionId,
            value
        }, {
            withCredentials: true,
        })
        if (response.data.success) {
            const data = response.data.result.summary
            setTagsValues(data, tagDefinitionId)
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const patchTagsValues = async (tagDefinitionId: number, tagValueId: number, data: Partial<ITagValueSimpleDto>): Promise<Record<string, ITagValueSimpleDto> | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.patch(`${ApiUrl.TAGS_VALUES}/${tagValueId}`, data, {
            withCredentials: true,
        })
        if (response.data.success) {
            const data = response.data.result.summary
            setTagsValues(data, tagDefinitionId)
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const getTagsValues = async (tagDefinitionId: number): Promise<Record<string, ITagValueSimpleDto> | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.TAGS_VALUES, {
            withCredentials: true,
            params: {
                tagDefinitionId
            }
        })
        if (response.data.success) {
            const data = response.data.result.summary
            setTagsValues(data, tagDefinitionId)
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const getTagsLinks = async ({
    tagValueIds
}: {
    tagValueIds: number[]
}): Promise<Record<string, ITagLinkSimpleDto> | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.TAGS_LINKS, {
            withCredentials: true,
            params: {
                tagValueIds: tagValueIds.join(',')
            }
        })
        if (response.data.success) {
            const data: Record<string, ITagLinkSimpleDto> = response.data.result.summary
            let obj = {}
            Object.values(data).forEach((v) => {
                obj[v.tagValue.id] = {
                    ...obj[v.tagValue.id],
                    [v.id]: v
                }
            })
            Object.keys(obj).forEach((valueId) => {
                setTagsLinks(obj[valueId], +valueId)
            })
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const putTagLink = async ({
    tagDefinitionId,
    tagValueId,
    entityId
}: {
    tagDefinitionId: number,
    tagValueId: number,
    entityId: number
}): Promise<any | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.put(ApiUrl.TAGS_LINKS, {
            tagDefinitionId,
            tagValueId,
            entityId
        }, {
            withCredentials: true,
        })
        if (response.data.success) {
            const data: Record<string, ITagLinkSimpleDto> = response.data.result.summary
            Object.values(data).forEach((v) => {
                onChangeTagLink(v)
            })
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const deleteTagLink = async (link: ITagLinkSimpleDto): Promise<any | undefined> => {
    try {
        const tagValueId = link.tagValue.id
        const response = await APIHandler.AxiosInstance.delete(ApiUrl.TAGS_LINKS, {
            withCredentials: true,
            params: {
                tagDefinitionId: link.tagDefinition.id,
                tagValueId,
                entityId: link.entityId
            }
        })
        if (response.data.success) {
            onRemoveTagLink(link)
            return true
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}