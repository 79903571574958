import React, { useImperativeHandle, useState } from 'react';
import _ from 'lodash';

import Modal from 'src/Framework/Controls/Modal';
import Button from 'src/Framework/Controls/Button';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import WYSIWYG from 'src/Framework/Controls/WYSIWYG';
import test_ids from 'src/tests-script/pages/AdminPanel/FormBuilder';

import * as formsActions from '../store/Actions';
import { openBuildingFormTab } from '../utils';
import GroupTypesSelector from 'src/App/Admin/Pages/GroupTypes/Components/GroupTypesSelector';
import { nameMaxLength } from 'src/App/Admin/Pages/FormBuilder/Lists/BuildingBlocks/utils';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import { Container } from './styled';

interface IOwnProps {}

interface IProps extends IOwnProps {}

export interface IModalHandles {
  show(obj: any): void;

  close(): void;
}

interface IData {
  name: string;
  groupTemplateFormId: null | number;
  description?: string;
}

const defaultData: IData = {
  name: '',
  description: '',
  groupTemplateFormId: null
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  {},
  ref
) => {
  const [data, setData] = useState<IData>(_.cloneDeep(defaultData));
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  useModalFocus({ active: visible });
  const show = () => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setData(_.cloneDeep(defaultData));
  };

  useImperativeHandle(ref, () => ({
    show,
    close
  }));

  const apply = async () => {
    setLoading(true);
    const result = await formsActions.post({
      name: data.name,
      description: data?.description || '',
      groupTemplateFormId: data.groupTemplateFormId
    });
    if (result) {
      const list = Object.values(result);
      const item = list[0];
      if (item) {
        openBuildingFormTab(item);
        close();
      }
    }
    setLoading(false);
  };

  const checkDisabled = () => {
    return data.name === '';
  };

  const title = 'Form info';
  const headerRight = (
    <Button
      id="save-button"
      disabled={checkDisabled()}
      loading={loading}
      onClick={apply}
      shape="round"
      className="green-button"
    >
      Save
    </Button>
  );
  const defaultWidth = 810;

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title
      }}
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      headerBorder={true}
      afterClose={setToDefault}
      modalProps={{
        destroyOnClose: true
      }}
      buttons={headerRight}
    >
      <Container>
        <SelectContainer
          label="Name"
          type="input"
          inputProps={{
            maxLength: nameMaxLength,
            id: test_ids.formsName
          }}
          value={data.name}
          onChange={(value) => {
            setData((data) => ({ ...data, name: value }));
          }}
        />
        <GroupTypesSelector
          id=""
          name="Form Group"
          value={data.groupTemplateFormId}
          onChange={(groupTemplateFormId) => {
            setData((data) => ({ ...data, groupTemplateFormId }));
          }}
          type="single"
          groupId="groupTemplateForm"
          selectProps={{
            star: false,
            inputProps: {
              allowClear: true
            }
          }}
        />
        <WYSIWYG
          title="Description"
          id={test_ids.formsDescription}
          value={data.description}
          onChange={(value: string) => {
            setData((data) => ({ ...data, description: value }));
          }}
        />
      </Container>
    </Modal>
  );
};

export default React.forwardRef(Component);
