import React, { useImperativeHandle, useState } from 'react';
import _ from 'lodash';
import { Row, Col } from 'antd';

import Button from 'src/Framework/Controls/Button';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Modal from 'src/Framework/Controls/Modal';
import Icon from 'src/Framework/Controls/Icon';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import { useAppSelector } from 'src/store';

import * as tagsActions from 'src/App/Tags/store/Actions';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';
import TagContainer from '../TagContainer';

import { ContainerModal, NewContainer } from './styled';

export interface IData {
  tags: number[];
}

interface IProps {
  onChange: (data: IData) => any;
  tagDefinitionId: number;
}

interface IShow {
  tags: number[];
}

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

const defaultData: IData = {
  tags: []
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  props,
  ref
) => {
  const { onChange, tagDefinitionId } = props;
  const { values } = useAppSelector((state) => {
    return {
      values: state.tags.values[tagDefinitionId] || {}
    };
  });
  const [data, setData] = useState<IData>(_.cloneDeep(defaultData));
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [loadingNew, setLoadingNew] = useState(false);
  const [visibleSelectContent, setVisibleSelectContent] = useState(false);
  useModalFocus({ active: visible });
  const show = ({ tags }: IShow) => {
    setData({
      tags
    });
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setData(_.cloneDeep(defaultData));
    setSearch('');
  };

  useImperativeHandle(ref, () => ({
    show,
    close
  }));

  const apply = async () => {
    const res = await onChange(data);
    if (res) {
      close();
    }
  };

  const checkTagsValueDuplication = (value: string) => {
    const list = Object.values(values);
    const item = list.find((v) => v.value === value.trim());
    return {
      item,
      exist: item ? true : false
    };
  };

  const createNew = async () => {
    const duplication = checkTagsValueDuplication(search);
    if (duplication.exist) {
      setSearch('');
      setData((data) => {
        if (duplication.item) {
          const alreadyExist = data.tags.includes(duplication.item.id);
          if (alreadyExist) {
            return data;
          }
          return {
            ...data,
            tags: [...data.tags, duplication.item.id]
          };
        }
        return data;
      });
      return;
    }
    if (loadingNew) return;
    setLoadingNew(true);
    const res = await tagsActions.postTagsValues(
      tagDefinitionId,
      search.trim()
    );
    if (res) {
      setSearch('');
      const response = Object.values(res)[0];
      if (response) {
        setData((data) => ({
          ...data,
          tags: [...data.tags, response.id]
        }));
      }
    }
    setLoadingNew(false);
  };

  const list = Object.values(values);
  const filteredValues = list.filter(
    (v) =>
      v.isActive &&
      !data.tags.includes(v.id) &&
      v.value.toLowerCase().includes(search.toLowerCase())
  );

  const title = 'Tags';
  const headerRight = (
    <Button
      id="save-button"
      onClick={apply}
      shape="round"
      className="green-button"
    >
      Save
    </Button>
  );
  const defaultWidth = 400;

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title
      }}
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      headerBorder={true}
      afterClose={setToDefault}
      modalProps={{
        destroyOnClose: true
      }}
      buttons={headerRight}
    >
      <ContainerModal>
        {loadingNew && <AbsoluteLoader />}
        <SelectContainer
          star={false}
          label="Tags"
          type="auto-complete"
          value={search}
          onSearch={(search) => {
            setSearch(search);
          }}
          onChange={(value: string) => {
            setSearch('');
            setData((data) => ({
              ...data,
              tags: [...data.tags, +value]
            }));
          }}
          bottomMargin={false}
          options={filteredValues
            .map((v) => ({
              value: v.id,
              label: v.value
            }))
            .sort((a, b) => a.label?.localeCompare(b.label))}
          inputProps={{
            id: 'tags-search-field',
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                createNew();
              }
            },
            notFoundContent: (
              <NewContainer
                onClick={() => {
                  createNew();
                  setVisibleSelectContent(false);
                }}
              >
                <Row align="middle" gutter={8}>
                  <Col>
                    <Icon name="add" size={18} />
                  </Col>
                  <Col>Add new tag</Col>
                </Row>
              </NewContainer>
            ),
            onBlur: () => setVisibleSelectContent(false),
            open: visibleSelectContent,
            onDropdownVisibleChange: (open) => {
              setVisibleSelectContent(open);
            }
          }}
        />
        <Row gutter={[8, 8]} style={{ marginTop: 10 }}>
          {data.tags.map((v, index) => {
            const item = values[v];
            if (!item) return null;
            return (
              <Col key={v}>
                <TagContainer>
                  <Row gutter={8} align="middle">
                    <Col>{item.value}</Col>
                    <Col>
                      <Icon
                        size={10}
                        name="thinClose"
                        color="black"
                        onClick={() => {
                          setData((data) => {
                            const tags = [...data.tags];
                            tags.splice(index, 1);
                            return {
                              ...data,
                              tags
                            };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </TagContainer>
              </Col>
            );
          })}
        </Row>
      </ContainerModal>
    </Modal>
  );
};

export default React.forwardRef(Component);
