import styled from 'styled-components';
import { gray_background_color } from 'src/CommonStyles/styles';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 12px;
`;

export const Attachment = styled(InputContainer)`
  cursor: pointer;
  height: 32px;
  background: ${gray_background_color};
`;

export const TextContainer = styled.div`
  white-space: nowrap;
  width: 100%;
  margin: 8px;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AttachmentButton = styled.div`
  width: 62px;
  height: 24px;
  background: #eefaea;
  border: 1px solid ${globalStyleVariables.newBrandingPrimary200};
  display: flex;
  align-items: center;
  color: ${globalStyleVariables.newBrandingPrimary200};

  span {
    text-align: center;
    width: 100%;
  }

  margin: 4px;
  padding: 4px;
`;

export const FileUploader: any = styled.input`
  display: none;
`;

export const Container: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 16px 0;
`;
