
export const SET_CURRENT_SECTION = "SET_CURRENT_SECTION";
export const SET_CURRENT_POSITION = "SET_CURRENT_POSITION";
export const ORDERED_TEMPLATES = "ORDERED_TEMPLATES";
export const IS_SCROLLING = "IS_SCROLLING";
export const LOADING_MOFF = "LOADING_MOFF";
export const SAVING_NOTE = "SAVING_NOTE";
export const SET_SECTIONS_VALID = "SET_SECTIONS_VALID";

export const setCurrentSection = (data: string, id: any) => {
  return {
    type: SET_CURRENT_SECTION,
    id: id,
    payload: data
  }
};

export const setCurrentPosition = (position: any, id: any) => {
  return {
    type: SET_CURRENT_POSITION,
    payload: position,
    id: id,
  }
};

export const updateSectionsStatus = (valid: boolean) => {
  return {
    type: SET_SECTIONS_VALID,
    valid
  }
};

export const setOrderedTemplates = (data: any, id: any) => {
  return {
    type: ORDERED_TEMPLATES,
    id: id,
    payload: data
  }
};

export const isScrolling = (data: any, id: any) => {
  return {
    type: IS_SCROLLING,
    id: id,
    payload: data
  }
};
export const loadingMoff = (data: any, id: any) => {
  return {
    type: LOADING_MOFF,
    id: id,
    payload: data
  }
};

export const savingNote = (data: any) => {
  return {
    type: SAVING_NOTE,
    payload: data
  }
};
