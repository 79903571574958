import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import _ from 'lodash';
import { store } from "../../index";
import {
  getProvidersFromClinics,
} from "../Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction";

export const GET_PATIENT_LIST = "GET_PATIENT_LIST";
export const SET_PROVIDERS = "SET_PROVIDERS";
export const SET_CURRENT_CLINIC_PROVIDERS = "SET_CURRENT_CLINIC_PROVIDERS";
export const SET_ALL_CLINICS = "SET_ALL_CLINICS";

export const getPatientListsRequest = {
  lists: [
    "groupPatient",
    "language",
    "relationship",
    "gender",
    "genderIdentity",
    "preferredPronoun",
    "billingMethod",
    "feeSchedule",
    "studentStatus",
    "class",
    "residency",
    "school",
    "sport",
    "eligibility",
    "ethnicity",
    "race",
    "employer",
    "employed",
    "providerTeam",
    "typeProvider",
    "maritalStatus",
    "standing",
    "category",
    "complianceStatus"
  ],
};

export const getCorporateScreeningAccessToken = async (email: string) => {
  const response = await APIHandler.AxiosInstance.get(ApiUrl.GET_CORPORATESSCREENING_ACCESSTOKEN, { withCredentials: true });
  if (response.data && response.data.success) {
    const res = await APIHandler.AxiosInstance.get(`${ApiUrl.GET_CORPORATESSCREENING_AUTHORIZATION_REDIRECT}?email=${email}`, {
      headers: {
        Authorization: `Bearer ${response.data.result.access_token}`
      },
      withCredentials: true
    });
    if (res.data && res.data.success) {
      const win = window.open(res.data.result, '_blank');
      win?.focus();
    }
  }
};

export const getPatientLists = async () => {
  try {
    const response = await getPatientList(getPatientListsRequest)
    let data: any = response.data;

    if (data.success) {
      store.dispatch(addPatientLists(data.result));
    }
  } catch (error) {
    console.log(error);

  }
}

export const getPatientList = (request: any) => {
  return APIHandler.AxiosInstance.post(ApiUrl.GET_PATIENT_LIST, request, {
    withCredentials: true,
  });
};

export const addPatientLists = (patientList: any) => {
  return {
    type: GET_PATIENT_LIST,
    payload: patientList,
  };
};

export const getProviders = async (request: any, set: boolean = true) => {
  try {
    const response = await APIHandler.AxiosInstance.post(ApiUrl.GET_PROVIDERS, request, {
      withCredentials: true,
    })
    let data: any = response.data;
    if (data.success) {
      if (set) {
        setProviders(data.result);
      }
      return data.result
    }
  } catch (e) {
    console.log({ e })
  }
};

export const getCurrentClinicProviders = () => {
  getProvidersFromClinics([store.getState().user.current.clinicCode])
    .then((response) => {
      if (response.data.success) {
        let data: any = response.data;
        setCurrentClinicProviders(data.result);
      }
    })
    .catch((error) => {
      console.log("getProviders error", error);
    });
};

export const setCurrentClinicProviders = (providers: any) =>
  store.dispatch({
    type: SET_CURRENT_CLINIC_PROVIDERS,
    payload: providers,
  });

export const setProviders = (providers: any) =>
  store.dispatch({
    type: SET_PROVIDERS,
    payload: providers,
  });

export const getClinics = () => {
  APIHandler.AxiosInstance.post(ApiUrl.GET_CLINICS, null, {
    withCredentials: true,
  }).then((response) => {
    if (response.data.success) {
      let data: any = response.data;
      setClinics(data.result);
    }
  })
    .catch((error) => {
      console.log("getClinics error", error);
    });
};

export const setClinics = (clinics: any) =>
  store.dispatch({
    type: SET_ALL_CLINICS,
    payload: clinics,
  });

export const getSelectedOptions = (
  options: any,
  selectedValList: any,
  optKey: any = "value"
) => {
  return options.filter(
    (it: any) =>
      selectedValList.map((item: any) => item).indexOf(it[optKey]) != -1
  );
};

export const getSelectedOptionsItem = (
  options: any,
  selected: any,
  optKey: any = "value",
  selKey: any = "value"
) => {
  return options.filter(
    (it: any) =>
      selected.map((item: any) => item[selKey]).indexOf(it[optKey]) != -1
  );
};

export const getOptions = (
  list: any,
  valKey: any = "value",
  label: any = "label"
) => {
  const options: {
    key: any,
    value: any,
    label: string
  }[] = Object.keys(list).map((key) => ({
    key: list[key].value ? list[key].value : list[key][valKey],
    value: list[key][valKey],
    label: list[key][label],
  }));
  return options.sort((a, b) => a.label?.localeCompare(b.label));
};

export const getOpt = (list: any, value: any) => {
  let opt = {};
  if (list) {
    let val = list["id" + value];
    if (val) {
      opt = {
        key: val.value,
        value: val.value,
        label: val.label,
      };
    }
  }
  return opt;
};

export const findOpt = (list: any, value: any, key: any = "value") => {
  let opt = {};
  if (list) {
    let val = list.find((c: any) => c[key] === value);
    if (val) {
      opt = {
        key: val[key],
        value: val[key],
        label: val.label,
        id: val.id,
      };
    }
  }
  return opt;
};
export const findItem = (list: any, value: any, key: any = "value") => {
  if (list) {
    let val = list.find((c: any) => c[key] === value);
    if (val) {
      return val;
    }
  }
};

export const getOptValues = (values: any) => {
  return values.map((val: any) => {
    return val.value;
  });
};

export const setDataToUI = (ui: any, data: any) => {
  Object.keys(data).forEach((key) => {
    if (ui[key] && data[key] !== null) {
      ui[key].value = data[key];
    }
  });
};

export const assignOption = (data: any, value: any, code?: any) => {
  let selectOption: any;
  code ? selectOption = {
    id0: { value: value, label: " ", code: "" }
  } : selectOption = {
    id0: { value: value, label: " " }
  }
  return _.assign(selectOption, data);
};
