import store from 'src/store';

import { actionsTypes } from '../index';
import { IBuildBlocks, IFilters, ISimpleBuildBlock } from '../types';

import * as Getters from './Getters';
import { ISort } from 'src/Framework/Controls/Table/types';

export const summary = (payload: IBuildBlocks) => {
  store.dispatch({
    type: actionsTypes.SET,
    payload
  });
};

export const loading = (payload: boolean) => {
  store.dispatch({
    type: actionsTypes.SET_LOADING,
    payload
  });
};

export const setSorting = (payload: ISort) => {
  filters({ pageNumber: 1 })
  store.dispatch({
    type: actionsTypes.SET_TABLE_SORT,
    payload
  });
};

export const filters = (data: Partial<IFilters>) => {
  const payload = { ...data }
  if (!('pageNumber' in payload)) {
    payload.pageNumber = 1
  }
  store.dispatch({
    type: actionsTypes.SET_FILTERS,
    payload
  });
};

export const updates = (payload: IBuildBlocks) => {
  store.dispatch({
    type: actionsTypes.SET_UPDATES,
    payload
  });
};

export const onChangeItem = (payload: ISimpleBuildBlock) => {
  const summary = Getters.summary();
  summary[payload.id] = payload;
  store.dispatch({
    type: actionsTypes.SET,
    payload: summary
  });
};
