import React, { useCallback, useImperativeHandle, useState } from 'react';
import { LongPressLoader } from './styled';
import classNames from 'classnames';

interface IProps {}

export interface IHandles {
  onChange: (value: number) => any;
}

const Component: React.ForwardRefRenderFunction<IHandles, IProps> = (
  {},
  ref
) => {
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const onChange = useCallback((value: number) => {
    setLoadingPercentage(value);
  }, []);
  useImperativeHandle(ref, () => ({
    onChange
  }));
  return (
    <LongPressLoader
      className={classNames({
        show: loadingPercentage !== 0
      })}
      percentage={loadingPercentage}
    />
  );
};

export default React.forwardRef(Component);
