import _ from 'lodash';
import {
  UPDATE_EVENTS_ON_SLICE_EVENTS,
  REMOVE_SCHEDULE_SLICE_EVENT,
  UPDATE_SCHEDULE_SLICE_EVENTS,
  REMOVE_EVENT_FROM_SLICE_EVENTS
} from './ScheduleSliceEventsActions';
import { SET_SCHEDULE_SLICES } from './ScheduleSlicesActions';

const initState: IState = { reqTime: 0 };

interface IState {
  reqTime: number;
}

interface IAction {
  type: string;
  payload: any;
}

export const ScheduleSliceEventsReducer = (
  state: IState = _.cloneDeep(initState),
  action: IAction
) => {
  switch (action.type) {
    case SET_SCHEDULE_SLICES:
      let newEvents = { reqTime: action.payload.reqTime };
      Object.keys(state).forEach((key: any) => {
        if (action.payload[key] && key !== 'reqTime') {
          newEvents[key] = { ...state[key] };
        }
      });
      return newEvents;
    case UPDATE_EVENTS_ON_SLICE_EVENTS: {
      state[action.payload.sliceId] = {
        ...state[action.payload.sliceId],
        ...action.payload.eventsToUpdate
      };
      return state;
    }
    case REMOVE_EVENT_FROM_SLICE_EVENTS:
      let newSliceEventsRemove = _.cloneDeep(state[action.payload.sliceId]);
      if (newSliceEventsRemove) {
        delete newSliceEventsRemove[action.payload.uid];
      }
      state[action.payload.sliceId] = {
        ...newSliceEventsRemove
      };
      return state;
    case REMOVE_SCHEDULE_SLICE_EVENT:
      //Remove the key from the state
      delete state[action.payload.sliceId];
      return state;
    case UPDATE_SCHEDULE_SLICE_EVENTS:
      //Update only the slices in the payload
      // This code preventing rerendering all events instead of changed events
      Object.entries(action.payload).forEach(([key, value]) => {
        if (!_.isEqual(state[key], value)) {
          state[key] = value;
        }
      });
      return state;
    default:
      return state;
  }
};
