import React, { FC } from 'react';
import moment from 'moment';

import store, { useAppSelector } from 'src/store';
import { IProps, ISingle, IMultiple } from './types';
import Single from './Single';
import Multiple from './Multiple';
import { ClinicSimpleQueryResult } from 'src/App/Admin/Pages/Clinics/store/types';
import {
  capitalizeFirstLetter,
  clinicDisplayName
} from 'src/Framework/util/format';

export const clinicIsCounseling = (clinicId: number) => {
  const clinic = getClinicById(clinicId);
  if (clinic) {
    return clinic.typeId === 1;
  }
  return false;
};

export const filterByClinicTypes = (
  clinicId: number,
  clinicTypes: number[]
) => {
  if (clinicTypes.length === 0) return true;
  const clinic = getClinicById(clinicId);
  if (clinic) {
    return clinic.typeId ? clinicTypes.includes(clinic.typeId) : false;
  }
  return false;
};

export const clinicIsMedical = (clinicId: number) => {
  const clinic = getClinicById(clinicId);
  if (clinic) {
    return clinic.typeId === 2;
  }
  return false;
};

export const getCurrentClinic = (): ClinicSimpleQueryResult | null => {
  const clinics = store.getState().adminPanel.clinics.summary;
  return clinics[getCurrentClinicId()] || null;
};
export const getCurrentClinicInfo = (): ClinicSimpleQueryResult | null => {
  const clinics = store.getState().adminPanel.clinics.summary;
  const currentClinic: any = clinics[getCurrentClinicId()] || null;
  return {
    ...currentClinic,
    value: currentClinic.code,
    dayEndTime: currentClinic.endTime,
    dayStartTime: currentClinic.startTime,
    start: moment(currentClinic.startTime).format('LT'),
    end: moment(currentClinic.endTime).format('LT'),
    visitduration: currentClinic.visitTimeDuration,
    visitDuration: currentClinic.visitTimeDuration,
    dayWeek: currentClinic.workingDays.map((val: string) =>
      capitalizeFirstLetter(val)
    ),
    label: clinicDisplayName(currentClinic)
  };
};

export const getCurrentClinicId = (): number => {
  return +store.getState().user.current.clinicId;
};

export const getAllClinics = () => {
  const clinics = Object.values(store.getState().adminPanel.clinics.summary);
  return clinics.sort((a, b) => a.name?.localeCompare(b.name));
};

export const getCurrentClinicCode = () => {
  return store.getState().user.current.clinicCode;
};

export const getClinicCodesByIds = (ids: number[]) => {
  return ids
    .map((item) => getClinicById(item)?.code)
    .filter((item) => item) as string[];
};
export const getClinicIdsByCodes = (codes: string[]) => {
  return codes
    .map((item) => getClinicByCode(item)?.id)
    .filter((item) => item) as number[];
};

export const getClinics = () => {
  const clinics = Object.values(
    store.getState().adminPanel.clinics.summary
  ).filter((v) => !v.inactive);
  return clinics.sort((a, b) => a.name?.localeCompare(b.name));
};

export const getClinicById = (id: number | string | null | undefined) => {
  const clinics = store.getState().adminPanel.clinics.summary;
  if (!id) {
    return;
  }
  const clinic = clinics[id];
  if (clinic) {
    return clinic;
  }
  return;
};

export const getClinicByCode = (code: string) => {
  const clinics = Object.values(store.getState().adminPanel.clinics.summary);
  const value = clinics.find((v: any) => v.code === code);
  if (value) {
    return {
      ...value,
      // used in some places in calendar
      start: moment(value.startTime).format('LT'),
      end: moment(value.endTime).format('LT'),
      label: clinicDisplayName(value),
      value: value.code,
      visitDuration: value.visitTimeDuration
    };
  }
  return null;
};

export const getClinicByCodeNoCase = (code: string) => {
  const clinics = Object.values(store.getState().adminPanel.clinics.summary);
  return clinics.find((v: any) => v.code.toLowerCase() === code.toLowerCase());
};

const Component: FC<IProps> = (props: IProps) => {
  const { filterItem, useClinicCode, multiple } = props;
  const clinics = useAppSelector((state) => state.adminPanel.clinics.summary);
  const allClinics = Object.values(clinics);
  const filteredClinics = allClinics
    .filter((v) => !v.inactive)
    .sort((a, b) => a.name?.localeCompare(b.name))
    .filter((v) => {
      if (filterItem) {
        return filterItem(v);
      }
      return true;
    });
  const allClinicsOptions = allClinics.map((value) => ({
    title: clinicDisplayName(value),
    label: clinicDisplayName(value),
    value: useClinicCode ? value.code : `${value.id}`,
    clinic: value
  }));
  const options = filteredClinics.map((value) => ({
    title: clinicDisplayName(value),
    label: clinicDisplayName(value),
    value: useClinicCode ? value.code : `${value.id}`,
    clinic: value
  }));
  if (multiple === undefined || multiple === false) {
    const singleProps = props as ISingle;
    return (
      <Single
        {...singleProps}
        allClinicsOptions={allClinicsOptions}
        options={options}
        multiple={false}
      />
    );
  }
  if (multiple === true) {
    const multipleProps = props as IMultiple;
    return (
      <Multiple
        {...multipleProps}
        options={options}
        allClinicsOptions={allClinicsOptions}
        multiple={true}
      />
    );
  }
  return null;
};

Component.defaultProps = {
  mandatory: false
};

export default Component;
