import {
  getMembershipFullPreview,
  updateIncrementalStatus
} from "./CohortManagerAction";
import { APIHandler } from "../../../Framework/Communication/ServerProxy";
import { PROGRESS_TIMER } from '../CohortSheared';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { getCohortOverview, addCohortOverview } from 'src/Activities/Cohort/Reducer';
import { getCohortMembers, addCohortMembers } from 'src/Activities/Cohort/Member/Reducer';
import { expiryCheck } from 'src/App/LoginPage/store/Actions';

interface IIncrementActions {
  watch(
    dispatch: any,
    cohortId: string,
    continueToken: string,
    userId: string,
    ruleId: string,
    jobId: string
  ): any;
}

export class CohortProgress implements IIncrementActions {
  private cohortId: string;
  private userId: string;
  private ruleId: string;
  private jobId: string;
  private ruleVersion: number;
  private cohortName: string;
  constructor(
    cohortId: string,
    userId: string,
    ruleId: string,
    jobId: string,
    ruleVersion: number,
    cohortName: string
  ) {
    this.cohortId = cohortId;
    this.userId = userId;
    this.ruleId = ruleId;
    this.jobId = jobId;
    this.ruleVersion = ruleVersion;
    this.cohortName = cohortName;
  }

  watch(dispatch: any, continueToken: string) {
    setTimeout(() => {
      APIHandler.AxiosInstance.get(
        ApiUrl.GET_MEMBERSHIP_INCREMENTAL_PREVIEW +
        "?continueToken=" +
        continueToken, { withCredentials: true }
      )
        .then(response => {
          let data: any = response.data;
          if (data.success) {
            let preview: any = data.result.preview;
            if (preview.jobStatus === 0) {
              this.watch(dispatch, preview.continueToken);
            }
            else if (preview.jobStatus === -1) {
              dispatch(
                getMembershipFullPreview(
                  this.userId,
                  this.cohortId,
                  this.ruleId,
                  this.jobId,
                  this.ruleVersion,
                  this.cohortName
                )
              );
            } else {
              getCohortOverview({ cohortName: this.cohortName }).then((response: any) => {
                if (response.data.success) {
                  dispatch(addCohortOverview(response.data.result.overview))
                  let mdata = {
                    cohortId: this.cohortId,
                    startIndex: 0,
                    count: 20,
                    filter: {
                      searchString: "",
                      searchField: ["lastName", "firstName", "patientId"],
                      complyFilter: null
                    },
                    sortBy: ""
                  };
                  getCohortMembers(mdata)
                    .then(response => {
                      if (response.data.success) {
                        let result = response.data.result;
                        let pagination = {
                          current: 1,
                          total: result.total
                        };
                        dispatch(
                          addCohortMembers({
                            cohortId: this.cohortId,
                            pagination: pagination,
                            members: result.summary
                          })
                        );
                      }
                    })
                    .catch(error => {
                      dispatch( expiryCheck(error));
                     });
                }
              })
            }
            dispatch(
              updateIncrementalStatus(
                this.cohortId,
                preview.executionRate,
                preview.jobStatus,
                preview.successCount
              )
            );
          }
        })
        .catch(error => {
          dispatch( expiryCheck(error));
          console.log(error);
        });
    }, PROGRESS_TIMER);
  }
}
