
import moment from "moment";
import { setPreference, updateCurrentUserPreference } from "src/App/Preferences/Actions";
import { getCurrentUserId } from "src/App/User/Current/Setters";
import store from "src/store";
import { ICalendarSettings } from "../ScheduleReducers/ScheduleDetailsReducer/types";
import { setSchedulerSubtitle } from "./ScheduleDetailActions/ScheduleDetailAction";

export const changeCalendarSettings = async (value: ICalendarSettings) => {
    const userId = getCurrentUserId()
    const userPreferenceInfo = {
        userId,
        preferences: [
            {
                id: 3037,
                userId,
                name: 'CalendarSettings',
                value: JSON.stringify(value),
                defaultValue: ''
            }
        ]
    };
    try {
        updateCurrentUserPreference(userPreferenceInfo.preferences);
        const response = await setPreference(userPreferenceInfo);
        const data: any = response.data;
        if (data.success && data.result.success) {
        }
    } catch (error) {
        console.log({ error });
    }
};

export const setScheduleTabSubtitle = (currentDateRange: any) => {
    let dateString = '';
    if (
        currentDateRange[0] &&
        currentDateRange[1] &&
        moment(currentDateRange[0]).format('MM/DD/YYYY') ===
        moment(currentDateRange[1]).format('MM/DD/YYYY')
    ) {
        let month = moment(currentDateRange[0]).format('MMM');
        let day = moment(currentDateRange[0]).format('D');
        dateString = month + ' ' + day;
    } else {
        if (
            currentDateRange[0] &&
            moment(currentDateRange[0]).format('MMM') ===
            moment(currentDateRange[0]).format('MMM')
        ) {
            let month = moment(currentDateRange[0]).format('MMM');
            let startDay = moment(currentDateRange[0]).format('D');
            let endDay = moment(currentDateRange[1]).format('D');
            dateString = month + ' ' + startDay + ' - ' + endDay;
        } else {
            if (currentDateRange[0] && currentDateRange[1]) {
                let monthStart = moment(currentDateRange[0]).format('MMM');
                let monthEnd = moment(currentDateRange[1]).format('MMM');
                let startDay = moment(currentDateRange[0]).format('D');
                let endDay = moment(currentDateRange[1]).format('D');
                dateString =
                    monthStart + ' ' + startDay + ' - ' + monthEnd + ' ' + endDay;
            }
        }
    }
    store.dispatch(setSchedulerSubtitle(dateString));
}
