import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    background-color: white;
    display: flex;
    flex: 1;
    flex-direction: column;
`

export const FormAbsoluteBlock = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: auto;
    padding: 16px;
`

export const FormBlock = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    border: 16px solid #E6E6E6;
`;