import styled from 'styled-components';
import Table from 'antd/lib/table';
import { NavLink } from 'react-router-dom';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

////////////////////////
export const input_disabled_text_color: any = '#CCCCCC';
export const input_placeholder_text_color: any = '#666666';
export const input_active_text_color: any = '#333333';
export const input_normal_bg_color: any = '#f7f7f7';
export const input_active_bg_color: any = '#ffffff';
export const blue_color: any = globalStyleVariables.newBrandingPrimary200;
export const border_color = '#E6E6E6';
// export const active_color = '#53C82E';
export const active_color = globalStyleVariables.newBrandingPrimary200;
export const background_active_color = 'rgba(246,252,245,99)';
export const box_shadow = 'rgba(19,65,98,0.06)';
export const red_lines = '#FF2828';
export const black_color = '#000000';
export const mandatory_color = '#F9B40B';
export const gray_background_color: any = '#F7F7F7';
export const gray_font_color: string = '#333333';
export const white_color: string = '#FFFFFF';
export const font_color: string = '#666666';
export const green_radio: string = '#40B81A';
export const inactive_text: string = globalStyleVariables.brandGray;
export const gray_border: string = '#E6E6E6';
export const blue_background: string = '#F3F8FA';
export const active_background: string = '#F6FCF5';
export const red_background: string = '#FFEAEA';
////////////////////////

export const drop_down_style: any = {
  borderBottom: '1px solid ' + blue_color,
  borderLeft: '1px solid ' + blue_color,
  borderRight: '1px solid ' + blue_color,
  zIndex: 10000
};

export const IconContainer = styled.span`
  padding: 0 5px;
`;
///////////////////

export const Box: any = styled.div`
  display: flex;
  align-items: center;

  &.schedule-content-box {
    height: 100%;
  }

  .all-providers-container {
    height: 100%;
  }

  .review-button {
    margin: 0 10px 0 0;
  }

  .ant-checkbox-checked {
    background-color: ${globalStyleVariables.newBrandingPrimary200} !important;
    border-color: ${globalStyleVariables.newBrandingPrimary200} !important;

    .ant-checkbox-inner {
      border-color: ${globalStyleVariables.newBrandingPrimary200} !important;
      background-color: ${globalStyleVariables.newBrandingPrimary200} !important;
    }
  }

  .ant-checkbox-inner {
    border-color: ${globalStyleVariables.newBrandingPrimary200} !important;
  }

  .loader {
    border: 6px solid ${globalStyleVariables.newBrandingPrimary10010};
    border-radius: 50%;
    border-top: 6px solid ${globalStyleVariables.newBrandingPrimary200};
    border-right: 6px solid ${globalStyleVariables.newBrandingPrimary200};
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1.2s linear infinite; /* Safari */
    animation: spin 1.2s linear infinite;
  }

  .little_loader {
    border: 3px solid ${globalStyleVariables.newBrandingPrimary10010};
    border-radius: 50%;
    border-top: 3px solid ${globalStyleVariables.newBrandingPrimary200};
    border-right: 3px solid ${globalStyleVariables.newBrandingPrimary200};
    width: 32px;
    height: 32px;
    -webkit-animation: spin 1.2s linear infinite; /* Safari */
    animation: spin 1.2s linear infinite;
  }

  &.all-providers-container {
    padding: 0;
  }
`;

export const BoxMessages: any = styled.div`
  .ant-checkbox-checked {
    background-color: ${globalStyleVariables.newBrandingPrimary200} !important;
    border-color: ${globalStyleVariables.newBrandingPrimary200} !important;

    .ant-checkbox-inner {
      border-color: ${globalStyleVariables.newBrandingPrimary200} !important;
      background-color: ${globalStyleVariables.newBrandingPrimary200} !important;
    }
  }

  .ant-checkbox-inner {
    border-color: ${globalStyleVariables.newBrandingPrimary200} !important;
  }

  .loader {
    border: 6px solid ${globalStyleVariables.newBrandingPrimary10010};
    border-radius: 50%;
    border-top: 6px solid ${globalStyleVariables.newBrandingPrimary200};
    border-right: 6px solid ${globalStyleVariables.newBrandingPrimary200};
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1.2s linear infinite; /* Safari */
    animation: spin 1.2s linear infinite;
  }
`;

export const ColumnBox: any = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  &.all-providers-view {
    height: 100%;
    padding: 0;
  }

  .radio-button-focus {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
`;

export const DoubleColumn: any = styled.div`
  display: flex;
  width: 100%;

  div:first-child {
    margin-right: 4px;
  }
`;

export const DoubleColumnBox: any = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  width: 50%;
`;

export const LoadingText: any = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #666666;
  padding: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DataTable = styled(Table)`
  background: #fff;
  width: 100%;
  position: relative;

  .ant-table-tbody > tr:hover > td {
    background-color: rgba(230, 230, 230, 0.3) !important;
  }

  .ant-table-tbody > tr:hover > td > span > span {
    color: ${blue_color} !important;
  }

  .ant-table-tbody > tr > td > span > span {
    max-width: 150px;
  }

  .ant-table-tbody > tr > td > span > span > span {
    max-width: 150px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    mark {
      max-width: 150px;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .ant-table-tbody > tr {
    color: #666666;
    cursor: pointer;

    svg {
      .arrow-icon-circle {
        fill: #ffffff;
      }

      .arrow-icon-path {
        fill: #666666;
      }
    }
  }

  .ant-table-tbody > tr:hover {
    background-color: rgba(230, 230, 230, 0.3) !important;

    svg {
      .arrow-icon-circle {
        fill: #ffffff !important;
      }

      .arrow-icon-path {
        fill: ${blue_color};
      }
    }
  }

  .ant-table-row > td > span > span {
    display: flex;
    justify-content: flex-start;
  }

  .selected,
  .selected:hover {
    background-color: rgba(230, 230, 230, 0.3) !important;
    color: #ffffff;
  }

  .ant-table-thead > tr > th {
    background: transparent;
    color: #666666;
    font-weight: 500;
    border-bottom: 0.4rem solid #e8e8e8;
    font-family: Encode Sans Expanded;
    font-style: normal;
    text-transform: uppercase;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 1rem 1rem;
  }

  .ant-table-header {
    background: transparent;
    overflow: hidden !important;
    padding-bottom: 20px !important;
    margin-bottom: -20px !important;
  }
`;

export const DataTableCohortWidget = styled(DataTable)`
  .ant-table-tbody > tr:hover > td > div > div > span > span {
    color: ${blue_color} !important;
  }

  .ant-table-tbody > tr > td:nth-child(1) {
    padding: 1rem 1rem 1rem 3rem;
  }

  .ant-table-tbody > tr > td:nth-child(5) {
    padding: 0 0 0 2rem;
  }
`;

export const Container: any = styled.div`
  /* width: 100%;
  height: 100%; */
  display: flex;
  flex: 1;
  min-height: 40rem;
  min-width: 35rem;
`;

export const NavigationBar = styled(ColumnBox)`
  min-width: 220px;
  max-width: 220px;
  min-height: 300px;
  height: 100%;
  overflow: hidden;
  transition: width 0.4s;
  transition-timing-function: ease-out;

  /* @media (max-width: 900px) {
    width: 0;
    min-width: 0;
  } */
`;

export const NavigationItem = styled(Box)`
  min-height: 50px;
  min-height: fit-content;
  width: 100%;

  a {
    border-left: 0.2rem solid transparent;
  }

  cursor: pointer;
  text-transform: capitalize;

  .active-nav-link {
    .active-nav-item-body {
      color: #333333;

      .active-nav-item-title {
        font-weight: bold;
      }

      .active-nav-item-content,
      .active-nav-cohort {
        opacity: 1;
      }
    }
  }

  .inactive-nav-link {
    border-left: 0.2rem solid transparent;
  }
`;

export const NavigationTitle = styled(Box)`
  white-space: nowrap;
  height: 3rem;
  font-family: Encode Sans Expanded;
  font-weight: 500;
  line-height: 2.8rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #ababab;
`;
export const NavigationLink = styled(NavLink)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none !important;

  &.active-nav-link {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const NavigationItemBody = styled(ColumnBox)`
  padding: 1.2rem 1rem 1rem 2rem;
  font-family: Lato;
  width: 100%;
  height: 100%;
  justify-content: center;
  color: #666666;
  position: relative;

  .loader {
    border: 2px solid ${globalStyleVariables.newBrandingPrimary10010} !important;
    border-radius: 50%;
    border-top: 2px solid ${globalStyleVariables.newBrandingPrimary200} !important;
    width: 14px !important;
    height: 14px !important;
    -webkit-animation: spin 1.2s linear infinite; /* Safari */
    animation: spin 1.2s linear infinite;
    border-right: 2px solid ${globalStyleVariables.newBrandingPrimary200} !important;
  }
`;
export const NavigationItemTitle = styled(Box)`
  line-height: 1.8rem;
  font-size: 1.5rem;
  /*text-transform: capitalize;*/
  text-transform: none;
`;
export const NavigationMember = styled(Box)`
  line-height: 1.8rem;
  font-size: 1.5rem;
  text-transform: none;
  width: 80%;
`;
export const NavigationIcon = styled(Box)`
  color: #666666;

  svg {
    stroke: currentColor;
  }
`;
export const NavigationNoDataIcon = styled(Box)`
  color: #666666;

  svg {
    stroke: currentColor;
  }

  width: 8%;
  display: inline-block;
`;
export const NavigationRightArrowIcon = styled.div`
  width: 5%;
  display: inline-block;
  margin-top: -4px;
`;
export const NavigationItemContent = styled(ColumnBox)`
  line-height: 1.6rem;
  font-size: 1.2rem;
  margin-top: 0.3rem;
  opacity: 0.75;
  text-transform: capitalize;
`;
export const LightGreenText = styled(Box)`
  color: ${globalStyleVariables.green};

  .eye-icon-path {
    stroke: currentColor;
  }
`;
export const BrandColorText = styled(Box)`
  color: ${globalStyleVariables.newBrandingPrimary200};
`;
export const LightOrangeText = styled(Box)`
  color: #f9b40b;
`;

export const ActionArrow: any = styled.span`
  color: #cccccc;

  svg > path {
    fill: #666666;
  }

  cursor: pointer;

  :hover,
  :focus,
  :active {
    color: ${blue_color};
  }

  :hover,
  :focus,
  :active svg > path {
    fill: ${blue_color};
  }

  svg {
    fill: currentColor;
  }
`;
export const ActionDelete: any = styled.span`
  height: 32px;
  width: 32px;
  border-radius: 22px;
  background-color: #ffffff;
  color: #cccccc;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;

  :hover,
  :focus,
  :active {
    color: #ff2828;
  }

  svg {
    stroke: currentColor;
  }
`;

export const SearchWrapperActivities = styled.div`
  min-width: 166px;
  width: 30vh;
  /* min-width: 250px;
  width: 35vh; */
  background-color: #f3f3f3;
  height: 35px;
  border-radius: 3px;
  margin-left: 2vh;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const PopUpConfirmationButton: any = styled.button`
  border: 2px solid #45b4ec;
  color: #1984cb;
  background-color: #ffffff;
  font-size: 14px;
  border-radius: 21px;
  min-width: 100px;
  max-width: 120px;
  width: auto;
  height: auto;
  min-height: 30px;
  max-height: 40px;
  margin-top: 10px;
`;

export const DCEmptyTextFull: any = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: black; */
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
`;
export const SearchContainer = styled.div`
  display: flex;
  min-height: 30px;
  height: 4rem;
  font-family: Lato;

  .ant-input {
    border-radius: 100px;
    background-color: ${input_normal_bg_color};
  }

  .ant-input::placeholder {
    color: ${input_placeholder_text_color};
  }

  .ant-input:hover {
    border-color: ${(props: { isFilled?: boolean }) =>
      props.isFilled ? '#333333 !important' : blue_color + ' !important'};
  }

  .ant-input:focus {
    border-color: ${blue_color} !important;
    background-color: ${input_active_bg_color};
  }

  .ant-input:focus::placeholder {
    color: #cccccc;
  }
`;
export const FilterContainer = styled.div`
  margin-left: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const DisplayEmpty = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 30%;
`;
export const SelectItem = styled.div`
  margin: 10px 2%;
  color: #8a7968;
  display: flex;
  align-items: center;
  border-radius: 20px;
  max-width: 450px;
  min-width: 340px;
  max-height: 50px;
`;

export const CommonButton = styled.div`
  display: flex;
  margin-left: 16px;
`;

export const CreateButtonContainer = styled.div`
  display: flex;
  margin-left: 16px;
`;

export const Input: any = styled.input`
  width: 100%;
  min-width: 100%;
  height: 40px;
  background-color: ${input_normal_bg_color};
  color: #333333;
  border: ${(props: { valid: string }) =>
    props.valid ? '1px solid transparent' : '1px solid red'};
  outline: 0;
  font-family: Lato;
  font-style: normal;
  padding: 10px;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 0.5vh;

  &::placeholder {
    color: ${input_placeholder_text_color};
  }

  &:hover {
    border-color: ${(props: { valid: string; isFilled?: boolean }) =>
      props.isFilled ? input_active_text_color : blue_color};
  }

  &:focus::placeholder {
    color: ${input_disabled_text_color};
  }

  &:focus {
    border-color: ${blue_color};
    background-color: ${input_active_bg_color};
  }
`;

export const FileUploader: any = styled.input`
  position: absolute;
  flex: 1;
  opacity: 0;
  width: ${(props: any) => props.width || '32px'};
  height: ${(props: any) => props.height || '32px'};
  cursor: pointer;

  ::-webkit-file-upload-button {
    width: ${(props: any) => props.width || '32px'};
    height: ${(props: any) => props.height || '32px'};
    cursor: pointer;
  }

  &input {
    width: ${(props: any) => props.width || '32px'};
    height: ${(props: any) => props.height || '32px'};
    cursor: pointer;
  }
`;
