
import * as actionType from "../../RuleActions/ActionTypes";
import _ from 'lodash';

interface IAction {
    type: string;
    ruleId: string;
    componentId: number;
    immunizations: any;
    pagination: any;
    id: string;
}
export interface IImmunizationsStore {
    ruleId: string;
    componentId: number;
    pagination: any
    immunizations: any[];
}



const ImmunizationOptionReducer = (state: IImmunizationsStore[] = [], action: IAction) => {
    switch (action.type) {
        case actionType.ADD_IMMUNIZATIONS: {
            let newState = _.cloneDeep(state)
            let immunizationStore = newState.find((i: IImmunizationsStore) => i.ruleId === action.ruleId && i.componentId === action.componentId);
            if (immunizationStore) {
                if (action.pagination.current > 1) {
                    immunizationStore.immunizations = [...immunizationStore.immunizations, ...action.immunizations];
                }
                else {
                    immunizationStore.immunizations = action.immunizations;
                }
                immunizationStore.pagination = action.pagination;
            } else {
                let data: IImmunizationsStore = {
                    ruleId: action.ruleId,
                    componentId: action.componentId,
                    immunizations: action.immunizations,
                    pagination: action.pagination,
                }
                newState.push(data);
            }
            return newState;
        }
        case actionType.CLEAR_RULE_EDITOR: {
            let newState = _.cloneDeep(state)
            let iArray = newState.filter((i: IImmunizationsStore) => i.ruleId === action.id);
            if (iArray.length === 0) return newState;
            for (let i = 0; i < iArray.length; i++) {
                newState.splice(newState.indexOf(iArray[i]), 0);
            }
            return newState;
        }
        default:
            return state;
    }
};

export default ImmunizationOptionReducer;
