import React, { FC, useEffect, useState } from 'react';

import { IFullBuildingForm } from '../../Lists/Forms/store/types';
import { ISimpleBuildBlock } from '../../Lists/BuildingBlocks/store/types';
import * as buildingFormsActions from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/Actions';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Preview from 'src/App/Admin/Pages/FormBuilder/Edit/Form/Preview';

interface IProps {
  data: IFullBuildingForm;
}

const Component: FC<IProps> = ({ data }: IProps) => {
  const [buildingBlockList, setBuildingBlockList] = useState<
    Record<string, ISimpleBuildBlock>
  >({});
  const [loading, setLoading] = useState(false);
  const getBuildingBlocks = async () => {
    setLoading(true);
    const res = await buildingFormsActions.getBuildingBlocksList();
    if (res) {
      setBuildingBlockList(res);
    }
    setLoading(false);
  };
  useEffect(() => {
    getBuildingBlocks();
  }, []);
  return (
    <>
      {loading ? (
        <AbsoluteLoader />
      ) : (
        <Preview data={data} buildingBlockList={buildingBlockList} />
      )}
    </>
  );
};

export default React.memo(Component);
