import React, { FC } from 'react';

import { Star } from './styled';

interface IProps {}

const StarIcon = require('src/Framework/Common/Svg/messaging/star.svg').default;

const Component: FC<IProps> = ({}: IProps) => {
  return (
    <Star
      role="img"
      aria-label="Required"
      style={{
        WebkitMaskImage: `url(${StarIcon})`,
        maskImage: `url(${StarIcon})`,
        background: '#666666'
      }}
    />
  );
};

export default React.memo(Component);
