import React from "react";

export const SquaredCsv = (props: any) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M12.5 0.625V5.625H17.5" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.5 8.125V5.625L12.5 0.625H2.5V8.125" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.5 16.875V19.375H17.5V16.875" stroke="#111111" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.18744 11.5625C6.93744 11.5625 6.74994 11.6875 6.62494 11.875C6.49994 12.0625 6.43744 12.3125 6.43744 12.625C6.43744 13.3125 6.68744 13.625 7.24994 13.625C7.43744 13.625 7.56244 13.625 7.68744 13.5625C7.81244 13.5 7.99994 13.4375 8.12494 13.375V14.1875C7.81244 14.3125 7.49994 14.375 7.12494 14.375C6.56244 14.375 6.18744 14.25 5.87494 13.875C5.56244 13.5 5.43744 13.125 5.43744 12.5C5.43744 12.125 5.49994 11.8125 5.62494 11.5C5.74994 11.1875 5.93744 11 6.24994 10.875C6.56244 10.75 6.81244 10.625 7.18744 10.625C7.56244 10.625 7.93744 10.6875 8.31244 10.875L7.99994 11.625C7.87494 11.5625 7.74994 11.5 7.62494 11.5C7.49994 11.5 7.31244 11.5625 7.18744 11.5625Z" fill="#111111"/>
        <path d="M11.1876 13.3125C11.1876 13.5625 11.1251 13.75 11.0001 13.875C10.8751 14 10.7501 14.1875 10.5001 14.25C10.2501 14.3125 10.0626 14.375 9.75006 14.375C9.50006 14.375 9.31256 14.375 9.12506 14.3125C8.93756 14.25 8.81256 14.25 8.62506 14.125V13.25C8.81256 13.375 9.00006 13.4375 9.18756 13.5C9.37506 13.5625 9.56256 13.5625 9.75006 13.5625C9.87506 13.5625 10.0001 13.5625 10.0626 13.5C10.1251 13.4375 10.1876 13.375 10.1876 13.3125C10.1876 13.25 10.1876 13.25 10.1251 13.1875C10.0626 13.125 10.0626 13.1875 10.0001 13.125C9.93756 13.0625 9.81256 13 9.50006 12.875C9.25006 12.75 9.06256 12.6875 9.00006 12.5625C8.93756 12.4375 8.81256 12.375 8.75006 12.25C8.68756 12.125 8.68756 12 8.68756 11.8125C8.68756 11.5 8.81256 11.25 9.06256 11.0625C9.31256 10.875 9.62506 10.8125 10.0626 10.8125C10.4376 10.8125 10.8126 10.875 11.1876 11.0625L10.8751 11.8125C10.5626 11.6875 10.2501 11.5625 10.0001 11.5625C9.87506 11.5625 9.81256 11.5625 9.75006 11.625C9.68756 11.6875 9.68756 11.75 9.68756 11.8125C9.68756 11.875 9.75006 11.9375 9.81256 12C9.87506 12.0625 10.0626 12.125 10.3751 12.3125C10.6876 12.4375 10.8751 12.625 11.0001 12.75C11.1251 12.875 11.1876 13.0625 11.1876 13.3125Z" fill="#111111"/>
        <path d="M13.75 10.8125H14.8125L13.625 14.375H12.5L11.3125 10.8125H12.375L12.875 12.625C13 13 13 13.3125 13.0625 13.4375C13.0625 13.3125 13.125 13.1875 13.125 13C13.125 12.8125 13.1875 12.6875 13.1875 12.625L13.75 10.8125Z" fill="#111111"/>
        <path d="M19.375 8.125H0.625V16.875H19.375V8.125Z" stroke="#111111" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);
