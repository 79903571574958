import globalStyleVariables from "src/Framework/Styles/variables.module.scss";
import styled from "styled-components";

interface ILongPressLoader {
    percentage: number
}

export const LongPressLoader = styled.div<ILongPressLoader>`
${({ percentage }) => `
    position: absolute;
    top: 50%;
    left: 50%;
    width: 36px;
    height: 36px;
    margin: -18px 0 0 -18px;
    border-radius: 50%;
    opacity: 0;
    transition: all 1s ease-in-out;
    visibility: hidden;
    &.show {
        visibility: visible;
        opacity: 1;
        background: conic-gradient(${globalStyleVariables.brandBlue} ${percentage}%, #ccc ${percentage}%);
    }
`}
    
`
export const Container = styled.div`
    position: relative; 
`