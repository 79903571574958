import _ from 'lodash';
import { IStateReducer, IAction } from './types';

const prefix = 'FORM_BUILDER_HEADER_AND_FOOTER_';

export const actionsTypes = {
  SET: `${prefix}SET`,
  SET_LOADING: `${prefix}SET_LOADING`,
  SET_FILTERS: `${prefix}SET_FILTERS`,
  SET_UPDATES: `${prefix}SET_UPDATES`,
  SET_TABLE_SORT: `${prefix}SET_TABLE_SORT`
} as const;

const initialState: IStateReducer = {
  summary: {},
  loading: false,
  filters: {
    searchString: '',
    isInactive: false,
    showHeaders: true
  },
  sort: {
    sortBy: 'name',
    sortType: 'asc'
  }
};

const Reducer = (
  state: IStateReducer = _.cloneDeep(initialState),
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case actionsTypes.SET: {
      return {
        ...state,
        summary: action.payload
      };
    }
    case actionsTypes.SET_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };
    }
    case actionsTypes.SET_UPDATES: {
      return {
        ...state,
        summary: { ...state.summary, ...action.payload }
      };
    }
    case actionsTypes.SET_TABLE_SORT:
      return { ...state, sort: action.payload };
    default:
      return state;
  }
};

export default Reducer;
