import * as actionType from "../../RuleActions/ActionTypes";
import _ from 'lodash';

interface IAction {
    ruleId: string;
    type: string;
    payload: any;
    id: string
}

const RuleDefinitionActionReducer = (
    state: any[] = [],
    action: IAction
) => {
    switch (action.type) {
        case actionType.UPDATE_ACTION_STATUS: {
            let newState = _.cloneDeep(state);
            let actionStore = newState.find((s: any) => s.ruleId === action.ruleId);
            if (!actionStore) {
                let actionObj = {
                    ruleId: action.ruleId,
                    currentActionIndex: 0,
                    editorActions: [action.payload]
                }
                newState.push(actionObj);
                return newState;
            }
            if (actionStore.currentActionIndex > -1) {
                actionStore.editorActions.splice(0, actionStore.currentActionIndex);
            }
            actionStore.currentActionIndex = 0;
            actionStore.editorActions.unshift(action.payload);
            return newState;
        }
        case actionType.UNDO_UPDATE_ACTION: {
            let newState = _.cloneDeep(state);
            let actionStore = newState.find((s: any) => s.ruleId === action.ruleId);
            if (!actionStore) return newState;
            actionStore.currentActionIndex = actionStore.currentActionIndex + 1;
            return newState;
        }
        case actionType.REDO_UPDATE_ACTION: {
            let newState = _.cloneDeep(state);
            let actionStore = newState.find((s: any) => s.ruleId === action.ruleId);
            if (!actionStore) return newState;
            actionStore.currentActionIndex = actionStore.currentActionIndex - 1;

            return newState;
        }
        case actionType.CLEAR_RULE_EDITOR: {
            let newState = _.cloneDeep(state);
            let index = newState.findIndex((s: any) => s.ruleId === action.id);
            if (index === -1) return newState;
            newState.splice(index, 1);
            return newState;
        }

        default:
            return state;
    }
};
export default RuleDefinitionActionReducer;

