import store from 'src/store';
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"
import ApiSubscription, { BucketsList } from "src/Framework/ApiSubscription";

import { IAssessment } from './types'

import { actionsTypes } from './Reducer'

export const SubscriptionData = (patientId: string | number) => ({
    bucket: BucketsList.patientAssessment,
    filter: `${BucketsList.patientAssessment}.PatientId == ${patientId}`
})

export const onChangeSort = (patientId: string, payload: any) => {
    store.dispatch({
        type: actionsTypes.SET_SORT,
        payload,
        patientId
    })
}

const setAssessments = (patientId: string | number, payload: any) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload,
        patientId
    })
}

export const onCreateUpdateItem = (patientId: string | number, payload: any) => {
    const data = { ...(store.getState().assessments[patientId]?.summary || {}) }
    if (data[payload.id]) {
        data[payload.id] = { ...data[payload.id], ...payload }
    } else {
        data[payload.id] = payload
    }
    setAssessments(patientId, data)
}

export const get = async (patientId: string) => {
    try {
        const req = SubscriptionData(patientId)
        const alreadySubscribed = ApiSubscription.instance.isSubscribed(
            req
        );
        if (alreadySubscribed) return
        const response = await ApiSubscription.instance.FindAndSubscribe<IAssessment>(req)
        if (response?.success) {
            setAssessments(patientId, response.data)
            return response
        }
        return
    } catch (e) {
        return
    }
}

export const post = async (data: Partial<IAssessment>): Promise<boolean | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(`${ApiUrl.GET_ASSESSMENTS}`, data, {
            withCredentials: true,
            interceptError: true
        })
        if (response.data.success) {
            return true
        }
        return
    } catch (e) {
        return
    }
}

export const patch = async (data: Partial<IAssessment>): Promise<boolean | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.patch(`${ApiUrl.GET_ASSESSMENTS}/${data.id}`, data, {
            withCredentials: true,
            interceptError: true
        })
        if (response.data.success) {
            return true
        }
        return
    } catch (e) {
        return
    }
}