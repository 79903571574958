import React from "react";

interface IProps {
    stroke?: string;
}

export const LockGraySvg = ({ stroke }: IProps) => (
<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 15.5H1.5C0.948 15.5 0.5 15.052 0.5 14.5V7.5C0.5 6.948 0.948 6.5 1.5 6.5H12.5C13.052 6.5 13.5 6.948 13.5 7.5V14.5C13.5 15.052 13.052 15.5 12.5 15.5Z" stroke="#666666" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.5 6.5V4C3.5 2.067 5.067 0.5 7 0.5C8.933 0.5 10.5 2.067 10.5 4V6.5" stroke="#666666" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7 12.5C7.82843 12.5 8.5 11.8284 8.5 11C8.5 10.1716 7.82843 9.5 7 9.5C6.17157 9.5 5.5 10.1716 5.5 11C5.5 11.8284 6.17157 12.5 7 12.5Z" stroke="#666666" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);
