import { notification } from 'antd';
import React, { FC, useCallback, useRef } from 'react';

import { ILegacyForm } from '../Forms';
import { setFormDetailsV2 } from '../FormsActions';
import LegacyForm from '../LegacyForm';
import * as patientFormsActions from '../FormList/PatientFormActions';
import { ISendData } from '../LegacyForm/FormHeader';
import { IGetFormValidation } from 'src/App/FormBuilder/FormContainer';

interface IProps {
  match?: any;
  formSelected: ILegacyForm;
  onRemove?: () => any;
  getFormValidation?: IGetFormValidation;
  readOnly?: boolean;
  hideInFormHeader?: boolean;
  hideRemoveButton?: boolean;
}

const Component: FC<IProps> = ({
  match,
  formSelected,
  onRemove,
  getFormValidation,
  readOnly,
  hideInFormHeader,
  hideRemoveButton
}: IProps) => {
  const formSubmission = useRef(null);
  const onClickSave = useCallback(
    async (value: ILegacyForm) => {
      if (!formSubmission.current) {
        return;
      }
      if (value.isActive) {
        const res = await setFormDetailsV2(
          value?.seedId,
          formSubmission.current,
          formSelected.patientId
        );
        if (res) {
          notification.success({
            message: 'Form saved'
          });
        }
      }
    },
    [formSelected.patientId]
  );
  const onChangeFormSubmission = useCallback((value: any) => {
    formSubmission.current = value;
  }, []);
  const onClickSend = async (data: ISendData, form: ILegacyForm) => {
    return await patientFormsActions.patchPatientForms(
      { ...data, seedId: form.seedId },
      formSelected.patientId
    );
  };
  const onClickRemove = useCallback(() => {
    if (onRemove) {
      onRemove();
    }
  }, []);
  return (
    <LegacyForm
      key={formSelected.seedId}
      formSelected={formSelected}
      match={match}
      patientId={`${formSelected.patientId}`}
      onClickSave={onClickSave}
      onChangeFormSubmission={onChangeFormSubmission}
      onRemove={onClickRemove}
      onClickSend={onClickSend}
      getFormValidation={getFormValidation}
      readOnly={readOnly}
      hideInFormHeader={hideInFormHeader}
      hideRemoveButton={hideRemoveButton}
    />
  );
};

export default React.memo(Component);
