import { ICohortSummary } from "../Models/Cohort";
import { ICohort } from "../Models/Cohort";
import { CohortProgress } from "./CreationPropcess";
import { APIHandler } from "../../../Framework/Communication/ServerProxy";
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { getCohortOverview, addCohortOverview } from 'src/Activities/Cohort/Reducer';
import { getCohortMembers, addCohortMembers } from 'src/Activities/Cohort/Member/Reducer';
import { expiryCheck } from 'src/App/LoginPage/store/Actions';

export const ADD_COHORTS = "ADD_COHORTS";
export const DELETE_COHORT = "DELETE_COHORT";
export const UPDATE_INCREMENTAL_STATUS = "UPDATE_INCREMENTAL_STATUS";
export const UPDATE_COHORT_LIST = "UPDATE_COHORT_LIST";
export const UPDATE_SELECTED_COHORT = "UPDATE_SELECTED_COHORT";
export const ADD_RULE_DEFINITION_COHORT = "ADD_RULE_DEFINITION_COHORT";
export const CLEAR_COHORT_MANAGER = "CLEAR_COHORT_MANAGER";

export const addCohorts = (
  cohortList: ICohortSummary, pagination: any
) => {
  return {
    type: ADD_COHORTS,
    cohortList: cohortList,
    pagination: pagination
  };
};

export const deleteCohort = (cohortId: string) => {
  return {
    type: DELETE_COHORT,
    cohortId: cohortId
  };
};
export const addRuleDefinition = (cohortRuleDefinition: any) => {
  return {
    type: UPDATE_SELECTED_COHORT,
    cohortRuleDefinition: cohortRuleDefinition
  };
};

export const updatCohortList = (cohort: ICohort) => {
  return {
    type: UPDATE_COHORT_LIST,
    cohort: cohort
  };
};

export const updateSelectedCohort = (cohortId: string) => {
  return {
    type: UPDATE_SELECTED_COHORT,
    cohortId: cohortId
  };
};

export const updateIncrementalStatus = (
  cohortId: string,
  executionRate: number,
  jobStatus: number,
  successCount: number
) => {
  return {
    type: UPDATE_INCREMENTAL_STATUS,
    cohortId: cohortId,
    executionRate: executionRate,
    jobStatus: jobStatus,
    successCount: successCount
  };
};

export const getCohortList = (
  currentPage = 1,
  count = 20,
  sortBy: any,
  filter: any,
  currentPagination?: any,
  callBack?: any
) => {
  let paginationDetails = {
    startIndex: (currentPage - 1) * count,
    count: count,
    sortBy: sortBy,
    filter: filter
  };

  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(ApiUrl.GET_ALL_COHORTS, paginationDetails, { withCredentials: true })
      .then(response => {
        if (callBack) {
          callBack();
        }
        let data: any = response.data;
        if (data.success) {
          let pagination = {
            ...currentPagination,
            total: data.result.total,
            current: currentPage
          };
          dispatch(addCohorts(data.result.summary, pagination));
        }
      })
      .catch(error => {
        if (callBack) callBack();
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};



export const createCohort = (
  cohortName: string,
  cohortDescription: string,
  ruleId: string,
  ruleVersion: number
) => {
  let newcohortInfo: any = {
    name: cohortName,
    description: cohortDescription,
    membershipId: ruleId,
    ruleVersion: ruleVersion
  };
  return APIHandler.AxiosInstance.post("/Cohort/CreateCohort", newcohortInfo, { withCredentials: true });
};

export const IsCohortExist = (cohortName: any) => {
  return APIHandler.AxiosInstance.post("/Cohort/IsCohortExist", { "cohortName": cohortName }, { withCredentials: true });
}

export const updateCohort = (
  cohortName: string,
  cohortDescription: string,
  ruleId: string,
  cohortId: string,
  ruleVersion: any
) => {
  let updatedCohortInfo: any = {
    name: cohortName,
    description: cohortDescription,
    membershipId: ruleId,
    ruleVersion: ruleVersion
  };
  return APIHandler.AxiosInstance.post(
    ApiUrl.UPDATE_COHORT + cohortId,
    updatedCohortInfo, { withCredentials: true }
  );
};


export const getMembershipFullPreview = (
  userId: string,
  cohortId: string,
  ruleId: string,
  jobId: string,
  ruleVersion: number,
  cohortName: string
) => {
  let data = {
    userId: userId,
    cohortId: cohortId,
    memberId: ruleId,
    jobId: jobId,
    memRuleVersion: ruleVersion
  };
  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(ApiUrl.GET_MEMBERSHIP_FULL_PREVIEW, data, { withCredentials: true })
      .then(response => {
        let data: any = response.data;
        if (data.success) {
          let preview: any = data.result.preview;
          if (preview.jobStatus === 0) {
            let incrementProcess = new CohortProgress(
              cohortId,
              userId,
              ruleId,
              jobId,
              ruleVersion,
              cohortName
            );
            incrementProcess.watch(dispatch, preview.continueToken);
          } else {
            getCohortOverview({ cohortName: cohortName }).then((response: any) => {
              if (response.data.success) {
                dispatch(addCohortOverview(response.data.result.overview));
                let mdata = {
                  cohortId: cohortId,
                  startIndex: 0,
                  count: 20,
                  filter: {
                    searchString: "",
                    searchField: ["lastName", "firstName", "patientId"],
                    complyFilter: null
                  },
                  sortBy: ""
                };
                getCohortMembers(mdata)
                  .then(response => {
                    if (response.data.success) {
                      let result = response.data.result;
                      let pagination = {
                        current: 1,
                        total: result.total
                      };
                      dispatch(
                        addCohortMembers({
                          cohortId: cohortId,
                          pagination: pagination,
                          members: result.summary
                        })
                      );
                    }
                  })
                  .catch(error => {
                    dispatch(expiryCheck(error));
                  });
              }
            });
          }
          dispatch(
            updateIncrementalStatus(
              cohortId,
              preview.executionRate,
              preview.jobStatus,
              preview.successCount
            )
          );

        }
      })
      .catch(error => {
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};
export const removeCohort = (cohortId: string) => {
  return APIHandler.AxiosInstance.post("Cohort/RemoveCohort", { cohortId: cohortId }, { withCredentials: true });
};
