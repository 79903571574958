import _ from "lodash";
import {
  ADD_RULE_DETAILS,
  CHANGE_RULE_ENABLE_DISABLE,
  UPDATE_DRAFT_STATUS,
  REMOVE_DRAFT_FROM_UI,
  ENABLE_DISABLE_RULE
} from "../../RuleActions/RuleDetailsActions/RuleDetailsAction";
import { CLEAR_RULE_EDITOR } from '../../RuleActions/ActionTypes';

const initialState: any[] = [];

interface IAction {
  type: string;
  ruleId: string;
  payload: any;
  isEnabled: boolean;
  id: string;
}

const RuleDetailsReducer = (state: any[] = initialState, action: IAction) => {
  switch (action.type) {
    case ADD_RULE_DETAILS: {
      let newState = _.cloneDeep(state);
      let ruleDetails: any = newState.find(
        (rule: any) => rule.result.ruleId === action.payload.result.ruleId
      );
      if (!ruleDetails) return [...newState, action.payload];
      newState[newState.indexOf(ruleDetails)] = action.payload;
      return newState;
    }

    case CHANGE_RULE_ENABLE_DISABLE: {
      let newState = _.cloneDeep(state);
      let changeRuleDetails: any = newState.find(
        (rule: any) => rule.result.ruleName === action.payload.ruleName
      );
      changeRuleDetails.result.enabled = !changeRuleDetails.result.enabled;
      return newState;
    }
    case UPDATE_DRAFT_STATUS: {
      let newState = _.cloneDeep(state);
      let rule: any | undefined = newState.find(
        (r: any) => r.result.ruleId === action.ruleId
      );
      if (!rule) return newState;
      rule.result.hasDraft = true;
      return newState;
    }

    case REMOVE_DRAFT_FROM_UI: {
      let newState = _.cloneDeep(state);
      let rule: any | undefined = newState.find(
        (r: any) => r.result.ruleId === action.ruleId
      );
      if (!rule) return newState;
      rule.result.hasDraft = false;
      return newState;
    }

    case ENABLE_DISABLE_RULE: {
      let newState = _.cloneDeep(state);
      let changeRuleDetails: any = newState.find(
        (rule: any) => rule.result.ruleId === action.ruleId
      );
      changeRuleDetails.result.enabled = action.isEnabled;
      return newState;
    }
    case CLEAR_RULE_EDITOR: {
      let newState = _.cloneDeep(state);
      let index = state.findIndex((r: any) => r.result.ruleId === action.id);
      if (index === -1) return newState;
      newState.splice(index, 1);
      return newState;
    }
    default:
      return state;
  }
};

export default RuleDetailsReducer;