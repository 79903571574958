import React, { FC, PropsWithChildren } from 'react';
import { Collapse, CollapsePanelProps, CollapseProps } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import classnames from 'classnames';

import Icon from 'src/Framework/Controls/Icon';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import { Container } from './styled';

const { Panel } = Collapse;

interface ICollapseProps extends CollapseProps {
  containerClass?: string;
  uppercaseHeader?: boolean;
  noSidePaddings?: boolean;
  noHeaderSidePaddings?: boolean;
  noVerticalPaddings?: boolean;
  noHeaderVerticalPaddings?: boolean;
  noBorder?: boolean;
  icon?:
    | 'tableArrow'
    | 'roundIcon'
    | 'cleanIcon'
    | 'reverseIcon'
    | 'roundAnimIcon'
    | 'cleanAnimIcon'
    | 'reverseAnimIcon'
    | 'sideArrow';
}

interface ICollapseInterface extends React.FC<ICollapseProps> {
  Panel: typeof CollapsePanel;
}

const PanelA11y: FC<PropsWithChildren<CollapsePanelProps>> = (props) => {
  return <Panel {...props}>{props.children}</Panel>;
};

const Component: ICollapseInterface = (props) => {
  return (
    <Container className={props.containerClass}>
      <Collapse
        ghost={true}
        bordered={true}
        expandIconPosition="right"
        defaultActiveKey={[]}
        onChange={() => {}}
        expandIcon={
          props.icon &&
          ((collapseProps: any) => {
            switch (props.icon) {
              case 'tableArrow':
                return (
                  <span className={'anticon-right'}>
                    <Icon
                      onShowAnimation={{
                        rotate: !collapseProps.isActive
                      }}
                      color={globalStyleVariables.darkGrey}
                      containerStyles={{
                        width: 32,
                        height: 32
                      }}
                      size={20}
                      name={'arrowDesc'}
                    />
                  </span>
                );
              case 'roundAnimIcon':
                return (
                  <span className={'anticon-right'}>
                    <Icon
                      onShowAnimation={{
                        rotate: !collapseProps.isActive
                      }}
                      color={
                        collapseProps.isActive
                          ? 'white'
                          : globalStyleVariables.newBrandingPrimary200
                      }
                      containerStyles={{
                        backgroundColor: collapseProps.isActive
                          ? globalStyleVariables.newBrandingPrimary200
                          : globalStyleVariables.grey,
                        borderRadius: '50%',
                        width: 32,
                        height: 32,
                        padding: 10
                      }}
                      size={12}
                      name={'arrowUp'}
                    />
                  </span>
                );
              case 'roundIcon':
                return (
                  <span className={'anticon-right'}>
                    <Icon
                      rotate={collapseProps.isActive ? 0 : 180}
                      color={
                        collapseProps.isActive
                          ? 'white'
                          : globalStyleVariables.newBrandingPrimary200
                      }
                      containerStyles={{
                        backgroundColor: collapseProps.isActive
                          ? globalStyleVariables.newBrandingPrimary200
                          : globalStyleVariables.grey,
                        borderRadius: '50%',
                        width: 32,
                        height: 32,
                        padding: 10
                      }}
                      size={12}
                      name={'arrowUp'}
                    />
                  </span>
                );
              case 'cleanIcon':
                return (
                  <span className={'anticon-right'}>
                    <Icon
                      rotate={collapseProps.isActive ? 0 : 180}
                      color={globalStyleVariables.newBrandingPrimary200}
                      containerStyles={{
                        width: 32,
                        height: 32,
                        padding: 10
                      }}
                      size={12}
                      name={'arrowUp'}
                    />
                  </span>
                );
              case 'sideArrow':
                return (
                  <span className={'anticon-right'}>
                    <Icon
                      rotate={collapseProps.isActive ? 0 : -90}
                      color={globalStyleVariables.newBrandingPrimary200}
                      containerStyles={{
                        width: 32,
                        height: 32,
                        padding: 10
                      }}
                      size={12}
                      name={'arrowDown'}
                    />
                  </span>
                );
              case 'cleanAnimIcon':
                return (
                  <span className={'anticon-right'}>
                    <Icon
                      onShowAnimation={{
                        rotate: !collapseProps.isActive
                      }}
                      color={globalStyleVariables.newBrandingPrimary200}
                      containerStyles={{
                        width: 32,
                        height: 32,
                        padding: 10
                      }}
                      size={12}
                      name={'arrowUp'}
                    />
                  </span>
                );
              case 'reverseIcon':
                return (
                  <span className={'anticon-right'}>
                    <Icon
                      rotate={collapseProps.isActive ? 180 : 0}
                      color={globalStyleVariables.newBrandingPrimary200}
                      containerStyles={{
                        width: 32,
                        height: 32,
                        padding: 10
                      }}
                      size={12}
                      name={'arrowUp'}
                    />
                  </span>
                );
              case 'reverseAnimIcon':
                return (
                  <span className={'anticon-right'}>
                    <Icon
                      onShowAnimation={{
                        rotate: !collapseProps.isActive
                      }}
                      color={globalStyleVariables.newBrandingPrimary200}
                      containerStyles={{
                        rotate: '180deg',
                        width: 32,
                        height: 32,
                        padding: 10
                      }}
                      size={12}
                      name={'arrowUp'}
                    />
                  </span>
                );
              default:
                return (
                  <span className={'anticon-right'}>
                    <Icon
                      onShowAnimation={{
                        rotate: !collapseProps.isActive
                      }}
                      color={globalStyleVariables.newBrandingPrimary200}
                      containerStyles={{
                        width: 32,
                        height: 32,
                        padding: 10
                      }}
                      size={12}
                      name={'arrowUp'}
                    />
                  </span>
                );
            }
          })
        }
        {...props}
        className={classnames(
          {
            'title-uppercase': props.uppercaseHeader,
            'no-side-paddings': props.noSidePaddings,
            'no-vertical-paddings': props.noVerticalPaddings,
            'no-header-side-paddings': props.noHeaderSidePaddings,
            'no-header-vertical-paddings': props.noHeaderVerticalPaddings,
            'no-border': props.noBorder
          },
          props?.className && props?.className
        )}
      >
        {props.children}
      </Collapse>
    </Container>
  );
};
Component.Panel = PanelA11y;

export default Component;
