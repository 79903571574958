import { RequestHelper } from "src/Framework/ApiSubscription/RequestHelper";
import { GenericIdsTypes } from "../../utils";

import { setSummary } from './Setters'

type IData = any

interface IConstructor {
    genericTypeId: GenericIdsTypes
}

export class GenericTypeRequestHelper {
    genericTypeId: string
    RequestHelper: RequestHelper
    pathKey: string
    constructor({ genericTypeId }: IConstructor) {
        this.genericTypeId = genericTypeId
        this.pathKey = `${this.genericTypeId} - true`
        const SubscriptionData = () => {
            return ({
                bucket: this.genericTypeId,
                filter: "true"
            })
        }
        this.RequestHelper = new RequestHelper<IData>({
            link: this.genericTypeId,
            onCreateUpdateItem: () => { },
            subscription: {
                subscriptionData: SubscriptionData as any,
                setSummary: (data) => {
                    setSummary(data, this.genericTypeId as any)
                }
            }
        })
    }
}