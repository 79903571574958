import React, { FC, useRef, useEffect, useState } from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import * as formsActions from '../../../FormsActions';
import Modal, { IModalHandles } from './Modal';
import { ILegacyForm } from '../../../Forms';

interface IProps {
  currentForm: ILegacyForm;
  readOnly: boolean;
  onAppendAdded?: () => any;
}

const Component: FC<IProps> = ({
  currentForm,
  readOnly,
  onAppendAdded
}: IProps) => {
  const [appends, setAppends] = useState<any[]>([]);
  const ModalRef = useRef<IModalHandles>(null);
  const getData = async () => {
    const res = await formsActions.getAppendForm(currentForm.seedId);
    if (res) {
      setAppends(res);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Modal
        ref={ModalRef}
        currentForm={currentForm}
        appends={appends}
        onAdded={(res: any) => {
          getData();
          if (onAppendAdded) {
            onAppendAdded();
          }
        }}
      />
      <ButtonIcon
        name={ICONS_LIST.append}
        changeColorOnHover={true}
        onClick={() => {
          ModalRef.current?.show({});
        }}
        active={Object.values(appends).length !== 0}
        disabled={!readOnly}
        tooltip={
          !readOnly
            ? 'Only locked or final signed form can be appended'
            : 'Append'
        }
      />
    </>
  );
};

export default Component;
