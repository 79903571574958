import _ from 'lodash'

import { IAction, IStateReducer } from './types'

const prefix = 'ACTIVITY_TASKS_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_CATEGORY: `${prefix}SET_CATEGORY`,
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_LOADING: `${prefix}SET_LOADING`,
    SET_PAGINATION: `${prefix}SET_PAGINATION`,
    SET_SORT: `${prefix}SET_SORT`
}

const pageLength = 30;

const initialState: IStateReducer = {
    summary: {},
    currentCategory: null,
    loading: false,
    filters: {
        clinicIds: [],
        providers: []
    },
    pagination: {
        pageNumber: 0,
        pageLength
    },
    sort: {
        sortBy: 'date',
        sortType: 'desc'
    }

};

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET:
            return { ...state, summary: action.payload }
        case actionsTypes.SET_CATEGORY:
            return { ...state, currentCategory: action.payload }
        case actionsTypes.SET_LOADING:
            return { ...state, loading: action.payload }
        case actionsTypes.SET_PAGINATION:
            return { ...state, pagination: { ...state.pagination, ...action.payload } }
        case actionsTypes.SET_FILTER:
            return { ...state, filters: { ...state.filters, ...action.payload } }
        case actionsTypes.SET_SORT:
            return {
                ...state,
                sort: action.payload
            }
        default:
            return state;
    }
};

export default Reducer;
