import _ from 'lodash'
import { IAction, IStateReducer } from './types'

const prefix = 'TICKET_DETAILS_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_EXPIRED_REASONS: `${prefix}SET_EXPIRED_REASONS`,
}

export const initialData = {
    summary: [],
    sort: {
        sortBy: null,
        sortType: null
    }
}

const initialState: IStateReducer = {
    patients: {},
    typeExpireReasons: {}
};

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET_EXPIRED_REASONS:
            return {
                ...state,
                typeExpireReasons: action.payload
            }
        case actionsTypes.SET:
            if (state.patients[action.patientId]) {
                return {
                    ...state,
                    patients: {
                        ...state.patients,
                        [action.patientId]: {
                            ...state[action.patientId],
                            summary: action.payload
                        }
                    }
                }
            }
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...initialData,
                        summary: action.payload
                    }
                }
            }
        default:
            return state;
    }
};

export default Reducer;
