import _ from 'lodash';
import { IStateReducer, IAction } from './types';


const prefix = 'M1_FORM_RECORD_SUBMISSION_';

export const actionsTypes = {
    SET: `${prefix}SET`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

const initialState: IStateReducer = {
    summary: {}
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(initialState),
    action: IAction
): IStateReducer => {
    const actions: IActions = {
        [actionsTypes.SET]: () => {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    ...action.payload
                }
            };
        }
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
