import React from 'react';
import { Tooltip, Row, List as AntdList, Col } from 'antd';
import classNames from 'classnames';

import { FormSvg } from 'src/Framework/Common/Svg/FormSvg';
import { FormCheckedSvg } from 'src/Framework/Common/Svg/FormCheckedSvg';
import { LockGraySvg } from 'src/Framework/Common/Svg/LockGraySvg';
import { RestrictedSvg } from 'src/Framework/Common/Svg/RestrictedSvg';
import { InProgressSvg } from 'src/Framework/Common/Svg/InProgressSvg';
import { SignGraySvg } from 'src/Framework/Common/Svg/SignGraySvg';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { getClinicById } from 'src/Framework/Controls/Selectors/ClinicSelector';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';
import { formattedDate } from 'src/Framework/Shared/Shared';

import { IFormData, Type } from 'src/Activities/Forms/Forms';
import { goToFormViewer } from 'src/App/ActivityPanel/Store/ActivityActions';
import { FormViewerTypes } from 'src/App/FormViewer';

import * as container from './styled';

interface IProps {
  formsData: IFormData[];
  selectionChanged: (form: IFormData | null) => any;
  subjectId: string;
  match: any;
  formSelected?: IFormData;
}

class List extends React.Component<IProps> {
  public refCallback: any;
  public myRef: any;
  public myList: any;

  constructor(props: any) {
    super(props);
    this.myRef = React.createRef();
    this.myList = React.createRef();
  }

  componentDidUpdate(prevProps: any) {
    //leave it for now
    // if (prevProps.scroll !== this.props.scroll) {
    //   let list = document.getElementById('list') || null;
    //   if (list) {
    //     let refExist =
    //       this.myRef && this.myRef.current && this.myRef.current.offsetTop;
    //     if (refExist) {
    //       const scrollList = this.myRef.current.parentElement.offsetTop - 70;
    //       list.scrollTo(0, scrollList);
    //     } else {
    //       if (this.props.scroll >= 0) {
    //         list.scrollTo(0, this.props.scroll);
    //       }
    //     }
    //   }
    // }
  }

  selectHandler = (e: IFormData) => {
    this.props.selectionChanged(e);
  };

  completeForm(completed: boolean) {
    if (completed) {
      return (
        <Tooltip placement="top" title="Completed">
          <container.IconContainer>
            <FormCheckedSvg />
          </container.IconContainer>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip placement="top" title="Not Completed">
          <container.IconContainer color="#53C82E">
            <FormSvg />
          </container.IconContainer>
        </Tooltip>
      );
    }
  }

  statusForm = (status: any) => {
    switch (status) {
      case 'inProgress':
        return (
          <Tooltip placement="top" title="In Progress">
            <container.IconContainer>
              <InProgressSvg />
            </container.IconContainer>
          </Tooltip>
        );
      case 'signed':
        return (
          <Tooltip placement="top" title="Signed">
            <container.IconContainer>
              <SignGraySvg />
            </container.IconContainer>
          </Tooltip>
        );
      case 'locked':
        return (
          <Tooltip placement="top" title="Locked">
            <container.IconContainer>
              <LockGraySvg />
            </container.IconContainer>
          </Tooltip>
        );
      default:
        return <div />;
    }
  };

  invalidStatus(invalid: boolean, formTitle: string | null) {
    if (invalid) {
      return <container.Invalid>{formTitle || ''}</container.Invalid>;
    }
    return formTitle || '';
  }

  render() {
    const { formSelected, formsData, match } = this.props;
    return (
      <container.ListContainer id={'list'} ref={this.myList}>
        <AntdList
          dataSource={formsData}
          renderItem={(form) => {
            if (form.type === Type.LEGACY) {
              const active =
                formSelected?.type === Type.LEGACY
                  ? formSelected.seedId == form.seedId
                  : false;
              return (
                <AntdList.Item
                  key={form.seedId}
                  tabIndex={0}
                  role="button"
                  onKeyDown={onKeyDownAccessibility}
                  onClick={() => this.selectHandler(form)}
                  className={classNames({
                    active: active,
                    'list-item-content': true
                  })}
                >
                  <Row
                    align="middle"
                    justify="space-between"
                    id={`f${form.id}`}
                    ref={
                      match.params.formId === form.seedId
                        ? this.myRef
                        : undefined
                    }
                    style={{ flexWrap: 'nowrap', width: '100%' }}
                  >
                    <Col>
                      <Row align="middle" style={{ flexWrap: 'nowrap' }}>
                        {this.statusForm(form.status)}
                        <container.Title>
                          {this.invalidStatus(
                            form.status === 'invalid',
                            form?.templateForm?.name||''
                          )}
                        </container.Title>
                      </Row>
                    </Col>
                    <Col>
                      <Row align="middle" gutter={16}>
                        <Col>
                          <ButtonIcon
                            name={ICONS_LIST.letterL}
                            size={22}
                            containerProps={{ tabIndex: -1 }}
                          />
                        </Col>
                        {form.isRestricted && (
                          <Col>
                            <container.RightIcon>
                              <Tooltip
                                placement="top"
                                title="Restricted Access"
                              >
                                <container.IconWithoutBackContainer margin="0">
                                  <RestrictedSvg />
                                </container.IconWithoutBackContainer>
                              </Tooltip>
                            </container.RightIcon>
                          </Col>
                        )}
                        <Col>
                          <ButtonIcon
                            isButton={true}
                            label="open in new tab"
                            name={ICONS_LIST.newTab}
                            changeColorOnHover={true}
                            size={22}
                            onClick={() => {
                              goToFormViewer(
                                form.seedId,
                                FormViewerTypes.legacyForm
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <container.Body>
                    <container.Date>
                      {`${formattedDate(form.createdDts)} - ${
                        getClinicById(form.clinicId)?.name || ''
                      }`}
                    </container.Date>
                  </container.Body>
                </AntdList.Item>
              );
            }
            if (form.type === Type.M1) {
              const active =
                formSelected?.type === Type.M1
                  ? formSelected.id == form.id
                  : false;
              return (
                <AntdList.Item
                  key={`M1-${form.id}`}
                  tabIndex={0}
                  role="button"
                  onKeyDown={onKeyDownAccessibility}
                  onClick={() => this.selectHandler(form)}
                  className={classNames({
                    active: active,
                    'list-item-content': true
                  })}
                >
                  <Row
                    align="middle"
                    justify="space-between"
                    id={`f${form.id}`}
                    style={{ flexWrap: 'nowrap', width: '100%' }}
                  >
                    <Col>
                      <Row align="middle" style={{ flexWrap: 'nowrap' }}>
                        {this.statusForm(form.status)}
                        <container.Title>
                          {this.invalidStatus(
                            !form.isActive,
                            form.formTemplateName
                          )}
                        </container.Title>
                      </Row>
                    </Col>
                    <Col>
                      <ButtonIcon
                        isButton={true}
                        label="open in new tab"
                        name={ICONS_LIST.newTab}
                        size={22}
                        changeColorOnHover={true}
                        onClick={() => {
                          goToFormViewer(form.id, FormViewerTypes.m1Form);
                        }}
                      />
                    </Col>
                  </Row>
                  <container.Body>
                    <container.Date>
                      {`${formattedDate(form.createDts)} - ${
                        getClinicById(form.clinicId)?.name || ''
                      }`}
                    </container.Date>
                  </container.Body>
                </AntdList.Item>
              );
            }
            return null;
          }}
        />
      </container.ListContainer>
    );
  }
}

export default List;
