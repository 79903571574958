import React, { FC } from 'react';
import { Row } from 'antd';
import cn from 'classnames';

import { Header as Container, Title } from './styled';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

export interface IProps {
  headerProps?: any;
  headerBorder?: boolean;
  back?: boolean;
  onClickBack?: Function;
  title?: any;
  buttons?: React.ReactNode;
  customTitle?: any;
  isDragable?: boolean;
}

const BackIcon = require('src/Framework/Common/Svg/back.svg').default;

const Component: FC<IProps> = ({
  headerBorder,
  headerProps,
  back,
  onClickBack,
  buttons,
  title,
  customTitle,
  isDragable
}: IProps) => {
  return (
    <Container
      className={cn({
        headerBorder: headerBorder,
        handleDrag: isDragable
      })}
      {...headerProps}
    >
      {back && (
        <div
          tabIndex={0}
          aria-label="Back button"
          id="close-button"
          className="back-button"
          style={{
            WebkitMaskImage: `url(${BackIcon})`,
            maskImage: `url(${BackIcon})`
          }}
          onClick={() => onClickBack && onClickBack()}
          onKeyDown={onKeyDownAccessibility}
        />
      )}
      <Row align="middle" justify="space-between" style={{ flex: 1 }}>
        {title && <Title>{title}</Title>}
        {customTitle ? customTitle : null}
        {buttons && <Row align="middle">{buttons}</Row>}
      </Row>
    </Container>
  );
};

export default React.memo(Component);
