import React, { FC, useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';
import LazyLoader from 'src/Framework/Controls/LazyLoader';

import { Container } from './styled';
import { IDisplaySchema } from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/types';
import { defaultValueField } from '../utils';
import * as patientInformationActions from 'src/App/PatientInformation/store/Actions';
import { pathToFields } from '../M1PatientForm';
import {
  ReactObjectMemo,
  debouncer,
  usePreviousProps
} from 'src/Framework/util/helps';
import { validator } from './Form';

const LazyForm = React.lazy(
  () => import(/* webpackChunkName: "Form" */ './Form')
);

interface IValidator {
  message: string;
  level: string;
  path: string[];
  context: {
    validator: string;
    hasLabel: boolean;
    setting: boolean;
    key: string;
    label: string;
    value: string;
  };
}

export type IFormValidationData = {
  validationData: ReturnType<typeof validator>;
};

export type IGetFormValidation = (data: IFormValidationData) => any;

export type IChange = (
  submission: any,
  isValid: boolean,
  validator?: IValidator[],
  formReadyTrigger?: boolean,
  data?: IFormValidationData
) => any;

export interface FormIOInstance {
  setSubmission: (data: { data: any }) => any;
  root: any;
}

export interface IFormForPatientFields {
  assignedProviderId: number | null;
  subjectPatientId: number | null;
  clinicId: number | null;
}

export interface IProps {
  schema: any;
  submission: any;
  onChange: IChange;
  readOnly?: boolean;
  previewMode?: boolean;
  isAbsoluteContainer?: boolean;
  getFormioInstance?: (instance: FormIOInstance) => any;
  form?: IFormForPatientFields;
}

const findPatientFormComponentIds = (displaySchema: null | IDisplaySchema) => {
  if (!displaySchema) return [];
  const ids: {
    key: string;
    fields: string[];
  }[] = [];
  const find = (components: IDisplaySchema['components']) => {
    components.forEach((v) => {
      if (v.type === 'm1PatientForm') {
        if (v.key && v[defaultValueField]?.data) {
          ids.push({
            key: v.key,
            fields: Object.keys(v[defaultValueField].data)
          });
        }
      }
      if (v.columns) {
        v.columns.forEach((val) => {
          if (val.components) {
            find(val.components);
          }
        });
      }
    });
  };
  find(displaySchema.components);
  return ids;
};

const Component: FC<IProps> = (props: IProps) => {
  const debounce = useRef(debouncer(500));
  const initRender = useRef(true);
  const formInstance = useRef<FormIOInstance | null>(null);
  const {
    schema,
    previewMode,
    readOnly,
    isAbsoluteContainer,
    submission,
    onChange,
    form
  } = props;
  const prevForm = usePreviousProps(form);

  const getPatientfields = async () => {
    if (!form || _.isEqual(prevForm, form)) return;
    const data = _.cloneDeep(submission || {});
    const patientFormIds = findPatientFormComponentIds(schema);
    if (patientFormIds.length > 0) {
      if (form.assignedProviderId) {
        debounce.current(async () => {
          if (
            form.clinicId === null ||
            form.assignedProviderId === null ||
            form.subjectPatientId === null
          ) {
            return;
          }
          const patientInfo = await patientInformationActions.get({
            clinicId: form.clinicId,
            providerId: form.assignedProviderId,
            patientId: form.subjectPatientId
          });
          patientFormIds.forEach((value) => {
            value.fields.forEach((v) => {
              data[value.key] = {
                ...data[value.key],
                data: {
                  ...data[value.key]?.data,
                  [v]: _.get(patientInfo, pathToFields[v])
                }
              };
            });
          });
          if (formInstance.current) {
            const submissionData = { ...submission, ...data };
            formInstance.current.setSubmission({
              data: submissionData
            });
            const validated: any[] = validator(formInstance.current.root).value;
            onChange(submissionData, validated.length === 0);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (!initRender.current) {
      getPatientfields();
    }
    initRender.current = false;
  }, [form]);

  const getFormioInstance = useCallback(
    (value: FormIOInstance) => {
      if (!formInstance.current) {
        getPatientfields();
      }
      formInstance.current = value;
      if (props.getFormioInstance) {
        props.getFormioInstance(value);
      }
    },
    [props.getFormioInstance]
  );
  return !_.isEmpty(schema) ? (
    <div
      key={`previewMode=${previewMode}${readOnly}`}
      className="formio-form-container"
    >
      <Container
        className="absolute-container"
        isAbsoluteContainer={isAbsoluteContainer}
      >
        <LazyLoader>
          <LazyForm {...props} getFormioInstance={getFormioInstance} />
        </LazyLoader>
      </Container>
    </div>
  ) : null;
};

export default ReactObjectMemo(Component);
