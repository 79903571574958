export const ApiUrl = {
  UPDATE_WAITING_LIST: 'schedule/UpdateWaitingList',
  GET_CLINICS: 'Schedule/GetClinics',
  GET_SCHEDULE_SLICES: 'Schedule/GetScheduleSlices',
  GET_SCHEDULE_SLICE_EVENTS: 'Schedule/GetScheduleSliceEvents',
  GET_WAITING_GROUPS_FROM_CLINICS: 'Schedule/GetClinicWaitingLists',
  GET_WAITING_LISTS: '/WaitingList',
  GET_WAITING_PATIENTS_FROM_LISTCODES: 'Schedule/GetWaitingList',
  GET_SCHEDULE_REASONS: 'Schedule/GetScheduleReasons',
  GET_GROUP_OF_REASONS: 'Schedule/GetGroupScheduleReasons',
  GET_NEXT_AVAILABLE_EVENTS: 'Schedule/GetNextAvailableEvents',
  GET_SUBJECT_SUMMARY: 'Schedule/GetSubjectSummary',
  GET_SUBJECT_SEARCH: 'Subject/PerformSubjectSearch',
  GET_APPOINTMENT_HISTORY: 'Schedule/GetAppointmentHistory',
  GET_APPOINTMENT_HX: 'Schedule/GetAppointmentHx',
  GET_APPOINTMENT_LOG: 'Schedule/GetAppointmentLog',
  CREATE_SCHEDULE_EVENT: 'Schedule/CreateScheduleEvent',
  UPDATE_SCHEDULE_EVENT: 'Schedule/UpdateScheduleEvent',
  DELETE_SCHEDULE_EVENT: 'Schedule/CancelScheduleEvent',
  GET_SUBJECT_PREFERENCE_FIELDS: 'Schedule/GetSubjectPreferenceFields',
  GET_CATEGORY_LIST: 'Schedule/GetCategoryList',
  GET_FORMS_FROM_CLINICS: 'Schedule/GetScheduleTemplates',
  //GET_SCHEDULE_FORMS_METADATA: "TemplateFormRecord/GetScheduleFormsMetadata",
  CONFLICT_CHECK_FOR_SERIES_EVENT: 'Schedule/ConflictCheckForSeriesEvent',
  CONFLICT_CHECK_FOR_EVENTS: 'Schedule/ConflictCheckEvent',
  GET_PATIENTS_IN_CATEGORY: 'Subject/GetPatientsForCategory',
  REMOVE_FORM: 'Schedule/RemoveAppointmentForm',
  UPDATE_APPT_STATUS: 'Schedule/UpdateAppointmentStatus',
  GET_SCHEDULE_EVENT: 'Schedule/GetScheduleEvent',
  MOVE_SCHEDULE_EVENT: 'schedule/MoveScheduleEvent',
  MOVE_GROUP_WAITINGLIST: 'Schedule/MoveGroupWaitingList',
  GET_ACTIVITY_APPOINTMENTS: 'schedule/getappointments',
  PATCH_ACTIVITY_APPOINTMENT: 'Schedule/patchappointment'
};
