import _ from 'lodash';
import { IOrderActionsReducer, IAction } from './types';

const prefix = 'ORDER_ACTION_';

export const actionsTypes = {
  SET_SUMMARY: `${prefix}SET_SUMMARY`
} as const;

type IActions = Record<
  typeof actionsTypes[keyof typeof actionsTypes],
  () => IOrderActionsReducer
>;

export const defaultData: IOrderActionsReducer = {
  summary: {}
};

const Reducer = (
  state: IOrderActionsReducer = _.cloneDeep(defaultData),
  action: IAction
): IOrderActionsReducer => {
  const actions: IActions = {
    [actionsTypes.SET_SUMMARY]: () => {
      return {
        ...state,
        summary: action.payload
      };
    }
  };
  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};

export default Reducer;
