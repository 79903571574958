import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { v4 as uuidv4 } from 'uuid';

import _, { parseInt } from 'lodash';
import {
  newEventv2,
  newEventBlockv2,
  initReleaseBeforeInterval,
  defaultPatientStatus,
  defaultScheduleEventData,
  defaultScheduleGroupEventData
} from '../Store/Models/ScheduleDefinition';
import {
  daysOptions,
  initWeekdayOptions,
  weekArray,
  weekNumberOptions
} from '../InitData';
import { validateStartTimeRule } from '../Popups/AppointmentPopup/utils';
import store from 'src/store';
import {
  ICurrentClinicOption,
  IScheduleClinic
} from '../Store/ScheduleReducers/ScheduleDetailsReducer/types';
import { ClinicSimpleQueryResult } from 'src/App/Admin/Pages/Clinics/store/types';
import {
  getAllClinics,
  getClinicByCode
} from 'src/Framework/Controls/Selectors/ClinicSelector';
import { clinicDisplayName } from 'src/Framework/util/format';
import {
  getClinicConfig,
  getProviderByCode,
  providerDisplayName
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { convertClinics } from '../utils';

//@ts-ignore
const moment = extendMoment(Moment);

export const defaultValueSchedule = '{"clinics":[],"providers":[]}';

export const parsedData = (value: string) => {
  try {
    const data = JSON.parse(value);
    return data;
  } catch (e) {
    console.log({ e });
    return null;
  }
};

export function getMinFrom1200AM(timeStr: string): number {
  const HMStrings = timeStr && timeStr.split(':');
  return HMStrings ? Number(HMStrings[0]) * 60 + Number(HMStrings[1]) : 0;
}

export function timeDiff(endTime: string, startTime: string): number {
  return getMinFrom1200AM(endTime) - getMinFrom1200AM(startTime);
}

export function getHourAndMinFormat(date: Date, isEnd: Boolean): string {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var totalMins = hours * 60 + minutes;
  if (totalMins == 0 && isEnd) {
    totalMins = 60 * 24;
  }
  return getEndTime('0:00', totalMins.toString());
}

export function get24HourAndMinFormat(hour12S: string, isEnd: Boolean) {
  var ampmStr = hour12S && hour12S.split(' ')[1];
  var timeStr = hour12S && hour12S.split(' ')[0];
  var isAM = ampmStr.toUpperCase() == 'AM';
  var hourStr = timeStr && timeStr.split(':')[0];
  var minStr = timeStr && timeStr.split(':')[1];
  if (isAM && hourStr === '12') {
    hourStr = '0';
  }
  var totalMins = parseInt(hourStr) * 60 + parseInt(minStr);
  if (!isAM) {
    totalMins += 60 * 12;
  }
  if (totalMins == 0 && isEnd) {
    totalMins = 60 * 24;
  }
  return getEndTime('0:00', totalMins.toString());
}

export function getEndTime(startTime: string, duration: string): string {
  var totalMins = getMinFrom1200AM(startTime) + Number(duration);
  var mins = totalMins % 60;
  var minString = mins.toString();
  if (mins < 10) {
    minString = '0' + minString;
  }
  let result = Math.floor(totalMins / 60) + ':' + minString;
  result = result[1] === ':' ? `0${result}` : result;
  return result;
}

export function getTime(minutesFromMidnight: number): string {
  var mins = minutesFromMidnight % 60;
  var minString = mins.toString();
  if (mins < 10) {
    minString = '0' + minString;
  }
  let result = Math.floor(minutesFromMidnight / 60) + ':' + minString;
  result = result[1] === ':' ? `0${result}` : result;
  return result;
}

export function getFormattedStr(str: string, maxLen: number): string {
  if (!str) return '';
  if (str.length > maxLen - 2) {
    return str.slice(0, maxLen - 2) + '...';
  }
  return str;
}

export function getStandartTimeFromMilitaryTime(militaryTime: string) {
  let time = '';
  if (parseInt(militaryTime[0] + militaryTime[1]) === 12) {
    time = militaryTime + ' PM';
  } else if (parseInt(militaryTime[0] + militaryTime[1]) > 12) {
    let newTime: any = parseInt(militaryTime[0] + militaryTime[1]) - 12;
    if (newTime < 9) {
      newTime = '0' + `${newTime}`;
    }
    time =
      `${newTime}` +
      militaryTime[2] +
      militaryTime[3] +
      militaryTime[4] +
      ' PM';
  } else {
    time = militaryTime + ' AM';
  }
  if (time[1] === ':') {
    time = '0' + time;
  }
  return time;
}

export function hideAllHighlights() {
  var allHighlightElements: any =
    document.getElementsByClassName('highlightComponent');
  for (var i = 0; i < allHighlightElements.length; i++) {
    allHighlightElements[i].style.opacity = '0';
  }
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getActiveStatusClass(
  event: any,
  displayStatus: string,
  statusObject: any
) {
  let updatedStartTime = event.scheduleEventDateTime;
  let isAfter = moment(moment()).isAfter(updatedStartTime);
  let status = '';
  if (isAfter && !statusObject.checkedIn) {
    const delay = store.getState().user.preferences.appointmentLateDelay?.value;
    if (delay) {
      const newDate = moment(updatedStartTime).add(delay, 'm').toDate();
      const isLate = isEventLate({
        scheduleEventDateTime: newDate
      });
      if (isLate) {
        status = 'Late';
      }
    } else {
      status = 'Late';
    }
  }
  if (status == displayStatus) {
    return 'active';
  }
  return '';
}

export function isEventLate(patient: any) {
  let updatedStartTime = patient.scheduleEventDateTime;
  let isAfter = moment(moment()).isAfter(updatedStartTime);
  return isAfter;
}

export function statusProgressHandler(status: string, state: any) {
  let details = _.cloneDeep(state);
  const currentDate = new Date(); // currentDateInFormat();
  switch (status) {
    case 'Arrived':
      details.checkedIn = !details.checkedIn;
      details = {
        ...details,
        discharged: false,
        ready: false,
        admit: false,
        checkedInTime: details.checkedIn ? currentDate : null
      };
      break;
    case 'Ready':
      details.ready = !details.ready;
      details = {
        ...details,
        readyTime: details.ready ? currentDate : null,
        discharged: false,
        admit: false
      };
      if (details.ready) {
        details = {
          ...details,
          checkedInTime: details.checkedIn
            ? details.checkedInTime
            : details.readyTime,
          checkedIn: true
        };
      }
      break;
    case 'Admitted':
      details.admit = !details.admit;
      details = {
        ...details,
        admitTime: details.admit ? currentDate : null,
        discharged: false
      };
      if (details.admit) {
        details = {
          ...details,
          checkedInTime: details.checkedIn
            ? details.checkedInTime
            : details.admitTime,
          readyTime: details.ready ? details.readyTime : details.admitTime,
          checkedIn: true,
          ready: true
        };
      }
      break;
    case 'Discharged':
      details.discharged = !details.discharged;
      details = {
        ...details,
        dischargeTime: details.discharged ? currentDate : null
      };
      if (details.discharged) {
        details = {
          ...details,
          checkedInTime: details.checkedIn
            ? details.checkedInTime
            : details.dischargeTime,
          readyTime: details.ready ? details.readyTime : details.dischargeTime,
          admitTime: details.admit ? details.admitTime : details.dischargeTime,
          checkedIn: true,
          ready: true,
          admit: true
        };
      }
      break;
  }
  return details;
}

export function getTimeObjectStringFromSimpleTime(time: any, date: any) {
  let updatedTime = _.cloneDeep(time);
  if (time[1] === ':') {
    updatedTime = `0${updatedTime}`;
  }
  return moment(date).format('YYYY-MM-DD') + 'T' + updatedTime + ':00.0000';
}

export function phoneNumberMask(phoneNumber: string) {
  let USNumber: any = phoneNumber.match(/(\d{3})(\d{3})(\d{4})/);
  let phone = '';
  if (USNumber && USNumber.length > 0) {
    phone = '(' + USNumber[1] + ') ' + USNumber[2] + '-' + USNumber[3];
  }
  return phone;
}

export function momentDateHandFormatter(dateString: string) {
  // MM/DD/YYYY -> YYYY-MM-DD
  let result: any = dateString.split('/');
  result = result[2] + '-' + result[0] + '-' + result[1];
  return result;
}

export function weekDayOptions() {
  let startIndex = initWeekdayOptions.findIndex(
    (item: any) => item.startOfTheWeek
  );
  let endIndex = initWeekdayOptions.findIndex((item: any) => item.endOfTheWeek);
  let weekdayOptions: any = [];
  if (startIndex >= 0 && endIndex >= 0) {
    weekdayOptions = initWeekdayOptions.slice(startIndex, endIndex + 1);
  } else {
    weekdayOptions = initWeekdayOptions.slice(0, 6);
  }
  return weekdayOptions;
}

export function getMomentFromTimeString(str: any) {
  var t = moment(str, 'HH:mm A');
  // Now t is a moment.js object of today's date at the time given in str

  if (t.get('hour') < 22)
    // If it's before 9 pm
    t.add(1, 'd'); // Add 1 day, so that t is tomorrow's date at the same time

  return t;
}

export function getTypeFrequencyView(typeFrequencyId: any) {
  let view = 'daily';
  if (typeFrequencyId === 2) {
    view = 'weekly';
  } else if (typeFrequencyId === 3) {
    view = 'biweekly';
  } else if (typeFrequencyId === 4) {
    view = 'monthly';
  } else if (typeFrequencyId === 5) {
    view = 'monthly-week';
  }
  return view;
}

export const getTypeFrequencyNumber = (typeFrequencyName: string) => {
  switch (typeFrequencyName) {
    case 'daily':
      return 1;
    case 'weekly':
      return 2;
    case 'biweekly':
      return 3;
    case 'monthly':
      return 4;
    case 'monthlyByWeekDay':
      return 5;
    default:
      return 1;
  }
};

export function getDurationOptionsFromClinic(clinic: any, startTime: any) {
  const interval = getClinicStartEndTimeInterval(clinic);
  var minstart: number = getMinFrom1200AM(interval.startTime);
  const minend: number = getMinFrom1200AM(interval.endTime);
  if (startTime) {
    const eventStartTime = moment(startTime).format('HH:mm');
    minstart = getMinFrom1200AM(eventStartTime);
  }
  const increment: number = interval.regularMeetingTime;
  const total = minend - minstart;
  const durationOptions: any[] = [];
  for (let i = increment; i <= total; i = i + increment) {
    durationOptions.push({
      value: i,
      label: i + ' Min'
    });
  }

  return durationOptions;
}

export function buildSliceId(
  tenantId: any,
  clinicCode: any,
  providerCode: any,
  date: any
) {
  let formattedDate = moment(date).format('MMDDYYYY');
  return `schedule-tenant#${tenantId}:clinic#${clinicCode.toLowerCase()}:provider#${providerCode.toLowerCase()}:date#${formattedDate}`;
}

export function getGroupReasonOptionsByClinic(
  groupReasons: any,
  clinicCode: any
) {
  let reasonOptions = groupReasons.map((reason: any) => ({
    value: reason.id,
    label: reason.name,
    id: reason.id,
    duration: 60,
    color: '#CCCCCC'
  }));
  return reasonOptions;
}

export function getClinicStartEndTimeInterval(clinic: any) {
  const regularMeetingTime = Number(clinic.visitDuration);
  const startTime = get24HourAndMinFormat(clinic.start, false);
  const endTime = get24HourAndMinFormat(clinic.end, true);
  return {
    startTime,
    endTime,
    regularMeetingTime
  };
}

export const getStartTimeOpts = (clinic: any) => {
  var interval = getClinicStartEndTimeInterval(clinic);
  var minstart: number = getMinFrom1200AM(interval.startTime);
  var minend: number = getMinFrom1200AM(interval.endTime);
  var timeIdx: number = 0;
  var increment: number = interval.regularMeetingTime;
  let durationOptions: any[] = [];
  minend = minend - increment;
  for (timeIdx = minstart; timeIdx <= minend; timeIdx += increment) {
    var timeVal = getTime(timeIdx);
    var label = getStandartTimeFromMilitaryTime(timeVal);
    durationOptions.push({
      value: timeVal,
      label: label
    });
  }
  return durationOptions;
};

export const getEndTimeOpts = (clinic: any, startTime: any) => {
  let durationOptions: any[] = [];
  if (startTime) {
    var interval = getClinicStartEndTimeInterval(clinic);
    var eventStartTime = getMomentFromTimeString(startTime).format('HH:mm');
    var minstart: number = getMinFrom1200AM(eventStartTime);
    var minend: number = getMinFrom1200AM(interval.endTime);
    var timeIdx: number = 0;
    var increment: number = interval.regularMeetingTime;
    minstart = minstart + increment;
    for (timeIdx = minstart; timeIdx <= minend; timeIdx += increment) {
      var timeVal = getTime(timeIdx);
      var label = getStandartTimeFromMilitaryTime(timeVal);
      if (timeIdx >= 1440) {
        timeVal = '24:00';
        label = '(Midnight)';
      }
      durationOptions.push({
        value: timeVal,
        label: label
      });
    }
  }
  return durationOptions;
};

export const findOpt = (list: any, value: any, key: any = 'value') => {
  if (list) {
    let val = list.find((c: any) => c[key] === value);
    if (val) {
      return val;
    }
  }
  return null;
};
export const mapClinic = (clinic: IScheduleClinic): ICurrentClinicOption => {
  return {
    label: clinicDisplayName(clinic),
    value: clinic.code,
    ...clinic
  };
};
const findOptLowerCase = (list: any, value: string, key: any = 'value') => {
  if (list) {
    let val = list.find(
      (c: any) => c[key]?.toLowerCase() === value?.toLowerCase()
    );
    if (val) {
      return val;
    }
  }
  return null;
};

export function setSelectedProviders(providerCodes: any, providerOptions: any) {
  let selectedProviders: any = [];
  if (providerCodes) {
    providerCodes.forEach((provider: any) => {
      providerOptions.forEach((item: any) => {
        if (item.value === provider.code) {
          selectedProviders.push(item);
        }
      });
    });
  }
  return selectedProviders;
}

export function getScheduleEventTypeName(scheduleEventTypeId: number) {
  if (scheduleEventTypeId === 2) {
    return 'Block';
  } else if (scheduleEventTypeId === 3) {
    return 'Reservation';
  } else if (scheduleEventTypeId === 1) {
    return 'Appointment';
  } else if (scheduleEventTypeId === 6) {
    return 'Outreach';
  } else {
    return 'Event';
  }
}

export function monthlyRecurrancePayloadBuilder(days: any) {
  let payloadString = '';
  days.map((day: any) => {
    if (day.isSelected) {
      payloadString = payloadString + '1';
    } else {
      payloadString = payloadString + '0';
    }
  });
  return payloadString;
}

export function monthlyRecurranceStateBuilder(string: any) {
  let days = [...daysOptions];
  days.map((day: any, index: number) => {
    days[index].isSelected = parseInt(string[index]);
  });
  return days;
}

export function weekNumber(number: number) {
  let weeks: any[] = [...weekNumberOptions];
  let selectedWeek = weeks.find((week: any) => week.value === number);
  return selectedWeek;
}

export function weekDay(string: string) {
  let weekDays: any[] = [...weekdayOptions];
  let selectedWeekDay = weekDays.find(
    (week: any) => week.code === parseInt(string)
  );
  return selectedWeekDay;
}

function buildRecurrenceFrequencyString(series: any) {
  let view = getTypeFrequencyView(series.typeFrequencyId);
  let selectedDays: any = [];
  let rString = `Occurs ${view} `;
  if (view === 'weekly' || view === 'biweekly') {
    let weekdayOptions = getDaysOfWeekFilterOpt(series.dayOfWeekFilter);
    rString = rString + 'on ';
    weekdayOptions.map((weekday: any) => {
      if (weekday.isSelected) {
        selectedDays.push(weekday.label);
      }
    });
    rString = rString + selectedDays.join(', ');
  }
  if (view === 'monthly') {
    let monthOptions = getDaysOfMonthFilterOpt(series.dayOfMonthFilter);
    rString = rString + 'on ';
    monthOptions.forEach((selected: any) => {
      selectedDays.push(selected.label);
    });
    rString = rString + selectedDays.join(', ');
  }
  if (view === 'monthly-week') {
    let rString = `Occurs monthly `;
    let weekDay = '';
    if (series.dayOfWeekFilter) {
      let weekDayVal = parseInt(series.dayOfWeekFilter.charAt(0)) - 1;
      weekDay = weekDayOptions()[weekDayVal].label;
    }

    rString = rString + '\xa0' + 'on ' + weekDay + '\xa0';
    rString = rString + '\xa0' + 'of week ' + series.weekNumber + '\xa0';
  }
  return rString;
}

function buildRecurrenceRangeString(series: any) {
  let rString =
    '\xa0' +
    'effective from' +
    '\xa0' +
    moment(series.startDate).format('MM/DD/YYYY');
  if (moment(series.endDate).get('year') < 3000) {
    rString =
      rString +
      '\xa0' +
      'to' +
      '\xa0' +
      moment(series.endDate).format('MM/DD/YYYY');
  }
  rString =
    rString +
    '\xa0' +
    'between' +
    '\xa0' +
    getStandartTimeFromMilitaryTime(moment(series.startTime).format('HH:mm')) +
    '\xa0' +
    'to' +
    '\xa0' +
    getStandartTimeFromMilitaryTime(moment(series.endTime).format('HH:mm'));
  return rString;
}

export function buildRecurrenceString(series: any) {
  let rString = buildRecurrenceFrequencyString(series);
  rString = rString + buildRecurrenceRangeString(series);
  return rString;
}

export function newEventCardFromGroupWaitingList(
  group: any,
  patients: any,
  reason: any,
  provider: any,
  col: any,
  targetSliceId: string,
  createdDts: any,
  eventStartTime: any,
  eventEndTime: any,
  duration: number
) {
  const { clinicCode, date, clinicName } = col;
  let eventGuid = uuidv4();
  const patientsParser: any = newEventPatientInfoFromGroupWaitingListPatients(
    group,
    patients
  );
  const patientInfo = Object.values(patientsParser)[0];
  let card = {
    allDay: false,
    appointmentBlockId: 0,
    appointmentFor: reason.name,
    appointmentId: 0,
    blockSelfCheckin: false,
    color: reason.color,
    patientId: patients[0].subjectId,
    patientName: '',
    visitReason: reason.code,
    waitingList: {
      id: group.id,
      code: group.waitingListCode
    },
    clinicCode,
    clinicName,
    sliceId: targetSliceId,
    createdDts,
    date,
    duration,
    endTime:
      date && moment(date).format('YYYY-MM-DD') + 'T' + eventStartTime + ':00',
    modifiedDts: createdDts,
    provider: { ...provider, code: capitalizeFirstLetter(provider.code) },
    providerCode: capitalizeFirstLetter(provider.code),
    providerName: provider.Name,
    reason: { ...reason },
    startTime: moment(date).format('YYYY-MM-DD') + 'T' + eventEndTime + ':00',
    uid: eventGuid,
    ...defaultScheduleGroupEventData,
    patients: patientsParser,
    patientInfo,
    tempLoading: true
  };
  return card;
}

function newEventPatientInfoFromGroupWaitingListPatients(
  group: any,
  patients: any
) {
  const obj = {};
  patients.forEach((v: any) => {
    obj[v.patientInfo.patientId] = {
      patientId: v.patientInfo.patientId,
      patientName: v.patientInfo.patientName,
      patientEmail: v.patientInfo.patientEmail,
      patientPreferredPhone: v.patientInfo.patientPreferredPhone,
      patientSex: v.patientInfo.patientSex,
      patientBirthDate: v.patientInfo.patientBirthDate,
      preferredName: v.patientInfo.preferredName,
      patientStatus: {
        checkedIn: false,
        checkedInTime: null,
        checkInUserCode: '',
        ready: false,
        readyTime: null,
        discharged: false,
        readyUserCode: '',
        dischargeTime: null,
        dischargeUserCode: '',
        admit: false,
        admitTime: null,
        admitUserCode: ''
      }
    };
  });
  return obj;
  let pats = {
    '75': {
      patientId: 75,
      patientName: 'Keung Kimmy',
      patientStatus: {
        checkedIn: false,
        checkedInTime: null,
        checkInUserCode: '',
        ready: false,
        readyTime: null,
        discharged: false,
        readyUserCode: '',
        dischargeTime: null,
        dischargeUserCode: '',
        admit: false,
        admitTime: null,
        admitUserCode: ''
      },
      patientEmail: '',
      patientPreferredPhone: '',
      patientSex: 'M',
      patientBirthDate: '1990-07-02T00:00:00',
      preferredName: 'KayKay'
    },
    '88': {
      patientId: 88,
      patientName: 'Sheila Runner',
      patientStatus: {
        checkedIn: false,
        checkedInTime: null,
        checkInUserCode: '',
        ready: false,
        readyTime: null,
        discharged: false,
        readyUserCode: '',
        dischargeTime: null,
        dischargeUserCode: '',
        admit: false,
        admitTime: null,
        admitUserCode: ''
      },
      patientEmail: 'lavender_kc@hotmail.com',
      patientPreferredPhone: '4045554410',
      patientSex: 'F',
      patientBirthDate: '1980-08-19T00:00:00',
      preferredName: 'Shelly'
    }
  };
  return pats;
}

export function newEventCardFromWaitingList(
  event: any,
  provider: any,
  col: any,
  targetSliceId: string,
  createdDts: any,
  eventStartTime: any,
  eventEndTime: any,
  duration: number
) {
  const { clinicCode, date, clinicName } = col;
  let card = {
    allDay: event.allDay,
    appointmentBlockId: event.appointmentBlockId,
    appointmentFor: event.reason.name,
    appointmentId: event.appointmentId,
    blockSelfCheckin: event.blockSelfCheckin,
    color: event.color,
    patientId: event.subjectId,
    patientName: event.name,
    visitReason: event.visitReason,
    waitingList: event.waitingList,
    clinicCode,
    clinicName,
    sliceId: targetSliceId,
    createdDts,
    date,
    duration,
    endTime:
      date && moment(date).format('YYYY-MM-DD') + 'T' + eventStartTime + ':00',
    modifiedDts: createdDts,
    provider: { ...provider, code: capitalizeFirstLetter(provider.code) },
    providerCode: capitalizeFirstLetter(provider.code),
    providerName: provider.Name,
    reason: { ...event.reason },
    startTime: moment(date).format('YYYY-MM-DD') + 'T' + eventEndTime + ':00',
    uid: event.uid,
    ...defaultScheduleEventData,
    patientInfo: {
      ...event.patientInfo,
      patientStatus: { ...defaultPatientStatus }
    },
    patientStatus: { ...defaultPatientStatus },
    tempLoading: true
  };
  return card;
}

export function createNewEventPayload(options: any) {
  let {
    providerCode,
    providerName,
    clinicCode,
    clinicName,
    typeAppointmentParticipantId,
    scheduleEventTypeId,
    startTime,
    date
  } = options;
  let providerCodes: any = [];
  const clinicsList = getAllClinics();
  const clinics = convertClinics(clinicsList);
  const providerOpt = getProviderByCode(providerCode);
  if (providerCode) {
    const clinic = getClinicByCode(clinicCode);
    if (providerOpt && clinic) {
      providerCodes = [providerOpt].map((provider) => ({
        id: provider.providerId,
        code: provider.code,
        page: getClinicConfig(provider, clinic.id)?.maxPages || 1,
        name: providerDisplayName(provider.providerId)
      }));
    }
  }
  let clinic = undefined;
  let clinicOpt = undefined;
  if (clinicCode) {
    clinic = findOptLowerCase(clinics, clinicCode, 'code');
  }
  if (!clinic && clinics?.length > 0) {
    clinicCode = localStorage.getItem('clinicCode');
    clinic = findOptLowerCase(clinics, clinicCode, 'code');
  }
  let startDate: any = moment(Date.now());
  let newStartTime: any =
    startDate.format('YYYY-MM-DD') +
    'T' +
    startDate.format('HH') +
    ':00:00.000';
  if (date) {
    startDate = moment(date);
  }
  if (startTime) {
    newStartTime = startDate.format('YYYY-MM-DD') + 'T' + startTime + ':00.000';
    if (clinicOpt) {
      newStartTime = validateStartTimeRule(clinicOpt, newStartTime);
    }
  }
  var newEventPayload: any = _.cloneDeep(newEventv2);
  newEventPayload.scheduleEvent.uid = Date.now() * -1;
  newEventPayload.providerCodes = providerCodes;
  newEventPayload.scheduleEvent.scheduleEventBlock =
    _.cloneDeep(newEventBlockv2);
  newEventPayload.scheduleEvent.scheduleEventBlock.releaseBeforeInterval =
    _.cloneDeep(initReleaseBeforeInterval);
  newEventPayload.scheduleEvent.clinicCode = clinic?.code;
  newEventPayload.scheduleEvent.clinicName = clinicName;
  newEventPayload.scheduleEvent.providerCode = providerOpt?.code;
  newEventPayload.scheduleEvent.providerName = providerName;
  newEventPayload.scheduleEvent.scheduleEventTypeId = scheduleEventTypeId;
  newEventPayload.scheduleEvent.typeAppointmentParticipantId =
    typeAppointmentParticipantId;
  newEventPayload.scheduleEvent.scheduleEventDateTime = clinic?.code
    ? newStartTime
    : null;
  newEventPayload.scheduleEvent.endTime = null;
  newEventPayload.scheduleEvent.reason = null;
  newEventPayload.scheduleEvent.maxParticipants = 10;
  newEventPayload.scheduleEvent.series.startDate =
    startDate.format('YYYY-MM-DD');
  newEventPayload.scheduleEvent.series.endDate = '9999-01-01';
  newEventPayload.scheduleEvent.series.startTime = clinic?.code
    ? newStartTime
    : null;
  newEventPayload.scheduleEvent.series.endTime = null;
  return newEventPayload;
}

export function getDaysOfWeekFilterOpt(dayOfWeekFilter: any) {
  let weekdayOptions = _.cloneDeep(weekDayOptions());
  dayOfWeekFilter.split('').map((item: string) => {
    if (weekdayOptions[parseInt(item) - 1]) {
      weekdayOptions[parseInt(item) - 1].isSelected = true;
    }
  });
  return weekdayOptions;
}

export function setSeriesDaysOfWeek(weekdayOptions: any, series: any) {
  let dayOfWeekFilter = '';
  weekdayOptions.forEach((item: any) => {
    if (item.isSelected) {
      dayOfWeekFilter = dayOfWeekFilter + item.code;
    }
  });
  series.dayOfWeekFilter = dayOfWeekFilter;
  return series;
}

export function setSeriesDaysOfMonth(
  value: any,
  series: any,
  lastDay: boolean
) {
  series.dayOfMonthFilter = value;
  series.lastDayofMonth = lastDay;
  return series;
}

export function monthlySelectedDates(days: any) {
  let selectedDays: any[] = [];
  days.map((day: any) => {
    if (day.isSelected) {
      selectedDays.push(day.value);
    }
  });
  return selectedDays;
}

function getDaysOfMonthFilterOpt(dayOfMonthFilter: any) {
  let selectedDaysOfMonthOpt: any = [];
  dayOfMonthFilter.split('').map((item: string, index: number) => {
    let optIndex = index;
    if (daysOptions[optIndex] && item === '1') {
      selectedDaysOfMonthOpt.push(daysOptions[optIndex]);
    }
  });
  return selectedDaysOfMonthOpt;
}

const weekdayOptions = [
  { value: 'Sunday', label: 'Sunday', isSelected: false, code: 1 },
  { value: 'Monday', label: 'Monday', isSelected: false, code: 2 },
  { value: 'Tuesday', label: 'Tuesday', isSelected: false, code: 3 },
  { value: 'Wednesday', label: 'Wednesday', isSelected: false, code: 4 },
  { value: 'Thursday', label: 'Thursday', isSelected: false, code: 5 },
  { value: 'Friday', label: 'Friday', isSelected: false, code: 6 },
  { value: 'Saturday', label: 'Saturday', isSelected: false, code: 7 }
];

export function getDurationEndTime(
  duration: any,
  startTime: any,
  endDate: any
) {
  let endDateMoment = moment(startTime);
  if (endDate) {
    endDateMoment = moment(endDate);
  }
  let newEndTime = getMomentFromTimeString(moment(startTime).format('HH:mm'));
  newEndTime.add(moment.duration(duration, 'minutes'));
  return (
    endDateMoment.format('YYYY-MM-DD') +
    'T' +
    newEndTime.format('HH:mm') +
    ':00.000'
  );
}

export function calcDurationTimeValue(startTimeValue: any, endTimeValue: any) {
  const startMinutes = getMinFrom1200AM(startTimeValue);
  const endMinutes = getMinFrom1200AM(endTimeValue);
  let duration = endMinutes - startMinutes;
  return duration;
}

export function calcDurationDateTime(startDateTime: any, endDateTime: any) {
  startDateTime = getMomentFromTimeString(startDateTime.format('HH:mm'));
  endDateTime = getMomentFromTimeString(endDateTime.format('HH:mm'));
  let duration = moment.duration(endDateTime.diff(startDateTime));
  let minutes = duration.asMinutes();
  if (minutes < 0) {
    minutes = 1440 - minutes;
  }
  return minutes;
}

export function getTimeObject(time: any) {
  let updatedTime = `${moment(time).format('YYYY-MM-DD')}T${moment(time).format(
    'HH:mm:ss'
  )}`;
  return updatedTime;
}

export const getClinicsStartEndTimeInterval = (clinicList: any[]) => {
  if (clinicList.length > 0) {
    const firstClinic = clinicList[0];
    let slotHeight = 1;
    let regularMeetingTime = Number(firstClinic.visitDuration);
    let minimumStartTime = get24HourAndMinFormat(firstClinic.start, false);
    let maximumEndTime = get24HourAndMinFormat(firstClinic.end, true);
    clinicList.forEach((clinic: any) => {
      if (regularMeetingTime > Number(clinic.visitDuration)) {
        regularMeetingTime = Number(clinic.visitDuration);
      }
      const startTime = get24HourAndMinFormat(clinic.start, false);
      const endTime = get24HourAndMinFormat(clinic.end, true);
      if (getMinFrom1200AM(minimumStartTime) > getMinFrom1200AM(startTime)) {
        minimumStartTime = startTime;
      }
      if (getMinFrom1200AM(maximumEndTime) < getMinFrom1200AM(endTime)) {
        maximumEndTime = endTime;
      }
    });
    const tempDayStartTime = minimumStartTime;
    const tempDayEndTime = maximumEndTime;

    let dayStartTime = tempDayStartTime;
    let dayEndTime = tempDayEndTime;
    if (tempDayStartTime[1] === ':') {
      dayStartTime = `0${tempDayStartTime}`;
    }
    if (tempDayEndTime[1] === ':') {
      dayEndTime = `0${tempDayEndTime}`;
    }
    if (regularMeetingTime === 60) {
      if (clinicList.length === 1) {
        const clinic =
          store.getState().adminPanel.clinics.summary[firstClinic.id];
        if (clinic) {
          if (clinic.typeId === 1) {
            // slotHeight = 2;
          }
        }
      }
    }
    return {
      regularMeetingTime,
      dayStartTime,
      dayEndTime,
      slotHeight
    };
  }
  return {
    regularMeetingTime: 30,
    dayEndTime: '19:00',
    dayStartTime: '08:00',
    slotHeight: 1
  };
};

export function slicesFilter(slices: any, currentView: any, weekFilter: any) {
  if (currentView === 'weekprovider' || currentView === 'workweek') {
    let filteredSlices = slices.map((slice: any) => ({
      ...slice,
      // check the day for each slice to see if slice is valid for clinic.dayWeek configuration (only a single clinic)
      clinicOpen:
        getClinicByCode(slice.clinicCode)?.workingDays.indexOf(
          slice.weekDay.toLowerCase()
        ) !== -1
    }));

    if (weekFilter === '5days') {
      //workWeek(use configuration)
      return filteredSlices.filter(
        (slice: any) => slice.clinicOpen // only show work week configuration
      );
    } else {
      //full week(sunday to saturday week)
      return filteredSlices; //slices will have closed/open info
    }
  }

  return slices;
}

export function isClinicOpened(
  clinic: ClinicSimpleQueryResult | undefined | null,
  startDate: string
) {
  const date = moment(startDate);
  const weekday = date.day();
  if (clinic) {
    return clinic.workingDays.includes(weekArray[weekday]?.toLowerCase());
  }
  return false;
}

export function validateClinicOpenByDate(
  clinicCode: string,
  startDate: string
) {
  const clinics = getAllClinics();
  const date = moment(startDate);
  const weekday = date.day();
  const clinic = clinics.find((c) => c.code === clinicCode);
  if (clinic) {
    if (clinic.workingDays) {
      return clinic.workingDays.includes(weekArray[weekday]?.toLowerCase());
    }
  }
  return false;
}

export function validateClinicClosedOnWeekDays(
  clinicWeekDays: string[],
  weekdays: string[]
) {
  const intersection = _.intersection(clinicWeekDays, weekdays); //common days
  return intersection.length === weekdays.length
    ? false
    : _.without(weekdays, ...intersection);
}
