

import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"

export const get = async (params: {
    clinicId: number
    patientId: number
    providerId: number
}) => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.PatientInformation, { withCredentials: true, params })
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result
                return data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}