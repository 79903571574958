import _ from "lodash";

const ENABLE_LOADER = "ENABLE_LOADER";

export const enableCohortLoader = (flag: boolean) => {
  return {
    type: ENABLE_LOADER,
    enableLoader: flag
  };
};

let initialState: any = {
  enableLoader: false
};

interface IAction {
  type: string;
  enableLoader: boolean;
}

const EnableCohortLoaderReducer = (state: any = { ...initialState }, action: IAction) => {
  switch (action.type) {
    case ENABLE_LOADER: {
      let newState: IAction = _.cloneDeep(state);
      newState.enableLoader = action.enableLoader;
      return newState;
    }

    default:
      return state;
  }
};

export default EnableCohortLoaderReducer;