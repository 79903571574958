import React from 'react';

export const RoundedClipSvg = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="16" cy="16" r="16" fill="#F7F7F7" />
    <path
      d="M16.8568 13.357L13.8518 16.362C13.1688 17.045 13.1688 18.153 13.8518 18.837C14.5348 19.52 15.6428 19.52 16.3268 18.837L20.3928 14.771C21.7598 13.404 21.7598 11.188 20.3928 9.82103C19.0258 8.45403 16.8098 8.45403 15.4428 9.82103L11.0238 14.241C8.97383 16.291 8.97383 19.615 11.0238 21.666C13.0738 23.716 16.3978 23.716 18.4488 21.666L22.5138 17.6"
      stroke="#3A5B8B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
