import * as actionType from "../../RuleActions/ActionTypes";
import {
  IRuleComponent,
  ILogicalOperatorRuleComponent,
  IRuleStore
} from "../../../../RuleEditor/Store/Models/RuleDefinition";
import _ from "lodash";

const initialState: IRuleStore[] = [];

interface IAction {
  id: string;
  type: string;
  value: any;
  parentId: number;
  componentId: number;
  componentTemplate: any;
  sourceId: number;
  targetId: number;
  keyName: string;
  component: IRuleComponent;
  sourceIndex: number;
  targetIndex: number;
  index: number;
  newValue: any;
  subType: string;
  ruleId: string;
  subComponent: IRuleComponent;
}

const RuleViewerDefinitionReducer = (state: IRuleStore[] = initialState, action: IAction): any => {
  switch (action.type) {
    case actionType.ADD_RULE_FOR_VIEWER: {
      let ruleStore: IRuleStore | undefined = state.find((r: IRuleStore) => r.rule.id === action.value.id);
      if (!ruleStore) return [...state, { rule: _.cloneDeep(action.value), publishedRule: _.cloneDeep(action.value) }];
      ruleStore.isDirty = false;
      ruleStore.rule = _.cloneDeep(action.value);
      ruleStore.publishedRule = _.cloneDeep(action.value);
      return [...state];
    }

    default:
      return state;
  }
};

const findInnerRuleComponent = (parent: ILogicalOperatorRuleComponent, id: number): IRuleComponent | null => {
  let comp: any = parent.operands.find((o: IRuleComponent) => o.id === id);
  if (comp) return comp;
  let containers: ILogicalOperatorRuleComponent[] = parent.operands
    .filter((o: IRuleComponent) =>
      isContainerRuleComponent(o)
    )
    .map((r: IRuleComponent) => r as ILogicalOperatorRuleComponent);
  for (let index: number = 0; index < containers.length; index++) {
    comp = findInnerRuleComponent(containers[index], id);
    if (comp) return comp;
  }
  if (containers.length === 0) {
    let compWithSeries: any[] = parent.operands.filter((o: IRuleComponent) => o["series"] && o["series"].length > 0);
    for (let index: number = 0; index < compWithSeries.length; index++) {
      comp = compWithSeries[index].series.find((s: any) => s.id === id);
      if (comp) return comp;
    }
  }
  return null;
};

const isContainerRuleComponent = (rule: IRuleComponent): boolean => {
  return rule.ruleComponent === "logical-and" || rule.ruleComponent === "logical-or" || rule.ruleComponent === "logical-not" || rule.ruleComponent.includes("series");
};

export default RuleViewerDefinitionReducer;

