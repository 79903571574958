import _ from 'lodash';
import { IStateReducer, IAction } from './types';

const prefix = 'ADMIN_USERS_';

export const actionsTypes = {
  SET: `${prefix}SET`,
  SET_LOADING: `${prefix}SET_LOADING`,
  SET_FILTERS: `${prefix}SET_FILTERS`,
  SET_PERMISSIONS: `${prefix}SET_PERMISSIONS`,
  SET_ASSOCIATED_PROVIDERS: `${prefix}SET_ASSOCIATED_PROVIDERS`,
  SET_TABLE_SORT: `${prefix}TYPES_SET_TABLE_SORT`,
  SET_USERS_GROUPS: `${prefix}_SET_USERS_GROUPS`,
  REMOVE_USER_GROUP: `${prefix}_REMOVE_USER_GROUP`,
  ADD_USER_GROUP: `${prefix}_ADD_USER_GROUP`
};

const initialState: IStateReducer = {
  summary: {},
  loading: false,
  filters: {
    search: '',
    showInactive: false
  },
  permissions: {
    modules: {},
    functions: {},
    moduleFunctions: {}
  },
  associatedProviders: {},
  sort: {
    sortBy: 'fullName',
    sortType: 'asc'
  },
  usersGroups: {}
};

const Reducer = (
  state: IStateReducer = _.cloneDeep(initialState),
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET_ASSOCIATED_PROVIDERS: {
      if (!action.userId) return state;
      return {
        ...state,
        associatedProviders: {
          ...state.associatedProviders,
          [action.userId]: action.payload
        }
      };
    }
    case actionsTypes.SET_PERMISSIONS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          ...action.payload
        }
      };
    case actionsTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionsTypes.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };
    case actionsTypes.SET_TABLE_SORT:
      return { ...state, sort: action.payload };
    case actionsTypes.SET_USERS_GROUPS:
      return { ...state, usersGroups: action.payload };
    case actionsTypes.REMOVE_USER_GROUP: {
      const usersGroups = _.cloneDeep(state.usersGroups);
      const newSet = Object.values(usersGroups);
      const itemToRemove = newSet.find(
        (item) =>
          item.medicatUserId === action.payload.userId &&
          item.groupUserId === action.payload.groupId
      );
      if (itemToRemove) {
        delete usersGroups[itemToRemove.id];
      }
      return { ...state, usersGroups };
    }
    case actionsTypes.ADD_USER_GROUP: {
      const usersGroups = _.cloneDeep(state.usersGroups);
      usersGroups[action.payload.id + ''] = action.payload;
      return { ...state, usersGroups };
    }
    case actionsTypes.SET:
      return {
        ...state,
        summary: action.payload
      };
    default:
      return state;
  }
};

export default Reducer;
