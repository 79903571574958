import React, { FC, useRef } from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import { M1FormRecordHistoryAction } from 'src/App/M1FormRecords/store/types';

import Modal, { IModalHandles } from './Modal';
import { IM1FormRecord } from 'src/App/M1FormRecords/store/types';

interface IProps {
  currentForm: IM1FormRecord;
  readOnly: boolean;
  color?: string;
}

const Component: FC<IProps> = ({ currentForm, readOnly, color }: IProps) => {
  const ModalRef = useRef<IModalHandles>(null);
  const active = Object.values(currentForm.history).find(
    (v) => v.action === M1FormRecordHistoryAction.Append
  )
    ? true
    : false;
  return (
    <>
      <Modal
        ref={ModalRef}
        currentForm={currentForm}
        onAdded={(res: any) => {}}
      />
      <ButtonIcon
        color={color}
        name={ICONS_LIST.append}
        changeColorOnHover={true}
        onClick={() => {
          ModalRef.current?.show({});
        }}
        active={active}
        disabled={!readOnly}
        tooltip={
          !readOnly
            ? 'Only locked or final signed form can be appended'
            : 'Append'
        }
      />
    </>
  );
};

export default Component;
