import React, { FC, useRef } from 'react';

import ButtonIcon, { ICONS_LIST, IIcon } from '../ButtonIcon';
import FileViewerModal, { IModalHandles } from './Modal';
import FileViewerWrapper from './Wrapper';

interface IProps {
  item: any;
  buttonProps?: Partial<IIcon>;
  closePopup?: Function;
  parentButtonRef?: React.MutableRefObject<any>;
}

const FileViewerButton: FC<IProps> = ({
  item,
  buttonProps,
  closePopup,
  parentButtonRef
}: IProps) => {
  const FilterViewerModalRef = useRef<IModalHandles>(null);
  return (
    <>
      <FileViewerModal ref={FilterViewerModalRef} />
      <FileViewerWrapper item={item} parentButtonRef={parentButtonRef}>
        {({ onClickPreview, previewSupport }) => (
          <ButtonIcon
            isButton={true}
            label={
              !previewSupport
                ? 'Preview not supported for this file type'
                : 'Preview'
            }
            {...buttonProps}
            name={ICONS_LIST.filePreview}
            tooltip={
              !previewSupport
                ? 'Preview not supported for this file type'
                : 'Preview'
            }
            disabled={!previewSupport}
            iconScale={1.2}
            onClick={() => {
              closePopup && closePopup();
              onClickPreview();
            }}
            changeColorOnHover={true}
          />
        )}
      </FileViewerWrapper>
    </>
  );
};

export default React.memo(FileViewerButton);
