import React, { FC } from 'react';

import CombinedSelector, {
  IOutProps as ICombinedProps
} from 'src/Framework/Controls/CombinedSelector';
import { useGenericType } from '../../store/Actions/hooks';
import { ITypeCampusStatus } from '../../store/types';

import { Container } from './styled';

type IProps = ICombinedProps<ITypeCampusStatus> & {};

const TypeCampusStatus: FC<IProps> = (props: IProps) => {
  const typeCampusStatus = useGenericType('typeCampusStatus');
  return (
    <Container>
      <CombinedSelector
        {...props}
        loading={typeCampusStatus.loading}
        data={typeCampusStatus.genericTypes.map((v) => ({
          label: v.name,
          value: v.id,
          isActive: true,
          dto: v
        }))}
      />
    </Container>
  );
};

export default React.memo(TypeCampusStatus);
