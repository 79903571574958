import { DependencyList, useMemo } from 'react';

type IValue =
  boolean |
  number
  | string
  | null
  | undefined
  | object
  | number[]
  | string[]
  | object[];

interface IFormValidatorItem {
  enable?: boolean;
  value?: IValue;
  customCompare?: () => boolean;
  /** This parameter has more priority then "value" */
  oneOfValues?: IValue[];
  fieldName?: string
}

export type IFormValidator = IFormValidatorItem[];

const isNotValid = (v: IValue) => {
  if (!v) {
    return true;
  }
  if (typeof v === 'string') {
    return v.trim().length === 0;
  }
  if (typeof v === 'number') {
  }
  if (Array.isArray(v)) {
    return v.length === 0;
  }
  return false;
};

export const isFormValid = (data: IFormValidator) => {
  const list = data.filter((v) => v.enable === true || v.enable === undefined);
  const index = list.findIndex((v) => {
    if (v.customCompare) {
      return !v.customCompare();
    }
    if (v.oneOfValues) {
      const foundValid = v.oneOfValues.some((v) => !isNotValid(v));
      return !foundValid;
    }
    return isNotValid(v.value);
  });
  const isValid = index === -1;
  return {
    isValid,
    notValidItem: !isValid ? list[index] : undefined
  };
};

export const useValidator = (
  data: IFormValidator,
  deps: DependencyList | undefined
) => {
  return useMemo(() => {
    return isFormValid(data).isValid;
  }, deps);
};