import React, { FC } from 'react';
import { useQuery } from 'src/Framework/util/helps';

import M1Viewer from './M1Viewer';
import LegacyViewer from './LegacyViewer';

interface IProps {
  match: any;
}

export enum FormViewerTypes {
  m1Form = 'm1',
  legacyForm = 'legacy'
}

const Component: FC<IProps> = (props: IProps) => {
  const queryParams = useQuery();
  const type = queryParams.get('type');
  if (type === FormViewerTypes.m1Form) {
    return <M1Viewer {...props} />;
  }
  if (type === FormViewerTypes.legacyForm) {
    return <LegacyViewer {...props} />;
  }
  return <div></div>;
};

export default React.memo(Component);
