import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
`

export const Title = styled.div`
    font-size: 112px;
    line-height: 90px;
    color: #005581;
    margin: 10px 0 0 0;
`
export const SubTitle = styled.div`
    font-size: 32px;
    color: #005581;
    margin: 10px;
`
export const Description = styled.div`
    font-size: 16px;
    color: #005581;
`