
import * as actionTypes from "./SignalRActions";
import _ from 'lodash';

interface IStateReducer {
  connection: any
  signalRConnectionStatus: boolean
}

const initialState: IStateReducer = {
  connection: null,
  signalRConnectionStatus: false
}

interface IAction {
  type: string;
  payload: any;
}

const SignalRReducer = (
  state: IStateReducer = _.cloneDeep(initialState),
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionTypes.UPDATE_SIGNALR_CONNECTION_STATE: {
      return {
        ...state,
        signalRConnectionStatus: action.payload
      };
    }
    case actionTypes.SAVE_SIGNALR_CONNECTION: {
      return {
        ...state,
        connection: action.payload
      };
    }
    default:
      return state;
  }
};
export default SignalRReducer;


