import _ from 'lodash'
import { IAction, IStateReducer, IPatientState } from './types'

const prefix = 'INSURANCE_REDUCER_'

export const actionsTypes = {
    SET_COMPANIES: `${prefix}SET_COMPANIES`,
    SET_PATIENT_INSURANCES: `${prefix}SET_PATIENT_INSURANCES`,
    SET_PATIENT_INSURANCES_LOADING: `${prefix}SET_PATIENT_INSURANCES_LOADING`,
    SET_GENERIC_TYPES: `${prefix}SET_GENERIC_TYPES`,
    SET_PATIENT_IS_ACTIVE: `${prefix}SET_PATIENT_IS_ACTIVE`,
}

const initialState: IStateReducer = {
    companies: {},
    patients: {},
    genericTypes: {
        typeInsurance: [],
        typeInsuranceWaiverTerm: [],
        typeInsuranceWaiverStatus: [],
        typeRelationship: []
    }
};

export const initialPatientInsurances: IPatientState = {
    summary: {},
    loading: false,
    isActive: false
}

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction): IStateReducer => {
    if (action.patientId) {
        if (!state.patients[action.patientId]) {
            state.patients[action.patientId] = { ...initialPatientInsurances }
        }
    }
    switch (action.type) {
        case actionsTypes.SET_PATIENT_IS_ACTIVE:
            return {
                ...state, patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        isActive: action.payload
                    }
                }
            }
        case actionsTypes.SET_GENERIC_TYPES:
            return {
                ...state,
                genericTypes: {
                    ...state.genericTypes,
                    ...action.payload
                }
            }
        case actionsTypes.SET_PATIENT_INSURANCES_LOADING:
            return {
                ...state, patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        loading: action.payload
                    }
                }
            }
        case actionsTypes.SET_PATIENT_INSURANCES:
            return {
                ...state, patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...state.patients[action.patientId],
                        summary: action.payload
                    }
                }
            }
        case actionsTypes.SET_COMPANIES:
            return { ...state, companies: action.payload }
        default:
            return state;
    }
};

export default Reducer;
