import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { APIHandler } from "src/Framework/Communication/ServerProxy";

export const getResidencyType = async (): Promise<{
    id: number
    name: string
}[] | undefined> => {
    try {
        const data = {
            componentType: 'residency',
            reqFields: ['name', 'id', 'inactive']
        };
        const res = await APIHandler.AxiosInstance.post(ApiUrl.GET_DEMOGRAPHICS_COMPONENT, data, { withCredentials: true });
        if (res.data.success) {
            return res.data.result
        }
        return
    } catch (e) {
        return
    }
};

export const getSchoolType = async (): Promise<{
    id: number
    name: string
}[] | undefined> => {
    try {
        const data = {
            componentType: 'school',
            reqFields: ['name', 'id', 'inactive']
        };
        const res = await APIHandler.AxiosInstance.post(ApiUrl.GET_DEMOGRAPHICS_COMPONENT, data, { withCredentials: true });
        if (res.data.success) {
            return res.data.result
        }
        return
    } catch (e) {
        return
    }
};

export const getStudentClass = async (): Promise<{
    id: number
    name: string
}[] | undefined> => {
    try {
        const data = {
            componentType: 'studentclass',
            reqFields: ['name', 'id', 'inactive']
        };
        const res = await APIHandler.AxiosInstance.post(ApiUrl.GET_DEMOGRAPHICS_COMPONENT, data, { withCredentials: true });
        if (res.data.success) {
            return res.data.result
        }
        return
    } catch (e) {
        return
    }
};

export const getStanding = async (): Promise<{
    id: number
    name: string
}[] | undefined> => {
    try {
        const data = {
            componentType: 'standing',
            reqFields: ['name', 'id', 'inactive']
        };
        const res = await APIHandler.AxiosInstance.post(ApiUrl.GET_DEMOGRAPHICS_COMPONENT, data, { withCredentials: true });
        if (res.data.success) {
            return res.data.result
        }
        return
    } catch (e) {
        return
    }
};

export const getStatus = async () => {
    try {
        const data = {
            componentType: 'studentstatus',
            reqFields: ['name', 'id', 'inactive']
        };
        const res = await APIHandler.AxiosInstance.post(ApiUrl.GET_DEMOGRAPHICS_COMPONENT, data, { withCredentials: true });
        if (res.data.success) {
            return res.data.result
        }
        return
    } catch (e) {
        return
    }
};

export const getEligibility = async (): Promise<{
    id: number
    name: string
}[] | undefined> => {
    try {
        const data = {
            componentType: 'eligibility',
            reqFields: ['name', 'id', 'inactive']
        };
        const res = await APIHandler.AxiosInstance.post(ApiUrl.GET_DEMOGRAPHICS_COMPONENT, data, { withCredentials: true });
        if (res.data.success) {
            return res.data.result
        }
        return
    } catch (e) {
        return
    }
};