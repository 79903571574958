
import _ from 'lodash';
import { IStateReducer, IAction } from './types'

const prefix = `LOGIN_`

export const actionTypes = {
  USER_LOGIN: `${prefix}USER_LOGIN`,
  LOGIN_FAIL: `${prefix}LOGIN_FAIL`,
  GET_PERMISSION: `${prefix}GET_PERMISSION`,
  SESSION_LOGOFF: `${prefix}SESSION_LOGOFF`
}

const initialState: IStateReducer = {
  response: {},
  permissions: {},
  currentClinic: {},
  errors: "",
  connection: null,
  noteTypes: {}
};

const LoginReducer = (
  state: IStateReducer = { ..._.cloneDeep(initialState) },
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionTypes.USER_LOGIN: {
      let newState = _.cloneDeep(state)
      let currentClinic = action.payload.clinics?.find((m: any) => m.id === action.payload.clinicId);
      newState.currentClinic = currentClinic;
      newState.response = action.payload;
      return newState;
    }
    case actionTypes.SESSION_LOGOFF: {
      let newState = _.cloneDeep(state)
      let connection = _.cloneDeep(newState.connection);
      newState = { ...initialState }
      newState.connection = connection;
      return newState;
    }
    case actionTypes.GET_PERMISSION: {
      let newState = _.cloneDeep(state)
      newState.permissions = action.payload;
      return newState;
    }
    case actionTypes.LOGIN_FAIL: {
      let newState = _.cloneDeep(state)
      newState.errors = action.payload;
      return newState;
    }
    default:
      return state;
  }
};
export default LoginReducer;


