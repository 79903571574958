import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import Modal from 'src/Framework/Controls/Modal';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import Viewer, { IGetOnLoadedData } from './Viewer';
import { Formats, IData } from './types';

import { Container } from './styled';
import { useReactToPrint } from 'react-to-print';
import { Col, Row } from 'antd';

const modalId = 'fileViewerModal';

interface IProps {
  parentButtonRef?: React.MutableRefObject<any>;
}

export interface IModalHandles {
  show(obj: IData): void;

  close(): void;
}

const FileViewerModal: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { parentButtonRef },
  ref
) => {
  const [blobUrlForPrint, setBlobUrlForPrint] = useState<string | null>(null);
  const [printLoading, setPrintLoading] = useState(false);
  const printRef = useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    onAfterPrint: () => {},
    pageStyle: `body {
      width: 1248px;
    }
    @page {  margin: 0; } @media print { body {  padding: 0px; } }`
  });
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<IData | null>(null);

  const show = async (data: IData) => {
    setData(data);
    setVisible(true);
    setPrintLoading(true);
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      modalElement.focus();
    }
  };

  const close = () => {
    setVisible(false);
    setTimeout(() => {
      if (parentButtonRef?.current) {
        parentButtonRef?.current.focus();
      }
    }, 300);
  };

  const setToDefault = () => {
    setData(null);
    setPrintLoading(false);
    setBlobUrlForPrint(null);
    printRef.current = null;
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IData) => show(obj),
    close
  }));

  const getOnLoadedData = useCallback(
    ({ ref, format, blobUrl }: IGetOnLoadedData) => {
      printRef.current = ref;
      setPrintLoading(false);
      if (format === Formats.html || format === Formats.pdf) {
        setBlobUrlForPrint(blobUrl);
      }
    },
    []
  );

  const onClickPrint = useCallback(() => {
    if (blobUrlForPrint) {
      const myWindow = window.open(blobUrlForPrint);
      myWindow?.print();
    } else {
      handlePrint();
    }
  }, [blobUrlForPrint]);

  return (
    <Modal
      id={modalId}
      width={1248}
      title={'Viewer'}
      visible={visible}
      hideClose={true}
      onClose={close}
      buttons={
        <Row align="middle" gutter={16}>
          <Col>
            <ButtonIcon
              isButton={true}
              label="Print"
              name={ICONS_LIST.print}
              onClick={onClickPrint}
              changeColorOnHover={true}
              loading={printLoading}
            />
          </Col>
          <Col>
            <ButtonIcon
              isButton={true}
              label="close modal"
              name={ICONS_LIST.thinClose}
              onClick={close}
              disableBackground={true}
            />
          </Col>
        </Row>
      }
      headerBorder={true}
      afterClose={setToDefault}
      headerProps={{
        tabIndex: 0,
        style: { paddingRight: 24 }
      }}
      modalProps={{
        destroyOnClose: true,
        keyboard: true,
        wrapClassName: 'file-viewer-modal',
        maskStyle: {
          zIndex: 1420
        }
      }}
    >
      <Container>
        {data && <Viewer data={data} getOnLoadedData={getOnLoadedData} />}
      </Container>
    </Modal>
  );
};

export default React.forwardRef(FileViewerModal);
