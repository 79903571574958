import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import ReactComponent from '@formio/react/lib/components/ReactComponent';
import settingsForm from './settingsForm';

import MultipleSelector from 'src/Framework/Controls/Selectors/MultipleSelector';

import { Container } from './styled';
import { EnumFields } from './types';

import PatientForm from './PatientForm';
import { defaultValueField, getComponentState } from '../utils';

export const componentType = 'm1PatientForm';

interface IValue {
  changes?: any;
  changed?: any;
  data: Record<EnumFields, any>;
}

interface IProps {
  value: IValue | null;
  component: {
    data?: IValue;
    key: string;
    id: string;
  };
  onChange: Function;
  readOnly: boolean;
  root: any;
}

interface IState {
  value: IValue | null;
}

export const pathToFields: {
  [key in EnumFields]: string;
} = {
  [EnumFields.SocialSecurityNo]: 'patient.socialSecurityNo',
  [EnumFields.OtherId]: 'patient.otherId',
  [EnumFields.ChartNo]: 'patient.chartNo',
  [EnumFields.Eligibility]: 'patient.eligibility',
  [EnumFields.Age]: 'patient.age',
  [EnumFields.PrimaryInsurance]: 'patient.primaryInsurance',
  [EnumFields.SecondaryInsurance]: 'patient.secondaryInsurance',
  [EnumFields.LastName]: 'patient.lastName',
  [EnumFields.MobilePhone]: 'patient.mobilePhone',
  [EnumFields.Sex]: 'patient.sex',
  [EnumFields.AccountNo]: 'patient.accountNo',
  [EnumFields.BirthDate]: 'patient.birthDate',
  [EnumFields.HomePhone]: 'patient.homePhone',
  [EnumFields.WorkPhone]: 'patient.workPhone',
  [EnumFields.Comment]: 'patient.comment',
  [EnumFields.Suffix]: 'patient.suffix',
  [EnumFields.Address]: 'patient.address',
  [EnumFields.CampusAddress]: 'patient.campusAddress',
  [EnumFields.Email]: 'patient.email',
  [EnumFields.EnrollmentDate]: 'patient.enrollmentDate',
  [EnumFields.International]: 'patient.international',
  [EnumFields.Major]: 'patient.major',
  [EnumFields.SchoolName]: 'patient.schoolName',
  [EnumFields.FirstName]: 'patient.firstName',
  [EnumFields.Standing]: 'patient.standing',
  [EnumFields.Employer]: 'patient.employer',
  [EnumFields.FeeSchedule]: 'patient.feeScheduleName',
  [EnumFields.ProviderCode]: 'patient.providerCode',
  [EnumFields.BillingMethod]: 'patient.billingMethod',
  [EnumFields.Employed]: 'patient.employed',
  [EnumFields.MaritalStatus]: 'patient.maritalStatus',
  [EnumFields.Pager]: 'patient.pager',
  [EnumFields.Group]: 'patient.group',
  [EnumFields.StudentStatus]: 'patient.studentStatus',
  [EnumFields.AddressLine2]: 'patient.addressLine2',
  [EnumFields.Class]: 'patient.class',
  [EnumFields.MiddleInitial]: 'patient.middleInitial',
  [EnumFields.NickName]: 'patient.nickName',
  [EnumFields.PrimaryPolicyNo]: 'patient.primaryPolicyNo',
  [EnumFields.SecondaryPolicyNo]: 'patient.secondaryPolicyNo',
  [EnumFields.PrimaryPolicyEffectiveDate]: 'patient.primaryPolicyEffectiveDate',
  [EnumFields.SecondaryPolicyEffectiveDate]:
    'patient.secondaryPolicyEffectiveDate',
  [EnumFields.PrimaryPolicyExpirationDate]:
    'patient.primaryPolicyExpirationDate',
  [EnumFields.SecondaryPolicyExpirationDate]:
    'patient.secondaryPolicyExpirationDate',
  [EnumFields.City]: 'patient.city',
  [EnumFields.State]: 'patient.state',
  [EnumFields.Zip]: 'patient.zip',
  [EnumFields.EmergencyContact]: 'patient.emergencyContact',
  [EnumFields.EmergencyRelationship]: 'patient.emergencyRelationship',
  [EnumFields.EmergencyPhone1]: 'patient.emergencyPhone1',
  [EnumFields.EmergencyPhone2]: 'patient.emergencyPhone2',
  [EnumFields.ProviderTeam]: 'patient.providerTeam',
  [EnumFields.GenderIdentity]: 'patient.genderIdentity',
  [EnumFields.PrimaryPolicyGroupNo]: 'patient.primaryPolicyGroupNo',
  [EnumFields.PrimaryPolicySubscriberName]:
    'patient.primaryPolicySubscriberName',
  [EnumFields.PrimaryPolicySubscriberDOB]: 'patient.primaryPolicySubscriberDOB',
  [EnumFields.Pronouns]: 'patient.pronouns',
  [EnumFields.Sport]: 'patient.sport',
  [EnumFields.GPA]: 'patient.gpa',
  [EnumFields.AcademicAdvisor]: 'patient.academicAdvisor',
  [EnumFields.MealPlan]: 'patient.mealPlan',
  [EnumFields.CampusHousingBuildingName]: 'patient.campusHousingBuildingName',
  [EnumFields.CampusHousingFloor]: 'patient.campusHousingFloor',
  [EnumFields.CampusHousingRoom]: 'patient.campusHousingRoom'
};

export const fieldNames: {
  [key in EnumFields]: string;
} = {
  [EnumFields.SocialSecurityNo]: 'Social Security No',
  [EnumFields.OtherId]: 'Other ID',
  [EnumFields.ChartNo]: 'Chart No',
  [EnumFields.Eligibility]: 'Eligibility',
  [EnumFields.Age]: 'Age',
  [EnumFields.PrimaryInsurance]: 'Primary Insurance',
  [EnumFields.SecondaryInsurance]: 'Secondary Insurance',
  [EnumFields.LastName]: 'Last Name',
  [EnumFields.MobilePhone]: 'Cell Phone',
  [EnumFields.Sex]: 'Sex',
  [EnumFields.AccountNo]: 'Account No',
  [EnumFields.BirthDate]: 'Birth Date',
  [EnumFields.HomePhone]: 'Home Phone',
  [EnumFields.WorkPhone]: 'Work Phone',
  [EnumFields.Comment]: 'Comment',
  [EnumFields.Suffix]: 'Suffix',
  [EnumFields.Address]: 'Address',
  [EnumFields.CampusAddress]: 'Campus Address',
  [EnumFields.Email]: 'Email',
  [EnumFields.EnrollmentDate]: 'Enrollment Date',
  [EnumFields.International]: 'International',
  [EnumFields.Major]: 'Major',
  [EnumFields.SchoolName]: 'School Name',
  [EnumFields.FirstName]: 'First Name',
  [EnumFields.Standing]: 'Standing',
  [EnumFields.Employer]: 'Employer',
  [EnumFields.FeeSchedule]: 'Fee Schedule',
  [EnumFields.ProviderCode]: 'Provider Code',
  [EnumFields.BillingMethod]: 'Billing Method',
  [EnumFields.Employed]: 'Employed',
  [EnumFields.MaritalStatus]: 'Marital Status',
  [EnumFields.Pager]: 'Alt Phone',
  [EnumFields.Group]: 'Group',
  [EnumFields.StudentStatus]: 'Student Status',
  [EnumFields.AddressLine2]: 'Address Line 2',
  [EnumFields.Class]: 'Class',
  [EnumFields.MiddleInitial]: 'Middle Initial',
  [EnumFields.NickName]: 'Nick Name',
  [EnumFields.PrimaryPolicyNo]: 'Primary Policy No',
  [EnumFields.SecondaryPolicyNo]: 'Secondary Policy No',
  [EnumFields.PrimaryPolicyEffectiveDate]: 'Primary Policy Effective Date',
  [EnumFields.SecondaryPolicyEffectiveDate]: 'Secondary Policy Effective Date',
  [EnumFields.PrimaryPolicyExpirationDate]: 'Primary Policy Expiration Date',
  [EnumFields.SecondaryPolicyExpirationDate]:
    'Secondary Policy Expiration Date',
  [EnumFields.City]: 'City',
  [EnumFields.State]: 'State',
  [EnumFields.Zip]: 'Zip',
  [EnumFields.EmergencyContact]: 'Emergency Contact',
  [EnumFields.EmergencyRelationship]: 'Emergency Relationship',
  [EnumFields.EmergencyPhone1]: 'Emergency Phone 1',
  [EnumFields.EmergencyPhone2]: 'Emergency Phone 2',
  [EnumFields.ProviderTeam]: 'Provider Team',
  [EnumFields.GenderIdentity]: 'Gender Identity',
  [EnumFields.PrimaryPolicyGroupNo]: 'Primary Policy Group No',
  [EnumFields.PrimaryPolicySubscriberName]: 'Primary Policy Subscriber Name',
  [EnumFields.PrimaryPolicySubscriberDOB]: 'Primary Policy Subscriber DOB',
  [EnumFields.Pronouns]: 'Pronouns',
  [EnumFields.Sport]: 'Sport',
  [EnumFields.GPA]: 'GPA',
  [EnumFields.AcademicAdvisor]: 'Academic Advisor',
  [EnumFields.MealPlan]: 'Meal Plan',
  [EnumFields.CampusHousingBuildingName]: 'Campus Housing Building Name',
  [EnumFields.CampusHousingFloor]: 'Campus Housing Floor',
  [EnumFields.CampusHousingRoom]: 'Campus Housing Room'
};

const options: {
  value: EnumFields;
  label: string;
}[] = Object.entries(fieldNames).map(([key, label]) => ({
  value: key as EnumFields,
  label
}));
const M1PatientFormComp = class extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      value: _.cloneDeep(props.value)
    };
  }

  getStateValue = () => {
    const { component } = this.props;
    const { value } = this.state;
    return value || _.cloneDeep(component[defaultValueField]);
  };

  setValue = (data: IValue) => {
    const { changed, changes, ...other } = data;
    this.setState(
      {
        value: other
      },
      () => {
        const { onChange } = this.props;
        const { value } = this.state;
        if (value) {
          const { changed, changes, ...other } = value;
          onChange(other, value);
        }
      }
    );
  };

  render() {
    const value = this.getStateValue();
    const { root } = this.props;
    const { isDataTab, isPreview, isBuilder, isRender } =
      getComponentState(root);
    return (
      <Container>
        {isDataTab && (
          <>
            <MultipleSelector
              id="Fields"
              label={'Fields'}
              value={Object.keys(value?.data || {})}
              onChange={(fields) => {
                const data: any = {};
                fields.forEach((key) => {
                  data[key] = null;
                });
                this.setValue({
                  ...value,
                  data
                });
              }}
              options={options}
              selectProps={{
                star: false
              }}
            />
          </>
        )}
        {isPreview && (
          <>
            <PatientForm data={value?.data} />
          </>
        )}
        {isBuilder && (
          <>
            <PatientForm data={value?.data} />
          </>
        )}
        {isRender && (
          <>
            <PatientForm data={value?.data} />
          </>
        )}
      </Container>
    );
  }
};

export default class M1PatientForm extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Patient fields',
      icon: 'list',
      group: 'Data',
      documentation: '',
      weight: -10,
      schema: M1PatientForm.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    // @ts-ignore
    return ReactComponent.schema({
      type: componentType,
      label: 'Patient fields',
      multiple: false
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element: any, ref: any) {
    if (!element) return null;
    return ReactDOM.render(
      <M1PatientFormComp
        ref={ref}
        // @ts-ignore
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue}
        // @ts-ignore
        readOnly={this.root.options.readOnly}
        // @ts-ignore
        root={this.root}
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element: any) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
