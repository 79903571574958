import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    .help-icon {
        margin: 0 5px 0 5px;
        cursor: pointer;
    }
`

export const Title = styled.div`
    display: flex;
`

export const Star = styled.div`
    height: 12px;
    width: 12px;
    mask-size: contain;
    mask-position: center center;
    mask-repeat: no-repeat no-repeat;
    margin: 0 5px 0 0;
`

interface ILabel {
    labelInRow?: boolean
}

export const Label = styled.label<ILabel>`
${({labelInRow})=>!labelInRow? `
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    margin: 0 0 5px 0;
    color: #666666;
`:`
    white-space:nowrap;
    padding-top: 2px;
`}
`