import _ from 'lodash';
import { IAction } from './Interfaces';

export const dashboardNavInit: any = {
    dashboard: {
        name: "dashboard",
        title: "Dashboard",
        items: {},
        information: "",
        url: "",
    },
}

const CounselingNavigationReducer = (state: any = dashboardNavInit, action: IAction) => {
    let newState = _.cloneDeep(state)
    if (!newState[action.subjectId]) {
        newState[action.subjectId] = { ...dashboardNavInit };
    }
    switch (action.type) {
        default:
            return state;
    }
}

export default CounselingNavigationReducer;