import store from 'src/store';

export const getReasonByID = (id: number | string | null | undefined) => {
    if (!id) return null
    const value = store.getState().adminPanel.appointmentTypes.summary[id]
    return value ? value : null
}

export const getReasonByCode = (code: string) => {
    const values = Object.values(store.getState().adminPanel.appointmentTypes.summary)
    const value = values.find((v) => v.code === code)
    if (value) {
        return {
            ...value,
            value: value.code,
            label: value.name
        }
    }
    return null
}