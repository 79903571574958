import _ from 'lodash';
import { IAction, IStateReducer } from './types';

const prefix = 'GENERIC_TYPES';

export const actionsTypes = {
  SET_GENERIC_TYPES: `${prefix}SET_GENERIC_TYPES`
};

const initialState: IStateReducer = {
  summary: {
    typeResidency: [],
    typeClass: [],
    typeStanding: [],
    typeSchool: [],
    typeEligibility: [],
    typeSpecialty: [],
    typeServiceProvider: [],
    typeGroupProvider: [],
    typeGender: [],
    typeCampusStatus: [],
    typeRelationship: [],
    medicatDemo: [],
    CampusHousing: [],
    typeMedicareSecondaryPayor: [],
    typeCHAPReferral: [],
    typeOver90DayReasons: [],
    typeServiceAuth: [],
    typeAbortionSterilization: [],
    typeClaim: [],
    typeDisability: [],
    typeEPSDTReferralCode: [],
    typePatientStatus: [],
    typeImmuneStatus: [],
    typeStudentStatus: []
  }
};

const Reducer = (
  state: IStateReducer = { ..._.cloneDeep(initialState) },
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET_GENERIC_TYPES:
      return {
        ...state,
        summary: { ...state.summary, ...action.payload }
      };
    default:
      return state;
  }
};

export default Reducer;
