import { store } from 'src';
import SunEditor from 'suneditor/src/lib/core';
import { v4 } from 'uuid';

const insertText = ({
    editor,
    insertionText
}: {
    editor: SunEditor,
    insertionText: string
}) => {
    editor.core.focus();
    const selection =
        editor.core.getSelection();
    const anchorNode = selection.anchorNode;
    const text = anchorNode?.nodeValue || '';
    const position = selection.anchorOffset;
    const text1 = text.slice(0, position);
    const text2 = text.slice(position);
    if (anchorNode) {
        anchorNode.textContent = `${text1}${insertionText}${text2}`;
        const selection = document.getSelection();
        const range = document.createRange();
        if (selection) {
            range.setStart(
                anchorNode,
                `${text1}${insertionText}`.length
            );
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
        }
        const html = editor.getContents(true);
        return {
            html
        }
    }
    return {

    }
}

const multilineAutoReplace = ({ withWord, anchorNode, editor, text, selection }: {
    withWord: string
    anchorNode: Node
    editor: SunEditor,
    text: string
    selection: Selection
}) => {
    const position = selection.anchorOffset;
    const text1 = text.slice(0, position);
    const textEnd = text.slice(position);
    const words = text1.split(' ')
    let index = words.length - 1
    if (words[index] === '') {
        index = words.length - 2
    }
    console.log({ position, text, anchorNode })
    const withWordLines = withWord.split('\n')
    const firstLine = withWordLines[0]
    const lastLine = withWordLines[withWordLines.length - 1]
    withWordLines.shift()
    withWordLines.pop()
    words[index] = ``
    const textBegining = `${words.join(' ')} `
    const textBeginWithoutWord = `${textBegining}${firstLine}`
    if (anchorNode.parentNode) {
        const id = v4()
        const el = document.createElement('span')
        el.id = id
        el.innerHTML = `<p>${textBeginWithoutWord}</p>${withWordLines.map((v) => `<p>${v}</p>`).join('')}<span>${lastLine}</span> <span>${textEnd}</span>`
        anchorNode.parentNode.appendChild(el)
        editor.util.removeItem(anchorNode)
        const selection = document.getSelection();
        const range = document.createRange();
        if (selection) {
            const element = document.getElementById(id)
            if (element) {
                range.setStart(
                    element,
                    element.childNodes.length - 1
                );
                range.collapse(true);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    }
}

const autoReplace = ({
    editor,
    event,
}: {
    editor: SunEditor,
    event: KeyboardEvent
}) => {
    if (event.code === 'Space') {
        const selection =
            editor.core.getSelection();
        const anchorNode = selection.anchorNode
        const parentNode = anchorNode?.parentNode

        if (anchorNode && parentNode) {
            const text = anchorNode.textContent || '';
            const position = selection.anchorOffset;
            const text1 = text.slice(0, position);
            const textEnd = text.slice(position);
            const words = text1.split(' ')
            let index = words.length - 1
            if (words[index] === '') {
                index = words.length - 2
            }
            const lastWord = words[index]?.trim()
            if (lastWord) {
                const replaceList = Object.values(store.getState().autoReplace.summary);
                const replaced = replaceList.find(
                    (v) => v.replaceWord.toLowerCase() === lastWord.toLowerCase()
                );
                if (replaced) {
                    event.preventDefault();
                    if (replaced.withWord.includes('\n')) {
                        multilineAutoReplace({
                            withWord: replaced.withWord,
                            editor,
                            anchorNode,
                            text,
                            selection
                        })
                    } else {
                        words[index] = `${replaced.withWord} `
                        const newText = `${words.join(' ')} `
                        anchorNode.textContent = `${newText}${textEnd}`
                        const selection = document.getSelection();
                        const range = document.createRange();
                        if (selection) {
                            range.setStart(
                                anchorNode,
                                newText.length
                            );
                            range.collapse(true);
                            selection.removeAllRanges();
                            selection.addRange(range);
                        }
                    }
                    const html = editor.getContents(true);
                    console.log({ html })
                    return {
                        html
                    }
                }
            }
        }
    }
    return {}
}

const autoReplaceOnKeyDown = ({
    editor,
    event,
}: {
    editor: SunEditor,
    event: KeyboardEvent
}) => {
    if (event.code === 'Enter') {
        const selection =
            editor.core.getSelection();
        const anchorNode = selection.anchorNode;
        const parentNode = anchorNode?.parentNode;

        if (anchorNode && parentNode) {
            const text = parentNode.textContent || '';
            const position = selection.anchorOffset;
            const text1 = text.slice(0, position);
            const textEnd = text.slice(position);
            const words = text1.split(' ')
            let index = words.length - 1
            if (words[index] === '') {
                index = words.length - 2
            }
            console.log({ parentNode, words })
            const lastWord = words[index]?.trim()
            if (lastWord) {
                const replaceList = Object.values(store.getState().autoReplace.summary);
                const replaced = replaceList.find(
                    (v) => v.replaceWord.toLowerCase() === lastWord.toLowerCase()
                );
                if (replaced) {
                    event.preventDefault();
                    if (replaced.withWord.includes('\n')) {
                        multilineAutoReplace({
                            withWord: replaced.withWord,
                            editor,
                            anchorNode,
                            text,
                            selection
                        })
                    } else {
                        words[index] = `${replaced.withWord}`
                        const newText = `${words.join(' ')} `
                        const id = v4()
                        const el = document.createElement('span')
                        el.id = id
                        el.innerHTML = `<p>${newText}</p><br/><p>${textEnd}</p>`
                        parentNode.appendChild(el)
                        editor.util.removeItem(anchorNode)
                        const selection = document.getSelection();
                        const range = document.createRange();
                        if (selection) {
                            const element = document.getElementById(id)
                            if (element) {
                                range.setStart(
                                    element,
                                    element.childNodes.length - 2
                                );
                                range.collapse(true);
                                selection.removeAllRanges();
                                selection.addRange(range);
                            }
                        }
                    }
                    const html = editor.getContents(true);
                    return {
                        html
                    }
                }
            }
        }
        return {}
    }
    return {}
}

export const HtmlEditonUtils = {
    insertText,
    autoReplace,
    autoReplaceOnKeyDown
}