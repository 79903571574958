import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { notification } from 'antd';

import {
  Attachment,
  AttachmentButton,
  TextContainer,
  FileUploader as Uploader,
  Container
} from './styled';
import { APIHandler } from '../../Communication/ServerProxy';
import { ApiUrl } from '../../Common/ApiUrl';
import FilesDownloader, { IFile } from '../FilesDownloader';

import ElementTitle, { IProps as ITitleProps } from '../ElementTitle';
import { Alert } from '../../Common/Notification';

interface IFileUploader {
  onChange?: (e: any) => void;
  files: IFileSimpleDto[];
  setFiles: (files: IFileRequest[]) => void;
  setPropsFiles?: (files: IFileSimpleDto[]) => void;
  reset?: boolean;
  accept?: string;
  multi?: boolean;
  label?: string;
  containerStyles?: any;
  onlyPropsFiles?: boolean;
  clearOnMount?: boolean;
  setFilesByDefault?: boolean;
  titleProps: Partial<ITitleProps>;
  eventFiles?: boolean;
  onChangeEventFiles?: (arr: Array<File>) => void;
  max?: number;
  showDownloader?: boolean;
  id?: string;
}

export interface IFileRequest {
  name: string;
  type: string;
  base64: string;
  fileId?: string | number;
  title?: string;
}

export interface IFileSimpleDto {
  id: number;
  contentLength: number;
  contentType: string;
  fileName: string;
  originalFileName?: string;
  name?: string;
  crc: string;
  userId?: number;
  dts?: string;
}

export interface IFileSimpleDtoWithTitle {
  id: number;
  contentLength: number;
  contentType: string;
  fileName: string;
  title: string;
}

interface IAttachFile {
  filesIds: number[];
  transactionCodeId: string | number | null;
  noteId: string | number;
  patientId: string | number;
  onSuccess: () => void;
  onError: (message: string) => void;
  title: string;
}

// Convert file to base64 string
export const blobToBase64 = (file: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function (event) {
      if (event.target) resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};

export function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const updateMultipartFile = async (
  file: File,
  fileId: number
): Promise<IFileSimpleDto | undefined> => {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response = await APIHandler.AxiosInstance.put(
      `${ApiUrl.FILES_MULTIPART_UPLOAD}/${fileId}/content`,
      formData,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data.success && response.data.result) {
      const data: any = Object.values(response.data.result.summary)[0];
      return data;
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const uploadMultipartFiles = async (
  data: Array<any>,
  setErrorMessage?: (errorMessage: string) => void
): Promise<IFileSimpleDto[]> => {
  const files = data.map((value: any) => {
    const formData = new FormData();
    formData.append('file', value);
    return formData;
  });
  return Promise.all(
    files.map((file) =>
      APIHandler.AxiosInstance.post(ApiUrl.FILES_MULTIPART_UPLOAD, file, {
        withCredentials: true,
        interceptError: true
      })
        .then(
          (response: {
            data: {
              errorCode: Boolean;
              error: string;
              success: any;
              result: any;
            };
          }) => {
            if (response.data.success && response.data.result) {
              const data: any = Object.values(response.data.result.summary)[0];
              return data;
            }
            if (
              !response.data.success &&
              response.data.error &&
              response.data.errorCode
            ) {
              if (setErrorMessage) setErrorMessage(response.data.error);
            }
            return Promise.reject();
          }
        )
        .catch((e: any) => {
          return Promise.reject();
        })
    )
  );
};

export const uploadFiles = async (
  files: IFileRequest[],
  setErrorMessage?: (errorMessage: string) => void
): Promise<any> => {
  return Promise.all(
    files.map((file) =>
      APIHandler.AxiosInstance.post(
        ApiUrl.FILES_UPLOAD,
        {
          documentName: file.name,
          documentType: file.type,
          inputStream: file.base64
        },
        { withCredentials: true }
      )
        .then(
          (response: {
            data: {
              errorCode: Boolean;
              error: string;
              success: any;
              result: IFileSimpleDto;
            };
          }) => {
            if (response.data.success && response.data.result) {
              return response.data.result;
            }
            if (
              !response.data.success &&
              response.data.error &&
              response.data.errorCode
            ) {
              if (setErrorMessage) setErrorMessage(response.data.error);
            }
            return {};
          }
        )
        .catch((e: any) => {
          console.log('FILE UPLOAD ERROR', e);
          return {};
        })
    )
  );
};

export const attachFilesToNote = async ({
  filesIds,
  transactionCodeId,
  noteId,
  patientId,
  onSuccess,
  onError,
  title
}: IAttachFile) => {
  const data = {
    fileIds: filesIds,
    title: title,
    txnCodeId: transactionCodeId, //txnCode id
    patientId: patientId,
    noteId: noteId,
    dts: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
  };
  return APIHandler.AxiosInstance.post(ApiUrl.CREATE_ATTACHMENT, data, {
    withCredentials: true
  })
    .then((response) => {
      if (!response.data.success && response.data.error) {
        onError('An error occurred while attaching files');
      }
      onSuccess();
    })
    .catch((e: any) => {
      console.log('ATTACHMENT CREATE ERROR', e);
      onError('An error occurred while attaching files');
    });
};

export const convertBlobsToBase64 = async (files: Array<any>) => {
  try {
    const data = [];
    for (let i = 0; i < files.length; i++) {
      const dataFile = files[i];
      const result: any = await blobToBase64(dataFile);
      const file = {
        name: dataFile.name,
        type: dataFile.type,
        size: dataFile.size,
        base64: result.substr(result.indexOf(',') + 1)
      };
      data.push(file);
    }
    return data;
  } catch (e) {
    console.log({ e });
    return;
  }
};

const FileUploader = (props: IFileUploader) => {
  const {
    eventFiles,
    multi,
    onChangeEventFiles,
    onlyPropsFiles,
    titleProps,
    showDownloader,
    containerStyles,
    label,
    accept,
    id
  } = props;
  const UploderRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<IFileRequest[]>(
    // @ts-ignore
    props.setFilesByDefault ? props.files : []
  );
  const [eventFilesList, setEventFilesList] = useState<Array<any>>([]);
  const [filesNames, setFilesNames] = useState<any>('');

  const onChangeEventFilesList = (data: Array<File>) => {
    setEventFilesList(data);
  };
  const maxFileSize = 20 * 1024 * 1024;
  const uploadAttachment = (e: any) => {
    const arr: any = Array.from(e.target.files);
    arr.forEach((v: File, index: number) => {
      if (v.size >= maxFileSize) {
        notification.error({
          message: 'File upload error',
          description: `${v.name} exceeds the maximum allowed file size of 20 MB`
        });
        arr.splice(index, 1);
      }
    });
    if (arr.length > 0) {
      const simpleFiles: IFileRequest[] = [];
      if (eventFiles) {
        const data: Array<any> = multi ? [...eventFilesList, ...arr] : arr;
        onChangeEventFilesList(data);
        onChangeEventFiles && onChangeEventFiles(data);
      }
      arr.forEach((inputFile: any) => {
        const dataFile = inputFile;
        blobToBase64(dataFile).then((result: any) => {
          const file = {
            name: dataFile.name,
            type: dataFile.type,
            size: dataFile.size,
            base64: result.substr(result.indexOf(',') + 1)
          };
          simpleFiles.push(file);
          if (!props.multi) {
            setFiles(simpleFiles);
          }
          if (props.multi) {
            setFiles([...files, ...simpleFiles]);
          }
        });
      });
    }
  };

  const handleOnChange = (e: any) => {
    if (!props.max || (props.max && files.length < props.max)) {
      uploadAttachment(e);
      if (props.onChange) props.onChange(e);
    }
    if (props.max && files.length >= props.max) {
      Alert(
        'warning',
        'Warning',
        `You are only allowed to upload a maximum of ${props.max} files`
      );
    }
  };

  useEffect(() => {
    setFilesNames(
      files.map(
        (file, index) => file.name + (files.length - 1 === index ? '' : ', ')
      )
    );
    props.setFiles(files);
  }, [files]);

  const handleFilesFromUploader = (updatedFiles: IFile[]) => {
    if (props.multi) {
      const newToUploadFiles = _.intersectionBy(files, updatedFiles, 'name');
      setFiles(newToUploadFiles);
      if (props.files && props.setPropsFiles) {
        const newPropsFiles = _.intersectionBy(props.files, updatedFiles, 'id');
        props.setPropsFiles(newPropsFiles);
      }
    }
  };

  useEffect(() => {
    if (props.reset) {
      setFiles([]);
    }
  }, [props.reset]);

  useEffect(() => {
    const { clearOnMount } = props;
    if (clearOnMount) {
      setFiles([]);
    }
  }, []);
  return (
    <Container style={containerStyles}>
      {props.label && <ElementTitle {...titleProps} name={label} />}
      <Attachment
        id={`attachment-container`}
        onClick={() => UploderRef?.current?.click()}
      >
        <TextContainer id={`${id}files-names`}>
          {files.length === 0 ? (
            <span style={{ color: '#CCC' }}>
              {props.multi ? 'Files: //' : 'Files: //'}
            </span>
          ) : (
            filesNames
          )}
        </TextContainer>
        <AttachmentButton id={`${id}upload-button`}>
          <span>Upload</span>
          <Uploader
            ref={UploderRef}
            onClick={(e: any) => (e.target.value = null)}
            onChange={(e: any) => handleOnChange(e)}
            type="file"
            accept={accept}
            multiple={multi}
          />
        </AttachmentButton>
      </Attachment>
      {(files.length > 0 || props.files.length > 0) &&
        (multi || showDownloader) && (
          <FilesDownloader
            id={id}
            deletable={true}
            type="squared"
            onChangeEventFiles={
              onChangeEventFiles
                ? (index: number) => {
                    const data = [...eventFilesList];
                    data.splice(index, 1);
                    onChangeEventFilesList(data);
                    if (onChangeEventFiles) {
                      onChangeEventFiles(data);
                    }
                  }
                : undefined
            }
            setFiles={(files: any) => handleFilesFromUploader(files)}
            files={
              onlyPropsFiles
                ? props.files.map((file: IFileSimpleDto) => ({
                    id: file.id,
                    name: file.originalFileName
                      ? file.originalFileName
                      : file.name,
                    type: file.contentType
                  }))
                : props.files
                    .map((file: IFileSimpleDto) => ({
                      id: file.id,
                      name: file.originalFileName,
                      type: file.contentType
                    }))
                    .concat(
                      files.map((file: IFileRequest) => ({
                        id: 0,
                        name: file.name,
                        type: file.type
                      }))
                    )
            }
          />
        )}
    </Container>
  );
};

FileUploader.defaultProps = {
  label: null,
  accept: '.jpg,.jpeg,.pdf,.png,.gif,.tiff,.csv',
  onChange: () => {},
  setFiles: () => {},
  multi: false,
  containerStyles: {},
  reset: false,
  files: [],
  clearOnMount: true,
  titleProps: {},
  max: 15
};
export default FileUploader;
