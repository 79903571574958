import styled from "styled-components";

export const Container = styled.div`
    .async-image {
        cursor: pointer;
    }
`

export const EditorContainer = styled.div`
    position: relative;
    .tui-image-editor-container {
        width: 100% !important;
    }
    .tui-image-editor-header {
        display: none;
    }
`

export const EditorSaveButton = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
`