const prefix = 'CURRENT_USER_'

export const actionsTypes = {
  SET_PERMISSIONS: `${prefix}SET_PERMISSIONS`,
}

const initState: IState = {};

interface IState {
  [permissionLine: string]: boolean
}

interface IAction {
  type: string;
  payload: any;
}

export const UserPermissionReducer = (
  state: IState = initState,
  action: IAction
): IState => {
  switch (action.type) {
    case actionsTypes.SET_PERMISSIONS: {
      return { ...action.payload };
    }
    default:
      return state;
  }
};
