import _ from "lodash";
import moment from 'moment'

import { IStateReducer, IAction } from './types'

const prefix = 'UNASSIGNED_DATA_'

export const actionsTypes = {
  SET: `${prefix}SET`,
  SET_FILTER: `${prefix}SET_FILTER`,
  SET_PAGINATION: `${prefix}SET_PAGINATION`,
  SET_LOADING: `${prefix}SET_LOADING`,
  SET_SORT: `${prefix}SET_SORT`
};

const pageLength = 20;

const initialState: IStateReducer = {
  data: [],
  loading: true,
  filter: {
    clinicId: null,
    dateFrom: moment().subtract(1, 'months').format('MM/DD/YYYY'),
    dateTo: moment().format('MM/DD/YYYY'),
  },
  pagination: {
    pageNumber: 0,
    pageLength
  },
  sort: {
    sortBy: null,
    sortType: null
  },
};

const ActivityUnassignedClientsReducer = (state: IStateReducer = _.cloneDeep(initialState), action: IAction): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET: {
      let newState = _.cloneDeep(state);
      newState.data = action.payload;
      return newState;
    }
    case actionsTypes.SET_FILTER: {
      let newState = _.cloneDeep(state);
      return {
        ...newState,
        filter: {
          ...newState.filter,
          ...action.payload
        }
      }
    }
    case actionsTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case actionsTypes.SET_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    case actionsTypes.SET_SORT:
      return {
        ...state,
        sort: { ...state.sort, ...action.payload }
      }
    default:
      return state;
  }
};
export default ActivityUnassignedClientsReducer;
