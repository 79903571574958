import React, { FC, useEffect, useState } from 'react';

interface IProps {
  message?: string;
}

const Component: FC<IProps> = ({ message }: IProps) => {
  const [data, setData] = useState(message);
  useEffect(() => {
    if (message) {
      setData(message);
    }
  }, [message]);
  return <span>{data}</span>;
};

export default React.memo(Component);
