import _ from 'lodash';
import { ITicketDiagnosis } from './types';

export interface IPatientData {
    summary: Record<string, ITicketDiagnosis>
}

export interface IStateReducer {
    patients: Record<string, IPatientData>
}

export interface IAction {
    type: typeof actionsTypes[keyof typeof actionsTypes];
    payload: any;
    patientId: string
}


const prefix = 'TICKET_DIAGNOSIS_';

export const actionsTypes = {
    SET_PATIENT_SUMMARY: `${prefix}SET_PATIENT_SUMMARY`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

export const defaultData: IStateReducer = {
    patients: {}
};

const defaultPatientData: IPatientData = {
    summary: {}
}

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const patientData = state.patients[action.patientId] || _.cloneDeep(defaultPatientData)
    const actions: IActions = {
        [actionsTypes.SET_PATIENT_SUMMARY]: () => {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patientData,
                        summary: {
                            ...patientData.summary,
                            ...action.payload
                        }
                    }
                }
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
