import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import styled from 'styled-components';

export const Container = styled.div`
  &.disabled {
    pointer-events: none;
    filter: grayscale(100%);
    opacity: 0.6;
  }

  .multiProviderContainer {
    .ant-select-selector > span:nth-of-type(1) {
      width: calc(100% - 20px);
    }
  }

  .ant-select-selection-search-input {
    min-width: max-content !important;
  }
`;
export const Tag = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 2px 4px;
  margin: 0 2px 0 0;
`;

export const SearchContainer = styled.div`
  margin: 5px 12px;
`;

export const OriginNodeContainer = styled.div`
  .ant-select-item-option-selected {
    background-color: unset !important;
  }

  .ant-checkbox-wrapper {
    font-size: 14px;
  }

  .ant-select-item-option-active {
    background-color: ${globalStyleVariables.activeOption} !important;
  }
`;
export const InactiveProvidersContainer = styled.div`
  padding: 12px;
  border-bottom: 1px solid ${globalStyleVariables.borderColor};

  .ant-select-item-option-selected {
    background-color: unset !important;
  }

  .ant-checkbox-wrapper {
    font-size: 14px;
  }
`;
export const InactiveProvidersHeader = styled.div`
  padding-bottom: 4px;
`;
export const InactiveProvider = styled.div`
  margin-top: 12px;
`;

export const FiltersContainer = styled.div``;

export const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${globalStyleVariables.borderColor};
  margin: 0 0 10px 0;

  .filter-item {
    flex-wrap: nowrap;
    cursor: pointer;
    padding: 0 0 10px 0;
    border-bottom: 1px solid ${globalStyleVariables.borderColor};
  }
`;

export const FilterTitle = styled.div`
  line-height: 16px;
  margin-left: 4px;
`;

export const IconContainer = styled.div`
  margin: 0 10px 0 0;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

export const SelectAllContainer = styled.div<{
  disabled: boolean;
}>`
  padding: 8px 12px;
  border-bottom: 1px solid ${globalStyleVariables.borderColor};
  ${({ disabled }) =>
    disabled &&
    `
        pointer-events: none;
        filter: grayscale(100%);
        opacity: 0.6;
    `}
`;
export const SelectorContainer = styled.div`
  & .multiselector {
    position: relative;

    .ant-select-selector {
      input {
        position: absolute !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .ant-select-selection-overflow {
        .ant-select-selection-search {
          position: unset !important;

          //input {
          //  position: unset !important;
          //  top: 0;
          //  bottom: 0;
          //  left: 0;
          //  right: 0;
          //}
        }
      }
    }
  }
`;
