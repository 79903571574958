export enum TagDefinitionIds {
    BuildingBlockTags = 5,
    FormTemplateTags = 7,
    Category = 8,
    Institutional = 15
}

export const TagValuesIds = {
    Institutional: {
        ReadOnly: 15001,
        MedicatCertified: 15002,
        CCHMCertified: 15003
    },
    Category: {
        PointOfCare: 8006
    }
} as const

export const InstitutionalTag = (value: number) => {
    return Object.values(TagValuesIds.Institutional).some(v => v === value)
}

export const InstitutionalIsReadOnly = (value: number) => {
    return value === TagValuesIds.Institutional.ReadOnly
}

export const InstitutionalIsCertification = (value: number) => {
    return value === TagValuesIds.Institutional.MedicatCertified || value === TagValuesIds.Institutional.CCHMCertified
}

export const FilterInstitutionalTags = (value?: number[]) => {
    if (!value) return []
    const tags = Object.values(TagValuesIds.Institutional)
    return value.filter((v) => !tags.includes(v as any))
}

export const isTagInstitutional = (value: number) => {
    const tags = Object.values(TagValuesIds.Institutional)
    return tags.find((v) => v === value)
}