import globalStyleVariables from "src/Framework/Styles/variables.module.scss"
import styled from "styled-components"
import { Position } from "./types"

export const MouseOverContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: hidden;
  z-index: 10;
  transition: all 0.25s ease-in-out;
`

interface IMouseOverContainerInner {
  position: Position
}

export const MouseOverContainerInner = styled.div<IMouseOverContainerInner>`
  display: flex;
  align-items: center;
  transition: all 0.25s ease-in-out;
  transform: translateX(200px);
  height: 100%;
  margin-right: 10px;
  padding: 10px 10px;
  background-color: white;
  opacity: 0;
  border-right: 1px solid ${globalStyleVariables.borderColor};
        border-left: 1px solid ${globalStyleVariables.borderColor};
    ${({ position }) => position === Position.bottom ? `
        border-radius: 0px 0px 10px 10px;
        border-bottom: 1px solid ${globalStyleVariables.borderColor};

    `: `
        border-radius: 10px 10px 0px 0px;
        border-top: 1px solid ${globalStyleVariables.borderColor};
    `}
  
  background-color: ${globalStyleVariables.newBrandingTertiary1};

  &.active {
    transform: translateX(0px);
    opacity: 1;
  }
`