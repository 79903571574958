import moment from "moment";
import { ETypeTxnClassId } from "src/App/Admin/Pages/TransactionCodes/store/types";
import { labsPermission, pointOfCarePermission } from "src/App/Admin/permissions";
import { permissionChecking } from "src/App/User/Permission";

export const startOfDay = (date: string) => {
    const d = moment(new Date(`${date}`))
    d.set({ hour: 0, minute: 0, second: 0 })
    return d.format('YYYY-MM-DDTHH:mm:ss')
}

export const endOfDay = (date: string) => {
    const d = moment(new Date(`${date}`))
    d.set({ hour: 23, minute: 59, second: 59 })
    return d.format('YYYY-MM-DDTHH:mm:ss')
}

export const classesOptions = () => Object.values(ETypeTxnClassId).filter((item) =>
    [
        ETypeTxnClassId.laboratory,
        ETypeTxnClassId.pointOfCare,
    ].includes(item as ETypeTxnClassId)
) as ETypeTxnClassId[];

const ClassPermissionFunctions: {
    [key in ETypeTxnClassId]?: ReturnType<
        typeof permissionChecking
    >['functionId'];
} = {
    [ETypeTxnClassId.laboratory]: 507,
    [ETypeTxnClassId.pointOfCare]: 509,
};

export const permittedClasses = () => classesOptions().filter((item) => {
    if (item === ETypeTxnClassId.pointOfCare) {
        if (!pointOfCarePermission().success) {
            return false
        }
    }
    if (item === ETypeTxnClassId.laboratory) {
        if (!labsPermission().success) {
            return false
        }
    }
    const functionId = ClassPermissionFunctions[item as ETypeTxnClassId];
    if (functionId) {
        const classPermission = permissionChecking({
            anyClinic: true,
            moduleId: 45,
            functionId
        });
        return classPermission.success;
    }
    return false;
});