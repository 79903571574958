import * as actionTypes from "./Actions";

interface IAction {
  type: string;
  payload: any;
}

interface IStateReducer {
  clinicId: string,
  userId: string,
  clinicCode: string,
  name: string
}

const Reducer = (state: IStateReducer = {
  clinicId: '',
  userId: '',
  clinicCode: '',
  name: ''
}, action: IAction): IStateReducer => {
  switch (action.type) {
    case actionTypes.ADD_CURRENT_USER: {
      return { ...action.payload };
    }
    default:
      return state;
  }
};
export default Reducer