import { useEffect, useRef } from 'react';

interface IProps {
  active: boolean;
}

export const useModalFocus = ({ active }: IProps) => {
  const element = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (active) {
      element.current = document.activeElement as HTMLElement;
    } else {
      if (element.current) {
        element.current.focus();
      }
    }
  }, [active]);
};
