import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

import * as Setters from './Setters';
import { IFilters, IBuildingForm, IFormVersion, IFullBuildingForm, IFullFormVersion, IExtendedBuildingForm, IDesignSchema, IPublicFormsResponse } from '../types';
import { ModelFormat, ISimpleBuildBlock, EnumM1FormBuildingBlockType } from '../../../BuildingBlocks/store/types';
import { ISort } from 'src/Framework/Controls/Table/types';

export const importPublicForms = async (publicFormIds: number[]) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.PUBLIC_FORMS,
      {
        publicFormIds
      },
      {
        withCredentials: true,
      }
    );
    if (response.data.success) {
      const data = response.data.result;
      return data;
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
}

interface IPublicFormsParams extends Partial<ISort> {
  searchString: string
  pageNumber: number
}

export const publicFormsCount = 20

export const getPublicForms = async ({ pageNumber, searchString, sortBy, sortType }: IPublicFormsParams): Promise<IPublicFormsResponse | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.PUBLIC_FORMS,
      {
        withCredentials: true,
        params: {
          sortBy,
          sortType,
          searchString,
          startIndex: pageNumber * publicFormsCount,
          count: publicFormsCount,
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result;
      return data;
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
}

export const setFilters = (payload: Partial<IFilters>) => {
  Setters.filters(payload);
};

export const get = async ({
  IncludeSharedFormInformation
}: {
  IncludeSharedFormInformation?: boolean
} = {}): Promise<Record<string, IBuildingForm> | undefined> => {
  try {
    Setters.loading(true);
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.M1FormsTemplates,
      {
        withCredentials: true,
        params: {
          format: ModelFormat.Simple,
          onlyLatest: true,
          IncludeSharedFormInformation
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      Setters.summary(data);
      return data;
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
    Setters.loading(false);
  }
};

export const getSimpleDto = async (id: number): Promise<IBuildingForm | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.M1FormsTemplates,
      {
        withCredentials: true,
        params: {
          format: ModelFormat.Simple,
          onlyLatest: true,
          ids: [id]
        }
      }
    );
    if (response.data.success) {
      const data = Object.values(response.data.result.summary)[0] as IBuildingForm;
      if (data) {
        return data;
      }
      return
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const post = async (
  block: any
): Promise<Record<string, IBuildingForm> | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.M1FormsTemplates,
      block,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      Setters.updates(data);
      return data;
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const patch = async (
  data: Partial<IBuildingForm> & { id: number }
): Promise<IExtendedBuildingForm | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.M1FormsTemplates}/${data.id}`,
      data,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const list: IExtendedBuildingForm[] = Object.values(response.data.result.summary)
      const data = list[0];
      if (data) {
        get()
        return data;
      }
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const preview = async (
  data: IFullFormVersion
): Promise<{ displaySchema: IDesignSchema } | undefined> => {
  try {
    const { designSchema, footerId, footerVersion, headerId, headerVersion } = data
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.M1FormsTemplates}/preview`,
      {
        designSchema: designSchema ? JSON.stringify(designSchema) : "{\"rows\":[]}", footerId, footerVersion, headerId, headerVersion
      },
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      return {
        displaySchema: JSON.parse(response.data.result)
      }
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const patchVersion = async (
  buildingBlockId: number,
  data: Partial<IFormVersion> & { id: number }
): Promise<IExtendedBuildingForm | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.M1FormsTemplates}/${buildingBlockId}/versions/${data.id}`,
      data,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const list: IExtendedBuildingForm[] = Object.values(response.data.result.summary)
      const data = list[0];
      if (data) {
        get()
        return data;
      }
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const deleteForm = async (
  id: number,
): Promise<boolean | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.delete(
      `${ApiUrl.M1FormsTemplates}/${id}`,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      return true
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const parseScheme = (data: IFullBuildingForm) => {
  const schema = data.versions[0]?.designSchema
  if (schema) {
    try {
      if (data.versions[0]) {
        // @ts-ignore
        data.versions[0].designSchema = JSON.parse(schema)
      }
    } catch (e) {
      console.log('schema not parsed')
    }
  }
  const displaySchema = data.versions[0]?.displaySchema
  if (displaySchema) {
    try {
      if (data.versions[0]) {
        // @ts-ignore
        data.versions[0].displaySchema = JSON.parse(displaySchema)
      }
    } catch (e) {
      console.log('displaySchema not parsed')
    }
  }
  return data
}

export const postBuildingFormVersion = async (data: Partial<IFullFormVersion> & {
  id: number
}): Promise<
  IExtendedBuildingForm | undefined
> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.M1FormsTemplates}/${data.id}/versions`,
      {
        ...data,
        designSchema: JSON.stringify(data.designSchema)
      },
      {
        withCredentials: true,
      }
    );
    if (response.data.success) {
      const list: IExtendedBuildingForm[] = Object.values(response.data.result.summary)
      const data = list[0];
      if (data) {
        get()
        return data
      }
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const getFormTemplateWithVersion = async (formId: number | string, version: number | string): Promise<
  IFullBuildingForm | undefined
> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.M1FormsTemplates,
      {
        withCredentials: true,
        params: {
          ids: [formId],
          version,
          format: ModelFormat.Full
        }
      }
    );
    if (response.data.success) {
      const list: IFullBuildingForm[] = Object.values(response.data.result.summary)
      const data = list[0];
      if (data) {
        return parseScheme(data)
      }
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const getFullBuildingFormForPreview = async (id: number | string, options: { disableOnlyPublished?: boolean }): Promise<
  IFullBuildingForm | undefined
> => {
  const { disableOnlyPublished } = options
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.M1FormsTemplates,
      {
        withCredentials: true,
        params: {
          ids: [id],
          onlyLatest: true,
          onlyPublished: disableOnlyPublished ? false : true,
          format: ModelFormat.Full
        }
      }
    );
    if (response.data.success) {
      const list: IFullBuildingForm[] = Object.values(response.data.result.summary)
      const data = list[0];
      if (data) {
        return parseScheme(data)
      }
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const getFullBuildingForm = async (id: number | string): Promise<
  IFullBuildingForm | undefined
> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.M1FormsTemplates,
      {
        withCredentials: true,
        params: {
          ids: [id],
          onlyLatest: true,
          format: ModelFormat.Full,
          IncludeSharedFormInformation: true
        }
      }
    );
    if (response.data.success) {
      const list: IFullBuildingForm[] = Object.values(response.data.result.summary)
      const data = list[0];
      if (data) {
        return parseScheme(data)
      }
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const getHeaderFooterList = async (): Promise<Record<string, ISimpleBuildBlock> | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.M1FormsBuildingBlocks,
      {
        withCredentials: true,
        params: {
          format: ModelFormat.Simple,
          onlyLatestPublished: true,
          types: [EnumM1FormBuildingBlockType.Footer, EnumM1FormBuildingBlockType.Header]
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      return data;
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const getBuildingBlocksList = async (): Promise<Record<string, ISimpleBuildBlock> | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.M1FormsBuildingBlocks,
      {
        withCredentials: true,
        params: {
          format: ModelFormat.Minimal,
          onlyPublished: true,
          types: [EnumM1FormBuildingBlockType.Content],
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      return data;
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const exportM1FormTemplate = async (formTemplateId: number): Promise<any> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.M1FormsTemplates}/${formTemplateId}/export`,
      {
        withCredentials: true,
      }
    );
    if (response.data.success) {
      const data = response.data.result.content;
      return data;
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const importM1FormTemplate = async (data: any): Promise<any> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.M1FormsTemplates}/import`,
      data,
      {
        withCredentials: true,
      }
    );
    if (response.data.success) {
      return true
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};

export const shareM1FormTemplate = async (formTemplateId: number): Promise<any> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.ShareFormTemplate}/form/share/${formTemplateId}`,
      {},
      {
        withCredentials: true,
      }
    );
    if (response.data.success) {
      return true
    }
    return;
  } catch (e) {
    console.error({ e });
    return;
  } finally {
  }
};