import _ from 'lodash';
import { IInventoryDto } from './types';

interface ITxnCodeInventoryData {
    summary: Record<string, IInventoryDto>
}

export interface IStateReducer {
    txnCodes: Record<string, ITxnCodeInventoryData>
}

export interface IAction {
    type: typeof actionsTypes[keyof typeof actionsTypes];
    payload: any;
    txnCode: string | number
}


const prefix = 'INVENTORY_';

export const actionsTypes = {
    SET_SUMMARY: `${prefix}SET_SUMMARY`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

const defaultTxnCodeData: ITxnCodeInventoryData = {
    summary: {}
}

export const defaultData: IStateReducer = {
    txnCodes: {}
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const txnCodeInventoryData = state.txnCodes[action.txnCode] || _.cloneDeep(defaultTxnCodeData)
    const actions: IActions = {
        [actionsTypes.SET_SUMMARY]: () => {
            return {
                ...state,
                txnCodes: {
                    ...state.txnCodes,
                    [action.txnCode]: {
                        ...txnCodeInventoryData,
                        summary: action.payload
                    }
                }
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
