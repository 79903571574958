import store from 'src/store'
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { expiryCheck } from 'src/App/LoginPage/store/Actions';
import { enableCohortLoader } from './cohortLoader';


export const ADD_COHORTS_WIDGET = "ADD_COHORTS_WIDGET";
export const UPDATE_COHORT_ID = "UPDATE_COHORT_ID";

export const SET_COHORT_DATA = "SET_COHORT_DATA";

export const setCohortData = (
  payload: any
) => {
  store.dispatch({
    type: SET_COHORT_DATA,
    payload
  })
};

export const addCohorts = (
  cohortWList: any, pagination: any
) => {
  return {
    type: ADD_COHORTS_WIDGET,
    cohortWList: cohortWList,
    pagination: pagination
  };
};

export const updateCohortId = (
  cohortId: string,
) => {
  return {
    type: UPDATE_COHORT_ID,
    cohortId: cohortId,
  };
};

export const getCohortList = (
  currentPage = 1,
  count = 20,
  sortBy: any,
  filter: any,
  currentPagination: any,
  callBack: any,
  _callBack?: any
): any => {
  let paginationDetails = {
    startIndex: (currentPage - 1) * 20,
    count: 20,
    sortBy: sortBy,
    filter: filter
  };

  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(ApiUrl.GET_ALL_COHORTS, paginationDetails, { withCredentials: true })
      .then(response => {
        if (callBack) {
          callBack();
        }
        let data: any = response.data;
        if (data.success) {
          let pagination = {
            ...currentPagination,
            total: data.result.total,
            current: currentPage
          };
          dispatch(addCohorts(data.result.summary, pagination));
          if (_callBack) {
            _callBack(data.result.summary);
          }
          if (!data.result.summary || !data.result.summary.length) {
            dispatch(enableCohortLoader(false));
          }
        } else {
          dispatch(enableCohortLoader(false));
        }
      })
      .catch(error => {
        if (callBack) callBack();
        dispatch(expiryCheck(error));
        dispatch(enableCohortLoader(false));
        console.log(error);
      });
  };
};