import _ from 'lodash'
import React from 'react';

class CacheStateData {
    private cacheData = {}

    // Used for scroll events
    onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>, link: string, fieldName: string) => {
        const target = e.target;
        // @ts-ignore
        const { scrollTop } = target;
        this.set(link, {
            [fieldName]: scrollTop
        })
    }
    setScrollPositionByRef = (link: string, field: string, ref: HTMLDivElement) => {
        const res = this.get(link);
        const data = res?.[field];
        if (data) {
            ref.scrollTo({
                top: data
            })
        }
    }
    setScrollPosition = (link: string, field: string) => {
        const res = this.get(link);
        const data = res?.[field];
        if (data) {
            const el = document.getElementById(field);
            if (el) {
                el.scrollTo({
                    top: data
                })
            }
        }
    }

    getLinkSeparation = (link: string) => {
        const separate = link.split('/').map((v) => v.replace(/\./g, ""))
        return separate
    }

    get = (link: string) => {
        const replaced = this.getLinkSeparation(link)
        const replacedString = replaced.join('.').substring(1)
        const data = this.cacheData[""]
        const obj = _.get(data, replacedString)
        if (obj) {
            return obj
        }
        return null
    }
    set = <T>(link: string, data: T) => {
        const separate = this.getLinkSeparation(link)
        if (separate.length > 0) {
            let obj = this.cacheData
            separate.forEach((key) => {
                if (!obj[key]) {
                    obj[key] = {}
                }
                obj = obj[key]
            })
            Object.assign(obj, data)
        }
    }
    remove = (link: string) => {
        const arr = this.getLinkSeparation(link)
        const lastIndex = arr.length - 1
        const last = arr[lastIndex]
        if (arr.length > 2) {
            arr.splice(lastIndex, 1)
            const replaced = arr.join('.').substring(1)
            const data = this.cacheData[""]
            const obj = _.get(data, replaced)
            if (obj) {
                delete obj[last]
            }
        } else {
            const data = this.cacheData[""]
            if (data?.[last]) {
                delete data[last]
            }
        }
    }
    // Need to use after logout
    clear = () => {
        this.cacheData = {}
    }
}

export const Cache = new CacheStateData()