import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Container, FormContainer } from './styled';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { ILegacyForm, Type } from 'src/Activities/Forms/Forms';
import LegacyFormContainer from 'src/Activities/Forms/LegacyForm/Container';
import PatientHeader from 'src/Activities/Subject/PatientHeader';

import * as actions from './Actions';
import { usePatients } from 'src/App/Patients/Hooks/usePatient';
import {
  removeCurrentActivity,
  updateCurrentActivity
} from 'src/App/ActivityPanel/Store/ActivityActions';
import { useAppSelector } from 'src/store';
import { getPatientForms } from 'src/Activities/Forms/FormList/PatientFormActions';

interface IProps {
  match: any;
}

const Component: FC<IProps> = ({ match }: IProps) => {
  const formsPatients = useAppSelector((state) => state.client.forms);
  const id = match.params.id;
  const patients = usePatients();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<ILegacyForm | null>(null);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      if (id) {
        const res = await actions.getForm(id);
        if (res) {
          const form: ILegacyForm = res.form;
          await getPatientForms(form.patientId);
          const patientName = await patients.getPatientNamePromise(
            form.patientId
          );
          updateCurrentActivity({
            title: patientName,
            subtitle: form.summary
          });
          setForm(form);
        }
      }
      setLoading(false);
    };
    init();
  }, []);

  const onRemove = useCallback(() => {
    removeCurrentActivity();
  }, []);

  const formSelected = useMemo(() => {
    const list = form
      ? Object.values(
          formsPatients[form.patientId]?.forms.summary || {}
        ).filter((v) => v.isActive && !v.invalid)
      : [];
    const formSelected = form
      ? list.find((v) => v.seedId === form.seedId)
      : null;
    if (formSelected) {
      return {
        ...formSelected,
        type: Type.LEGACY
      } as ILegacyForm;
    }
    return null;
  }, [formsPatients, form]);
  return (
    <Container>
      {loading && <AbsoluteLoader />}
      {formSelected && form && (
        <>
          <PatientHeader subjectId={`${form.patientId}`} />
          <FormContainer>
            <LegacyFormContainer
              key={`${formSelected.seedId}-${formSelected.status}`}
              formSelected={formSelected}
              match={match}
              onRemove={onRemove}
            />
          </FormContainer>
        </>
      )}
    </Container>
  );
};

export default React.memo(Component);
