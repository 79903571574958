import * as actionTypes from "./ReferralsActions";
import _ from 'lodash';

import { IReferral, ITypeReqPrioritySimpleDto, ITypeReqStatusSimpleDto } from "./types";
import { ISort } from "src/Framework/Controls/Table/types";


const prefix = 'REFERRALS_'

export const actionsTypes = {
	CHANGE_REFERRAL: `${prefix}CHANGE_REFERRAL`,
	SET_SORT: `${prefix}SET_SORT`,
}

const initialState: IReferralReducer = {
	reqStatuses: {},
	reqPriorities: {},
	reqOrders: {},
	reqOrdersDashboard: [],
	referralResults: {},
	sortTable: {}
};

interface IAction {
	type: string;
	patientId: number,
	reqOrderId: number,
	payload: any;
}

interface IReferralReducer {
	reqStatuses: Record<string, ITypeReqStatusSimpleDto>;
	reqPriorities: Record<string, ITypeReqPrioritySimpleDto>;
	reqOrders: {
		[patientId: string]: {
			[key: string]: IReferral
		}
	};
	reqOrdersDashboard: IReferral[];
	referralResults: any;
	sortTable: {
		[patientId: string]: ISort
	}
}

const ReferralsReducer = (
	state: IReferralReducer = _.cloneDeep(initialState),
	action: IAction
) => {
	switch (action.type) {
		case actionsTypes.SET_SORT: {
			const newState = _.cloneDeep(state)
			newState.sortTable[action.patientId] = action.payload
			return newState;
		}
		case actionTypes.SET_REQ_STATUSES: {
			let newState = _.cloneDeep(state)
			newState.reqStatuses = action.payload;
			return newState;
		}
		case actionTypes.SET_REQ_PRIORITIES: {
			let newState = _.cloneDeep(state)
			newState.reqPriorities = action.payload;
			return newState;
		}
		case actionTypes.SET_REQ_ORDERS: {
			let newState = _.cloneDeep(state)
			newState.reqOrders[action.patientId] = action.payload;
			return newState;
		}
		case actionTypes.SET_REQ_ORDERS_DASHBOARD: {
			let newState = _.cloneDeep(state)
			newState.reqOrdersDashboard = action.payload;
			return newState;
		}
		case actionTypes.SET_REQ_ORDER: {
			let newState = _.cloneDeep(state)
			newState.reqOrders[action.patientId][action.reqOrderId] = action.payload;
			return newState;
		}
		case actionTypes.SET_REQ_ORDER_LAST_READ: {
			let newState = _.cloneDeep(state)
			return newState;
		}
		case actionsTypes.CHANGE_REFERRAL: {
			let newState = _.cloneDeep(state)
			const a = newState.reqOrders[action.patientId]?.[action.payload.id] || {}
			if (newState.reqOrders[action.patientId]) {
				newState.reqOrders[action.patientId][action.payload.id] = { ...a, ...action.payload };
			}
			return newState;
		}
		case actionTypes.SET_REFERRAL_RESULTS: {
			let newState = _.cloneDeep(state)
			newState.referralResults[action.reqOrderId] = action.payload;
			return newState;
		}
		case actionTypes.SET_REFERRAL_RESULT: {
			let newState = _.cloneDeep(state)
			newState.referralResults[action.reqOrderId] = {
				...state.referralResults[action.reqOrderId],
				[action.payload.id]: action.payload
			};
			return newState;
		}
		case actionTypes.SET_REFERRAL_RESULTS_LOG: {
			let newState = _.cloneDeep(state)
			newState.reqOrders[action.patientId][action.reqOrderId].log = action.payload;
			return newState;
		}
		default:
			return state;
	}
};
export default ReferralsReducer;