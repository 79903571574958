import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import {
    ICohortOverview,
    IRule,
    ICohortData,
    IRuleDefinition,
    ICohort,
    IRuleMetaData,
    IFilterData,
    IComplianceMessages,
    IStateReducer,
    ICohortPatientStatistics,
    ICompliancePatientsHeader
} from '../types';
import { notification } from 'antd';
import {
    setPreference,
    updateCurrentUserPreference
} from 'src/App/Preferences/Actions';
import { PrefEnum } from 'src/App/Preferences/utils';
import { ISort } from 'src/Framework/Controls/Table/types';
import { upperFirst } from 'lodash';
import { defaultSources } from '../../utils';
import {
    getEligibility,
    getResidencyType,
    getSchoolType,
    getStanding,
    getStudentClass
} from '../../Components/RuleEditor/RuleDesigner/Options/SingleSelect/Actions';

export const getRules = async (): Promise<IRule[] | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            `${ApiUrl.GET_RULES}`,
            {
                sortBy: 'NameAsc',
                filter: {
                    searchString: '',
                    enabled: true
                }
            },
            {
                withCredentials: true
            }
        );
        if (response.data.success) {
            return response.data.result.summary;
        }
        return;
    } catch (e) {
        return;
    } finally {
    }
};

export const getRuleDefinition = async (rule: {
    ruleId: string;
    currentVersion: string;
}): Promise<IRule | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            `${ApiUrl.GetRuleDefinition}`,
            {
                withCredentials: true,
                params: {
                    ruleId: rule.ruleId,
                    version: rule.currentVersion
                }
            }
        );
        if (response.data.success) {
            return response.data.result;
        }
        return;
    } catch (e) {
        return;
    } finally {
    }
};

export const getRuleInfo = async (ruleId: string): Promise<IRule | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            `${ApiUrl.GetRuleInfo}`,
            {
                withCredentials: true,
                params: {
                    ruleId
                }
            }
        );
        if (response.data.success) {
            return response.data.result;
        }
        return;
    } catch (e) {
        return;
    } finally {
    }
};

export const getCohortOverview = async (
    cohortName: string
): Promise<ICohortOverview | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            ApiUrl.GET_COHORT_OVERVIEW,
            { cohortName },
            { withCredentials: true }
        );
        if (response.data.success) {
            return response.data.result.overview;
        }
        return;
    } catch (e) {
        return;
    }
};

export const getCohortOverviewEx = async (
    cohortId: string
): Promise<ICohortOverview | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            ApiUrl.GET_COHORT_OVERVIEW_EX,
            { cohortId },
            { withCredentials: true }
        );
        if (response.data.success) {
            return response.data.result.overview;
        }
        return;
    } catch (e) {
        return;
    }
};

export const postCreateCohort = async (
    data: ICohortData
): Promise<{ cohortId: string; jobId: string } | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            '/Cohort/CreateCohort',
            data,
            {
                withCredentials: true
            }
        );
        if (response.data.success) {
            return response.data.result;
        }
        return;
    } catch (e) {
        return;
    }
};

export const updateCohort = async (
    data: ICohortData
): Promise<{ cohortId: string; jobId: string } | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            '/Cohort/UpdateCohort',
            {
                ...data
            },
            {
                withCredentials: true,
                params: {
                    cohortId: data.cohortId
                }
            }
        );
        if (response.data.success) {
            return response.data.result;
        }
        return;
    } catch (e) {
        return;
    }
};

export const postAddCompliance = async (data: any) => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            ApiUrl.ADD_COMPLIANCE,
            data,
            { withCredentials: true }
        );
        if (response.data.success) {
            return true;
        }
        return;
    } catch (e) {
        return;
    }
};

export const removeCompliance = async (data: any) => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            ApiUrl.REMOVECOMPLIANCECRITERIA,
            data,
            { withCredentials: true }
        );
        if (response.data.success) {
            return true;
        }
        return;
    } catch (e) {
        return;
    }
};

export const postPublishRuleDefinition = async (
    data: Partial<IRuleDefinition>
) => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            '/Rule/Publish?ruleId=' + data.id,
            data,
            { withCredentials: true }
        );
        if (response.data.success) {
            return true;
        }
        return;
    } catch (e) {
        return;
    }
};

export const postNewRule = async (data: {
    name: string;
    description: string;
    user: null;
    ruleType: number;
}): Promise<{ id: string } | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            '/Rule/CreateRule',
            data,
            {
                withCredentials: true
            }
        );
        if (response.data.success) {
            return response.data.result;
        }
        return;
    } catch (e) {
        return;
    }
};

export const getAllCohorts = async (): Promise<ICohort[] | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            ApiUrl.GET_ALL_COHORTS,
            {
                startIndex: 0,
                sortBy: 'nameAsc',
                filter: ''
            },
            { withCredentials: true }
        );
        if (response.data.success) {
            return response.data.result.summary;
        }
        return;
    } catch (e) {
        return;
    }
};

export const removeCohort = async (
    cohortId: string
): Promise<boolean | undefined> => {
    try {
        const response = await await APIHandler.AxiosInstance.post(
            '/Cohort/RemoveCohort',
            {
                cohortId
            },
            { withCredentials: true }
        );
        if (response.data.success) {
            return true;
        }
        return;
    } catch (e) {
        return;
    }
};

export const isCohortExist = async (
    cohortName: string
): Promise<boolean | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            '/Cohort/IsCohortExist',
            { cohortName: cohortName },
            { withCredentials: true }
        );
        if (response.data.success) {
            return true;
        } else {
            notification.error({
                message: response.data.error || 'Error'
            });
        }
        return;
    } catch (e) {
        return;
    }
};

export const enableRule = async (
    data: IRuleMetaData
): Promise<boolean | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            '/Rule/EnableRule',
            {
                id: data.ruleId,
                name: data.ruleName,
                enabled: true,
                ruleVersion: data.currentVersion
            },
            {
                withCredentials: true
            }
        );
        if (response.data.success) {
            return true;
        }
        return;
    } catch (e) {
        return;
    }
};

export const GetRuleMetaDataByName = async (
    ruleName: string
): Promise<IRuleMetaData | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            '/Rule/GetRuleMetaDataByName',
            {
                withCredentials: true,
                params: {
                    ruleName
                }
            }
        );
        if (response.data.success) {
            return response.data.result;
        }
        return;
    } catch (e) {
        return;
    }
};

const count = 20;

const generateSort = (sort?: ISort) => {
    const sortData = sort ? { ...sort } : sort;
    if (sortData?.sortBy === 'ruleComplianceStatusName') {
        sortData.sortBy = 'ruleComplianceStatus';
    }
    if (sortData?.sortBy === 'lastActivityDateTime') {
        sortData.sortBy = 'lastActivityDate';
    }
    if (sortData?.sortBy === 'reviewedDateTime') {
        sortData.sortBy = 'reviewedDate';
    }
    if (sortData?.sortBy === 'statusId') {
        sortData.sortBy = 'complianceStatus';
    }
    return sortData
}

const generateParams = (data: IFilterData) => {
    const params = { ...data };
    Object.keys(params).forEach((key) => {
        if (Array.isArray(params[key])) {
            if (params[key].length === 0) {
                delete params[key];
            } else {
                params[key] = params[key].join(',');
            }
        }
    });
    return params
}

export const getCompliancePatients = async (
    data: IFilterData,
    startIndex?: number,
    sort?: ISort
): Promise<IStateReducer['compliancePatients'] | undefined> => {
    try {
        const sortData = generateSort(sort)
        const params = generateParams(data)
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.CompliancePatients,
            {
                withCredentials: true,
                params: {
                    ...params,
                    count,
                    startIndex: (startIndex || 0) * count,
                    sortBy: upperFirst(sortData?.sortBy || 'patientId'),
                    sortType: sortData?.sortType || 'asc'
                }
            }
        );
        if (response.data.success) {
            return response.data.result;
        }
        return;
    } catch (e) {
        return;
    }
};

export const getCompliancePatientsIds = async (
    data: IFilterData,
    sort?: ISort
): Promise<number[] | undefined> => {
    try {
        const sortData = generateSort(sort)
        const params = generateParams(data)
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.CompliancePatientsIds,
            {
                withCredentials: true,
                params: {
                    ...params,
                    sortBy: upperFirst(sortData?.sortBy || 'patientId'),
                    sortType: sortData?.sortType || 'asc'
                }
            }
        );
        if (response.data.success) {
            return response.data.result;
        }
        return;
    } catch (e) {
        return;
    }
};

export const getMessages = async (
    providerIds: number[]
): Promise<IComplianceMessages[] | undefined> => {
    try {
        const paginationDetails = {
            providerIds,
            includeInactive: false,
            sortBy: 'Asc',
            filter: {
                read: false,
                searchString: ''
            }
        };
        const response = await APIHandler.AxiosInstance.post(
            ApiUrl.GET_MESSAGES,
            paginationDetails,
            { withCredentials: true }
        );
        if (response.data.success) {
            return response.data.result.summary;
        }
        return;
    } catch (e) {
        return;
    }
};

export const getCohortPatientStatistics = async (): Promise<
    Record<string, ICohortPatientStatistics> | undefined
> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.cohortPatientStatistics,
            { withCredentials: true }
        );
        if (response.data.success) {
            return response.data.result.summary;
        }
        return;
    } catch (e) {
        return;
    }
};

export const getCompliancePatientsHeader = async (
    data: IFilterData
): Promise<ICompliancePatientsHeader | undefined> => {
    try {
        const params = { ...data };
        Object.keys(params).forEach((key) => {
            if (Array.isArray(params[key])) {
                if (params[key].length === 0) {
                    delete params[key];
                } else {
                    params[key] = params[key].join(',');
                }
            }
        });
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.compliancePatientHeader,
            {
                withCredentials: true,
                params: {
                    ...params
                }
            }
        );
        if (response.data.success) {
            return response.data.result;
        }
        return;
    } catch (e) {
        return;
    }
};

export const saveMessagesProviders = async (providerCodes: string[]) => {
    const userId = localStorage.getItem('userId');
    const userPreferenceInfo = {
        userId,
        preferences: [
            {
                id: PrefEnum.MessageProviderFilter.id,
                userId,
                name: PrefEnum.MessageProviderFilter.name,
                value: JSON.stringify(providerCodes),
                defaultValue: ''
            }
        ]
    };
    try {
        updateCurrentUserPreference(userPreferenceInfo.preferences);
        const response = await setPreference(userPreferenceInfo);
        const data: any = response.data;
        if (data.success && data.result.success) {
            return data;
        }
        return;
    } catch (error) {
        console.log({ error });
        return;
    }
};

export const saveComplianceFilter = async (data: IFilterData) => {
    const userId = localStorage.getItem('userId');
    const userPreferenceInfo = {
        userId,
        preferences: [
            {
                id: PrefEnum.ActivityComplianceFilter.id,
                userId,
                name: PrefEnum.ActivityComplianceFilter.name,
                value: JSON.stringify(data),
                defaultValue: ''
            }
        ]
    };
    try {
        updateCurrentUserPreference(userPreferenceInfo.preferences);
        const response = await setPreference(userPreferenceInfo);
        const data: any = response.data;
        if (data.success && data.result.success) {
            return data;
        }
        return;
    } catch (error) {
        console.log({ error });
        return;
    }
};

export const getSources = async () => {
    const data = defaultSources();
    const ResidencyType = await getResidencyType();
    if (ResidencyType) {
        data.ResidencyType = ResidencyType.map((v) => ({
            value: v.id,
            label: v.name
        }));
    }
    const SchoolType = await getSchoolType();
    if (SchoolType) {
        data.SchoolType = SchoolType.map((v) => ({
            value: v.id,
            label: v.name
        }));
    }
    const StudentClass = await getStudentClass();
    if (StudentClass) {
        data.StudentClass = StudentClass.map((v) => ({
            value: v.id,
            label: v.name
        }));
    }
    const Standing = await getStanding();
    if (Standing) {
        data.Standing = Standing.map((v) => ({
            value: v.id,
            label: v.name
        }));
    }
    const Eligibility = await getEligibility();
    if (Eligibility) {
        data.Eligibility = Eligibility.map((v) => ({
            value: v.id,
            label: v.name
        }));
    }
    return data;
};

export const GetAllComplianceCriteriaEx = async (cohortId: string): Promise<{ ruleId: string, id: string }[] | undefined> => {
    try {
        const response = await await APIHandler.AxiosInstance.post(
            '/Cohort/GetAllComplianceCriteriaEx',
            {
                cohortId,
                startIndex: 0,
                count: 30,
                filter: {
                    searchString: '',
                    include: true
                }
            },
            {
                withCredentials: true
            }
        );
        if (response.data.success) {
            return response.data.result.summary
        }
        return
    } catch (e) {
        return
    }
};
