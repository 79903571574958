import React, { FC } from 'react';
import { Row } from 'antd';
import classNames from 'classnames';

import SketchPicker from '../SketchPicker';

import { ColorPickerContainer, ColorContainer } from '../styled';

import { ICommon } from '../index';

export interface IColoPicker extends ICommon {
  type: 'color-picker';
  /**
   * @param preferColorsList list of colors that will show over the element
   */
  preferColorsList?: string[];
  onChange?: (color: string) => any;
  value: null | undefined | string;
}

const Component: FC<IColoPicker> = (props: IColoPicker) => {
  const { preferColorsList, onChange, value } = props;
  return (
    <ColorPickerContainer>
      {preferColorsList && (
        <Row align="middle">
          {preferColorsList.map((color) => (
            <ColorContainer
              onClick={() => onChange && onChange(color)}
              className={classNames({
                enabled: `${value}` === color
              })}
              style={{ backgroundColor: color }}
            />
          ))}
        </Row>
      )}
      <SketchPicker
        color={`${value}`}
        handleChange={(color: any) => {
          onChange && onChange(color);
        }}
      />
    </ColorPickerContainer>
  );
};

export default Component;
