import { ISort } from 'src/Framework/Controls/Table/types';

export interface IStateReducer {
  filter: IFilter;
  sort: ISort;
}

export interface IAction {
  type: string;
  payload: any;
}

export enum TypeRadio {
  SUPERVISOR = 'SUPERVISOR',
  TRAINEE = 'TRAINEE'
}

export interface IFilter {
  id?: string
  providersTrainees: number[]
  providersSupervisors: number[]
  providerTypes: number[]
  radio: TypeRadio
  mode: TypeModes
  groupBy: TypeGroupBy
}

export enum TypeModes {
  GOALS = 'GOALS',
  ASSIGNED = 'ASSIGNED'
}

export enum TypeGroupBy {
  NONE = 'NONE',
  SUPERVISOR_ROLE = 'SUPERVISOR_ROLE',
  SUPERVISOR_AND_SUPERVISOR_ROLE = 'SUPERVISOR_AND_SUPERVISOR_ROLE'
}

export type TypeFavorite = ({
  id: string,
  name: string
  data: Partial<IFilter>
})[]