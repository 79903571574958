import { useCallback, useMemo, useRef, useState } from "react";

function preventDefault(e: Event) {
    if (!isTouchEvent(e)) return;

    if (e.touches.length < 2 && e.preventDefault) {
        e.preventDefault();
    }
};

export function isTouchEvent(e: Event): e is TouchEvent {
    return e && "touches" in e;
};

interface PressHandlers<T> {
    onLongPress?: (e: React.MouseEvent<T> | React.TouchEvent<T>) => void,
    onClick?: (e: React.MouseEvent<T> | React.TouchEvent<T>) => void,
    onChangePercentage?: (value: number) => any
}

interface Options {
    delay?: number,
    shouldPreventDefault?: boolean
    touchOnly?: boolean
    loaderId?: string
}

export default function useLongPress<T>(
    { onLongPress, onClick, onChangePercentage }: PressHandlers<T>,
    { delay = 1000, shouldPreventDefault = true, touchOnly = false }
        : Options
        = {}
) {
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const startProgress = useRef<null | number>(null)
    const timeout = useRef<NodeJS.Timeout>();
    const target = useRef<EventTarget>();

    const startProgressAnimation = useCallback(() => {

        function step(timestamp: number) {
            if (!target.current) return
            if (!startProgress.current) startProgress.current = timestamp;
            const progress = Math.min((timestamp - startProgress.current) / delay, 1);
            const percentage = progress * 100;
            if (onChangePercentage) {
                onChangePercentage(percentage)
            }

            if (progress < 1) {
                requestAnimationFrame(step);
            }
        }

        requestAnimationFrame(step);
    }, [onChangePercentage])

    const stopProgressAnimation = useCallback(() => {
        startProgress.current = null
        if (onChangePercentage) {
            onChangePercentage(0)
        }
    }, [onChangePercentage])

    const start = useCallback(
        (e: React.MouseEvent<T> | React.TouchEvent<T>) => {
            if (!onLongPress) return
            e.persist();
            const clonedEvent = { ...e };

            if (shouldPreventDefault && e.target) {
                e.target.addEventListener(
                    "touchend",
                    preventDefault,
                    { passive: false }
                );
                target.current = e.target;
            }
            startProgressAnimation()
            timeout.current = setTimeout(() => {
                onLongPress(clonedEvent);
                setLongPressTriggered(true);
            }, delay);
        },
        [onLongPress, delay, shouldPreventDefault]
    );

    const clear = useCallback((
        e: React.MouseEvent<T> | React.TouchEvent<T>,
        shouldTriggerClick = true
    ) => {
        stopProgressAnimation()
        timeout.current && clearTimeout(timeout.current);
        shouldTriggerClick && !longPressTriggered && onClick?.(e);

        setLongPressTriggered(false);

        if (shouldPreventDefault && target.current) {
            target.current.removeEventListener("touchend", preventDefault);
        }
        target.current = undefined
    },
        [shouldPreventDefault, onClick, longPressTriggered]
    );

    const events = useMemo(() => {
        if (touchOnly) {
            return {
                onTouchStart: (e: React.TouchEvent<T>) => start(e),
                onTouchEnd: (e: React.TouchEvent<T>) => clear(e),
                onTouchMove: (e: React.TouchEvent<T>) => clear(e),
            }
        }
        return {
            onMouseDown: (e: React.MouseEvent<T>) => start(e),
            onMouseUp: (e: React.MouseEvent<T>) => clear(e),
            onMouseLeave: (e: React.MouseEvent<T>) => clear(e, false),
            // Touch
            onTouchStart: (e: React.TouchEvent<T>) => start(e),
            onTouchEnd: (e: React.TouchEvent<T>) => clear(e),
            onTouchMove: (e: React.TouchEvent<T>) => clear(e),
        }
    }, [start, clear])

    return {
        events,
        longPressTriggered
    };
};