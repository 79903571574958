import React, { FC, useState } from 'react';
import { Button, Row, Col } from 'antd';

import ButtonIcon, {
  ICONS_LIST,
  IIcon
} from 'src/Framework/Controls/ButtonIcon';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Modal, { IProps as IModalProps } from 'src/Framework/Controls/Modal';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import ids from 'src/tests-script/pages/Framework/Components/RemoveButton';

import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import { Container, ModalContainer, Text } from './styled';

interface IProps {
  removeAction?: (e: any) => Promise<any>;
  tooltip?: string;
  buttonIconProps?: Partial<IIcon>;
  approve?: boolean;
  approveText?: string;
  id?: string;
  modalProps?: Partial<IModalProps>;
  restoreMode?: boolean;
}

const defaultWidth = 500;

const Component: FC<IProps> = ({
  removeAction,
  tooltip,
  buttonIconProps,
  approve,
  approveText,
  id,
  modalProps,
  restoreMode
}: IProps) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  useModalFocus({ active: visible });
  const onClick = async (e: any) => {
    if (loading) return;
    setLoading(true);
    if (removeAction) {
      e.preventDefault();
      await removeAction(e);
      close();
    }
    setLoading(false);
  };
  const close = () => {
    setVisible(false);
  };

  const onKeyDownAccessibility = (e: React.KeyboardEvent<any>) => {
    if (
      (e.key === 'Enter' || e.code === 'Space') &&
      (e.target as HTMLElement).tagName === 'BUTTON'
    ) {
      e.preventDefault();
      if (e.currentTarget) {
        // @ts-ignore
        e.currentTarget.click();
      }
    }
  };

  const title = restoreMode ? 'Restore' : 'Remove';

  const headerRight = (
    <ButtonIcon
      disableBackground={true}
      name={ICONS_LIST.thinClose}
      isButton={true}
      label="close dialog"
      onClick={close}
      size={24}
    />
  );

  return (
    <Container>
      {loading && <AbsoluteLoader />}
      <ButtonIcon
        id={id}
        tooltip={tooltip}
        name={restoreMode ? ICONS_LIST.restore : ICONS_LIST.delete}
        color={globalStyleVariables.brandGray}
        onClick={(e) => {
          if (approve) {
            setVisible(true);
          } else {
            onClick(e);
          }
        }}
        changeColorOnHover={true}
        {...buttonIconProps}
        isButton={buttonIconProps?.isButton || true}
        containerProps={{
          'aria-label': restoreMode ? 'Restore button' : 'Remove button',
          ...buttonIconProps?.containerProps
        }}
      />
      <Modal
        width={defaultWidth}
        title={title}
        visible={visible}
        onClose={close}
        headerBorder={true}
        hideClose={true}
        headerProps={{
          style: {
            paddingRight: 24
          }
        }}
        buttons={headerRight}
        {...modalProps}
        modalProps={{
          ...modalProps?.modalProps,
          wrapClassName: 'alert-modal'
        }}
        modalSliderProps={{
          defaultWidth: `${defaultWidth}px`,
          minWidth: `${defaultWidth}px`,
          isOpen: visible,
          onClose: close,
          headerRight: undefined,
          title
        }}
      >
        <ModalContainer>
          <Row
            tabIndex={0}
            gutter={16}
            style={{
              flexWrap: 'nowrap'
            }}
            align="middle"
          >
            <Col span={17}>
              <Text>
                {approveText
                  ? approveText
                  : `Are you sure that you want to ${
                      restoreMode ? 'restore' : 'remove'
                    } this item?`}
              </Text>
            </Col>
            <Col>
              <Row
                gutter={16}
                style={{
                  flexWrap: 'nowrap'
                }}
                align="middle"
              >
                <Col>
                  <Button
                    id={ids.APPROVE_MODAL_YES_BUTTON}
                    loading={loading}
                    onKeyDown={onKeyDownAccessibility}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onClick(e);
                    }}
                  >
                    Yes
                  </Button>
                </Col>
                <Col>
                  <Button
                    onKeyDown={onKeyDownAccessibility}
                    id={ids.APPROVE_MODAL_NO_BUTTON}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      close();
                    }}
                  >
                    No
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default Component;
