import { ApiUrl } from './../../../../../Framework/Common/ApiUrl';
import * as actionType from '../ActionTypes';
import { ITemplateOption } from '../../Models/RuleTemplate';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';


export const addRuleComponentOptionAction = (ruleId: string, option: ITemplateOption, componentId: number, optionVal: string, screenX: number, screenY: number, eventId?: string) => {
    return {
        type: actionType.ADD_COMPONENT_OPTION,
        ruleId,
        option,
        componentId,
        optionVal,
        screenX,
        screenY,
        eventId
    };
};

export const hideRuleOptionModal = (ruleId: string) => {
    return {
        type: actionType.HIDE_RULE_OPTION_MODEL,
        ruleId: ruleId,
    };
};

export const updateRuleToShow = (ruleId: string) => {
    return {
        type: actionType.UPDATE_RULE_TO_SHOW,
        ruleId: ruleId,
    };
};
export const addImmunizations = (immunizations: any, ruleId: string, cmpId: number, pagination: any) => {
    return {
        type: actionType.ADD_IMMUNIZATIONS,
        immunizations: immunizations,
        ruleId: ruleId,
        componentId: cmpId,
        pagination: pagination
    };
};
export const addImmunizationsSeries = (immunizationSeries: any, ruleId: string, cmpId: number, pagination: any) => {
    return {
        type: actionType.ADD_IMMUNIZATIONS_SERIES,
        immunizationSeries: immunizationSeries,
        ruleId: ruleId,
        componentId: cmpId,
        pagination: pagination
    };
};
export const addProcedures = (procedures: any, ruleId: string, cmpId: number, pagination: any) => {
    return {
        type: actionType.ADD_PROCEDURES,
        procedures: procedures,
        ruleId: ruleId,
        componentId: cmpId,
        pagination: pagination
    };
};
export const addImPros = (imPros: any, ruleId: string, cmpId: number, pagination: any) => {
    return {
        type: actionType.ADD_IMMUNIZATION_PROCEDURES,
        imPros: imPros,
        ruleId: ruleId,
        componentId: cmpId,
        pagination: pagination
    };
};

/**
 * todo
 * methods with same content, it better to combine it, and didnt write everywhere if statements where you need to
 * choose which to call
 * */
export const getImmunizationSeries = (request: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_IMMUNIZATIONS, request, { withCredentials: true });
};
export const getImmunizations = (request: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_IMMUNIZATIONS, request, { withCredentials: true });
};
export const getImmunizationsForFacts = (request: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_IMMUNIZATIONS_FOR_FACTS, request, { withCredentials: true });
};
export const getProcedures = (request: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_PROCEDURES, request, { withCredentials: true });
};
export const getAllFacts = (request: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_ALL_FACTS, request, { withCredentials: true });
};
export const getDemographicsComponent = (request: any) => {
    return APIHandler.AxiosInstance.post(ApiUrl.GET_DEMOGRAPHICS_COMPONENT, request, { withCredentials: true });
};