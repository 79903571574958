export interface IActivity {
  activityId: string;
  content: IActivityContent;
  renderComponent: any;
  type: ActivityType;
  closable: boolean;
  targetURL: string;
  isCurrent: boolean;
  currentURL: string;
  cache: any;
  title?: string;
  subtitle?: string;
  openedTimes?: number;
  isActivityCompliance?: boolean;
  cacheId?: string;
}

export interface IActivityContent extends Object {
  id: string;
}

export enum ActivityType {
  Admin = 'admin',
  WaitingListSettings = 'waiting-list-settings',
  Dashboard = 'dashboard',
  Scheduler = 'scheduler',
  Patients = 'patients',
  Compliance = 'compliance',
  Orders = 'orders',
  Cohort = 'cohort',
  Billing = 'billing',
  RulesManager = 'rules-manager',
  SCPM = 'scpm',
  Reports = 'reports',
  CATS = 'cats',
  SystemSetup = 'system-setup',
  Messages = 'messages',

  SecureMessageNew = 'new-secure-message',
  SecureMessageForward = 'forward-secure-message',
  SecureMessageReply = 'reply-secure-message',
  InternalMessageNew = 'internal-message-new',
  InternalMessageForward = 'internal-message-forward',
  InternalMessageReply = 'internal-message-reply',
  InternalMessage = 'InternalMessage',

  PatientHeaderSettings = 'patient-header-settings',
  RuleEditor = 'rule-editor',
  RuleViewer = 'rule-viewer',
  NotificationViewer = 'notification-viewer',
  NotificationManager = 'notification-manager',
  CohortManager = 'cohort-manager',
  NewCohort = 'new-cohort',
  Subject = 'subject',
  Subjects = 'subjects',
  Counseling = 'counseling',
  CohortComplianceViewer = 'cohort-compliance-viewer',
  NewMessage = 'new-message',
  ReplyMessage = 'reply-message',
  ForwardMessage = 'forward-message',
  Client = 'client',
  ClientManager = 'client-manager',
  Activity = 'activity',
  DashboardSettings = 'counseling-dashboard-settings',
  Jobs = 'jobs',
  UserPreferences = 'user-preferences',
  BuildingBlock = 'building-block',
  BuildingForm = 'building-form',
  Trainee = 'trainee',
  ConflictManager = 'conflict-resolution-manager',
  NewCohortManager = 'new-cohort-manager',
  NewCohortCreation = 'new-cohort-creation',
  ActivityPage = 'activity-page',
  FormViewer = 'form-viewer',
  TraineeFormViewer = 'trainee-form-viewer',
  NoteViewer = 'note-viewer',
  FileViewer = 'file-viewer'
}
