import _ from 'lodash';
import { IStateReducer, IAction, IAllergiesData } from './types';

const prefix = 'PATIENT_ALLERGIES_';

export const actionsTypes = {
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_SUMMARY: `${prefix}SET_SUMMARY`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

export const defaultPatientData: IAllergiesData = {
    filter: {
        onlyActive: true
    },
    summary: {}
};

export const defaultData: IStateReducer = {
    patients: {}
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const patientData = state.patients[action.patientId] || _.cloneDeep(defaultPatientData)
    const actions: IActions = {
        [actionsTypes.SET_FILTER]: () => {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patientData,
                        filter: {
                            ...patientData.filter,
                            ...action.payload
                        }
                    }
                }
            };
        },
        [actionsTypes.SET_SUMMARY]: () => {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patientData,
                        summary: action.payload
                    }
                }
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
