import React, { FC, useCallback, useState, useRef } from 'react';

import { focusableDOMItems } from 'src/App/Accessibility/Components/A11yAutofocusContainer';

import { IColumnFilter } from '../types';
import Popover from '../../Popover';
import ButtonIcon, { ICONS_LIST } from '../../ButtonIcon';

import { Container, IconContainer, ContentContainer } from './styled';

interface IProps extends Partial<IColumnFilter> {}

const Component: FC<IProps> = ({
  filterContent,
  filterEnabled,
  filterTooltip,
  onPopoverStateChanged
}: IProps) => {
  const popupRef = useRef(null);
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const onOpenChange = useCallback(
    (value: boolean) => {
      setOpen(value);
      if (onPopoverStateChanged) {
        onPopoverStateChanged(value);
      }
    },
    [onPopoverStateChanged]
  );

  const onKeyDownPopupContainer = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      if (buttonRef.current) {
        e.preventDefault();
        //@ts-ignore
        buttonRef.current.focus();
      }
      setOpen(false);
    }
    if (
      e.key === 'Tab' &&
      !e.shiftKey &&
      (e.target as HTMLElement) !== popupRef.current
    ) {
      const focusableElements = (
        popupRef?.current as unknown as HTMLElement
      ).querySelectorAll(focusableDOMItems);
      const lastFocusableElement =
        focusableElements[focusableElements.length - 1];
      const overlay = document.getElementsByClassName(
        'popover-global-overlay-container'
      );
      let overlayFocusableElements,
        firstOverlayFocusableElement,
        lastOverlayFocusableElement;
      if (overlay && overlay[0]) {
        overlayFocusableElements = (overlay[0] as HTMLElement).querySelectorAll(
          focusableDOMItems
        );
        firstOverlayFocusableElement = overlayFocusableElements[0];
        lastOverlayFocusableElement =
          overlayFocusableElements[overlayFocusableElements.length - 1];
      }
      if (e.target === lastFocusableElement) {
        e.preventDefault();
        if (overlay && firstOverlayFocusableElement) {
          //@ts-ignore
          firstOverlayFocusableElement.focus();
        } else {
          if (buttonRef.current) {
            //@ts-ignore
            buttonRef.current.focus();
          }
        }
      }
      if (
        overlay &&
        lastOverlayFocusableElement &&
        e.target === lastOverlayFocusableElement
      ) {
        e.preventDefault();
        //@ts-ignore
        lastFocusableElement.focus();
      }
    }
    if (e.target === e.currentTarget && e.key === 'Tab' && e.shiftKey) {
      if (buttonRef.current) {
        e.preventDefault();
        //@ts-ignore
        buttonRef.current.focus();
      }
    }
    if (
      e.key === 'ArrowRight' ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowUp'
    ) {
      e.stopPropagation();
    }
  };

  const onKeyDownButton = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      e.stopPropagation();
      if (e.currentTarget) {
        // @ts-ignore
        e.currentTarget.click();
      }
    }
    if (e.key === 'Tab' && !e.shiftKey && open) {
      if (popupRef.current) {
        e.preventDefault();
        //@ts-ignore
        popupRef.current.focus();
      }
    }
    if (e.key === 'Tab' && e.shiftKey && open) {
      setOpen(false);
    }
  };

  if (!filterContent) {
    return null;
  }
  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        if (e.code === 'Space' || e.key === 'Enter') {
          e.stopPropagation();
        }
      }}
    >
      <Popover
        id="table-filter-popup"
        content={
          <ContentContainer
            tabIndex={0}
            ref={popupRef}
            onKeyDown={onKeyDownPopupContainer}
          >
            {filterContent({ onOpenChange })}
          </ContentContainer>
        }
        trigger={'click'}
        open={open}
        onOpenChange={onOpenChange}
        placement="bottom"
      >
        <IconContainer>
          <ButtonIcon
            name={ICONS_LIST.filter2}
            isButton={true}
            haspopup={'dialog'}
            controlsId="table-filter-popup"
            label={
              filterEnabled
                ? `values are filtered: ${filterTooltip ? filterTooltip : ''}`
                : 'filter values'
            }
            size={14}
            iconScale={1}
            tooltip={filterTooltip ? filterTooltip : undefined}
            color={filterEnabled ? 'currentColor' : 'rgba(153, 153, 153, 1)'}
            containerProps={{
              onKeyDown: (e: React.KeyboardEvent) => onKeyDownButton(e),
              ref: buttonRef
            }}
          />
        </IconContainer>
      </Popover>
    </Container>
  );
};

export default React.memo(Component);
