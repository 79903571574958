import globalStyleVariables from "src/Framework/Styles/variables.module.scss";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex: 1;
`

export const MainContainer = styled.div`
  position:relative;
  display: flex;
  flex-direction: column;
  flex:1;
`

export const PageContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

export const SideBarContainer = styled.div`
  position: relative;
  border-right: 1px solid ${globalStyleVariables.borderColor};
  .displayNone {
    display: none;
  }
  >div{
    display:flex;
    flex:1;
    flex-direction: column;
  }
`

export const ColResizer = styled.div`
  position: absolute;
  right: 0;
  width: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 1px;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  &:hover {
    > div {
      background-image: linear-gradient(rgba(0,0,0,0.2) 60%,rgba(0,0,0,0) 0%);
      background-position: right;
      background-size: 1px 10px;
      background-repeat: repeat-y;
      border-right: unset;
    }
  }
`

export const SidebarHeader = styled.div`
    background-color: ${globalStyleVariables.newBrandingPrimary300};
    padding: 25px 20px;
    font-family: Encode Sans Expanded;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    color: white;
`

export const SidebarBody = styled.div`
  position: relative;
  display: flex;
  flex:1;
  flex-direction: column;
`

export const SidebarFooter = styled.div`
    padding: 25px 20px;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
`

export const LogoutButton = styled.div`
  cursor: pointer;
`