import { ISort } from 'src/Framework/Controls/Table/types';
import { SharedFormStatus } from 'src/ManagementSystem/Pages/Dashboard/Components/Status/utils';

export interface ISimpleBlockVersion {
  buildingBlockId: number;
  version: number;
  name: string;
  description: string;
  createDts: string;
  createUserId: number;
  status: M1FormBuildingBlockVersionStatus;
}

export enum M1FormBuildingBlockVersionStatus {
  Invalid = 'invalid',
  Published = 'published',
  Drafted = 'drafted',
  Discarded = 'discarded'
}

export interface IFullBuildBlockVersion extends ISimpleBlockVersion {
  schema: any;
}

export interface ISimpleBuildBlock {
  id: number;
  isActive: boolean;
  type: EnumM1FormBuildingBlockType;
  createDts: string;
  createUserId: number;
  modifyDts: string;
  modifyUserId: number;
  tags: number[];
  latestPublishedVersion: number;
  isShareAvailable: boolean
  draftVersion?: string | number;
  name: string;
  importedEntityId: null | number
  isImported: boolean | null
  isUpdateAvailable: boolean
  sharedBuildingBlockStatus: SharedFormStatus
  isShared: boolean
}

export interface IExtendedBuildBlock extends ISimpleBuildBlock {
  versions: ISimpleBlockVersion[];
}

export interface IFullBuildBlock extends IExtendedBuildBlock {
  relatedTemplates: IRelatedTemplates[];
  versions: IFullBuildBlockVersion[];
}

interface IRelatedTemplates {
  templates: ITemplates[];
  version: number;
}

interface ITemplates {
  id: number;
  name: string;
  version: number;
}

export interface IBuildBlocks {
  [key: string]: ISimpleBuildBlock;
}

export interface IStateReducer {
  summary: IBuildBlocks;
  loading: boolean;
  filters: IFilters;
  sort: ISort;
}

export interface IFilters {
  searchString: string;
  isInactive: boolean;
  pageNumber: number
}

export interface IAction {
  type: string;
  payload: any;
}

export enum EnumM1FormBuildingBlockType {
  Invalid = 'invalid',
  Content = 'content',
  Header = 'header',
  Footer = 'footer'
}

export enum ModelFormat {
  Invalid = 'invalid',
  Minimal = 'minimal',
  Simple = 'simple',
  Extended = 'Extended',
  Full = 'full',
  Empty = 'empty'
}

export interface IBlockRequest {
  name: string;
  description: string;
  type: EnumM1FormBuildingBlockType;
}
