import React, { FC, useRef, useState } from 'react';

import SelectContainer from 'src/Framework/Controls/SelectContainer/index';
import Table from 'src/Framework/Controls/Table';
import { ISelect } from 'src/Framework/Controls/SelectContainer/Components/Select';
import { IProps as ITableProps } from 'src/Framework/Controls/Table/types';

import { TableContainer } from './styles';

interface ITableSelect extends Omit<ISelect, 'type'> {
  type: 'table';
}

interface IOwnProps {
  tableProps: ITableProps;
  onSelectItem: (value: any) => void;
  filterFieldName?: string;
  dropdownWidth?: number;
}

export type IProps = ITableSelect & IOwnProps;

const Component: FC<IProps> = (props) => {
  const [isPopupVisible, setPopupVisible] = useState<boolean>(false);
  const tableRef = useRef<any>(null);
  const selectorRef = useRef<any>(null);
  const [filterString, setFilterString] = useState('');
  const {
    tableProps,
    filterFieldName,
    connectToContainerOnScroll,
    containerRef
  } = props;
  const onKeyDownSelector = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setPopupVisible(!isPopupVisible);
    }
    if (e.key === 'Tab' && isPopupVisible) {
      if (tableRef.current) {
        tableRef.current.focus();
      }
    }
  };
  const onKeyDownRow = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape' && isPopupVisible) {
      e.preventDefault();
      setPopupVisible(false);
      setFilterString('');
      if (selectorRef.current) {
        selectorRef.current.focus();
      }
    }
  };
  const onKeyDownTable = (e: React.KeyboardEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      <label
        htmlFor={props.inputProps.id ? props.inputProps.id : props.id}
        className="visually-hidden"
      >
        {props.label + (props.value ? '. ' + props.value : '')}
      </label>
      <SelectContainer
        {...props}
        aria-required={props.star}
        aria-label={props.label + (props.value ? '. ' + props.value : '')}
        label={props.label + (props.value ? '. ' + props.value : '')}
        hideLabel={true}
        type="select"
        getPopupContainer={() =>
          tableProps.containerId
            ? document.getElementById(tableProps.containerId)
            : connectToContainerOnScroll && containerRef
            ? () => containerRef.current
            : undefined
        }
        loading={tableProps.loading}
        inputProps={{
          onSearch: (searchString: any) => {
            setFilterString(searchString);
          },
          ref: selectorRef,
          onKeyDown: onKeyDownSelector,
          open: isPopupVisible,
          onDropdownVisibleChange: (open: boolean) => {
            setPopupVisible(open);
          },
          dropdownClassName: 'table-dropdown-class',
          dropdownRender: (originNode: any) => {
            return (
              <TableContainer
                ref={tableRef}
                tabIndex={0}
                style={{
                  minWidth: 320,
                  width: props.dropdownWidth ? props.dropdownWidth : '100%'
                }}
                onMouseDown={(e) => e.preventDefault()}
                onKeyDown={onKeyDownTable}
              >
                <Table
                  {...tableProps}
                  data={
                    filterFieldName && filterString !== ''
                      ? tableProps.data?.filter((item: any) =>
                          item[filterFieldName]
                            .toLowerCase()
                            .includes(filterString.toLowerCase())
                        )
                      : tableProps.data
                  }
                  onKeyDownRow={onKeyDownRow}
                  absoluteContainer={true}
                  headerBackground="#F7F7F7"
                  rowClassname="table-selector-row"
                  onClickRow={(index, value) => {
                    props.onSelectItem(value);
                    setPopupVisible(false);
                    setFilterString('');
                    if (selectorRef.current) {
                      selectorRef.current.focus();
                    }
                  }}
                />
              </TableContainer>
            );
          }
        }}
      />
    </>
  );
};

export default Component;
