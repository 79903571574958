import globalStyleVariables from "src/Framework/Styles/variables.module.scss";
import styled from "styled-components";

export const Container = styled.div`
    padding: 20px 24px;
    border-bottom: 1px solid ${globalStyleVariables.borderColor};
    border-top: 4px solid ${globalStyleVariables.newBrandingPrimary200};
    .back-button {
        height: 30px;
        width: 30px;
        mask-size: contain;
        mask-position: center center;
        mask-repeat: no-repeat no-repeat;
        background: ${globalStyleVariables.newBrandingPrimary200};
        margin: 0 10px 0 0;
        cursor: pointer;

        &:active {
        transform: scale(0.9);
        }
    }
`

export const Title = styled.div`
    font-family: Encode Sans Expanded;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    color: #333;
`