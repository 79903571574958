import React from 'react';

import { getStudentStatusTerm } from 'src/Framework/util/patient-term-util';

import { updateCurrentActivity } from 'src/App/ActivityPanel/Store/ActivityActions';
import { genericTypeMainUrl } from '../../utils';
import { permissionChecking } from 'src/App/User/Permission';
import history from 'src/history';

const ManufacturerCode = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ManufacturerCode" */ './Pages/Custom/ManufacturerCode'
    )
);

const GroupTemplateForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "GroupTemplateForm" */ './Pages/Custom/GroupTemplateForm'
    )
);

export const goToGenericType = (id: string | number) => {
  updateCurrentActivity({
    targetURL: `${genericTypeMainUrl}/${id}`
  });
};

interface IGenericTypesData {
  name: string;
  shortName: string;
  id: GenericIdsTypes;
  hidded?: boolean;
  permission?: () => {
    success: boolean;
    message: string;
  };
}

export const AllGenericTypesData: IGenericTypesData[] = [
  {
    name: 'Diagnosis Groups',
    shortName: 'Diagnosis Group',
    id: 'groupDiagnosis'
  },
  {
    name: 'Transaction Groups',
    shortName: 'Transaction Group',
    id: 'groupTxn'
  },
  {
    name: 'Insurance Groups',
    shortName: 'Insurance Group',
    id: 'groupInsurance'
  },
  {
    name: 'Provider Groups',
    shortName: 'Provider Group',
    id: 'groupProvider'
  },
  {
    name: 'Fee Schedules',
    shortName: 'Fee Schedule',
    id: 'typeFee'
  },
  {
    name: 'Referral Groups',
    shortName: 'Referral Group',
    id: 'groupReferral'
  },
  {
    name: 'Calendar Item Groups',
    shortName: 'Calendar Item Group',
    id: 'groupAppointmentReason'
  },
  {
    name: 'Eligibility Statuses',
    shortName: 'Eligibility Status',
    id: 'typeEligibility'
  },
  {
    name: 'Inventory Packaging Units',
    shortName: 'Inventory Packaging Unit',
    id: 'typePackagingUnit'
  },
  {
    name: 'Immunization Administration Methods',
    shortName: 'Immunization Administration Method',
    id: 'typeAdministrationMethod'
  },
  {
    name: 'Facility Groups',
    shortName: 'Facility Group',
    id: 'groupFacility'
  },
  {
    name: 'Inventory Adjustment Reason Groups',
    shortName: 'Inventory Adjustment Reason Group',
    id: 'groupInventoryAdjustmentReason'
  },
  {
    name: 'Patient Groups',
    shortName: 'Patient Group',
    id: 'groupPatient'
  },
  {
    name: 'School Names',
    shortName: 'School Name',
    id: 'typeSchool'
  },
  {
    name: getStudentStatusTerm(),
    shortName: getStudentStatusTerm(),
    id: 'typeStudentStatus'
  },
  // {
  //     name: 'Rx Verbs',
  //     id: 'rxVerb'
  // },
  // {
  //     name: 'Rx Forms',
  //     id: 'rxForm'
  // },
  // {
  //     name: 'Rx Descriptions',
  //     id: 'rxDescription'
  // },
  // {
  //     name: 'Rx Frequencies',
  //     id: 'rxFrequency'
  // },
  // {
  //     name: 'Rx TimeUnits',
  //     id: 'rxTimeUnits'
  // },
  {
    name: 'Immunization Waivers',
    shortName: 'Immunization Waiver',
    id: 'typeWaiver'
  },
  {
    name: 'Student Standings',
    shortName: 'Student Standing',
    id: 'typeStanding'
  },
  {
    name: 'Student Classes',
    shortName: 'Student Class',
    id: 'typeClass'
  },
  // {
  //     name: 'Reason for Visit',
  //     id: 'chiefComplaint'
  // },
  {
    name: 'Current Room of Appointment',
    shortName: 'Current Room of Appointment',
    id: 'typeRoom'
  },
  {
    name: 'Group Lab',
    shortName: 'Group Lab',
    id: 'groupReqLab'
  },
  {
    name: 'Source Of Specimen',
    shortName: 'Source Of Specimen',
    id: 'typeSourceOfSpecimen'
  },
  {
    name: 'Units of Measure',
    shortName: 'Units of Measure',
    id: 'typeMeasureUnit'
  },
  {
    name: 'Primary Sport',
    shortName: 'Primary Sport',
    id: 'typeSport'
  },
  {
    name: 'Lab Specimen Type',
    shortName: 'Lab Specimen Type',
    id: 'typeSpecimen'
  },
  {
    name: 'Acuity Levels',
    shortName: 'Acuity Level',
    id: 'typeAcuity'
  },
  {
    name: 'Diagnosis Status',
    shortName: 'Diagnosis Status',
    id: 'typeDiagnosisStatus'
  },
  {
    name: 'Rx Discontinue Reason',
    shortName: 'Rx Discontinue Reason',
    id: 'typeRxDiscontinueReason'
  },
  {
    name: 'Patient Summary (Expire) Reason',
    shortName: 'Patient Summary (Expire) Reason',
    id: 'typeExpireReason'
  },
  {
    name: 'Message Status',
    shortName: 'Message Status',
    id: 'typeMessageStatus'
  },
  {
    name: 'EMR Appointment Seen By',
    shortName: 'EMR Appointment Seen By',
    id: 'typeEMRSeenBy'
  },
  {
    name: 'Medication Status',
    shortName: 'Medication Status',
    id: 'typeMedicationStatus'
  },
  {
    name: 'Residence',
    shortName: 'Residence',
    id: 'typeResidency'
  },
  // {
  //     name: 'User Groups',
  //     id: 'groupUser'
  // },
  {
    name: 'Ethnicity',
    shortName: 'Ethnicity',
    id: 'typeEthnicity2010'
  },
  {
    name: 'Race',
    shortName: 'Race',
    id: 'typeRace'
  },
  {
    name: 'Countries',
    shortName: 'Country',
    id: 'typeCountry'
  },
  {
    name: 'Smoking Statuses',
    shortName: 'Smoking Status',
    id: 'typeSmokingStatus'
  },
  {
    name: 'Patient Portal Immunization Display Box',
    shortName: 'Patient Portal Immunization Display Box',
    id: 'typeOSHImmEntryFrame'
  },
  {
    name: 'Chart Shredder Reasons',
    shortName: 'Chart Shredder Reason',
    id: 'typeShredReason'
  },
  {
    name: 'Preferred Gender Pronoun',
    shortName: 'Preferred Gender Pronoun',
    id: 'typePreferredPronoun'
  },
  {
    name: 'Sexual Orientation',
    shortName: 'Sexual Orientation',
    id: 'typeSexualOrientation'
  },
  {
    name: 'Gender Identity',
    shortName: 'Gender Identity',
    id: 'typeGenderIdentity'
  },
  {
    name: 'Major',
    shortName: 'Major',
    id: 'typeMajor'
  },
  {
    name: 'Gender',
    shortName: 'Gender',
    id: 'typeGender'
  },
  // {
  //     name: 'Disability',
  //     id: 'typeDisability'
  // },
  // {
  //     name: 'Immune Status',
  //     id: 'typeImmuneStatus'
  // },
  {
    name: 'Relationship',
    shortName: 'Relationship',
    id: 'typeRelationship'
  },
  // {
  //     name: 'CHAP Referral',
  //     id: 'typeCHAPReferral'
  // },
  // {
  //     name: 'Over 90 Day Reasons',
  //     id: 'typeOver90DayReasons'
  // },
  // {
  //     name: 'Service Auth',
  //     id: 'typeServiceAuth'
  // },
  // {
  //     name: 'Medicare Secondary Payor',
  //     id: 'typeMedicareSecondaryPayor'
  // },
  //  {
  //     name: 'Abortion Sterilization',
  //     id: 'typeAbortionSterilization'
  // },
  // {
  //     name: 'EPSDT Referral Code',
  //     id: 'typeEPSDTReferralCode'
  // },
  {
    name: 'Trainee Type',
    shortName: 'Trainee Type',
    id: 'traineeType',
    hidded: true
  },
  {
    name: 'Manufacturer Code',
    shortName: '',
    id: 'manufacturer',
    permission: () => {
      return permissionChecking({
        anyClinic: true,
        moduleId: 32,
        functionId: 1
      });
    }
  },
  {
    name: 'Residence Hall',
    shortName: 'esidence Hall',
    id: 'campusHousing'
  },
  {
    name: 'Allergy inactivation reasons',
    shortName: 'Allergy inactivation reason',
    id: 'typeAllergyInactive'
  },
  {
    name: 'Form Groups',
    shortName: 'Form Group',
    id: 'groupTemplateForm'
  },
  {
    name: 'Portal Education Materials Group',
    shortName: 'Portal Education Materials Group',
    id: 'portalExternalLinkCategory'
  },
  {
    name: 'Report types',
    shortName: 'Report type',
    id: 'typeOrderReport'
  }
  // {
  //   name: 'Report types',
  //   shortName: 'Report type',
  //   id: 'typeReportAggregate'
  // }
];

export const GenericTypesData = AllGenericTypesData.filter((v) => !v.hidded);
GenericTypesData.sort((a, b) => a.name?.localeCompare(b.name));

export const getGenericTypesData = () => {
  return GenericTypesData.filter((v) =>
    v.permission ? v.permission().success : true
  );
};

export const GenericTypeIds = [
  'campusHousing',
  'manufacturer',
  'typeCDCDefinition',
  'groupDiagnosis',
  'groupTxn',
  'groupInsurance',
  'groupProvider',
  'typeFee',
  'groupReferral',
  'groupAppointmentReason',
  'typeEligibility',
  'typePackagingUnit',
  'typeAdministrationMethod',
  'groupFacility',
  'groupInventoryAdjustmentReason',
  'groupPatient',
  'typeSchool',
  'typeStudentStatus',
  'rxVerb',
  'rxForm',
  'rxDescription',
  'rxFrequency',
  'rxTimeUnits',
  'typeWaiver',
  'typeStanding',
  'typeClass',
  'chiefComplaint',
  'typeRoom',
  'groupReqLab',
  'typeSourceOfSpecimen',
  'typeMeasureUnit',
  'typeSport',
  'typeSpecimen',
  'typeAcuity',
  'typeDiagnosisStatus',
  'typeRxDiscontinueReason',
  'typeExpireReason',
  'typeMessageStatus',
  'typeEMRSeenBy',
  'typeMedicationStatus',
  'typeResidency',
  'groupUser',
  'typeShredReason',
  'typeEthnicity2010',
  'typeRace',
  'typeCountry',
  'typeSmokingStatus',
  'typeProvider',
  'typeOSHImmEntryFrame',
  'typeShredReason',
  'typePreferredPronoun',
  'typeSexualOrientation',
  'typeGenderIdentity',
  'typeMajor',
  'typeGender',
  'typeDisability',
  'typeImmuneStatus',
  'typeRelationship',
  'typeCHAPReferral',
  'typeOver90DayReasons',
  'typeServiceAuth',
  'typeMedicareSecondaryPayor',
  'typeAbortionSterilization',
  'typeEPSDTReferralCode',
  'traineeType',
  'txnTypeOfService',
  'typeTemperature',
  'typeAllergy',
  'typeAdjustment',
  'typeImmEligibility',
  'facility',
  'category',
  'typeAllergyInactive',
  'typeAllergyReaction',
  'typeAllergySeverity',
  'portalExternalLinkCategory',
  'groupTemplateForm',
  'typeReqStatus',
  'typeReqPriority',
  'typeReportAggregate',
  'typeOrderReport',
  'typeInterfaceTask',
  'typeLabAccount',
] as const;

export const DropdownManagementBuckets = GenericTypeIds.reduce(
  (a, v) => ({ ...a, [v]: v }),
  {}
);

export type GenericIdsTypes = typeof GenericTypeIds[number];

const CustomPages: {
  [key in GenericIdsTypes]?: React.LazyExoticComponent<any>;
} = {
  manufacturer: ManufacturerCode,
  groupTemplateForm: GroupTemplateForm
};

export const Pages: {
  key: GenericIdsTypes;
  component: React.LazyExoticComponent<any> | null;
  permission: IGenericTypesData['permission'];
}[] = GenericTypeIds.map((v) => ({
  key: v,
  component: CustomPages[v] || null,
  permission: AllGenericTypesData.find((val) => val.id === v)?.permission
}));

export const redirectCurrentTabToType = (type: GenericIdsTypes) => {
  const link = `/admin/group-types/${type}`;
  history.push(link);
  updateCurrentActivity({
    targetURL: link
  });
};
