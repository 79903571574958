import _ from 'lodash'
import { IAction, IStateReducer, IPatientData } from './types'

const prefix = 'MEDICATIONS_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_CURRENT_SORT: `${prefix}SET_CURRENT_SORT`,
    SET_DISCONTINUED_SORT: `${prefix}SET_DISCONTINUED_SORT`,
}

export const initialData: IPatientData = {
    summary: {},
    currentSort: {
        sortBy: 'timestamp',
        sortType: 'desc'
    },
    discontinuedSort: {
        sortBy: 'timestamp',
        sortType: 'desc'
    }
}

const initialState: IStateReducer = {
    patients: {}
};

const Reducer = (state: IStateReducer = _.cloneDeep(initialState), action: IAction): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET_DISCONTINUED_SORT: {
            const patiendData = state.patients[action.patientId] || _.cloneDeep(initialData)
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patiendData,
                        discontinuedSort: action.payload
                    }
                }
            }
        }
        case actionsTypes.SET_CURRENT_SORT: {
            const patiendData = state.patients[action.patientId] || _.cloneDeep(initialData)
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patiendData,
                        currentSort: action.payload
                    }
                }
            }
        }
        case actionsTypes.SET: {
            const patiendData = state.patients[action.patientId] || _.cloneDeep(initialData)
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patiendData,
                        summary: action.payload
                    }
                }
            }
        }
        default:
            return state;
    }
};

export default Reducer;
