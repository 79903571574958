import { BroadcastSubscription, BroudcastChannelNames } from "../";

export let isMainTab = window.opener ? false : true
const channel = new BroadcastSubscription()
channel.connection({
    channel: BroudcastChannelNames.MainTabClosed,
    onmessage: ({ data }: {
        data: { type: string, message: string }
    }) => {
        if (!isMainTab) {
            if (data.type === 'closed') {
                window.close()
            }
        }
    }
})

export const CloseAllChildrenTabs = () => {
    if (isMainTab) {
        channel.postMessage({
            type: 'closed',
            message: 'Main tab closed'
        })
    }
}

window.addEventListener("beforeunload", function (e) {
    CloseAllChildrenTabs()
    return;
});

export const MainTabClosedChannel = channel