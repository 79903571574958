import _ from "lodash";
const actionManager = {
  CHANGE_UPDATE_STATUS: "CHANGE_UPDATE_STATUS"
};

const intialState = {
  updateStatus: false
};

interface IAction {
  type: string;
  updateStatus: boolean;
}

const RuleUpdateReducer = (state: any = intialState, action: IAction) => {
  switch (action.type) {
    case actionManager.CHANGE_UPDATE_STATUS: {
      return {
        ...state,
        updateStatus: action.updateStatus
      };
    }
    default:
      return state;
  }
};
export default RuleUpdateReducer;


export const changeUpdateStatus = (updateStatus:boolean) => {
    return {
      type: actionManager.CHANGE_UPDATE_STATUS,
      updateStatus:updateStatus
    };
  };