import { BucketsList } from "src/Framework/ApiSubscription";
import { RequestHelper } from "src/Framework/ApiSubscription/RequestHelper";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { IM1FormRecordSubmission } from "../types";
import { setSummary } from "./Setters";

export const SubscriptionData = (FormRecordId: string | number) => ({
    bucket: BucketsList.m1FormRecordSubmission,
    filter: `${BucketsList.m1FormRecordSubmission}.FormRecordId == ${FormRecordId}`
})

export const { get, findAndUnsubscribe } = new RequestHelper<IM1FormRecordSubmission, [FormRecordId: string | number]>({
    link: ApiUrl.M1FormRecords,

    subscription: {
        subscriptionData: SubscriptionData,
        setSummary,
        parseMethod: (data) => {
            const obj = {}
            Object.values(data).forEach((value) => {
                obj[value.formRecordId] = value
            })
            return obj
        },
    }
})
