import styled, { css } from "styled-components";
import * as color from 'src/CommonStyles/styles'

interface IActionBar {
    saving: boolean
}

export const ActionBarContainer = styled.div<IActionBar>`
    background: ${color.white_color};
    height: 64px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 4px solid ${color.border_color};
    padding-right: 8px;
    ${({saving})=>saving && `
        pointer-events: none;
        filter: grayscale(1);
    `}
`;

export const ButtonContainer = styled.div`
    margin: 0 5px;
`

interface IIConContainer {
    disableHover?: boolean
    active?: any
    disabled?: boolean
}

export const IconContainer = styled.div`
    position: relative;
    cursor: pointer;
    margin: 0 8px;
    height: 32px;
    ${({ disableHover }: IIConContainer) => !disableHover && `
    &:hover {
        svg > path {
            stroke: ${color.white_color};
            fill: ${color.blue_color}; 
        } 
        svg > circle {
            fill: ${color.blue_color};
            stroke: ${color.white_color};
        } 
        svg > rect {
            fill: ${color.white_color};
        } 
    }
    `}
    ${(props: IIConContainer) => props.active && css`
        svg > path {
            stroke: ${color.white_color};
            fill: ${color.blue_color}; 
        } 
        svg > circle {
            fill: ${color.blue_color};
            stroke: ${color.white_color};
        } 
        svg > rect {
            fill: ${color.white_color};
        }
    `}
     ${(props: IIConContainer) => props.disabled && css`
        cursor: not-allowed;
        svg > path {
            stroke: ${color.white_color};
            fill: ${color.input_disabled_text_color}; 
        } 
        svg > circle {
            stroke: ${color.input_normal_bg_color}; 
            fill: ${color.input_normal_bg_color}; 
        } 
        svg > rect {
            fill: ${color.white_color}; 
        }
        &:hover {
            svg > path {
                stroke: ${color.white_color};
                fill: ${color.input_disabled_text_color}; 
            } 
            svg > circle {
                stroke: ${color.input_normal_bg_color}; 
                fill: ${color.input_normal_bg_color}; 
            } 
            svg > rect {
                fill: ${color.white_color};
            } 
        }
    `}
`;

export const VerticalLine = styled.div`
    width: 1px;
    height: 32px;
    background: ${color.border_color};
    margin: 16px;
`;
