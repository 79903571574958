import React, { FC, useMemo } from 'react';
import _ from 'lodash';

import Table from 'src/Framework/Controls/Table';
import Checkbox from 'src/Framework/Controls/Checkbox';
import ColorSelector from 'src/Framework/Controls/Selectors/ColorSelector';
import { IColumn } from 'src/Framework/Controls/Table/types';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

import { isRiskModuleEnabled } from 'src/App/Settings/utils';
import { IValue } from './SettingsContainer';

export interface IOwnProps {
  data: IValue;
  onChange: (v: IValue) => any;
}

interface IProps extends IOwnProps {}

const Component: FC<IProps> = ({ data, onChange }: IProps) => {
  const settingsData = useMemo(() => {
    if (isRiskModuleEnabled()) {
      return [
        {
          title: 'Alerts',
          value: 'alerts'
        },
        {
          title: 'Risks',
          value: 'risks'
        }
      ];
    }
    return [
      {
        title: 'Alerts',
        value: 'alerts'
      }
    ];
  }, []);

  return (
    <Table
      data={settingsData.map((v) => {
        const active = data.show[v.value];
        const item = data.items?.[v.value] || {
          color: undefined,
          titleOnly: undefined
        };
        return {
          ...v,
          title: `[${v.title}]`,
          show: (
            <Checkbox
              onKeyDown={onKeyDownAccessibility}
              id={'show-item-' + v.title}
              checked={active}
              onChange={() => {
                const newData = _.cloneDeep(data);
                newData.show[v.value] = !active;
                onChange(newData);
              }}
            >
              <label className="visually-hidden">show</label>
            </Checkbox>
          ),
          color: (
            <ColorSelector
              selectProps={{
                hideLabel: true,
                bottomMargin: false
              }}
              value={item.color}
              onChange={(color) => {
                const newData = _.cloneDeep(data);
                newData.items = {
                  ...newData.items,
                  [v.value]: {
                    ...item,
                    color
                  }
                };
                onChange(newData);
              }}
            />
          ),
          titleOnly: (
            <Checkbox
              onKeyDown={onKeyDownAccessibility}
              id={'title_only-' + v.title}
              checked={item.titleOnly}
              onChange={() => {
                const newData = _.cloneDeep(data);
                newData.items = {
                  ...newData.items,
                  [v.value]: {
                    ...item,
                    titleOnly: !item.titleOnly
                  }
                };
                onChange(newData);
              }}
            >
              <label className="visually-hidden">title only</label>
            </Checkbox>
          )
        };
      })}
      columns={columns}
    />
  );
};

export default React.memo(Component);

const columns: IColumn[] = [
  {
    title: 'Item',
    key: 'title',
    flex: '425px'
  },
  {
    title: 'Show',
    key: 'show',
    flex: '80px',
    centered: true
  },
  {
    title: 'Color',
    flex: '300px',
    key: 'color'
  },
  {
    title: 'Title Only',
    flex: '80px',
    key: 'titleOnly',
    centered: true
  }
];
