import { INotificationSummary } from '../Models/Rule';
import * as actionTypes from './NotificationManagerActions';
import _ from 'lodash';
import { actionsTypes } from 'src/App/Admin/Pages/NoteTypes/store/Reducer';
import { ISort } from 'src/Framework/Controls/Table/types';

const initialState: any = {
  pagination: {
    current: 1
  },
  summary: [],
  notification: [],
  codesAndTypes: [],
  sort: {
    sortBy: 'nameString',
    sortType: 'asc'
  }
};

interface IAction {
  type: string;
  pagination: any;
  ruleSummary: INotificationSummary[];
  id: string;
  isEnabled: boolean;
  name: string;
  description: string;
  notification: any;
  notificationId: string;
  codesAndTypes: any;
  sort?: Partial<ISort>;
}

const NotificationManagerReducer = (
  state: any = { ...initialState },
  action: IAction
) => {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATIONS: {
      let newState = _.cloneDeep(state);
      if (action.pagination.current > 1) {
        let isExistSameRule: boolean = isExistRule(
          newState.summary,
          action.ruleSummary
        );
        if (isExistSameRule) return state;
        newState.summary = [...newState.summary, ...action.ruleSummary];
      } else {
        newState.summary = action.ruleSummary;
      }
      newState.pagination = action.pagination;
      return newState;
    }
    case actionTypes.ADD_NOTIFICATION: {
      let newState = _.cloneDeep(state);
      let index: number = newState.notification.findIndex(
        (r: any) => r.id === action.notification.id
      );
      if (index === -1) {
        newState.notification.push(action.notification);
      }
      return newState;
    }
    case actionTypes.ADD_LETTER_CODES: {
      let newState = _.cloneDeep(state);
      newState.codesAndTypes = action.codesAndTypes;
      return newState;
    }

    case actionTypes.CLEAR_RULES: {
      let newState = _.cloneDeep(state);
      newState = initialState;
      return newState;
    }

    case actionTypes.UPDATE_NOTIFICATION_TEMPLATE: {
      let newState = _.cloneDeep(state);
      let index: number = newState.notification.findIndex(
        (r: any) => r.id === action.notification.templateId
      );
      if (index !== -1) {
        newState.notification[index].description =
          action.notification.description;
        newState.notification[index].textInLetter =
          action.notification.textInLetter;
        newState.notification[index].code = action.notification.code;
        newState.notification[index].typeLetterId =
          action.notification.typeLetterId;
      }
      return newState;
    }

    case actionTypes.REMOVE_NOTFICATION: {
      let newState = _.cloneDeep(state);
      let notifications: any[] = [...newState.summary];
      let index: number = notifications.findIndex(
        (r: INotificationSummary) => r.id === action.notificationId
      );
      notifications.splice(index, 1);
      newState.summary = notifications;
      if (newState.pagination.total > 0) {
        newState.pagination.total -= 1;
      }
      return newState;
    }
    case actionsTypes.SET_TABLE_SORT:
      return { ...state, sort: action.sort };
    case actionTypes.CREATE_NOTIFICATION: {
      let newState = _.cloneDeep(state);
      newState.summary.push(action.notification);
      return newState;
    }

    default:
      return state;
  }
};
export default NotificationManagerReducer;

// to check whether a rule already exist in the store
function isExistRule(stateRules: any, actionRules: any): boolean {
  if (actionRules && actionRules.length > 0) {
    let action_rule: any = actionRules[0];
    let isExistRule: any = stateRules.find((rule: any) => {
      return rule.id === action_rule.id;
    });
    if (isExistRule) return true;
  }
  return false;
}
