import _ from 'lodash';
import { NoteRisk } from './types';

export interface IStateReducer {
    notes: Record<string, INoteState>
}

interface INoteState {
    summary: Record<string, NoteRisk>
}

export interface IAction {
    type: typeof actionsTypes[keyof typeof actionsTypes];
    payload: any;
    noteId: string
}


const prefix = 'NOTE_RISKS_';

export const actionsTypes = {
    SET_SUMMARY: `${prefix}SET_SUMMARY`,
    SET_SUMMARY_ITEM: `${prefix}SET_SUMMARY_ITEM`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

const defaultNoteData: INoteState = {
    summary: {}
}

export const defaultData: IStateReducer = {
    notes: {},
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const noteData = state.notes[action.noteId] || _.cloneDeep(defaultNoteData)
    const actions: IActions = {
        [actionsTypes.SET_SUMMARY_ITEM]: () => {
            return {
                ...state,
                notes: {
                    ...state.notes,
                    [action.noteId]: {
                        ...noteData,
                        summary: {
                            ...noteData.summary,
                            [action.payload.id]: action.payload
                        }
                    }
                }
            };
        },
        [actionsTypes.SET_SUMMARY]: () => {
            return {
                ...state,
                notes: {
                    ...state.notes,
                    [action.noteId]: {
                        ...noteData,
                        summary: action.payload
                    }
                }
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
