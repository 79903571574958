import _ from 'lodash';
import { IRule, IValidationResult } from 'src/Activities/RuleEditor/Store/Models/RuleDefinition';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

const ActionManager = {
    ADD_MEMBER_COMPLIANCE_RULE: "ADD_MEMBER_COMPLIANCE_RULE",
    ADD_MEMBER_COMPLIANCE_VALIDATIONRESULT: "ADD_MEMBER_COMPLIANCE_VALIDATIONRESULT",
}

interface IStateReducer {
    rule: any,
    validationResult: any,
}

const initialState: IStateReducer = {
    rule: null,
    validationResult: null,
}

interface IAction {
    type: string;
    rule: IRule;
    validationResult: IValidationResult;
}

const MemberComplianceAnalysisReducer = (
    state = initialState,
    action: IAction
): IStateReducer => {
    switch (action.type) {
        case ActionManager.ADD_MEMBER_COMPLIANCE_RULE: {
            return {
                ...state,
                rule: action.rule
            }
        }
        case ActionManager.ADD_MEMBER_COMPLIANCE_VALIDATIONRESULT: {
            return {
                ...state,
                validationResult: action.validationResult
            }
        }

        default:
            return state;
    }
};
export default MemberComplianceAnalysisReducer;

export const addMemberComplianceRule = (rule: any) => {
    return {
        type: ActionManager.ADD_MEMBER_COMPLIANCE_RULE,
        rule: rule
    }
}
export const addMemberComplianceValidationResult = (validationResult: any) => {
    return {
        type: ActionManager.ADD_MEMBER_COMPLIANCE_VALIDATIONRESULT,
        validationResult: validationResult
    }
}

export const getExecutionResult = (
    ruleId: any,
    subId: any,
    ruleVersion: number
) => {
    return APIHandler.AxiosInstance.get(ApiUrl.GET_EXECUTION_RESULT + subId + "&ruleId=" + ruleId + "&ruleVersion=" + ruleVersion, { withCredentials: true }
    );
};