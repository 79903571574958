import { TempFieldsForTable } from "src/Framework/Controls/Table/types";
import store from "src/store";
import { actionsTypes } from "..";
import { ITraineeGoal } from "../types";

export const onCreateUpdateItem = (payload: ITraineeGoal & Partial<TempFieldsForTable>) => {
    const data = { ...(store.getState().trainee.dashboard.goals.providers[payload.traineeId]?.summary || {}) }
    if (payload.id) {
        if (data[payload.id]) {
            data[payload.id] = { ...data[payload.id], ...payload }
        } else {
            data[payload.id] = payload
        }
    }
    setSummary(data, [payload.traineeId])
}

export const setSummary = (payload: Record<string, ITraineeGoal>, providerIds: (string | number)[]) => {
    providerIds.forEach((providerId) => {
        const data = {}
        Object.entries(payload).forEach(([key, value]) => {
            if (`${value.traineeId}` === `${providerId}`) {
                data[key] = value
            }
        })
        store.dispatch({
            type: actionsTypes.SET,
            payload: data,
            providerId
        })
    })
}

export const onRemoveItem = (id: string | number) => {
    const providers = store.getState().trainee.dashboard.goals.providers;
    const findProviderKey = Object.keys(providers).find(
        (v) => providers[v]?.summary[id]
    );
    if (findProviderKey) {
        const data = { ...(providers[findProviderKey]?.summary || {}) };
        delete data[id];
        setSummary(data, [findProviderKey]);
    }
};