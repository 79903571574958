import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { v4 } from 'uuid';

import { debouncer } from 'src/Framework/util/helps';
import { IMouseOverRow } from '../types';
import { Position } from './types';

import { MouseOverContainer, MouseOverContainerInner } from './styled';

interface IProps {
  mouseOverData: IMouseOverRow;
  index: number;
}

function isTouchDevice() {
  // turned off for proper IPad working
  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  // return isMobile;
  return false;
  // return navigator.maxTouchPoints || 'ontouchstart' in document.documentElement;
}

const Component: FC<IProps> = ({ mouseOverData, index }: IProps) => {
  const hovered = useRef(false);
  const preventClose = useRef(false);
  const rowElementRef = useRef<HTMLDivElement | null>(null);
  const mouseHoverContainer = useRef(false);
  const debounceEnter = useRef(debouncer(250));
  const debounceLeave = useRef(debouncer(10));

  const [active, setActive] = useState(false);
  const [data, setData] = useState({
    offsetTop: 0,
    height: 0,
    width: 0,
    innerHeight: 0,
    position: Position.top,
    key: v4()
  });

  const containerRef = useRef<HTMLDivElement | null>(null);
  const innerRef = useRef<HTMLDivElement | null>(null);

  const onMouseLeaveChecker = useCallback(() => {
    debounceLeave.current(() => {
      if (!hovered.current && !mouseHoverContainer.current) {
        setActive(false);
      }
    });
  }, []);

  useEffect(() => {
    const rowElement = containerRef.current?.previousSibling as HTMLDivElement;
    if (rowElement && innerRef.current) {
      rowElementRef.current = rowElement;
      const tableElement = rowElement.offsetParent as HTMLDivElement;
      if (tableElement) {
        const calcData = () => {
          const innerHeight = innerRef.current?.clientHeight || 0;
          const offsetTop = rowElement.offsetTop;
          setData({
            key: v4(),
            offsetTop,
            height: rowElement.clientHeight,
            innerHeight,
            width: innerRef.current?.clientWidth || 0,
            position:
              offsetTop - innerHeight < tableElement.scrollTop
                ? Position.bottom
                : Position.top
          });
        };
        const activate = () => {
          setActive(true);
        };
        const calcAll = () => {
          if (containerRef.current) {
            containerRef.current.style.right = `${-tableElement.scrollLeft}px`;
          }
          calcData();
        };
        const mouseenter = (e: any) => {
          if (isTouchDevice()) return;
          hovered.current = true;
          calcAll();
          debounceEnter.current(() => {
            if (hovered.current) {
              activate();
            }
          });
        };
        const mouseleave = (e: any) => {
          if (isTouchDevice()) return;
          hovered.current = false;
          onMouseLeaveChecker();
        };
        rowElement.addEventListener('mouseenter', mouseenter);
        rowElement.addEventListener('mouseleave', mouseleave);
        rowElement.addEventListener('focusin', mouseenter);
        rowElement.addEventListener('focusout', mouseleave);
        calcData();
        return () => {
          rowElement.removeEventListener('mouseenter', mouseenter);
          rowElement.removeEventListener('mouseleave', mouseleave);
          rowElement.removeEventListener('focusin', mouseenter);
          rowElement.removeEventListener('focusout', mouseleave);
        };
      }
    }
    return () => {};
  }, []);

  const onMouseEnterContainer = useCallback(() => {
    mouseHoverContainer.current = true;
    if (rowElementRef.current) {
      rowElementRef.current.classList.add('hovered');
    }
  }, []);
  const onMouseLeaveContainer = useCallback(() => {
    if (preventClose.current) return;
    mouseHoverContainer.current = false;
    onMouseLeaveChecker();
    if (rowElementRef.current) {
      rowElementRef.current.classList.remove('hovered');
    }
  }, []);

  const onOpenPopoverChange = useCallback((value: boolean) => {
    setActive(value);
  }, []);

  // INSTEAD OF last-in-row id
  const onBlur = useCallback(() => {
    setTimeout(() => {
      const activeElement = document.activeElement;
      if (
        !containerRef.current?.contains(activeElement) &&
        !rowElementRef.current?.contains(activeElement)
      ) {
        onMouseLeaveContainer();
      }
    }, 25);
  }, []);

  const onPreventClose = useCallback((value: boolean) => {
    preventClose.current = value;
  }, []);

  return (
    <MouseOverContainer
      onMouseEnter={onMouseEnterContainer}
      onMouseLeave={onMouseLeaveContainer}
      onFocus={onMouseEnterContainer}
      onBlur={onBlur}
      ref={containerRef}
      style={{
        top:
          data.position === Position.bottom
            ? data.offsetTop + data.height - 1
            : data.offsetTop - data.innerHeight,
        maxWidth: active ? '1000px' : '0px'
      }}
    >
      <MouseOverContainerInner
        ref={innerRef}
        position={data.position}
        className={classNames({
          'mouseover-container': true,
          active: active
        })}
      >
        {mouseOverData.component({
          onOpenPopoverChange,
          active,
          onPreventClose
        })}
      </MouseOverContainerInner>
    </MouseOverContainer>
  );
};

export default React.memo(Component);
