import React from 'react';
import _ from 'lodash';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import test_ids from 'src/tests-script/pages/Subject/Forms';

import { Box } from 'src/CommonStyles/styles';
import { Header, Title, Actions, ButtonContainer } from './FormHeader_Styles';

interface IState {}

interface IProps {
  subjectId: any;
  onClickOpenSort: any;
  onClickOpenAddModal: any;
  sort?: any;
  filter?: any;
  openFilter: () => any;
}

class FormHeader extends React.Component<IProps, IState> {
  render() {
    const { filter, onClickOpenSort, sort, onClickOpenAddModal } = this.props;
    return (
      <Header>
        <Title>Forms</Title>
        <Actions>
          <Box>
            <ButtonContainer>
              <ButtonIcon
                isButton={true}
                label="Filter dialog"
                haspopup="dialog"
                controlsId={test_ids.formsFilterModal}
                iconScale={2.2}
                changeColorOnHover={true}
                name={ICONS_LIST.filter}
                tooltip="Filter"
                active={!_.isEmpty(filter)}
                onClick={() => this.props.openFilter()}
              />
            </ButtonContainer>
            <ButtonContainer>
              <ButtonIcon
                isButton={true}
                label="Sort dialog"
                haspopup="dialog"
                controlsId={test_ids.formsSortModal}
                changeColorOnHover={true}
                name={ICONS_LIST.sort}
                tooltip="Sort"
                active={!_.isEmpty(sort)}
                onClick={() => onClickOpenSort()}
              />
            </ButtonContainer>
            <ButtonContainer>
              <ButtonIcon
                isButton={true}
                label="Add form"
                haspopup="dialog"
                controlsId={test_ids.formsAddFormModal}
                id={test_ids.formsAddButton}
                iconScale={2.2}
                changeColorOnHover={true}
                name={ICONS_LIST.add}
                tooltip="Add"
                onClick={() => onClickOpenAddModal()}
              />
            </ButtonContainer>
          </Box>
        </Actions>
      </Header>
    );
  }
}

export default FormHeader;
