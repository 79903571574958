import * as actionTypes from "../Store/CohortWidgetAction";
import _ from 'lodash';
import { ICohort } from 'src/Activities/CohortManager/Models/Cohort';
import { CLEAR_DASHBOARD } from '../../Store/ComplianceActions';

const initialState: any = {
  pagination: {
    current: 1
  },
  summary: [],
  cohortId: "",
  cohortData: null
}

interface IAction {
  type: string;
  pagination: any;
  cohortId: string;
  cohortWList: ICohort[];
  payload?: any;
}

const CohortWidgetReducer = (
  state: any = { ...initialState },
  action: IAction
) => {
  switch (action.type) {
    case actionTypes.SET_COHORT_DATA: {
      let newState = _.cloneDeep(state)
      newState.cohortData = action.payload;
      return newState;
    }
    case actionTypes.ADD_COHORTS_WIDGET: {
      let newState = _.cloneDeep(state)
      if (action.pagination.current > 1) {
        let isExist: boolean = isExistSameWList_Item(newState.summary, action.cohortWList);
        if (isExist) return state;
        newState.summary = [...newState.summary, ...action.cohortWList];
      } else {
        newState.summary = action.cohortWList;
      }
      newState.pagination = action.pagination
      return newState;
    }
    case actionTypes.UPDATE_COHORT_ID: {
      let newState = _.cloneDeep(state)
      newState.cohortId = action.cohortId

      return newState;
    }
    case CLEAR_DASHBOARD: {
      let newState = _.cloneDeep(state)
      newState = initialState
      return newState;
    }

    default:
      return state;
  }
};
export default CohortWidgetReducer;


// to check whether a WListItem already exist in the store
function isExistSameWList_Item(stateCohortWList: any[], actioncohortWList: any[]): boolean {
  if (actioncohortWList && actioncohortWList.length > 0) {
    let cohortWList_item: any = actioncohortWList[0];
    let isExistWList: any = stateCohortWList.find((rule: any) => {
      return rule.id === cohortWList_item.id;
    });
    if (isExistWList) return true;
  }
  return false;
}
