import { IStateReducer, IAction } from './types';

const prefix = 'APPOINTMENT_TYPES_';

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_LOADING: `${prefix}SET_LOADING`,
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_GENERIC_TYPES: `${prefix}SET_GENERIC_TYPES`,
    SET_SORT: `${prefix}SET_SORT`,
};

const initialState: IStateReducer = {
    summary: {},
    sort: {
        sortBy: 'code',
        sortType: 'asc'
    },
    filter: {
        clinicId: null,
        reasonType: 'appointment',
        showInactive: false
    },
    loading: false,
    genericTypes: {
        groupAppointmentReason: [],
        CCMHAppointmentCategory: []
    }
};

const Reducer = (
    state: IStateReducer = { ...initialState },
    action: IAction
): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET_SORT:
            return {
                ...state,
                sort: action.payload
            }
        case actionsTypes.SET_GENERIC_TYPES:
            return {
                ...state,
                genericTypes: action.payload
            }
        case actionsTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case actionsTypes.SET:
            return {
                ...state,
                summary: action.payload
            };
        case actionsTypes.SET_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export default Reducer;
