import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import store from "src/store";
import { actionsTypes } from './FormList/PatientFormReducer'
import { IFormItem } from "./FormList/types";
import { onChangeItems } from "./FormList/PatientFormActions";

export const ADD_FORM_OVERVIEW = "ADD_FORM_OVERVIEW";
export const ADD_DOCUMENT_FILE = "ADD_DOCUMENT_FILE";


export const updatePatientFormsList = (subjectId: number | string, payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_FORMS,
    subjectId,
    payload
  })
}

export const getDocument = (documentId: number) => {
  return APIHandler.AxiosInstance.get(
    ApiUrl.GET_DOCUMENT + `?documentId=${documentId}&getOriginal=true`,
    { withCredentials: true }
  );
};

export const getFormOverview = (
  id: number,
  seedId: number,
  includeForm: any
) => {
  const data = {
    seedId,
    includeForm,
    viaPortal: false
  };
  return APIHandler.AxiosInstance.post(ApiUrl.GET_TEMPLATE_FORM_RECORD, data, {
    withCredentials: true,
  });
};

export const getAppendForm = async (
  seedId: number | string,
) => {
  try {
    const response = await APIHandler.AxiosInstance.get(`${ApiUrl.GET_TEMPLATE_FORM_NEW}/${seedId}/append`, {
      withCredentials: true,
    });
    if (response.data.success) {
      const data = response.data.result.summary
      return data
    }
    return
  } catch (e) {
    return
  }
};

export const postAppendForm = async (
  seedId: number | string,
  appendText: string
) => {
  try {
    const response = await APIHandler.AxiosInstance.post(`${ApiUrl.GET_TEMPLATE_FORM_NEW}/${seedId}/append`, {
      seedId,
      appendText
    }, {
      withCredentials: true,
    });
    if (response.data.success) {
      const summary = response.data.result.summary
      const data = Object.values(summary) as IFormItem[];
      const patientForms = data
      if (patientForms.length !== 0) {
        onChangeItems(data, data[0].patientId)
      }
      return data
    }
    return
  } catch (e) {
    return
  }
};

export const setFormDetailsV2 = async (
  seedId: number | string,
  submission: any,
  patientId: number | string
) => {
  try {
    const response = await APIHandler.AxiosInstance.post(`${ApiUrl.GET_TEMPLATE_FORM_NEW}/${seedId}/details?v=2`, {
      data: submission
    }, {
      withCredentials: true,
    });
    if (response.data.success) {
      const data = response.data.result.summary
      updatePatientFormsList(patientId, data)
      return data
    }
    return
  } catch (e) {
    return
  }
};

export const setFormDetails = async (
  seedId: number | string,
  MoffFormModel: any
) => {
  try {
    const response = await APIHandler.AxiosInstance.post(`${ApiUrl.GET_TEMPLATE_FORM_NEW}/${seedId}/details`, { MoffFormModel }, {
      withCredentials: true,
    });
    if (response.data.success) {
      return response.data.result
    }
    return
  } catch (e) {
    return
  }
};


export const getFormTemplate = async () => {
  try {
    const response = await APIHandler.AxiosInstance.get(ApiUrl.GET_FORM_TEMPLATE, {
      withCredentials: true,
      params: {
        isOnlyLatestForm: true
      }
    });
    if (response.data.success) {
      return response.data.result.summary
    }
  } catch (e) {

  }
}