import { TempFieldsForTable } from "src/Framework/Controls/Table/types"
import store from "src/store"
import { actionsTypes } from ".."
import { IFilter, IM1NoteSimpleDTO, ISortNote } from "../types"

export const setSummary = (payload: Record<string, IM1NoteSimpleDTO>, providerId: string | number) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload,
        providerId
    })
}

export const onCreateUpdateItem = (payload: IM1NoteSimpleDTO & Partial<TempFieldsForTable>, providerId: string | number) => {
    const data = { ...(store.getState().trainee.notes.providers[providerId]?.summary || {}) }
    if (data[payload.id]) {
        data[payload.id] = { ...data[payload.id], ...payload }
    } else {
        data[payload.id] = payload
    }
    setSummary(data, providerId)
}

export const setFilter = (payload: Partial<IFilter>, providerId: string | number) => {
    store.dispatch({
        type: actionsTypes.SET_FILTER,
        payload,
        providerId
    })
}

export const setSort = (payload: Partial<ISortNote>, providerId: string | number) => {
    store.dispatch({
        type: actionsTypes.SET_SORT,
        payload,
        providerId
    })
}