import React, { FC, useRef, useState } from 'react';

import SortModal from 'src/Framework/Controls/SortModal/SortModal';
import { getCurrentProvider } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import AppErrorBoundary from 'src/Framework/Errors/AppErrorBoundary';

import * as m1FormRecordsActions from 'src/App/M1FormRecords/store/Actions';
import { M1FormSubjectType } from 'src/App/M1FormRecords/store/types';

import FormHeader from './FormHeader/FormHeader';
import List from './List';
import FilterFormModal from './FilterFormModal';
import { IFormData, Type } from '../Forms';
import AddForm, { ITemplateForm, IType } from './AddForm';

interface IProps {
  patientId: string;
  formSelected?: IFormData;
  formList: IFormData[];
  match?: any;
  selectionChanged: (form: IFormData | null) => any;
  filter: any;
  sort: any;
  setFilters: (value: any, patientId: string) => any;
  setSort: (value: any, patientId: string) => any;
  addForm: (value: any) => Promise<any>;
  clinicTypes: number[];
}

interface IProps {}

const Component: FC<IProps> = ({
  patientId,
  selectionChanged,
  match,
  formList,
  filter,
  sort,
  formSelected,
  setFilters,
  setSort,
  addForm,
  clinicTypes
}: IProps) => {
  const [openSortModal, setOpenSortModal] = useState(false);
  const AddFormRef = useRef<any>(null);
  const FilterFormModalRef = useRef<FilterFormModal>(null);
  const onClickFilter = (
    dateBegin: string,
    dateEnd: string,
    status: string,
    showInvalid: boolean
  ) => {
    let filters: {
      dateBegin: string;
      dateEnd: string;
      status: string;
      showInvalid: boolean | null;
    } | null;
    filters = { dateBegin: '', dateEnd: '', status: '', showInvalid: null };
    filters.dateBegin = dateBegin;
    filters.dateEnd = dateEnd;
    filters.status = status;
    filters.showInvalid = showInvalid;
    if (!dateBegin && !dateEnd && !status && !showInvalid) {
      filters = null;
      selectionChanged(null);
    }

    setFilters(filters, patientId);
  };

  const onClickSort = (name: any, date: any) => {
    let sort: { name: string; date: string } | null;
    sort = { name: '', date: '' };
    sort.name = name;
    sort.date = date;
    if (!name && !date) {
      sort = null;
    }
    setSort(sort, patientId);
  };

  const addFormHandler = async (item: ITemplateForm, clinicId: string) => {
    if (item) {
      if (item.type === IType.LEGACY) {
        return await addForm(item);
      }
      if (item.type === IType.M1 && clinicId) {
        const currentProvider = getCurrentProvider();
        return await m1FormRecordsActions.post(
          {
            formTemplateId: item.id,
            assignedProviderId: currentProvider,
            formTemplateVersion: item.latestPublishedVersion,
            clinicId: +clinicId,
            subject: {
              id: +patientId,
              subjectType: M1FormSubjectType.Patient
            }
          },
          patientId
        );
      }
    }
  };

  const addM1FormHandler = (id: number) => {
    addForm({ type: Type.M1, id });
  };

  const openCloseSortModal = () => {
    setOpenSortModal((value) => !value);
  };

  const openCloseAddModal = () => {
    AddFormRef?.current?.show({});
  };

  return (
    <AppErrorBoundary>
      <>
        <AddForm
          ref={AddFormRef}
          patientId={patientId}
          onClickAddButton={addFormHandler}
          clinicTypes={clinicTypes}
          onM1formAdded={addM1FormHandler}
          sliderMode={true}
        />
        <FilterFormModal
          ref={FilterFormModalRef}
          filter={filter}
          subjectId={patientId}
          onClickApplyHandler={onClickFilter}
          sliderMode={true}
        />
        <SortModal
          visible={openSortModal}
          title="Sort Forms"
          onClickApplyHandler={onClickSort}
          closeSortModal={openCloseSortModal}
          sortData={sort}
          sliderMode={true}
        />
        <FormHeader
          openFilter={() => FilterFormModalRef?.current?.open()}
          onClickOpenSort={openCloseSortModal}
          subjectId={patientId}
          onClickOpenAddModal={openCloseAddModal}
          filter={filter}
          sort={sort}
        />
        <List
          formsData={formList}
          selectionChanged={selectionChanged}
          formSelected={formSelected}
          subjectId={patientId}
          match={match}
        />
      </>
    </AppErrorBoundary>
  );
};

export default React.memo(Component);
