import axios, { AxiosInstance } from 'axios';
import { capitalizeFirstLetter } from 'src/Activities/Schedule/Utils/Utils';
import { Alert } from '../Common/Notification';

import * as AccessToken from 'src/App/LoginPage/store/AccessToken';
import * as authActions from 'src/App/LoginPage/store/Actions';
import { notification } from 'antd';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';

import * as Notification from './Notification';

import RequestMapping from './RequestMapping';
import { isManagementSystem } from 'src/ManagementSystem/utils';

import * as managementLoginActions from 'src/ManagementSystem/Pages/Login/store/Actions'

// theFileYouDeclaredTheCustomConfigIn.ts
declare module 'axios' {
  export interface AxiosRequestConfig {
    errorInterceptorMessage?: string;
    successInterceptorMessage?: string;
    interceptError?: boolean;
    interceptSuccess?: boolean;
  }
}

interface ITableauItem {
  url: string;
  name: string;
}

interface IConfig {
  inactivityLogoutMinutes: number;
  accessibility: boolean;
  tableauReportName: string;
  signalRPushSystem: string;
  signalRUrl: string;
  version: string;
  baseUrl: string;
  tableauJSFile: string;
  tableauHost: string;
  tableauDashboardsUrls: {
    MyWatchList: ITableauItem;
    CrisisAppointments: ITableauItem;
    CaseManagement: ITableauItem;
    CaseManagementReferrals: ITableauItem;
    CaseManagementRisks: ITableauItem;
    CaseManagementClinicActivity: ITableauItem;
    AppointmentType: ITableauItem;
    AppointmentDistribution: ITableauItem;
    Risks: ITableauItem;
    RisksManagementEngagement: ITableauItem;
    RisksManagementRisks: ITableauItem;
    AppointmentStatus: ITableauItem;
    ProviderAssignments: ITableauItem;
    ClinicActivity: ITableauItem;
    SupervisorDashboard: ITableauItem;
  };
}

export let configData: IConfig | null = null;

const parseJwt = (
  token: string
): {
  exp: number;
} | null => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export let numberOfAjaxCAllPending = 0

export class APIHandler {
  public static AxiosInstance: AxiosInstance;
  private tokenRefreshing = false;
  private readonly requestDelay = 1000;
  private ipError = false;

  init() {
    return new Promise(async (resolve, reject) => {
      try {
        const isManagement = isManagementSystem()
        const res = await axios.get(isManagement ? '/config.management.json' : '/config.json');
        configData = res.data;
        APIHandler.AxiosInstance = axios.create({
          baseURL: res.data.baseUrl
        });
        AccessToken.insert();
        APIHandler.AxiosInstance.interceptors.request.use(async (request) => {
          numberOfAjaxCAllPending++;
          if (this.tokenRefreshing) {
            let checkedTimes = 0;
            let promise = new Promise((resolve) => {
              Notification.RefreshingNotification();
              let timer = setInterval(() => {
                checkedTimes++;
                console.log(checkedTimes, request.url);
                if (!this.tokenRefreshing) {
                  if (request.headers?.Authorization) {
                    const token = AccessToken.get();
                    if (token.accessToken) {
                      request.headers.Authorization = `Bearer ${token.accessToken}`;
                    }
                  }
                  setTimeout(() => {
                    resolve(true);
                    Notification.RefreshingNotificationClose();
                    clearInterval(timer);
                  }, 150);
                }
              }, this.requestDelay);
            });
            await promise;
          }
          const token = AccessToken.get();
          if (token.accessToken) {
            const parsedToken = parseJwt(token.accessToken);
            if (parsedToken) {
              if (Date.now() >= parsedToken.exp * 1000) {
                if (token.refreshToken && token.refreshToken !== 'undefined') {
                  this.tokenRefreshing = true;
                  if (isManagement) {
                    const res = await managementLoginActions.refreshToken({
                      refreshToken: token.refreshToken
                    });
                    if (!res) {
                      notification.error({
                        key: 'error-refresh-message',
                        message: 'Session refreshing error'
                      });
                    } else {
                      request.headers.Authorization = `Bearer ${res.accessToken}`;
                      notification.success({
                        key: 'success-refresh-message',
                        message: 'Session refreshed'
                      });
                      this.tokenRefreshing = false;
                      return RequestMapping(request);
                    }
                    this.tokenRefreshing = false;
                  } else {
                    const res = await authActions.authV2Refresh({
                      refreshToken: token.refreshToken
                    });
                    if (!res) {
                      notification.error({
                        key: 'error-refresh-message',
                        message: 'Session refreshing error'
                      });
                    } else {
                      request.headers.Authorization = `Bearer ${res.accessToken}`;
                      notification.success({
                        key: 'success-refresh-message',
                        message: 'Session refreshed'
                      });
                      this.tokenRefreshing = false;
                      return RequestMapping(request);
                    }
                    this.tokenRefreshing = false;
                  }

                }
              }
            }
            request.headers.Authorization = `Bearer ${token.accessToken}`;
          }
          return RequestMapping(request);
        });
        APIHandler.AxiosInstance.interceptors.response.use(
          (response) => {
            numberOfAjaxCAllPending--;
            if (
              response.config.interceptError !== false &&
              response.data.error
            ) {
              if (response.data.errorCode) {
                const errorCode = +response.data.errorCode;
                if (errorCode >= 1 && errorCode <= 10000) {
                  const [msg, desc] = response.data.error.split(', ');
                  // temporary until sever-side fix
                  if (msg !== 'Appointment does not exist') {
                    Alert(
                      'error',
                      getPatientTerm(
                        msg ||
                        response.config.errorInterceptorMessage ||
                        'Error'
                      ),
                      `${desc
                        ? capitalizeFirstLetter(desc.replace('errors:', ''))
                        : ''
                      }${response.data.operationId
                        ? `\nOperationId: ${response.data.operationId}`
                        : ''
                      }`
                    );
                  }
                  reject();
                }
                if (errorCode >= 30000 && errorCode <= 39999) {
                  const errorMsg = response.data.error;
                  Alert(
                    'error',
                    'Access Denied',
                    `${errorMsg
                      ? capitalizeFirstLetter(errorMsg.replace('errors:', ''))
                      : 'Access Denied'
                    }${response.data.operationId
                      ? `\nOperationId: ${response.data.operationId}`
                      : ''
                    }`
                  );
                  reject();
                }
              }
            } else if (
              response.config.interceptSuccess &&
              response.config.successInterceptorMessage &&
              response.data.success
            ) {
              Alert('success', response.config.successInterceptorMessage, '');
            }
            return response;
          },
          (error) => {
            numberOfAjaxCAllPending--;
            if (error.message === 'Network Error') {
              Alert('error', 'Network Error', 'Internet connection not stable');
            }
            if (
              error.request?.status === 401 ||
              error.request?.status === 403
            ) {
              const response = error.response?.data;
              if (response?.errorCode === '30001') {
                if (this.ipError) {
                  return;
                }
                this.ipError = true;
                authActions.logoutWithError(
                  `${response?.error}\nOperationId: ${response?.operationId}`
                );
              } else {
                authActions.logoutSession();
              }
              console.log({ error });
            }
            return Promise.reject(error);
          }
        );
        resolve(res);
      } catch (e) {
        console.error('CONFIG ISSUE');
      }
    });
  }
}
