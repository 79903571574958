import React from 'react';

import MultipleSelector from '../Selectors/MultipleSelector';
import { IOption, IMultiple, IValueType } from './types';

interface IProps<S = IValueType> extends IMultiple<S> {
  options: IOption<S>[];
}

const Component = <S,>({
  id,
  value,
  onChange,
  selectProps,
  options,
  name
}: IProps<S>) => {
  return (
    <MultipleSelector<S>
      label={name}
      id={id}
      selectProps={selectProps}
      options={options}
      value={value}
      onChange={(...args) => {
        onChange(...args);
      }}
    />
  );
};

export default Component;
