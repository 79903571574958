import styled from 'styled-components';
import {
  ModalTitle,
  MenuTitleLink,
  MandatoryField
} from 'src/CommonStyles/fonts';
import {
  black_color,
  blue_color,
  mandatory_color,
  inactive_text,
  gray_border
} from 'src/CommonStyles/styles';

export const TitleContainer = styled(ModalTitle)`
  color: ${black_color};
  display: flex;
`;

export const AddButton = styled(MenuTitleLink)`
  position: relative;
  height: 32px;
  width: 80px;
  left: 16px;
  right: 16px;
  top: calc(50% - 16px / 2);
  border: 1px solid ${blue_color};
  box-sizing: border-box;
  border-radius: 50px;
  align-items: center;
  text-align: center;
  padding: 7px 7px;
  margin-right: 8px;
  color: ${blue_color};
  cursor: pointer;
  ${(props: { disable?: boolean }) =>
    props.disable &&
    `
        color: ${inactive_text};
        border: 1px solid ${gray_border};
        cursor: default;
        `}
`;

export const CancelButton = styled(AddButton)`
  width: 62px;
`;

export const Right = styled.div`
  position: relative;
  display: flex;
  gap: 16px;
`;
export const Buttons = styled.div`
  display: flex;
  gap: 16px;
`;

export const ModalClose = styled.div`
  align-items: baseline;
  justify-content: flex-end;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  margin-top: 5.5px;
  margin-left: 15px;
`;

export const Instructions = styled.div`
  margin-top: -20px;
  margin-left: 24px;
  margin-bottom: 7px;
`;

export const FormList = styled.div`
  margin-left: 16px;
  height: calc(75vh - 213px);
  overflow-y: auto;
`;

export const FormItem = styled.div`
  height: 43px;
  width: 100%;
  padding: 16px;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 91px;
  margin-left: ${(props: { marginLeft?: string }) =>
    props.marginLeft ? props.marginLeft : '0px'};
  margin-bottom: 20px;
`;
export const Checks = styled.div`
  display: flex;
  flex-direction: column;
  height: 55px;
  margin-left: 25px;
`;

export const OptionsContainer = styled.div`
  padding: 16px;
  height: 52px;
`;

export const ContentFields = styled.div`
  height: 100%;
`;

export const Item = styled.div`
  width: 100%;
  padding: 16px;
`;

export const Mandatory = styled(MandatoryField)`
  color: ${mandatory_color};
  margin: -2px 0;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
