import _ from "lodash";

const SET_COHORT_ID = "SET_COHORT_ID";

export const updateCohortID = (data: any) => {
  return {
    type: SET_COHORT_ID,
    cohortId: data.cohortId,
    cohortName: data.cohortName
  };
};

const initialState: any = {
  cohortId: "",
  cohortName: ""
};

interface IAction {
  type: string;
  cohortId: string;
  cohortName: string;
}

const CohortIdReducer = (state: any = { ...initialState }, action: IAction) => {
  let newState: IAction = _.cloneDeep(state);
  switch (action.type) {
    case SET_COHORT_ID: {
      newState.cohortId = action.cohortId;
      newState.cohortName = action.cohortName;
      return { ...newState };
    }

    default:
      return state;
  }
};

export default CohortIdReducer;
