import React, { FC, useEffect, useRef } from 'react';
import { Popover } from 'antd';
import { PopoverProps } from 'antd/es/popover';
import classNames from 'classnames';

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

interface IProps extends PopoverProps {
  globalOverlay?: boolean;
  id: string;
  onClickOutSide?: () => any;
  removePaddings?: boolean;
  disableAutofocus?: boolean;
}

const appRoot = document.body;

const classOverlay = 'popover-global-overlay-container';

const Component: FC<IProps> = ({
  globalOverlay,
  onClickOutSide,
  removePaddings,
  ...otherProps
}: IProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<any>(null);
  const el = useRef(document.createElement('div'));
  const removeElement = () => {
    if (el.current) {
      try {
        if (el?.current?.classList) {
          el.current.classList.remove(classOverlay);
        }
        appRoot.removeChild(el.current);
      } catch (e) {}
    }
  };
  useEffect(() => {
    if (onClickOutSide) {
      function handleClickOutside(event: any) {
        if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
          if (onClickOutSide) {
            onClickOutSide();
          }
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
    return () => {};
  }, [wrapperRef]);
  useEffect(() => {
    return () => {
      removeElement();
    };
  }, []);
  useEffect(() => {
    if (globalOverlay) {
      if (otherProps.visible || otherProps.open) {
        el.current.classList.add(classOverlay);
      } else {
        el.current.classList.remove(classOverlay);
      }
    }
    if (
      !otherProps.disableAutofocus &&
      (otherProps.visible || otherProps.open)
    ) {
      setTimeout(() => {
        if (wrapperRef.current) {
          const firstFocusableElement =
            wrapperRef.current.querySelector('[tabindex="0"]');
          if (firstFocusableElement) {
            (firstFocusableElement as HTMLElement).focus();
          }
        }
      }, 150);
    }
  }, [otherProps.visible, otherProps.open]);

  return (
    <Popover
      ref={popoverRef}
      destroyTooltipOnHide={true}
      {...otherProps}
      overlayClassName={classNames(
        {
          'remove-popover-paddings': removePaddings
        },
        otherProps.overlayClassName
      )}
      content={<div ref={wrapperRef}>{otherProps.content}</div>}
      overlayStyle={{
        zIndex: globalStyleVariables.alertPopupZIndex,
        ...otherProps.overlayStyle
      }}
      getPopupContainer={
        globalOverlay
          ? () => {
              appRoot.appendChild(el.current);
              return el.current;
            }
          : otherProps.getPopupContainer
      }
    />
  );
};

export default React.memo(Component);
