import React, { FC } from 'react';
import { Radio } from 'antd';
import { RadioButtonProps } from 'antd/es/radio/radioButton';

interface IProps extends RadioButtonProps {
  id: string;
  checked: boolean;
}

/**
 * {@link RadioComponent} used like antd container
 * @note value and checked inside unsed to solve issue for QA tests
 */
const RadioComponent: FC<IProps> = (props: IProps) => {
  const { checked } = props;
  return <Radio {...props} value={checked} checked={checked} />;
};

export default RadioComponent;
