import _ from 'lodash';
import { IStateReducer, IAction } from './types';
import { defaultSorting } from 'src/App/Trainee/Main/store';

const prefix = 'PROVIDER_EVALUATIONS_';

export const actionsTypes = {
  SET: `${prefix}SET`,
  SET_LOADING: `${prefix}SET_LOADING`,
  SET_SORT: `${prefix}SET_SORT`
} as const;

const initialState: IStateReducer = {
  providers: {}
};

export const defaultData: IStateReducer['providers'][number] = {
  summary: {},
  loading: false,
  sort: defaultSorting
};

const Reducer = (
  state: IStateReducer = _.cloneDeep(initialState),
  action: IAction
): IStateReducer => {
  const providerData = () =>
    state.providers[action.providerId] || _.cloneDeep(defaultData);
  switch (action.type) {
    case actionsTypes.SET_SORT: {
      const data = providerData();
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: {
            ...data,
            sort: action.payload
          }
        }
      };
    }
    case actionsTypes.SET_LOADING: {
      const data = providerData();
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: {
            ...data,
            loading: action.payload
          }
        }
      };
    }
    case actionsTypes.SET: {
      const data = providerData();
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: {
            ...data,
            summary: action.payload
          }
        }
      };
    }
    default:
      return state;
  }
};

export default Reducer;
