import React, { useCallback, useRef } from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import RemoveButton from 'src/Framework/Controls/Table/RemoveButton';
import Button from 'src/Framework/Controls/Button';
import AlertPopUp, {
  IModalHandles as IAlertPopUp
} from 'src/Framework/Controls/AlertPopUp';
import AppErrorBoundary from 'src/Framework/Errors/AppErrorBoundary';
import test_ids from 'src/tests-script/pages/Subject/Forms';

import * as formListActions from 'src/Activities/Forms/FormList/PatientFormActions';
import { ILegacyForm } from 'src/Activities/Forms/Forms';
import Summary from 'src/Activities/Forms/Summary';
import Append from './Append';
import Print from './Print';
import CreateSecureMessageButton from 'src/App/SecureMessages/Components/CreateSecureMessageButton';
import { usePermission } from 'src/App/User/Permissions/Hook';
import { updatePatientFormsList } from 'src/Activities/Forms/FormsActions';

import { ActionBarContainer, ButtonContainer, VerticalLine } from './styled';

interface IProps {
  onClickShowPreview: any;
  formSelected: ILegacyForm;
  patientId: string;
  onRemove: Function;
  onClickSave: (value: ILegacyForm) => any;
  containerClassname?: string;
  verticalLineHeight?: number;
  preview?: boolean;
  isNotValid?: boolean;
  onAppendAdded?: () => any;
  hideRemoveButton?: boolean;
}

export const FormListActionBar: React.FC<IProps> = ({ ...props }) => {
  const AlertPopUpRef = useRef<IAlertPopUp>(null);
  const {
    patientId,
    formSelected,
    onClickShowPreview,
    onRemove,
    onClickSave,
    containerClassname,
    verticalLineHeight,
    preview,
    isNotValid,
    onAppendAdded,
    hideRemoveButton
  } = props;
  const notDeleted = formSelected.status !== 'invalid';
  const deleteFormPermission = usePermission({
    anyClinic: false,
    clinicId: formSelected.clinicId,
    moduleId: 40,
    functionId: 310
  });

  const afterFormSummaryUpdate = useCallback((res) => {
    const patientId = (Object.values(res)[0] as any).patientId;
    updatePatientFormsList(patientId, res);
  }, []);

  return (
    <AppErrorBoundary>
      <AlertPopUp ref={AlertPopUpRef} />
      <ActionBarContainer className={containerClassname}>
        {notDeleted && (
          <>
            <ButtonContainer>
              <Button
                id="save-button"
                disabled={formSelected.status !== 'inProgress'}
                shape="round"
                onClick={async () => await onClickSave(formSelected)}
              >
                Save
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Summary
                currentForm={formSelected}
                afterFormUpdate={afterFormSummaryUpdate}
              />
            </ButtonContainer>
            <ButtonContainer>
              <Append
                onAppendAdded={onAppendAdded}
                readOnly={
                  formSelected.status === 'locked' ||
                  formSelected.status === 'signed'
                }
                currentForm={formSelected}
              />
            </ButtonContainer>
            <ButtonContainer>
              <ButtonIcon
                id={test_ids.formsPreviewButton}
                isButton={true}
                label="Form Preview"
                toggled={preview}
                changeColorOnHover={true}
                name={ICONS_LIST.eye2}
                active={preview}
                tooltip="Form Preview"
                onClick={() => onClickShowPreview()}
              />
            </ButtonContainer>
            <ButtonContainer>
              <ButtonIcon
                isButton={true}
                label="Restrict Access"
                changeColorOnHover={true}
                toggled={formSelected.isRestricted}
                active={formSelected.isRestricted}
                iconScale={1.6}
                name={ICONS_LIST.restricted}
                tooltip="Restrict Access"
                asyncOnClick={async () => {
                  await formListActions.patchPatientForms(
                    {
                      seedId: formSelected.seedId,
                      isRestricted: !formSelected.isRestricted
                    },
                    patientId
                  );
                }}
              />
            </ButtonContainer>
          </>
        )}
        {formSelected.status === 'locked' && (
          <ButtonContainer>
            <CreateSecureMessageButton
              patientId={formSelected.patientId}
              tooltip="Send the form as PDF in a secure message"
              params={{
                formId: formSelected.seedId,
                formType: 'legacy'
              }}
            />
          </ButtonContainer>
        )}
        <ButtonContainer>
          <Print seedId={props.formSelected.seedId} />
        </ButtonContainer>
        {notDeleted && !hideRemoveButton && (
          <>
            <ButtonContainer>
              <RemoveButton
                id={test_ids.formsDeleteButton}
                buttonIconProps={{
                  disabled: !deleteFormPermission.success,
                  tooltip: !deleteFormPermission.success
                    ? deleteFormPermission.message
                    : ''
                }}
                removeAction={async () => {
                  AlertPopUpRef.current?.show({
                    content: 'Are you sure you would like to remove this form?',
                    action: 'delete',
                    closeAfterConfirm: false,
                    onConfirm: async () => {
                      AlertPopUpRef.current?.show({
                        loading: true
                      });
                      const res = await formListActions.patchPatientForms(
                        {
                          seedId: formSelected.seedId,
                          invalid: true
                        },
                        patientId
                      );
                      if (res) {
                        AlertPopUpRef.current?.close();
                        onRemove();
                      }
                    }
                  });
                }}
              />
            </ButtonContainer>
          </>
        )}
        <VerticalLine style={{ height: verticalLineHeight || 32 }} />
        {notDeleted && (
          <>
            <ButtonContainer>
              <ButtonIcon
                isButton={true}
                label="Sign"
                toggled={formSelected.status === 'signed'}
                changeColorOnHover={true}
                disabled={isNotValid}
                active={formSelected.status === 'signed'}
                name={ICONS_LIST.sign}
                tooltip="Sign"
                asyncOnClick={async () => {
                  if (
                    formSelected.status === 'inProgress' ||
                    formSelected.status === 'signed'
                  ) {
                    if (formSelected.status !== 'signed') {
                      await onClickSave(formSelected);
                    }
                    await formListActions.postPatientFormsSign(
                      {
                        seedId: formSelected.seedId,
                        signatureType: 1
                      },
                      patientId
                    );
                  }
                }}
              />
            </ButtonContainer>
            <ButtonContainer>
              <ButtonIcon
                isButton={true}
                label="Lock Form"
                toggled={formSelected.status === 'locked'}
                disabled={isNotValid || formSelected.status === 'locked'}
                changeColorOnHover={true}
                active={formSelected.status === 'locked'}
                name={ICONS_LIST.lock}
                tooltip="Lock Form"
                asyncOnClick={async () => {
                  if (formSelected.status === 'inProgress') {
                    await onClickSave(formSelected);
                  }
                  await formListActions.postPatientFormsSign(
                    {
                      seedId: formSelected.seedId,
                      signatureType: 3
                    },
                    patientId
                  );
                }}
              />
            </ButtonContainer>
          </>
        )}
      </ActionBarContainer>
    </AppErrorBoundary>
  );
};
