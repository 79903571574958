import React, { useImperativeHandle, useState } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import _ from 'lodash';

import Modal from 'src/Framework/Controls/Modal';
import Button from 'src/Framework/Controls/Button';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import ElementTitle from 'src/Framework/Controls/ElementTitle';
import Radio from 'src/Framework/Controls/Radio';
import TEST_IDS from 'src/tests-script/pages/Activity/Compliance/CohortCreateEdit/FilterModal';

import TypeCampusStatus from 'src/App/GenericTypes/Selectors/TypeCampusStatus';
import TypeStanding from 'src/App/GenericTypes/Selectors/TypeStanding';
import TypeSchool from 'src/App/GenericTypes/Selectors/TypeSchool';
import TypeClass from 'src/App/GenericTypes/Selectors/TypeClass';
import TypeResidency from 'src/App/GenericTypes/Selectors/TypeResidency';
import TypeEligibility from 'src/App/GenericTypes/Selectors/TypeEligibility';
import {
  IFilterData,
  IStateReducer
} from 'src/Activities/Home/Cohort/store/types';
import * as actions from 'src/Activities/Home/Cohort/store/Actions';

import { Container, Block } from './styled';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

interface IOwnProps {
  setFilter: (data: IFilterData) => any;
  onChange: (data: IStateReducer['compliancePatients']) => any;
}

interface IProps extends IOwnProps {}

export interface IModalHandles {
  show(obj: any): void;

  close(): void;
}

export const defaultData = (): IFilterData => ({
  searchString: '',
  ruleId: null,
  enrollmentDateFrom: null,
  enrollmentDateTo: null,
  typeSchoolIds: [],
  typeClassIds: [],
  typeEligibilityIds: [],
  typeResidencyIds: [],
  typeStandingIds: [],
  isForeign: undefined,
  complianceStatuses: [],
  waiverDateFrom: null,
  waiverDateTo: null,
  cohortIds: [],
  typeStudentStatusIds: [],
  needsReview: null,
  hasFlaggedFacts: null,
  hasUnverifiedFacts: null
});

const selectProps = {
  star: false,
  bottomMargin: false,
  inputProps: {
    allowClear: true
  }
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { onChange, setFilter },
  ref
) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<IFilterData>(defaultData());
  useModalFocus({ active: visible });
  const show = async ({ value }: any) => {
    if (value) {
      setData(value);
    }
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {};

  useImperativeHandle(ref, () => ({
    show: (obj: any) => show(obj),
    close
  }));

  const onChangeData = (obj: Partial<IFilterData>) => {
    setData((data) => ({ ...data, ...obj }));
  };

  const apply = async () => {
    if (_.isEqual(data, defaultData())) {
      setFilter(data);
      onChange({
        count: 0,
        startIndex: 0,
        summary: {},
        total: 0
      });
      close();
      return;
    }
    setLoading(true);
    const res = await actions.getCompliancePatients(data);
    if (res) {
      setFilter(data);
      onChange(res);
      close();
    }
    setLoading(false);
  };

  const defaultWidth = 1024;
  const title = 'Filter';
  const headerRight = (
    <Row align="middle" gutter={16}>
      <Col>
        <Button
          disableClickLoader={true}
          id={TEST_IDS.CLEAR_BUTTON}
          onClick={() => {
            setData(defaultData());
          }}
          shape="round"
        >
          Clear
        </Button>
      </Col>
      <Col>
        <Button
          id={TEST_IDS.SAVE_BUTTON}
          loading={loading}
          onClick={apply}
          shape="round"
        >
          Save
        </Button>
      </Col>
    </Row>
  );
  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title
      }}
      id={TEST_IDS.modal}
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      headerBorder={true}
      afterClose={setToDefault}
      modalProps={{
        destroyOnClose: true
      }}
      buttons={headerRight}
    >
      <Container>
        <Row>
          <Col flex={'auto'}>
            <Block border={true}>
              <Row
                align="middle"
                gutter={16}
                style={{ flex: 1, marginBottom: 10, marginTop: 36 }}
              >
                <Col span={8}>
                  <TypeSchool
                    type="multiple"
                    id={TEST_IDS.SCHOOL}
                    name="School"
                    value={data.typeSchoolIds}
                    onChange={(typeSchoolIds) => {
                      onChangeData({ typeSchoolIds });
                    }}
                    selectProps={selectProps}
                  />
                </Col>
                <Col span={8}>
                  <TypeEligibility
                    type="multiple"
                    id={TEST_IDS.Eligibility}
                    name="Eligibility"
                    value={data.typeEligibilityIds}
                    onChange={(typeEligibilityIds: any) => {
                      onChangeData({ typeEligibilityIds });
                    }}
                    selectProps={selectProps}
                  />
                </Col>
                <Col span={8}>
                  <TypeStanding
                    type="multiple"
                    id={TEST_IDS.Standing}
                    name="Standing"
                    value={data.typeStandingIds}
                    onChange={(typeStandingIds) => {
                      onChangeData({ typeStandingIds });
                    }}
                    selectProps={selectProps}
                  />
                </Col>
              </Row>
              <Row align="middle" gutter={16} style={{ flex: 1 }}>
                <Col span={8}>
                  <TypeClass
                    type="multiple"
                    id={TEST_IDS.Class}
                    name="Class"
                    value={data.typeClassIds}
                    onChange={(typeClassIds) => {
                      onChangeData({ typeClassIds });
                    }}
                    selectProps={selectProps}
                  />
                </Col>
                <Col span={8}>
                  <TypeResidency
                    type="multiple"
                    id={TEST_IDS.Residency}
                    name="Residence"
                    value={data.typeResidencyIds}
                    onChange={(typeResidencyIds) => {
                      onChangeData({ typeResidencyIds });
                    }}
                    selectProps={selectProps}
                  />
                </Col>
                <Col span={8}>
                  <TypeCampusStatus
                    type="multiple"
                    name="Campus Status"
                    id={TEST_IDS.student}
                    value={data.typeStudentStatusIds}
                    onChange={(typeStudentStatusIds) => {
                      onChangeData({ typeStudentStatusIds });
                    }}
                    selectProps={selectProps}
                  />
                </Col>
              </Row>
              <Row align="middle" gutter={16}>
                <Col span={8} style={{ paddingTop: 10 }}>
                  <ElementTitle name="Account" star={false} />
                  <Row align="middle" gutter={16}>
                    <Col>
                      <Radio
                        name="account"
                        id={TEST_IDS.ALL}
                        checked={data.isForeign === null}
                        onChange={() => onChangeData({ isForeign: null })}
                      >
                        All <span className="visually-hidden">accounts</span>
                      </Radio>
                    </Col>
                    <Col>
                      <Radio
                        name="account"
                        id={TEST_IDS.international}
                        checked={data.isForeign === true}
                        onChange={() => onChangeData({ isForeign: true })}
                      >
                        International{' '}
                        <span className="visually-hidden">accounts</span>
                      </Radio>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Block>
          </Col>
          <Col flex={'350px'}>
            <Block>
              <b>Enrollment Date</b>
              <Row align="middle" gutter={[16, 12]} style={{ marginTop: 10 }}>
                <Col>
                  <SelectContainer
                    id={TEST_IDS.EnrollmentFrom}
                    label="From:"
                    type="date"
                    star={false}
                    value={data.enrollmentDateFrom}
                    onChange={(enrollmentDateFrom) => {
                      onChangeData({ enrollmentDateFrom });
                    }}
                    inputProps={{
                      disabledDate(date) {
                        return data.enrollmentDateTo
                          ? date.isAfter(moment(data.enrollmentDateTo), 'day')
                          : false;
                      }
                    }}
                    bottomMargin={false}
                  />
                </Col>
                <Col>
                  <SelectContainer
                    id={TEST_IDS.EnrollmentTo}
                    label="To:"
                    type="date"
                    star={false}
                    value={data.enrollmentDateTo}
                    onChange={(enrollmentDateTo) => {
                      onChangeData({ enrollmentDateTo });
                    }}
                    inputProps={{
                      disabledDate(date) {
                        return data.enrollmentDateFrom
                          ? date.isBefore(
                              moment(data.enrollmentDateFrom),
                              'day'
                            )
                          : false;
                      }
                    }}
                    bottomMargin={false}
                  />
                </Col>
              </Row>
            </Block>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default React.forwardRef(Component);
