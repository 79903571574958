import _ from 'lodash';
import * as actionTypes from './NavigationActions';
import { IAction, NoteParamType } from './NavigationInterfaces';

import { AppUrl } from "src/Framework/Common/AppUrl";
import { EnumPatientMenuSystemIds } from 'src/App/Admin/Pages/PatientMenuItems/store/types';
import { permissionChecking } from 'src/App/User/Permission';
import { permittedClasses } from 'src/Activities/Home/OrderManager/utils';

export const initialState: Record<string, {
    patientMenuId: EnumPatientMenuSystemIds | null,
    name: string,
    title: string,
    items: Record<string, {
        description: string,
        itemName: string,
        information: string,
        url: string,
        isNotes?: boolean
        isInProgressNotes?: boolean,
        permission?: () => ({
            success: boolean
            message: string
        })
    }>,
    url: string | undefined,
    expanded: boolean,
    visible: boolean,
    hideArrow: boolean,
    withoutItems: boolean,
    order: number,
    permission?: () => ({
        success: boolean
        message: string
    })
}> = {
    "personalDetails": {
        patientMenuId: EnumPatientMenuSystemIds.PersonalDetails,
        name: "personalDetails",
        title: "PERSONAL DETAILS",
        url: undefined,
        hideArrow: false,
        withoutItems: false,
        items: {
            "demographics":
            {
                itemName: "demographics",
                description: "Demographics",
                information: "",
                url: AppUrl.PATIENTINFO + AppUrl.DEMOGRAPHICS,
            },
            "studentInfo":
            {
                itemName: "studentInfo",
                description: "Student Info",
                information: "",
                url: AppUrl.PATIENTINFO + AppUrl.STUDENTINFO,
            },
            "otherContacts":
            {
                itemName: "otherContacts",
                description: "Other Contacts",
                information: "",
                url: AppUrl.PATIENTINFO + AppUrl.OTHERCONTACTS,
            },
            "insurance":
            {
                itemName: "insurance",
                description: "Insurance",
                information: "",
                url: AppUrl.PATIENTINFO + AppUrl.INSURANCE,
            },
            "claimProfile":
            {
                itemName: "claimProfile",
                description: "Claim Profile",
                information: "",
                url: AppUrl.PATIENTINFO + AppUrl.CLAIM_PROFILE,
            },
            "additionalInfo":
            {
                itemName: "additionalInfo",
                description: "Additional Info",
                information: "",
                url: AppUrl.PATIENTINFO + AppUrl.AdditionalInfo,
                permission: () => {
                    return permissionChecking({
                        anyClinic: true,
                        moduleId: 4,
                        functionId: 318
                    })
                }
            },
            "administrativeNotes":
            {
                itemName: "administrativeNotes",
                description: "Administrative Notes",
                information: "",
                url: AppUrl.PATIENTINFO + AppUrl.AdministrativeNotes,
                permission: () => {
                    return permissionChecking({
                        anyClinic: true,
                        moduleId: 27,
                        functionId: 1
                    })
                }
            },

        },
        expanded: false,
        visible: true,
        order: 1
    },
    "messages": {
        patientMenuId: EnumPatientMenuSystemIds.Messages,
        name: "messages",
        title: "MESSAGES",
        url: AppUrl.CLIENT_MESSAGES_ROUTE,
        hideArrow: true,
        withoutItems: true,
        items: {},
        expanded: false,
        visible: false,
        order: 2
    },
    "patientHistory": {
        patientMenuId: EnumPatientMenuSystemIds.AppointmentHistory,
        name: "patientHistory",
        title: "APPOINTMENT HISTORY",
        url: AppUrl.APPOINTMENT_HISTORY,
        items: {},
        expanded: false,
        visible: true,
        hideArrow: true,
        withoutItems: true,
        order: 3
    },
    "dashboard": {
        patientMenuId: EnumPatientMenuSystemIds.Dashboard,
        name: "dashboard",
        title: "DASHBOARD",
        url: AppUrl.COUNSELING + AppUrl.COUNSELINGDASHBOARD,
        items: {},
        expanded: false,
        visible: true,
        hideArrow: true,
        withoutItems: true,
        order: 4
    },
    "compliance": {
        patientMenuId: EnumPatientMenuSystemIds.Compliance,
        name: "compliance",
        title: "COMPLIANCE",
        url: undefined,
        hideArrow: false,
        withoutItems: false,
        items: {
            "compliance": {
                itemName: "compliance",
                description: "Status",
                information: "",
                url: AppUrl.COMPLIANCE + AppUrl.COMPLIANCE_ANALYSIS,
            },
            "facts": {
                itemName: "facts",
                description: "Facts",
                information: "",
                url: AppUrl.COMPLIANCE + AppUrl.COMPLIANCE_FACTS,
            },
            "messages": {
                itemName: "messages",
                description: "Messages",
                information: "",
                url: AppUrl.COMPLIANCE + AppUrl.MESSAGES,
            },
        },
        expanded: false,
        visible: false,
        order: 7
    },
    "notesOfConcern": {
        patientMenuId: EnumPatientMenuSystemIds.NotesOfConcern,
        name: "notesOfConcern",
        title: "NOTES OF CONCERN",
        items: {},
        url: AppUrl.COUNSELING_NOTES_OF_CONCERN,
        expanded: false,
        visible: true,
        hideArrow: true,
        withoutItems: true,
        order: 8
    },
    "forms": {
        patientMenuId: EnumPatientMenuSystemIds.Forms,
        name: "forms",
        title: "FORMS",
        items: {},
        url: AppUrl.PATIENT_FORMS,
        expanded: false,
        visible: true,
        hideArrow: true,
        withoutItems: true,
        order: 9
    },
    "todo": {
        patientMenuId: EnumPatientMenuSystemIds.ToDo,
        name: "todo",
        title: "TO DO",
        items: {},
        url: AppUrl.PATIENT_TODO,
        expanded: false,
        visible: true,
        hideArrow: true,
        withoutItems: true,
        order: 11
    },
    "orders": {
        patientMenuId: EnumPatientMenuSystemIds.Orders,
        name: "orders",
        title: "Orders",
        items: {},
        url: AppUrl.PATIENT_ORDERS,
        expanded: false,
        visible: true,
        hideArrow: true,
        withoutItems: true,
        order: 12,
        permission: () => {
            const list = permittedClasses()
            return {
                success: list.length > 0,
                message: ''
            }
        }
    },
    [EnumPatientMenuSystemIds.AllNotes]: {
        patientMenuId: EnumPatientMenuSystemIds.AllNotes,
        name: EnumPatientMenuSystemIds.AllNotes,
        title: "ALL NOTES",
        items: {
            "notes": {
                itemName: "notes",
                description: "Notes",
                information: "",
                url: `${AppUrl.PATIENT_NOTES}?type=${NoteParamType.all}`,
                isNotes: true
            },
            "inProgress": {
                description: "In Progress Notes",
                itemName: "iProgress",
                information: "",
                url: `${AppUrl.PATIENT_NOTES}?type=${NoteParamType.inProgress}`,
                isInProgressNotes: true
            },
        },
        url: undefined,
        expanded: false,
        visible: true,
        hideArrow: false,
        withoutItems: false,
        order: 10
    },
};

const NavigationReducer = (
    state: any = {},
    action: IAction
) => {
    const checkExist = () => {
        let newState = _.cloneDeep(state)
        if (!newState[action.subjectId]) {
            newState[action.subjectId] = _.cloneDeep(initialState);
        }
        return newState
    }

    switch (action.type) {
        case actionTypes.SET_NAVIGATION_DATA: {
            let newState = checkExist()
            let item = newState[action.subjectId];
            let newItem = _.merge({}, item, action.payload);
            newState[action.subjectId] = newItem;
            return newState;
        }
        case actionTypes.SET_NOTES_IN_PROGRESS: {
            let newState = checkExist()
            let item = newState[action.subjectId];
            let newItem = { ...item, notesInProgress: { ...item.notesInProgress, items: { ...action.payload.notesInProgress.items } } };
            newState[action.subjectId] = newItem;
            return newState;
        }
        case actionTypes.SET_ACCORDION_EXPAND: {
            let newState = checkExist()
            if (newState[action.subjectId]) {
                const item = newState[action.subjectId][action.payload.name]
                newState[action.subjectId][action.payload.name] = {
                    ...item,
                    name: action.payload.name,
                    expanded: action.payload.expanded
                };
            }
            return newState;
        }
        default:
            return state;
    }
};
export default NavigationReducer;