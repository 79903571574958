import React, { FC } from 'react';
import { EnumFields } from './types';
import { fieldNames } from '.';
import { formattedDate } from 'src/Framework/Shared/Shared';
import { getMaskOptions } from 'src/Framework/Controls/SelectContainer/Components/Input';

interface IProps {
  data?: Record<EnumFields, any>;
}

const notAnswered = '__________';

const answers: {
  [key in EnumFields]?: (value: any) => any;
} = {
  [EnumFields.HomePhone]: (value: any) => {
    if (value) {
      return getMaskOptions('phone', value)?.value;
    }
    return notAnswered;
  },
  [EnumFields.WorkPhone]: (value: any) => {
    if (value) {
      return getMaskOptions('phone', value)?.value;
    }
    return notAnswered;
  },
  [EnumFields.MobilePhone]: (value: any) => {
    if (value) {
      return getMaskOptions('phone', value)?.value;
    }
    return notAnswered;
  },
  [EnumFields.Pager]: (value: any) => {
    if (value) {
      return getMaskOptions('phone', value)?.value;
    }
    return notAnswered;
  },
  [EnumFields.EmergencyPhone1]: (value: any) => {
    if (value) {
      return getMaskOptions('phone', value)?.value;
    }
    return notAnswered;
  },
  [EnumFields.EmergencyPhone2]: (value: any) => {
    if (value) {
      return getMaskOptions('phone', value)?.value;
    }
    return notAnswered;
  },
  [EnumFields.BirthDate]: (value: any) => {
    if (value) {
      return formattedDate(value);
    }
    return notAnswered;
  },
  [EnumFields.EnrollmentDate]: (value: any) => {
    if (value) {
      return formattedDate(value);
    }
    return notAnswered;
  },
  [EnumFields.PrimaryPolicySubscriberDOB]: (value: any) => {
    if (value) {
      return formattedDate(value);
    }
    return notAnswered;
  },
  [EnumFields.PrimaryPolicyEffectiveDate]: (value: any) => {
    if (value) {
      return formattedDate(value);
    }
    return notAnswered;
  },
  [EnumFields.PrimaryPolicyExpirationDate]: (value: any) => {
    if (value) {
      return formattedDate(value);
    }
    return notAnswered;
  },
  [EnumFields.SecondaryPolicyEffectiveDate]: (value: any) => {
    if (value) {
      return formattedDate(value);
    }
    return notAnswered;
  },
  [EnumFields.SecondaryPolicyExpirationDate]: (value: any) => {
    if (value) {
      return formattedDate(value);
    }
    return notAnswered;
  }
};

const generateAnswer = (value: any, key: EnumFields) => {
  if (answers[key]) {
    return answers[key]?.(value);
  }
  if (value === null || value === undefined) {
    return notAnswered;
  }
  if (typeof value === 'boolean') {
    if (value) return 'True';
    return 'False';
  }
  if (typeof value === 'string') {
    if (value.length === 0) return notAnswered;
    return value;
  }
  return value;
};

const Component: FC<IProps> = ({ data }: IProps) => {
  return (
    <div>
      {Object.entries(data || {})
        .sort(([key1], [key2]) =>
          (fieldNames[key1] as string)?.localeCompare(fieldNames[key2])
        )
        .map(([key, value]) => {
          return (
            <div key={key}>
              <b>{fieldNames[key]}</b>
              <span>{` - ${generateAnswer(value, key as EnumFields)}`}</span>
            </div>
          );
        })}
    </div>
  );
};

export default React.memo(Component);
