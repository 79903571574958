import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { APIHandler } from "src/Framework/Communication/ServerProxy";

export const GET_PATIENT_IMAGE = "GET_PATIENT_IMAGE";

export const getPatientImage = (subjectId: any) => {
  return APIHandler.AxiosInstance.post(ApiUrl.GET_PATIENT_IMAGE, {subjectId: subjectId}, {
    withCredentials: true,
  });
};

export const addPatientImage = (payload: any, subjectId: any) => {
  return {
    type: GET_PATIENT_IMAGE,
    payload: payload,
    subjectId: subjectId,
  };
};
