import { Col, Row } from 'antd';
import React, { FC } from 'react';
import Icon from 'src/Framework/Controls/Icon';
import { Text } from './styled';
import EllipsisText from 'src/Framework/Controls/EllipsisText';

interface IProps {
  value: {
    value: string;
  };
}

const Component: FC<IProps> = ({ value }: IProps) => {
  return (
    <Row align="middle" gutter={4} wrap={false}>
      <Col>
        <Icon name="certificate" size={18} />
      </Col>
      <Col flex={1}>
        <Text>
          <EllipsisText>{value.value}</EllipsisText>
        </Text>
      </Col>
    </Row>
  );
};

export default React.memo(Component);
