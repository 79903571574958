import _ from 'lodash';
// import { ApiUrl } from 'src/Framework/Common/ApiUrl';
// import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { dashboardInit } from '..';

import * as groupTxnActions from 'src/App/GroupTxn/store/Actions';

export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const SET_AVAILABLE_CATEGORIES = 'SET_AVAILABLE_CATEGORIES';

//DASHBOARD
export const getAvailableCategoriesData = async () => {
  try {
    // const response = await APIHandler.AxiosInstance.post(ApiUrl.GET_AVAILABLE_CATEGORIES, {}, { withCredentials: true });
    const response = await groupTxnActions.get();
    if (response) {
      const data = _.pickBy(_.cloneDeep(response), (value) => value.isActive)
      Object.values(data).forEach((v) => {
        v.isDynamic = true
      })
      return {
        data: {
          result: {
            ...data,
            alerts: {
              id: -1,
              name: 'Alerts',
              isDynamic: false,
            },
            ccaps: {
              id: -1,
              name: 'CCAPS',
              isDynamic: false,
            },
            medications: {
              id: -1,
              name: 'Medications',
              isDynamic: false,
            },
            diagnosis: {
              id: -1,
              name: 'Diagnosis & Treatment Plan',
              isDynamic: false,
            },
            risks: {
              id: -1,
              name: 'Risks',
              isDynamic: false,
            },
            assessments: {
              id: -1,
              name: 'Assessments',
              isDynamic: false,
            },
            attachments: {
              id: -1,
              name: 'Attachments',
              isDynamic: false,
            },
            consents: {
              id: -1,
              name: 'Consents',
              isDynamic: false,
            },
            hospitalizations: {
              id: -1,
              name: 'Hospitalizations',
              isDynamic: false,
            },
            referrals: {
              id: -1,
              name: 'Referrals',
              isDynamic: false,
            },
            allergies: {
              id: -1,
              name: 'Allergies',
              isDynamic: false,
            }
          },
          success: true
        }
      };
    }
  } catch (e) { }
  return;
};

export const setAvailableCategoriesData = (data: any) => {
  return {
    type: SET_AVAILABLE_CATEGORIES,
    payload: data
  };
};

export const getDashboardUI = (pref: any, settings: any) => {
  let dashboard = _.cloneDeep(dashboardInit);
  dashboard.items = getDashboardUIItems(pref, settings);
  return dashboard;
};

const getDashboardUIItems = (pref: any, settings: any) => {
  let initData = {};
  if (pref) {
    Object.keys(pref)
      .filter((v) => v !== 'alerts' && v !== 'risks')
      .forEach((key: any, index: number) => {
        if (settings[key] && pref[key].show === true) {
          initData[key] = {
            name: settings[key].name,
            itemName: key,
            ...pref[key]
          };
        }
        if (settings[key] === 'counselingReferrals') {
        }
      });
  }
  return initData;
};
