import { IFilter, INoteType } from './types';
import { ISort } from 'src/Framework/Controls/Table/types';

interface IAction {
  type: string;
  payload: any;
}

export interface IStateReducer {
  list: {
    summary: {
      [key: string]: INoteType;
    };
    total: number;
  };
  filter: IFilter
  sort: ISort;
  loading: boolean;
  templateForms: {
    summary: Record<
      string,
      {
        name: string;
        code: string;
        isActive: boolean;
        seedId: number;
        id: number;
        clinicIds: number[];
        groupId: null | number
      }
    >;
    total: number;
  };
}

const pref = `NOTE_TYPES_`

export const actionsTypes = {
  ADD_NODE_TYPE: 'ADD_NODE_TYPE',
  SET_NODE_TYPES: 'SET_NODE_TYPES',
  SET_NODE_TYPES_LOADING: 'SET_NODE_TYPES_LOADING',
  SET_NODE_TYPES_TEMPLATE_FORMS: 'SET_NODE_TYPES_TEMPLATE_FORMS',
  CHANGE_NODE_TYPE: 'CHANGE_NODE_TYPE',
  SET_TABLE_SORT: `NOTE_TYPES_SET_TABLE_SORT`,
  SET_FITLER: `${pref}SET_FITLER`
};

const initialState: IStateReducer = {
  list: {
    summary: {},
    total: 0
  },
  loading: true,
  templateForms: {
    summary: {},
    total: 0
  },
  sort: {
    sortBy: 'name',
    sortType: 'asc'
  },
  filter: {
    search: '',
    clinicIds: []
  }
};

const Reducer = (
  state: IStateReducer = { ...initialState },
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.CHANGE_NODE_TYPE:
      state.list.summary[action.payload.id] = { ...action.payload };
      return {
        ...state
      };
    case actionsTypes.ADD_NODE_TYPE:
      const a = Object.values(state.list.summary).find(
        (v) => v.seedId === action.payload.seedId
      );
      if (a) {
        delete state.list.summary[a.id];
      }
      state.list.summary[action.payload.id] = { ...action.payload };
      return {
        ...state
      };
    case actionsTypes.SET_NODE_TYPES:
      return { ...state, list: action.payload };
    case actionsTypes.SET_NODE_TYPES_LOADING:
      return { ...state, loading: action.payload };
    case actionsTypes.SET_NODE_TYPES_TEMPLATE_FORMS:
      return { ...state, templateForms: action.payload };
    case actionsTypes.SET_TABLE_SORT:
      return { ...state, sort: action.payload };
    case actionsTypes.SET_FITLER:
      return {
        ...state, filter: {
          ...state.filter,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default Reducer;
