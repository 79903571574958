import { APIHandler } from "../../../Framework/Communication/ServerProxy";
import { ApiUrl } from "../../../Framework/Common/ApiUrl"
import { expiryCheck } from 'src/App/LoginPage/store/Actions';

import store from 'src/store'

export const ADD_SUBJECTS = "ADD_SUBJECTS";
export const ADD_FACTS = "ADD_FACTS";
export const ADD_FACTS_PATIENT_DETAIL = "ADD_FACTS_PATIENT_DETAIL";
export const ADD_MESSAGES = "ADD_MESSAGES";
export const CLEAR_DASHBOARD = "CLEAR_DASHBOARD";
export const GET_MESSAGE_PROVIDER_FOR_CLINIC = "GET_MESSAGE_PROVIDER_FOR_CLINIC";
export const SET_MESSAGE_SELECTED_PROVIDERS = "SET_MESSAGE_SELECTED_PROVIDERS";

export const addSubjects = (result: any, pagination: any) => {
  return {
    type: ADD_SUBJECTS,
    subjectsSummary: result,
    pagination: pagination
  };
};

export const addFacts = (result: any, unverifiedCount: any, flaggedCount: any, pagination: any,) => {
  return {
    type: ADD_FACTS,
    factsSummary: result,
    unverifiedCount: unverifiedCount,
    flaggedCount: flaggedCount,
    pagination: pagination,

  };
};


export const addFactsPatientDetail = (result: any, unverifiedCount: any, flaggedCount: any, pagination: any) => {
  return {
    type: ADD_FACTS_PATIENT_DETAIL,
    factsSummary: result,
    pagination: pagination,
    unverifiedCount: unverifiedCount,
    flaggedCount: flaggedCount,
  };
};
export const addMessages = (result: any, pagination: any) => {
  return {
    type: ADD_MESSAGES,
    messagesSummary: result,
    pagination: pagination
  };
};
export const clearDashboard = () => {
  return {
    type: CLEAR_DASHBOARD,
  };
};

export const messageProviderForClinic = (result: any) => {
  return {
    type: GET_MESSAGE_PROVIDER_FOR_CLINIC,
    payload: result,
  };
};

export const setMessageSelectedProviders = (result: any) => {
  return {
    type: SET_MESSAGE_SELECTED_PROVIDERS,
    payload: result,
  };
};


// export const updateRuleDetails = (
//   ruleId: string,
//   ruleName: string,
//   description: string
// ) => {
//   return {
//     type: UPDATE_RULE_DETAILS,
//     ruleId: ruleId,
//     ruleName: ruleName,
//     description: description
//   };
// };


export const getSubjects = (
  currentPage = 1,
  cohortId: any,
  count = 20,
  sortBy: any,
  searchText: string,
  currentPagination: any,
  callBack: any
) => {
  let paginationDetails = {
    count: count,
    filter: {
      cohortId: cohortId, searchField: ["lastName", "firstName", "patientId"], searchString: searchText
    },
    sortBy: sortBy,
    startIndex: (currentPage - 1) * count,
  };

  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(ApiUrl.GET_SUBJECTS, paginationDetails, { withCredentials: true })
      .then(response => {
        if (callBack) {
          callBack();
        }
        let data: any = response.data;
        if (data.success) {
          let pagination = {
            ...currentPagination,
            total: data.result.total,
            startIndex: data.result.startIndex,
            current: currentPage
          };
          dispatch(addSubjects(data.result.summary, pagination));
        }
      })
      .catch(error => {
        if (callBack) callBack();
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};

export const getDocumentDetails = (
  currentPage = 1,
  cohortId: any,
  count = 20,
  searchText: string,
  currentPagination: any,
  callBack: any,
  factsLength: number = 0,
  flagged: any,
  sortBy: any,
  sortType: any,
) => {
  let paginationDetails = {
    startIndex: (currentPage - 1) === 0 ? 0 : factsLength !== 0 ? factsLength : (currentPage - 1) * count,
    count: count,
    filter: {
      cohortId: cohortId,
      processed: null,
      flagged: flagged,
      searchString: searchText,
      sortBy: sortBy ? sortBy : "date",
      sortType: sortType ? sortType : "Asc",
      searchField: ["firstName", "lastName", "patientId"]
    },
  };

  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(ApiUrl.GET_DOCUMENT_DETAILS, paginationDetails, { withCredentials: true })
      .then(response => {
        if (callBack) {
          callBack();
        }
        let data: any = response.data;
        if (data.success) {
          let pagination = {
            ...currentPagination,
            total: data.result.total,
            current: currentPage
          };
          dispatch(addFacts(data.result.summary, data.result.unverifiedCount, data.result.flaggedCount, pagination));
        }
      })
      .catch(error => {
        if (callBack) callBack();
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};

export const getPatientComplianceDetail = async (
  currentPage = 1,
  cohortId: any,
  count = 20,
  searchText: string,
  currentPagination: any,
  callBack: any,
  factsLength: number = 0,
  flagged: any,
  sortBy: any,
  sortType: any,
) => {
  const { dispatch }: any = store
  try {
    let paginationDetails = {
      startIndex: (currentPage - 1) === 0 ? 0 : factsLength !== 0 ? factsLength : (currentPage - 1) * count,
      count: count,
      filter: {
        cohortId: cohortId,
        processed: null,
        flagged: flagged,
        name: searchText,
        sortBy: sortBy ? sortBy : "date",
        sortType: sortType ? sortType : "Asc",
        searchString: searchText,
        searchField: ["firstName", "lastName", "patientId"]

      },
    };
    const response = await APIHandler.AxiosInstance.post(ApiUrl.GET_PATIENT_COMPLIANCE_DETAIL, paginationDetails, { withCredentials: true })
    if (callBack) {
      callBack();
    }
    let data: any = response.data;
    if (data.success) {
      let pagination = {
        ...currentPagination,
        total: data.result.total,
        current: currentPage
      };
      dispatch(addFactsPatientDetail(data.result.summary, data.result.unverifiedCount, data.result.flaggedCount, pagination));
    }
  } catch (error) {
    if (callBack) callBack();
    dispatch(expiryCheck(error));
    console.log(error);
  }
}


export const getMessages = (
  currentPage = 1,
  cohortId: any,
  count = 20,
  sortBy: any,
  searchText: string,
  currentPagination: any,
  callBack: any,
  messageLength: number = 0,
  providerIds: number[] = []
) => {
  let paginationDetails = {
    startIndex: (currentPage - 1) === 0 ? 0 : messageLength !== 0 ? messageLength : (currentPage - 1) * count,
    cohortId: cohortId,
    includeInactive: false,
    sortBy: sortBy,
    count: count,
    providerIds: providerIds,
    filter: {
      read: false,
      searchString: searchText,
    },
  };

  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(ApiUrl.GET_MESSAGES, paginationDetails, { withCredentials: true })
      .then(response => {
        if (callBack) {
          callBack();
        }
        let data: any = response.data;
        if (data.success) {
          let pagination = {
            ...currentPagination,
            total: data.result.total,
            current: currentPage
          };
          dispatch(addMessages(data.result.summary, pagination));
        }
      })
      .catch(error => {
        if (callBack) callBack();
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};

export const setPreference = (
  data: any
) => {
  return APIHandler.AxiosInstance.post(ApiUrl.SAVE_USER_PREFERENCES, data, { withCredentials: true })
};
export const getPreference = (
  data: any
) => {
  return APIHandler.AxiosInstance.post(ApiUrl.GET_USER_PREFERENCES, data, { withCredentials: true })
};

export const getMessageProviderForClinic = (clinicCodes: any[]) => {
  return APIHandler.AxiosInstance.post(ApiUrl.GET_MESSAGE_PROVIDER_FOR_CLINIC,
    {
      typeToRetrieve: 0,
      clinicCodes,//: ["Counsel", "SouthCNC", "North", "West"],
      includeInactive: false
    },
    { withCredentials: true })
}