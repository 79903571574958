import React, { FC } from 'react';
import { Col, Row } from 'antd';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

import { Container, Title } from './styled';

export interface IModalSliderHeader {
  title?: string | React.ReactNode;
  onClose?: () => any;
  headerRight?: React.ReactNode;
  backButton?: boolean;
  onClickBack?: () => any;
  hideCloseButton?: boolean;
  noWrap?: boolean;
}

const BackIcon = require('src/Framework/Common/Svg/back.svg').default;

const ModalSliderHeader: FC<IModalSliderHeader> = ({
  title,
  onClose,
  headerRight,
  backButton,
  onClickBack,
  hideCloseButton,
  noWrap
}: IModalSliderHeader) => {
  return (
    <Container>
      <Row align="middle" justify="space-between" wrap={noWrap ? false : true}>
        <Col>
          <Row align="middle" gutter={16} wrap={false}>
            {backButton && (
              <Col>
                <div
                  tabIndex={0}
                  role="button"
                  aria-label="Back button"
                  id="close-button"
                  className="back-button"
                  style={{
                    WebkitMaskImage: `url(${BackIcon})`,
                    maskImage: `url(${BackIcon})`
                  }}
                  onClick={() => onClickBack && onClickBack()}
                  onKeyDown={onKeyDownAccessibility}
                />
              </Col>
            )}
            <Col>
              <Title tabIndex={0}>{title}</Title>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row align="middle" gutter={16} wrap={false}>
            <Col>{headerRight}</Col>
            {!hideCloseButton && (
              <Col>
                <ButtonIcon
                  isButton={true}
                  label="close modal"
                  name={ICONS_LIST.thinClose}
                  color="rgba(153, 153, 153, 1)"
                  onClick={onClose}
                  disableBackground={true}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(ModalSliderHeader);
