import React from 'react';

import { IItemTree } from 'src/Framework/Controls/Tree';
import { onKeyDownCallbacks } from 'src/Framework/util/accessibility';
import history from '../../../history';
import { idGenerator } from 'src/Framework/Controls/Tree/utils';
import { ROUTES } from 'src/ManagementSystem/routes';

const BuildingBlocks = React.lazy(
    () =>
        import(/* webpackChunkName: "BuildingBlocks" */ './Pages/BuildingBlocks')
);

const Forms = React.lazy(
    () =>
        import(/* webpackChunkName: "Forms" */ './Pages/Forms')
);

interface IPermissionResult {
    success: boolean,
    accessMessage: string
}

export const getFirstPage = (item: IItem) => {
    const key = item.withoutClick ? Object.keys(item.options)[0] : item.key;
    return {
        key,
        path: item.withoutClick ? PagesList[key].path : `${PagesList[key].path}`
    };
};

export const PagesList: {
    [key: string]: {
        path: string;
        component: any;
        key: string;
        permission?: () => IPermissionResult;
        containerId?: string;
        exact?: boolean
    };
} = {
    buildingBlocks: {
        path: ROUTES.BuildingBlocks,
        component: BuildingBlocks,
        key: 'buildingBlocks',
        containerId: 'management-system-building-blocks'
    },
    forms: {
        path: ROUTES.Forms,
        component: Forms,
        key: 'forms',
        containerId: 'management-system-forms'
    },
};

export const AdminRightContainerID = 'management-system-right-container';

export const handleClasses = {};

[
    'top',
    'left',
    'bottom',
    'topRight',
    'bottomRight',
    'bottomLeft',
    'topLeft'
].forEach((key: string) => {
    handleClasses[key] = 'displayNone';
});

interface IItem extends IMenuItem, IItemTree {
    children?: any[];
    title: string;
    key: string;
}

interface IMenuItem {
    name: string;
    open?: boolean;
    withoutClick?: boolean;
    options: Record<string, IMenuItem>;
    permission?: () => IPermissionResult;
}

interface ISideBar {
    menu: Record<string, IMenuItem>;
}

export const SIDEBAR_DATA: ISideBar = {
    menu: {
        sharingLibrary: {
            name: 'Sharing library',
            open: true,
            withoutClick: true,
            options: {
                buildingBlocks: { name: 'Building blocks', options: {} },
                forms: { name: 'Forms', options: {} },
            }
        },
    }
};

export const getTreeData = (data: ISideBar) => {
    const treeData: IItem[] = [];
    const defaultExpandedKeys: Array<string> = [];
    const recursion = (obj: Record<string, IMenuItem>, array: IItem[]) => {
        Object.keys(obj).forEach((key) => {
            let item: IItem = {
                ...obj[key],
                key: '',
                title: ''
            };
            if (item.permission && !item.permission().success) {
                return;
            }
            const pagePermission = PagesList[key]?.permission;
            if (pagePermission && !pagePermission().success) {
                return;
            }
            if (item.options) {
                item.children = [];
                recursion(item.options, item.children);
            }
            item.title = item.name;
            item.key = key;
            if (item.open) {
                defaultExpandedKeys.push(item.key);
            }
            array.push(item);
        });
    };
    if (data.menu) {
        recursion(data.menu, treeData);
    }
    return {
        defaultExpandedKeys,
        treeData
    };
};


export const focusOnSidebar = (e: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyDownCallbacks(e, {
        ArrowLeft: () => {
            const menuData = getTreeData(SIDEBAR_DATA);
            const el = checkActive(menuData);
            const current = document.getElementById(idGenerator(el.key));
            if (current) {
                current.focus();
            }
        }
    });
};

export const checkActive = (menuData: any) => {
    const value = Object.values(PagesList).find((v) =>
        history?.location?.pathname.includes(v.path)
    );
    if (value && (value.permission ? value.permission() : true)) {
        return value;
    }
    const firstMenuItem = getFirstPage(menuData.treeData[0]);
    return firstMenuItem;
};
