import { IAction, IStateReducer, IDiagnosisState } from './types';

const prefix = 'DIAGNOSIS_';

export const actionsTypes = {
  SET: `${prefix}SET`,
  SET_SORT: `${prefix}SET_SORT`
};

export const initialData: IDiagnosisState = {
  summary: [],
  sort: {
    sortBy: null,
    sortType: null
  }
};

const initialState: IStateReducer = {
  patients: {}
};

const Reducer = (
  state: IStateReducer = { ...initialState },
  action: IAction
) => {
  switch (action.type) {
    case actionsTypes.SET:
      if (state.patients[action.patientId]) {
        return {
          ...state,
          patients: {
            ...state.patients,
            [action.patientId]: {
              ...state[action.patientId],
              summary: action.payload
            }
          }
        };
      }
      return {
        ...state,
        patients: {
          ...state.patients,
          [action.patientId]: {
            ...initialData,
            summary: action.payload
          }
        }
      };
    case actionsTypes.SET_SORT:
      return {
        ...state,
        patients: {
          ...state.patients,
          [action.patientId]: {
            ...state.patients[action.patientId],
            sort: action.payload
          }
        }
      };
    default:
      return state;
  }
};

export default Reducer;
