import React, { FC, useEffect, useState } from 'react';

import Container from './Container';

import { Message } from './styled';

interface IProps {
  message?: string;
}

const Component: FC<IProps> = ({ message }: IProps) => {
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (!message) {
      setHeight(0);
    } else {
      setHeight(18);
    }
  }, [message]);
  return (
    <Message height={height} exist={!!message}>
      <Container message={message} />
    </Message>
  );
};

export default React.memo(Component);
