import moment from 'moment';
import React, { FC, useMemo } from 'react';

import { ISelect } from '../index';

import Select from './Select';

export interface ITime extends Omit<ISelect, 'type'> {
  type: 'time';
  filterOption?: (option: { value: string; label: string }) => boolean;
}

type IProps = ITime & {
  setAsyncLoading: Function;
};

const timeOptions = () => {
  let options = [];
  let startDay = moment(new Date()).startOf('day');
  let time = startDay.format('LT');
  let time24 = startDay.format('hh:mm A');

  options.push({ value: time, label: time });

  do {
    startDay = startDay.add(30, 'minutes');
    time = startDay.format('LT');
    time24 = startDay.format('hh:mm A');
    options.push({ value: time24, label: time });
  } while (time !== '11:30 PM');

  return options;
};

const options = timeOptions();

const Component: FC<IProps> = (props: IProps) => {
  const filteredOptions = useMemo(() => {
    if (props?.filterOption) {
      return options.filter(props?.filterOption);
    }
    return options;
  }, [options, props.filterOption]);
  return (
    <Select
      {...({ ...props, type: 'select' } as ISelect)}
      setAsyncLoading={props.setAsyncLoading}
      options={filteredOptions}
    />
  );
};

export default Component;
