import _ from 'lodash'
import { IAction, IStateReducer, IConsents } from './types'

const prefix = 'CONSENTS_REDUCER_'

export const actionsTypes = {
    SET_CONSENTS: `${prefix}SET_CONSENTS`,
    SET_SORT: `${prefix}SET_SORT`,
}

export const initialConsents: IConsents = {
    summary: {},
    sort: {
        sortBy: null,
        sortType: null
    }
}

const initialState: IStateReducer = {};

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET_SORT:
            return {
                ...state,
                [action.patientId]: {
                    ...state[action.patientId],
                    sort: action.payload
                }
            }
        case actionsTypes.SET_CONSENTS:
            if (state[action.patientId]) {
                return {
                    ...state,
                    [action.patientId]: {
                        ...state[action.patientId],
                        summary: action.payload
                    }
                }
            }
            return {
                ...state,
                [action.patientId]: {
                    ...initialConsents,
                    summary: action.payload
                }
            }
        default:
            return state;
    }
};

export default Reducer;
