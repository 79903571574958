import React, { FC, useState } from 'react';
import { Tooltip, Button, Row, Col } from 'antd';
import { CheckboxProps } from 'antd/es/checkbox/Checkbox';

import Modal from 'src/Framework/Controls/Modal';
import Checkbox from 'src/Framework/Controls/Checkbox';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import { Container, ModalContainer, Text } from './styled';

interface IProps {
  value: boolean;
  onChangeAction: () => Promise<any>;
  checkboxProps?: Partial<CheckboxProps>;
  tooltip?: string;
  confirm?: {
    action: 'check' | 'uncheck';
    text: string;
    closeAfterConfirm?: boolean;
  };
}

const Component: FC<IProps> = ({
  value,
  onChangeAction,
  checkboxProps,
  tooltip,
  confirm
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const onChange = async () => {
    if (loading) return;
    setLoading(true);
    await onChangeAction();
    setLoading(false);
    close();
  };

  const onKeyDown = (e: React.KeyboardEvent<any>) => {
    if (
      e.key === 'Enter' ||
      (e.code === 'Space' && (e.target as HTMLElement).tagName === 'INPUT')
    ) {
      e.preventDefault();
      e.stopPropagation();
      if (e.currentTarget) {
        // @ts-ignore
        e.currentTarget.click();
      }
    }
  };
  const close = () => {
    setVisible(false);
  };
  const renderMain = () => {
    return (
      <Checkbox
        tabIndex={0}
        checked={value}
        onKeyDown={onKeyDown}
        onChange={() => {
          if (confirm) {
            if (confirm.action === 'check' && !value) {
              return setVisible(true);
            }
            if (confirm.action === 'uncheck' && value) {
              return setVisible(true);
            }
          }
          onChange();
        }}
        id=""
        {...checkboxProps}
        tooltip={tooltip}
      />
    );
  };
  return (
    <Container className="custom-checkbox">
      {loading && <AbsoluteLoader size={14} />}
      {tooltip ? (
        <Tooltip title={tooltip} overlayStyle={{ zIndex: 10000 }}>
          <div>{renderMain()}</div>
        </Tooltip>
      ) : (
        renderMain()
      )}
      {confirm && (
        <Modal
          width={500}
          title="Confirmation"
          visible={visible}
          onClose={close}
          headerBorder={true}
        >
          <ModalContainer>
            <Row
              gutter={16}
              style={{
                flexWrap: 'nowrap'
              }}
              align="middle"
            >
              <Col>
                <Text>
                  {confirm.text ||
                    `Are you sure that you want to ${
                      value ? 'disable' : 'enable'
                    }?`}
                </Text>
              </Col>
              <Col>
                <Row
                  gutter={16}
                  style={{
                    flexWrap: 'nowrap'
                  }}
                  align="middle"
                >
                  <Col>
                    <Button
                      loading={loading}
                      onClick={() => {
                        if (confirm.closeAfterConfirm) {
                          close();
                        }
                        onChange();
                      }}
                    >
                      Yes
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        close();
                      }}
                    >
                      No
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalContainer>
        </Modal>
      )}
    </Container>
  );
};

export default Component;
