import _ from 'lodash';
import { IOrderReportReducer, IAction } from '../types';

const prefix = 'ORDER_REPORT_';

export const actionsTypes = {
  SET_SUMMARY: `${prefix}SET_SUMMARY`,
  SET_FILES: `${prefix}SET_FILES`
} as const;

type IActions = Record<
  typeof actionsTypes[keyof typeof actionsTypes],
  () => IOrderReportReducer
>;

export const defaultData: IOrderReportReducer = {
  summary: {},
  crystalReportsFiles: []
};

const Reducer = (
  state: IOrderReportReducer = _.cloneDeep(defaultData),
  action: IAction
): IOrderReportReducer => {
  const actions: IActions = {
    [actionsTypes.SET_SUMMARY]: () => {
      return {
        ...state,
        summary: action.payload
      };
    },
    [actionsTypes.SET_FILES]: () => {
      return {
        ...state,
        crystalReportsFiles: action.payload
      };
    }
  };
  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};

export default Reducer;
