import styled from "styled-components";
import globalStyleVariables from "../Styles/variables.module.scss";

export const Container = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Title = styled.div`
    margin: 30px 0;
    font-weight: 800;
    font-size: 24px;
    text-align: center;
    color: ${globalStyleVariables.newBrandingPrimary200};
`