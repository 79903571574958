import { useCallback, useMemo, useState } from "react"
import { IBuildingForm } from "../types"

import * as Actions from '../Actions'

export const useM1Form = () => {
    const [data, setDate] = useState<Record<string, IBuildingForm>>({})

    const getSimpleDto = useCallback(async (id: number) => {
        const response = await Actions.getSimpleDto(id)
        if (response) {
            setDate(data => ({
                ...data,
                [response.id]: response
            }))
        }
    }, [])

    const actions = useMemo(() => {
        return {
            getSimpleDto
        }
    }, [getSimpleDto])

    return {
        actions,
        data
    }
}