import { store } from 'src';
import { actionsTypes } from '..';
import { SummaryReviewLog } from '../types';
import { TempFieldsForTable } from 'src/Framework/Controls/Table/types';

export const setSummary = (
    payload: Record<string, SummaryReviewLog>,
) => {
    const patientId = Object.values(payload)[0]?.patientId
    store.dispatch({
        type: actionsTypes.SET_SUMMARY,
        payload,
        patientId
    });
};

export const onCreateUpdateItem = (
    payload: SummaryReviewLog & Partial<TempFieldsForTable>,
) => {
    const data = {
        ...(store.getState().summaryReviewLog[payload.patientId]?.summary || {})
    };
    if (data[payload.id]) {
        data[payload.id] = { ...data[payload.id], ...payload };
    } else {
        data[payload.id] = payload;
    }
    setSummary(data);
};
