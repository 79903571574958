import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"
import * as Setters from './Setters';

export const get = async (): Promise<Record<string, any> | undefined> => {
    try {
        Setters.loading(true)
        const response = await APIHandler.AxiosInstance.get(ApiUrl.GROUP_TXN, { withCredentials: true })
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result.summary
                Setters.summary(data)
                return data
            }
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
        Setters.loading(false)
    }
}