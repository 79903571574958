import React, { FC, useRef } from 'react';
import { Row, Col } from 'antd';

import Icon from 'src/Framework/Controls/Icon';

import { ITagValueSimpleDto } from '../../store/types';

import Modal, { IModalHandles, IData } from './Modal';

import { Container, TagContainer } from './styled';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

interface IProps {
  values: Record<string, ITagValueSimpleDto>;
  tags: number[];
  onChange: (data: IData) => Promise<any>;
  clinicIds: number[];
}

const Component: FC<IProps> = ({
  values,
  tags,
  onChange,
  clinicIds
}: IProps) => {
  const ModalRef = useRef<IModalHandles>(null);
  const list = Object.values(values).filter((v) => tags.includes(v.id));
  return (
    <Container>
      <Modal
        ref={ModalRef}
        values={values}
        clinicIds={clinicIds}
        onChange={async (data) => {
          return await onChange(data);
        }}
      />
      <Row gutter={[8, 8]}>
        {list.map((v) => {
          return (
            <Col key={v.id}>
              <TagContainer>{v.value}</TagContainer>
            </Col>
          );
        })}
        <Col>
          <Icon
            name="add"
            color="white"
            size={20}
            roundIcon={true}
            containerStyles={{
              backgroundColor: globalStyleVariables.newBrandingPrimary200
            }}
            onClick={() => {
              ModalRef.current?.show({ tags });
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Component;
