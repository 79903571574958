import { BucketsList } from 'src/Framework/ApiSubscription';
import { RequestHelper } from 'src/Framework/ApiSubscription/RequestHelper';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { ITraineeInfo } from '../types';

import { setInfo, onCreateUpdateItem } from './Setters';

export const SubscriptionData = (providerId: string | number) => ({
  bucket: BucketsList.trainee,
  filter: `${BucketsList.trainee}.ProviderId == ${providerId}`
});

export const { get, patch, unsubscribe } = new RequestHelper<
  ITraineeInfo,
  [providerId: string | number],
  [providerId: string | number]
>({
  link: ApiUrl.Trainees,
  onCreateUpdateItem,
  subscription: {
    subscriptionData: SubscriptionData,
    setSummary: setInfo
  }
});
