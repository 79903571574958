import _ from 'lodash';
import { MarketplaceBBSimpleDto, MarketplaceSimpleDto } from './types';

export interface IStateReducer {
    summary: MarketplaceSimpleDto[]
    buldingBlocksSummary: MarketplaceBBSimpleDto[]
}

export interface IAction {
    type: typeof actionsTypes[keyof typeof actionsTypes];
    payload: any;
}


const prefix = 'MARKETPLACE_';

export const actionsTypes = {
    SET_SUMMARY: `${prefix}SET_SUMMARY`,
    SET_BB_SUMMARY: `${prefix}SET_BB_SUMMARY`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

export const defaultData: IStateReducer = {
    summary: [],
    buldingBlocksSummary: []
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const actions: IActions = {
        [actionsTypes.SET_BB_SUMMARY]: () => {
            return {
                ...state,
                buldingBlocksSummary: action.payload
            };
        },
        [actionsTypes.SET_SUMMARY]: () => {
            return {
                ...state,
                summary: action.payload
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
