import React, { FC, useCallback, useRef, useState } from 'react';
import FormContainer, {
  IProps as IFormContainer
} from 'src/App/FormBuilder/FormContainer';
import { IM1FormRecord } from 'src/App/M1FormRecords/store/types';

import Header from './Header';
import History from './History';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

interface IProps {
  schema: any;
  submission: any;
  getFormioInstance?: IFormContainer['getFormioInstance'];
  form?: IM1FormRecord;
  previewIsReady?: () => any;
}

const Component: FC<IProps> = ({
  schema,
  submission,
  getFormioInstance,
  form,
  previewIsReady
}: IProps) => {
  const [loading, setLoading] = useState(true);
  const readyChecker = useRef({
    footer: true,
    header: form ? false : true,
    formIO: false
  });
  const checkPreviewIsReady = useCallback(() => {
    const isReady = Object.values(readyChecker.current).every(
      (v) => v === true
    );
    if (isReady) {
      setLoading(false);
      if (previewIsReady) {
        previewIsReady();
      }
    }
  }, []);
  const instanceReady = useCallback(
    (...args: any) => {
      readyChecker.current.formIO = true;
      checkPreviewIsReady();
      if (getFormioInstance) {
        getFormioInstance(args);
      }
    },
    [getFormioInstance]
  );

  const headerDataIsReady = useCallback(() => {
    readyChecker.current.header = true;
    checkPreviewIsReady();
  }, []);

  return (
    <>
      {loading && <AbsoluteLoader />}
      {form && <Header formId={form.id} dataIsReady={headerDataIsReady} />}
      <FormContainer
        previewMode={true}
        schema={schema}
        submission={submission}
        onChange={() => {}}
        getFormioInstance={instanceReady}
      />
      {form && <History form={form} />}
    </>
  );
};

export default React.memo(Component);
