import { BucketsList } from 'src/Framework/ApiSubscription';
import { RequestHelper } from 'src/Framework/ApiSubscription/RequestHelper';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { IProviderContract } from '../types';

import { setSummary, onCreateUpdateItem } from './Setters';

export const SubscriptionData = (providerId: string | number) => ({
  bucket: BucketsList.providerContract,
  filter: `${BucketsList.providerContract}.ProviderId == ${providerId}`
});

export const { post, get, patch, unsubscribe } = new RequestHelper<
  IProviderContract,
  [providerId: string | number],
  [providerId: string | number]
>({
  link: ApiUrl.ProviderContracts,
  onCreateUpdateItem,
  subscription: {
    subscriptionData: SubscriptionData,
    setSummary
  }
});
