interface IPatientProvider {
  [patientId: string]: {
    summary: Record<string, IM1FormRecord>;
    summaryInNotes: Record<string, IM1FormRecord>;
    loading: boolean;
  };
}

export interface IStateReducer {
  patients: IPatientProvider;
  providers: IPatientProvider;
}

export interface IAction {
  type: string;
  payload: any;
  patientId: any;
  providerId: any;
}

export interface IM1FormRecord {
  id: number;
  isActive: boolean | null;
  formTemplateId: number;
  formTemplateName: string | null;
  formTemplateVersion: number;
  assignedProviderId: number | null;
  subject: M1FormRecordSubject | null;
  status: M1FormRecordStatus;
  clinicId: number;
  submission: string | null;
  createDts: string;
  createUserId: number;
  modifyDts: string | null;
  modifyUserId: number | null;
  history: M1FormRecordHistoryEntry[];
  subjectPatientId: number;
  subjectProviderId: number;
  noteId: null | number;
  description?: string;
  summary?: string | null;
}

export enum M1FormRecordHistoryAction {
  Append = 'append',
  Send = 'send',
  Sign = 'sign'
}

export interface M1FormRecordHistoryEntry {
  action: M1FormRecordHistoryAction;
  actionContent: string;
  dts: string;
  userId: number;
}

export enum M1FormRecordStatus {
  Invalid = 'invalid',
  InProgress = 'inProgress',
  Signed = 'signed',
  Locked = 'locked'
}

export enum M1FormSubjectType {
  Invalid = 'invalid',
  Patient = 'patient',
  Provider = 'provider'
}

export interface M1FormRecordSubject {
  id: number;
  subjectType: M1FormSubjectType;
}
