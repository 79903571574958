import React, { useImperativeHandle, useState } from 'react';

import { Row, Col, notification } from 'antd';

import Button, { IProps as IButtonProps } from 'src/Framework/Controls/Button';
import SelectContainer from 'src/Framework/Controls/SelectContainer';

import ValidationRules from 'src/App/UserPreferences/ChangePassword/ValidationRules';
import Modal from 'src/Framework/Controls/Modal';

import {
  Container,
  Header,
  Title,
  SubTitle,
  Body,
  Footer,
  CenteredContainer,
  CreatePasswordContainer
} from './styled';
import { useValidatorV2 } from 'src/Framework/util/formValidatorV2';

import * as Actions from 'src/App/LoginPage/store/Actions';
import { IGetForgotPasswordData } from 'src/App/LoginPage/store/types';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

interface IProps {}

const commonButtonProps: Partial<IButtonProps> = {
  shape: 'default',
  disableClickLoader: true
};

enum Pages {
  verification = 'Security question',
  createPassword = 'Create Password'
}

const buttons = [Pages.verification, Pages.createPassword];

interface IProps {}

interface IShow {}

export interface IModalHandles {
  show(obj: IShow): void;
  close(): void;
}

const modalProps = {
  destroyOnClose: true
};

interface IData {
  currentPassword: string;
  securedPassword: string;
  securedPasswordConfirm: string;
}

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  {},
  ref
) => {
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [forgotPasswordData, setForgotPasswordData] =
    useState<IGetForgotPasswordData | null>(null);
  const [data, setData] = useState<Partial<IData>>({});

  const show = async ({}: IShow) => {
    setVisible(true);
    const url = new URLSearchParams(window.location.search);
    const code = url.get('code');
    if (code) {
      setLoading(true);
      const res = await Actions.getForgotPasswordData({
        code: decodeURIComponent(code)
      });
      if (res) {
        setForgotPasswordData(res);
      }
      setLoading(false);
    }
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setAnswer('');
    setData({});
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  const [page, setPage] = useState<Pages>(Pages.verification);

  const [validations, setValidations] = useState<Record<Pages, boolean>>({
    [Pages.verification]: true,
    [Pages.createPassword]: false
  });

  const onNextVerification = async () => {
    if (forgotPasswordData) {
      const res = await Actions.answerChallengeQuestion({
        answer,
        objectSid: forgotPasswordData.objectSid,
        challengeQuestionSessionCode:
          forgotPasswordData.challengeQuestionSessionCode
      });
      if (res) {
        setValidations((v) => ({ ...v, [Pages.createPassword]: true }));
        setPage(Pages.createPassword);
      }
    }
  };

  const onClickFinish = async () => {
    if (forgotPasswordData) {
      if (
        data.securedPassword &&
        data.securedPasswordConfirm &&
        data.currentPassword
      ) {
        const res = await Actions.changePassword({
          currentPassword: data.currentPassword,
          securedPassword: data.securedPassword,
        });
        if (res) {
          notification.success({
            message: 'Password changed successfully',
            description: 'Please login with your new credentials'
          });
          close();
        }
      }
    }
  };

  const validatorToken = useValidatorV2(
    [
      {
        value: answer
      }
    ],
    [answer]
  );

  return (
    <Modal
      width={920}
      title={''}
      visible={visible}
      onClose={close}
      headerBorder={true}
      afterClose={setToDefault}
      modalProps={modalProps}
      hideHeader={true}
    >
      <Container>
        {loading && <AbsoluteLoader />}
        <Header>
          <Title>Create new Password</Title>
          <Row gutter={16}>
            {buttons.map((value) => {
              const valid = validations[value];
              const current = page === value;
              return (
                <Col key={value}>
                  <Button
                    id={`button-${value}`}
                    {...commonButtonProps}
                    disabled={!valid}
                    colorStyles={{
                      blue: valid && !current,
                      secondColorBackground: current
                    }}
                  >
                    {value}
                  </Button>
                </Col>
              );
            })}
          </Row>
        </Header>
        <Body>
          {page === Pages.verification && (
            <CenteredContainer>
              <CenteredContainer>
                <b>
                  You need to enter an answer to the security question below.
                </b>
                <SubTitle>
                  Please enter the answer to the security question below which
                  you provided to prove your identity.
                </SubTitle>
              </CenteredContainer>
              <div style={{ width: 400 }}>
                <SelectContainer
                  label="Question"
                  type="input"
                  value={answer}
                  onChange={(value) => setAnswer(value)}
                  bottomMargin={false}
                />
              </div>
            </CenteredContainer>
          )}
          {page === Pages.createPassword && (
            <CreatePasswordContainer>
              <CenteredContainer>
                <b>Create your new password.</b>
                <SubTitle>
                  Your Login User Name is <b>LOGIN</b>
                </SubTitle>
              </CenteredContainer>

              <Row gutter={32}>
                <Col flex={2}>
                  <SelectContainer
                    label={'Old password'}
                    type="input"
                    value={data.currentPassword}
                    onChange={(currentPassword) =>
                      setData((v) => ({ ...v, currentPassword }))
                    }
                    inputProps={{
                      type: 'password'
                    }}
                  />
                  <SelectContainer
                    label={'New password'}
                    type="input"
                    value={data.securedPassword}
                    onChange={(securedPassword) =>
                      setData((v) => ({ ...v, securedPassword }))
                    }
                    inputProps={{
                      type: 'password'
                    }}
                  />
                  <SelectContainer
                    label={'Confirm password'}
                    type="input"
                    value={data.securedPasswordConfirm}
                    onChange={(securedPasswordConfirm) =>
                      setData((v) => ({ ...v, securedPasswordConfirm }))
                    }
                    bottomMargin={false}
                    inputProps={{
                      type: 'password'
                    }}
                  />
                </Col>
                <Col flex={1}>
                  <ValidationRules
                    data={{
                      newPassword: data.securedPassword || '',
                      confirmPassword: data.securedPasswordConfirm || ''
                    }}
                  />
                </Col>
              </Row>
            </CreatePasswordContainer>
          )}
        </Body>
        <Footer>
          <Row gutter={16} align="middle" justify="space-between">
            <Col></Col>
            <Col>
              {page === Pages.verification && (
                <Button
                  id="next"
                  onClick={onNextVerification}
                  disabled={!validatorToken.isValid}
                >
                  Submit
                </Button>
              )}
              {page === Pages.createPassword && (
                <Button
                  id="finish"
                  disabled={
                    data.currentPassword &&
                    data.securedPassword &&
                    data.securedPasswordConfirm &&
                    data.securedPassword === data.securedPasswordConfirm
                      ? false
                      : true
                  }
                  colorStyles={{
                    successBorder: true
                    // greenBorder: true
                  }}
                  onClick={onClickFinish}
                >
                  Finish
                </Button>
              )}
            </Col>
          </Row>
        </Footer>
      </Container>
    </Modal>
  );
};

export default React.forwardRef(Component);
