import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &.onShowAnimation {
    transition: transform 0.3s;
    transform: rotate(0);
    transform-origin: center;

    & .visible {
      transform: scale(1);
    }

    &.rotate {
      transform: rotate(-180deg);
    }

    &.rotate-collapse {
      transform: rotate(90deg);
    }
  }
`;

export const Icon: any = styled.div`
  mask-size: contain;
  mask-position: center center;
  mask-repeat: no-repeat no-repeat;
  ${(props: any) => `background: ${props.color};`}
  ${(props: any) => {
    if (props.enableHover) {
      return `&:hover{
                background: ${globalStyleVariables.newBrandingPrimary200};
            }`;
    }
    return '';
  }}
`;
