import { IAction, IStateReducer } from './types';
import moment from 'moment';
import { dateRangeFormat } from 'src/Framework/Shared/Shared';

const prefix = 'DASHBOARD_';

export const actionsTypes = {
  SET_ACTIVE_DASHBOARD: `${prefix}SET_ACTIVE_DASHBOARD`,
  SET_ACTIVE_RISK: `${prefix}SET_ACTIVE_RISK`,
  SET_TASK: `${prefix}SET_TASK`,
  SET_MY_WATCH_LIST: `${prefix}SET_MY_WATCH_LIST`,
  SET_ASSIGNED_CLIENTS: `${prefix}SET_ASSIGNED_CLIENTS`,
  SET_STUDENTS_OF_CONCERN: `${prefix}SET_STUDENTS_OF_CONCERN`,
  SET_STUDENTS_ON_HIGH_RISK: `${prefix}SET_STUDENTS_ON_HIGH_RISK`,
  SET_CRISIS_APPOINTMENTS: `${prefix}SET_CRISIS_APPOINTMENTS`,
  SET_FILTERS: `${prefix}SET_FILTERS`,
  SET_RISKS_FILTERS: `${prefix}SET_RISKS_FILTERS`,
  SET_SORT: `${prefix}SET_SORT`,
  SET_TODOS: `${prefix}SET_TODOS`,
  SET_SUPERVISOR_MY_WATCH_LIST: `${prefix}SET_SUPERVISOR_MY_WATCH_LIST`,
  SET_PROVIDER_DASHBOARD_RISKS: `${prefix}SET_PROVIDER_DASHBOARD_RISKS`,
  SET_CASE_MANAGEMENT_DASHBOARD_RISKS: `${prefix}SET_CASE_MANAGEMENT_DASHBOARD_RISKS`
};
const rangeStartLimit = -30;
const initialState: IStateReducer = {
  providerDashboard: {
    risks: []
  },
  caseManagementDashboard: {
    risks: []
  },
  activeDashboard: '',
  provider: {},
  tasks: {
    summary: {}
  },
  todos: [],
  riskManagement: {
    active: 0,
    myWatchList: [],
    assignedClients: [],
    studentsOfConcern: [],
    studentsOnHighRisk: [],
    crisisAppointments: [],
    filters: {
      providersIds: [],
      dateRange: [
        moment().add(rangeStartLimit, 'day').format(dateRangeFormat),
        moment().format(dateRangeFormat)
      ],
      clinicId: null
    }
  },
  supervisor: {
    myWatchList: []
  },
  filters: {
    startTime: moment().add(rangeStartLimit, 'day').format(dateRangeFormat),
    endTime: moment().format(dateRangeFormat),
    parallelPrevious: 'Previous',
    frequency: 'Week',
    appointmentType: null,
    provider: null,
    caseManagerProviderId: null,
    supervisonProvidersIds: []
  },
  sort: {
    supervisorMyWatchList: {
      sortBy: null,
      sortType: null
    },
    myWatchList: {
      sortBy: 'lastName',
      sortType: 'asc'
    },
    studentsOfConcern: {
      sortBy: 'lastName',
      sortType: 'asc'
    },
    studentsOnHighRisk: {
      sortBy: 'lastName',
      sortType: 'asc'
    },
    crisisAppointments: {
      sortBy: 'lastName',
      sortType: 'asc'
    },
    activeReferrals: {
      sortBy: null,
      sortType: null
    },
    highPriorityReferrals: {
      sortBy: null,
      sortType: null
    },
    assignedClients: {
      sortBy: null,
      sortType: null
    }
  }
};

const Reducer = (
  state: IStateReducer = { ...initialState },
  action: IAction
): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET_CASE_MANAGEMENT_DASHBOARD_RISKS:
      return {
        ...state,
        caseManagementDashboard: {
          ...state.caseManagementDashboard,
          risks: action.payload
        }
      };
    case actionsTypes.SET_PROVIDER_DASHBOARD_RISKS:
      return {
        ...state,
        providerDashboard: {
          ...state.providerDashboard,
          risks: action.payload
        }
      };
    case actionsTypes.SET_SUPERVISOR_MY_WATCH_LIST:
      return {
        ...state,
        supervisor: {
          ...state.supervisor,
          myWatchList: action.payload
        }
      };
    case actionsTypes.SET_SORT:
      return {
        ...state,
        sort: {
          ...state.sort,
          [action.sortType]: action.payload
        }
      };
    case actionsTypes.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filterType]: action.payload
        }
      };
    case actionsTypes.SET_RISKS_FILTERS:
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          filters: {
            ...state.riskManagement.filters,
            ...action.payload
          }
        }
      };
    case actionsTypes.SET_TASK:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          summary: action.payload
        }
      };
    case actionsTypes.SET_TODOS:
      return {
        ...state,
        todos: action.payload
      };
    case actionsTypes.SET_MY_WATCH_LIST:
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          myWatchList: action.payload
        }
      };
    case actionsTypes.SET_ASSIGNED_CLIENTS:
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          assignedClients: action.payload
        }
      };
    case actionsTypes.SET_STUDENTS_OF_CONCERN:
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          studentsOfConcern: action.payload
        }
      };
    case actionsTypes.SET_STUDENTS_ON_HIGH_RISK:
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          studentsOnHighRisk: action.payload
        }
      };
    case actionsTypes.SET_CRISIS_APPOINTMENTS:
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          crisisAppointments: action.payload
        }
      };
    case actionsTypes.SET_ACTIVE_DASHBOARD:
      return {
        ...state,
        activeDashboard: action.payload
      };
    case actionsTypes.SET_ACTIVE_RISK:
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          active: action.payload
        }
      };
    default:
      return state;
  }
};

export default Reducer;
