export const AppStrings = {
  DASHBOARD: "Activity",
  RULE_MANAGER: "Rule Manager",
  SYSTEM_SETUP: "System Setup",
  BILLING: "Billing",
  RULE_EDITOR: "Rule Editor",
  RULE_VIEWER: "Rule Viewer",
  NOTIFICATION_MANAGER: "Notification Manager",
  NOTIFICATION_VIEWER: "Notification Viewer",
  COMPLIANCE_DASHBOARD: "Compliance Dashboard",
  SCHEDULER: "Calendar",
  ORDERS: "Orders",
  SCPM: "Scpm",
  REPORTS: "Reports",
  CATS: "Cats",
  MESSAGES: "Messages Center",
  PATIENTHEADERSETTINGS: "Admin Page",
  NO_RESULT_FOUND: "No results were found",
  NEW_RULE: "New Rule",
  COHORT_MANAGER: "Cohort Manager",
  COHORT: "Cohort",
  NEW_COHORT: "New Cohort",
  NEW_COHORT_SELECT_RULE: "New Cohort - Select rule",
  COHORTS: "Cohorts",
  COHORT_COMPLIANCE_VIEWER: "Cohort Compliance Viewer",
  SEARCH_COHORT: "Search cohort",
  SUBJECTS: "Subjects",
  MANAGESUBJECTS: "Manage Subjects",
  DASHBOARDSETTINGS: "Admin Dashboard",
}

export const SwitchStrings = {
  task: 'task',
  patient: 'patient'
};