
export const UPDATE_SIGNALR_CONNECTION_STATE = "UPDATE_SIGNALR_CONNECTION_STATE";
export const SAVE_SIGNALR_CONNECTION= "SAVE_SIGNALR_CONNECTION";
  export const updateSignalRConnectionStatus=(state: any) => {
    return {
      type: UPDATE_SIGNALR_CONNECTION_STATE,
      payload: state,
    };
  }

  export const saveSignalRConnection=(connection: any) => {
    return {
      type: SAVE_SIGNALR_CONNECTION,
      payload: connection,
    };
  }