import { useEffect, useState } from 'react';

let mql = window.matchMedia('(orientation: portrait)');

export const useScreenOrientation = () => {
  const [portraitOrientation, setPortraitOrientation] = useState<boolean>(
    mql.matches
  );
  useEffect(() => {
    const listener = () => {
      setPortraitOrientation(mql.matches);
    };

    mql.addEventListener('change', listener);
    return () => {
      mql.removeEventListener('change', listener);
    };
  }, []);
  return {
    portraitOrientation
  };
};
