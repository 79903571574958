import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px ${globalStyleVariables.headerSidePadding};
  background: white;

  .search-container {
    display: flex;
    align-items: baseline;
    gap: 12px;

    label {
      color: ${globalStyleVariables.middleGrey};
    }
  }
`;

export const Title = styled.div`
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`;
