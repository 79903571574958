import _ from "lodash";
import { notification } from 'antd'

import store from 'src/store'

import { actionsTypes } from './index'
import { ApiUrl } from "../../../Framework/Common/ApiUrl";
import { APIHandler } from "../../../Framework/Communication/ServerProxy";
import { getClinics } from "src/Framework/Controls/Selectors/ClinicSelector";


export const getCurrentUserPermissions = async (id?: number) => {
  try {
    const userId = id || Number(localStorage.getItem('userId'))
    let body = {
      userId: userId,
    }

    const response = await APIHandler.AxiosInstance.post(ApiUrl.GET_CURRENT_USER_PERMISSION, body, {
      withCredentials: true,
    });
    if (response.data.success) {
      addCurrentUserPermission(response.data.result.permissions)
    }
  }
  catch (error) {
    console.log(error);
  }
}

export const addCurrentUserPermission = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_PERMISSIONS,
    payload,
  });
};

export const checkPermission = (permissions: any, clinicId: any, userId: any, moduleId: any, functionId: any) => {
  let key = `c#${clinicId}:u#${userId}:m#${moduleId}:f#${functionId}`;
  if (permissions && permissions[key]) {
    return permissions[key];
  }
  return false;
}

export const checkPermissions = (clinicId: any, moduleId: any, functionId: any) => {
  const permissions = store.getState().user.permissions;
  const { userId } = store.getState().user.current;
  return checkPermission(permissions, clinicId, userId, moduleId, functionId)
}

export const checkForEveryClinicPermission = (moduleId: number, functionId: number, errorMessage?: string) => {
  const clinics = getClinics()
  const permissions = store.getState().user.permissions;
  const { userId } = store.getState().user.current;
  const permission = clinics.find((v) => checkPermission(permissions, v.id, userId, moduleId, functionId))
  if (permission) {
    return true;
  }
  if (errorMessage) {
    notification.error({
      message: errorMessage
    });
  }
  return false;
};

export const checkCurrentUserPermission = (moduleId: number, functionId: number, errorMessage?: string) => {
  const permissions = store.getState().user.permissions;
  const { clinicId, userId } = store.getState().user.current;
  const permission = checkPermission(permissions, clinicId, userId, moduleId, functionId);
  if (permission) {
    return true;
  }
  if (errorMessage) {
    notification.error({
      message: errorMessage
    });
  }
  return false;
};

export const checkPermissionByClinicCode = (clinicCode: string, moduleId: number, functionId: number) => {
  const clinics = getClinics()
  const permissions = store.getState().user.permissions;
  const { userId } = store.getState().user.current;
  const clinic = clinics.find((v) => v.code === clinicCode)
  if (clinic) {
    return checkPermission(permissions, clinic.id, userId, moduleId, functionId)
  }
  return false
};