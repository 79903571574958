import React, { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Input, InputRef } from 'antd';
import { InputProps } from 'antd/es/input';
import IMask from 'imask';
import { v4 } from 'uuid';

import { ICommon, IInputEditConfirm } from '../index';

import EditApprove, { IModalHandles } from '../EditApprove';
import { SearchIconSvg } from 'src/Framework/Common/Svg/SearchIconSvg';
import { spellReplacer } from './Area';

export const MASKS = {
  zip: '00000-0000',
  phone: '(000) 000-0000 Ext-[00000]',
  phoneHome: '(000) 000-0000',
  socialSecurity: '000-00-0000'
} as const;

type TypeMask = keyof typeof MASKS;

export const getMaskOptions = (mask: TypeMask, value: string) => {
  const masked = IMask.createMask({
    mask: MASKS[mask]
  });
  masked.resolve(value);
  return {
    value: masked.value,
    unmaskedValue: masked.unmaskedValue,
    isComplete: masked.isComplete
  };
};

export interface IInput extends ICommon {
  type: 'input';
  value?: string | null | undefined;
  onChange?: (value: string) => any;
  className?: string;
  disabled?: boolean;
  inputProps: Partial<InputProps>;
  inputEditConfirm?: IInputEditConfirm;
  /**
   * @param inputMask mask field you can find all masks here -> {@link MASKS}
   * if you need get unmask value or use mask out of input you can use this function {@link getMaskOptions}
   */
  inputMask?: TypeMask;
  searchIcon?: boolean;
}

export const searchSvgIcon = <SearchIconSvg />;

const Component: FC<IInput> = (props: IInput) => {
  const currentKey = useRef<string | null>(null);
  const EditApproveRef = useRef<IModalHandles>(null);
  const InputRef = useRef<InputRef>(null);
  const spellReplacerSelection = useRef<null | number>(null);

  const labelIdRef = useRef(v4());
  const {
    star,
    label,
    bottomMargin,
    value,
    onChange,
    className,
    disabled,
    inputProps,
    inputEditConfirm,
    inputMask,
    hideLabel,
    searchIcon,
    id
  } = props;
  const [editRejected, setEditRejected] = useState<boolean>(true);
  const areaLabel = typeof label === 'string' ? label : '';

  useEffect(() => {
    if (spellReplacerSelection.current !== null) {
      InputRef.current?.setSelectionRange?.(
        spellReplacerSelection.current,
        spellReplacerSelection.current
      );
      spellReplacerSelection.current = null;
    }
  }, [props.value]);

  return (
    <>
      {inputEditConfirm && (
        <EditApprove
          ref={EditApproveRef}
          data={inputEditConfirm}
          onApprove={() => {
            setEditRejected(false);
          }}
          onReject={() => {
            setEditRejected(true);
          }}
        />
      )}
      <label
        id={labelIdRef.current}
        htmlFor={inputProps.id ? inputProps.id : id}
        className="visually-hidden"
      >
        {label}
      </label>
      <Input
        aria-labelledby={labelIdRef.current}
        aria-required={props.star}
        id={id}
        prefix={searchIcon ? searchSvgIcon : undefined}
        {...inputProps}
        onKeyDown={(e) => {
          if (onChange) {
            currentKey.current = e.code;
          }
          inputProps?.onKeyDown && inputProps?.onKeyDown(e);
        }}
        onKeyUp={(e) => {
          currentKey.current = null;
          inputProps?.onKeyUp && inputProps?.onKeyUp(e);
        }}
        value={
          inputMask
            ? getMaskOptions(inputMask, value || '')?.value
            : value
            ? value
            : ''
        }
        className={cn({
          select: true,
          margin: bottomMargin,
          [`${className}`]: className
        })}
        placeholder={
          inputProps?.hasOwnProperty('placeholder')
            ? inputProps?.placeholder
            : hideLabel
            ? areaLabel
            : ''
        }
        onClick={() => {
          if (inputEditConfirm && editRejected) {
            EditApproveRef.current?.show({});
          }
        }}
        onChange={(e) => {
          if (onChange) {
            if (inputEditConfirm && editRejected) return;
            let text = e.target.value;
            if (inputProps.maxLength) {
              text = text.slice(0, inputProps.maxLength);
            }
            if (inputMask) {
              const unmask = getMaskOptions(inputMask, text)?.unmaskedValue;
              onChange(unmask);
            } else {
              if (['Enter', 'Space'].some((v) => v === currentKey.current)) {
                const onChangeLogic = (
                  firstPart: string,
                  secondPart?: string
                ) => {
                  let text = firstPart;
                  const replacer = spellReplacer(text);
                  if (replacer.changed) {
                    text = replacer.text;
                    if (
                      inputProps?.maxLength &&
                      text.length > inputProps?.maxLength
                    ) {
                      text = text.slice(0, inputProps?.maxLength);
                    }
                    onChange(text + (secondPart || ''));
                    spellReplacerSelection.current = text.length;
                    return replacer;
                  }
                  return;
                };
                console.log(e.target);
                if (e.target.selectionStart === e.target.selectionEnd) {
                  const firstPart = e.target.value.slice(
                    0,
                    e.target.selectionStart || undefined
                  );
                  const secondPart = e.target.value.slice(
                    e.target.selectionStart || undefined,
                    e.target.value.length
                  );
                  const changed = onChangeLogic(firstPart, secondPart);
                  if (changed?.changed) {
                    return;
                  }
                } else {
                  const changed = onChangeLogic(e.target.value);
                  if (changed?.changed) {
                    return;
                  }
                }
              }
              onChange(text);
            }
          }
        }}
        onFocus={(e) => {
          if (inputProps.onFocus) {
            inputProps.onFocus(e);
          }
        }}
        disabled={disabled || inputProps?.disabled}
        required={star}
        ref={InputRef}
      />
    </>
  );
};

export default Component;
