import _ from 'lodash';
import { IStateReducer, IAction } from './types';

const prefix = 'TRANSACTION_SETS_';

export const actionsTypes = {
  SET_SUMMARY: `${prefix}SET_SUMMARY`
} as const;

type IActions = Record<
  typeof actionsTypes[keyof typeof actionsTypes],
  () => IStateReducer
>;

export const defaultData: IStateReducer = {
  summary: []
};

const Reducer = (
  state: IStateReducer = _.cloneDeep(defaultData),
  action: IAction
): IStateReducer => {
  const actions: IActions = {
    [actionsTypes.SET_SUMMARY]: () => {
      return {
        ...state,
        summary: action.payload
      };
    }
  };
  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};

export default Reducer;
