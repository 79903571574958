import _ from 'lodash'
import moment from 'moment'

import { IAction, IStateReducer } from './types'

const prefix = 'PATIENT_TODOS_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_LOADING: `${prefix}SET_LOADING`,
    SET_RANGE: `${prefix}SET_RANGE`,
    SET_SORT: `${prefix}SET_SORT`
}

export const defaultFilter = {
    assignedClinicId: null,
    assignedProviderIds: [],
    assignedUserId: null,
    isDone: null,
}

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>


export const defaultPatientData: IStateReducer['patients'][number] = {
    summary: [],
    loading: false,
    filters: { ...defaultFilter },
    range: {
        dateFrom: moment().startOf('year').format('MM/DD/YYYY'),
        dateTo: moment().add(14, "days").format('MM/DD/YYYY')
    },
    sort: {
        sortBy: null,
        sortType: null
    },
}

const initialState: IStateReducer = {
    patients: {}
};

const Reducer = (state: IStateReducer = { ...initialState }, action: IAction): IStateReducer => {
    const patientData = state.patients[action.patientId] || _.cloneDeep(defaultPatientData)
    const actions: IActions = {
        [actionsTypes.SET_SORT]: () => {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patientData,
                        sort: {
                            ...patientData.sort,
                            ...action.payload
                        }
                    }
                }
            };
        },
        [actionsTypes.SET_RANGE]: () => {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patientData,
                        range: {
                            ...patientData.range,
                            ...action.payload
                        }
                    }
                }
            };
        },
        [actionsTypes.SET_FILTER]: () => {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patientData,
                        filters: {
                            ...patientData.filters,
                            ...action.payload
                        }
                    }
                }
            };
        },
        [actionsTypes.SET]: () => {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.patientId]: {
                        ...patientData,
                        summary: action.payload
                    }
                }
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
