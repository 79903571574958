import _ from 'lodash';

import { compareDates } from 'src/Framework/util/helps';
import { IFormItem } from './FormList/types';
import { IFormData, Type } from './Forms';
import { AppUrl } from 'src/Framework/Common/AppUrl';

export const filterFormList = (filter: any, data: IFormData[]) => {
  let listToFilter = _.values(data);
  if (listToFilter && (filter.dateBegin || filter.dateEnd)) {
    if (filter.dateBegin) {
      listToFilter = listToFilter.filter((v) => {
        if (v.type == Type.LEGACY) {
          const a = compareDates(v.dts, filter.dateBegin);
          return a === 1 || a === 0;
        }
        if (v.type == Type.M1) {
          const a = compareDates(v.createDts, filter.dateBegin);
          return a === 1 || a === 0;
        }
        return 0;
      });
    }
    if (filter.dateEnd) {
      listToFilter = listToFilter.filter((v) => {
        if (v.type == Type.LEGACY) {
          const a = compareDates(v.dts, filter.dateEnd);
          return a === -1 || a === 0;
        }
        if (v.type == Type.M1) {
          const a = compareDates(v.createDts, filter.dateEnd);
          return a === -1 || a === 0;
        }
        return 0;
      });
    }
  }
  if (listToFilter && filter.status && filter.status !== 'All') {
    listToFilter = _.filter(listToFilter, ['status', filter.status]);
  }
  return listToFilter;
};

export const sortFormList = (sort: any, forms: IFormItem[]) => {
  forms = _.sortBy(forms, 'createdDts').reverse();
  if (forms && sort) {
    if (sort.name === 'asc') {
      forms = forms.sort((a, b) => a.summary?.localeCompare(b.summary));
    }
    if (sort.name === 'desc') {
      forms = forms
        .sort((a, b) => a.summary?.localeCompare(b.summary))
        .reverse();
    }
    if (sort.date === 'desc') {
      forms = _.sortBy(forms, 'createdDts').reverse();
    }
    if (sort.date === 'asc') {
      forms = _.sortBy(forms, 'createdDts');
    }
  }
  return forms;
};

const fieldChecker = (form: IFormData) => {
  if (form.type === Type.LEGACY) {
    return form.summary;
  }
  if (form.type === Type.M1) {
    return form.formTemplateName || '';
  }
  return '';
};

export const sortBy = (sort: any, forms: IFormData[]) => {
  let sortedForms = _.sortBy(forms, 'createdDts').reverse();
  if (forms && sort) {
    if (sort.name === 'asc') {
      sortedForms = sortedForms.sort((a, b) => {
        return fieldChecker(a)?.localeCompare(fieldChecker(b));
      });
    }
    if (sort.name === 'desc') {
      sortedForms = sortedForms
        .sort((a, b) => fieldChecker(a)?.localeCompare(fieldChecker(b)))
        .reverse();
    }
    if (sort.date === 'desc') {
      sortedForms = _.sortBy(sortedForms, 'createdDts').reverse();
    }
    if (sort.date === 'asc') {
      sortedForms = _.sortBy(sortedForms, 'createdDts');
    }
  }
  return sortedForms;
};

export const generateM1FormLink = (
  patientId: string | number,
  formId: number
) => {
  return `/client/${patientId}${AppUrl.PATIENT_FORMS}/${formId}?type=m1`;
};
