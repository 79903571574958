import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { expiryCheck } from 'src/App/LoginPage/store/Actions';
import { INotificationSummary } from '../Models/Rule';
import { ISort } from 'src/Framework/Controls/Table/types';
import store from 'src/store';
import { actionsTypes } from 'src/App/Admin/Pages/NoteTypes/store/Reducer';

export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ADD_LETTER_CODES = 'ADD_LETTER_CODES';

export const CLEAR_RULES = 'CLEAR_RULES';
// export const ADD_RULE_SUMMARY = "ADD_RULE_SUMMARY";
export const UPDATE_RULE_DETAILS = 'UPDATE_RULE_DETAILS';
export const REMOVE_NOTFICATION = 'REMOVE_NOTFICATION';
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_TEMPLATE = 'UPDATE_NOTIFICATION_TEMPLATE';

export const UPDATE_DRAFT_STATUS = 'UPDATE_DRAFT_STATUS';
export const CLEAR_RULE_MANAGER = 'CLEAR_RULE_MANAGER';

export const SET_TABLE_SORT = `NOTIFICATIONS_MANAGER_SET_TABLE_SORT`;

export const addNotifications = (result: any, pagination: any) => {
  return {
    type: ADD_NOTIFICATIONS,
    ruleSummary: result,
    pagination: pagination
  };
};

export const setSorting = (sort: ISort) => {
  store.dispatch({
    type: actionsTypes.SET_TABLE_SORT,
    sort
  });
};

export const addNotification = (result: any) => {
  return {
    type: ADD_NOTIFICATION,
    notification: result
  };
};

export const addCodesAndTypes = (result: any) => {
  return {
    type: ADD_LETTER_CODES,
    codesAndTypes: result
  };
};

export const clearRules = () => {
  return {
    type: CLEAR_RULES
  };
};

// export const updateRuleDetails = (
//   ruleId: string,
//   ruleName: string,
//   description: string
// ) => {
//   return {
//     type: UPDATE_RULE_DETAILS,
//     ruleId: ruleId,
//     ruleName: ruleName,
//     description: description
//   };
// };

export const getNotifications = (
  currentPage = 1,
  count = 0,
  sortBy = 'NameAsc',
  searchText = '',
  callBack?: any,
  IsPublished?: boolean
): any => {
  let paginationDetails = {
    count: count,
    filter: { searchString: searchText },
    searchString: searchText,
    sortBy: sortBy,
    startIndex: (currentPage - 1) * count
  };

  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(
      ApiUrl.GET_ALL_NOTIFICATION_TEMPLATES,
      paginationDetails,
      { withCredentials: true }
    )
      .then((response) => {
        if (callBack) {
          callBack();
        }
        let data: any = response.data;
        if (data.success) {
          let pagination = {
            total: data.result.total,
            current: currentPage
          };
          dispatch(addNotifications(data.result.summary, pagination));
        }
      })
      .catch((error) => {
        if (callBack) callBack();
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};

export const getNotification = (notificationId: string) => {
  let details = {
    id: notificationId
  };

  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(ApiUrl.GET_NOTIFICATION_TEMPLATE, details, {
      withCredentials: true
    })
      .then((response) => {
        let data: any = response.data;
        if (data.success) {
          let result = {
            ...data.result.template,
            id: notificationId
          };
          dispatch(addNotification(result));
        }
      })
      .catch((error) => {
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};

export const getLetterCodesAndTypes = (): any => {
  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(ApiUrl.GET_ALL_LETTER_CODES_AND_TYPES, null, {
      withCredentials: true
    })
      .then((response) => {
        let data: any = response.data;
        if (data.success) {
          dispatch(addCodesAndTypes(data.result));
        }
      })
      .catch((error) => {
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};

export const updateNotification = (
  notificationId: string,
  code: string,
  description: string,
  typeLetterId: number,
  textInLetter: string
) => {
  let details = {
    templateId: notificationId,
    code: code,
    description: description,
    typeLetterId: typeLetterId,
    textInLetter: textInLetter
  };

  return APIHandler.AxiosInstance.post(ApiUrl.UPDATE_NOTIFICATION, details, {
    withCredentials: true
  });
};

export const removeNotification = (notificationId: string) => {
  return {
    type: REMOVE_NOTFICATION,
    notificationId: notificationId
  };
};

export const updateNotificationTemplate = (notification: any) => {
  return {
    type: UPDATE_NOTIFICATION_TEMPLATE,
    notification: notification
  };
};

export const createNotification = (notification: INotificationSummary) => {
  return {
    type: CREATE_NOTIFICATION,
    notification: notification
  };
};

export const createTemplate = (notification: any) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.CREATE_NOTIFICATION_TEMPLATE,
    notification,
    { withCredentials: true }
  );
};

export const deleteTemplate = (notificationId: string) => {
  return (dispatch: any) => {
    let details = {
      id: notificationId
    };
    APIHandler.AxiosInstance.post(
      ApiUrl.DELETE_NOTIFICATION_TEMPLATE,
      details,
      { withCredentials: true }
    )
      .then((response) => {
        let data: any = response.data;
        if (data.success) {
          dispatch(removeNotification(notificationId));
        }
      })
      .catch((error) => {
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};
