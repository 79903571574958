import { BucketsList } from 'src/Framework/ApiSubscription';
import { RequestHelper } from 'src/Framework/ApiSubscription/RequestHelper';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { IProviderEvaluation } from '../types';

import { setSummary, onCreateUpdateItem } from './Setters'

export const SubscriptionData = (providerId: string | number) => ({
    bucket: BucketsList.providerEvaluation,
    filter: `${BucketsList.providerEvaluation}.ProviderId == ${providerId}`
})

export const { post, get, patch, unsubscribe } = new RequestHelper<IProviderEvaluation, [providerId: string | number], [providerId: string | number]>({
    link: ApiUrl.ProviderEvaluations,
    onCreateUpdateItem,
    subscription: {
        subscriptionData: SubscriptionData,
        setSummary
    }
})
