import React, { FC, useState } from 'react';
import { IActivity } from '../Models/Activity';
import {
  ActivityItemWrapper,
  ActivityIcon,
  ActivityItemContent,
  ActivityHeaderText,
  ActivityBodyContent
} from './ActivityItem_Styles';

interface IProps {
  activity: IActivity;
  title: string;
  subtitle: string;
  icon: any;
  index?: number;
}

const Component: FC<IProps> = (props: IProps) => {
  const [focus, setFocus] = useState(false);
  const onFocus = () => {
    console.log(focus);
    setFocus(true);
  };
  const onBlur = () => {
    setFocus(false);
  };
  return (
    <ActivityItemWrapper onFocus={onFocus} onBlur={onBlur}>
      <ActivityIcon isCurrent={props.activity.isCurrent}>
        {props.icon}
      </ActivityIcon>
      <ActivityItemContent>
        <ActivityHeaderText aria-label={props.title}>
          <span title={props.title}>{props.title}</span>
        </ActivityHeaderText>
        <ActivityBodyContent aria-label={props.subtitle}>
          <span title={props.subtitle}>{props.subtitle}</span>
        </ActivityBodyContent>
      </ActivityItemContent>
    </ActivityItemWrapper>
  );
};

export default React.memo(Component);
