import React, { FC } from 'react';
import { List } from 'antd';
import classNames from 'classnames';

export interface INoDataProps {
  removeVerticalPaddings?: boolean;
  littleIcon?: boolean;
}

const NoData: FC<INoDataProps> = ({ removeVerticalPaddings, littleIcon }) => {
  return (
    <List
      dataSource={[]}
      className={classNames({
        noDataListRemoveVerticalPaddings: removeVerticalPaddings,
        littleIcon: littleIcon
      })}
    />
  );
};

export default React.memo(NoData);
