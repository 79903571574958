

import React from "react";

export const SearchIconSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6002 13.6002L10.2002 10.2002" stroke="#666666" strokeMiterlimit="10"/>
    <path d="M6.8 11.6C9.45097 11.6 11.6 9.45097 11.6 6.8C11.6 4.14903 9.45097 2 6.8 2C4.14903 2 2 4.14903 2 6.8C2 9.45097 4.14903 11.6 6.8 11.6Z" stroke="#666666" strokeMiterlimit="10" strokeLinecap="square"/>
    </svg>
    
    
);





