import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    display: flex;
    flex:1;
    flex-direction: column;
`

export const FormContainer = styled.div`
    position: relative;
    display: flex;
    flex:1;
    padding: 64px 0 0 0;
    flex-direction: column;
`