import ApiSubscription from 'src/Framework/ApiSubscription';
import * as ConsentsActions from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Consents/store/Actions';
import * as ContractsActions from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Contracts/store/Actions';
import * as InfoActions from 'src/App/Trainee/Main/store/Actions';
import * as ContactsActions from 'src/App/Trainee/Main/Pages/Contacts/store/Actions';
import * as EvaluationsActions from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Evaluations/store/Actions';
import * as AssessmentsActions from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Assessments/store/Actions';
import * as SupervisorsActions from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Dashboard/Supervisors/store/Actions';
import * as supervisorsNotesActions from 'src/App/Trainee/Main/Pages/Supervision/Notes/store/Actions';
import * as GoalsActions from 'src/App/Trainee/Main/Pages/Dashboard/Pages/Goals/store/Actions';

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { usePermission } from '../User/Permissions/Hook';
import { useAppSelector } from 'src/store';
import {
  getCurrentProvider,
  getProviderById
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { updateCurrentActivity } from '../ActivityPanel/Store/ActivityActions';

export const unsubscribeProviderPage = (providerId: any) => {
  const ConsentsReq = ConsentsActions.SubscriptionData(providerId);
  const ContractsReq = ContractsActions.SubscriptionData(providerId);
  const InfoReq = InfoActions.SubscriptionData(providerId);
  const EvaluationsReq = EvaluationsActions.SubscriptionData(providerId);
  const AssessmentsReq = AssessmentsActions.SubscriptionData(providerId);
  const ContactsReq = ContactsActions.SubscriptionData(providerId);
  const SupervisorsReq = SupervisorsActions.SubscriptionData(
    [providerId],
    'providerTraineeId'
  );
  const SupervisionNotesReq =
    supervisorsNotesActions.SubscriptionData(providerId);
  const GoalsReq = GoalsActions.SubscriptionData([providerId], 'traineeId');

  ApiSubscription.instance.Unsubscribe(ConsentsReq);
  ApiSubscription.instance.Unsubscribe(GoalsReq);
  ApiSubscription.instance.Unsubscribe(ContractsReq);
  ApiSubscription.instance.Unsubscribe(EvaluationsReq);
  ApiSubscription.instance.Unsubscribe(AssessmentsReq);
  ApiSubscription.instance.Unsubscribe(SupervisorsReq);
  ApiSubscription.instance.Unsubscribe(InfoReq);
  ApiSubscription.instance.Unsubscribe(ContactsReq);
  ApiSubscription.instance.Unsubscribe(SupervisionNotesReq);
};

export const modalHeaderProps = {
  style: {
    borderTop: `4px solid ${globalStyleVariables.newBrandingPrimary200}`
    // borderTop: `4px solid ${globalStyleVariables.newBrandingTrainee}`
  }
};

export const checkCurrentProviderIsTrainee = (
  providerId?: string | number
) => {
  const id = getCurrentProvider();
  if (`${providerId}` !== `${id}`) {
    return true;
  }
  if (id) {
    const provider = getProviderById(id);
    if (provider) {
      if (provider.isTrainee) {
        return false;
      }
    }
  }
  return true;
};

export const useTraineeAccess = () => {
  const providers = useAppSelector((state) => state.user.providers.summary);
  const permission = usePermission({
    anyClinic: true,
    moduleId: 108,
    functionId: 23
  }).success;

  const isTrainee = (providerId: string | number) => {
    const provider = providers[providerId];
    if (provider) {
      return provider.isTrainee;
    }
    return false;
  };
  return {
    isTrainee: (providerId: string | number) => isTrainee(providerId),
    checkCurrentProviderIsTrainee: checkCurrentProviderIsTrainee,
    checkProviderHasTraineePermissions: (providerId: string | number) =>
      permission && isTrainee(providerId),
    permission
  };
};


export const goToProviderForm = (providerId: string | number, formRecordId: number | string) => {
  updateCurrentActivity({
    targetURL: `/trainee/${providerId}/forms?id=${formRecordId}`
  });
}