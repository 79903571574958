import React from 'react';
import { SketchPicker, ColorResult } from 'react-color';

interface IProps {
  handleChange: Function;
  color: string;
}

interface IState {
  displayColorPicker: boolean;
}

class Sketch extends React.Component<IProps, IState> {
  state = {
    displayColorPicker: false
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color: ColorResult) => {
    const { handleChange } = this.props;
    handleChange(color.hex);
  };

  render() {
    const { color } = this.props;
    const styles: any = {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: color
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer'
      },
      popover: {
        position: 'absolute',
        zIndex: 2
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
      }
    };

    return (
      <div style={{ display: 'flex' }}>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              disableAlpha={true}
              color={color}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default Sketch;
