export enum BoolString {
  True = 'True',
  False = 'False'
}

export interface IDocumentIploadKeyValuePairs {
  displayAllDocuments: BoolString;
  faxCoverSheetMessage: string;
  uploadMessage: string;
  uploadsNotCompleted: string;
  immunizationDatesMessage: string;
}

export interface IVisitPublishKeyValuePairs {
  includeNotes: BoolString;
  includeSummary: BoolString;
  visitsAfterDate: string;
  visitsClinicId: string;
  visitsMessage: string;
}

export interface IStatementsKeyValuePairs {
  allowOnlineBillPay: BoolString;
  onlySendToOnline: BoolString;
  showBalanceDue: BoolString;
  statementLinkName: string;
  statementMessage: string;
  statementType: null | StatementType;
}

export interface IEducationKeyValuePairs {
  educationMessage: string;
}

export interface ILabResultKeyValuePairs {
  labResultMessage: string;
}

export interface IInsuranceKeyValuePairs {
  allowInsuranceEntry: BoolString;
  allowInternationalInsurance: BoolString;
  allowPolicyHolderEntry: BoolString;
  allowRenewInsurance: BoolString;
  allowUploadInsuranceCard: BoolString;
  checkInsuranceEligibility: BoolString;
  expireDate: string;
  expireInsuranceCardPic: BoolString;
  insuranceCardExpireType: null | InsuranceCardExpireType;
  insuranceElectSHIPOption: string;
  insuranceMessage: string;
  insuranceSHIPMessage: string;
  insuranceWaiver: BoolString;
  insuranceWaiverDeniedMessage: string;
  insuranceWaiverFailedMessage: string;
  insuranceWaiverTemplateClinicID: string;
  insuranceWaiverTemplateFormID: string;
  insuranceWaiverTemplateProviderID: string;
  insuranceWaiverTemplateScore: string;
  insuranceWaiveSHIPOption: string;
  policyHolderHeading: string;
  requireInsuranceCardUpload: BoolString;
  saveHCFA: BoolString;
}

export interface IFormsKeyValuePairs {
  formsNotCompleted: string;
  hideNoAnswer: BoolString;
}

export interface IImmunizationKeyValuePairs {
  awaitingReviewMessage: string;
  displayImmunizationStatus: BoolString;
  groupAllImmunizations: BoolString;
  immFacility: string;
  immProvider: string;
  immunizationDatesMessage: string;
  immunizationEntryMessage: string;
  immunizationHistoryMessage: string;
  immunizationMessage: string;
  immunizationMessageAfterSave: string;
  isM1: BoolString;
  onePageDisplay: BoolString;
  onlyDisplayRequirements: BoolString;
  requireImmunizationDocUpload: BoolString;
  viewHistoricalHeading: string;
  viewMissingRequirmentHeading: string;
  viewServicesRenderedHeading: string;
  viewSubmittedUnverifiedHeading: string;
}

export interface IStatusKeyValuePairs {
  hideCOVIDHistory: BoolString;
  covidLabProviderID: string;
  covidLabResultAfterSave: string;
  covidLabTxnCodeID: string;
  covidLabEntry: BoolString;
  hideCOVIDMedallion: BoolString;
}

export interface IMainValuePairs {
  address1: string;
  address2: string;
  afterHoursPolicy: string;
  allowEditEmergencyContacts: BoolString;
  allowEditEthnicity: BoolString;
  allowEditGenderIdentity: BoolString;
  allowEditPreferredPronoun: BoolString;
  allowEditRace: BoolString;
  allowEditSexualOrientation: BoolString;
  allowEditUserInformation: BoolString;
  appointmentLinkName: string;
  city: string;
  clinicID: string;
  clinicMessage: string;
  clinicSpecificOptions: string;
  covid: string;
  education: BoolString;
  educationLinkName: string;
  errorMessage: string;
  forms: BoolString;
  homeMessage: string;
  immunizationLinkName: string;
  immunizations: BoolString;
  insurance: BoolString;
  insuranceLinkName: string;
  intakeForms: BoolString;
  isImmunitrax: BoolString;
  isMCS: BoolString;
  labResultLinkName: string;
  labResults: BoolString;
  logo: string;
  mapLink: string;
  messagesLinkName: string;
  messaging: BoolString;
  name: string;
  operatingHours: string;
  personalRecord: BoolString;
  phone: string;
  prescriptions: BoolString;
  privacyPolicy: string;
  productName: string;
  qrFax: BoolString;
  scheduling: BoolString;
  selectChildLabel: string;
  selectChildMessage: string;
  selfTestLinkName: string;
  state: string;
  statement: BoolString;
  statusLinkName: string;
  timeZoneOffset: string;
  toDoMessage: string;
  upload: BoolString;
  uploadLinkName: string;
  url: string;
  userID: string;
  validateDemographicInput: string;
  visits: BoolString;
  visitsLinkName: string;
  welcomeMessage: string;
  zip: string;
  statementLinkName: string;
  formsLinkName: string;
  displayOptInText: BoolString;
  allowCheckin: BoolString;
}

export enum ReplyOption {
  OnlyToSelectedOnlineProviders = 'Only to selected online providers',
  OnlyToSendingProvider = 'Only to sending provider',
  ToAnyOnlineProvider = 'To any online provider'
}

export interface IAppointmentsValuePairs {
  advancedScheduling: BoolString;
  allowCheckin: BoolString;
  allowFreeTextSymptom: BoolString;
  allowGroupAppointments: BoolString;
  allowIneligibleAppointment: BoolString;
  allowSameDayAppt: BoolString;
  appointmentMessage: string;
  appointmentsDisplayed: string;
  cancelBeforeApptMessage: string;
  cancellationEmailMessage: string;
  checkinDistance: string;
  checkinModule: BoolString;
  confirmationEmail: string;
  confirmationEmailMessage: string;
  currentlySheduledAppointmentTimeCheck: string;
  defaultSearch: string;
  displayAppointmentHistory: BoolString;
  displayDefaultProvider: BoolString;
  displayOptInText: BoolString;
  displayProviderTeam: BoolString;
  doNotCheckDistance: BoolString;
  emergencyEmail: string;
  emergencySymptomClinicCode: string;
  emergencySymptomMessage: string;
  emergencySymptomProviderCode: string;
  emergencySymptomTxnCode: string;
  excessAppointmentMessage: string;
  hideUpcomingAppointments: BoolString;
  locationsHeader: string;
  maxAppointmentsPerDay: string;
  minutesBeforeScheduleMessage: string;
  nextAvailableSearchDays: string;
  noAppointmentsFound: string;
  noApptAfter: string;
  noApptBeforeMinutes: string;
  noCancelApptBeforeMinutes: string;
  patientProviderScheduling: BoolString;
  patientProviderTeamScheduling: BoolString;
  requireSymptoms: BoolString;
  sendConfirmationEmail: BoolString;
  showSpecialInstructions: BoolString;
  showSymptoms: BoolString;
  symptomFreeTextLabel: string;
  symptomsHeader: string;
  walkinWindow: string;
}

export interface ISecureMessageValuePairs {
  allowAttachments: BoolString;
  allowCompose: BoolString;
  allowIneligibleMessaging: BoolString;
  allowRepy: BoolString;
  clinicMessaging: BoolString;
  messageLength: string;
  messagesDisplayed: string;
  patientProviderMessaging: BoolString;
  replyOption: ReplyOption | '';
  replyProviders: string;
  secureMessageEmail: string;
  secureMessageEmailSubject: string;
  secureMessageMessage: string;
  secureMessageText: string;
}

export interface IAuthenticationValuePairs {
  allowDualAuth: BoolString;
  allowNewPatient: BoolString;
  allowParentsToLogin: BoolString;
  allowReregistration: BoolString;
  alreadyRegisteredMessage: string;
  authenticationType: AuthenticationType;
  casAttribute: string;
  casLoginURL: string;
  casLogoutURL: string;
  casMedicatAttribute: string;
  confirmationEmail: string;
  displaySplash: BoolString;
  dualLandingPageMessage: string;
  inactiveMessage: string;
  ineligibleMessage: string;
  ldapAttribute: string;
  ldapFailOver: BoolString;
  ldapPassword: string;
  ldapPort: string;
  ldapSearchAttribute: string;
  ldapSearchDomain: string;
  ldapSearchFilterField: string;
  ldapServerName: string;
  ldapUserName: string;
  lockAccount: BoolString;
  lockAccountMessage: string;
  lockAccountTimes: string;
  loginMessage: string;
  mppAuthButton: string;
  noEmailMessage: string;
  password_AllowUserNameAsPassword: BoolString;
  password_AllowUserNameAsPassword_FailureMessage: string;
  password_FailureMessage_ReturnComposite: BoolString;
  password_MinimumLength: string;
  password_MinimumLength_FailureMessage: string;
  password_RequireMixedCase: BoolString;
  password_RequireMixedCase_FailureMessage: string;
  password_RequireMixedLettersAndNumbers: BoolString;
  password_RequireMixedLettersAndNumbers_FailureMessage: string;
  password_RequireSpecialCharacter: BoolString;
  password_RequireSpecialCharacter_FailureMessage: string;
  patientNotFoundMessage: string;
  preventInactiveLogin: BoolString;
  preventIneligibleLogin: BoolString;
  registrationFields: string;
  registrationIDField: string;
  shibAttribute: string;
  shibLogoutURL: string;
  shibMedicatAttribute: string;
  ssoButton: string;
  studentIDCaption: string;
  unregisterableMessage: string;
  userNameInUse: string;
  clinicMessage: string;
}

export enum AuthenticationType {
  PatientPortal = '0',
  CAS = '1',
  LDAP = '2',
  Shibboleth = '4',
  Other = '3'
}

export const AuthenticationTypeNames = (): {
  [key in AuthenticationType]?: string;
} => {
  return {
    [AuthenticationType.PatientPortal]: 'Patient Portal',
    [AuthenticationType.CAS]: 'Central Authentication Service (CAS)',
    [AuthenticationType.LDAP]: 'Lightweight Directory Access Protocol (LDAP)',
    [AuthenticationType.Other]: 'Other',
    [AuthenticationType.Shibboleth]: 'Shibboleth'
  };
};

export enum RegistrationFields {
  'StudentID' = 'StudentID',
  'FirstAndLastName' = 'Name',
  'Email' = 'Email',
  'Birthdate' = 'Birthdate'
}

export const RegistrationFieldsNames = (): {
  [key in RegistrationFields]?: string;
} => {
  return {
    [RegistrationFields.Birthdate]: 'Birthdate',
    [RegistrationFields.FirstAndLastName]: 'First and Last Name',
    [RegistrationFields.Email]: 'Email',
    [RegistrationFields.StudentID]: 'StudentID'
  };
};

interface IAppointmentsKeyValuePairs {
  advancedScheduling: BoolString;
  allowFreeTextSymptom: BoolString;
  allowGroupAppointments: BoolString;
  allowIneligibleAppointment: BoolString;
  allowSameDayAppt: BoolString;
  appointmentMessage: string;
  appointmentsDisplayed: string;
  cancelBeforeApptMessage: string;
  cancellationEmailMessage: string;
  checkinDistance: string;
  checkinModule: BoolString;
  confirmationEmail: string;
  confirmationEmailMessage: string;
  currentlySheduledAppointmentTimeCheck: string;
  defaultSearch: string;
  displayAppointmentHistory: BoolString;
  displayDefaultProvider: BoolString;
  displayProviderTeam: BoolString;
  doNotCheckDistance: BoolString;
  emergencyEmail: string;
  emergencySymptomClinicCode: string;
  emergencySymptomMessage: string;
  emergencySymptomProviderCode: string;
  emergencySymptomTxnCode: string;
  excessAppointmentMessage: string;
  hideUpcomingAppointments: BoolString;
  locationsHeader: string;
  maxAppointmentsPerDay: string;
  minutesBeforeScheduleMessage: string;
  nextAvailableSearchDays: string;
  noAppointmentsFound: string;
  noApptAfter: string;
  noApptBeforeMinutes: string;
  noCancelApptBeforeMinutes: string;
  patientProviderScheduling: BoolString;
  patientProviderTeamScheduling: BoolString;
  requireSymptoms: BoolString;
  sendConfirmationEmail: BoolString;
  showSpecialInstructions: BoolString;
  showSymptoms: BoolString;
  symptomFreeTextLabel: string;
  symptomsHeader: string;
  walkinWindow: string;
}

export interface PortalOptionData {
  [PortalOptionGroup.Main]: Partial<IMainValuePairs>;
  [PortalOptionGroup.Authentication]: Partial<IAuthenticationValuePairs>;
  [PortalOptionGroup.SecureMessage]: Partial<ISecureMessageValuePairs>;
  [PortalOptionGroup.Appointments]: Partial<IAppointmentsKeyValuePairs>;
  [PortalOptionGroup.Forms]: Partial<IFormsKeyValuePairs>;
  [PortalOptionGroup.Immunization]: Partial<IImmunizationKeyValuePairs>;
  [PortalOptionGroup.Insurance]: Partial<IInsuranceKeyValuePairs>;
  [PortalOptionGroup.Education]: Partial<IEducationKeyValuePairs>;
  [PortalOptionGroup.Statements]: Partial<IStatementsKeyValuePairs>;
  [PortalOptionGroup.DocumentUpload]: Partial<IDocumentIploadKeyValuePairs>;
  [PortalOptionGroup.Status]: Partial<IStatusKeyValuePairs>;
  [PortalOptionGroup.LabResults]: Partial<ILabResultKeyValuePairs>;
  [PortalOptionGroup.VisitPublish]: Partial<IVisitPublishKeyValuePairs>;
}

export interface IPortalKeyValuePairs
  extends IMainValuePairs,
  IAuthenticationValuePairs,
  ISecureMessageValuePairs,
  IAppointmentsKeyValuePairs,
  IFormsKeyValuePairs,
  IImmunizationKeyValuePairs,
  IInsuranceKeyValuePairs,
  IEducationKeyValuePairs,
  IStatementsKeyValuePairs,
  IDocumentIploadKeyValuePairs, IStatusKeyValuePairs, ILabResultKeyValuePairs, IVisitPublishKeyValuePairs { }

export enum StatementType {
  Walkouts = '0',
  Statement = '1'
}

export enum InsuranceCardExpireType {
  ExpireCardsOnInsuranceExpireDate = '0',
  ExpireCardsOnDate = '1'
}

export enum PortalOptionGroup {
  Main = 1,
  Authentication = 2,
  SecureMessage = 3,
  Appointments = 4,
  Forms = 5,
  Immunization = 6,
  Insurance = 7,
  Education = 8,
  Statements = 9,
  DocumentUpload = 10,
  Status = 11,
  LabResults = 12,
  VisitPublish = 13
}

export const PortalOptionGroupNames = (): Record<PortalOptionGroup, string> => {
  return {
    [PortalOptionGroup.Main]: 'Main',
    [PortalOptionGroup.Authentication]: 'Authentication',
    [PortalOptionGroup.SecureMessage]: 'Secure Message',
    [PortalOptionGroup.Appointments]: 'Appointments',
    [PortalOptionGroup.Forms]: 'Forms',
    [PortalOptionGroup.Immunization]: 'Immunization',
    [PortalOptionGroup.Insurance]: 'Insurance',
    [PortalOptionGroup.Education]: 'Education',
    [PortalOptionGroup.Statements]: 'Statements',
    [PortalOptionGroup.DocumentUpload]: 'Document Upload',
    [PortalOptionGroup.Status]: 'Status',
    [PortalOptionGroup.LabResults]: 'Lab Results',
    [PortalOptionGroup.VisitPublish]: 'Visit Publish'
  };
};

export interface IsInitItem {
  clinicId: number;
  isInit: boolean;
}
