interface IAction {
    type: string;
    payload: any;
}
interface IStateReducer {
    immunization: any
    procedure: any
    document: any
    all: any
}

export const actionsTypes = {
    SET_FACT_IMMUNIZATIONS: 'SET_FACT_IMMUNIZATIONS',
    SET_FACT_PROCEDURES: "SET_FACT_PROCEDURES",
    SET_FACT_DOCUMENTS: "SET_FACT_DOCUMENTS",
    SET_FACT_ALL: "SET_FACT_ALL",
}

const initItem = {
    summary: []
}

const initialState: IStateReducer = {
    immunization: initItem,
    procedure: initItem,
    document: initItem,
    all: initItem
};

const Reducer = (state: any = { ...initialState }, action: IAction) => {
    switch (action.type) {
        case actionsTypes.SET_FACT_IMMUNIZATIONS:
            return { ...state, immunization: action.payload }
        case actionsTypes.SET_FACT_PROCEDURES:
            return { ...state, procedure: action.payload }
        case actionsTypes.SET_FACT_DOCUMENTS:
            return { ...state, document: action.payload }
        case actionsTypes.SET_FACT_ALL:
            return { ...state, all: action.payload }
        default:
            return state;
    }
};

export default Reducer;
