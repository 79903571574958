import _ from "lodash";
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { expiryCheck } from "src/App/LoginPage/store/Actions";
import {
  getAllFacts,
  getImmunizationsForFacts,
  getProcedures,
} from "src/Activities/RuleEditor/Store/RuleActions/RuleOptionActions/RuleOptionActions";

const ActionManager = {
  ADD_FACT_IMMUNIZATION: "ADD_FACT_IMMUNIZATION",
  ADD_FACT_PROCEDURE: "ADD_FACT_PROCEDURE",
  ADD_ALL_FACTS: "ADD_ALL_FACTS",
  ADD_FACT_DOCUMENT_CODES: "ADD_FACT_DOCUMENT_CODES",
};

const initialState: any = {
  immunizationStore: {
    pagination: {},
    immunizations: [],
  },
  procedureStore: {
    pagination: {},
    procedures: [],
  },
  documentCodeStore: {
    pagination: {},
    documentCodes: [],
  },
  allFactsStore: {
    pagination: {},
    allFacts: [],
  },
};

interface IAction {
  type: string;
  payload: any;
}

const ImProReducer = (state = { ...initialState }, action: IAction) => {
  switch (action.type) {
    case ActionManager.ADD_FACT_IMMUNIZATION: {
      let newState = _.cloneDeep(state);
      let immunizationStore = newState.immunizationStore;
      if (action.payload.pagination.current > 1) {
        let isExist: boolean = isExistData(
          immunizationStore.immunizations,
          action.payload.data
        );
        if (isExist) return state;
        immunizationStore.immunizations = [
          ...immunizationStore.immunizations,
          ...action.payload.data,
        ];
      } else immunizationStore.immunizations = action.payload.data;
      immunizationStore.pagination = action.payload.pagination;
      return newState;
    }
    case ActionManager.ADD_FACT_PROCEDURE: {
      let newState = _.cloneDeep(state);
      let procedureStore = newState.procedureStore;
      if (action.payload.pagination.current > 1) {
        let isExist: boolean = isExistData(
          procedureStore.procedures,
          action.payload.data
        );
        if (isExist) return state;
        procedureStore.procedures = [
          ...procedureStore.procedures,
          ...action.payload.data,
        ];
      } else procedureStore.procedures = action.payload.data;
      procedureStore.pagination = action.payload.pagination;
      return newState;
    }
    case ActionManager.ADD_FACT_DOCUMENT_CODES: {
      let newState = _.cloneDeep(state);
      let documentCodeStore = newState.documentCodeStore;
      if (action.payload.pagination.current > 1) {
        let isExist: boolean = isExistData(
          documentCodeStore.documentCodes,
          action.payload.data
        );
        if (isExist) return state;
        documentCodeStore.documentCodes = [
          ...documentCodeStore.documentCodes,
          ...action.payload.data,
        ];
      } else documentCodeStore.documentCodes = action.payload.data;
      documentCodeStore.pagination = action.payload.pagination;
      return newState;
    }
    case ActionManager.ADD_ALL_FACTS: {
      let newState = _.cloneDeep(state);
      let allFactsStore = newState.allFactsStore;
      if (action.payload.pagination.current > 1) {
        let isExist: boolean = isExistData(
          allFactsStore.allFacts,
          action.payload.data
        );
        if (isExist) return state;
        allFactsStore.allFacts = [
          ...allFactsStore.allFacts,
          ...action.payload.data,
        ];
      } else allFactsStore.allFacts = action.payload.data;
      allFactsStore.pagination = action.payload.pagination;
      return newState;
    }
    default: {
      return state;
    }
  }
};
export default ImProReducer;

export const addFactImmunizations = (data: any) => {
  return {
    type: ActionManager.ADD_FACT_IMMUNIZATION,
    payload: data,
  };
};
export const addFactProcedures = (data: any) => {
  return {
    type: ActionManager.ADD_FACT_PROCEDURE,
    payload: data,
  };
};
export const addFactDocumentCodes = (data: any) => {
  return {
    type: ActionManager.ADD_FACT_DOCUMENT_CODES,
    payload: data,
  };
};
export const addAllFacts = (data: any) => {
  return {
    type: ActionManager.ADD_ALL_FACTS,
    payload: data,
  };
};

export const addFact = (data: any) => {
  return APIHandler.AxiosInstance.post(ApiUrl.ADD_FACT, data, {
    withCredentials: true,
  });
};

export const editFact = (data: any) => {
  return APIHandler.AxiosInstance.post(ApiUrl.UPDATE_FACT_STATUS, data, {
    withCredentials: true
  })
};

// to check whether a Cohort already exist in the store
function isExistData(state: any, action: any): boolean {
  if (action && action.length > 0) {
    let actionItem: any = action[0];
    let isExist: any = state.find((impro: any) => {
      return impro.name === actionItem.name;
    });
    if (isExist) return true;
  }
  return false;
}

export const getFactDocumentCodes = (dispatch: any) => {
  const data = {
    startIndex: 0,
    count: 0,
    filter: {
      excludeImmunizations: true,
      gender: "",
      includeInactive: false,
      searchString: "",
      typeTxnClassId: -1,
      includeCvxGroup: false,
    },
  };

  getDocumentCodes(data)
    .then((response: any) => {
      if (response.data.success) {
        let result = response.data.result;
        dispatch(addFactDocumentCodes({ pagination: 0, data: result.summary }));
      }
    })
    .catch((error: any) => {
      dispatch(expiryCheck(error));
      console.log(error);
    });
};

export const getFactProcedureCodes = (dispatch: any) => {
  const data = {
    startIndex: 0,
    count: 0,
    filter: {
      excludeImmunizations: true,
      gender: "",
      includeInactive: false,
      searchString: "",
      typeTxnClassId: -1,
      includeCvxGroup: false,
    },
  };

  getProcedures(data)
    .then((response: any) => {
      if (response.data.success) {
        let result = response.data.result;
        dispatch(addFactProcedures({ pagination: 0, data: result.summary }));
      }
    })
    .catch((error: any) => {
      dispatch(expiryCheck(error));
      console.log(error);
    });
};

export const getAllFactCodes = (dispatch: any) => {
  let data = {
    startIndex: 0,
    count: 0,
    filter: {
      gender: "",
      includeInactive: false,
      searchString: "",
      typeTxnClassId: -1,
      includeCvxGroup: null,
    },
  };

  getAllFacts(data)
    .then((response: any) => {
      if (response.data.success) {
        let result = response.data.result;
        dispatch(addAllFacts({ pagination: 0, data: result.summary }));
      }
    })
    .catch((error: any) => {
      dispatch(expiryCheck(error));
      console.log(error);
    });
};
export const getFactImmunizationCodes = (dispatch: any) => {
  const data = {
    startIndex: 0,
    count: 0,
    filter: {
      excludeImmunizations: false,
      gender: "",
      includeInactive: false,
      searchString: "",
      typeTxnClassId: -1,
      includeCvxGroup: false,
    },
  };

  getImmunizationsForFacts(data)
    .then((response: any) => {
      if (response.data.success) {
        let result = response.data.result;
        dispatch(addFactImmunizations({ pagination: 0, data: result.summary }));
      }
    })
    .catch((error: any) => {
      dispatch(expiryCheck(error));
      console.log(error);
    });
};

export const getDocumentCodes = (request: any) => {
  return APIHandler.AxiosInstance.post(ApiUrl.GET_DOCUMENT_CODES, request, {
    withCredentials: true,
  });
};
