import React, { DependencyList, useMemo } from 'react';
import { IFormValidator, isFormValid } from './formValidator';

export const useValidatorV2 = (
  data: IFormValidator,
  deps: DependencyList | undefined
) => {
  const value = useMemo(() => {
    const arr = data.map((v) => isFormValid([v]));
    return arr;
  }, deps);
  const isValid = !value.some((v) => !v.isValid);
  const nonValidFieldsNames = value
    .filter((v) => !v.isValid)
    .filter((v) => v.notValidItem?.fieldName);
  return {
    isValid,
    errorMessage: !isValid ? (
      nonValidFieldsNames.length > 0 ? (
        <div>
          <div>The following required fields are missing</div>
          {nonValidFieldsNames.map((v) => (
            <div>
              {` - `}
              {v.notValidItem?.fieldName}
            </div>
          ))}
        </div>
      ) : (
        'Maybe data was not changed'
      )
    ) : undefined
  };
};
