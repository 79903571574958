import React, { FC, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import LazyLoader from 'src/Framework/Controls/LazyLoader';
import NotFound from 'src/App/404';

import Sidebar from './Sidebar';
import { Container, MainContainer, PageContainer } from './styled';
import {
  AdminRightContainerID,
  PagesList,
  SIDEBAR_DATA,
  getFirstPage,
  getTreeData
} from './utils';
import ErrorPage from 'src/App/ErrorPage';

import { ROUTES } from 'src/ManagementSystem/routes';

interface IProps {}

const InnerComponent = ({ value, props }: { value: any; props: any }) => {
  useEffect(() => {
    const element = document.getElementById(AdminRightContainerID);
    if (element) {
      element.focus();
    }
  }, []);
  const Component = value.component;
  return (
    <PageContainer tabIndex={0} id={AdminRightContainerID}>
      <LazyLoader>
        <Component {...props} />
      </LazyLoader>
    </PageContainer>
  );
};

const Render = (value: any, props: any) => {
  return <InnerComponent props={props} value={value} />;
};

const Component: FC<IProps> = ({}: IProps) => {
  const menuData = getTreeData(SIDEBAR_DATA);
  const firstPage = getFirstPage(menuData.treeData[0]).path;
  return (
    <Container>
      <Sidebar />
      <MainContainer>
        <Switch>
          {Object.values(PagesList).map((value) => (
            <Route
              key={value.key}
              exact={value.exact !== undefined ? value.exact : true}
              path={value.path}
              render={(props) => {
                const permision = value.permission;
                if (permision) {
                  const result = permision();
                  if (result.success) {
                    return Render(value, props);
                  }
                  return <ErrorPage message={result.accessMessage} />;
                }
                return Render(value, props);
              }}
            />
          ))}
          <Route
            exact
            path={ROUTES.ManagementSystem}
            component={() => <Redirect to={firstPage} />}
          />
          <Route component={NotFound} />
        </Switch>
      </MainContainer>
    </Container>
  );
};

export default React.memo(Component);
