import _ from 'lodash';
import { store } from 'src';
import { actionsTypes } from '..';
import { ITraineeInfo } from '../types';
import { TempFieldsForTable } from 'src/Framework/Controls/Table/types';

export const setInfo = (
  payload: Record<string, ITraineeInfo>,
  providerId: string | number
) => {
  const data = Object.values(payload)[0];
  if (data) {
    store.dispatch({
      type: actionsTypes.SET,
      payload: data,
      providerId
    });
  }
};

export const onCreateUpdateItem = (
  payload: Partial<ITraineeInfo> & Partial<TempFieldsForTable>,
  providerId: string | number
) => {
  let data = {
    ...(store.getState().trainee.info.providers[providerId]?.info || {})
  };
  if (!_.isEmpty(data)) {
    data = { ...data, ...payload };
  } else {
    data = { ...payload, providerId: +providerId };
  }
  setInfo({ [providerId]: data }, providerId);
};
