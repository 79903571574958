import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import cn from 'classnames';
import { SketchPicker, ColorResult } from 'react-color';
import { v4 as uuidv4 } from 'uuid';

import SelectContainer, {
  ISelect
} from 'src/Framework/Controls/SelectContainer';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import {
  Color,
  PreferedColorsContainer,
  PreferedItem,
  Container,
  MainContainer
} from './styled';

interface IProps {
  value: string | undefined | null;
  onChange: (color: string) => void;
  selectProps?: Partial<ISelect>;
  defaultWidth?: number | string;
}

const preferColorsList = [
  '#20609b',
  '#2e85fe',
  '#35b68f',
  '#6ed635',
  '#eebd17',
  '#e07122',
  '#ef1a29',
  '#8d56ff',
  '#2675b8',
  '#3b92fe',
  '#38be9e',
  '#7ddc49',
  '#f0c52c',
  '#e47f35',
  '#f0343e',
  '#9868ff',
  '#3088d4',
  '#509fff',
  '#4ac7a9',
  '#90e262',
  '#f2cd43',
  '#e88e47',
  '#f04a51',
  '#a57aff',
  '#4d9ada',
  '#64acfe',
  '#63d1b6',
  '#9de574',
  '#f4d55f',
  '#ec9b5b',
  '#f2676b',
  '#b18bff',
  '#67a9de',
  '#79b8ff',
  '#7ad9c4',
  '#b0eb8e',
  '#f5db77',
  '#f0a96e',
  '#f48185',
  '#bb9bfe',
  '#89bee5',
  '#8fc5ff',
  '#93e3d2',
  '#c1f1a5',
  '#f8e392',
  '#f4bb85',
  '#f59a9e',
  '#c7adff',
  '#aacfea',
  '#a7d2ff',
  '#afecdf',
  '#d2f4be',
  '#fbeaaf',
  '#f9c99d',
  '#f8b8b8',
  '#d3beff',
  '#cee3ee',
  '#bedeff',
  '#ccf6ec',
  '#e3f9d7',
  '#fdf2cf',
  '#fad9b6',
  '#fad4d5'
];

const dialogTogglerId = 'dialog-toggler-button';

const Component: FC<IProps> = ({
  value,
  onChange,
  selectProps,
  defaultWidth
}: IProps) => {
  const currentId = useRef(uuidv4());
  const inputRef = useRef(null);
  const optionsRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [pickerOpened, setPickerOpened] = useState(false);
  const fixedValue = useMemo(() => {
    if (!value || (value && value.length === 7)) {
      return value;
    } else {
      return '#0' + value.slice(1);
    }
  }, [value]);
  useEffect(() => {
    const element = document.getElementById(currentId.current);
    const parentElement =
      element?.parentElement?.parentElement?.parentElement?.parentElement;
    if (parentElement) {
      if (visible) {
        parentElement.style.bottom = '0px';
        parentElement.style.right = '0px';
        parentElement.style.zIndex = '10000';
      } else {
        parentElement.style.bottom = 'unset';
        parentElement.style.right = 'unset';
        parentElement.style.zIndex = 'unset';
      }
    }
  }, [visible]);

  const handlePickerChange = (color: ColorResult) => {
    onChange(color.hex);
  };

  const handleChange = (color: string) => {
    onChange(color);
    setVisible(false);
    returnToInput();
  };

  const returnToInput = () => {
    if (inputRef.current) {
      //@ts-ignore
      inputRef.current.focus();
    }
  };

  const onKeyDownAccessibility = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    const target = e.target as HTMLElement;
    if (
      e.shiftKey &&
      e.key === 'Tab' &&
      target?.id &&
      target?.id === currentId.current
    ) {
      e.preventDefault();
      returnToInput();
    }
    if (
      !e.shiftKey &&
      e.key === 'Tab' &&
      target.id &&
      target.id === dialogTogglerId &&
      !pickerOpened
    ) {
      e.preventDefault();
      returnToInput();
    }
    if (e.key === 'Enter') {
      target.click();
      if (target.tagName === 'BUTTON') {
        returnToInput();
      }
    }
    if (e.key === 'ArrowRight') {
      e.preventDefault();
      if (document.activeElement?.nextElementSibling) {
        // @ts-ignore
        document.activeElement.nextElementSibling.focus();
      }
    }
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      if (document.activeElement?.previousElementSibling) {
        // @ts-ignore
        document.activeElement.previousElementSibling.focus();
      }
    }
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (document.activeElement?.nextElementSibling) {
        // @ts-ignore
        document.activeElement.nextElementSibling.focus();
      }
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (document.activeElement?.previousElementSibling) {
        // @ts-ignore
        document.activeElement.previousElementSibling.focus();
      }
    }
  };

  const onKeyDownAccessibilityInput = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab' && visible) {
      e.preventDefault();
      (optionsRef.current as unknown as HTMLElement).focus();
    }
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      if (visible) {
        setVisible(false);
      }
    }
  };

  const pickerHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      setVisible(false);
      setPickerOpened(false);
      returnToInput();
    }
  };

  return (
    <MainContainer style={{ width: defaultWidth || 245 }}>
      <SelectContainer
        value={undefined}
        onChange={onChange}
        label="Color"
        {...selectProps}
        inputProps={{
          onKeyDown: (e: React.KeyboardEvent) => onKeyDownAccessibilityInput(e),
          ref: inputRef,
          dropdownRender: (originNode: any) => {
            return (
              <Container
                id={currentId.current}
                ref={optionsRef}
                tabIndex={0}
                role="list"
                onKeyDown={(e) => onKeyDownAccessibility(e)}
              >
                <PreferedColorsContainer>
                  {preferColorsList.map((color) => (
                    <PreferedItem
                      role="listitem"
                      aria-label="color selector"
                      key={color}
                      onClick={() => handleChange(color)}
                      className={cn({
                        enabled: color === fixedValue
                      })}
                      style={{ backgroundColor: color }}
                    />
                  ))}
                  <ButtonIcon
                    isButton={true}
                    id={dialogTogglerId}
                    label={pickerOpened ? 'close dialog' : 'open dialog'}
                    toggled={pickerOpened}
                    name={pickerOpened ? ICONS_LIST.minus : ICONS_LIST.add}
                    size={24}
                    onClick={() => {
                      setPickerOpened(!pickerOpened);
                    }}
                  />
                </PreferedColorsContainer>
                {pickerOpened && (
                  <div onKeyDown={(e) => pickerHandler(e)}>
                    <SketchPicker
                      disableAlpha={true}
                      color={fixedValue || ''}
                      onChange={handlePickerChange}
                    />
                  </div>
                )}
              </Container>
            );
          },
          suffixIcon: (
            <>
              {fixedValue && <Color style={{ backgroundColor: fixedValue }} />}
            </>
          ),
          placeholder: fixedValue || '',
          ...selectProps?.inputProps,
          onBlur: () => setVisible(false),
          open: visible,
          onDropdownVisibleChange: (open) => {
            if (open) {
              setTimeout(() => {
                if (optionsRef.current) {
                  (optionsRef.current as unknown as HTMLElement).focus();
                }
              }, 200);
            }
            setVisible(open);
          }
        }}
      />
    </MainContainer>
  );
};

export default Component;
