import React from "react";

export const SquaredJpg = (props: any) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M12.5 0.625V5.625H17.5" stroke="#111111" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.5 8.125V5.625L12.5 0.625H2.5V8.125" stroke="#111111" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.5 16.875V19.375H17.5V16.875" stroke="#111111" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.8125 15.5C5.625 15.5 5.5 15.5 5.3125 15.4375V14.6875C5.375 14.6875 5.4375 14.6875 5.5 14.75C5.5625 14.8125 5.625 14.75 5.6875 14.75C5.875 14.75 5.9375 14.6875 6 14.625C6.0625 14.5625 6.125 14.375 6.125 14.125V10.8125H7.125V14.0625C7.125 14.5625 7 14.9375 6.8125 15.1875C6.625 15.4375 6.1875 15.5 5.8125 15.5Z" fill="#111111"/>
        <path d="M10.4375 11.9375C10.4375 12.3125 10.3125 12.625 10.0625 12.875C9.81247 13.125 9.49997 13.1875 9.06247 13.1875H8.81247V14.375H7.87497V10.8125H9.12497C9.56247 10.8125 9.93747 10.9375 10.125 11.125C10.3125 11.3125 10.4375 11.5625 10.4375 11.9375ZM8.81247 12.375H8.99997C9.12497 12.375 9.24997 12.3125 9.37497 12.25C9.49997 12.1875 9.49997 12.0625 9.49997 11.9375C9.49997 11.6875 9.37497 11.5625 9.12497 11.5625H8.87497V12.375H8.81247Z" fill="#111111"/>
        <path d="M12.4375 12.3125H14V14.25C13.5625 14.375 13.125 14.4375 12.625 14.4375C12.0625 14.4375 11.625 14.25 11.375 13.9375C11.125 13.625 10.9375 13.1875 10.9375 12.5625C10.9375 12 11.125 11.5625 11.4375 11.1875C11.75 10.8125 12.25 10.6875 12.8125 10.6875C13.0625 10.6875 13.25 10.6875 13.4375 10.75C13.625 10.8125 13.8125 10.875 13.9375 10.9375L13.625 11.6875C13.375 11.5625 13.0625 11.5 12.75 11.5C12.4375 11.5 12.25 11.5625 12.125 11.75C12 11.9375 11.875 12.25 11.875 12.625C11.875 12.9375 11.9375 13.1875 12.0625 13.375C12.1875 13.5625 12.375 13.625 12.6875 13.625C12.8125 13.625 12.9375 13.625 13.0625 13.5625V13H12.4375V12.3125Z" fill="#111111"/>
        <path d="M19.375 8.125H0.625V16.875H19.375V8.125Z" stroke="#111111" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);
