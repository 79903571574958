import React, { FC, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { HtmlContainer } from './styled';

interface IProps {
  value: string;
}

const generateUUID = (value: string) => `preview-${value}`;
const styleUUID = (value: string) => `style-${value}`;

const Component: FC<IProps> = ({ value }: IProps) => {
  const uuid = useRef(generateUUID(uuidv4()));
  let htmlString = value;
  useEffect(() => {
    const element = document.getElementById(uuid.current);
    if (element) {
      const el = document.createElement('style');
      el.appendChild(document.createTextNode(''));
      el.id = styleUUID(uuid.current);
      document.head.appendChild(el);
      const style = element.getElementsByTagName('style')[0];
      if (style) {
        const sheet = style.sheet;
        if (sheet) {
          const length = sheet.cssRules.length;
          for (let i = 0; i < length; i++) {
            const item = sheet.cssRules[i];
            if (item) {
              const cssRule = `#${uuid.current} :not(img) ${item.cssText}`;
              el.sheet?.insertRule(cssRule, i);
            }
          }
        }
      }
      return () => {
        el.remove();
      };
    }
    return () => {};
  }, []);

  return (
    <HtmlContainer isRawPreview={true}>
      <div id="rawPreview">
        <div
          id={uuid.current}
          dangerouslySetInnerHTML={{ __html: htmlString }}
        />
      </div>
    </HtmlContainer>
  );
};

export default React.memo(Component);
