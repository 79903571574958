import store from "src/store";
import { filterExpiredEvents } from "../utils";
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "./ScheduleActions/ScheduleDetailActions/ApiUrl";

export const UPDATE_SCHEDULE_SLICE_EVENTS = "UPDATE_SCHEDULE_SLICE_EVENTS";
export const REMOVE_SCHEDULE_SLICE_EVENT = "REMOVE_SCHEDULE_SLICE_EVENT";
export const REMOVE_EVENT_FROM_SLICE_EVENTS = "REMOVE_EVENT_FROM_SLICE_EVENTS";
export const UPDATE_EVENTS_ON_SLICE_EVENTS = "UPDATE_EVENTS_ON_SLICE_EVENTS";

export const updateEventsOnAction = (result: any) => {
  try {
    const saveScheduleEventInfo = result.saveScheduleEventInfo;
    const scheduleEvents = result.scheduleEvents;
    const updateEventAction = (item: any) => {
      const updatedEvent = scheduleEvents.find((e: any) => e.uid === item.uid);
      const eventsToUpdate = {
        [item.uid]: { ...updatedEvent }
      };
      updateEventOnSliceEvents(item.sliceId, eventsToUpdate);
    };
    saveScheduleEventInfo?.deleted?.forEach((item: any) =>
      updateEventAction(item)
    );
    saveScheduleEventInfo?.updated?.forEach((item: any) =>
      updateEventAction(item)
    );
    saveScheduleEventInfo?.created?.forEach((item: any) =>
      updateEventAction(item)
    );
  } catch (e) {
    console.log({ e })
  }
}

export const onDelete = async (
  details: any,
  singleSubject?: boolean,
  callback = (data: any, error: any) => { }
) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.DELETE_SCHEDULE_EVENT,
      {
        ...details
      },
      { withCredentials: true }
    );
    let data: any = response.data;
    callback(data, null);
    if (data.success) {
      const { result } = data;
      updateEventsOnAction(result);
      return result;
    }
  } catch (error) {
    console.log(error);
    callback(null, error);
  }
};


export const updateEventOnSliceEvents = (sliceId: string, eventsToUpdate: any) => {
  store.dispatch({
    type: UPDATE_EVENTS_ON_SLICE_EVENTS,
    payload: { sliceId, eventsToUpdate }
  })
};

export const removeScheduleSliceEvent = (sliceId: string, uid: any) => {
  return {
    type: REMOVE_EVENT_FROM_SLICE_EVENTS,
    payload: { sliceId, uid }
  };
};

export const updateScheduleSliceEvents = (reqTime: number, sliceEvents: any) => {
  store.dispatch({
    type: UPDATE_SCHEDULE_SLICE_EVENTS,
    payload: { ...sliceEvents }
  })
};

export const findAndFilterExpiredEvents = () => {
  const slices = store.getState().schedule.sliceEvents
  const data = filterExpiredEvents(slices)
  updateScheduleSliceEvents(new Date().getTime(), data)
}