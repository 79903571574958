import React from 'react';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const CheckGreenCircle = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="16"
      cy="16"
      r="15.5"
      fill="white"
      stroke={globalStyleVariables.newBrandingSuccessText}
    />
    <path
      d="M10.5 17.5L13.5 20.5L21.5 11.5"
      stroke={globalStyleVariables.newBrandingSuccessText}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
