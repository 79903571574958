import _ from 'lodash';

import { IAction, IStateReducer, IProviderTasks } from './types';

const prefix = 'PROVIDER_TASKS_';

export const actionsTypes = {
  SET: `${prefix}SET`,
  SET_STATE: `${prefix}SET_STATE`,
  SET_CATEGORY: `${prefix}SET_CATEGORY`,
  SET_LOADING: `${prefix}SET_LOADING`,
  SET_FILTERS: `${prefix}SET_FILTERS`,
  SET_PAGINATION: `${prefix}SET_PAGINATION`,
  SET_SORT: `${prefix}SET_SORT`
};

const pageLength = 20;

export const defaultData: IProviderTasks = {
  filters: {
    clinicIds: []
  },
  summary: {},
  sort: {
    sortBy: null,
    sortType: null
  },
  loading: false,
  pagination: {
    pageNumber: 1,
    pageLength
  },
  currentCategory: null
};

const initialState: IStateReducer = {
  providers: {}
};

const Reducer = (
  state: IStateReducer = { ...initialState },
  action: IAction
): IStateReducer => {
  const providerData = () =>
    state.providers[action.providerId] || _.cloneDeep(defaultData);
  switch (action.type) {
    case actionsTypes.SET: {
      const data = providerData();
      data.summary = action.payload;
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: { ...data }
        }
      };
    }
    case actionsTypes.SET_STATE: {
      const data = providerData();
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: data
        }
      };
    }
    case actionsTypes.SET_CATEGORY: {
      const data = providerData();
      data.currentCategory = action.payload;
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: { ...data }
        }
      };
    }
    case actionsTypes.SET_LOADING: {
      const data = providerData();
      data.loading = action.payload;
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: { ...data }
        }
      };
    }
    case actionsTypes.SET_FILTERS: {
      const data = providerData();
      data.filters.clinicIds = action.payload;
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: { ...data }
        }
      };
    }
    case actionsTypes.SET_PAGINATION: {
      const data = providerData();
      data.pagination = {
        ...data.pagination,
        ...action.payload
      };
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: { ...data }
        }
      };
    }
    case actionsTypes.SET_SORT: {
      const data = providerData();
      data.sort = action.payload;
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.providerId]: { ...data }
        }
      };
    }
    default:
      return state;
  }
};

export default Reducer;
