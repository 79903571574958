interface IAction {
    type: string;
    payload: any;
}

const prefix = 'CLINIC_PROVIDERS_'

export const actionsTypes = {
    SET: `${prefix}SET`
}

interface IStateReducer {
    data: Array<any>
}

const initialState: IStateReducer = {
    data: []
}

const Reducer = (state: any = { ...initialState }, action: IAction) => {
    switch (action.type) {
        case actionsTypes.SET:
            return {
                ...state,
                data: [...action.payload]
            }
        default:
            return state;
    }
};

export default Reducer;
